import React, { useContext, useEffect, useState } from "react";
import "./recruiter.css";
import SubHeader from "../subHeader/SubHeader";
import Header from "../header/Header";
import leftArrow from "../../assets/recruiter/left-arrow.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import download from "../../assets/recruiter/download.svg";
import tableStar from "../../assets/recruiter/table-star.svg";
import tableStarNone from "../../assets/recruiter/table-star-none.svg";
import dropDown from "../../assets/recruiter/dropdown.svg";
import dropUp from "../../assets/recruiter/drop-up.svg";
import downloadImg from "../../assets/recruiter/download-btn.svg";
import RecruiterSubMenu from "../mobileMenu/RecruiterSubMenu";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import closeBtn from "../../assets/close-bt.svg";
import moment from "moment";
import Calendar from "react-calendar";
import call from "../../assets/interview/call.svg";
import office from "../../assets/interview/office.svg";
import Video from "../../assets/interview/video.svg";
import CalenderImg from "../../assets/calender/calender.svg";
import { useMain } from "../../hook/useMain";
import AllModalContext from "../../context/allModal/AllModalContext";
import RecruiterChat from "./chat/RecruiterChat";
import RecruiterModal from "./RecruiterReview";
import ScreenModal from "../../dashboard/ScreenModal";
import Loader from "../loader/Loader";
import InterviewChat from "../interviewer/chat/InterviewChat";
import RecruiterHeader from "../header/RecruiterHeader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import { baseUrl } from "../../contextApi/common/data";

const selectItem = [
  {
    id: 1,
    option: "1",
    selected: false,
  },
  {
    id: 2,
    option: "2",
    selected: false,
  },
  {
    id: 3,
    option: "3",
    selected: false,
  },
  {
    id: 4,
    option: "4",
    selected: false,
  },
  {
    id: 5,
    option: "5",
    selected: false,
  },
];
const Status = [
  {
    id: 1,
    option: "INTERVIEWING",
    selected: false,
    bgColor: "#F59E0B",
  },
  {
    id: 2,
    option: "DECISION NEEDED",
    selected: false,
    bgColor: "#DBD9D9",
  },
  {
    id: 3,
    option: "HIRE",
    selected: false,
    bgColor: "#2DD4BF",
  },
  {
    id: 4,
    option: "NOT HIRE",
    selected: false,
    bgColor: "#FB7185",
  },
];
const hiring = [
  {
    id: 1,
    option: "Add Interview",
    selected: false,
  },
  {
    id: 2,
    option: "Andrew States",
    selected: false,
  },
];

function TableCard() {
  const [company, setCompany] = useState(false);
  const [dropHover1, setDropHover1] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("Interviewing");

  return (
    <>
      <div className="table-card">
        <h6 className="table-card-title">Pavan Gandhi</h6>
        <div className="d-flex justify-content-between">
          <div>
            <h6 className="m-0 table-card-email">pavanghandhi009@gmail.com</h6>
            <h6 className="m-0 table-card-email p-0">+9162882534678</h6>
          </div>
          <div>
            <p className="table-card-date m-0">Interview Date</p>
            <p className="table-card-date-text m-0">23/10/2023</p>
          </div>
        </div>
        <div className="table-card-border"></div>
        <p className="table-card-summary m-0">Summary:</p>
        {/* <p className="table-card-summary-text m-0">
          Candidate seems to have good potential for learning at a fast pace
          with so Candidate seems to have good potential for learning at a fast
          pace with soCandidate seems to have good potential for learning at a
          fast pace with...
        </p> */}
        <div className="table-card-border"></div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="m-0 table-card-status">STATUS:</p>
          <div
            className="table-dropdown"
            onMouseEnter={() => setDropHover1(true)}
            onMouseLeave={() => setDropHover1(false)}
          >
            <div
              onClick={() => {
                setCompany(!company);
              }}
              className="dropdown-btn d-flex"
            >
              <p
                className="m-0"
                style={{
                  color: "#000",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {selectedCompany}
              </p>
              <img src={dropHover1 ? dropDown : dropUp} alt="" />
            </div>
            <div
              className="dropdown-content"
              style={{
                display: company ? "block" : "none",
                left: "unset",
                width: "unset",
              }}
            >
              {Status.map((element, index) => {
                return (
                  <div
                    onClick={(e) => {
                      setSelectedCompany(e.target.textContent);
                      setCompany(!company);
                    }}
                    className="item"
                  >
                    {element.option}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <button className="view-profile-btn">View Profile</button>
      </div>
    </>
  );
}

function Table() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("auth") === "true";
    if (!isAuthenticated) {
      navigate("/log-in");
      return;
    }
  }, []);
  const modal = useContext(AllModalContext);
  // const { toggleModal, showModal } = modal;
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, []);
  const [data, setData] = useState([]);
  const [interviewer, setInterviewer] = useState([]);
  const [interviewerId, setInterviewerId] = useState("");
  const [toggle, setToggle] = useState(false);
  const {
    getAllApplication,
    getAllByCompany,
    asigntoInterviewer,
    updateApplication,
    updateInterviewStatus,
  } = useMain();

  const handleAssign = async (e) => {
    const token = localStorage.getItem("recruiterToken");
    if (e.target.value != "") {
      const assign = await asigntoInterviewer(
        id,
        {
          interviewerId: e.target.value,
        },
        token
      );

      if (assign?.statusCode == 200) {
        toast.success("Job assigned successfully.");
      } else if (assign?.statusCode === 500) {
        toast.info("Job already assigned.");
      }
    }
    setInterviewerId(e.target.value);
  };
  const [select1, setSelect1] = useState("");

  const updateStatus = async (e, id1) => {
    const { value, name } = e.target;

    const data = { [name]: name === "score" ? Number(value) : value };

    setLoader(true);
    const res = await updateApplication(data, id1);
    if (res?.statusCode === 200) {
      toast.success("Status updated.");
      setLoader(false);

      getData(id);
    } else {
      setLoader(false);
    }
  };

  const menuItem = useContext(MobileContext);
  const { handleRecruiterToggle, recruiterMenu } = menuItem;
  // const navigate = useNavigate();
  const [dropHover1, setDropHover1] = useState(false);
  const [dropHover2, setDropHover2] = useState(false);
  const [dropHover3, setDropHover3] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("1");
  const [status, setStatus] = useState("Interviewing");
  const [downloadData, setDownloadData] = useState([]);
  const [date, setDate] = useState("");
  const [dateModal, setDateModal] = useState(false);
  var Date = moment(date).format("DD/MM/YYYY");
  const [interview, setInterview] = useState("Andrew States");
  const [interviewDate, setInterviewDate] = useState("");
  const [interviewModal, setInterviewModal] = useState(false);
  const [screen, setScreen] = useState("");
  const [loader, setLoader] = useState(false);
  const [company, setCompany] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [userDataId, setUserDataId] = useState({
    id: "",
    name: "",
    email: "",
  });
  console.log(userDataId);
  const [interviewDetail, setInterviewDetail] = useState({
    title: "",
    date: "",
    startTime: "",
    endTime: "",
    meetLink: "",
    desc: "",
  });
  console.log(interviewDetail);
  const [received, setReceived] = useState("");
  const [receivedModal, setReceivedModal] = useState(false);
  var receivedDate = moment(received).format("DD/MM/YYYY");
  console.log(receivedDate);
  const location = useLocation();
  const { name } = location?.state||"";

  function handleReceivedOpen() {
    setReceivedModal(!receivedModal);
  }

  function handleDateOpen() {
    setDateModal(!dateModal);
  }
  function handleInterview() {
    setInterviewModal(!interviewModal);
  }
  useEffect(() => {
    if (interview === "Add Interview") {
      setInterviewModal(true);
    }
  }, [interview]);

  const getData = async (id) => {
    setLoader(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await getAllApplication(id);

    const res1 = await getAllByCompany(token);
    if (res?.statusCode === 200) {
      setLoader(false);
      setData(res?.data);
    } else {
      setLoader(false);
    }
    if (res1?.statusCode === 200) {
      setLoader(false);
      setInterviewer(res1?.data);
    } else {
      setLoader(false);
    }
  };

  const optionStyle = {
    color: "#0F172A",
    backgroundColor: "#F1F8FE",
  };

  const handleHover = () => {
    optionStyle.color = "#0F172A";
    optionStyle.backgroundColor = "#E0E7FF";
  };

  const handleLeave = () => {
    optionStyle.color = "#0F172A";
    optionStyle.backgroundColor = "#F1F8FE";
  };

  const handleInterviewOpen = (id, name, email) => {
    setUserDataId({ id, name, email });
    setInterviewModal(true);
  };

  const scheduleIntertiew = async () => {
    if (
      interviewDetail.title !== "" &&
      interviewDetail.startTime !== "" &&
      interviewDetail.endTime !== "" &&
      interviewDetail.desc !== "" &&
      userDataId.email !== "" &&
      received !== ""
    ) {
      setLoader(true);
      const { title, startTime, endTime, desc } = interviewDetail;
      const formattedStartTime = moment(startTime, "HH:mm").format("HH:mm");
      const formattedEndTime = moment(endTime, "HH:mm").format("HH:mm");

      // Combine date with start and end times
      const startDateString = moment(received).format("YYYY-MM-DD");
      const startDateTimeString =
        startDateString + "T" + formattedStartTime + ":00+05:30";
      const endDateTimeString =
        startDateString + "T" + formattedEndTime + ":00+05:30";
      const formattedData = {
        title: title,
        startDateTime: startDateTimeString,
        endDateTime: endDateTimeString,
        desc: desc,
        attendees: [{"email":userDataId.email}],
        location:`/dashboard/table/${id}`
      };
      const res = await updateInterviewStatus(
        { ...interviewDetail, date: moment(received).format("DD/MM/YYYY") },
        userDataId?.id
      );
      if (res?.statusCode === 200) {
        // toast.success("Interview Schedule Success.");
        localStorage.setItem("interviewData", JSON.stringify(formattedData));
        window.location.href = `${baseUrl}/google`;
        setDateModal(false);
        setInterviewModal(false);
        setInterviewDetail({
          title: "",
          date: "",
          startTime: "",
          endTime: "",
          meetLink: "",
          desc: "",
        });
        getData(id);
        setLoader(false);
      } else {
        toast.error("Interview schedule failed.");
        setLoader(false);
      }
    } else {
      toast.info("Please fill in all details.");
      setLoader(false);
    }
  };

  const filterData = () => {
    const update =
      data &&
      data.length > 0 &&
      data.map((e) => {
        // Clean up newline characters from the summary dynamically
        const cleanSummary = e?.userId?.summary
          ? e?.userId?.summary.replace(/\n/g, " ")
          : "";

        return {
          candidate: e?.name || "",
          resume: e?.userId?.resumeUrl || "",
          summary: cleanSummary,
          contact: e?.phone || "",
          mail: e?.email || "",
          city: e?.userId?.city !== "undefined" ? e?.userId?.city : "",
          state: e?.userId?.state !== "undefined" ? e?.userId?.state : "",
          status: e?.status || "",
          score: e?.score || "",
          notes: e?.notes || "",
          interviewtitle: e?.interviewDetail?.title || "",
          interviewdate: e?.interviewDetail?.date || "",
          interviewstartTime: e?.interviewDetail?.startTime || "",
          interviewendTime: e?.interviewDetail?.endTime || "",
          interviewmeetLink: e?.interviewDetail?.meetLink || "",
          interviewdesc: e?.interviewDetail?.desc || "",
          questions:
            e?.screeningQuestions && e?.screeningQuestions?.length > 0
              ? e?.screeningQuestions?.map((q) => ({
                  question: q.question,
                  answer: q.answer,
                }))
              : "",
        };
      });

    setDownloadData(update);
  };

  useEffect(() => {
    filterData();
  }, [data]);

  const downloadFile = () => {
    const csvData = convertToCSV(downloadData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "your_file.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (downloadData) => {
    const header = [
      "candidate",
      "resume",
      "summary",
      "contact",
      "mail",
      "city",
      "state",
      "status",
      "score",
      "notes",
      "interviewtitle",
      "interviewdate",
      "interviewstartTime",
      "interviewendTime",
      "interviewmeetLink",
      "interviewdesc",
      "questions",
    ].join(",");

    const rows = downloadData.map((obj) => {
      const summary = obj.summary
        ? `"${obj.summary.replace(/"/g, '""').replace(/\n/g, " ")}"`
        : "";

      const questionsString = Array.isArray(obj.questions)
        ? obj.questions.map((q) => `${q.question}: ${q.answer}`).join("; ") // Adjust this format as needed
        : "";

      const values = [
        `"${obj.candidate}"`,
        `"${obj.resume}"`,
        summary,
        `"${obj.contact}"`,
        `"${obj.mail}"`,
        `"${obj.city}"`,
        `"${obj.state}"`,
        `"${obj.status}"`,
        `"${obj.score}"`,
        `"${obj.notes}"`,
        `"${obj.interviewtitle}"`,
        `"${obj.interviewdate}"`,
        `"${obj.interviewstartTime}"`,
        `"${obj.interviewendTime}"`,
        `"${obj.interviewmeetLink}"`,
        `"${obj.interviewdesc}"`,
        `"${questionsString}"`,
      ];

      return values.join(",");
    });

    return [header, ...rows].join("\n");
  };

  return (
    <>
      <AlertContainer />
      <RecruiterHeader title={name} search={false} />
      <div className="desktop-show-hide">
        <SubHeader />
      </div>
      {loader && <Loader />}
      <div
        className="recruiter-container desktop-show-hide"
        style={{ paddingRight: "0" }}
      >
        <div className="table-container">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div
              className="d-flex align-items-center"
              onClick={() => navigate(-1)}
            >
              <img src={leftArrow} style={{ marginRight: "8px" }} alt="" />
              <p className="m-0 table-title">{name}</p>
            </div>
            <div style={{ display: "flex" }}>
              <select
                value={interviewerId}
                style={{
                  " -webkit-appearance": "none",
                  "-moz-appearance": "none",
                  backgroundImage: `url(${dropDown})`,
                  backgroundPosition: "right center",
                  backgroundRepeat: "no-repeat",
                  paddingRight: "20px",
                }}
                onChange={handleAssign}
                className="table-selector"
              >
                <option value={""}>Select Interviwer</option>
                {interview &&
                  interview.length > 0 &&
                  interviewer?.map((e, i) => (
                    <option key={e?._id} value={e?._id}>
                      {e?.name}
                    </option>
                  ))}
              </select>
              &nbsp;&nbsp;&nbsp;
              <button className="download-btn" onClick={downloadFile}>
                Download
                <img src={download} style={{ marginLeft: "6px" }} alt="" />
              </button>
            </div>
          </div>
          <div className="table-overflow">
            <table className="table-body" style={{ width: "2000px" }}>
              <thead>
                <tr>
                  <th style={{ width: "165px" }}>Candidate</th>
                  <th style={{ width: "100px" }}>Resume</th>
                  <th style={{ width: "280px" }}>Summary</th>
                  <th>Phone Number</th>
                  <th style={{ width: "170px" }}>Email ID</th>
                  <th style={{ width: "170px" }}>Institute name</th>
                  <th style={{ width: "170px" }}>Current State</th>
                  <th style={{ width: "200px" }}>Screening Question</th>
                  <th style={{ width: "210px" }}>Status</th>
                  <th>Interviewer</th>
                  <th>Schedule Interview</th>
                  <th>Score</th>
                  <th style={{ width: "180px" }}>Feedback</th>
                  <th style={{ width: "180px" }}>Notes</th>
                  <th style={{ width: "180px" }}>Chat</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data.map((e, index) => (
                    <tr>
                      <td className="first-box">{e?.name}</td>
                      <td className="second-box">
                        <a
                          href={e?.resume || e?.userId?.resumeUrl}
                          style={{ color: "#0D9488" }}
                          target={"_blank"}
                        >
                          Open
                        </a>
                      </td>
                      <td className="third-box">
                        <div className="summary-box">{e?.summary || e?.userId?.summary}</div>
                      </td>
                      <td className="four-box">{e?.phone}</td>
                      <td className="five-box">{e?.email}</td>
                      <td className="five-box">
                        {e?.userId?.academic?.length > 0
                          ? e.userId.academic[0]?.instituteName
                          : ""}
                      </td>
                      <td className="four-box">
                        {e?.userId?.state !== "undefined"
                          ? e?.userId?.state
                          : ""}
                      </td>
                      <td className="screen-box">
                        <button
                          style={{
                            borderBottom: "1px solid #000",
                            background: "transparent",
                            border: "none",
                            color: "#0D9488 ",
                            textDecoration: "underline",
                          }}
                          onClick={() => setScreen(e?._id)}
                        >
                          View
                        </button>
                        <ScreenModal
                          id={e?._id}
                          showModal={screen}
                          setShowModal={setScreen}
                          question={e?.screeningQuestions}
                        />
                      </td>
                      <td className="six-box">
                        {/* <div className="table-dropdown">
                          <div
                            onClick={() => {
                              setDropHover2(!dropHover2);
                            }}
                            className="dropdown-btn d-flex"
                          >
                            <p
                              className="m-0"
                              style={{
                                color: "#000",
                                fontFamily: "Inter",
                                backgroundColor:
                                  status === "Interviewing" ? "#F59E0B" : "",
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              <button
                                className="table-select-btn"
                                style={{
                                  backgroundColor:
                                    status === "Interviewing"
                                      ? "#F59E0B"
                                      : "" || status === "Decision needed"
                                      ? "#DBD9D9"
                                      : "" || status === "Hire"
                                      ? "#2DD4BF"
                                      : "" || status === "Not Hire"
                                      ? "#FB7185"
                                      : "",
                                }}
                              >
                                {status}
                              </button>
                            </p>
                            <img src={dropHover2 ? dropDown : dropUp} alt="" />
                          </div>
                          
                          <div
                            className="dropdown-content"
                            style={{
                              display: dropHover2 ? "block" : "none",
                              left: "unset",
                              width: "unset",
                            }}
                          >
                            {Status.map((element, index) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    setStatus(e.target.textContent);
                                    setDropHover2(!dropHover2);
                                  }}
                                  className="item"
                                >
                                  {element.option}
                                </div>
                              );
                            })}
                          </div>
                        </div> */}
                        <select
                          value={e?.status}
                          className="table-select-btn"
                          onChange={(s) => updateStatus(s, e?._id)}
                          style={{
                            backgroundColor:
                              e.status === "INTERVIEWING"
                                ? "#F59E0B"
                                : "" || e.status === "DECISION NEEDED"
                                ? "#DBD9D9"
                                : "" || e.status === "HIRE"
                                ? "#2DD4BF"
                                : "" || e.status === "NOT HIRE"
                                ? "#FB7185"
                                : "",
                            border: "none",
                            outline: "none",
                            " -webkit-appearance": "none",
                            "-moz-appearance": "none",
                            backgroundImage: `url(${dropDown})`,
                            backgroundPosition: "right center",
                            backgroundRepeat: "no-repeat",
                            // paddingRight: "20px",
                          }}
                          name="status"
                        >
                          <option
                            value=""
                            style={{ backgroundColor: "#F1F8FE" }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                          >
                            Select Status
                          </option>
                          {/* {<option value="">Select Status</option>} */}
                          {Status.map((element, index) => {
                            return (
                              <>
                                <option
                                  onMouseEnter={handleHover}
                                  onMouseLeave={handleLeave}
                                  // className="dropdown-content"
                                  value={element.option}
                                >
                                  {element.option}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </td>
                      <td className="seven-box">
                        <p
                          className="m-0"
                          style={{
                            color: "#000",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          {
                            JSON.parse(localStorage.getItem("recruiterData"))
                              ?.name
                          }
                        </p>
                        {/* <div className="table-dropdown">
                      <div
                        onClick={() => {
                          setDropHover3(!dropHover3);
                        }}
                        className="dropdown-btn d-flex"
                      >
                        <p
                          className="m-0"
                          style={{
                            color: "#000",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          <button>{interview}</button>
                        </p>
                        <img src={dropHover3 ? dropDown : dropUp} alt="" />
                      </div> */}
                        {/* <div
                        className="dropdown-content"
                        style={{
                          display: dropHover3 ? "block" : "none",
                          left: "unset",
                          width: "unset",
                        }}
                      >
                        {hiring.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setInterview(e.target.textContent);
                                setDropHover3(!dropHover3);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div> */}
                        {/* </div> */}
                      </td>
                      <td className="eight-box">
                        <a
                          onClick={() =>
                            handleInterviewOpen(
                              e?._id,
                              e?.name,
                              e?.userId?.email
                            )
                          }
                          style={{ textDecoration: "underline" }}
                        >
                          {!e?.interviewDetail?.date
                            ? "Set"
                            : e?.interviewDetail?.date}
                        </a>
                      </td>
                      <td className="eight-box">
                        <select
                          name="score"
                          value={e?.score}
                          style={{
                            border: "none",
                            outline: "none",
                            appearance: "none",
                            " -webkit-appearance": "none",
                            "-moz-appearance": "none",
                            backgroundImage: `url(${dropDown})`,
                            backgroundPosition: "right center",
                            backgroundRepeat: "no-repeat",
                            paddingRight: "20px",
                          }}
                          onChange={(s) => updateStatus(s, e?._id)}
                        >
                          <option value="">Select Score</option>
                          {selectItem.map((item) => (
                            <option
                              // selected={item.status === e.option}
                              value={item.option}
                            >
                              {item.option}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="nine-box">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => setShowModal(e?._id)}
                        >
                          {/* <ShowStars feedback={item?.feedback} /> */}
                          {/* <td className="second-box p-0 ">Given feedback</td> */}
                          <td
                            className="second-box p-0"
                            style={{ color: "#0D9488" }}
                          >
                            Give feedback
                          </td>
                        </div>
                        <RecruiterModal
                          id={e?._id}
                          getData={getData}
                          feedback={e?.feedback}
                          interviewId={id}
                          showModal={showModal}
                          setShowModal={setShowModal}
                          recruiterId={e?.recruiterId}
                          userId={e?.userId?._id}
                          username={e?.name}
                        />
                      </td>
                      <td className="ten-box">
                        <input
                          type="text"
                          name="notes"
                          defaultValue={e?.notes}
                          onBlur={(s) => updateStatus(s, e?._id)}
                        />
                      </td>
                      <td
                        className="ten-box"
                        style={{
                          textDecoration: "underline",
                          color: "#0D9488",
                        }}
                        onClick={() => setToggle(index)}
                      >
                        Chat
                      </td>
                      {toggle === index && (
                        <InterviewChat
                          setToggle={setToggle}
                          userId={e?.userId?._id}
                          name={e?.name}
                          company={e?.jobId?.companyName}
                        />
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="recruiter-container mobile-show-hide"
        style={{ marginTop: "80px" }}
      >
        {/* <div className="table-box"> */}
        {/* <div
            className="d-flex justify-content-between align-items-center"
            style={{ marginBottom: "16px" }}
          >
            <h6 className="table-title m-0">{name}</h6>
            <img src={downloadImg} alt="" onClick={downloadFile} />
          </div> */}
        <div className="d-flex flex-column" style={{ gap: "8px" }}>
          {data?.length > 0 &&
            data?.map((item, index) => (
              <div key={index} className="table-card">
                <h6 className="table-card-title">{item?.name}</h6>
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 className="m-0 table-card-email">{item?.email}</h6>
                    <h6 className="m-0 table-card-email p-0">{item?.phone}</h6>
                  </div>
                  <div>
                    <p className="table-card-date m-0">Interview Date</p>
                    <p className="table-card-date-text m-0">
                      <a
                        onClick={() =>
                          handleInterviewOpen(
                            item?._id,
                            item?.name,
                            item?.userId?.email
                          )
                        }
                      >
                        {!item?.interviewDetail?.date
                          ? "Set"
                          : item?.interviewDetail?.date}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="table-card-border"></div>
                <p className="table-card-summary m-0">Summary:</p>
                <p className="table-card-summary-text m-0">
                  //
                  <input
                    type="text"
                    name="notes"
                    defaultValue={item?.userId?.summary}
                    onBlur={(s) => updateStatus(s, item?._id)}
                  />
                </p>
                <div className="table-card-border"></div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 table-card-status">STATUS:</p>
                  <select
                    value={item?.status}
                    className="table-select-btn"
                    onChange={(s) => updateStatus(s, item?._id)}
                    style={{
                      backgroundColor:
                        item.status === "INTERVIEWING"
                          ? "#F59E0B"
                          : "" || item.status === "DECISION NEEDED"
                          ? "#DBD9D9"
                          : "" || item.status === "HIRE"
                          ? "#2DD4BF"
                          : "" || item.status === "NOT HIRE"
                          ? "#FB7185"
                          : "",
                      border: "none",
                      outline: "none",
                      " -webkit-appearance": "none",
                      "-moz-appearance": "none",
                      appearance: "none",
                      backgroundImage: `url(${dropDown})`,
                      backgroundPosition: "right center",
                      backgroundRepeat: "no-repeat",
                      paddingRight: "20px",
                    }}
                    name="status"
                  >
                    {/* <option
                            value=""
                            style={{ backgroundColor: "#F1F8FE" }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                          >
                            Select Status
                          </option> */}
                    {Status.map((element, index) => {
                      return (
                        <>
                          <option
                            onMouseEnter={handleHover}
                            onMouseLeave={handleLeave}
                            // className="dropdown-content"
                            value={element.option}
                          >
                            {element.option}
                          </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <button
                  className="view-profile-btn"
                  onClick={() =>
                    navigate(`/dashboard/table/view-profile/${item?._id}`)
                  }
                >
                  View Profile
                </button>
              </div>
            ))}
        </div>
        <RecruiterSubMenu />
        <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
          <img
            src={top}
            alt=""
            style={{
              transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 150ms ease",
              height: "14px",
            }}
          />
          <img src={jobIcon} style={{ height: "24px" }} alt="" />
        </div>
        {/* </div> */}
      </div>
      <div className="bottom-btn mobile-show-hide">
        <div className="d-flex flex-column">
          <button className="save-btn" onClick={downloadFile}>
            <img src={downloadImg} style={{ height: "24px" }} alt="" />
            Download
          </button>
          <button className="skip-btn" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>
      </div>
      {dateModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleDateOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date</h6>
              <div className="calender">
                <Calendar onChange={setDate} value={date} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {receivedModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleReceivedOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar
                  onChange={(date) => {
                    setReceived(date);
                    handleReceivedOpen();
                  }}
                  value={received}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {interviewModal ? (
        <div className="interview-modal">
          <div className="interview-box">
            <button className="close-btn" onClick={handleInterview}>
              <img src={closeBtn} alt="" />
            </button>
            <h6 className="interview-modal-title m-0">Schedule Interview</h6>
            <div className="d-flex">
              <p className="m-0 interview-modal-to">
                to: <span className="to-span">{userDataId?.name}</span>
              </p>
            </div>
            <div className="w-100">
              <p className="m-0 interview-input-title">Add title</p>
              <input
                type="text"
                className="interview-input"
                placeholder="e.g. FIrst round interview with Tech lead"
                value={interviewDetail?.title}
                onChange={(e) =>
                  setInterviewDetail({
                    ...interviewDetail,
                    title: e.target.value,
                  })
                }
              />
            </div>
            <div className="w-100">
              <p className="m-0 interview-input-title">Interview Type</p>
              <div
                className="d-flex align-items-center"
                style={{ gap: "12px" }}
              >
                <button className="active-item">
                  <img src={Video} alt="" /> Video Call
                </button>
                {/* <button className="inactive-item">
                  <img src={call} alt="" /> Phone
                </button>
                <button className="inactive-item">
                  <img src={office} alt="" /> In Office
                </button> */}
              </div>
            </div>
            <div className="w-100">
              <p className="m-0 interview-input-title">Interview Date </p>
              <div className="interview-calender" onClick={handleReceivedOpen}>
                <input
                  placeholder={"Select Date"}
                  style={{ border: "none", outline: "none" }}
                  type="text"
                  value={receivedDate == "Invalid date" ?"Select Date": receivedDate }
                />
                <img src={CalenderImg} className="input-calender" alt="" />
              </div>
            </div>
            <div className="w-100">
              <p className="m-0 interview-input-title">
                Interview Time{" "}
                <span
                  style={{
                    color: "#4D4D4D",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                >
                  IST (+5:30 hours GMT)
                </span>
              </p>
              <div
                className="d-flex align-items-center"
                style={{ gap: "12px" }}
              >
                <input
                  type="time"
                  className="interview-input"
                  placeholder="Select start time"
                  value={interviewDetail?.startTime}
                  onChange={(e) => {
                    setInterviewDetail({
                      ...interviewDetail,
                      startTime: e.target.value,
                    });
                  }}
                />
                to
                <input
                  type="time"
                  className="interview-input"
                  placeholder="Select End time"
                  value={interviewDetail?.endTime}
                  onChange={(e) =>
                    setInterviewDetail({
                      ...interviewDetail,
                      endTime: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            {/* <div className="w-100">
              <p className="m-0 interview-input-title">Share video call link</p>
              <input
                type="text"
                className="interview-input"
                placeholder="e.g. https://meet.google.com/dwr-pduw-htr"
                value={interviewDetail?.meetLink}
                onChange={(e) =>
                  setInterviewDetail({
                    ...interviewDetail,
                    meetLink: e.target.value,
                  })
                }
              />
            </div> */}
            {/* <p
              className="m-0"
              style={{
                color: "#4D4D4D",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              Unable to create & share video call link?{" "}
              <span
                style={{
                  color: "#11274B",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "18px",
                }}
              >
                Need help
              </span>
            </p> */}
            <div className="w-100">
              <p className="m-0 interview-input-title">
                Add description & Contact number
              </p>
              <textarea
                name=""
                id=""
                style={{ resize: "none" }}
                placeholder="Send the message to the candidate"
                className="interview-input"
                cols="30"
                rows="3"
                value={interviewDetail?.desc}
                onChange={(e) =>
                  setInterviewDetail({
                    ...interviewDetail,
                    desc: e.target.value,
                  })
                }
              ></textarea>
            </div>
            <button className="send-invite" onClick={scheduleIntertiew}>
              Send Invite
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Table;
