import React, { useState } from "react";
import Header from "../components/header/Header";
import Light from "../assets/recruiter/right-icon.svg";
import Dark from "../assets/right-light.png";
import "./dashboard.css";

function UpgradePage() {
  const [radio, setRadio] = useState("Monthly");
  return (
    <>
      <Header />
      <section className="upgrade-hero">
        <div className="mobile-upgrade-box">
          <h6 className="mobile-upgrade-title">Upgrade to Plan [name]</h6>
          <p className="mobile-upgrade-text">
            Get hired faster with better opportunities
          </p>
          <div className="d-flex justify-content-center">
            <div className="radio-button" style={{ marginLeft: "8px" }}>
              <input
                type="radio"
                className="radio-button__input"
                id="radio1"
                onChange={(e) => setRadio(e.target.value) }
                value="Monthly"
                name="Sort by"
              />
              <label className="upgrade-radio-button" htmlFor="radio1">
                <span className="radio-button__custom"></span>
                Monthly
              </label>
            </div>
            <div className="radio-button" style={{ marginLeft: "8px" }}>
              <input
                type="radio"
                className="radio-button__input"
                id="radio2"
                onChange={(e) => setRadio(e.target.value) }
                value="Yearly"
                name="Sort by"
              />
              <label className="upgrade-radio-button" htmlFor="radio2">
                <span className="radio-button__custom"></span>
                Yearly
              </label>
            </div>
          </div>
        </div>
        {radio === "Monthly" ? 
        (
          <>
        <div className="mobile-upgrade-box-two">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="upgrade-box-two-title m-0">Premium</h6>
            <p className="upgrade-box-two-text m-0">499/-</p>
          </div>
          <div className="upgrade-box-two-border"></div>
          <p className="upgrade-text-two-bold m-0">
            <img src={Dark} alt="" /> Apply to Unlimited Jobs
          </p>
          <p className="upgrade-text-two-bold m-0">
            <img src={Dark} alt="" /> Premium Resume Templates
          </p>
          <p className="upgrade-text-two-bold m-0">
            <img src={Dark} alt="" /> Career Counseling
          </p>
          <p className="upgrade-text-two-bold m-0">
            <img src={Dark} alt="" /> Feedback and Analysis
          </p>
          <p className="upgrade-text-two-bold m-0">
            <img src={Dark} alt="" /> WhatsApp Updates
          </p>
          <button className="upgrade-text-two-btn">Upgrade to Premium</button>
        </div>
        <div className="mobile-upgrade-box-three">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="upgrade-box-three-title m-0">Basic</h6>
            <p className="upgrade-box-three-text m-0">299/-</p>
          </div>
          <div className="upgrade-box-three-border"></div>
          <p className="upgrade-text-three-bold m-0">
            <img src={Light} alt="" /> Apply to 20 Jobs
          </p>
          <p className="upgrade-text-three-bold m-0">
            <img src={Light} alt="" /> 5 premium resume templates
          </p>
          <p className="upgrade-text-three-bold m-0">
            <img src={Light} alt="" /> Career Counselling
          </p>
          <p className="upgrade-text-three m-0">
            <span className="ms-3"></span> WhatsApp Updates
          </p>
          <p className="upgrade-text-three m-0">
            <span className="ms-3"></span> Feedback & Analysis
          </p>
          <button className="upgrade-text-three-btn">Upgrade to Basic</button>
        </div>
        <div className="mobile-upgrade-box-one">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="upgrade-box-title m-0">Starter</h6>
            <p className="upgrade-box-text m-0">199/-</p>
          </div>
          <div className="upgrade-box-border"></div>
          <p className="upgrade-text-one-bold m-0">
            <img src={Light} alt="" /> Apply to 20 Jobs
          </p>
          <p className="upgrade-text-one-bold m-0">
            <img src={Light} alt="" /> 5 premium resume templates
          </p>
          <p className="upgrade-text-one m-0">
            <span className="ms-3"></span> Career Counselling
          </p>
          <p className="upgrade-text-one m-0">
            <span className="ms-3"></span> WhatsApp Updates
          </p>
          <p className="upgrade-text-one m-0">
            <span className="ms-3"></span> Feedback & Analysis
          </p>
          <button className="upgrade-starter-btn">Upgrade to Starter</button>
        </div>
        </>
        ) : (
          <>
          <div className="mobile-upgrade-box-two">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="upgrade-box-two-title m-0">Premium</h6>
            <p className="upgrade-box-two-text m-0">4999/-</p>
          </div>
          <div className="upgrade-box-two-border"></div>
          <p className="upgrade-text-two-bold m-0">
            <img src={Dark} alt="" /> Apply to Unlimited Jobs
          </p>
          <p className="upgrade-text-two-bold m-0">
            <img src={Dark} alt="" /> Premium Resume Templates
          </p>
          <p className="upgrade-text-two-bold m-0">
            <img src={Dark} alt="" /> Career Counseling
          </p>
          <p className="upgrade-text-two-bold m-0">
            <img src={Dark} alt="" /> Feedback and Analysis
          </p>
          <p className="upgrade-text-two-bold m-0">
            <img src={Dark} alt="" /> WhatsApp Updates
          </p>
          <button className="upgrade-text-two-btn">Upgrade to Premium</button>
        </div>
        <div className="mobile-upgrade-box-three">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="upgrade-box-three-title m-0">Basic</h6>
            <p className="upgrade-box-three-text m-0">2999/-</p>
          </div>
          <div className="upgrade-box-three-border"></div>
          <p className="upgrade-text-three-bold m-0">
            <img src={Light} alt="" /> Apply to 20 Jobs
          </p>
          <p className="upgrade-text-three-bold m-0">
            <img src={Light} alt="" /> 5 premium resume templates
          </p>
          <p className="upgrade-text-three-bold m-0">
            <img src={Light} alt="" /> Career Counselling
          </p>
          <p className="upgrade-text-three m-0">
            <span className="ms-3"></span> WhatsApp Updates
          </p>
          <p className="upgrade-text-three m-0">
            <span className="ms-3"></span> Feedback & Analysis
          </p>
          <button className="upgrade-text-three-btn">Upgrade to Basic</button>
        </div>
        <div className="mobile-upgrade-box-one">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="upgrade-box-title m-0">Starter</h6>
            <p className="upgrade-box-text m-0">1999/-</p>
          </div>
          <div className="upgrade-box-border"></div>
          <p className="upgrade-text-one-bold m-0">
            <img src={Light} alt="" /> Apply to 20 Jobs
          </p>
          <p className="upgrade-text-one-bold m-0">
            <img src={Light} alt="" /> 5 premium resume templates
          </p>
          <p className="upgrade-text-one m-0">
            <span className="ms-3"></span> Career Counselling
          </p>
          <p className="upgrade-text-one m-0">
            <span className="ms-3"></span> WhatsApp Updates
          </p>
          <p className="upgrade-text-one m-0">
            <span className="ms-3"></span> Feedback & Analysis
          </p>
          <button className="upgrade-starter-btn">Upgrade to Starter</button>
        </div>
        </>
        )
        }
      </section>
    </>
  );
}

export default UpgradePage;
