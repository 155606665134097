import React, { useEffect, useState } from "react";
import box from "../assets/office-building.png";
import left from "../assets/left-arrow.svg";
import save from "../assets/sideBar/save.svg";
import save2 from "../assets/save-btn.svg";
import { useNavigate } from "react-router-dom";
import { useMain } from "../hook/useMain";
import "./dashboard.css";
import Loader from "../components/loader/Loader";
import { map } from "jquery";
import { toast } from "react-toastify";

function BusinessCard({ data, filter, search, location, dropdownData }) {
  // const [data, setData] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { getAllUserPost, saveJob } = useMain();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));

  // const getData = async () => {
  //   setLoader(true);
  //   const res = await getAllUserPost();

  //   if (res?.statusCode === 200) {
  //     setLoader(false);
  //     if (userData) {
  //       const initialData = [];

  //       const filteredData = res?.data?.data?.filter((item) => {
  //         // Assuming jobInfo is an object inside the item
  //         const jobInfo = item.jobInfo;

  //         // Check if the conditions match
  //         const isCityIndore = item.headQuater === userData?.city;
  //         const isJobTitleDeveloper = jobInfo.position
  //           .toLowerCase()
  //           .includes(userData?.jobTitle);
  //         const hasJobPreference =
  //           jobInfo?.jobType &&
  //           jobInfo?.jobType
  //             .toLowerCase()
  //             .includes(
  //               userData?.jobPreference.map((item) => item.toLowerCase())
  //             );

  //         if (isCityIndore || isJobTitleDeveloper || hasJobPreference) {
  //           return isCityIndore || isJobTitleDeveloper || hasJobPreference;
  //         } else {
  //           initialData.push(item);
  //         }
  //       });

  //       // Apply the custom sort function to the data array

  //       setData([...filteredData, ...initialData]);
  //       setFilteredData([...filteredData, ...initialData]);
  //     }
  //   } else {
  //     // alert("Somthing went wrong");
  //     setLoader(false);
  //   }
  // };

  // useEffect(() => {
  //   // Reset to show all data when both search and location queries are empty
  //   if (search.trim() === "" && location.trim() === "") {
  //     setFilteredData(data);
  //   } else {
  //     const filtered = data?.filter((job) => {
  //       const searchFilter =
  //         search.trim() === "" ||
  //         Object.values(job.jobInfo).some((value) =>
  //           String(value).toLowerCase().includes(search.toLowerCase())
  //         );

  //       const locationFilter =
  //         location.trim() === "" ||
  //         String(job.jobInfo.workPlace)
  //           .toLowerCase()
  //           .includes(location.toLowerCase());

  //       return searchFilter && locationFilter;
  //     });

  //     setFilteredData(filtered);
  //   }
  // }, [filter]);

  //dropdown 1 ************
  //**************** */
  // useEffect(() => {
  //   let currentDate = new Date();
  //   const oneWeekAgo = new Date();
  //   oneWeekAgo.setDate(
  //     oneWeekAgo.getDate() - dropdownData?.dropdown2 === "Past Week"
  //       ? 7
  //       : dropdownData?.dropdown2 === "Last 2 months"
  //       ? 60
  //       : null
  //   );

  //   const filterData = data.filter(
  //     (e) =>
  //       Object.values(e.jobInfo).some((value) =>
  //         String(value)
  //           .toLowerCase()
  //           .includes(dropdownData.dropdown1.toLowerCase())
  //       ) ||
  //       Object.values(e.jobInfo).some((value) =>
  //         String(value)
  //           .toLowerCase()
  //           .includes(dropdownData.dropdown3.toLowerCase())
  //       ) ||
  //       Object.values(e.jobInfo).some((value) =>
  //         String(value)
  //           .toLowerCase()
  //           .includes(dropdownData.dropdown5.toLowerCase())
  //       ) ||
  //       (new Date(e.createdAt) >= oneWeekAgo &&
  //         new Date(e.createdAt) <= currentDate)
  //   );

  //   setFilteredData(filterData);
  // }, [dropdownData]);

  // useEffect(() => {
  //   getData();
  // }, []);

  const handleSave = async (jobid) => {
    setLoader(true);
    const { _id } = JSON.parse(localStorage.getItem("userData"));
    if (_id && jobid) {
      const res = await saveJob(jobid, { userId: _id });
      if (res?.statusCode == 200) {
        toast.success("Job saved successfully.");

        localStorage.setItem("userData", JSON.stringify(res?.data));
        setLoader(false);
      } else {
        toast.error("Failed to save job.");

        setLoader(false);
      }
    }
  };

  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  function handleOnApply(jobId) {
    navigate(`/dashboard/job-description/${jobId}`);
  }

  const formatToCurrency = (number) => {
    if (number >= 1000 && number < 10000) {
      return `₹${number}`;
    } else if (number >= 100000 && number < 10000000) {
      return `₹${(number / 100000).toFixed(0)}L`;
    } else if (number >= 10000000) {
      return `₹${(number / 100000).toFixed(0)}C`;
    } else {
      return `₹${number}`;
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        data?.length > 0 &&
        data?.map((job, index) => (
          <div className="business-card" key={index}>
            <button className="business-actively">Actively hiring</button>
            <div className="d-flex align-items-center">
              <img
                src={job?.userId?.companyLogo ? job?.userId?.companyLogo : box}
                alt=""
                style={{ height: "25px", width: "25px" }}
              />
              <div className="ps-3">
                <h6 className="business-title m-0">{job?.jobInfo?.position}</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="business-text m-0">{job?.companyName}</p>
                  <p className="business-text m-0 ms-5">
                    {timeAgo(job.createdAt)}
                  </p>
                </div>
              </div>
            </div>
            <div className="business-card-border"></div>
            <ul className="pl-2 m-0">
              {/* <li className="save-item">Start up</li> */}
              <li className="save-item">{job?.jobInfo?.jobType}</li>
              {/* <li className="save-item">11-{job?.companyStrength} employees</li> */}
              <li className="save-item">{job?.jobInfo?.workPlace}</li>

              {job?.jobInfo?.salaryMax && job?.jobInfo?.salaryMin && (
                <li className="save-item">
                  {formatToCurrency(Number(job?.jobInfo?.salaryMin))} -
                  {formatToCurrency(Number(job?.jobInfo?.salaryMax))}
                </li>
              )}
            </ul>
            <div className="d-flex align-items-center justify-content-between">
              <div
                className="apply-btn"
                style={{
                  backgroundColor: userData?.appliedjobs.includes(job?._id)
                    ? "green"
                    : null,
                  color: userData?.appliedjobs.includes(job?._id)
                    ? "white"
                    : null,
                }}
                onClick={() => handleOnApply(job._id)}
              >
                <button style={{color: userData?.appliedjobs.includes(job?._id)
                    ? "white"
                    : null,}}>
                  {userData?.appliedjobs.includes(job?._id)
                    ? "Applied"
                    : "Apply"}
                </button>
                <img src={left} alt="" />
              </div>

              <button className="save-btn" onClick={() => handleSave(job?._id)}>
                <img
                  src={userData?.savedjobs.includes(job?._id) ? save2 : save}
                  alt=""
                />
              </button>
            </div>
          </div>
        ))
      )}
    </>
  );
}

export default BusinessCard;

//  // dropdown 1 ************
//   //**************** */
//   useEffect(() => {
//     const filtered = data?.filter((job) => {
//       const searchFilter = Object.values(job.jobInfo).some((value) =>
//         String(value).toLowerCase().includes(dropdown1.toLowerCase())
//       );

//       return searchFilter;
//     });

//     console.log(filtered);
//     setFilteredData(filtered);
//   }, [dropdown1]);

//   //dropdown 2*********************
//   //**************************** */
//   useEffect(() => {
//     const currentDate = new Date();

//     // Get the date one week ago
//     const oneWeekAgo = new Date();
//     const onOneMontsAgo = new Date();
//     const onTowMontsAgo = new Date();
//     oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
//     onOneMontsAgo.setDate(oneWeekAgo.getDate() - 60);
//     onTowMontsAgo.setDate(oneWeekAgo.getDate() - 60);

//     if (dropdown2 === "Any Time") {
//       setFilteredData(data);
//     }

//     if (dropdown2 === "Past Week") {
//       // Use the filter method to get jobs created in the past week
//       const jobsFromPastWeek = data?.filter((job) => {
//         const createdAtDate = new Date(job.createdAt);
//         return createdAtDate >= oneWeekAgo && createdAtDate <= currentDate;
//       });
//       setFilteredData(jobsFromPastWeek);
//     }

//     if (dropdown2 === "Most Relevant") {
//       // Use the filter method to get jobs created in the past week
//       const jobsFromPastWeek = data?.filter((job) => {
//         const createdAtDate = new Date(job.createdAt);
//         return createdAtDate >= onOneMontsAgo && createdAtDate <= currentDate;
//       });
//       setFilteredData(jobsFromPastWeek);
//     }

//     if (dropdown2 === "Last 2 months") {
//       // Use the filter method to get jobs created in the past week
//       const jobsFromPastWeek = data?.filter((job) => {
//         const createdAtDate = new Date(job.createdAt);
//         return createdAtDate >= onTowMontsAgo && createdAtDate <= currentDate;
//       });
//       setFilteredData(jobsFromPastWeek);
//     }
//   }, [dropdown2]);

//   // dropdown 3***********************
//   //******************************* */

//   useEffect(() => {}, []);
