import React, { useEffect, useState } from "react";
import "./recruiter.css";
import Header from "../header/Header";
import SubHeader from "../subHeader/SubHeader";
// import LeftArrow from "../../assets/recruiter/left-arrow.svg";
import ProfileDelete from "../../assets/recruiter/profile-delete.svg";
import downArrow from "../../assets/select-down.svg";
import close from "../../assets/close-btn.svg";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
import { useParams, useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";
import RecruiterHeader from "../header/RecruiterHeader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";

const JobPre = [
  {
    id: 1,
    option: "Internship",
    selected: false,
  },
  {
    id: 2,
    option: "Full-Time",
    selected: false,
  },
  {
    id: 3,
    option: "Part-Time",
    selected: false,
  },
  {
    id: 4,
    option: "Remote",
    selected: false,
  },
  {
    id: 5,
    option: "Hybrid",
    selected: false,
  },
];

const initialValue = {
  subcategories: [],
  job: [],
};

function ProfileDetail() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, []);
  const {
    singleInterviewer,
    deleteInterviewer,
    allPostByRecruiter,
    assignInterviewer,
  } = useMain();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState(initialValue);
  const [selected, setIsSelected] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [recruiterPosts, setRecruiterPosts] = useState([]);
  const [postId, setPostId] = useState([]);

  const getData = async (id) => {
    setLoader(true);
    const res = await singleInterviewer(id);
    if (res?.statusCode === 200) {
      setLoader(false);
      setData(res?.data);
    } else {
      setLoader(false);
    }
  };

  const handleDelete = async (id) => {
    setLoader(true);
    const res = await deleteInterviewer(id);
    if (res?.statusCode == 200) {
      toast.success("Interviewer deleted successfully.");
      navigate("/dashboard/all-interviews");
      setLoader(false);
    } else {
      toast.error("Interviewer deletion failed.");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (selected.trim() !== "") {
      if (!formData?.subcategories?.includes(selected))
        setFormData({
          ...formData,
          subcategories: [...formData?.subcategories, selected],
        });
    }
  }, [selected]);
  const handleDeleteSubcategory = (item) => {
    const subcategories = formData?.subcategories?.filter(
      (s, id) => id !== item
    );
    const deleteId = postId.filter((_, id) => id !== item);
    setPostId(deleteId);
    setFormData({ ...formData, subcategories });
    setIsSelected("");
  };

  useEffect(() => {
    recruiterPost();
  }, []);

  const recruiterPost = async () => {
    setLoader(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await allPostByRecruiter(token);
    if (res?.statusCode === 200) {
      setRecruiterPosts(res?.data?.data);
      setLoader(false);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };

  const assignPost = async () => {
    if (postId.length > 0) {
      try {
        setLoader(true);
        const token = localStorage.getItem("recruiterToken");
        const Id = [...new Set(postId)];
        const result = await assignInterviewer(id, Id, token);

        if (result?.statusCode == 200) {
          // Handle success, e.g., update state, show a message, etc.
          toast.success(result?.message);
          setLoader(false);
          setPostId([]);
          setFormData(initialValue);
          setIsSelected("");
        } else if (result?.statusCode === 500) {
          // Handle error, e.g., show an error message
          toast.error(result?.message);
          setLoader(false);
          setPostId([]);
          setFormData(initialValue);
          setIsSelected("");
        }
        setLoader(false);
        setPostId([]);
        setFormData(initialValue);
        setIsSelected("");
      } catch (error) {
        setLoader(false);
        setPostId([]);
        setFormData(initialValue);
        setIsSelected("");
      }
    }
  };

  return (
    <>
      <AlertContainer />
      <RecruiterHeader />
      <div className="desktop-show-hide">
        <SubHeader />
      </div>
      {loader && <Loader />}

      <div className="desktop-show-hide">
        <div className="recruiter-container">
          <div className="profile-detail-box">
            <div className="profile-detail-content">
              <div className="d-flex justify-content-between w-100 align-items-center">
                <p className="back-profile-btn">
                  {/* <img src={LeftArrow} style={{ marginRight: "8px" }} alt="" /> */}
                  Profile Details
                </p>
                <p
                  className="back-profile-btn"
                  style={{ color: "#64748B", cursor: "pointer" }}
                  onClick={() => handleDelete(data?._id)}
                >
                  <img
                    src={ProfileDelete}
                    style={{ marginRight: "8px" }}
                    alt=""
                  />
                  Delete Profile
                </p>
              </div>
              <div className="profile-detail">
                <div className="profile-name">
                  <h6 className="profile-title">Name</h6>
                  <p className="profile-text">{data?.name}</p>
                </div>
                <div className="profile-name">
                  <h6 className="profile-title">Mobile Number</h6>
                  <p className="profile-text">{data?.mobileNo}</p>
                </div>
                <div className="profile-name">
                  <h6 className="profile-title">WOrk Email ID</h6>
                  <p className="profile-text">{data?.email}</p>
                </div>
                <div className="profile-name">
                  <h6 className="profile-title">Team/Department</h6>
                  <p className="profile-text">{data?.department}</p>
                </div>
              </div>
            </div>
            <div className="profile-center-border"></div>
            <div className="profile-detail-content">
              <div>
                <p className="back-profile-btn">Log In Credentials</p>
                {/* <p className="back-profile-text">
                  Make sure to use updated resume
                </p> */}
              </div>
              <div className="profile-detail">
                <div className="profile-name">
                  <h6 className="profile-title">USER ID</h6>
                  <p className="profile-text">{data?.email}</p>
                </div>
                <div className="profile-name">
                  <h6 className="profile-title">Password</h6>
                  <p className="profile-text">{data?.password2}</p>
                </div>
              </div>
            </div>
            <div className="profile-center-border"></div>
            <div className="profile-detail-content">
              <div>
                <p className="back-profile-btn">Assigned Interviewes</p>
                <p className="back-profile-text">
                  Send interview collaborations to your colleagues for the job
                  posting
                </p>
              </div>
              <div
                className="d-flex flex-column profile-detail-"
                style={{ width: "300px" }}
              >
                <label htmlFor="" className="assign-job-label">
                  Job Posting
                </label>
                <div
                  className="dropdown"
                  onMouseEnter={() => setHover1(true)}
                  onMouseLeave={() => setHover1(false)}
                  style={{ marginBottom: "8px" }}
                >
                  <div
                    onClick={(e) => {
                      setIsActive(!isActive);
                    }}
                    style={{ outline: isActive ? "2px solid #94A3B8" : "none" }}
                    className="dropdown-btn"
                  >
                    Choose Job Posting
                    <img src={hover1 ? downArrowHover : downArrow} alt="" />
                  </div>
                  <div
                    className="dropdown-content"
                    style={{ display: isActive ? "block" : "none" }}
                  >
                    {recruiterPosts?.map((element, i) => {
                      return (
                        <div
                          key={i}
                          onClick={(e) => {
                            setIsSelected(e.target.textContent);
                            setPostId([...postId, element?._id]);
                            setIsActive(!isActive);
                          }}
                          className="item"
                        >
                          {element.jobInfo.position}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {formData?.subcategories?.length > 0 && (
                  <div className="d-flex">
                    {formData?.subcategories?.map((item, index) => (
                      <p className="select-option" key={index}>
                        {item}
                        <img
                          src={close}
                          style={{ marginLeft: "4px" }}
                          alt=""
                          onClick={() => {
                            handleDeleteSubcategory(index);
                          }}
                        />
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className="d-flex" style={{ gap: "14px" }}>
                <button onClick={assignPost} className="save-btn-">
                  Save
                </button>
                <button
                  className="edit-btn"
                  onClick={() =>
                    navigate(`/dashboard/add-new-hire/${data?._id}`)
                  }
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="mobile-show-hide">
        <div
          className="recruiter-container"
          style={{ marginTop: "60px", height: "100vh" }}
        >
          <div className="profile-mobile-box">
            <div className="profile-detail-content">
              <div className="d-flex justify-content-between w-100 align-items-center">
                <p className="back-profile-btn">Profile Details</p>
              </div>
              <div className="profile-detail">
                <div className="profile-name">
                  <h6 className="profile-title">Name</h6>
                  <p className="profile-text">{data?.name}</p>
                </div>
                <div className="profile-name">
                  <h6 className="profile-title">Mobile Number</h6>
                  <p className="profile-text">{data?.mobileNo}</p>
                </div>
                <div className="profile-name">
                  <h6 className="profile-title">WOrk Email ID</h6>
                  <p className="profile-text">{data?.email}</p>
                </div>
                <div className="profile-name">
                  <h6 className="profile-title">Team/Department</h6>
                  <p className="profile-text">{data?.department}</p>
                </div>
              </div>
            </div>
            <div className="profile-detail-content">
              <div>
                <p className="back-profile-btn">Log In Credentials</p>
                <p className="back-profile-text">
                  Make sure to use updated resume
                </p>
              </div>
              <div className="profile-detail">
                <div className="profile-name">
                  <h6 className="profile-title">USER ID</h6>
                  <p className="profile-text">{data?.email}</p>
                </div>
                <div className="profile-name">
                  <h6 className="profile-title">Password</h6>
                  <p className="profile-text">{data?.password2}</p>
                </div>
              </div>
            </div>
            <div className="profile-detail-content">
              <div>
                <p className="back-profile-btn">Assigned Interviewes</p>
                <p className="back-profile-text">
                  Send interview collaborations to your colleagues for the job
                  posting
                </p>
              </div>
              <div
                className="d-flex flex-column profile-detail-"
                style={{ width: "300px" }}
              >
                <label htmlFor="" className="assign-job-label">
                  Job Posting
                </label>
                <div
                  className="dropdown"
                  onMouseEnter={() => setHover1(true)}
                  onMouseLeave={() => setHover1(false)}
                  style={{ marginBottom: "0" }}
                >
                  <div
                    onClick={(e) => {
                      setIsActive(!isActive);
                    }}
                    style={{ outline: isActive ? "2px solid #94A3B8" : "none" }}
                    className="dropdown-btn"
                  >
                    Choose Job Posting
                    <img src={hover1 ? downArrowHover : downArrow} alt="" />
                  </div>
                  <div
                    className="dropdown-content"
                    style={{ display: isActive ? "block" : "none" }}
                  >
                    {recruiterPosts.map((element, i) => {
                      return (
                        <div
                          key={i}
                          onClick={(e) => {
                            setIsSelected(e.target.textContent);
                            setPostId([...postId, element?._id]);
                            setIsActive(!isActive);
                          }}
                          className="item"
                        >
                          {element.jobInfo.position}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {formData?.subcategories?.length > 0 && (
                  <div className="d-flex flex-wrap">
                    {formData?.subcategories?.map((item, index) => (
                      <p className="select-option" key={index}>
                        {item}
                        <img
                          src={close}
                          style={{ marginLeft: "4px" }}
                          alt=""
                          onClick={() => handleDeleteSubcategory(index)}
                        />
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <p
              className="back-profile-btn"
              style={{ color: "#64748B", padding: "10px 10px 10px 0" }}
              onClick={() => handleDelete(data?._id)}
            >
              <img src={ProfileDelete} style={{ marginRight: "8px" }} alt="" />
              Delete Profile
            </p>
          </div>
        </div>
        <div className="bottom-box" style={{ position: "fixed" }}>
          <button className="bottom-preview-btn" onClick={assignPost}>
            Save
          </button>
          <button
            className="bottom-clear-btn"
            onClick={() => navigate(`/dashboard/add-new-hire/${data?._id}`)}
          >
            Edit
          </button>
        </div>
      </div>
    </>
  );
}

export default ProfileDetail;
