import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import "./recruiter.css";
import SubHeader from "../subHeader/SubHeader";
import leftArrow from "../../assets/recruiter/left-arrow.svg";
import AccUp from "../../recruiter-img/acco-up.svg";
import { useNavigate, useParams } from "react-router-dom";
import downArrow from "../../assets/select-down.svg";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
import closeBtn from "../../assets/close-btn.svg";
import shareBtn from "../../assets/recruiter/share-icon.svg";
import RecruiterSubMenu from "../mobileMenu/RecruiterSubMenu";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";
import RecruiterHeader from "../header/RecruiterHeader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";

const selectItem = [
  {
    id: 1,
    option: "Within 1 months",
    selected: false,
  },
  {
    id: 2,
    option: "Within 2 months",
    selected: false,
  },
  {
    id: 3,
    option: "Within 3 months",
    selected: false,
  },
  {
    id: 4,
    option: "Within 4 months",
    selected: false,
  },
  {
    id: 5,
    option: "Within 5 months",
    selected: false,
  },
];

const initialState = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  mobileNo: "",
  department: "",
  jobTitle: "",
  // subcategories: [],
  // job: [],
};

function AddNewHire() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const { createInterviewer, singleInterviewer, updateInterviewer } = useMain();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getSingleData(id);
    }
  }, []);
  const getSingleData = async (id) => {
    const res = await singleInterviewer(id);
    if (res?.statusCode == 200) {
      const { name, email, mobileNo, department, jobTitle, password2 } =
        res?.data;
      setFormData({
        ...formData,
        name,
        email,
        mobileNo,
        department,
        jobTitle,
        password: password2,
        confirmPassword: password2,
      });
    }
  };
  const menuItem = useContext(MobileContext);
  const { handleRecruiterToggle, recruiterMenu } = menuItem;

  // const navigate = useNavigate();
  const [accOpen1, setAccOpen1] = useState(true);
  const [accOpen2, setAccOpen2] = useState(true);
  const [accOpen3, setAccOpen3] = useState(true);

  const [selected, setIsSelected] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCreate = async () => {
    if (formData?.password !== formData?.confirmPassword) {
      return toast.info("Passwords don't match. Try again.");
    }
    if (
      !formData.name ||
      !formData.email ||
      !formData.mobileNo ||
      !formData.department ||
      !formData.jobTitle
    ) {
      return toast.info("All fields mandatory.");
    }
    if (id) {
      setLoader(true);
      const res = await updateInterviewer(id, formData);
      if (res?.statusCode == 200) {
        toast.success("Interviewer updated successfully.");
        navigate("/dashboard/all-interviews");
        setLoader(false);
      } else {
        toast.error("Interviewer update failed.");
        setLoader(false);
      }
    } else {
      setLoader(true);
      const token = localStorage.getItem("recruiterToken");
      const res = await createInterviewer(formData, token);
      if (res?.statusCode == 200) {
        toast.success("Interviewer created successfully.");
        navigate("/dashboard/all-interviews");
        setLoader(false);
      } else {
        toast.error("Interviewer creation failed.");
        setLoader(false);
      }
    }
    // console.log(res)
  };

  useEffect(() => {
    if (selected.trim() !== "") {
      if (!formData?.subcategories?.includes(selected))
        setFormData({
          ...formData,
          subcategories: [...formData?.subcategories, selected],
        });
    }
  }, [selected]);
  const handleDeleteSubcategory = (item) => {
    const subcategories = formData?.subcategories?.filter(
      (s, id) => id !== item
    );
    setFormData({ ...formData, subcategories });
  };
  return (
    <>
      <AlertContainer />
      <RecruiterHeader title="Add New Hiring Manager" search={false} />
      <div className="desktop-show-hide">
        <SubHeader />
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="desktop-show-hide">
          <div className="recruiter-container">
            <div className="draft-container">
              <div
                className="d-flex align-items-center"
                onClick={() => navigate(-1)}
              >
                <img src={leftArrow} style={{ marginRight: "8px" }} alt="" />
                <p className="m-0 draft-title">Add New Hiring Manager</p>
              </div>
              <div
                className="custom-accordion"
                style={{ display: accOpen1 ? "flex" : "unset" }}
              >
                <div
                  onClick={() => setAccOpen1(!accOpen1)}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <h2 className="acc-title">Interviewer’s Details</h2>
                    <p className="acc-text">
                      Fill all the details of an Interviewer and send them an
                      invite link
                    </p>
                  </div>
                  <img
                    src={AccUp}
                    style={{
                      transform: accOpen1 ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 150ms ease",
                    }}
                    alt=""
                  />
                </div>
                <div
                  className="acc-box"
                  style={{ height: accOpen1 ? "135px" : "0" }}
                >
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <div
                        className="acc-input"
                        style={{ display: accOpen1 ? "flex" : "none" }}
                      >
                        <label htmlFor="company-name">Name</label>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          name="name"
                          value={formData?.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <div
                        className="acc-input"
                        style={{ display: accOpen1 ? "flex" : "none" }}
                      >
                        <label htmlFor="company-name">job Title</label>
                        <input
                          type="text"
                          placeholder="Enter job title"
                          name="jobTitle"
                          value={formData?.jobTitle}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <div
                        className="acc-input"
                        style={{ display: accOpen1 ? "flex" : "none" }}
                      >
                        <label htmlFor="company-name">Work EMail ID</label>
                        <input
                          type="text"
                          placeholder="Enter email ID"
                          name="email"
                          value={formData?.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <div
                        className="acc-input"
                        style={{ display: accOpen1 ? "flex" : "none" }}
                      >
                        <label htmlFor="company-name">Mobile Number</label>
                        <input
                          type="text"
                          placeholder="Enter mobile number"
                          name="mobileNo"
                          value={formData?.mobileNo}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      {/* <div
                      className="acc-input"
                      style={{ display: accOpen1 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">
                        Team/Department
                      </label>
                      <div
                        className="dropdown"
                        onMouseEnter={() => setHover1(true)}
                        onMouseLeave={() => setHover1(false)}
                      >
                        <div
                          onClick={(e) => {
                            setIsActive(!isActive);
                          }}
                          style={{
                            outline: isActive ? "2px solid #94A3B8" : "none",
                          }}
                          className="dropdown-btn"
                        >
                          {selected}
                          <img
                            src={hover1 ? downArrowHover : downArrow}
                            alt=""
                          />
                        </div>
                        <div
                          className="dropdown-content"
                          style={{ display: isActive ? "block" : "none" }}
                        >
                          {selectItem.map((element, index) => {
                            return (
                              <div
                                onClick={(e) => {
                                  setIsSelected(e.target.textContent);
                                  setIsActive(!isActive);
                                }}
                                className="item"
                              >
                                {element.option}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div> */}
                      <div
                        className="acc-input"
                        style={{ display: accOpen1 ? "flex" : "none" }}
                      >
                        <label htmlFor="company-name">Team/Department</label>
                        <input
                          type="text"
                          placeholder="Enter Department"
                          name="department"
                          value={formData?.department}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="custom-accordion"
                style={{ display: accOpen2 ? "flex" : "unset" }}
              >
                <div
                  onClick={() => setAccOpen2(!accOpen2)}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <h2 className="acc-title">
                      Login Credentials for Interviewer
                    </h2>
                    <p className="acc-text">
                      Fill all the details of an Interviewer and send them an
                      invite link
                    </p>
                  </div>
                  <img
                    src={AccUp}
                    style={{
                      transform: accOpen2 ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 150ms ease",
                    }}
                    alt=""
                  />
                </div>
                <div
                  className="acc-box"
                  style={{ height: accOpen2 ? "62px" : "0" }}
                >
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <div
                        className="acc-input"
                        style={{ display: accOpen2 ? "flex" : "none" }}
                      >
                        <label htmlFor="company-name">User ID</label>
                        <input
                          type="text"
                          placeholder="Enter User ID"
                          name="email"
                          value={formData?.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <div
                        className="acc-input"
                        style={{ display: accOpen2 ? "flex" : "none" }}
                      >
                        <label htmlFor="company-name">Set Password</label>
                        <input
                          type="password"
                          placeholder="Enter set password"
                          name="password"
                          value={formData?.password}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <div
                        className="acc-input"
                        style={{ display: accOpen2 ? "flex" : "none" }}
                      >
                        <label htmlFor="company-name">Confirm Password</label>
                        <input
                          type="password"
                          placeholder="Enter confirm password"
                          name="confirmPassword"
                          value={formData?.confirmPassword}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div
              className="custom-accordion"
              style={{ display: accOpen3 ? "flex" : "unset" }}
            >
              <div
                onClick={() => setAccOpen3(!accOpen3)}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <h2 className="acc-title">Assign Interviews</h2>
                  <p className="acc-text">
                    Send interview collaborations to your colleagues for the job
                    posting
                  </p>
                </div>
                <img
                  src={AccUp}
                  style={{
                    transform: accOpen3 ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 150ms ease",
                  }}
                  alt=""
                />
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen3 ? "auto" : "0" }}
              >
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div
                      className="acc-input m-0"
                      style={{ display: accOpen3 ? "flex" : "none" }}
                    >
                      <label htmlFor="" className="assign-job-label">
                        Job Posting
                      </label>
                      <div
                        className="dropdown"
                        onMouseEnter={() => setHover1(true)}
                        onMouseLeave={() => setHover1(false)}
                        style={{ marginBottom: "0" }}
                      >
                        <div
                          onClick={(e) => {
                            setIsActive(!isActive);
                          }}
                          style={{
                            outline: isActive ? "2px solid #94A3B8" : "none",
                          }}
                          className="dropdown-btn"
                        >
                          Choose Job Posting
                          <img
                            src={hover1 ? downArrowHover : downArrow}
                            alt=""
                          />
                        </div>
                        <div
                          className="dropdown-content"
                          style={{ display: isActive ? "block" : "none" }}
                        >
                          {selectItem.map((element, index) => {
                            return (
                              <div
                                onClick={(e) => {
                                  setIsSelected(e.target.textContent);
                                  setIsActive(!isActive);
                                }}
                                className="item"
                              >
                                {element.option}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {formData?.subcategories?.length > 0 && (
                        <div className="d-flex flex-wrap">
                          {formData?.subcategories?.map((item, index) => (
                            <p className="select-option" key={index}>
                              {item}
                              <img
                                src={closeBtn}
                                style={{ marginLeft: "4px" }}
                                alt=""
                                onClick={() => handleDeleteSubcategory(index)}
                              />
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              <div className="d-flex justify-content-end">
                {/* <button className="remove-btn" onClick={handleCreate}>
                  Add Interviewer
                </button> */}
                <button className="invite-btn" onClick={handleCreate}>
                  {id != undefined || id!=null ? "Update Interviewer":"Invite Interviewer"}
                  <img src={shareBtn} style={{ marginLeft: "8px" }} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mobile-show-hide">
        <div
          className="recruiter-container"
          style={{
            marginTop: "80px",
            height: accOpen1 ? "100%" : "100vh",
            paddingBottom: "150px",
          }}
        >
          <div className="profile-mobile-box">
            <div
              className="custom-accordion"
              style={{
                display: accOpen1 ? "flex" : "flex",
                gap: accOpen1 ? "12px" : "0",
              }}
            >
              <div onClick={() => setAccOpen1(!accOpen1)}>
                <div className="d-flex justify-content-between">
                  <h2 className="acc-title">Interviewer’s Details</h2>
                  <img
                    src={AccUp}
                    style={{
                      transform: accOpen1 ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 150ms ease",
                    }}
                    alt=""
                  />
                </div>
                <p className="acc-text">
                  Fill all the details of an Interviewer and send them an
                  invite link
                </p>
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen1 ? "auto" : "0" }}
              >
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div
                      className="acc-input"
                      style={{ display: accOpen1 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">Name</label>
                      <input
                        type="text"
                        placeholder="Enter Name"
                        name="name"
                        value={formData?.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <div
                      className="acc-input"
                      style={{ display: accOpen1 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">job Title</label>
                      <input
                        type="text"
                        placeholder="Enter job title"
                        name="jobTitle"
                        value={formData?.jobTitle}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <div
                      className="acc-input"
                      style={{ display: accOpen1 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">Work EMail ID</label>
                      <input
                        type="text"
                        placeholder="Enter email ID"
                        name="email"
                        value={formData?.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <div
                      className="acc-input"
                      style={{ display: accOpen1 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">Mobile Number</label>
                      <input
                        type="text"
                        placeholder="Enter mobile number"
                        name="mobileNo"
                        value={formData?.mobileNo}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <div
                      className="acc-input"
                      style={{
                        display: accOpen1 ? "flex" : "none",
                        margin: "0",
                      }}
                    >
                      <label htmlFor="company-name">
                        Candidate’s Team/Department
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Department"
                        name="department"
                        value={formData?.department}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="custom-accordion"
              style={{ gap: accOpen2 ? "12px" : "0" }}
            >
              <div onClick={() => setAccOpen2(!accOpen2)}>
                <div className="d-flex justify-content-between">
                  <h2 className="acc-title">
                    Login Credentials for Interviewer
                  </h2>
                  <img
                    src={AccUp}
                    style={{
                      transform: accOpen2 ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 150ms ease",
                    }}
                    alt=""
                  />
                </div>
                <p className="acc-text">
                  Fill all the details of an Interviewer and send them an
                  invite link
                </p>
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen2 ? "auto" : "0" }}
              >
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div
                      className="acc-input"
                      style={{ display: accOpen2 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">User ID</label>
                      <input
                        type="text"
                        placeholder="Enter User ID"
                        name="email"
                        value={formData?.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <div
                      className="acc-input"
                      style={{ display: accOpen2 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">Set Password</label>
                      <input
                        type="password"
                        placeholder="Enter set password"
                        name="password"
                        value={formData?.password}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <div
                      className="acc-input"
                      style={{ display: accOpen2 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">Confirm Password</label>
                      <input
                        type="password"
                        placeholder="Enter confirm password"
                        name="confirmPassword"
                        value={formData?.confirmPassword}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              className="custom-accordion"
              style={{ gap: accOpen3 ? "12px" : "0" }}
            >
              <div
                onClick={() => setAccOpen3(!accOpen3)}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <h2 className="acc-title">Assign Interviews</h2>
                  <p className="acc-text">
                    Send interview collaborations to your colleagues for the job
                    posting
                  </p>
                </div>
                <img
                  src={AccUp}
                  style={{
                    transform: accOpen3 ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 150ms ease",
                  }}
                  alt=""
                />
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen3 ? "auto" : "0" }}
              >
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div
                      className="acc-input m-0"
                      style={{ display: accOpen3 ? "flex" : "none" }}
                    >
                      <label htmlFor="" className="assign-job-label">
                        Job Posting
                      </label>
                      <div
                        className="dropdown"
                        onMouseEnter={() => setHover1(true)}
                        onMouseLeave={() => setHover1(false)}
                      >
                        <div
                          onClick={(e) => {
                            setIsActive(!isActive);
                          }}
                          style={{
                            outline: isActive ? "2px solid #94A3B8" : "none",
                          }}
                          className="dropdown-btn"
                        >
                          Choose Job Posting
                          <img
                            src={hover1 ? downArrowHover : downArrow}
                            alt=""
                          />
                        </div>
                        <div
                          className="dropdown-content"
                          style={{ display: isActive ? "block" : "none" }}
                        >
                          {selectItem.map((element, index) => {
                            return (
                              <div
                                onClick={(e) => {
                                  setIsSelected(e.target.textContent);
                                  setIsActive(!isActive);
                                }}
                                className="item"
                              >
                                {element.option}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {formData?.subcategories?.length > 0 && (
                        <div className="d-flex flex-wrap">
                          {formData?.subcategories?.map((item, index) => (
                            <p className="select-option" key={index}>
                              {item}
                              <img
                                src={closeBtn}
                                style={{ marginLeft: "4px" }}
                                alt=""
                                onClick={() => handleDeleteSubcategory(index)}
                              />
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="d-flex  justify-content-end">
              <button className="remove-btn">Add Interviewer</button>
              <button className="invite-btn">Send Invite</button>
            </div> */}
          </div>
          <RecruiterSubMenu />
          <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
            <img
              src={top}
              alt=""
              style={{
                transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 150ms ease",
                height: "14px",
              }}
            />
            <img src={jobIcon} style={{ height: "24px" }} alt="" />
          </div>
        </div>
        <div className="bottom-btn mobile-show-hide">
          <div className="d-flex flex-column" style={{ gap: "20px" }}>
            <button
              className="bottom-preview-btn"
              // onClick={() => navigate("/dashboard/add-new-hire")}
              onClick={handleCreate}
            >
              {id != undefined || id!=null ? "Update Interviewer":"Invite Interviewer"}
              <img src={shareBtn} style={{ marginRight: "8px" }} alt="" />
            </button>

            {/* <button className="bottom-clear-btn" onClick={handleCreate}>
              Add Interviewer
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewHire;
