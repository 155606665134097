import React from "react";
import "./calender.css";
import Slider from "react-slick";
import moment from "moment";

function CalenderSlider({ data }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 7,
  };

  const currentDate = moment();
  const firstDayOfMonth = moment(currentDate).startOf("month");
  const lastDayOfMonth = moment(currentDate).endOf("month");

  const daysInMonth = [];
  let currentDay = moment(firstDayOfMonth);
  while (currentDay.isSameOrBefore(lastDayOfMonth, "day")) {
    daysInMonth.push(moment(currentDay));
    currentDay.add(1, "days");
  }

  return (
    <>
      <Slider {...settings}>
        {daysInMonth.map((day, index) => {
          // Find the data for the current date
          const userData = data.filter((item) =>
            moment(item.date, "DD/MM/YYYY").isSame(day, "day")
          );

          // If there are more than 3 candidates, show ellipsis
          let displayedUsers = userData.slice(0, 3);
          let additionalCount = userData.length - 3;
          let ellipsis = additionalCount > 0 ? "..." : "";

          return (
            <div>
              <div className="calender-box--" key={index}>
                <p>{day.date()}</p>
                <p>{day.format("ddd")}</p>
                {displayedUsers.map((user, i) => (
                  <p key={i} style={{ fontSize: "8px" }}>
                    {user.userId.name}
                  </p>
                ))}
                {ellipsis && <p>{ellipsis}</p>}
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
}

export default CalenderSlider;
