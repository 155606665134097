import React, { useEffect, useState } from "react";
import "./header.css";
import logo from "../../assets/logo.svg";
import mobileLogo from "../../assets/mobile-logo.svg";
import searchImg from "../../assets/white-search.svg";
// import upgrade from "../../assets/upgrade.svg";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import UpgradeModal from "../../dashboard/UpgradeModal";

function HomeHeader() {
  const [toggleSideBar, setSidebarToggle] = useState(false);
  const [searchInput, setSearchInput] = useState(false);

  // const handleDropdown = () => {
  //   setDropdown(!dropdown);
  // };
  // const modal = useContext(AllModalContext);
  // const { toggleUpgrade } = modal;

  const navigate = useNavigate();

  function openSidebar() {
    setSidebarToggle(!toggleSideBar);
  }
  function openSearch() {
    setSearchInput(!searchInput);
  }
  useEffect(() => {
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 100) {
        $(".home-header").css("background-color", "#172742");
      } else {
        $(".home-header").css("background-color", "transparent");
      }
    });
  }, []);

  function handleOnLogin() {
    navigate("/log-in");
  }
  // function handleUpgrade() {
  //   navigate("/upgrade");
  // }

  return (
    <div>
      <header className="home-header">
        <div
          className="search-input"
          style={{ display: searchInput ? "flex" : "none" }}
        >
          <input type="text" />
          <img src={searchImg} onClick={openSearch} alt="" />
        </div>
        <div
          className="overlay"
          onClick={() => {
            openSidebar();
          }}
          style={toggleSideBar ? { right: 0 } : { right: "100%" }}
        ></div>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" className="img-fluid" />
          </Link>
        </div>
        <div
          className="mobile-logo"
          style={{ display: searchInput ? "none" : "block" }}
        >
          <Link to="/">
            <img src={mobileLogo} alt="" className="img-fluid" />
          </Link>
        </div>
        <div
          className="list"
          style={{
            display: searchInput ? "none" : "inline-block",
          }}
        >
          {/* <button className="header-search-btn" onClick={openSearch}>
            <img src={searchImg} alt="" />
          </button> */}
          {/* <button className="upgrade-btn" onClick={toggleUpgrade}>
            Plan Upgrade
          </button> */}
          {/* <img className="me-4 upgrade-btn-img" src={upgrade} alt="" /> */}
          <button className="login-btn" onClick={handleOnLogin}>
          Get Started
          </button>
          {/* <button className="menu-dropdown" onClick={handleDropdown}>
            Dropdown
          </button> */}
          {/* {dropdown ? (
            <ul className="menu">
              <li
                className="menu-item"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Link to="">Profile</Link>
                <p
                  className="m-0"
                  style={{
                    color: "#0D9488",
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    paddingLeft: "20px",
                    lineHeight: "16px",
                  }}
                >
                  60% complete
                </p>
              </li>
              <li className="menu-item">
                <Link to="/setting">Settings and Privacy</Link>
              </li>
              <li className="menu-item">
                <Link to="/help-and-support">Help</Link>
              </li>
              <li className="menu-item">
                <Link to="">Contact us</Link>
              </li>
              <li className="menu-item">
                <Link to="">Log out</Link>
              </li>
              <button className="menu-upgrade-btn" onClick={handleUpgrade}>
                Upgrade Plans
              </button>
            </ul>
          ) : null} */}
        </div>
        <button onClick={() => openSidebar()} className="bras-btn">
          <i className="fa fa-regular fa-bars"></i>
        </button>
      </header>
      <UpgradeModal />
    </div>
  );
}

export default HomeHeader;
