import React, { useState } from "react";
import Header from "../components/header/Header";
import Light from "../assets/recruiter/right-icon.svg";
import Dark from "../assets/right-light.png";
import "./dashboard.css";
import RecruiterHeader from "../components/header/RecruiterHeader";

function UpgradeRecriterPage() {
  const [radio, setRadio] = useState("Monthly");
  return (
    <>
      <RecruiterHeader />
      <section className="upgrade-hero">
        <div className="mobile-upgrade-box">
          <h6 className="mobile-upgrade-title">Upgrade to Plan [name]</h6>
          <p className="mobile-upgrade-text">
            Get hired faster with better opportunities
          </p>
          <div className="d-flex justify-content-center">
            <div className="radio-button" style={{ marginLeft: "8px" }}>
              <input
                type="radio"
                className="radio-button__input"
                id="radio1"
                onChange={(e) => setRadio(e.target.value)}
                value="Monthly"
                name="Sort by"
              />
              <label className="upgrade-radio-button" htmlFor="radio1">
                <span className="radio-button__custom"></span>
                Monthly
              </label>
            </div>
            <div className="radio-button" style={{ marginLeft: "8px" }}>
              <input
                type="radio"
                className="radio-button__input"
                id="radio2"
                onChange={(e) => setRadio(e.target.value)}
                value="Yearly"
                name="Sort by"
              />
              <label className="upgrade-radio-button" htmlFor="radio2">
                <span className="radio-button__custom"></span>
                Yearly
              </label>
            </div>
          </div>
        </div>
        {radio === "Monthly" ? (
          <>
            <div className="mobile-upgrade-box-two">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="upgrade-box-two-title m-0">Premium Pro</h6>
                <p className="upgrade-box-two-text m-0">4,999/-</p>
              </div>
              <div className="upgrade-box-two-border"></div>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> 10 Active Job Post Every Month
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> 10000 Candidates Every Month
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> ATS
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> Interviewer (Admin + 5)
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> Work Flow Automation
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" />
                Interview Scheduler
              </p>
              <p
                className="upgrade-card-one-bold m-0"
                style={{ color: "#262626" }}
              >
                Add ons <button className="extra-one">@Extra cost</button>
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> Job Post @499/-
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> 1000 Candidates @999/-
              </p>
              <button className="upgrade-text-two-btn">
                Upgrade to Premium Pro
              </button>
            </div>
            <div className="mobile-upgrade-box-three">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="upgrade-box-three-title m-0">Premium</h6>
                <p className="upgrade-box-three-text m-0">3,499/-</p>
              </div>
              <div className="upgrade-box-three-border"></div>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" />5 Active Job Post - Month
              </p>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" /> 5000 Candidates - Month
              </p>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" /> ATS
              </p>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" /> Interviewer (Admin + 2)
              </p>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" /> Work Flow Automation
              </p>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" /> Interview Scheduler
              </p>
              <p className="upgrade-card-one-bold m-0">
                Add ons <button className="extra-one">@Extra cost</button>
              </p>
              <p className="upgrade-card-one-bold m-0">
                <img src={Light} style={{ paddingRight: "6px" }} alt="" />
                +1 Interviewer @499/-
              </p>
              <button className="upgrade-starter-btn">
                Upgrade to Premium
              </button>
            </div>
            <div className="mobile-upgrade-box-one">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="upgrade-box-title m-0">Basic</h6>
                <p className="upgrade-box-text m-0">1,999/-</p>
              </div>
              <div className="upgrade-box-border"></div>
              <p className="upgrade-text-three-bold m-0">
                <img src={Light} alt="" /> 3 Active Job Post Every Month
              </p>
              <p className="upgrade-text-three-bold m-0">
                <img src={Light} alt="" /> 3000 Candidates Every Month
              </p>
              <p className="upgrade-text-three-bold m-0">
                <img src={Light} alt="" /> ATS
              </p>
              <p className="upgrade-text-three-bold m-0">
                <img src={Light} alt="" /> Interviewer (Admin + 1)
              </p>
              <p className="upgrade-text-three-bold m-0">
                <img src={Light} alt="" /> Interview Scheduler
              </p>
              <button className="upgrade-text-three-btn">
                Upgrade to Basic
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="mobile-upgrade-box-two">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="upgrade-box-two-title m-0">Premium Pro</h6>
                <p className="upgrade-box-two-text m-0">49,999/-</p>
              </div>
              <div className="upgrade-box-two-border"></div>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> 10 Active Job Post Every Month
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> 10000 Candidates Every Month
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> ATS
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> Interviewer (Admin + 5)
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> Work Flow Automation
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" />
                Interview Scheduler
              </p>
              <p
                className="upgrade-card-one-bold m-0"
                style={{ color: "#262626" }}
              >
                Add ons <button className="extra-one">@Extra cost</button>
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> Job Post @499/-
              </p>
              <p className="upgrade-text-two-bold m-0">
                <img src={Dark} alt="" /> 1000 Candidates @999/-
              </p>
              <button className="upgrade-text-two-btn">
                Upgrade to Premium Pro
              </button>
            </div>
            <div className="mobile-upgrade-box-three">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="upgrade-box-three-title m-0">Premium</h6>
                <p className="upgrade-box-three-text m-0">34,999/-</p>
              </div>
              <div className="upgrade-box-three-border"></div>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" />5 Active Job Post - Month
              </p>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" /> 5000 Candidates - Month
              </p>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" /> ATS
              </p>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" /> Interviewer (Admin + 2)
              </p>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" /> Work Flow Automation
              </p>
              <p className="upgrade-text-one-bold m-0">
                <img src={Light} alt="" /> Interview Scheduler
              </p>
              <p className="upgrade-card-one-bold m-0">
                Add ons <button className="extra-one">@Extra cost</button>
              </p>
              <p className="upgrade-card-one-bold m-0">
                <img src={Light} style={{ paddingRight: "6px" }} alt="" />
                +1 Interviewer @499/-
              </p>
              <button className="upgrade-starter-btn">
                Upgrade to Premium
              </button>
            </div>
            <div className="mobile-upgrade-box-one">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="upgrade-box-title m-0">Basic</h6>
                <p className="upgrade-box-text m-0">19,999/-</p>
              </div>
              <div className="upgrade-box-border"></div>
              <p className="upgrade-text-three-bold m-0">
                <img src={Light} alt="" /> 3 Active Job Post Every Month
              </p>
              <p className="upgrade-text-three-bold m-0">
                <img src={Light} alt="" /> 3000 Candidates Every Month
              </p>
              <p className="upgrade-text-three-bold m-0">
                <img src={Light} alt="" /> ATS
              </p>
              <p className="upgrade-text-three-bold m-0">
                <img src={Light} alt="" /> Interviewer (Admin + 1)
              </p>
              <p className="upgrade-text-three-bold m-0">
                <img src={Light} alt="" /> Interview Scheduler
              </p>
              <button className="upgrade-text-three-btn">
                Upgrade to Basic
              </button>
            </div>
          </>
        )}
      </section>
    </>
  );
}

export default UpgradeRecriterPage;
