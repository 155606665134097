import React, { useState } from "react";
import lightArrow from "../../assets/recruiter-dashboard/light-down-arrow.svg";
import './account.css'
import backImg from "../../assets/recruiter/back-img.svg";
import { useNavigate } from "react-router-dom";
import FormHeader from "../header/FormHeader";
import RecruiterHeader from "../header/RecruiterHeader";

function RecruiterTermsPage() {
  const navigate = useNavigate();
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);
  const [isActive7, setIsActive7] = useState(false);
  const [isActive8, setIsActive8] = useState(false);
  const [isActive9, setIsActive9] = useState(false);
  const [isActive10, setIsActive10] = useState(false);
  const [isActive11, setIsActive11] = useState(false);
  const [isActive12, setIsActive12] = useState(false);
  const [isActive13, setIsActive13] = useState(false);
  const [isActive14, setIsActive14] = useState(false);
  const [isActive15, setIsActive15] = useState(false);
  const [isActive16, setIsActive16] = useState(false);
  return (
    <>
      <RecruiterHeader title="T&C" />
      <div className="privacy-page">
        <div className="faq-container">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <button
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={() => navigate(-1)}
              >
                <img src={backImg} className="prev-page" alt="" />
              </button>
            </div>
            <div>
              <h6 className="faq-title">Terms and Conditions</h6>
              <p className="faq-text">
                Read about Recruitifie Terms and Conditions
              </p>
            </div>
            <div></div>
          </div>
          <div
            style={{ borderBottom: "1px solid #E2E8F0", margin: "16px 0" }}
          ></div>
          <div className="faq-accordion-box">
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive1(!isActive1)}
              style={{ backgroundColor: isActive1 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive1 ? "#fff" : "#262626" }}
                  >
                    Terms and Conditions
                  </h2>
                </div>
                <div
                  className={isActive1 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive1 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive1 && (
                <div className="faq-acc-content">
                  Welcome to Recruitifie.com, (hereinafter referred to as the
                  "Site" or as "Recruitifie" interchangeably), a portal of YAM
                  RECRUIT Limited Liability Partnership (hereinafter referred to
                  as the YAM RECRUIT). These terms and conditions of use
                  ("Terms") constitute a legal agreement between you and YAM
                  RECRUIT. Use of this Site constitutes your unconditional
                  acceptance of these Terms herein and by all terms, policies
                  and guidelines incorporated by reference. These Terms apply to
                  your use of this Site, and do not alter in any way the terms
                  or conditions of any other agreement you may have with YAM
                  RECRUIT, its subsidiaries or affiliates. If you are using the
                  Site on behalf of any entity, you further represent and
                  warrant that you are authorized to accept these Terms on such
                  entity's behalf, and that such entity agrees to indemnify YAM
                  RECRUIT for violations of these Terms. If you do not agree
                  with these terms, please do not use this Site. <br />
                  Any individual and organisation wanting to make use of Site to
                  post internships/jobs (hereinafter referred to as the "Jobs")
                  for self or for an organisation he/she represents is referred
                  to as "Employer" or "Employers" as context may demand. <br />
                  Any individual wanting to make use of Site to apply to Jobs
                  listed on Recruitifie is referred to as "Applicant" or
                  "Applicants" as context may demand. <br />
                  Registration Data and Account Security <br />
                  In consideration of your use of the Site, you agree to: (a)
                  provide accurate, current and complete information about you
                  as may be prompted by any registration forms on the Site
                  ("Registration Data"); (b) maintain and promptly update the
                  Registration Data, and any other information you provide to
                  YAM RECRUIT, to keep it accurate, current and complete; (c)
                  maintain the security of your password and identification; (d)
                  notify YAM RECRUIT immediately of any unauthorized use of your
                  account or any other breach of security; (e) accept all
                  responsibility for any and all activities that occur under
                  your account; and (f) accept all risks of unauthorized access
                  to the Registration Data and any other information you provide
                  to YAM RECRUIT. Please refer to our Privacy Policy for more
                  details on how we collect, store, use, and retain your data on
                  the Site. <br />
                  Most Important Terms & Conditions for Employers <br />
                  These Terms, specifically for Employers, are in addition to
                  the other generic Terms & Conditions listed on the page which
                  are common to all the users of the site and which you are
                  required to go through. Any violation of these Terms may
                  result in, but not limited to, an Employer's Job being
                  declined for publication on Recruitifie and/or his/her account
                  being permanently blocked and/or appropriate legal action
                  being initiated against him/her by YAM RECRUIT. <br />
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive2(!isActive2)}
              style={{ backgroundColor: isActive2 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive2 ? "#fff" : "#262626" }}
                  >
                    Job Posting T&C
                  </h2>
                </div>
                <div
                  className={isActive2 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive2 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive2 && (
                <div className="faq-acc-content">
                  <ol style={{ margin: 0 }}>
                    <li>
                      It is your responsibility to ensure that you are
                      authorized to post Jobs on your organization's behalf. Any
                      dispute or legal claim arising out of unauthorized posting
                      would be solely your liability and you indemnify
                      Recruitifie and YAM RECRUIT of any and all possible
                      consequences of such actions.
                    </li>
                    <li>
                      If you upload your organization's logo while posting your
                      Job or creating/updating your company profile, you are
                      authorizing Recruitifie to display it on our website along
                      with Job post or in the list of our clients. It is your
                      responsibility to ensure that you are duly authorized to
                      share your organization logo with 3rd parties and allow
                      them to use it.
                    </li>
                    <li>
                      Recruitifie reserves the right to publish the Job post of
                      Employers on its social media handles and job board
                      partners like LinkedIn, Indeed etc., and in newspapers in
                      order to increase the visibility of the Job post.
                    </li>
                    <li>
                      You must provide accurate and complete details about the
                      organization and the Job. Any act of misinformation or
                      hiding material information would result in your account
                      being permanently blocked or any other suitable action as
                      deemed fit by YAM RECRUIT.
                    </li>
                    <li>
                      You may be asked for documents in the name of your company
                      for our first-time user authentication process. This
                      information will only be used for authentication purposes
                      and will not be used anywhere else in any form.
                    </li>
                    <li>
                      You may also complete the one-time authentication process
                      by signing-in to your company's YouTube channel. This will
                      be governed by the YouTube Terms of Service.
                    </li>
                    <li>
                      The minimum stipend criteria for an in-office internship
                      is Rs. 2000/Month and for a work from home internship is
                      Rs. 1000/Month. The minimum CTC criteria for a full time
                      job post is 2 LPA.
                    </li>
                  </ol>
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive3(!isActive3)}
              style={{ backgroundColor: isActive3 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive3 ? "#fff" : "#262626" }}
                  >
                    Recruitifie does NOT allow
                  </h2>
                </div>
                <div
                  className={isActive3 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive3 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive3 && (
                <div className="faq-acc-content">
                  <ol style={{ margin: 0, paddingLeft: "15px" }}>
                    <li>
                      Unpaid internships, except for NGOs/NPOs, government
                      agencies & some niche profiles (Law, Experimental Physics,
                      Library Science, and the likes).
                    </li>
                    <li>
                      Training programs and any such program where Applicants
                      are expected to pay a security deposit or an admission
                      fee. To charge money in any form (including test fee,
                      security deposit, documentation fee, etc.) from the
                      Applicants for offering Job is strictly prohibited.
                    </li>
                    <li> Modeling Jobs/Internships.</li>
                    <li>
                      Network Level Marketing (NLM) jobs/internships where
                      applicants/individuals have to use their personal
                      connections and personal social media accounts to promote
                      a business.
                    </li>
                    <li>
                      Any Job where there is a possibility of an Applicant
                      consuming alcohol or smoking or inducing others to do so,
                      as the majority of our users are underage.
                    </li>
                    <li>
                      Individuals or organizations promoting explicit religious
                      content, a particular religious personality or a sect,
                      etc. are not allowed to post Jobs on Recruitifie.
                    </li>
                    <li>
                      Businesses promoting or dealing in gambling, porn, and
                      other prohibited/illegal/age-inappropriate activities.
                    </li>
                    <li>
                      Third-party Job posting. You can post Jobs only for the
                      organization that you directly work for. Recruiting
                      agencies or any third-party agencies are authorized to
                      post Jobs on their clients' behalf only after sharing the
                      client's confirmation via email/authorization letters.
                    </li>
                    <li>
                      Any Job where there is a possibility of an Applicant
                      promoting betting or activities not suitable for people
                      under 18 years of age such as Rummy, Poker, etc.
                    </li>
                    <li>Any freelancing opportunity.</li>
                    <li>
                      Employers to offer different profiles to applicants than
                      the advertised one.
                    </li>
                    <li>
                      Organizations to ask applicants to open Demat accounts
                      (e.g. for Finance internships) or to assign any
                      pre-selection assessment which involves opening a Demat
                      account.
                    </li>
                    <li>
                      Organizations to entrust applicants with the task of
                      opening bank accounts on behalf of the Organization as
                      part of the shortlisting process.
                    </li>
                    <li>
                      Employers to ask applicants to sell/promote their
                      products/services as part of the shortlisting process.
                    </li>
                    <li>
                      Employers ask applicants for personal documents like
                      Aadhar, PAN card, etc. at the shortlisting stage.
                    </li>
                  </ol>
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive4(!isActive4)}
              style={{ backgroundColor: isActive4 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive4 ? "#fff" : "#262626" }}
                  >
                    Hiring T&Cs
                  </h2>
                </div>
                <div
                  className={isActive4 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive4 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive4 && (
                <div className="faq-acc-content">
                  <ol style={{ paddingLeft: "15px", margin: 0 }}>
                    <li>
                      It is your responsibility to ensure that there is no
                      material difference between the Job details that are
                      advertised on Recruitifie and the details that are
                      communicated to Applicants later in the selection process
                      (pre-interview, in offer letter etc.). Any ‘negative'
                      material difference (such as offered stipend being lower
                      than what was advertised) will result in suitable action
                      being taken by YAM RECRUIT against you.
                    </li>
                    <li>
                      Any assignment(s) that you may give to Applicants to
                      assess their suitability for the Job should be fair and
                      relevant to the Job profile. Any attempt to get work done
                      for free from Applicants disguised as assessment or to get
                      them to download or generate downloads for your app or get
                      likes/shares/comments etc. for your social media handles
                      only for the purpose of getting free publicity disguised
                      as an assignment are strictly prohibited and would invite
                      suitable actions against you.
                    </li>
                    <li>
                      All your communications with Applicants (through
                      Recruitifie Chat or otherwise) should be professional and
                      must not contain any obscene or offensive content.
                    </li>
                    <li>
                      Once you hire any Applicant(s) for your Job, you must
                      provide them with an offer letter clearly detailing all
                      the important details of the Job (such as role &
                      responsibilities, remuneration, and payment mechanism) and
                      complete address and contact details of your organisation.
                    </li>
                    <li>
                      You must pay the promised remuneration to the selected
                      Applicants in a timely manner as per agreed terms and
                      conditions in the offer letter. Non payment of the
                      remuneration or delayed payment of the remuneration is
                      strictly prohibited and would invite suitable action
                      against you.
                    </li>
                    <li>
                      You must respond within 72 working hours to any Applicant
                      complaints regarding your Job that we may bring to your
                      notice. Failure to do so may result in a temporary or
                      permanent suspension of your Recruitifie account depending
                      on the nature of the complaint.
                    </li>
                    <li>
                      If you come across any suspicious activity, content or
                      behaviour on Recruitifie by an Applicant or another user,
                      you must report it to Recruitifie immediately so that
                      necessary actions can be taken.
                    </li>
                    <li>
                      While we put our best efforts to reach out to the best
                      talent available in the country, posting your Job on
                      Recruitifie does not guarantee hiring.
                    </li>
                    <li>
                      Recruitifie always recommends employers to terminate the
                      internship/job within 15 days if the candidate's
                      performance is unsatisfactory.
                    </li>
                  </ol>
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive5(!isActive5)}
              style={{ backgroundColor: isActive5 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive5 ? "#fff" : "#262626" }}
                  >
                    Applicant data usage T&Cs
                  </h2>
                </div>
                <div
                  className={isActive5 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive5 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive5 && (
                <div className="faq-acc-content">
                  <ol style={{ paddingLeft: "15px", margin: 0 }}>
                    <li>
                      You can use the Applicants' data that you receive for your
                      Job solely for the purpose of hiring. Any attempt to send
                      any other communication (promotional content for example)
                      to the Applicants or any other usage of the data is
                      strictly prohibited.
                    </li>
                    <li>
                      You are strictly prohibited from
                      transferring/selling/sharing Applicants' data, for free or
                      for a fee, to any other entity. Any such attempt would
                      result in your account on Recruitifie being permanently
                      blocked and would be liable for legal action against you
                      and your organization.
                    </li>
                  </ol>
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive6(!isActive6)}
              style={{ backgroundColor: isActive6 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive6 ? "#fff" : "#262626" }}
                  >
                    Payment & refund T&Cs
                  </h2>
                </div>
                <div
                  className={isActive6 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive6 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive6 && (
                <div className="faq-acc-content">
                  <ol style={{ paddingLeft: "15px", margin: 0 }}>
                    <li>
                      Payments for the services offered by Recruitifie shall be
                      on a 100% advance basis.
                    </li>
                    <li>Refund if any will be as per our Refund Policy.</li>
                    <li>
                      YAM RECRUIT offers no guarantees whatsoever for the
                      accuracy or timeliness of the refunds reaching the
                      Employers card/bank accounts.
                    </li>
                  </ol>
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive7(!isActive7)}
              style={{ backgroundColor: isActive7 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive7 ? "#fff" : "#262626" }}
                  >
                    Most Important Terms & Conditions for Applicants
                  </h2>
                </div>
                <div
                  className={isActive7 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive7 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive7 && (
                <div className="faq-acc-content">
                  These Terms, specifically for Applicants, are in addition to
                  the other generic Terms & Conditions listed on the page which
                  are common to all the users of the site and which you are
                  required to go through. Any violation of these T&Cs may result
                  in, but not limited to, an applicant's Job application being
                  declined and/or his/her account being permanently blocked
                  and/or suitable legal action being initiated against him/her
                  by YAM RECRUIT.
                  <ol style={{ paddingLeft: "15px", margin: 0 }}>
                    <li>
                      If you have applied for a Job on Recruitifie and receive a
                      communication from Recruitifie or Employer regarding your
                      application, you must respond to it with-in 72 hours.
                    </li>
                    <li>
                      Once you accept a Job offer, you must make every possible
                      effort to join and start the Job on time. To not show up
                      or to decline an earlier accepted Job offer at the last
                      moment, or to go incommunicado creates a very poor
                      impression of you in front of the Employer and reduces the
                      credibility of the Applicant community at large. Any such
                      behaviour will not only result in your account being
                      blocked on Recruitifie but we will also report it to your
                      college administration wherever applicable.
                    </li>
                    <li>
                      You must provide accurate and complete information at the
                      time of Job application or creating an account on
                      Recruitifie – any misrepresentation of information, or
                      hiding of material information, or impersonation would
                      result in your account being blocked on the site and you
                      being reported to your college administration wherever
                      applicable.
                    </li>
                    <li>
                      You must pay attention to a Job's complete details
                      (profile, location, remuneration, skills required, etc.)
                      before applying for it. Irrelevant applications will
                      result in your account being penalized (we may stop you
                      from applying to future Jobs or block your account all
                      together)
                    </li>
                    <li>
                      You are strictly prohibited from
                      transferring/selling/sharing Employers' data (contact
                      details etc.), for free or for a fee, with any other
                      entity. Any such attempt would result in your account on
                      Recruitifie being permanently blocked and would be liable
                      for legal action against you.
                    </li>
                    <li>
                      Recruitifie community is a community of sincere users who
                      are known for and are expected to demonstrate high
                      standards of professionalism in their dealings with other
                      users and Employers – you are required to maintain these
                      standards and decorum of the community. All your
                      communications with other users of Recruitifie (through
                      Recruitifie Chat or otherwise) should be professional and
                      must not contain any obscene or offensive content.
                    </li>
                    <li>
                      You agree to have gone through the ‘Safety Tips for
                      Applicants' section of these Terms and have understood the
                      same.
                    </li>
                    <li>
                      While it is our endeavor to provide you with accurate and
                      reliable Job information; it is always possible for an
                      error to occur. Hence, you must conduct your own due
                      diligence and research about an employer or organisation
                      before starting a Job and take full responsibility of the
                      same. You further agree to have read and understood the
                      ‘Disclaimers' section of these Terms and explicitly and
                      specifically agree to it.
                    </li>
                    <li>
                      If you come across any suspicious activity, content or
                      behaviour on Recruitifie by an Employer or another user,
                      you must report it to Recruitifie immediately so that
                      necessary actions can be taken – this is both your duty
                      and obligation.
                    </li>
                    <li>
                      While we make our best efforts to bring you the best Job
                      opportunities possible, Recruitifie does not guarantee a
                      Job to a student.
                    </li>
                    <li>
                      Upon registration, your registered email id and phone
                      number will be automatically subscribed to receive email,
                      Whatsapp notifications and SMS notifications from
                      Recruitifie. You may opt out of it anytime.
                    </li>
                    <li>
                      Recruitifie reserves the right to remove your photo if it
                      doesn't feature your likeness or a headshot photograph. If
                      we take down your photo, you can upload a new one of
                      yourself to make things right.
                    </li>
                    <li>
                      If you upload your profile picture, you are authorizing
                      Recruitifie to share it with employers and other relevant
                      users of Recruitifie platform.
                    </li>
                  </ol>
                  Safety Tips for Applicants <br />
                  It is our endeavor to provide all our users with a safe and
                  hassle free experience on Recruitifie. Accordingly, we would
                  like to make Applicants aware of following safety tips for
                  them.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive8(!isActive8)}
              style={{ backgroundColor: isActive8 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive8 ? "#fff" : "#262626" }}
                  >
                    Recruitifie does NOT allow Employers to
                  </h2>
                </div>
                <div
                  className={isActive8 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive8 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive8 && (
                <div className="faq-acc-content">
                  <ol style={{ paddingLeft: "15px", margin: 0 }}>
                    <li>
                      Offer unpaid Jobs, unless explicitly mentioned so in the
                      Job post.
                    </li>
                    <li>
                      Post training program and any such program where
                      Applicants are expected to pay a security deposit or an
                      admission fee.
                    </li>
                    <li>
                      Charge money in any form (including test fee, security
                      deposit, documentation fee etc.) from the Applicants for
                      offering Job. This is strictly prohibited.
                    </li>
                    <li>Offer modelling Jobs from lesser known companies.</li>
                    <li>
                      Offer Network Level Marketing (NLM) jobs/internships where
                      applicants/individuals have to use their personal
                      connections and personal social media accounts to promote
                      a business.
                    </li>
                    <li>
                      Offer any Job where there is a possibility for an
                      Applicant consuming alcohol or smoking or inducing others
                      to do so, as the majority of our users are underage.
                    </li>
                    <li>
                      Offer Jobs from individuals or organisations promoting
                      explicit religious content, a particular religious
                      personality, or a sect etc.
                    </li>
                    <li>
                      Offer Jobs at businesses promoting or dealing in gambling,
                      porn, and other prohibited/illegal/age inappropriate
                      activities.
                    </li>
                    <li>
                      Make third party Job posting. An Employer can post Jobs
                      only for the organization that he/she directly works for.
                      Recruiting agencies or any third party agencies are
                      authorized to post Jobs on their clients' behalf only
                      after they have been authorized by the Recruitifie team.
                    </li>
                  </ol>
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive9(!isActive9)}
              style={{ backgroundColor: isActive9 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive9 ? "#fff" : "#262626" }}
                  >
                    Further, it is mandatory for Employers to ensure that :
                  </h2>
                </div>
                <div
                  className={isActive9 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive9 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive9 && (
                <div className="faq-acc-content">
                  <ol style={{ margin: "0", paddingLeft: "13px" }}>
                    <li>
                      Jobs details communicated to Applicants at any stage are
                      same as advertised on Recruitifie and there is no negative
                      material difference (Ex. - offering lower or no stipend
                      than what was advertised on Recruitifie).
                    </li>
                    <li>
                      Any assignment(s) that they may give to Applicants to
                      assess their suitability for the Job should be fair and
                      relevant to the Job profile. Any attempt to get work done
                      for free from Applicants disguised as assessment or to get
                      them to download or generate downloads for an app or get
                      likes/shares/comments etc. for Employer's social media
                      handles only for the purpose of getting free publicity
                      disguised as an assignment are strictly prohibited.
                    </li>
                    <li>
                      All their communications with Applicants (through
                      Recruitifie Chat or otherwise) are professional and must
                      not contain any obscene or offensive content.
                    </li>
                    <li>
                      Once they hire any Applicant(s) for their Job, they must
                      provide them with an offer letter clearly detailing all
                      the important details of the Job (such as role &
                      responsibilities, remuneration, and payment mechanism) and
                      complete address and contact details of Employer.
                    </li>
                    <li>
                      They pay the promised remuneration to the selected
                      Applicant(s) in a timely manner as per agreed terms and
                      conditions of the Job as detailed in the offer letter.
                    </li>
                  </ol>
                  If you come across any example of any Employer violating these
                  guidelines, you must report it to Recruitifie using Help
                  Center so that we can investigate and take appropriate
                  actions.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive10(!isActive10)}
              style={{ backgroundColor: isActive10 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive10 ? "#fff" : "#262626" }}
                  >
                    Disclaimers
                  </h2>
                </div>
                <div
                  className={isActive10 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive10 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive10 && (
                <div className="faq-acc-content">
                  This Site and its content are provided "as is" and YAM RECRUIT
                  and its directors, employees, content providers, agents and
                  affiliates exclude, to the fullest extent permitted by
                  applicable law, any warranty, express or implied, including,
                  without limitation, any implied warranties of merchantability,
                  satisfactory quality or fitness for a particular purpose. YAM
                  RECRUIT will not be liable for any damages of any kind arising
                  from the use of this site. The functions embodied on or in the
                  materials of this site are not warranted to be uninterrupted
                  or without error. You, not YAM RECRUIT and/or Recruitifie,
                  assume the entire cost of all necessary care or correction due
                  to your use of this site or content. YAM RECRUIT makes no
                  warranty that the site or the content is free from infection
                  by viruses or anything else that has contaminating or
                  destructive properties. YAM RECRUIT uses reasonable efforts to
                  ensure the accuracy, correctness and reliability of the
                  Content, but we make no representations or warranties for the
                  same. Images of people or places displayed on the Site are
                  either the property of, or used with permission by, YAM
                  RECRUIT or third parties. The use of these images by you, or
                  anyone else authorized by you, is prohibited unless
                  specifically permitted by these Terms or specific permission
                  provided elsewhere on the Site or by separate license or
                  agreement. Any unauthorized use of these images may violate
                  copyright laws, trademark laws, the laws of privacy and
                  publicity, and other applicable laws. <br />
                  Rights to Website and Contents thereof
                  <br />
                  <br />
                  This Site is owned and operated by YAM RECRUIT. All the
                  content featured or displayed on this Site, including, but not
                  limited to, text, graphics, data, images(photographic and
                  moving), illustrations, software and selection and arrangement
                  thereof ("Content"), is owned by YAM RECRUIT. All elements of
                  this Site, including, but not limited to, the general design
                  and the Content thereof, are protected by copyright, moral
                  rights, trademark and other laws related to the protection of
                  intellectual property rights. Except for, as explicitly
                  permitted under this, or other written license or agreement
                  with YAM RECRUIT, no portion or element of this Site or its
                  content may be copied or retransmitted via any means. All
                  related rights shall remain the exclusive property of YAM
                  RECRUIT, its licensors or its third-party image partners.{" "}
                  <br />
                  <br />
                  <h2
                    className="faq-acc-title m-0"
                    style={{
                      color: isActive10 ? "#fff" : "#262626",
                      textTransform: "uppercase",
                    }}
                  >
                    Using the Website
                  </h2>
                  This Site and its Content are intended for Users of
                  Recruitifie.com, the division of YAM RECRUIT. You may not use
                  this Site or the content for any purpose, which is not related
                  to your business with Recruitifie.com. Any use of the Content,
                  this Site or any of its functionality for a purpose not
                  permitted by these Terms is grounds for the immediate
                  revocation of any usernames, pass codes or other permissions
                  that may have been granted by Recruitifie.com on behalf of YAM
                  RECRUIT for use of this Site. You are specifically prohibited
                  from: (i) downloading, copying, or retransmitting any or all
                  of the Site or the Content without, or in violation of, a
                  written license or agreement with YAM RECRUIT; (ii) using any
                  data mining or similar data gathering or extraction methods;
                  (iii) manipulating or otherwise displaying the site or the
                  content by using, framing or similar navigational technology;
                  (iv) registering, subscribing, unsubscribing, or attempting to
                  register, subscribe any party for any the site if you are not
                  expressly authorized by such party to do so; and (v) using the
                  Site or Content other than for its intended purpose. Such
                  unauthorized use may also violate applicable laws including
                  without limitation copyright and trademark laws, the laws of
                  privacy and publicity, and applicable communications
                  regulations and statutes. You represent and warrant that you
                  will comply with all applicable regulations, including,
                  without limitation, those relating to the internet, data,
                  e-mail, privacy and the transmission of technical data
                  exported from India or the Country in which you reside. <br />
                  <br />
                  <h2
                    className="faq-acc-title m-0"
                    style={{
                      color: isActive10 ? "#fff" : "#262626",
                      textTransform: "uppercase",
                    }}
                  >
                    Trademarks
                  </h2>
                  YAM RECRUIT’s Trademarks, the YAM RECRUIT’s and Recruitifie
                  logo, and any other product or service name or slogan
                  contained in the Site are trademarks of YAM RECRUIT and its
                  suppliers or licensors, and may not be copied, imitated or
                  used, in whole or in part, without the prior written
                  permission of YAM RECRUIT or the applicable trademark holder.
                  You may not use meta-tags or any other "hidden text" utilizing
                  content or any other name, trademark or product or service
                  name of YAM RECRUIT without our prior written permission. In
                  addition, the look and feel of the Site, including all page
                  headers, Site name and logo, custom graphics, button icons and
                  scripts, is the service mark, trademark and/or trade dress of
                  YAM RECRUIT and may not be copied, imitated or used, in whole
                  or in part, without our prior written permission. All other
                  trademarks, registered trademarks, product names and company
                  names or logos mentioned in the Site are the property of their
                  respective owners. Reference to any products, services,
                  processes or other information, by trade name, trademark,
                  manufacturer, supplier or otherwise does not constitute or
                  imply endorsement, sponsorship or recommendation thereof by
                  us.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive11(!isActive11)}
              style={{ backgroundColor: isActive11 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive11 ? "#fff" : "#262626" }}
                  >
                    Indemnity
                  </h2>
                </div>
                <div
                  className={isActive11 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive11 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive11 && (
                <div className="faq-acc-content">
                  You agree to defend, indemnify and hold harmless YAM RECRUIT,
                  its subsidiaries, affiliates, licensors, employees, agents,
                  third party information providers and independent contractors
                  against any claims, damages, costs, liabilities and expenses
                  (including, but not limited to, reasonable attorneys' fees)
                  arising out of or related to any User Content that you post,
                  store or otherwise transmit on or through the Site, your
                  conduct, your use or inability to use the Site, your breach or
                  alleged breach of the Site Terms or of any representation or
                  warranty contained herein, your unauthorized use of the
                  Content, or your violation of any rights of another. Further
                  you shall indemnify YAM RECRUIT, its subsidiaries, its
                  affiliates and licensors against any loss, expense, cost or
                  damage incurred by any or all of them as a result of your
                  breach of these Terms or your unauthorized use of the Content
                  or the Site.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive12(!isActive12)}
              style={{ backgroundColor: isActive12 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive12 ? "#fff" : "#262626" }}
                  >
                    Termination
                  </h2>
                </div>
                <div
                  className={isActive12 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive12 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive12 && (
                <div className="faq-acc-content">
                  Notwithstanding any of these Site Terms, YAM RECRUIT reserves
                  the right, without notice and in its sole discretion, to
                  terminate your account and/or to block your use of the Site.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive13(!isActive13)}
              style={{ backgroundColor: isActive13 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive13 ? "#fff" : "#262626" }}
                  >
                    Changes to Site Terms
                  </h2>
                </div>
                <div
                  className={isActive13 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive13 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive13 && (
                <div className="faq-acc-content">
                  YAM RECRUIT reserves the right to change any of the terms and
                  conditions contained in the Site Terms or any policy or
                  guideline of the Site, at any time and in its sole discretion.
                  Any changes will be effective immediately upon posting on the
                  Site. Your continued use of the Site following the posting of
                  changes will constitute your acceptance of such changes. We
                  encourage you to review the Site Terms whenever you visit the
                  website.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive14(!isActive14)}
              style={{ backgroundColor: isActive14 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive14 ? "#fff" : "#262626" }}
                  >
                    Chargeback
                  </h2>
                </div>
                <div
                  className={isActive14 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive14 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive14 && (
                <div className="faq-acc-content">
                  You hereby expressly agree that once you or any other person
                  have started the process of downloading content from the site
                  by expressly, without reservation, agreeing to the terms and
                  conditions of the site and license agreement, by clicking and
                  accepting them and have further submitted your credit card/
                  any other card details to the site/internet banking service
                  provider for payment and your account gets debited no
                  chargeback claim/dispute with regard to the failure of
                  delivery of Product/ Content or the use/misuse of such credit
                  card or any other card, filed by you/on your behalf with the
                  Credit Card Provider/Internet banking service provider or any
                  Bank/Financial Institution shall be binding on YAM RECRUIT or
                  YAM RECRUIT’s Merchant Bank. You accept and take full
                  responsibility towards the ownership/possession and use of
                  your credit card or any other card. You expressly waive and
                  consciously give up any/all rights you may have in law or in
                  any agreement between you and the credit card or any other
                  card provider/internet service provider or under network rules
                  and regulations to raise any dispute to claim any charge back
                  or any process fee from any Bank/Financial Institution/
                  Internet banking service provider affecting YAM RECRUIT in any
                  manner pecuniary or otherwise on any grounds whatsoever
                  including non delivery of Product/Content or fraudulent or
                  improper transactions by the unauthorized/improper use of your
                  credit/debit card or any other card. This understanding that
                  you will have no right to claim any chargeback is a material
                  condition of this agreement and once you hereby expressly
                  agree to it you shall be stopped from challenging/disputing it
                  in any adjudication/proceeding before any Bank/Financial
                  institution/ Internet banking service provider or Court or
                  otherwise. Without prejudice to YAM RECRUIT’s right to defend
                  its interest(s), YAM RECRUIT shall not be made a party to any
                  chargeback proceedings before any Bank/Financial institution/
                  Internet banking service provider or court and in no case be
                  required to prove the transaction effected by the use of your
                  credit/debit or any other card was legitimate and genuine, or
                  that YAM RECRUIT has actually provided you the downloaded
                  content. Please refer to our Refund & Cancellation policy for
                  more details.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive15(!isActive15)}
              style={{ backgroundColor: isActive15 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive15 ? "#fff" : "#262626" }}
                  >
                    Severance & Waiver
                  </h2>
                </div>
                <div
                  className={isActive15 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive15 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive15 && (
                <div className="faq-acc-content">
                  No action of YAM RECRUIT, other than an express written waiver
                  or amendment, may be construed as a waiver or amendment of any
                  of these Terms. If any clause in these Terms is found to be
                  unenforceable, wherever possible, it will not affect any other
                  clause and each will remain in full force and effect. We
                  reserve the right to change these Terms, the Content displayed
                  on the Site, any license contained on the Site and any other
                  information or license terms without prior notice. These Terms
                  set out the entire agreement between YAM RECRUIT and you
                  relating to your use of this Site. Any rights not expressly
                  granted herein are reserved.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive16(!isActive16)}
              style={{ backgroundColor: isActive16 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive16 ? "#fff" : "#262626" }}
                  >
                    Jurisdiction
                  </h2>
                </div>
                <div
                  className={isActive16 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive15 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive16 && (
                <div className="faq-acc-content">
                  All license agreements, use or any issues arising out of any
                  activity regarding the use of this website will be governed by
                  the laws of India and subject to the exclusive jurisdiction of
                  courts in Pune.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruiterTermsPage;
