import React, { useEffect, useState } from "react";
import arrow from "../assets/left-arrow.svg";
import arrowHover from "../assets/our/right-arrow-btn.svg";
import { useNavigate } from "react-router-dom";
import FormHeader from "../components/header/FormHeader";
import "../App.css";
import { useMain } from "../hook/useMain";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import Header from "../components/header/Header";

function Complete() {
  const navigate = useNavigate();
  const [dashHover, setDashHover] = useState(false);
  const { completeUser } = useMain();
  const id = localStorage.getItem("userId");
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  useEffect(() => {
    if (id) {
      // sendMessage(id);
    }
  }, [id]);

  const sendMessage = async (id) => {
    const result = await completeUser(id);
    if (result?.statusCode === 200) {
      // toast.success(result?.data);
    }
  };

  function handleOnClick() {
    navigate("/dashboard");
  }
  return (
    <>
      <AlertContainer/>
      <Header title="Build Profile" />
      <section className="complete-section">
        <div className="complete-box">
          <div className="wrapper"></div>
          <div>
            <h2 className="complete-title">Yaay!</h2>
            <p className="m-0 complete-text">
              Your application is complete. <br />
              Grab your dream job now.
            </p>
          </div>
          <button
            onMouseEnter={() => setDashHover(true)}
            onMouseLeave={() => setDashHover(false)}
            className="dashboard-btn d-flex align-items-center justify-content-center"
            onClick={handleOnClick}
          >
            Dashboard{" "}
            <img
              src={dashHover ? arrowHover : arrow}
              style={{ marginLeft: "8px", height: "16px" }}
              alt=""
            />
          </button>
        </div>
      </section>
    </>
  );
}

export default Complete;
