import React, { useState } from "react";
import Slider from "react-slick";
import box from "../../assets/office-building.png";
import chat from "../../assets/chat-track.svg";
import ChatModal from "../../dashboard/ChatModal";

function OpenCard({ toggleModal }) {
  return (
    <>
      <div className="open-card">
        <div className="d-flex align-items-center">
          <img src={box} className="me-2" alt="" />
          <div>
            <h6 className="open-card-title m-0">Human Resources</h6>
            <p className="open-card-text m-0">Youth Empower Foundation</p>
          </div>
        </div>
        <div className="open-card-border"></div>
        <ul className="ps-4 m-0">
          <li className="open-card-item">Delhi</li>
          <li className="open-card-item">Start up</li>
          <li className="open-card-item">Full-time</li>
          <li className="open-card-item">Immediately</li>
        </ul>
        {/* <div className="d-flex align-items-center">
          <p className="open-card-item m-0">Delhi</p>
          <div className="open-card-dot"></div>
          <p className="open-card-item m-0">Start up</p>
          <div className="open-card-dot"></div>
          <p className="open-card-item m-0">Full-time</p>
          <div className="open-card-dot"></div>
          <p className="open-card-item m-0">Immediately</p>
        </div> */}
        <div className="chat-btn" onClick={toggleModal}>
          <img src={chat} alt="" />
          <h6 className="chat-btn-text">Chat with HR</h6>
        </div>
      </div>
    </>
  );
}
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  arrows: false,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

function ChatOpenSlider({ interviewer }) {
  const [toggle, setToggle] = useState("");

  return (
    <>
      {/* <Slider {...settings}> */}
      <div className="d-flex">
        {interviewer?.length > 0 &&
          interviewer?.map((item, index) => (
            <div key={index}>
              <div className="open-card">
                <div className="d-flex align-items-center">
                  <img src={box} className="me-2" alt=""  style={{width:"45px", height:"45px"}}/>
                  <div>
                    <h6 className="open-card-title m-0">
                      {item?.receiver?.jobInfo?.position}
                    </h6>
                    <p className="open-card-text m-0">
                      {item?.receiver?.companyName}
                    </p>
                  </div>
                </div>
                <div className="open-card-border"></div>
                <ul className="ps-4 m-0">
                  <li className="open-card-item">
                    {item?.receiver?.jobInfo?.workPlace}
                  </li>
                  {/* <li className="open-card-item">Start Up</li> */}
                  <li className="open-card-item">
                    {item?.receiver?.jobInfo?.jobType}
                  </li>
                  <li className="open-card-item">
                    {item?.receiver?.jobInfo?.workPlaceType}
                  </li>
                  <li className="open-card-item">
                    {item?.receiver?.jobInfo?.candidateJoining}
                  </li>
                </ul>
                <div className="chat-btn" onClick={() => setToggle(item?._id)}>
                  <img src={chat} alt="" />
                  <h6 className="chat-btn-text">Chat with HR</h6>
                </div>
              </div>
              {toggle === item?._id && (
                <ChatModal
                  toggle={toggle}
                  setToggle={setToggle}
                  interviewerId={item?.receiver?._id}
                  name={item?.receiver?.jobInfo?.position}
                  company={item?.receiver?.companyName}
                />
              )}
            </div>
          ))}
      </div>

      {/* </Slider> */}
    </>
  );
}

export default ChatOpenSlider;
