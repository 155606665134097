import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import search from "../../assets/recruiter/search.svg";
import Close from "../../assets/recruiter/close-input.svg";
import CoralSlider from "./CoralSlider";
import ResumeImg from "../../assets/profile-photo.svg";
import jobIcon from "../../assets/job-icon.svg";
import top from "../../assets/top-arrow.svg";
import CoralCard2 from "./CoralCard2";
import FIleView from "./FIleView";
import CoralCard3 from "./CoralCard3";
import MobileContext from "../../context/mobile/MobileContext";
import MobileMenu from "../mobileMenu/MobileMenu";
import downloadImg from "../../assets/recruiter/download-img.svg";
import editImg from "../../assets/recruiter/edit-img.svg";
import MobileResume from "../../assets/recruiter/mobile-resume.svg";
import pinImg from "../../assets/recruiter/pin-img.svg";
import InputField from "./InputField";
import Sidebar from "../sideBar/Sidebar";
import SideBarContext from "../../context/SidebarContext";

function TemplatesFour() {
  const menuItem = useContext(MobileContext);
  const { handleMobileToggle, mobileMenu } = menuItem;
  const sidebar = useContext(SideBarContext);
  const { isSidebarOpen } = sidebar;
  const [inputToggle, setInputToggel] = useState(false);
  const handleChangeupdate = () => {
    setInputToggel(!inputToggle);
  };
  const [formData, setFormData] = useState(null);
  const [education, setEducation] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [experience, setExperience] = useState(null);
  useEffect(() => {
    setFormData(JSON.parse(localStorage.getItem("resumeForm")));
    setEducation(JSON.parse(localStorage.getItem("resumeEducation")));
    setLanguages(JSON.parse(localStorage.getItem("resumeLanguage")));
    setExperience(JSON.parse(localStorage.getItem("resumeExperience")));
  }, [inputToggle]);
  // console.log(formData, education, languages, experience);
  return (
    <>
      <Header />
      <section className="dashboard">
        <div className="desktop-show-hide">
          <div className="dashboard-box ">
            <div className="sidebar-scroll">
              <Sidebar />
            </div>
            <div
              className="job-seeker-dashboard"
              style={{ left: isSidebarOpen ? "85px" : "354px" }}
            >
              <div className="template-container">
                <div className="template-body">
                  <div className="design-container">
                    <div className="design-head">
                      <div className="design-btns">
                        <button className="design-edit">Download</button>
                      </div>
                      <div className="design-btns">
                        <button className="design-save">Save</button>
                      </div>
                    </div>
                    <div className="design-body">
                      <div className="resume-box-2">
                        <div className="r-2-left">
                          <div className="r-card-title-white">Contact</div>
                          <div className="r-card-text-white">
                          {formData?.email || "Email Address"}
                          </div>
                          <div className="r-card-text-white">
                          {formData?.phoneNumber || "Phone Number"}
                          </div>
                          <div className="r-card-text-white">
                          {formData?.city || "Current City"}
                          </div>
                          <div className="r-card-title-white">Summary</div>
                          <p className="r-card-text-white">
                              {formData?.summary
                                ? formData.summary
                                    .split("\n")
                                    .map((line, index) => (
                                      <React.Fragment key={index}>
                                        {line}
                                        <br />
                                      </React.Fragment>
                                    ))
                                : "Write Summary"}
                            </p>
                          {/* <div className="r-card-title-white">Education</div> */}
                          <div>
                              {languages && (
                                <div className="r-card-title-white">
                                  Language
                                </div>
                              )}
                              {languages?.map((e) => (
                                <p className="r-card-text-white">
                                  {e?.languageName}
                                  {" - "}
                                  {e?.proficiency}
                                </p>
                              ))}
                            </div>
                        </div>
                        <div className="r-2-right">
                          <div className="r-right-top">
                            <h1 className="r-title"> {formData?.fullname || "Name"}</h1>
                            <p className="r-text">
                            {formData?.designation || "Designation"}
                            </p>
                          </div>
                          <div className="r-right-bottom">
                          {experience && (
                            <div className="r-card-title">Experience</div>
                            )}
                             {experience?.map((e, ind) => (
                              <div>
                                <h6 className="design-experience-titles">
                                  {e?.designation || "Designation"}
                                </h6>
                                <p className="r-card-text">
                                  {" "}
                                  {e?.companyName || "Company Name"}
                                </p>
                                <p className="r-card-text">
                                  {`${e?.form || "Form"}-${e?.to || "To"}`}{" "}
                                  {", "} {e?.companyType || "Company Type"}
                                </p>
                                <p className="r-card-text">
                                  {e?.describeRole
                                    ? e.describeRole
                                        .split("\n")
                                        .map((line, index) => (
                                          <span key={index}>
                                            {line}
                                            <br />
                                          </span>
                                        ))
                                    : "Describe your Job Roles & Responsibilities"}
                                </p>
                              </div>
                            ))}
                            {experience && (
                            <div className="r-card-title">Education</div>
                            )}
                            {education?.map((e, ind) => (
                              <div>
                                <h6 className="design-experience-titles">
                                  {e?.degreeName || "Degree Name"}
                                </h6>
                                <p className="design-experience-text">
                                  {e?.universityName || "Institute Name"}
                                </p>
                                <p className="design-experience-text-1">
                                  {`${e?.selectStartYear || "Start Year"}-${
                                    e?.selectEndYear || "End Year"
                                  }`}{" "}
                                  {", "} {e?.degree || "Degree Type"}
                                </p>
                                <p className="design-experience-text">
                                  {e?.writehighlight ||
                                    "HighLight of Your Journey"}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/* <img src={ResumeImg} className="img-fluid" alt="" /> */}
                    </div>
                    <div className="resume-file">
                      <h6 className="resume-file-title">Resumes</h6>
                      <FIleView />
                      <FIleView />
                      <FIleView />
                      <FIleView />
                    </div>
                  </div>
                  <div className="paid-tamp">
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                          className="nav-link active"
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-home"
                          type="button"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          Templates
                        </button>
                        <button
                          className="nav-link"
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-profile"
                          type="button"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                        >
                          Input fields
                        </button>
                        {/* <button
                    className="nav-link"
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                  >
                    Uploads
                  </button> */}
                      </div>
                    </nav>
                    <div
                      className="tab-content"
                      style={{ borderLeft: "none" }}
                      id="nav-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        <div className="coral-2-container">
                          <div className="template-search">
                            <img src={search} alt="" />
                            <input type="text" placeholder="Choose category" />
                            <img src={Close} alt="" />
                          </div>
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="paid-title">Saved Resume</h6>
                              <button className="paid-btn">View all</button>
                            </div>
                            <div className="coral-2-box">
                              <CoralCard2 />
                              <CoralCard2 />
                            </div>
                          </div>
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="paid-title">More</h6>
                              <button className="paid-btn">View all</button>
                            </div>
                            <div className="coral-2-box">
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        <div className="coral-2-container">
                          <InputField handleChangeupdate={handleChangeupdate} />
                        </div>
                      </div>
                      {/* <div
                  className="tab-pane fade"
                  id="nav-contact"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                >
                  Upload
                </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/*  Mobile Screen View */}
            </div>
          </div>
        </div>
      </section>
      {/*  Mobile Screen View */}
      <div className="template-container">
        <div className="mobile-show-hide">
          <div className="paid-tamp">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  className="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#templates"
                  type="button"
                  role="tab"
                  aria-controls="templates"
                  aria-selected="true"
                >
                  Templates
                </button>
                <button
                  className="nav-link"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#input-fields"
                  type="button"
                  role="tab"
                  aria-controls="input-fields"
                  aria-selected="false"
                >
                  Input fields
                </button>
                {/* <button
                  className="nav-link"
                  id="nav-contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#upload"
                  type="button"
                  role="tab"
                  aria-controls="upload"
                  aria-selected="false"
                >
                  Uploads
                </button> */}
              </div>
            </nav>
            <div
              className="tab-content"
              style={{ borderLeft: "none" }}
              id="nav-tabContent"
            >
              <div
                className="tab-pane fade show active"
                id="templates"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="coral-2-container">
                  <div style={{ marginTop: "12px" }}>
                    <p className="resume-tamp-text">Pinned</p>
                    <div className="coral-slider">
                      <CoralSlider />
                    </div>
                  </div>
                  <div style={{ marginTop: "12px" }}>
                    <p className="resume-tamp-text">More Templates</p>
                    <div className="d-flex flex-column" style={{ gap: "8px" }}>
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <h6 className="design-title">Basic CV Design</h6>
                    <p className="design-text">Best for Business</p>
                  </div>
                  <div className="d-flex" style={{ gap: "16px" }}>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        padding: "0",
                      }}
                    >
                      <img src={downloadImg} alt="download" />
                    </button>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        padding: "0",
                      }}
                    >
                      <img src={editImg} alt="edi" />
                    </button>
                  </div>
                </div>
                <img
                  src={MobileResume}
                  className="img-fluid w-100 mt-3"
                  alt=""
                />
                <button className="design-save-btn">
                  Save <img src={pinImg} alt="" />
                </button>
                <div className="resume-file">
                  <h6 className="resume-file-title">Resumes</h6>
                  <FIleView />
                  <FIleView />
                  <FIleView />
                  <FIleView />
                </div>
                <div className="mt-2">
                  <p className="resume-tamp-text">More Designs</p>
                  <div className="coral-slider">
                    <CoralSlider />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="input-fields"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <InputField handleChangeupdate={handleChangeupdate} />
              </div>
              {/* <div
                className="tab-pane fade"
                id="upload"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                Upload
              </div> */}
            </div>
          </div>
          <MobileMenu />
          <div className="mobile-menu-btn" onClick={handleMobileToggle}>
            <img
              src={top}
              alt=""
              style={{
                transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 150ms ease",
                height: "14px",
              }}
            />
            <img src={jobIcon} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default TemplatesFour;
