import React from "react";
import Header from "../components/header/Header";
import "./dashboard.css";

function RatingMobile() {
  return (
    <>
      <Header />
      <div className="dashboard-box">
        <div className="mobile-show-hide mobile-recommended">
          <div className="mobile-rating-box">
            <h6 className="rating-mobile-title">Give Feedback</h6>
            <p className="rating-mobile-text">Help us improve the communication between candidates and company</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default RatingMobile;
