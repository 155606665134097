// UserAlertModel.js
import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const paperStyle = {
  backgroundColor: "#ffff",
  border: "2px solid #4CAF50",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  padding: "24px",
  maxWidth: "600px",
  minWidth: "300px",
};

const closeButtonStyle = {
  marginTop: "16px",
  backgroundColor: "#101E36",
  color: "#fff",
  border: "none",
  padding: "10px 20px",
  borderRadius: "4px",
  cursor: "pointer",
};

const UserAlertModel = ({
  open,
  onClose,
  notifications,
  handleNotification,
  userId,
}) => {
  // const { notificaitonMarkRead } = useMain()
  // const hangleMarkRead = async () => {
  //     if(userId){
  //         const res = await notificaitonMarkRead(userId);
  //         console.log(res);
  //         if (res?.statusCode == 200) {
  //             handleNotification()
  //             onClose()
  //         }
  //     }
  // }
  const navigate = useNavigate()
  return (
    <Modal
      style={modalStyle}
      open={open}
      onClose={onClose}
      closeAfterTransition
      //   BackdropComponent={Backdrop}
      //   BackdropProps={{
      //     timeout: 500,
      //   }}
    >
      <Fade in={open}>
        <div style={paperStyle}>
          <Typography variant="h5" gutterBottom>
            Complete Your Profile
          </Typography>
          <Typography variant="body1" gutterBottom>
            100% complete profiles have a higher
          </Typography>
          <Typography variant="body1" gutterBottom>
            chance of getting shortlisted.
          </Typography>
          <Button  variant="contained" onClick={()=>{
            navigate("/set-job")
            onClose()
            }} 
            style={{backgroundColor:"#FFCA27", color:"#2E3D55"}}
            >
            Update Your Profile
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

export default UserAlertModel;
