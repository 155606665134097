import { baseUrl, headers } from "../common/data";

export const loginJobseeker = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/user/login`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};
