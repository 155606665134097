import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { useNavigate } from "react-router-dom";
import closeBtn from "../assets/close-btn.svg";
import downArrow from "../assets/select-down.svg";
import downArrowHover from "../assets/mobile/down-arrow-hover.svg";
import { City } from "country-state-city";
import { useMain } from "../hook/useMain";
import "../App.css";
import "react-select-search/style.css";
import Loader from "../components/loader/Loader";
import "./css/dropdown.css";
import { cityList } from "../context/searchList/cityList";
import useOutsideClick from "./outsideClick/useOutsideClick";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import SearchBar2 from "./SearchBar2";
// import rightCheck from "../assets/select-item-check.svg";

function SetJob() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const initialValue = {
    subcategories: [],
    job: [],
  };
  const initialValue2 = {
    subcategories: [],
    job: [],
  };
  const initialValue3 = {
    subcategories: [],
    job: [],
  };
  const JobPre = [
    {
      id: 1,
      option: "Internship",
      selected: false,
    },
    {
      id: 2,
      option: "Full-Time",
      selected: false,
    },
    {
      id: 3,
      option: "Part-Time",
      selected: false,
    },
    {
      id: 4,
      option: "Remote",
      selected: false,
    },
    {
      id: 5,
      option: "Hybrid",
      selected: false,
    },
  ];
  const Company = [
    {
      id: 1,
      option: "Mnc",
      selected: false,
    },
    {
      id: 2,
      option: "Start-up",
      selected: false,
    },
    {
      id: 3,
      option: "Sme",
      selected: false,
    },
    {
      id: 4,
      option: "Firm",
      selected: false,
    },
    {
      id: 5,
      option: "Non-profit",
      selected: false,
    },
    {
      id: 6,
      option: "Government",
      selected: false,
    },
    {
      id: 7,
      option: "other",
      selected: false,
    },
  ];

  const [value, setValue] = useState("");
  const [cityPreference, setCityPreference] = useState(initialValue);
  const [companyPreference, setCompanyPreference] = useState(initialValue3);

  // const [showModal, setShowModal] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const [jobActive, setJobActive] = useState("");
  const [companyActive, setCompanyActive] = useState("");
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);

  const ref1 = useOutsideClick(handleOutsideClick1);
  const ref2 = useOutsideClick(handleOutsideClick2);
  function handleOnClick1(event) {
    event.preventDefault();
    if (hover2 === false) {
      setHover2(true);
    }
  }
  function handleOnClick2(event) {
    event.preventDefault();
    if (hover3 === false) {
      setHover3(true);
    }
  }

  function handleOutsideClick1() {
    setHover2(false);
  }
  function handleOutsideClick2() {
    setHover3(false);
  }

  const id = localStorage.getItem("userId");
  const userData = JSON.parse(localStorage.getItem("userData"));



  const { Preferences } = useMain();

  // const fileChangedHandler = (e) => {
  //   if (!e.target.files || e.target.files.length === 0) {
  //     setSelectedFile(undefined);
  //     return;
  //   }

  //   setSelectedFile(e.target.files[0]);
  // };
  useEffect(() => {
    if (userData) {
      setCityPreference({
        ...cityPreference,
        subcategories: userData?.cityPreference ? userData?.cityPreference : "",
      });
      setJobPreference({
        ...jobPreference,
        subcategories: userData?.jobPreference ? userData?.jobPreference : "",
      });
      setCompanyPreference({
        ...companyPreference,
        subcategories: userData?.companyPreference
          ? userData?.companyPreference
          : "",
      });
      setJobTitle(userData?.jobTitle ? userData?.jobTitle : "");
    }
  }, []);

  useEffect(() => {
    if (value !== "") {
      if (!cityPreference?.subcategories?.includes(value))
        setCityPreference({
          ...cityPreference,
          subcategories: [...cityPreference?.subcategories, value],
        });
    }
  }, [value]);

  useEffect(() => {
    if (jobActive.trim() !== "") {
      if (!jobPreference?.subcategories?.includes(jobActive))
        setJobPreference({
          ...jobPreference,
          subcategories: [...jobPreference?.subcategories, jobActive],
        });
    }
  }, [jobActive]);

  useEffect(() => {
    if (companyActive.trim() !== "") {
      if (!companyPreference?.subcategories?.includes(companyActive))
        setCompanyPreference({
          ...companyPreference,
          subcategories: [...companyPreference?.subcategories, companyActive],
        });
    }
  }, [companyActive]);

  const handleDeleteSubcategory = (item, type) => {
    if (type === "city") {
      const subcategories = cityPreference?.subcategories?.filter(
        (s, id) => id !== item
      );
      setCityPreference({ ...cityPreference, subcategories });
    }
    if (type === "job") {
      const subcategories = jobPreference?.subcategories?.filter(
        (s, id) => id !== item
      );
      setJobPreference({ ...jobPreference, subcategories });
    }
    if (type === "company") {
      const subcategories = companyPreference?.subcategories?.filter(
        (s, id) => id !== item
      );
      setCompanyPreference({ ...companyPreference, subcategories });
    } else {
    }
  };
  // const selectedValue = cityPreference?.subcategories?.find(x => x===selected)

  const [jobPreference, setJobPreference] = useState(initialValue2);

  const [jobTitle, setJobTitle] = useState("");
  const [loader, setLoader] = useState(false);

  const allState = City?.getCitiesOfState("IN", "GJ")?.map((city) => ({
    value: city.name,
    displayValue: city.name,
  }));

  async function SetPreferences() {
    const data = {
      cityPreference: cityPreference.subcategories,
      jobPreference: jobPreference.subcategories,
      companyPreference: companyPreference.subcategories,
      jobTitle,
    };

    if (cityPreference && jobPreference && companyPreference && jobTitle) {
      setLoader(true);
      const result = await Preferences(data, id);
      if (result?.statusCode === 200) {
        setLoader(false);
        toast.success(result?.message);
        setTimeout(() => {
          localStorage.setItem("userData", JSON.stringify(result?.data));
          navigate("/set-job/switch");
        }, 3000);
      } else {
        setLoader(false);
        toast.error("Somthing went wrong");
      }
    } else {
      toast.info("Please fill all fields");
      setLoader(false);
    }
  }

  console.log(value);

  return (
    <>
      <AlertContainer />
      <Header />
      {loader ? (
        <Loader />
      ) : (
        <section className="job-section">
          <div className="set-job-box">
            <h6 className="set-job-title">Set job preferences</h6>
            <p className="set-job-text">
              Customize your job search experience by setting your job
              preferences today and let us do the rest!
            </p>
            <form>
              <div className="d-flex flex-column">
                <label htmlFor="" className="job-label">
                  City Preferences
                </label>
                <SearchBar2
                  options={cityList}
                  label="name"
                  placeHolder={"Choose preference(s)"}
                  selectedVal={value}
                  handleChange={(val) => setValue(val)}
                />
                {cityPreference?.subcategories?.length > 0 && (
                  <div className="d-flex-inline">
                    {cityPreference?.subcategories?.map((item, index) => (
                      <p className="select-option" key={index}>
                        {item}
                        <img
                          src={closeBtn}
                          alt=""
                          onClick={() => handleDeleteSubcategory(index, "city")}
                        />
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className="d-flex flex-column">
                <label htmlFor="" className="job-label">
                  Job Preferences
                </label>
                <div
                  ref={ref1}
                  className="dropdown"
                  onMouseEnter={() => setIsActive(true)}
                  onMouseLeave={() => setIsActive(false)}
                >
                  <div
                    onClick={handleOnClick1}
                    style={{ outline: isActive ? "2px solid #94A3B8" : "none" }}
                    className="dropdown-btn"
                  >
                    {jobActive === "" ? "Choose preference(s)" : "Choose preference(s)"}
                    <img src={isActive ? downArrowHover : downArrow} alt="" />
                  </div>
                  <div
                    className="dropdown-content"
                    style={{ display: hover2 ? "block" : "none" }}
                  >
                    {JobPre?.map((element, index) => {
                      return (
                        <>
                          <div
                            onClick={(e) => {
                              setJobActive(e.target.textContent);
                              setHover2(false);
                            }}
                            className="item"
                          >
                            {element.option}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                {jobPreference?.subcategories?.length > 0 && (
                  <div className="d-flex-inline">
                    {jobPreference?.subcategories?.map((item, index) => (
                      <p className="select-option" key={index}>
                        {item}
                        <img
                          src={closeBtn}
                          alt=""
                          onClick={() => handleDeleteSubcategory(index, "job")}
                        />
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className="d-flex flex-column">
                <label htmlFor="" className="job-label">
                  Company Preferences
                </label>
                <div
                  ref={ref2}
                  className="dropdown"
                  onMouseEnter={() => setIsActive(true)}
                  onMouseLeave={() => setIsActive(false)}
                >
                  <div
                    onClick={handleOnClick2}
                    style={{ outline: isActive ? "2px solid #94A3B8" : "none" }}
                    className="dropdown-btn"
                  >
                    {companyActive === ""
                      ? "Choose preference(s)"
                      : "Choose preference(s)"}
                    <img src={isActive ? downArrowHover : downArrow} alt="" />
                  </div>
                  <div
                    className="dropdown-content"
                    style={{ display: hover3 ? "block" : "none" }}
                  >
                    {Company?.map((element, index) => {
                      return (
                        <>
                          <div
                            onClick={(e) => {
                              setCompanyActive(e.target.textContent);
                              setHover3(false);
                            }}
                            className="item"
                          >
                            {element.option}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                {companyPreference?.subcategories?.length > 0 && (
                  <div className="d-flex-inline">
                    {companyPreference?.subcategories?.map((item, index) => (
                      <p className="select-option" key={index}>
                        {item}
                        <img
                          src={closeBtn}
                          alt=""
                          onClick={() =>
                            handleDeleteSubcategory(index, "company")
                          }
                        />
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className="form-border"></div>

              <input
                type="text"
                className="job-input"
                placeholder="Enter job title"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
              />
            </form>

            <button
              className="set-job-btn"
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              onClick={SetPreferences}
            >
              Set preferences
            </button>
          </div>
        </section>
      )}

      {/* {showModal && (
        <div
          className="desktop-custom-modal"
          // id="exampleModal"
          // tabIndex="-1"
          // aria-labelledby="exampleModalLabel"
          // aria-hidden="true"
          onClick={() => setShowModal(false)}
        >
          <div className="job-modal">
            <h6 className="job-modal-title">
              How would you like to build profile?
            </h6>
            <button
              className="create-resume-btn"
              onClick={HandleOnCreate}
              // data-bs-dismiss="modal"
            >
              Create Resume
            </button>
            <input
              type="file"
              hidden
              id="file"
              inputprops={{ accept: "image/*" }}
              name="file"
              onChange={fileChangedHandler}
            />
            <label htmlFor="file" className="upload-resume-btn">
              Upload Resume
            </label>
          </div>
        </div>
      )} */}
    </>
  );
}

export default SetJob;
