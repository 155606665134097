import React, { useState } from "react";
import CalenderContext from "./CalenderContext";

const CalenderState = (props) => {
    const [openCalender, setCalender] = useState(false);
    function handleOpen() {
        setCalender(!openCalender)
    }
  return (
    <CalenderContext.Provider
      value={{ openCalender, setCalender, handleOpen }}
    >
      {props.children}
    </CalenderContext.Provider>
  );
};

export default CalenderState;
