import React from 'react'
import './resume.css'

function CoralCard() {
  return (
    <>
    <div className="coral-card">
        <div className="d-flex justify-content-between">
            <h6 className="coral-title">Coral</h6>
            <p className="coral-text">Best for Business</p>
        </div>
        <div className="coral-img"></div>
        <button className="coral-apply">Apply</button>
    </div>
    </>
  )
}

export default CoralCard