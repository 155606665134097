import React from "react";
import "./sub-header.css";
import all from "../../assets/recruiter-subHeader/all.svg";
import Automate from "../../assets/recruiter-subHeader/automate.svg";
import ActiveAll from "../../assets/recruiter-subHeader/light-all.svg";
// import ActiveAutomate from "../../assets/recruiter-subHeader/light-automate.svg";
import Calender from "../../assets/recruiter-subHeader/calender.svg";
import { Link, useLocation } from "react-router-dom";

function InterviewHeader() {
  let path = useLocation();
  let pathName = path.pathname;
  return (
    <>
      <div className="sub-header-container">
        <div className="sub-header justify-content-center">
          <div style={{ display: "flex", gap: "16px" }}>
            <Link
              to="/interview/all-application"
              className={`${
                pathName === "/interview/all-application"
                  ? "sub-active"
                  : "sub-header-dashboard-btn"
              } `}
            >
              <img
                src={
                  pathName === "/interview/all-application" ? ActiveAll : all
                }
                style={{ marginRight: "4px" }}
                alt=""
              />
              Applications
            </Link>
            <Link className="sub-header-dashboard-btn">
              <img src={Automate} style={{ marginRight: "4px" }} alt="" />
              Automate
            </Link>
            <Link
                to="/interview/calender"
              className={`${
                pathName === "/interview/calender"
                  ? "sub-active"
                  : "sub-header-dashboard-btn"
              } `}
            >
              <img
                src={Calender}
                style={{ marginRight: "4px" }}
                alt=""
              />
              Calender
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default InterviewHeader;
