import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import RecruiterHeader from "../header/RecruiterHeader";
// import Autocomplete from "react-autocomplete";
// import rightCheck from "../assets/select-item-check.svg";

function RecruiterMobileUpdate() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [mobileNo, setMobileNo]=useState("")
//   const [cityPreference, setCityPreference] = useState(
//     JSON.parse(localStorage.getItem("recruiterData"))?.designation &&
//       JSON.parse(localStorage.getItem("recruiterData"))?.designation
//   );
  const [loader, setLoader] = useState(false);
  const recruiterName = JSON.parse(localStorage.getItem("recruiterData"));
  const id = JSON.parse(localStorage.getItem("recruiterData"))?._id;
  const { RecruiterUpdateMobileNo } = useMain();

  const handleNuber=(e)=>{
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^+\d]/g, '');
    setMobileNo(inputValue)
  }

  async function GetStarted(e) {
    e.preventDefault();
    setLoader(true);
    if (mobileNo) {
      const result = await RecruiterUpdateMobileNo({ mobileNo:mobileNo }, id);
      if (result?.statusCode === 200) {
        setLoader(false);
        localStorage.setItem("recruiterData", JSON.stringify(result?.data));
        navigate("/recruiter-dashboard");
        toast.success(result.message);
      } else {
        toast.error("somthing went wrong");
        setLoader(false);
      }
    } else {
      toast.info("Please enter mobile number.");
      setLoader(false);
    }
  }

  return (
    <>
      <AlertContainer/>
      <RecruiterHeader />
      {loader ? (
        <Loader />
      ) : (
        <section className="job-section">
          <div className="set-job-box">
            <h6 className="set-job-title">Hi {recruiterName?.name}</h6>
            <p className="set-job-text">
              Build your profile for a seamless hiring experience
            </p>
            <div className="form-border"></div>
            <form>
              <div className="d-flex flex-column">
                <label htmlFor="" className="job-label">
                  Mobile Number
                </label>
                <input
                  type="text"
                  placeholder="Enter Mobile Number"
                  className="resume-input"
                  onChange={handleNuber}
                  value={mobileNo}
                  maxLength={13}
                />
              </div>
              <button
                type="submit"
                className="set-job-btn"
                onClick={GetStarted}
              >
                Update Mobile
              </button>
            </form>
          </div>
        </section>
      )}
    </>
  );
}

export default RecruiterMobileUpdate;
