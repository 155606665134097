import React from "react";
import Subscribe from "../../assets/recruiter/sub-done.svg";

function MonthlyOne() {
  return (
    <>
      <div className={`upgrade-card-three`}>
        <div className="d-flex flex-column h-100 justify-content-between">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="upgrade-box-title m-0">Starter</h6>
              <p className="upgrade-box-text m-0" style={{textTransform: "capitalize"}}>free</p>
            </div>
            <div className={`upgrade-box-border-three`}></div>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" /> 1
              Active Job Post Every Month
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" /> 100
              Candidates
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              ATS
            </p>
          </div>
          <button className="upgrade-starter-btn">Upgrade to Starter</button>
        </div>
      </div>
    </>
  );
}

export default MonthlyOne;
