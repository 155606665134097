import React, { useContext, useEffect, useState } from "react";
import "./dashboard.css";
import Header from "../components/header/Header";
import Sidebar from "../components/sideBar/Sidebar";
import SideBarContext from "../context/SidebarContext";
import CareerSlider from "./CareerSlider";
import calender from "../assets/calender.svg";
import jobIcon from "../assets/job-icon.svg";
import chat from "../assets/chat-track.svg";
import round from "../assets/talent/round.svg";
import top from "../assets/top-arrow.svg";
import CalenderModal from "../components/calenderModal/CalenderModal";
import CalenderContext from "../context/calender/CalenderContext";
import MobileMenu from "../components/mobileMenu/MobileMenu";
import MobileContext from "../context/mobile/MobileContext";
import { useMain } from "../hook/useMain";
import CareerChat from "./Chat/CareerChat";
import Loader from "../components/loader/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function BusinessCard({ data }) {
  const modal = useContext(CalenderContext);
  const [toggle, setToggle] = useState(false);
  const { handleOpen } = modal;

  const { BookCounsleores } = useMain();

  const bookCounselors = async (id) => {
    const userId = localStorage.getItem("userId");

    const result = await BookCounsleores({ userId }, id);
    if (result?.statusCode === 200) {
      toast.success("Applied successfully.");
    } else {
      toast.error("Already applied.");
    }
  };

  return (
    <>
      {data?.length > 0 &&
        data?.map((item, index) => (
          <div key={index} className="career-mobile-card">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <button className="career-mobile-actively m-0">Mentor</button>
              <p className="career-mobile-hour m-0">499/- Per Hour</p>
            </div>
            <div className="d-flex align-items-center">
              <img src={round} alt="" />
              <div className="ps-3">
                <h6 className="career-mobile-card-title m-0">{item?.name}</h6>
                <p className="career-mobile-card-text m-0">10 Years Of Exp</p>
              </div>
            </div>
            <div className="career-mobile-card-border"></div>
            <p className="career-mobile-item m-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum a
              beatae reiciendis maxime cum minus magni quasi cupiditate, eum,
              ipsam molestiae enim ullam qui veniam!
            </p>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ gap: "8px" }}
            >
              <div
                className="book-your-slot"
                // onClick={() => bookCounselors(item?._id)}
              >
                <a
                  href="https://calendly.com/recruitifie/career-counselling-clone"
                  target="_blank"
                >
                  <button>Book Your Slot</button>
                </a>

                <img src={calender} alt="" />
              </div>
              <div
                className="track-card me-0 d-flex flex-row h-auto"
                style={{
                  gap: "4px",
                  borderRadius: "8px",
                  border: "1px solid #F1F5F9",
                  background: "#F8FAFC",
                  padding: "6px ",
                }}
                onClick={() => setToggle(index)}
              >
                <img src={chat} alt="" />
                <h6 className="track-text m-0">Chat</h6>
              </div>
            </div>
            {/* <div className="d-flex align-items-center justify-content-between">
              <div
                className="book-your-slot"
                onClick={() => bookCounselors(item?._id)}
              >
                <button>Book Your Slot</button>
                <img src={calender} alt="" />
              </div>
            </div> */}
            {toggle === index && (
              <CareerChat setToggle={setToggle} senderId={item?._id} />
            )}
          </div>
        ))}
    </>
  );
}

function CommunicationCard({ data }) {
  const [toggle, setToggle] = useState(false);
  const modal = useContext(CalenderContext);
  const { handleOpen } = modal;

  return (
    <>
      {data?.length > 0 &&
        data?.map((item, index) => (
          <div key={index} className="career-mobile-card">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <button className="career-mobile-actively m-0">Mentor</button>
              <p className="career-mobile-hour m-0">499/- Per Hour</p>
            </div>
            <div className="d-flex align-items-center">
              <img src={round} alt="" />
              <div className="ps-3">
                <h6 className="career-mobile-card-title m-0">{item?.name}</h6>
                <p className="career-mobile-card-text m-0">10 Years Of Exp</p>
              </div>
            </div>
            <div className="career-mobile-card-border"></div>
            <p className="career-mobile-item m-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum a
              beatae reiciendis maxime cum minus magni quasi cupiditate, eum,
              ipsam molestiae enim ullam qui veniam!
            </p>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ gap: "8px" }}
            >
              <div className="book-your-slot" 
              // onClick={handleOpen}
              >
                <a
                  href="https://calendly.com/recruitifie/career-counselling-clone"
                  target="_blank"
                >
                  <button>Book Your Slot</button>
                </a>
                <img src={calender} alt="" />
              </div>
              <div
                className="track-card me-0 d-flex flex-row h-auto"
                style={{
                  gap: "4px",
                  borderRadius: "8px",
                  border: "1px solid #F1F5F9",
                  background: "#F8FAFC",
                  padding: "6px ",
                }}
                onClick={() => setToggle(index)}
              >
                <img src={chat} alt="" />
                <h6 className="track-text m-0">Chat</h6>
              </div>
            </div>
            {/* <div className="d-flex align-items-center justify-content-between">
              <div className="book-your-slot" onClick={handleOpen}>
                <button>Book Your Slot</button>
                <img src={calender} alt="" />
              </div>
            </div> */}
            {toggle === index && (
              <CareerChat setToggle={setToggle} senderId={item?._id} />
            )}
          </div>
        ))}
    </>
  );
}

function CareerPage() {
  const navigate =useNavigate()
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const sidebar = useContext(SideBarContext);
  const menuItem = useContext(MobileContext);
  const { isSidebarOpen } = sidebar;
  const { handleMobileToggle, mobileMenu } = menuItem;

  const { GetAllCarrers } = useMain();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoader(true);
    const result = await GetAllCarrers();
    if (result?.statusCode === 200) {
      setData(result?.data);
      setLoader(false);
    }
  };

  return (
    <>
      <Header />
      {loader && <Loader />}

      <section className="dashboard">
        <div className="desktop-show-hide">
          <div className="dashboard-box">
            <div className="sidebar-scroll">
              <Sidebar />
            </div>
            <div
              className="career-box"
              style={{ left: isSidebarOpen ? "85px" : "354px" }}
            >
              <h6 className="career-page-title">Available Career Counselors</h6>
              <p className="career-text m-0">Business</p>
              <CareerSlider data={data} />
              {/* <p className="career-text m-0">Communication</p>
              <CareerSlider data={data} /> */}
            </div>
          </div>
        </div>

        {/* mobile */}
        <div className="mobile-show-hide mobile-box">
          <h6 className="mobile-track-title m-0">Jobs/Internships</h6>
          <p className="m-0 mobile-box-text">Business</p>
          <BusinessCard data={data} />
          {/* <BusinessCard data={data} /> */}
          {/* <p className="m-0 mobile-box-text">Communication</p>
          <CommunicationCard data={data} /> */}
          {/* <CommunicationCard data={data} />
          <CommunicationCard data={data} />
          <CommunicationCard data={data} />
          <CommunicationCard data={data} /> */}
          <MobileMenu />
          <div className="mobile-menu-btn" onClick={handleMobileToggle}>
            <img
              src={top}
              alt=""
              style={{
                transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 150ms ease",
                height: "14px",
              }}
            />
            <img src={jobIcon} alt="" />
          </div>
        </div>
      </section>
      {/* <CalenderModal /> */}
      {/* <CareerChat /> */}
    </>
  );
}

export default CareerPage;
