import React, { useState } from "react";
// import Header from "../../components/header/Header";
import arrow from "../../assets/login/left-arrow.svg";
import hoverArrow from "../../assets/login/left-arrow-hover.svg";
import { useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import eyesHide from "../../assets/eyes-show.svg";
import eyes from "../../assets/eyes-hide.svg";
import "./interviewer.css";
import LoginHeader from "../header/LoginHeader";
import { toast } from 'react-toastify'
import { AlertContainer } from "../Alerts/ToastifyContainer";
import IntervieewerHeader from "../header/IntervieewerHeader";

function InterviewSignUp() {
  const [showArrow, setShowArrow] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyes);
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  console.log(data);
  const { loginInterviewer } = useMain();

  const handleToggle = (e) => {
    e.preventDefault();
    if (type === "password") {
      setIcon(eyesHide);
      setType("text");
    } else {
      setIcon(eyes);
      setType("password");
    }
  };
  // function SignIn () {
  // }
  const SignIn = async (e) => {
    e.preventDefault();
    const res = await loginInterviewer(data);
    // console.log(res)
    if (res?.statusCode == 200) {
      toast.success("Login successful.");
      localStorage.setItem(
        "Interviewer_data",
        JSON.stringify(res?.data?.Interviewer)
      );
      localStorage.setItem(
        "Interviewer_token",
        JSON.stringify(res?.data?.token)
      );
      navigate(`/interview/all-application`);
    }else{
      toast.error("Login failed.");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  return (
    <>
    <AlertContainer />
      <IntervieewerHeader />
      <section className="login-hero">
        <div className="login-container">
          <div className="sign-up">
            <div className="text-center">
              <h6 className="sign-title">Sign in</h6>
              <p className="m-0 sign-text mb-2">
                Sign in to your account for endless possibilities.
              </p>
              <form>
                <input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  className="sign-input"
                  value={data?.email}
                  onChange={handleChange}
                />
                <label htmlFor="pass" className={"sign-input"}>
                  <input
                    type={type}
                    placeholder="Password"
                    id="pass"
                    style={{
                      border: "none",
                      outline: "none",
                      width: "100%",
                      left: "0",
                    }}
                    name="password"
                    value={data?.password}
                    onChange={handleChange}
                  />
                  <button className="show-password-btn" onClick={handleToggle}>
                    <img src={icon} alt="" />
                  </button>
                </label>
                <button
                  className="sign-up-btn m-0"
                  onMouseEnter={() => setShowArrow(true)}
                  onMouseLeave={() => setShowArrow(false)}
                  onClick={SignIn}
                >
                  Sign in
                  <img
                    src={showArrow ? hoverArrow : arrow}
                    style={{ marginLeft: "4px" }}
                    alt=""
                  />
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InterviewSignUp;
