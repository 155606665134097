import React, { useEffect, useRef, useState } from "react";
// import Header from "../components/header/Header";
import info from "../assets/information.svg";
import plush from "../assets/plush.svg";
import Delete from "../assets/delete.svg";
import { useDropzone } from "react-dropzone";
import close from "../assets/x.svg";
import done from "../assets/done.svg";
import "../App.css";
import "./css/dropdown.css";
import { NavLink, useNavigate } from "react-router-dom";
import number1 from "../assets/mobile/number1.svg";
import number2 from "../assets/mobile/number2.svg";
import number3 from "../assets/mobile/number3.svg";
import number4 from "../assets/mobile/number4.svg";
import downArrow from "../assets/mobile/down-arrow.svg";
import downArrowHover from "../assets/mobile/down-arrow-hover.svg";
import FormHeader from "../components/header/FormHeader";
import CalenderImg from "../assets/calender/calender.svg";
import uploadBtn from "../assets/calender/upload.svg";
import { State } from "country-state-city";
import Calendar from "react-calendar";
import closeBtn from "../assets/close-bt.svg";
import moment from "moment/moment";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import SearchBar from "./SearchBar";
import CitySearch from "./CitySearch";
import { cityList } from "../context/searchList/cityList";
import useOutsideClick from "./outsideClick/useOutsideClick";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import Header from "../components/header/Header";

const selectItem = [
  {
    id: 1,
    option: "Employed",
    selected: false,
  },
  {
    id: 2,
    option: "Serving Notice Period",
    selected: false,
  },
  {
    id: 3,
    option: "Unemployed",
    selected: false,
  },
  {
    id: 4,
    option: "Working Part-time",
    selected: false,
  },
  {
    id: 5,
    option: "Student",
    selected: false,
  },
];
const selectItemTwo = [
  {
    id: 1,
    option: "Married",
    selected: false,
  },
  {
    id: 2,
    option: "Single",
    selected: false,
  },
  {
    id: 3,
    option: "Divorced",
    selected: false,
  },
];
const selectItemThree = [
  {
    id: 1,
    option: "HSC",
    selected: false,
  },
  {
    id: 2,
    option: "SSC",
    selected: false,
  },
  {
    id: 3,
    option: "Bachelors",
    selected: false,
  },
  {
    id: 4,
    option: "Masters",
    selected: false,
  },
  {
    id: 5,
    option: "Post Graduate",
    selected: false,
  },
  {
    id: 6,
    option: "PGDM",
    selected: false,
  },
];
const cgp = [
  {
    id: 1,
    option: "CGPA",
    selected: false,
  },
  {
    id: 2,
    option: "Percentage",
    selected: false,
  },
];

function CreateResume() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("auth") === "true";
    if (!isAuthenticated) {
      navigate("/log-in");
      return;
    }
  }, []);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (userData) {
      setAcademicData({
        name: userData?.name && userData?.name,
        email: userData?.email && userData?.email,
        phone: userData?.phone && userData?.phone,
        dob: userData?.dob && userData?.dob,
        pincode: userData?.pincode && Number(userData?.pincode),
        workingStatus: userData?.workingStatus
          ? userData?.workingStatus
          : "Working status",
        maritalStatus: userData?.maritalStatus
          ? userData?.maritalStatus
          : "Marital status",
      });
      setCity(userData?.city ? userData?.city : "");
      setValue(userData?.state ? userData?.state : "");

      setAcademic2(
        userData?.academic?.length > 0
          ? userData?.academic
          : [
              {
                instituteName: "",
                degreeName: "",
                degreeType: "Degree Type",
                percentage: "",
                percentageType: "CGPA",
                startYear: "",
                endYear: "",
                highlight: "",
              },
            ]
      );

      setCerttificate(
        userData?.certificates.length > 0
          ? userData?.certificates
          : [
              {
                certificateName: "",
                certificateLink: "",
                issueDate: "",
              },
            ]
      );
    }
  }, []);

  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const [dropdown5, setDropdown5] = useState(false);
  const [dropdown6, setDropdown6] = useState(false);
  const [mobile1, setMobile1] = useState(true);
  const [mobile2, setMobile2] = useState(true);
  const [mobile3, setMobile3] = useState(true);
  const [dropHover2, setDropHover2] = useState(false);
  const [dropHover3, setDropHover3] = useState(false);
  const [dropHover4, setDropHover4] = useState(false);
  const ref1 = useOutsideClick(handleOutsideClick1);
  const ref2 = useOutsideClick(handleOutsideClick2);
  const ref3 = useOutsideClick(handleOutsideClick3);
  const ref4 = useOutsideClick(handleOutsideClick4);

  function handleOnClick1(event) {
    event.preventDefault();
    if (dropdown1 === false) {
      setDropdown1(true);
    }
  }
  function handleOnClick2(event) {
    event.preventDefault();
    if (dropdown2 === false) {
      setDropdown2(true);
    }
  }
  function handleOnClick3(event) {
    event.preventDefault();
    if (dropdown5 === false) {
      setDropdown5(true);
    }
  }
  function handleOnClick4(event) {
    event.preventDefault();
    if (dropdown6 === false) {
      setDropdown6(true);
    }
  }

  function handleOutsideClick1() {
    setDropdown1(false);
  }
  function handleOutsideClick2() {
    setDropdown2(false);
  }
  function handleOutsideClick3() {
    setDropdown5(false);
  }
  function handleOutsideClick4() {
    setDropdown6(false);
  }

  const [dateBirth, setDateBirth] = useState("");
  const [dateModal, setDateModal] = useState(false);
  function handleDateOpen() {
    setDateModal(!dateModal);
  }

  const [startModal, setStartModal] = useState(false);
  function handleStartOpen(index) {
    setStartModal(index);
  }

  const [passingModal, setPassingModal] = useState(false);

  function handlePassingOpen(index) {
    setPassingModal(index);
  }
  const [receivedModal, setReceivedModal] = useState(false);

  function handleReceivedOpen(index) {
    setReceivedModal(index);
  }

  const imageRef = useRef();
  // const [year, setYear] = useState("");
  const InitialValues = {
    name: "",
    phoneNumber: "",
    subcategories: [],
    image: null,
  };

  const [formData, setFormData] = useState(InitialValues);

  const handleDivClick = () => {
    imageRef?.current?.click();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleImage = (e, img) => {
    setFormData({ ...formData, [img]: e.target?.files[0] });
  };

  const [over, setOver] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const id = localStorage.getItem("userId");

  // function AddEducation() {
  //   const updatedList = [...education, { universityName: "", price: "" }];
  //   setEducation(updatedList);
  // }
  // function AddCertificates() {
  //   const updatedList = [...certificates, { universityName: "", price: "" }];
  //   setCerttificates(updatedList);
  // }
  const allState = State.getStatesOfCountry("IN", "TG").map((city) => ({
    value: city.name,
    displayValue: city.name,
  }));

  // Search State Start
  const [loader, setLoader] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    // multiple: false,
    maxSize: 10 * 1024 * 1024, // 10MB in bytes
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles[0]?.type == "application/pdf") {
        setLoader(true);
        const result = await UpdateResume(acceptedFiles[0], id);
        if (result?.statusCode === 200) {
          alert(result?.message);
          setSelectedFile(true);
          setLoader(false);
        } else {
          alert("Somthig went wrong");
          setSelectedFile(null);
          setLoader(false);
        }
      } else {
        alert("Please select valid pdf.");
        setSelectedFile(null);
        setLoader(false);
      }
    },
  });

  function removeFile() {
    setSelectedFile(null);
  }

  function Dropdown1() {
    setMobile1(!mobile1);
  }
  function Dropdown2() {
    setMobile2(!mobile1);
  }
  function Dropdown3() {
    setMobile3(!mobile1);
  }

  const [working, setWorking] = useState(false);
  const [value, setValue] = useState("Select state");
  const [city, setCity] = useState("");

  function DeleteCertificates(id) {
    const certificatesList = Object.assign([], certificate);
    let filteredArray = certificatesList.filter((item, index) => index !== id);
    setCerttificate(filteredArray);
  }

  const { CreateResumeOnce, UpdateResume } = useMain();

  // async function UploadResume() {
  //   if (selectedFile !== null) {
  //     const result = await UpdateResume(selectedFile, id);
  //     if (result?.statusCode === 200) {
  //       alert(result?.message);
  //       // setSelectedFile(null);
  //     } else {
  //       alert("Somthig went wrong");
  //     }
  //   }
  // }

  const [cert1, setCert1] = useState(null);
  const [cert2, setCert2] = useState(null);
  const [cert3, setCert3] = useState(null);
  const [cert4, setCert4] = useState(null);
  const [cert5, setCert5] = useState(null);
  const cart = [cert1, cert2, cert3, cert4, cert5];

  const updateFiles = (e, index) => {
    if (e?.target?.files[0].type === "application/pdf") {
      if (index === 0) {
        setCert1(e.target.files[0]);
      } else if (index === 1) {
        setCert2(e.target.files[0]);
      } else if (index === 2) {
        setCert3(e.target.files[0]);
      } else if (index === 3) {
        setCert4(e.target.files[0]);
      } else {
        setCert5(e.target.files[0]);
      }
    } else {
      toast.info("Please select a valid PDF file.");
    }
  };

  const [academicData, setAcademicData] = useState({
    name: "",
    phone: "",
    email: "",
    dob: "",
    city: city,
    state: value,
    pincode: "",
    workingStatus: "Working Status",
    maritalStatus: "Marital Status",
  });

  const [academic2, setAcademic2] = useState([
    {
      instituteName: "",
      degreeName: "",
      degreeType: "Degree Type",
      percentage: "",
      percentageType: "CGPA",
      startYear: "",
      endYear: "",
      highlight: "",
    },
  ]);

  const [certificate, setCerttificate] = useState([
    {
      certificateName: "",
      certificateLink: "",
      issueDate: "",
    },
  ]);

  const handleInputChange = (event, index, property) => {
    const updatedData2 = [...academic2];
    updatedData2[index][property] = event.target.value;
    setAcademicData(updatedData2);
  };

  function DeleteEducation(id) {
    const educationList = Object.assign([], academic2);
    let filteredArray = educationList.filter((item, index) => index !== id);
    setAcademic2(filteredArray);
  }
  async function handleOnSave() {
    setLoader(true);
    // const data = {
    //   name: academicData.name,
    //   phone: academicData.phone,
    //   email: academicData.email,
    //   dob: academicData.dob,
    //   city: academicData.city,
    //   state: academicData.state,
    //   pincode: academicData.pincode,
    //   workingStatus:
    //     academicData.workingStatus === "Working Status"
    //       ? ""
    //       : academicData.workingStatus,
    //   maritalStatus:
    //     academicData.maritalStatus === "Marital Status"
    //       ? ""
    //       : academicData.maritalStatus,
    //   academic: academic2,
    //   certificates: certificate,
    // };

    const result = await CreateResumeOnce(
      { ...academicData, city, state: value },
      academic2,
      certificate,
      cert1,
      cert2,
      cert3,
      cert4,
      cert5,
      id
    );
    if (result?.statusCode === 200) {
      toast.success(result?.message);
      setLoader(false);
      localStorage.setItem("userData", JSON.stringify(result?.data));
      navigate("/build-resume");
    } else {
      toast.error("Somthing went wrong");
      setLoader(false);
    }
  }

  return (
    <>
      <AlertContainer />
      <Header title="Build Profile" />
      {loader ? (
        <Loader />
      ) : (
        <section className="create-resume">
          <div className="mobile-show-hide">
            <div className="mobile-line-box">
              <div className="mobile-line"></div>
              <div className="d-flex justify-content-around number-line">
                <img src={number1} alt="" />
                <img src={number2} alt="" />
                <img src={number3} alt="" />
                <img src={number4} alt="" />
              </div>
            </div>
            <div className="build-box-mobile">
              <h6 className="build-title">Build your profile</h6>
              <p className="build-text">
                With a well-crafted professional profile, you'll showcase your
                expertise and attract the attention of potential employers. Good
                luck!
              </p>
              <div className="text-center send-resume-btn" {...getRootProps()}>
                <input {...getInputProps()} />
                {selectedFile === null ? (
                  <label htmlFor="file" className="auto-button">
                    Auto upload Resume
                  </label>
                ) : (
                  <button className="done-btn">
                    Uploaded
                    <img className="ps-2" src={done} alt="" />
                  </button>
                )}
                {selectedFile === null ? (
                  <p className="information">
                    <img src={info} alt="" /> Upload max 10MB .pdf file only
                  </p>
                ) : (
                  <p className="file-name">
                    {selectedFile.name}{" "}
                    <button onClick={removeFile} className="close-btn">
                      <img src={close} alt="" />
                    </button>
                  </p>
                )}
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={() => setMobile1(!mobile1)}
              >
                <p className="personal-title m-0">Personal Details</p>
                <img src={downArrow} alt="" />
              </div>
              <div
                className="resume-box"
                style={
                  mobile1 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Name"
                      className="resume-input"
                      name="name"
                      value={academicData?.name}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      name="phone"
                      value={academicData?.phone}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                      placeholder="Phone number"
                      className="resume-input"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    {/* email */}
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      className="resume-input"
                      value={academicData?.email}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="calender-date-input"
                      onClick={handleDateOpen}
                    >
                      <input
                        placeholder="Date of birth"
                        className="input-date"
                        type="text"
                        value={academicData?.dob}
                        name="dob"
                      />
                      <img
                        src={CalenderImg}
                        className="input-calender"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <CitySearch
                      options={cityList}
                      label="name"
                      selectedVal={city}
                      handleChange={(val) => setCity(val)}
                      placeHolder="Current city"
                    />
                    {/* <input
                      type="text"
                      placeholder="Current city"
                      className="resume-input"
                      name="city"
                      value={academicData.city}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    /> */}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <SearchBar
                      options={allState}
                      label="displayValue"
                      selectedVal={value}
                      handleChange={(val) => {
                        setValue(val);
                      }}
                      placeHolder="Current state"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      ref={ref3}
                      className="dropdown"
                      onMouseEnter={() => setDropHover2(true)}
                      onMouseLeave={() => setDropHover2(false)}
                    >
                      <div
                        onClick={handleOnClick3}
                        style={{
                          outline: working ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData.workingStatus}
                        <img
                          src={dropdown5 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: dropdown5 ? "block" : "none" }}
                      >
                        {selectItem.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["workingStatus"]: e.target.textContent,
                                });
                                setDropdown5(false);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Pin code"
                      className="resume-input"
                      name="pincode"
                      value={academicData.pincode}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      ref={ref4}
                      className="dropdown"
                      onMouseEnter={() => setDropHover3(true)}
                      onMouseLeave={() => setDropHover3(false)}
                    >
                      <div
                        onClick={handleOnClick4}
                        style={{
                          outline: dropdown6 ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData?.maritalStatus}
                        <img
                          src={dropdown6 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: dropdown6 ? "block" : "none" }}
                      >
                        {selectItemTwo.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["maritalStatus"]: e.target.textContent,
                                });
                                setDropdown6(false);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={() => setMobile2(!mobile2)}
              >
                <p className="personal-title m-0">Academic Details</p>
                <img src={downArrow} alt="" />
              </div>
              <div
                className="resume-box"
                style={
                  mobile2 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                {academic2.map((element, index) => {
                  return (
                    <>
                      <div className="row" key={index}>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Institute/University name"
                            className="resume-input"
                            value={academic2[index]?.instituteName}
                            name="instituteName"
                            onChange={(e) => {
                              const updatedData2 = [...academic2];
                              updatedData2[index][e.target.name] =
                                e.target.value;
                              setAcademic2(updatedData2);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div>
                            {dropdown3 === index ? (
                              <div
                                className="select-overlay"
                                onClick={() => setDropdown3(false)}
                              ></div>
                            ) : (
                              ""
                            )}
                            <div
                              className="dropdown"
                              onMouseEnter={() => setDropHover4(true)}
                              onMouseLeave={() => setDropHover4(false)}
                              style={{
                                zIndex: dropdown3 === index ? "1112" : "",
                              }}
                            >
                              <div
                                onClick={(e) => setDropdown3(index)}
                                style={{
                                  outline: dropdown3
                                    ? "2px solid #94A3B8"
                                    : "none",
                                }}
                                className="dropdown-btn"
                              >
                                {academic2[index]?.degreeType}
                                <img
                                  src={dropHover4 ? downArrowHover : downArrow}
                                  alt=""
                                />
                              </div>
                              <div
                                className="dropdown-content"
                                style={{
                                  display:
                                    index === dropdown3 ? "block" : "none",
                                }}
                              >
                                {selectItemThree.map((element, i) => {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].degreeType =
                                          e.target.textContent;
                                        setAcademic2(updatedData);
                                        setDropdown3(false);
                                      }}
                                      className="item"
                                    >
                                      {element.option}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Degree name"
                            className="resume-input"
                            name="degreeName"
                            value={academic2[index]?.degreeName}
                            onChange={(e) => {
                              const updatedData2 = [...academic2];
                              updatedData2[index][e.target.name] =
                                e.target.value;
                              setAcademic2(updatedData2);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="row">
                            <div
                              className="col-8"
                              style={{ paddingRight: "4px" }}
                            >
                              <input
                                type="text"
                                placeholder="0"
                                className="resume-input"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                name="percentage"
                                value={academic2[index].percentage}
                                onChange={(e) => {
                                  const updatedData2 = [...academic2];
                                  updatedData2[index][e.target.name] =
                                    e.target.value;
                                  setAcademic2(updatedData2);
                                }}
                              />
                            </div>
                            <div className="col-4 p-0">
                              <div>
                                {dropdown4 === index ? (
                                  <div
                                    className="select-overlay"
                                    onClick={() => setDropdown4(false)}
                                  ></div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="dropdown"
                                  onMouseEnter={() => setDropHover4(true)}
                                  onMouseLeave={() => setDropHover4(false)}
                                  style={{
                                    zIndex: dropdown4 === index ? "1112" : "",
                                  }}
                                >
                                  <div
                                    onClick={(e) => setDropdown4(index)}
                                    style={{
                                      outline:
                                        index === dropdown4
                                          ? "2px solid #94A3B8"
                                          : "none",
                                    }}
                                    className="dropdown-btn"
                                  >
                                    {academic2[index]?.percentageType}
                                    <img
                                      src={
                                        dropHover4 ? downArrowHover : downArrow
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    className="dropdown-content"
                                    style={{
                                      display:
                                        index === dropdown4 ? "block" : "none",
                                    }}
                                  >
                                    {cgp.map((element, i) => {
                                      return (
                                        <div
                                          onClick={(e) => {
                                            const updatedData = [...academic2];
                                            updatedData[index].percentageType =
                                              e.target.textContent;
                                            setAcademic2(updatedData);
                                            setDropdown4(false);
                                          }}
                                          className="item"
                                        >
                                          {element.option}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={handleStartOpen}
                          >
                            <input
                              placeholder="Starting year (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={academic2[index]?.startYear}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {startModal ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={() => setStartModal(false)}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].startYear =
                                          moment(e).format("MM-YYYY");
                                        setAcademic2(updatedData);
                                        setStartModal(false);
                                      }}
                                      // value={academic2[index]?.startYear}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={handlePassingOpen}
                          >
                            <input
                              placeholder="Passing year (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={academic2[index]?.endYear}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {passingModal ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={() => setPassingModal(false)}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].endYear =
                                          moment(e).format("MM-YYYY");
                                        setAcademic2(updatedData);
                                        setPassingModal(false);
                                      }}
                                      // value={academic2[index]?.endYear}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 ">
                          <textarea
                            name=""
                            id=""
                            style={{ resize: "none" }}
                            placeholder="Write about the highlights of your journey "
                            className="resume-input m-0"
                            cols="30"
                            rows="3"
                            value={academic2[index]?.highlight}
                            onChange={(e) => {
                              const updatedData = [...academic2];
                              updatedData[index].highlight = e.target.value;
                              setAcademic2(updatedData);
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="tooltip-container">
                        <div
                          className="mobile-tooltip-box"
                          style={{ display: over ? "block" : "none" }}
                        >
                          <div
                            className="tooltip-close"
                            onClick={() => setOver(false)}
                          ></div>
                          The highlights of your journey create a powerful
                          impact by showcasing your accomplishments, growth, and
                          potential. They provide insight into your professional
                          trajectory and demonstrate the value you bring to the
                          table.
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ paddingBottom: "12px", cursor: "pointer" }}
                          onClick={() => setOver(!over)}
                        >
                          <img src={info} className="pe-2" alt="" />
                          <p className="information-text">
                            What impact would highlights create
                          </p>
                        </div>
                      </div>

                      <div className="d-flex flex-column-reverse flex-column" style={{marginBottom: academic2.length > 1 ? "10px" : "0px"}}>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={(e) =>
                            setAcademic2([
                              ...academic2,
                              {
                                instituteName: "",
                                degreeName: "",
                                degreeType: "Degree Type",
                                percentage: "",
                                percentageType: "CGPA",
                                startYear: "",
                                endYear: "",
                                highlight: "",
                              },
                            ])
                          }
                          className="plush-text p-0"
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add education
                        </button>
                        <div
                          style={{
                            borderBottom: "1px solid #CBD5E1",
                            margin: "12px 0",
                          }}
                        ></div>
                        {academic2.length > 1 && (
                          <button
                            style={{
                              border: "none",
                              cursor: "pointer",
                              backgroundColor: "transparent",
                            }}
                            disabled={academic2.length === 1}
                            onClick={() => DeleteEducation(index)}
                            className="plush-text p-0"
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="personal-from">
              <div className="" onClick={() => setMobile3(!mobile3)}>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="personal-title m-0">Certificates awarded</p>
                  <img src={downArrow} alt="" />
                </div>
                <p
                  className="resume-text"
                  style={{
                    color: "#4D4D4D",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    marginTop: "8px",
                    marginBottom: "0px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "12px",
                  }}
                >
                  Upload certificates awarded during your Academic years
                </p>
              </div>
              <div
                className="resume-box"
                style={
                  mobile3 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                <div className="resume-box">
                  {certificate.map((element, index) => {
                    return (
                      <>
                        <div className="row" key={index}>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <input
                              type="text"
                              placeholder="Certificate Name"
                              name="certificateName"
                              className="resume-input"
                              value={certificate[index].certificateName}
                              onChange={(e) => {
                                const updatedData = [...certificate];
                                updatedData[index][e.target.name] =
                                  e.target.value;
                                setCerttificate(updatedData);
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <div
                              htmlFor={`file-upload${index}`}
                              onClick={handleDivClick}
                              className="custom-file-upload"
                            >
                              {cart[index] !== null
                                ? cart[index]?.name
                                : "Upload certificate"}
                              <input
                                id={`file-upload${index}`}
                                ref={imageRef}
                                className="custom-file-input"
                                type="file"
                                name="certificateLink"
                                accept="application/pdf"
                                onChange={(e) => {
                                  updateFiles(e, index);
                                }}
                              />
                              <img src={uploadBtn} alt="" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <div
                              className="calender-date-input"
                              onClick={handleReceivedOpen}
                            >
                              <input
                                placeholder="Received (mm/yyyy)"
                                className="input-date"
                                type="text"
                                value={certificate[index]?.issueDate}
                              />
                              <img
                                src={CalenderImg}
                                className="input-calender"
                                alt=""
                              />
                            </div>
                            {receivedModal ? (
                              <div className="calender-modal">
                                <div className="calender-box-2">
                                  <button
                                    className="close-btn"
                                    onClick={() => setReceivedModal(false)}
                                  >
                                    <img src={closeBtn} alt="" />
                                  </button>
                                  <div className="text-center">
                                    <h6 className="calender-title m-0">
                                      Choose date slot(s)
                                    </h6>
                                    <div className="calender">
                                      <Calendar
                                        onChange={(e) => {
                                          const updatedData = [...certificate];
                                          updatedData[index].issueDate =
                                            moment(e).format("MM-YYYY");
                                          setCerttificate(updatedData);
                                          setReceivedModal(false);
                                        }}
                                        // value={received}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      
                        <div className="d-flex flex-column-reverse flex-column" style={{marginBottom: certificate.length > 1 ? "10px" : "0px"}}>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            onClick={(e) =>
                              setCerttificate([
                                ...certificate,
                                {
                                  certificateName: "",
                                  certificateLink: "",
                                  issueDate: "",
                                },
                              ])
                            }
                            className="plush-text p-0"
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add certificate
                          </button>
                          <div
                          style={{
                            borderBottom: "1px solid #CBD5E1",
                            margin: "12px 0",
                          }}
                        ></div>
                          {certificate.length > 1 && (
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                              }}
                              className="plush-text p-0"
                              disabled={certificate.length === 1}
                              onClick={() => DeleteCertificates(index)}
                            >
                              <img src={Delete} className="pe-2" alt="" />
                              Delete
                            </button>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="bottom-btn">
              <div className="text-center">
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <button
                  className="skip-btn"
                  onClick={() => navigate("/build-resume")}
                >
                  Skip for now
                </button>
              </div>
            </div>
          </div>

          {/* Desktop Screen */}
          <div className="desktop-show-hide">
            <div className="profile-build">
              <div className="profile-line"></div>
              <h6 className="build-title">Build your profiles</h6>
              <p className="build-text">
                With a well-crafted professional profile, you'll showcase your
                expertise and attract the attention of potential employers. Good
                luck!
              </p>
              {/* <div
                className="text-center d-flex flex-column justify-content-center align-items-center"
                {...getRootProps()}
              >
                
                <input {...getInputProps()} />
                {selectedFile === null ? (
                  <label htmlFor="file" className="auto-button">
                    Auto upload Resume
                  </label>
                ) : (
                  <button className="done-btn">
                    Uploaded
                    <img className="ps-2" src={done} alt="" />
                  </button>
                )}
                {selectedFile === null ? (
                  <p className="information">
                    <img src={info} alt="" /> Upload max 10MB .pdf file only
                  </p>
                ) : (
                  <p className="file-name">
                    {selectedFile.name}
                    <button onClick={removeFile} className="close-btn">
                      <img src={close} alt="" />
                    </button>
                  </p>
                )}
              </div> */}

              {/* <div className="d-flex justify-content-center align-items-center">
                {selectedFile !== null && (
                  <button
                    className="save-btn px-5"
                    onClick={UploadResume}
                    style={{ width: "max-content" }}
                  >
                    Upload
                  </button>
                )}
              </div> */}
              <div className="border-bottom"></div>
              <div className="resume-box">
                <h6 className="resume-title">Personal Details</h6>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      name="name"
                      placeholder="Full name"
                      className="resume-input"
                      value={academicData?.name}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      name="phone"
                      value={academicData?.phone}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                      placeholder="Phone number"
                      className="resume-input"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className="resume-input"
                      value={academicData?.email}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="calender-date-input"
                      onClick={handleDateOpen}
                    >
                      <input
                        placeholder="Enter DOB"
                        className="input-date"
                        type="text"
                        value={academicData?.dob}
                        name="dob"
                      />
                      <img
                        src={CalenderImg}
                        className="input-calender"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <CitySearch
                      options={cityList}
                      label="name"
                      selectedVal={city}
                      handleChange={(val) => setCity(val)}
                      placeHolder="Choose City"
                    />
                    {/* <input
                      type="text"
                      placeholder="Current city"
                      className="resume-input"
                      name="city"
                      value={academicData.city}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    /> */}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <SearchBar
                      options={allState}
                      label="displayValue"
                      selectedVal={value}
                      handleChange={(val) => setValue(val)}
                      placeHolder="Current State"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Pin code"
                      className="resume-input"
                      name="pincode"
                      value={academicData.pincode}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      ref={ref1}
                      className="dropdown"
                      onMouseEnter={() => setDropHover2(true)}
                      onMouseLeave={() => setDropHover2(false)}
                    >
                      <div
                        onClick={handleOnClick1}
                        style={{
                          outline: dropdown1 ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData.workingStatus}
                        <img
                          src={dropHover2 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: dropdown1 ? "block" : "none" }}
                      >
                        {selectItem.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["workingStatus"]: e.target.textContent,
                                });
                                setDropdown1(false);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    {/* <div className="styled-select">
                      <select
                        className="custom-select"
                        onChange={(e) => {
                          setAcademicData({
                            ...academicData,
                            ["maritalStatus"]: e.target.value,
                          });
                        }}
                      >
                        <option value="">Marital Status </option>
                        {selectItemTwo?.map((element, index) => {
                          return (
                            <option value={element?.option}>
                              {element?.option}
                            </option>
                          );
                        })}
                      </select>
                    </div> */}
                    <div
                      ref={ref2}
                      className="dropdown"
                      onMouseEnter={() => setDropHover3(true)}
                      onMouseLeave={() => setDropHover3(false)}
                    >
                      <div
                        onClick={handleOnClick2}
                        style={{
                          outline: dropdown2 ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData?.maritalStatus}
                        <img
                          src={dropHover3 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: dropdown2 ? "block" : "none" }}
                      >
                        {selectItemTwo.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["maritalStatus"]: e.target.textContent,
                                });
                                setDropdown2(false);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-bottom"></div>
              <div className="resume-box">
                {academic2?.map((element, index) => {
                  return (
                    <>
                      <h6 className="resume-title">Academic Details</h6>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Institute/University name"
                            className="resume-input"
                            name="instituteName"
                            value={academic2[index]?.instituteName}
                            onChange={(e) => {
                              const updatedData2 = [...academic2];
                              updatedData2[index][e.target.name] =
                                e.target.value;
                              setAcademic2(updatedData2);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div>
                            {dropdown3 === index ? (
                              <div
                                className="select-overlay"
                                onClick={() => setDropdown3(false)}
                              ></div>
                            ) : (
                              ""
                            )}
                            <div
                              className="dropdown"
                              style={{
                                zIndex: dropdown3 === index ? "1112" : "",
                              }}
                              onMouseEnter={() => setDropHover4(true)}
                              onMouseLeave={() => setDropHover4(false)}
                            >
                              <div
                                onClick={(e) => setDropdown3(index)}
                                style={{
                                  outline: dropdown3
                                    ? "2px solid #94A3B8"
                                    : "none",
                                }}
                                className="dropdown-btn"
                              >
                                {academic2[index]?.degreeType}
                                <img
                                  src={
                                    dropdown3 === index
                                      ? downArrowHover
                                      : downArrow
                                  }
                                  alt=""
                                />
                              </div>
                              <div
                                className="dropdown-content"
                                style={{
                                  display:
                                    index === dropdown3 ? "block" : "none",
                                }}
                              >
                                {selectItemThree.map((element, i) => {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].degreeType =
                                          e.target.textContent;
                                        setAcademic2(updatedData);
                                        setDropdown3(false);
                                      }}
                                      className="item"
                                    >
                                      {element.option}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Degree name"
                            className="resume-input"
                            name="degreeName"
                            value={academic2[index]?.degreeName}
                            onChange={(e) => {
                              const updatedData2 = [...academic2];
                              updatedData2[index][e.target.name] =
                                e.target.value;
                              setAcademic2(updatedData2);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="row">
                            <div className="col-6">
                              <input
                                type="text"
                                placeholder="0"
                                className="resume-input"
                                name="percentage"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                value={academic2[index].percentage}
                                onChange={(e) => {
                                  const updatedData2 = [...academic2];
                                  updatedData2[index][e.target.name] =
                                    e.target.value;
                                  setAcademic2(updatedData2);
                                }}
                              />
                            </div>
                            <div className="col-6">
                              <div>
                                {dropdown4 === index ? (
                                  <div
                                    className="select-overlay"
                                    onClick={() => setDropdown4(false)}
                                  ></div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="dropdown"
                                  style={{
                                    zIndex: dropdown4 === index ? "1112" : "",
                                  }}
                                  onMouseEnter={() => setDropHover4(true)}
                                  onMouseLeave={() => setDropHover4(false)}
                                >
                                  <div
                                    onClick={(e) => setDropdown4(index)}
                                    style={{
                                      outline:
                                        index === dropdown4
                                          ? "2px solid #94A3B8"
                                          : "none",
                                    }}
                                    className="dropdown-btn"
                                  >
                                    {academic2[index]?.percentageType}
                                    <img
                                      src={
                                        dropHover4 ? downArrowHover : downArrow
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    className="dropdown-content"
                                    style={{
                                      display:
                                        index === dropdown4 ? "block" : "none",
                                    }}
                                  >
                                    {cgp.map((element, i) => {
                                      return (
                                        <div
                                          onClick={(e) => {
                                            const updatedData = [...academic2];
                                            updatedData[index].percentageType =
                                              e.target.textContent;
                                            setAcademic2(updatedData);
                                            setDropdown4(false);
                                          }}
                                          className="item"
                                        >
                                          {element.option}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handleStartOpen(index)}
                          >
                            <input
                              placeholder="Starting year (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={academic2[index]?.startYear}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {startModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button className="close-btn">
                                  <img
                                    src={closeBtn}
                                    alt=""
                                    onClick={() => setStartModal(false)}
                                  />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].startYear =
                                          moment(e).format("MM-YYYY");
                                        setAcademic2(updatedData);
                                        setStartModal(false);
                                      }}
                                      // value={academic2[index]?.startYear}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handlePassingOpen(index)}
                          >
                            <input
                              placeholder="Passing year (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={academic2[index]?.endYear}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {passingModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button className="close-btn">
                                  <img
                                    src={closeBtn}
                                    alt=""
                                    onClick={() => setPassingModal(false)}
                                  />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].endYear =
                                          moment(e).format("MM-YYYY");
                                        setAcademic2(updatedData);
                                        setPassingModal(false);
                                      }}
                                      // value={academic2[index]?.endYear}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 ">
                          <textarea
                            name=""
                            id=""
                            style={{ resize: "none" }}
                            placeholder="Write about the highlights of your journey "
                            className="resume-input m-0"
                            cols="30"
                            rows="3"
                            value={academic2[index]?.highlight}
                            onChange={(e) => {
                              const updatedData = [...academic2];
                              updatedData[index].highlight = e.target.value;
                              setAcademic2(updatedData);
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="tooltip-container">
                        <div
                          className="tooltip-box"
                          style={{ display: over ? "block" : "none" }}
                        >
                          <div
                            className="tooltip-close"
                            onClick={() => setOver(false)}
                          ></div>
                          The highlights of your journey create a powerful
                          impact by showcasing your accomplishments, growth, and
                          potential. They provide insight into your professional
                          trajectory and demonstrate the value you bring to the
                          table.
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ paddingBottom: "12px", cursor: "pointer" }}
                          onClick={() => setOver(!over)}
                        >
                          <img src={info} className="pe-2" alt="" />
                          <p className="information-text">
                            What impact would highlights create
                          </p>
                        </div>
                      </div>
                      <div className="d-flex mb-2 align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text p-0"
                          onClick={(e) =>
                            setAcademic2([
                              ...academic2,
                              {
                                instituteName: "",
                                degreeName: "",
                                degreeType: "Degree Type",
                                percentage: "",
                                percentageType: "CGPA",
                                startYear: "",
                                endYear: "",
                                highlight: "",
                              },
                            ])
                          }
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add education
                        </button>
                        {academic2.length > 1 && (
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              cursor: "pointer",
                            }}
                            disabled={academic2.length === 1}
                            className="plush-text p-0"
                            onClick={() => DeleteEducation(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="border-bottom"></div>
              <div className="resume-box">
                {certificate.map((element, index) => {
                  return (
                    <>
                      <h6 className="resume-title">
                        Certificates awarded during your Academic years
                      </h6>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Certificate Name"
                            name="certificateName"
                            className="resume-input"
                            value={certificate[index].certificateName}
                            onChange={(e) => {
                              const updatedData = [...certificate];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setCerttificate(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            htmlFor="file-upload"
                            onClick={() => handleDivClick()}
                            className="custom-file-upload"
                          >
                            {cart[index] !== null
                              ? cart[index]?.name
                              : "Upload certificate"}
                            <input
                              id="file-upload"
                              ref={imageRef}
                              className="custom-file-input"
                              type="file"
                              name="certificateLink"
                              value={""}
                              onChange={(e) => {
                                updateFiles(e, index);
                              }}
                            />
                            <img src={uploadBtn} alt="" />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handleReceivedOpen(index)}
                          >
                            <input
                              placeholder="Received (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={certificate[index]?.issueDate}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {receivedModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={() => setReceivedModal(false)}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...certificate];
                                        updatedData[index].issueDate =
                                          moment(e).format("MM-YYYY");
                                        setCerttificate(updatedData);
                                        setReceivedModal(false);
                                      }}
                                      // value={received}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={(e) => {
                            if (certificate?.length < 5) {
                              setCerttificate([
                                ...certificate,
                                {
                                  certificateName: "",
                                  certificateLink: "",
                                  issueDate: "",
                                },
                              ]);
                            }
                          }}
                          className="plush-text p-0"
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add certificate
                        </button>
                        {certificate.length > 1 && (
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            disabled={certificate.length === 1}
                            onClick={() => DeleteCertificates(index)}
                            className="plush-text p-0"
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="text-center pb-4">
                <button className="back-btn" onClick={() => navigate(-1)}>
                  Back
                </button>
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <NavLink to={"/build-resume"}>
                  <button className="skip-btn">Skip for now</button>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      )}

      {dateModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleDateOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar
                  onChange={(e) => {
                    setAcademicData({
                      ...academicData,
                      ["dob"]: moment(e).format("DD-MM-YYYY"),
                    });
                    setDateModal(!dateModal);
                  }}
                  onClick={handleDateOpen}
                  value={dateBirth}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* {startModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleStartOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar onChange={setStartYear} value={startYear} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
      {/* {passingModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handlePassingOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar onChange={setPassingYear} value={passingYear} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
      {/* {receivedModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleReceivedOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar onChange={setReceived} value={received} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
}

export default CreateResume;
