import { baseUrl, headers } from "../common/data";

export const getAllApplication = async (id, token) => {
  try {
    const response = await fetch(`${baseUrl}/application/getallapp/${id}`, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const getSingleApplication = async (id, token) => {
  try {
    const response = await fetch(`${baseUrl}/application/single/${id}`, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const asigntoInterviewer = async (id, data, token) => {
  try {
    const response = await fetch(`${baseUrl}/job/assign/interviewer/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};
