import { baseUrl, headers } from "../common/data";

export const getAllChats = async (userId, senderId) => {
  try {
    const response = await fetch(
      `${baseUrl}/message/user/interviewer/${userId}?senderId=${senderId}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const userInterviewerChat = async (userId, senderId) => {
  try {
    const response = await fetch(
      `${baseUrl}/message/user/interviewer/${userId}?senderId=${senderId}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const userCounselorChat = async (userId, senderId) => {
  try {
    const response = await fetch(
      `${baseUrl}/message/user/counselor/${userId}?senderId=${senderId}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const interviewerChat = async (data, senderId) => {
  try {
    const response = await fetch(
      `${baseUrl}/message/interviewer/send/user/${senderId}`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const counselorChat = async (data, senderId) => {
  try {
    const response = await fetch(
      `${baseUrl}/message/counselor/send/user/${senderId}`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const userChat = async (data, senderId) => {
  try {
    const response = await fetch(
      `${baseUrl}/message/user/send/interviewer/${senderId}`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const userChat1 = async (data, senderId) => {
  try {
    const response = await fetch(
      `${baseUrl}/message/user/send/counselor/${senderId}`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const chatRoomChats = async (userId) => {
  try {
    const response = await fetch(
      `${baseUrl}/message/all/user/interviewer/${userId}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const getNotification = async (userId) => {
  try {
    const response = await fetch(
      `${baseUrl}/message/notification/${userId}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const notificaitonMarkRead = async (userId) => {
  try {
    const response = await fetch(
      `${baseUrl}/message/notification/markread/${userId}`,
      {
        method: "PUT",
        headers: headers,
      }
    );
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};
