import React from "react";
import Header from "../components/header/Header";
import { useNavigate, useParams } from "react-router-dom";
import "./dashboard.css";

function SubmitSwitch() {
  const navigate = useNavigate();
  const postId = useParams();
  function HandleApply(id) {
    navigate(`/dashboard/job-apply/${id}`);
  }
  return (
    <>
      <Header />
      <section className="job-section">
        <div className="switch-modal">
          <button
            className="create-resume-btn"
            onClick={() => HandleApply(postId.id)}
          >
            Submit
          </button>
          <button className="upload-resume-btn"
          onClick={() => navigate(`/dashboard/job-description/${postId.id}`)}>Back</button>
        </div>
      </section>
    </>
  );
}

export default SubmitSwitch;
