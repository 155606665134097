import React, { useState } from "react";
import closeBtn from "../assets/close-bt.svg";
import box from "../assets/box.svg";
import "./dashboard.css";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import { toast } from "react-toastify";

function FeedbackModal({ setToggle, compayId, name, company }) {
  const [rat, setRating] = useState(0);
  const [excellent, setExcellent] = useState(0);
  const [outStanding, setOutStanding] = useState(0);
  const [HrUpdate, setHrUpdate] = useState(0);
  const [academic, setAcademic] = useState(0);
  const [skill, setSkill] = useState(0);
  const [desc, setDesc] = useState("");
  const [loader, setLoader] = useState(false);

  const [hover, setHover] = useState(0);
  const [hover1, setHover1] = useState(0);
  const [hover2, setHover2] = useState(0);
  const [hover3, setHover3] = useState(0);
  const [hover4, setHover4] = useState(0);
  const [hover5, setHover5] = useState(0);

  const { sendFeedbackByUser } = useMain();

  const submitFeedback = async () => {
    const data = {
      companyId: compayId,
      interviewExperience: rat,
      hrCommunication: excellent,
      hrFollowUps: outStanding,
      hrUpdates: HrUpdate,
      academic: academic,
      skill: skill,
      desc: desc,
    };

    const userId = localStorage.getItem("userId");
    if (userId) {
      setLoader(true);
      const result = await sendFeedbackByUser(data, userId);
      if (result?.statusCode === 200) {
        setLoader(false);
        setToggle(false);
        toast.success("Feedback submitted successfully.");

      } else {
        toast.error("Something went wrong. Please try again later.");

        setLoader(false);
        setToggle(false);
      }
    } else {
      toast.info("Please log in again.");

      setLoader(false);
      setToggle(false);
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="feedback-modal">
          <div className="feedback-filter-box">
            <button className="close-btn">
              <img src={closeBtn} alt="" onClick={() => setToggle(false)} />
            </button>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6 className="feedback-title m-0">Give Feedback</h6>
                <p className="m-0 feedback-text">
                  Help us improve the communication between candidates and
                  company
                </p>
              </div>
              <div className="feedback-card">
                <div className="d-flex align-items-center">
                  <img
                    src={box}
                    style={{
                      height: "32px",
                      width: "32px",
                      marginRight: "8px",
                    }}
                    alt=""
                  />
                  <div>
                    <h6 className="m-0 feedback-card-title">{name}</h6>
                    <p className="m-0 feedback-card-text">{company}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="feedback-border"></div>
            <div className="row">
              <div className="col-4">
                <div className="rating-box">
                  <h6 className="m-0 rating-title">Interview experience</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={
                              index <= (hover || rat)
                                ? "rating-star on"
                                : "rating-star off"
                            }
                            onClick={() => setRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rat)}
                          >
                            <span className="star"></span>
                          </button>
                        );
                      })}
                    </div>
                    <p className="m-0 rating-box-text">-</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="rating-box">
                  <h6 className="m-0 rating-title">HR communication</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={
                              index <= (hover1 || excellent)
                                ? "rating-star on"
                                : "rating-star off"
                            }
                            onClick={() => setExcellent(index)}
                            onMouseEnter={() => setHover1(index)}
                            onMouseLeave={() => setHover1(excellent)}
                          >
                            <span className="star"></span>
                          </button>
                        );
                      })}
                    </div>
                    <p className="m-0 rating-box-text">Excellent</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="rating-box">
                  <h6 className="m-0 rating-title">HR follow ups</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={
                              index <= (hover2 || outStanding)
                                ? "rating-star on"
                                : "rating-star off"
                            }
                            onClick={() => setOutStanding(index)}
                            onMouseEnter={() => setHover2(index)}
                            onMouseLeave={() => setHover2(outStanding)}
                          >
                            <span className="star"></span>
                          </button>
                        );
                      })}
                    </div>
                    <p className="m-0 rating-box-text">Outstanding</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="rating-box">
                  <h6 className="m-0 rating-title">HR updates</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={
                              index <= (hover3 || HrUpdate)
                                ? "rating-star on"
                                : "rating-star off"
                            }
                            onClick={() => setHrUpdate(index)}
                            onMouseEnter={() => setHover3(index)}
                            onMouseLeave={() => setHover3(HrUpdate)}
                          >
                            <span className="star"></span>
                          </button>
                        );
                      })}
                    </div>
                    <p className="m-0 rating-box-text">Excellent</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="rating-box">
                  <h6 className="m-0 rating-title">Academic Strength</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={
                              index <= (hover4 || academic)
                                ? "rating-star on"
                                : "rating-star off"
                            }
                            onClick={() => setAcademic(index)}
                            onMouseEnter={() => setHover4(index)}
                            onMouseLeave={() => setHover4(academic)}
                          >
                            <span className="star"></span>
                          </button>
                        );
                      })}
                    </div>
                    <p className="m-0 rating-box-text">Excellent</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="rating-box">
                  <h6 className="m-0 rating-title">Skills</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <button
                            type="button"
                            key={index}
                            className={
                              index <= (hover5 || skill)
                                ? "rating-star on"
                                : "rating-star off"
                            }
                            onClick={() => setSkill(index)}
                            onMouseEnter={() => setHover5(index)}
                            onMouseLeave={() => setHover5(skill)}
                          >
                            <span className="star"></span>
                          </button>
                        );
                      })}
                    </div>
                    <p className="m-0 rating-box-text">Outstanding</p>
                  </div>
                </div>
              </div>
            </div>
            <textarea
              name=""
              id=""
              cols="30"
              rows="4"
              className="rating-message"
              placeholder="Write highlights of the interview"
              onChange={(e) => setDesc(e.target.value)}
            >
              {desc}
            </textarea>
            <div className="text-center">
              <button className="rating-submit-btn" onClick={submitFeedback}>
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FeedbackModal;
