// NotificationModal.js
import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useMain } from '../hook/useMain';

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const paperStyle = {
    backgroundColor: '#ffff',
    border: '2px solid #4CAF50',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    padding: '24px',
    maxWidth: '600px',
    minWidth: "300px"
};


const closeButtonStyle = {
    marginTop: '16px',
    backgroundColor: '#101E36',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
};

const NotificationModal = ({ open, onClose, notifications, handleNotification, userId }) => {
    const { notificaitonMarkRead } = useMain()
    const hangleMarkRead = async () => {
        if(userId){
            const res = await notificaitonMarkRead(userId);
            console.log(res);
            if (res?.statusCode == 200) {
                handleNotification()
                onClose()
            }
        }
    }
    return (
        <Modal
            style={modalStyle}
            open={open}
            onClose={onClose}
            closeAfterTransition
        //   BackdropComponent={Backdrop}
        //   BackdropProps={{
        //     timeout: 500,
        //   }}
        >
            <Fade in={open}>
                <div style={paperStyle}>
                    <Typography variant="h5" gutterBottom style={{ color: '#101E36' }}>
                        Notifications
                    </Typography>
                    {notifications.length === 0 ? (
                        <Typography variant="body1">No notifications available.</Typography>
                    ) : (
                        <ul
                            style={{
                                maxHeight: '40vh',
                                overflowY: 'auto',
                                scrollbarWidth: 'thin',
                                scrollbarColor: '#115DE1 #E61269',
                            }}
                        >
                            {notifications.map((notification, index) => (
                                <li key={index} style={{ marginBottom: '12px' }}>
                                    <Typography variant="body1">{notification.message}</Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {notification.timestamp}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    )}
                    <button onClick={onClose} style={closeButtonStyle}>
                        Close
                    </button> &nbsp; &nbsp;
                    <button onClick={hangleMarkRead} style={closeButtonStyle}>
                        Mark as Read
                    </button>
                </div>
            </Fade>
        </Modal>
    );
};

export default NotificationModal;
