import { baseUrl, headers } from "../common/data";

export const getAllUserPost = async () => {
  try {
    const response = await fetch(`${baseUrl}/user/job/allpost`, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getSingleUserPost = async (id) => {
  try {
    const response = await fetch(`${baseUrl}/user/job/singlepost/${id}`, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getapplyJob = async (id) => {
  try {
    const response = await fetch(`${baseUrl}/user/job/applyjob/${id}`, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const applyJob = async (jobID, data) => {
  try {
    const response = await fetch(`${baseUrl}/user/job/applyjob/${jobID}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const saveJob = async (jobID, data) => {
  try {
    const response = await fetch(`${baseUrl}/user/job/savejob/${jobID}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getsaveJob = async (id) => {
  try {
    const response = await fetch(`${baseUrl}/user/job/savejob/${id}`, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};
