import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Header from "../components/header/Header";
import Sidebar from "../components/sideBar/Sidebar";
import SideBarContext from "../context/SidebarContext";
import info from "../assets/information.svg";
import close from "../assets/x.svg";
import "./dashboard.css";
import done from "../assets/done.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import UserAlertModel from "./UserAlertModel";
import axios from "axios";
import { baseUrl } from "../contextApi/common/data";
import deleteIcon from "../assets/delete.svg";

function JobApply() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("auth") === "true";
    if (!isAuthenticated) {
      navigate("/log-in");
      return;
    }
  }, []);
  useEffect(() => {
    if (id) {
      getData(id);
    }
    getAllResume();
  }, []);

  const { id } = useParams();
  const { applyJob, getSingleUserPost, UpdateResume } = useMain();
  const [data, setData] = useState({
    email: "",
    phone: "",
  });
  const [screeningQuestions, setScreeningQuestions] = useState({});
  const [answers, setAnswer] = useState([]);
  const [loader, setLoader] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const sidebar = useContext(SideBarContext);
  const [completeProfile, setCompleteProfile] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isSidebarOpen } = sidebar;
  const [allResume, setAllResume] = useState(null);
  const [summary, setSummary] = useState("");
  console.log(allResume);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState(null);

  const getAllResume = async () => {
    const id = localStorage.getItem("userToken");
    if (id) {
      try {
        const res = await axios.get(`${baseUrl}/user-resume`, {
          headers: {
            Authorization: `Bearer ${id}`,
          },
        });
        setAllResume(res?.data?.data?.resumes);
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.info("Please log in again.");

    }
  };

  const handleFileSelection = (file) => {
    setSelectedFile(file);
  };

  const handleUrlSelection = (url, val) => {
    setSelectedUrl(url);
    setSummary(val);
  };
  console.log(selectedUrl);

  const handleDeleteResume = async (id) => {
    try {
      const res = await axios.delete(`${baseUrl}/user-resume/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      if (res?.status == 200) {
        toast.success("Resume deleted successfully.");

        getAllResume();
      }
    } catch (error) {
      toast.error("Failed to delete resume.");

    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    multiple: false,
    maxSize: 10 * 1024 * 1024, // 10MB in bytes
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles[0]?.type == "application/pdf") {
        const id = localStorage.getItem("userId");
        setLoader(true);
        const result = await UpdateResume(acceptedFiles[0], id);
        if (result?.statusCode === 200) {
          console.log(result);
          if (result?.data) {
            localStorage.setItem("userData", JSON.stringify(result?.data));
          }
          getAllResume();
          toast.success(result?.message);
          // setSelectedFile(true);
          setLoader(false);
        } else {
          toast.error("Somthig went wrong");
          setSelectedFile(null);
          setLoader(false);
        }
      } else {
        toast.info("Please select valid pdf.");
        setSelectedFile(null);
        setLoader(false);
      }
    },
  });

  const getData = async (id) => {
    setLoader(true);
    const res = await getSingleUserPost(id);
    if (res?.statusCode === 200) {
      setScreeningQuestions(res?.data?.screeningQuestions);
      setLoader(false);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };

  function removeFile() {
    setSelectedFile(null);
  }

  const handleAnswers = (e, data, index) => {
    const ans = { ...data, [e.target.name]: e.target.value };
    const updatedAnswers = [...answers];
    updatedAnswers[index] = ans;
    setAnswer(updatedAnswers);
  };

  // const handlechange = (e) => {
  //   const { name, value } = e.target;
  //   setData({ ...data, [name]: value });
  // };
  // console.log(data)

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      setCompleteProfile(false);
      setIsModalOpen(true);
    } else {
      const requiredFields = [
        "name",
        "phone",
        "email",
        "dob",
        "city",
        "state",
        "resumeUrl",
        "academic",
      ];
      for (let field of requiredFields) {
        if (!userData[field] || userData[field] == "") {
          setCompleteProfile(false);
          setIsModalOpen(true);
          break;
        }
      }
    }
  }, []);

  const hadlecloseModel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    const { _id, resumeUrl } = JSON.parse(localStorage.getItem("userData"));
    console.log(resumeUrl);
    const { email, phone } = userData;
    if (
      resumeUrl == undefined ||
      !resumeUrl ||
      resumeUrl == "" ||
      resumeUrl == null
    ) {
      toast.error("Please upload resume first.");

    }
    if (resumeUrl && _id) {
      setLoader(true);
      const res = await applyJob(id, {
        email,
        phone,
        userId: _id,
        screeningQuestions: answers,
        resumeUrl: selectedUrl,
        summary: summary,
      });

      if (res?.statusCode == 200) {
        localStorage.setItem("userData", JSON.stringify(res?.data?.saveduser));
        setLoader(false);
        toast.success("Job application successful.");

        navigate("/dashboard");
      } else if (res?.message == "Already Applied for thid Job.") {
        toast.info("Job is already applied.");

        navigate("/dashboard");
        setLoader(false);
      } else {
        toast.error("Failed to apply for the job.");
        setLoader(false);
      }
    }
  };
  function Submit() {
    // navigate("/dashboard/job-apply/switch")
  }

  if (loader) {
    return <Loader />;
  }

  return (
    <>
      <AlertContainer />
      <Header />
      <section className="dashboard">
        <div className="dashboard-box">
          <div className="sidebar-scroll">
            <Sidebar />
          </div>
          <div className="desktop-show-hide">
            <div
              className="job-detail-page"
              style={{ left: isSidebarOpen ? "85px" : "354px" }}
            >
              <div className="contact-page">
                <div className="contact-information">
                  <div>
                    <h6 className="contact-title">Contact Information</h6>
                    <p className="contact-text">
                      Use accurate contact information
                    </p>
                  </div>
                  <div className="row w-100">
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <div className="d-flex flex-column">
                        <p className="information-input">Mobile Number</p>
                        <input
                          type="text"
                          placeholder="Enter mobile number"
                          className="information-input-box w-100"
                          name="phone"
                          disabled
                          value={userData?.phone}
                          // onChange={handlechange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <div className="d-flex flex-column">
                        <p className="information-input">Email Address</p>
                        <input
                          type="text"
                          placeholder="Enter Email Address"
                          className="information-input-box w-100"
                          name="email"
                          disabled
                          value={userData?.email}
                          // onChange={handlechange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="information-border"></div>
                <div className="resume-box">
                  <div>
                    <h6 className="resume-title-">Resume</h6>
                    <p className="resume-text" style={{ color: "black" }}>
                      Make sure to use updated resume
                    </p>
                  </div>
                  {/* {allResume?.length > 0 &&
                    allResume?.map((item) => (
                      <div key={item._id} className="radio-button">
                        <input
                          type="radio"
                          className="radio-button__input"
                          id={`url-${item._id}`}
                          name="radio-group"
                          checked={selectedUrl === item.url}
                          onChange={() => handleUrlSelection(item?.url, item?.summary)}
                        />
                        <label
                          className="radio-button__label m-0"
                          style={{ paddingLeft: "40px" }}
                          htmlFor={`url-${item._id}`}
                        >
                          <span className="radio-button__custom"></span>
                          <div className="d-flex align-items-center">
                            {selectedUrl === item.url && (
                              <iframe
                                title="resume-preview"
                                src={item.url}
                                style={{
                                  width: "150px",
                                  height: "200px",
                                  border: "none",
                                }}
                              ></iframe>
                            )}
                            <p
                              className="information m-0"
                              style={{
                                color: "#11274B",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                              }}
                            >
                              <img src={info} alt="" /> Click to select this
                              resume
                            </p>
                          </div>
                        </label>
                      </div>
                    ))} */}
                  {allResume?.length > 0 &&
                    allResume?.map((item) => (
                      <div key={item._id} className="resume-item">
                        <div style={{ display: "flex", gap: "10px" }}>
                          <input
                            type="radio"
                            className="radio-button__input"
                            id={`url-${item._id}`}
                            name="radio-group"
                            checked={selectedUrl === item.url}
                            onChange={() =>
                              handleUrlSelection(item?.url, item?.summary)
                            }
                          />
                          <label
                            className="radio-button__label m-0"
                            htmlFor={`url-${item._id}`}
                          >
                            <span className="radio-button__custom"></span>
                            <div className="d-flex align-items-center">
                              {selectedUrl === item.url && (
                                <iframe
                                  title="resume-preview"
                                  src={item.url}
                                  style={{
                                    width: "150px",
                                    height: "200px",
                                    border: "none",
                                  }}
                                ></iframe>
                              )}
                              <p
                                className="information m-0"
                                style={{
                                  color: "#11274B",
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "normal",
                                }}
                              >
                                <img src={info} alt="" /> Click to select this
                                resume
                              </p>
                            </div>
                          </label>
                          <div onClick={() => handleDeleteResume(item._id)}>
                            <img src={deleteIcon} alt="Delete" />
                          </div>
                        </div>
                      </div>
                    ))}

                  {/* <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="language1"
                      name="radio-group"
                      disabled
                    />
                    <label
                      className="radio-button__label"
                      style={{
                        color: "#4D4D4D",
                        marginBottom: "0",
                        paddingLeft: "40px",
                      }}
                      htmlFor="language1"
                    >
                      <span className="radio-button__custom"></span>
                      Use Recruitifie ATS Friendly Resume
                    </label>
                  </div> */}
                  <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="language2"
                      name="radio-group"
                    />
                    <label
                      className="radio-button__label m-0"
                      style={{ paddingLeft: "40px" }}
                      htmlFor="language2"
                    >
                      <span className="radio-button__custom"></span>
                      <div
                        className="d-flex align-items-center send-resume-btn"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        {selectedFile === null ? (
                          <label htmlFor="file" className="auto-button- me-3">
                            Upload Resume
                          </label>
                        ) : (
                          <button className="done-btn me-3">
                            Uploaded
                            <img className="ps-2" src={done} alt="" />
                          </button>
                        )}
                        {selectedFile === null ? (
                          <p
                            className="information m-0"
                            style={{
                              color: "#11274B",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            <img src={info} alt="" /> Upload max 2MB .pdf file
                            only
                          </p>
                        ) : (
                          <p
                            className="file-name m-0"
                            style={{
                              color: "#11274B",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            {selectedFile.name}{" "}
                            <button onClick={removeFile} className="close-btn">
                              <img src={close} alt="" />
                            </button>
                          </p>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="information-border"></div>
                <div className="screening-box">
                  <div>
                    <h6 className="screening-title">Screening Questions</h6>
                    <p className="screening-text">
                      Screening questions help give the right push
                    </p>
                  </div>
                  {screeningQuestions.length > 0 &&
                    screeningQuestions?.map((item, index) => (
                      <div className="d-flex flex-column w-50">
                        <p className="information-input">Q. {item?.question}</p>
                        <input
                          type="text"
                          placeholder="Answer"
                          className="information-input-box w-100"
                          name="answer"
                          onChange={(e) => handleAnswers(e, item, index)}
                        />
                      </div>
                    ))}

                  <button
                    className="screen-submit"
                    disabled={completeProfile == false}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="mobile-show-hide" style={{ padding: "12px 20px" }}>
            <div className="d-flex flex-column" style={{ gap: "8px" }}>
              <div className="contact-page p-0">
                <div className="contact-information">
                  <div>
                    <h6 className="contact-title">Contact Information</h6>
                    <p className="contact-text">
                      Use accurate contact information
                    </p>
                  </div>
                  <input
                    type="text"
                    placeholder="Enter mobile number"
                    className="information-input-box w-100"
                    name="phone"
                    disabled
                    value={userData?.phone}
                  />
                  <input
                    type="text"
                    placeholder="Enter Email Address"
                    className="information-input-box w-100"
                    name="email"
                    disabled
                    value={userData?.email}
                  />
                </div>
              </div>
              <div className="contact-page p-0">
                <div className="resume-box">
                  <div>
                    <h6 className="resume-title-">Resume</h6>
                    <p className="resume-text" style={{ color: "black" }}>
                      Make sure to use updated resume
                    </p>
                  </div>
                  {/* <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="language1"
                      name="radio-group"
                      disabled
                    />
                    <label
                      className="radio-button__label"
                      style={{
                        color: "#4D4D4D",
                        marginBottom: "0",
                        paddingLeft: "30px",
                      }}
                      htmlFor="language1"
                    >
                      <span className="radio-button__custom"></span>
                      Use Recruitifie ATS Friendly Resume
                    </label>
                  </div> */}
                  {/* {allResume?.length > 0 &&
                    allResume?.map((item) => (
                      <div key={item._id} className="radio-button">
                        <input
                          type="radio"
                          className="radio-button__input"
                          id={`url-${item._id}`}
                          name="radio-group"
                          checked={selectedUrl === item.url}
                          onChange={() =>
                            handleUrlSelection(item.url, item?.summary)
                          }
                        />
                        <label
                          className="radio-button__label m-0"
                          style={{
                            paddingLeft: "30px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          htmlFor={`url-${item._id}`}
                        >
                          <span className="radio-button__custom"></span>
                          <div>
                            {selectedUrl === item.url && (
                              <iframe
                                title="resume-preview"
                                src={item.url}
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  border: "none",
                                }}
                              ></iframe>
                            )}
                            <p
                              className="information m-0 mt-2"
                              style={{
                                color: "#11274B",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                              }}
                            >
                              <img src={info} alt="" /> Click to select this
                              resume
                            </p>
                          </div>
                        </label>
                      </div>
                    ))} */}
                  {allResume?.length > 0 &&
                    allResume?.map((item) => (
                      <div key={item._id} className="resume-item-mobile">
                        <div className="resume-item-mobile-inner">
                          <input
                            type="radio"
                            className="radio-button__input"
                            id={`url-${item._id}`}
                            name="radio-group"
                            checked={selectedUrl === item.url}
                            onChange={() =>
                              handleUrlSelection(item.url, item?.summary)
                            }
                          />
                          <label
                            className="radio-button__label m-0"
                            htmlFor={`url-${item._id}`}
                          >
                            <span className="radio-button__custom"></span>
                            <div>
                              {selectedUrl === item.url && (
                                <iframe
                                  title="resume-preview"
                                  src={item.url}
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    border: "none",
                                  }}
                                ></iframe>
                              )}
                              <p
                                className="information m-0 mt-2"
                                style={{
                                  color: "#11274B",
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: "normal",
                                }}
                              >
                                <img src={info} alt="" /> Click to select this
                                resume
                              </p>
                            </div>
                          </label>
                        </div>
                        <div
                          className="delete-icon-mobile"
                          onClick={() => handleDeleteResume(item._id)}
                        >
                          <img src={deleteIcon} alt="Delete" />
                        </div>
                      </div>
                    ))}

                  <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="language2"
                      name="radio-group"
                    />
                    <label
                      className="radio-button__label m-0"
                      style={{ paddingLeft: "30px" }}
                      htmlFor="language2"
                    >
                      <span className="radio-button__custom"></span>
                      <div
                        className="d-flex flex-column send-resume-btn"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        {selectedFile === null ? (
                          <label htmlFor="file" className="auto-button mb-2">
                            Auto upload Resume
                          </label>
                        ) : (
                          <button className="done-btn mb-2">
                            Uploaded
                            <img className="ps-2" src={done} alt="" />
                          </button>
                        )}
                        {selectedFile === null ? (
                          <p
                            className="information m-0"
                            style={{
                              color: "#11274B",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            <img src={info} alt="" /> Upload max 2MB .pdf file
                            only
                          </p>
                        ) : (
                          <p
                            className="file-name m-0"
                            style={{
                              color: "#11274B",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            {selectedFile.name}{" "}
                            <button onClick={removeFile} className="close-btn">
                              <img src={close} alt="" />
                            </button>
                          </p>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="contact-page p-0">
                <div className="screening-box">
                  <div>
                    <h6 className="screening-title">Screening Questions</h6>
                    <p className="screening-text">
                      Screening questions help give the right push
                    </p>
                  </div>

                  {screeningQuestions.length > 0 &&
                    screeningQuestions?.map((item, index) => (
                      <div className="d-flex flex-column w-100">
                        <p className="information-input">Q. {item?.question}</p>
                        <input
                          type="text"
                          placeholder="Answer"
                          className="information-input-box w-100"
                          name="answer"
                          onChange={(e) => handleAnswers(e, item, index)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-submit mobile-show-hide">
            <button
              className="screen-submit w-100"
              disabled={completeProfile == false}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </section>
      <UserAlertModel open={isModalOpen} onClose={hadlecloseModel} />
    </>
  );
}

export default JobApply;
