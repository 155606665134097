import React, { useState } from "react";
import Header from "../header/Header";
// import cap from "../../assets/login/cap.svg";
// import img1 from "../../assets/login/ats-logo.svg";
// import img2 from "../../assets/login/apply-logo.svg";
// import img3 from "../../assets/login/skill-logo.svg";
// import img4 from "../../assets/login/up-skills.svg";
import google from "../../assets/login/google-icon.svg";
import arrow from "../../assets/login/left-arrow.svg";
import hoverArrow from "../../assets/login/left-arrow-hover.svg";
import { Link, useNavigate } from "react-router-dom";
import "../recruiterPages/css/sign-in.css";
import { useMain } from "../../hook/useMain";
import {
  emailRegex,
  nameRegex,
  phoneRegex,
} from "../regexPatterns/RegexPatterns";
import Loader from "../loader/Loader";
import axios from "axios";
import { baseUrl } from "../../contextApi/common/data";
import CareerHead from "../header/CareerHead";
import { toast } from "react-toastify";
import { AlertContainer } from "../Alerts/ToastifyContainer";


function CareerForget() {
  const [showArrow, setShowArrow] = useState(false);

  const [formData, setFormData] = useState({
    otp: "",
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [mobileNoError, setMobileNoError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [sendOTP, setSendOTP] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "otp") {
      if (value === "") {
        setMobileNoError("OTP is required");
      } else {
        setMobileNoError(null);
      }
    }
    if (name === "email") {
      if (value === "") {
        setEmailError("email is required");
      } else {
        setEmailError(null);
      }
    }
    if (name === "password") {
      if (value === "") {
        setPasswordError("password is required");
      } else {
        setPasswordError(null);
      }
    }
  };

  async function SignIn(e) {
    e.preventDefault();
    try {
      const { otp, email, password } = formData;
      if (otp === "" || otp.length < 6) {
        setMobileNoError("Please enter 6 digit number");
        return;
      }
      if (email === "" || !emailRegex.test(email)) {
        setEmailError("Please enter valid email");
        return;
      }
      if (password === "" || password.length < 6) {
        setPasswordError("Password must be 6 characters");
        return;
      }
      if (otp && email && password) {
        setLoader(true);
        const data = {
          otp: Number(otp),
          email,
          newPassword: password,
        };
        const result = await axios.post(
          `${baseUrl}/counselor/forgot/submitotp`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(result);

        if (result?.status === 200) {
          setLoader(false);
          navigate("/career/sign-in");
        } else {
          setLoader(false);
          toast.error("Something went wrong.");
        }
      } else {
        setLoader(false);
        toast.info("All fields are required.");
      }
    } catch (error) {
      setLoader(false);
      toast.error("Internal server Error.");
    }
  }

  const handleOTPsend = async (e) => {
    e.preventDefault();
    try {
      const { email } = formData;
      if (email === "" || !emailRegex.test(email)) {
        setEmailError("Please enter valid email");
        return;
      }
      setLoader(true);
      const res = await axios.post(
        `${baseUrl}/counselor/forgot/sendotp`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status == 200) {
        setSendOTP(true);
      } else {
        toast.error("OTP send failed. Please check your email.");
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error("Internal server Error.");
    }
  };

  return (
    <>
      <AlertContainer />
      <CareerHead link="/career/sign-in" />
      {loader ? (
        <Loader />
      ) : (
        <section className="login-hero">
          <div className="login-container">
            <div className="sign-up m-0" style={{ width: "400px" }}>
              <div className="text-center">
                <h6 className="sign-title">Career Forget Password</h6>
                {/* <button className="google-btn">
                  <img src={google} className="pe-2" alt="" /> Google
                </button> */}
                <div className="form-border"></div>
                <form>
                  <div>
                    <input
                      type="email"
                      placeholder="Email address"
                      className="sign-input"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {emailError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{emailError}</p>
                    )}
                  </div>

                  {sendOTP ? (
                    <>               
                      <div>
                        <input
                          type="text"
                          placeholder="Enter OTP"
                          className="sign-input"
                          name="otp"
                          value={formData.otp}
                          onChange={handleChange}
                        />
                        {mobileNoError === null ? (
                          ""
                        ) : (
                          <p className="input-error-text m-0">
                            {mobileNoError}
                          </p>
                        )}
                      </div>
                      <div>
                        <input
                          type="password"
                          placeholder="Enter New Password"
                          className="sign-input"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        {passwordError === null ? (
                          ""
                        ) : (
                          <p className="input-error-text m-0">
                            {passwordError}
                          </p>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {sendOTP == false ? (
                    <button
                      className="sign-up-btn"
                      onMouseEnter={() => setShowArrow(true)}
                      onMouseLeave={() => setShowArrow(false)}
                      onClick={handleOTPsend}
                    >
                      Send OTP
                      <img
                        src={showArrow ? hoverArrow : arrow}
                        style={{ marginLeft: "4px" }}
                        alt=""
                      />
                    </button>
                  ) : (
                    ""
                  )}
                  {sendOTP ? (
                    <button
                      className="sign-up-btn"
                      onMouseEnter={() => setShowArrow(true)}
                      onMouseLeave={() => setShowArrow(false)}
                      onClick={SignIn}
                    >
                      Submit
                      <img
                        src={showArrow ? hoverArrow : arrow}
                        style={{ marginLeft: "4px" }}
                        alt=""
                      />
                    </button>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default CareerForget;
