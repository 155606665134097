import React from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';    

function SecondChart({newdata}) {
  const dataValues = [newdata.pending, newdata.rejected, newdata.hirecandidate];
  const filteredData = [];
  const filteredBgColors = [];

  dataValues.forEach((value, index) => {
    if (value !== 0) {
      filteredData.push(value);
      filteredBgColors.push(getSpecificBackgroundColor(index));
    }
  });

  const data = {
    datasets: [
      {
        data: filteredData,
        backgroundColor: filteredBgColors,
        borderWidth: 0, 
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        color: '#000',
        font: {
            size: 14,
        },
        formatter: (value) => {
          return value; 
        },
      },
    },
  };

  return (
    <>
      <Doughnut data={data} options={options} plugins={[ChartDataLabels]} />
    </>
  );
}

function getSpecificBackgroundColor(index) {
  const colors = ['#026D92', '#CBD5E1', '#FFEAD7'];
  return colors[index];
}

export default SecondChart;
