import { Suspense, lazy } from "react";
import "./App.css";
import Homepage from "./pages/Homepage";
// import ComingPage from "./pages/ComingPage";
// const ComingPage = lazy(() => import("./pages/ComingPage"));

function App() {
  return (
    <>
      <Suspense fallback={<div>Please wait...</div>}>
        <Homepage />
      </Suspense>
    </>
  );
}

export default App;
