import React, { useEffect, useState } from "react";
import { useMain } from "../../hook/useMain";
import { Link, useNavigate } from "react-router-dom";
import box from "../../assets/logo.png";
import dotIcon from "../../assets/row-dot.svg";
import Loader from "../loader/Loader";
import savedImg from "../../assets/saved-application.svg";
import "./recruiter.css";
import { AlertContainer } from "../Alerts/ToastifyContainer";

const OpenApplication = ({ inputVal, dropdown2, dropdown3, dropdown4 }) => {
  const [dotBtn, setDotBtn] = useState(-1);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState("");
  const { allPostByRecruiter, closePost } = useMain();
  const navigate = useNavigate();
  const getData = async () => {
    setLoader(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await allPostByRecruiter(token);
    if (res?.statusCode === 200) {
      setData(res?.data?.data);
      setFilterData(res?.data?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    filteredDrafts(inputVal);
  }, [inputVal]);

  useEffect(() => {
    handleFilterChange();
  }, [dropdown2, dropdown3, dropdown4]);
  const handelDialog = (val) => {
    if (dotBtn == val) {
      setDotBtn(-1);
    } else {
      setDotBtn(val);
    }
  };
  
  const handleClose = async (id) => {
    console.log(id)
    if (id) {
      setLoader(true);
      const result = await closePost(id);
      if (result?.statusCode === 200) {
        alert(result?.message);
        setLoader(false);
        // navigate("/dashboard/all-application");
        getData();
      } else {
        alert("Somthing went wrong");
        setLoader(false);
      }
    }
  };

  const handleFilterChange = () => {
    const lowertype = dropdown2.toLowerCase();
    const lowerdate = dropdown3.toLowerCase();
    const lowerloc = dropdown4.toLowerCase();
    const filtered =
      data &&
      data.length > 0 &&
      data.filter((e) => {
        const typeMatck = e?.jobInfo?.jobType.toLowerCase().includes(lowertype);
        const locMatch = e?.jobInfo?.workPlaceType
          .toLowerCase()
          .includes(lowerloc);

        const updatedAt = new Date(e?.updatedAt);
        const currentDate = new Date();
        const lastSevenDays = new Date();
        lastSevenDays.setDate(currentDate.getDate() - 7);
        const lastTwoMonths = new Date();
        lastTwoMonths.setMonth(currentDate.getMonth() - 2);
        const relevantDate = new Date();
        relevantDate.setDate(currentDate.getDate() - 3);

        return (
          (typeMatck || locMatch) &&
          ((lowerdate === "past week" && updatedAt >= lastSevenDays) ||
            lowerdate === "any time" ||
            (lowerdate === "last 2 months" && updatedAt >= lastTwoMonths) ||
            (lowerdate === "most relevant" && updatedAt >= relevantDate))
        );
      });
    setFilterData(filtered);
  };
  const filteredDrafts = (inputVal) => {
    const filterVal = data?.filter((item) => {
      const regex = new RegExp(inputVal, "i"); // 'i' for case-insensitive search
      return (
        inputVal.trim() === "" ||
        Object.values(item.jobInfo).some((value) => regex.test(String(value)))
      );
    });
    setFilterData(filterVal);
  };

  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  const formatToCurrency = (number) => {
    if (number >= 1000 && number < 10000) {
      return `₹${number}`;
    } else if (number >= 100000 && number < 10000000) {
      return `₹${(number / 100000).toFixed(0)}L`;
    } else if (number >= 10000000) {
      return `₹${(number / 100000).toFixed(0)}C`;
    } else {
      return `₹${number}`;
    }
  };
  

  return (
    <>
      {/* <AlertContainer /> */}
      {loader ? (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      ) : filterData && filterData.length > 0 ? (
        filterData.map((e, ind) => (
          <div className="draft-card" key={`openapp-${ind}`}>
            <div className="d-flex align-items-start">
              <img
                src={
                  JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                    ? JSON.parse(localStorage.getItem("recruiterData"))
                        .companyLogo
                    : box
                }
                alt=""
                style={{ marginRight: "8px", height: "25px", width: "25px" }}
              />
              <div className="w-100" style={{height:"50px"}}>
                <h2 className="draft-card-title">{e?.jobInfo?.position}</h2>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="draft-card-text">{e?.companyName}</p>
                  <p className="draft-days">{timeAgo(e?.createdAt)}</p>
                </div>
              </div>
            </div>
            <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
            <ul className="draft-list">
              <li>
                {e?.jobInfo?.jobType} · {e?.jobInfo?.workPlaceType}
              </li>
              <li>11-{e?.userId?.companyStrength} employees</li>
              <li>{e?.jobInfo?.workPlace}</li>
              <li>
                {formatToCurrency(Number(e?.jobInfo?.salaryMin))} -{" "}
                {formatToCurrency(Number(e?.jobInfo?.salaryMax))}
              </li>
            </ul>
            {/* <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <h6 className="draft-sub-title">Skills you will gain</h6>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <p className="m-0 draft-skills">Creativity</p>
                <p className="m-0 draft-skills">Art</p>
                <p className="m-0 draft-skills">History</p>
                <p className="m-0 draft-skills">Art History</p>
                <p className="m-0 draft-skills">Art Direction</p>
              </div>
            </div> */}
            <div className="d-flex" style={{ gap: "8px" }}>
              <Link
                className="draft-edit-btn text-decoration-none"
                onClick={() => navigate(`/dashboard/table/${e?._id}`)}
                state={{ name: e?.jobInfo?.position }}
              >
                View Applicants
              </Link>
              <button
                className="dot-btn"
                style={{
                  borderRadius: "8px",
                  background: " #F1F5F9",
                  padding: "6px",
                  position: "relative",
                }}
                onClick={() => handelDialog(ind)}
              >
                <img src={dotIcon} alt="" />
              </button>
              <div
                className="all-app-dropdown"
                style={{ display: dotBtn == `${ind}` ? "flex" : "none" }}
              >
                <button onClick={()=>navigate(`/dashboard/new-job/${e?._id}`)}>Edit Application</button>
                <button onClick={()=>handleClose(e?._id)}>Close Application</button>
              </div>
            </div>
            <button
              className="draft-mange-btn"
              onClick={() =>
                navigate(`/dashboard/all-application/review/${e?._id}`)
              }
            >
              Manage Application
            </button>
          </div>
        ))
      ) : (
        <div className="unsaved-image">
          <img src={savedImg} alt="" />
          <h6 className="unsaved-text m-0">No Open Applications</h6>
        </div>
      )}
    </>
  );
};

export default OpenApplication;
