import React, { useEffect, useState } from "react";
// import Header from "../components/header/Header";
import info from "../../assets/information.svg";
import "./css/recruiter-form.css";
import { useDropzone } from "react-dropzone";
import plush from "../../assets/plush.svg";
import Delete from "../../assets/delete.svg";
import close from "../../assets/x.svg";
import done from "../../assets/done.svg";
import { NavLink, json, useNavigate } from "react-router-dom";
import number1 from "../../assets/mobile/number1.svg";
import number2 from "../../assets/mobile/number2.svg";
import number3 from "../../assets/mobile/number3.svg";
import number4 from "../../assets/mobile/number4.svg";
import downArrow from "../../assets/mobile/down-arrow.svg";
import FormHeader from "../../components/header/FormHeader";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
import UploadBtn from "../../assets/recruiter-subHeader/upload-btn.svg";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";
import { industryList } from "../../context/searchList/industryList";
import IndustrySearch from "./search/IndustrySearch";
import { cityList } from "../../context/searchList/cityList";
import CitySearch from "./search/CitySearch";
import CountrySearch from "./search/CountrySearch";
import { countryList } from "../../context/searchList/countryList";
import useOutsideClick from "../../pages/outsideClick/useOutsideClick";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import RecruiterHeader from "../header/RecruiterHeader";

const selectItem = [
  {
    id: 1,
    option: "Jetpur",
    selected: false,
  },
  {
    id: 2,
    option: "Dhoraji",
    selected: false,
  },
  {
    id: 3,
    option: "Rajkot",
    selected: false,
  },
  {
    id: 4,
    option: "Junagadh",
    selected: false,
  },
  {
    id: 5,
    option: "Ahamdabad",
    selected: false,
  },
];

const strengthItme = [
  {
    id: 1,
    option: "0-10",
    selected: false,
  },
  {
    id: 2,
    option: "10-50",
    selected: false,
  },
  {
    id: 3,
    option: "50-100",
    selected: false,
  },
  {
    id: 4,
    option: "100-500",
    selected: false,
  },
  {
    id: 5,
    option: "500+",
    selected: false,
  },
];

const yearItme = [
  {
    id: 1,
    option: 2019,
    selected: false,
  },
  {
    id: 2,
    option: 2020,
    selected: false,
  },
  {
    id: 3,
    option: 2021,
    selected: false,
  },
  {
    id: 4,
    option: 2022,
    selected: false,
  },
  {
    id: 5,
    option: 2023,
    selected: false,
  },
];

function RecruiterBuild() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [value, setValue] = useState("");
  const [city, setCity] = useState("");
  const [dropdown1, setDropdown1] = useState(true);
  const [dropdown3, setDropdown3] = useState(true);
  const [dropHover2, setDropHover2] = useState(false);
  const [dropHover3, setDropHover3] = useState(false);
  const [dropHover4, setDropHover4] = useState(false);
  const [dropHover5, setDropHover5] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [certificates, setCertificates] = useState([
    { webSiteLink: "", linkedIn: "", company: "" },
  ]);

  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const [dropdown5, setDropdown5] = useState(false);
  const [dropdown6, setDropdown6] = useState(false);
  const ref1 = useOutsideClick(handleOutsideClick1);
  const ref2 = useOutsideClick(handleOutsideClick2);
  const ref3 = useOutsideClick(handleOutsideClick3);
  const ref4 = useOutsideClick(handleOutsideClick4);

  function handleOnClick1(event) {
    event.preventDefault();
    if (dropdown2 === false) {
      setDropdown2(true);
    }
  }
  function handleOnClick2(event) {
    event.preventDefault();
    if (dropdown4 === false) {
      setDropdown4(true);
    }
  }
  function handleOnClick3(event) {
    event.preventDefault();
    if (dropdown5 === false) {
      setDropdown5(true);
    }
  }
  function handleOnClick4(event) {
    event.preventDefault();
    if (dropdown6 === false) {
      setDropdown6(true);
    }
  }

  function handleOutsideClick1() {
    setDropdown2(false);
  }
  function handleOutsideClick2() {
    setDropdown4(false);
  }
  function handleOutsideClick3() {
    setDropdown5(false);
  }
  function handleOutsideClick4() {
    setDropdown6(false);
  }

  const [companyStrength, setCompanyStrength] = useState("Company Strength");
  const [yearFounded, setYearFounded] = useState("Year of Found");
  const [headQuater, setHeadQuater] = useState("");
  const [country, setCountry] = useState("");
  const [loader, setLoader] = useState(false);

  const { UpdateCompanyDetails, UpdateLogo } = useMain();
  const id = JSON.parse(localStorage.getItem("recruiterData"))?._id;

  // const navigate = useNavigate();
  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    multiple: false,
    maxSize: 10 * 1024 * 1024, // 10MB in bytes
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
      uploadeLogo(acceptedFiles[0]);
    },
  });

  const uploadeLogo = async (logo) => {
    setLoader(true);
    const result = await UpdateLogo(logo, id);
    if (result?.statusCode === 200) {
      toast.success(result?.message);
      setLoader(false);
      localStorage.setItem("recruiterData", JSON.stringify(result?.data));
    } else {
      toast.error("Something went wrong.");
      setLoader(false);
    }
  };

  // desktop state
  const [companyName, setCompanyName] = useState("");

  const [about, setAbout] = useState("");
  const [weblink, setWeblink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [industry, setIndustry] = useState("");

  const handleInputChange = (event, index, property) => {
    const updatedData2 = [...certificates];
    updatedData2[index][property] = event.target.value;
    setCertificates(updatedData2);
  };

  useEffect(() => {
    const recruiterData = JSON.parse(localStorage.getItem("recruiterData"));
    if (recruiterData) {
      setCompanyName(recruiterData?.companyName && recruiterData?.companyName);
      setIndustry(recruiterData?.industry && recruiterData?.industry);
      setValue(recruiterData?.industry ? recruiterData?.industry : "");
      setCompanyStrength(
        recruiterData?.companyStrength
          ? recruiterData?.companyStrength
          : "Company Strength"
      );
      setYearFounded(recruiterData?.yearFounded && recruiterData?.yearFounded);
      setHeadQuater(
        recruiterData?.headQuater
          ? recruiterData?.headQuater
          : ""
      );
      setCountry(recruiterData?.country ? recruiterData?.country : "");
      setAbout(recruiterData?.about && recruiterData?.about);
      setWeblink(recruiterData?.weblink && recruiterData?.weblink);
      setLinkedinLink(
        recruiterData?.linkedinLink && recruiterData?.linkedinLink
      );
      setCompanyEmail(
        recruiterData?.companyEmail && recruiterData?.companyEmail
      );
      setSelectedFile(recruiterData?.companyLogo && recruiterData?.companyLogo);
    }
  }, []);

  async function handleOnSave() {
    if (
      companyName &&
      companyStrength &&
      yearFounded &&
      headQuater &&
      country &&
      about &&
      weblink &&
      linkedinLink &&
      companyEmail &&
      industry
    ) {
      const data = {
        companyName,
        companyStrength: companyStrength,
        yearFounded: Number(yearFounded),
        headQuater,
        country,
        about,
        weblink,
        linkedinLink,
        companyEmail,
        industry,
      };

      setLoader(true);
      const result = await UpdateCompanyDetails(data, id);
      if (result?.statusCode === 200) {
        setLoader(false);
        navigate("/recruiter-help");
        toast.success(result.message);
        localStorage.setItem("recruiterData", JSON.stringify(result?.data));
      } else {
        toast.error("Something went wrong.");
        setLoader(false);
      }
    } else {
      toast.info("Please fill in all details.");
      setLoader(false);
    }
  }
  function removeFile() {
    setSelectedFile(null);
  }

  function Dropdown1() {
    setDropdown1(!dropdown1);
  }
  function Dropdown3() {
    setDropdown3(!dropdown3);
  }

  function DeleteCertificates(id) {
    const certificatesList = Object.assign([], certificates);
    let filteredArray = certificatesList.filter((item, index) => index !== id);
    setCertificates(filteredArray);
  }

  return (
    <>
      <AlertContainer />
      <RecruiterHeader title="Build Profile" />
      <section className="create-resume">
        {loader ? (
          <Loader />
        ) : (
          <div className="mobile-show-hide">
            <div className="mobile-line-box">
              <div className="mobile-line"></div>
              <div className="d-flex justify-content-around number-line">
                <img src={number1} alt="" />
                <img src={number2} alt="" />
                <img src={number3} alt="" />
                <img src={number4} alt="" />
              </div>
            </div>
            <div className="build-box-mobile">
              <h6 className="build-title">Build company profile</h6>
              <p className="build-text">
                Build your profile for a seamless hiring experience
              </p>
              <div className="text-center send-resume-btn" {...getRootProps()}>
                {/* <input
              type="file"
              hidden
              id="file"
              inputprops={{ accept: "image/*" }}
              name="file"
              onChange={fileChangedHandler}
            /> */}
                <input {...getInputProps()} />
                {selectedFile == null || selectedFile == "" ? (
                  <label htmlFor="file" className="auto-button">
                    <img src={info} style={{ marginRight: "6px" }} alt="" />
                    Company Logo
                  </label>
                ) : (
                  <button className="done-btn">
                    Uploaded
                    <img className="ps-2" src={done} alt="" />
                  </button>
                )}
                {selectedFile == null || selectedFile == "" ? (
                  <p className="information">
                    <img src={info} alt="" /> Upload max 2MB file only
                  </p>
                ) : (
                  <p className="file-name">
                    {selectedFile?.name ? (
                      <>
                        {selectedFile?.name}
                        <button onClick={removeFile} className="close-btn">
                          <img src={close} alt="" />
                        </button>
                      </>
                    ) : null}
                  </p>
                )}
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown1}
              >
                <p className="personal-title m-0">Company Details</p>
                <img src={downArrow} alt="" />
              </div>
              <div
                className="resume-box"
                style={
                  dropdown1 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Company Name"
                      className="resume-input"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <IndustrySearch
                      options={industryList}
                      label="name"
                      selectedVal={value}
                      handleChange={(val) => {
                        setValue(val);
                        setIndustry(val);
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      ref={ref3}
                      className="dropdown"
                      onMouseEnter={() => setDropHover2(true)}
                      onMouseLeave={() => setDropHover2(false)}
                    >
                      <div
                        onClick={handleOnClick3}
                        style={{
                          outline: dropdown5 ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {companyStrength}
                        <img
                          src={dropHover2 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: dropdown5 ? "block" : "none" }}
                      >
                        {strengthItme?.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setCompanyStrength(e.target.textContent);
                                setDropdown5(false);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="number"
                      placeholder="Year of Found"
                      className="resume-input"
                      value={yearFounded}
                      onChange={(e) => setYearFounded(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <CitySearch
                      options={cityList}
                      label="name"
                      selectedVal={headQuater}
                      handleChange={(val) => setHeadQuater(val)}
                    />
                  </div>
                  <div className="col-12">
                    <CountrySearch
                      options={countryList}
                      label="name"
                      selectedVal={country}
                      handleChange={(val) => setCountry(val)}
                    />
                  </div>
                  <div className="col-12 ">
                    <textarea
                      name=""
                      id=""
                      style={{ resize: "none" }}
                      placeholder="Write about the company"
                      className="resume-input m-0"
                      cols="30"
                      rows="3"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown3}
              >
                <p className="personal-title m-0">Links to connect</p>
                <img src={downArrow} alt="" />
              </div>
              <div
                className="resume-box"
                style={
                  dropdown3 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                <div className="resume-box">
                  {certificates.map((element, index) => {
                    return (
                      <>
                        <div className="row" key={index}>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <input
                              type="text"
                              placeholder="Website link"
                              className="resume-input"
                              value={weblink}
                              onChange={(e) => setWeblink(e.target.value)}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <input
                              type="text"
                              placeholder="LinkedIn link"
                              className="resume-input"
                              value={linkedinLink}
                              onChange={(e) => setLinkedinLink(e.target.value)}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <input
                              type="text"
                              placeholder="Company email address"
                              className="resume-input"
                              value={companyEmail}
                              onChange={(e) => setCompanyEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #CBD5E1",
                            marginBottom: "12px",
                          }}
                        ></div>
                        {/* <div className="d-flex align-items-center justify-content-between mb-2">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            onClick={(e) =>
                              setCertificates([
                                ...certificates,
                                {
                                  webSiteLink: "",
                                  linkedIn: "",
                                  company: "",
                                },
                              ])
                            }
                            className="plush-text"
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add more
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text"
                            disabled={certificates.length === 1}
                            onClick={() => DeleteCertificates(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div> */}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="bottom-btn">
              <div className="text-center">
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <NavLink
                  to={"/recruiter-help"}
                  className="skip-btn d-block text-decoration-none"
                >
                  Skip for now
                </NavLink>
              </div>
            </div>
          </div>
        )}

        {/* Desktop Screen */}
        {loader ? (
          <Loader />
        ) : (
          <div className="desktop-show-hide">
            <div className="profile-build">
              <div className="profile-line"></div>
              <h6 className="build-title">Build company profile</h6>
              <p className="build-text">
                Build your profile for a seamless hiring experience
              </p>
              <div
                className="text-center d-flex flex-column justify-content-center align-items-center send-resume-btn"
                {...getRootProps()}
              >
                {/* <input
              type="file"
              hidden
              id="file"
              inputprops={{ accept: "image/*" }}
              name="file"
              onChange={fileChangedHandler}
            /> */}
                <input {...getInputProps()} />
                {selectedFile == null || selectedFile == "" ? (
                  <label
                    htmlFor="file"
                    style={{ width: "190px" }}
                    className="auto-button"
                  >
                    Upload Company Logo
                  </label>
                ) : (
                  <button className="done-btn">
                    Uploaded
                    <img className="ps-2" src={done} alt="" />
                  </button>
                )}
                {selectedFile == "" || selectedFile == null ? (
                  <p className="information">
                    <img src={info} alt="" /> Upload max 2MB .pdf file only
                  </p>
                ) : (
                  <p className="file-name">
                    {selectedFile?.name ? (
                      <>
                        {selectedFile?.name}
                        <button onClick={removeFile} className="close-btn">
                          <img src={close} alt="" />
                        </button>
                      </>
                    ) : null}
                  </p>
                )}
              </div>
              <div className="border-bottom"></div>
              <div className="resume-box">
                <h6 className="resume-title">Company Details</h6>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Company Name"
                      className="resume-input"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <IndustrySearch
                      options={industryList}
                      label="name"
                      selectedVal={value}
                      handleChange={(val) => {
                        setValue(val);
                        setIndustry(val);
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      ref={ref1}
                      className="dropdown"
                      onMouseEnter={() => setDropHover2(true)}
                      onMouseLeave={() => setDropHover2(false)}
                    >
                      <div
                        onClick={handleOnClick1}
                        style={{
                          outline: dropdown2 ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {companyStrength}
                        <img
                          src={dropHover2 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: dropdown2 ? "block" : "none" }}
                      >
                        {strengthItme?.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setCompanyStrength(e.target.textContent);
                                setDropdown2(false);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="number"
                      placeholder="Year of Found"
                      className="resume-input"
                      value={yearFounded}
                      onChange={(e) => setYearFounded(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <CitySearch
                      options={cityList}
                      label="name"
                      selectedVal={headQuater}
                      handleChange={(val) => setHeadQuater(val)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <CountrySearch
                      options={countryList}
                      label="name"
                      selectedVal={country}
                      handleChange={(val) => setCountry(val)}
                    />
                  </div>
                  <div className="col-12 ">
                    <textarea
                      name=""
                      id=""
                      style={{ resize: "none" }}
                      placeholder="Write about the company"
                      className="resume-input m-0"
                      cols="30"
                      rows="3"
                      value={about}
                      onChange={(e) => setAbout(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="resume-box">
                <h6 className="resume-title">Links to connect</h6>
                {certificates.map((element, index) => {
                  return (
                    <>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Website link"
                            className="resume-input"
                            value={weblink}
                            onChange={(e) => setWeblink(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="LinkedIn link"
                            className="resume-input"
                            value={linkedinLink}
                            onChange={(e) => setLinkedinLink(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Company email address"
                            className="resume-input"
                            value={companyEmail}
                            onChange={(e) => setCompanyEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-between mb-2">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={(e) =>
                            setCertificates([
                              ...certificates,
                              {
                                webSiteLink: "",
                                linkedIn: "",
                                company: "",
                              },
                            ])
                          }
                          className="plush-text"
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add another link
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          disabled={certificates.length === 1}
                          onClick={() => DeleteCertificates(index)}
                          className="plush-text"
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div> */}
                    </>
                  );
                })}
              </div>
              <div className="border-bottom" style={{ margin: "16px 0" }}></div>
              <div className="text-center pb-4">
                <button className="back-btn" onClick={() => navigate(-1)}>
                  Back
                </button>
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <NavLink to={"/recruiter-help"}>
                  <button className="skip-btn">Skip for now</button>
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default RecruiterBuild;
