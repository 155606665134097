import React, { useContext, useEffect, useState } from "react";
import CalenderContext from "../context/calender/CalenderContext";
import closeBtn from "../assets/close-bt.svg";
import closeB from "../assets/talent/close-btn.png";
import Calendar from "react-calendar";
import moment from "moment";

function CareerCalender({ toggle2, setToggle2 }) {
  const modal = useContext(CalenderContext);
  const { openCalender, handleOpen } = modal;

  const [value, onChange] = useState(new Date());
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = ["All", "Morning slots", "Evening slots"];
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [timeSlots, setTimeSlots] = useState([]);

  const generateTimeSlots = () => {
    const date = new Date();
    const startHour = 5;
    const endHour = 9;
    const slots = [];

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let minute = 0; minute < 60; minute += 60) {
        const period = hour < 12 ? "AM" : "PM";
        const formattedHour = hour <= 12 ? hour : hour - 12;
        const formattedTime = `${moment(value).format(
          "DD MMM"
        )} ${formattedHour}:${minute === 0 ? "00" : minute} ${period}`;
        slots.push(formattedTime);
      }
    }

    setTimeSlots(slots);
  };

  // Call the function to generate time slots when the component mounts
  useEffect(() => {
    generateTimeSlots();
  }, [value]);

  // if (openCalender) {
  return (
    <>
      <div className="calender-modal">
        <div className="calender-box">
          <button className="close-btn">
            <img src={closeBtn} alt="" onClick={() => setToggle2(false)} />
          </button>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar onChange={onChange} value={value} />
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <h6 className="calender-title m-0">Choose time slot(s)</h6>
              <div className="calender-tab">
                {tabContent.map((content, index) => (
                  <button
                    key={index}
                    onClick={() => handleTabClick(index)}
                    className={activeTab === index ? "active-tab" : ""}
                  >
                    {content}
                  </button>
                ))}
              </div>
              <div className="tab-content">
                <div className="d-flex align-items-center justify-content-between calender-list">
                  <div className="checkbox-calender-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Experience"
                      className="checkbox-calender-button-input"
                      id="checkbox1"
                    />
                    <label
                      className="checkbox-calender-button__label"
                      htmlFor="checkbox1"
                    >
                      <span className="checkbox-calender-button__custom"></span>
                    </label>
                  </div>
                  <h6 className="calender-list-title m-0">
                    {moment(value).format("DD MMM")}
                  </h6>
                  <p className="calender-list-time m-0">
                    {moment(new Date()).format("h:mm A")}
                  </p>
                  <p className="calender-list-day m-0">
                    {moment(value).format("ddd")}
                  </p>
                </div>
                <h6 className="calender-time-table m-0">Selected slots</h6>
                <div className="row">
                  {timeSlots?.length > 0 &&
                    timeSlots?.map((item, index) => (
                      <div key={index} className="col-6 p-0">
                        <div className="selected-slots">
                          <p className="selected-slot-title m-0">{item}</p>
                          <img
                            style={{ marginLeft: "4px" }}
                            src={closeB}
                            onClick={() => {
                              const filter = timeSlots?.filter(
                                (item, i) => i !== index
                              );
                              setTimeSlots(filter);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // }

  // return null;
}

export default CareerCalender;
