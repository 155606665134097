import React, { useContext, useState } from "react";
import "./dashboard.css";
import Slider from "react-slick";
import round from "../assets/talent/round.svg";
import calender from "../assets/calender.svg";
import CalenderContext from "../context/calender/CalenderContext";
import chat from "../assets/chat-track.svg";
import AllModalContext from "../context/allModal/AllModalContext";
import { useMain } from "../hook/useMain";
import CareerChat from "./Chat/CareerChat";
import CareerCalender from "./CareerCalender";

function CareerCard({ item, index }) {
  const modal = useContext(CalenderContext);
  const careerModal = useContext(AllModalContext);
  const { toggleCareer } = careerModal;
  const { handleOpen } = modal;
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  const { BookCounsleores } = useMain();

  const bookCounselors = async (index, id) => {
    setToggle2(index);

    // const userId = localStorage.getItem("userId");

    // const result = await BookCounsleores({ userId }, id);
    // if (result?.statusCode === 200) {
    //   alert(result?.message);
    // } else if (result?.statusCode === 500) {
    //   alert(result?.message);
    // }
  };
  return (
    <>
      <div className="career-card">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <button className="career-actively m-0">Mentor</button>
          <p className="career-hour m-0">499/- Per Hour</p>
        </div>
        <div className="d-flex align-items-center">
          <img src={round} alt="" />
          <div className="ps-3">
            <h6 className="career-card-title m-0">{item?.name}</h6>
            <p className="career-card-text m-0">10 Years Of Exp</p>
          </div>
        </div>
        <div className="career-card-border"></div>
        <p className="career-item m-0">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum a
          beatae reiciendis maxime cum minus magni quasi cupiditate, eum, ipsam
          molestiae enim ullam qui veniam!
        </p>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ gap: "12px" }}
        >
          <div
            className="book-your-slot"
            // onClick={() => bookCounselors(index, item?._id)}
          >
            <a
              href="https://calendly.com/recruitifie/career-counselling-clone"
              target="_blank"
            >
              <button>Book Your Slot</button>
            </a>
            <img src={calender} alt="" />
          </div>
          <div
            className="track-card me-0 d-flex flex-row h-auto"
            style={{
              marginTop: "12px",
              gap: "4px",
              borderRadius: "8px",
              border: "1px solid #F1F5F9",
              background: "#F8FAFC",
              padding: "6px ",
            }}
            onClick={() => setToggle(index)}
          >
            <img src={chat} alt="" />
            <h6 className="track-text m-0">Chat</h6>
          </div>
        </div>
      </div>
      {toggle === index && (
        <CareerChat setToggle={setToggle} senderId={item?._id} />
      )}
      {toggle2 === index && (
        <CareerCalender toggle2={toggle2} setToggle2={setToggle2} />
      )}
    </>
  );
}

function CareerSlider({ data }) {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      {/* <Slider {...settings}> */}
      <div className="recommended-container" style={{ height: "max-content" }}>
        {data?.map((item, index) => (
          // <div key={index}>
          <CareerCard key={index} item={item} index={index} />
          // </div>
        ))}
      </div>
      {/* </Slider> */}
    </>
  );
}

export default CareerSlider;
