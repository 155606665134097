import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import SideBarContext from "../../context/SidebarContext";
import RecruiterSidebar from "../sideBar/RecruiterSidebar";
import { useNavigate } from "react-router-dom";
import box from "../../assets/calender/box.svg";
import downArrow from "../../assets/select-down.svg";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";

function DraftCard() {
  const navigate = useNavigate();
  return (
    <>
      <div className="draft-card">
        <div className="d-flex align-items-start">
          <img src={box} alt="" style={{ marginRight: "8px" }} />
          <div className="w-100">
            <h2 className="draft-card-title">Product manager</h2>
            <div className="d-flex justify-content-between align-items-center">
              <p className="draft-card-text">product</p>
              <p className="draft-days">2 days ago</p>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
        <ul className="draft-list">
          <li>Full-time · Entry level</li>
          <li>11-50 employees · IT Services and IT Consulting</li>
          <li>See how you compare to 843 applicants.</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
        </ul>
        {/* <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <h6 className="draft-sub-title">Skills you will gain</h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <p className="m-0 draft-skills">Creativity</p>
            <p className="m-0 draft-skills">Art</p>
            <p className="m-0 draft-skills">History</p>
            <p className="m-0 draft-skills">Art History</p>
            <p className="m-0 draft-skills">Art Direction</p>
          </div>
        </div> */}
        <button
          className="draft-edit-btn"
          onClick={() => navigate("/dashboard/table")}
        >
          View Applicants
        </button>
        <button
          className="draft-mange-btn"
          style={{ fontSize: "14px", fontWeight: 600 }}
        >
          Manage Application
        </button>
      </div>
    </>
  );
}

const selectItem = [
  {
    id: 1,
    option: "Within 1 months",
    selected: false,
  },
  {
    id: 2,
    option: "Within 2 months",
    selected: false,
  },
  {
    id: 3,
    option: "Within 3 months",
    selected: false,
  },
  {
    id: 4,
    option: "Within 4 months",
    selected: false,
  },
  {
    id: 5,
    option: "Within 5 months",
    selected: false,
  },
];

function AllJobs() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const sidebar = useContext(SideBarContext);
  const { isSidebarOpen } = sidebar;

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [dropdown1, setDropdown1] = useState("Open");
  const [dropdown2, setDropdown2] = useState("Full-time/Part-time");
  const [dropdown3, setDropdown3] = useState("Date posted");
  const [dropdown4, setDropdown4] = useState("Onsite/Remote");
  return (
    <>
      <Header />
      <section className="dashboard">
        <div className="dashboard-box">
          <RecruiterSidebar />
          <div
            className="all-job-container"
            style={{ left: isSidebarOpen ? "85px" : "354px" }}
          >
            <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
              <div
                className="draft-dropdown"
                onMouseEnter={() => setHover1(true)}
                onMouseLeave={() => setHover1(false)}
              >
                <div className="dropdown-btn">
                  {dropdown1}
                  <img
                    src={hover1 ? downArrowHover : downArrow}
                    style={{ marginLeft: "4px" }}
                    alt=""
                  />
                </div>
                <div
                  className="draft-dropdown-content"
                  // style={{ display: isActive1 ? "block" : "none" }}
                >
                  {selectItem.map((element, index) => {
                    return (
                      <div
                        onClick={(e) => {
                          setDropdown1(e.target.textContent);
                          // setIsActive1(false);
                        }}
                        className="item"
                      >
                        {element.option}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="draft-dropdown"
                onMouseEnter={() => setHover2(true)}
                onMouseLeave={() => setHover2(false)}
              >
                <div
                  // onClick={(e) => {
                  //   setIsActive2(!isActive2);
                  // }}
                  className="dropdown-btn"
                >
                  {dropdown2}
                  <img
                    src={hover2 ? downArrowHover : downArrow}
                    style={{ marginLeft: "4px" }}
                    alt=""
                  />
                </div>
                <div
                  className="draft-dropdown-content"
                  // style={{ display: isActive2 ? "block" : "none" }}
                >
                  {selectItem.map((element, index) => {
                    return (
                      <div
                        onClick={(e) => {
                          setDropdown2(e.target.textContent);
                          // setIsActive2(!isActive2);
                        }}
                        className="item"
                      >
                        {element.option}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="draft-dropdown"
                onMouseEnter={() => setHover3(true)}
                onMouseLeave={() => setHover3(false)}
              >
                <div
                  // onClick={(e) => {
                  //   setIsActive3(!isActive3);
                  // }}
                  className="dropdown-btn"
                >
                  {dropdown3}
                  <img
                    src={hover3 ? downArrowHover : downArrow}
                    style={{ marginLeft: "4px" }}
                    alt=""
                  />
                </div>
                <div
                  className="draft-dropdown-content"
                  // style={{ display: isActive3 ? "block" : "none" }}
                >
                  {selectItem.map((element, index) => {
                    return (
                      <div
                        onClick={(e) => {
                          setDropdown3(e.target.textContent);
                          // setIsActive3(!isActive3);
                        }}
                        className="item"
                      >
                        {element.option}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="draft-dropdown"
                onMouseEnter={() => setHover4(true)}
                onMouseLeave={() => setHover4(false)}
              >
                <div
                  // onClick={(e) => {
                  //   setIsActive4(!isActive4);
                  // }}
                  className="dropdown-btn"
                >
                  {dropdown4}
                  <img
                    src={hover4 ? downArrowHover : downArrow}
                    style={{ marginLeft: "4px" }}
                    alt=""
                  />
                </div>
                <div
                  className="draft-dropdown-content"
                  // style={{ display: isActive4 ? "block" : "none" }}
                >
                  {selectItem.map((element, index) => {
                    return (
                      <div
                        onClick={(e) => {
                          setDropdown4(e.target.textContent);
                          // setIsActive4(!isActive4);
                        }}
                        className="item"
                      >
                        {element.option}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="draft-dropdown"
                // onMouseEnter={() => setHover1(true)}
                // onMouseLeave={() => setHover1(false)}
              >
                <div
                  // onClick={(e) => {
                  //   setIsActive(!isActive);
                  // }}
                  className="dropdown-btn"
                >
                  All Filter
                </div>
                {/* <div
                className="draft-dropdown-content"
                style={{ display: isActive ? "block" : "none" }}
              >
                {selectItem.map((element, index) => {
                  return (
                    <div
                      onClick={(e) => {
                        setIsSelected(e.target.textContent);
                        setIsActive(!isActive);
                      }}
                      className="item"
                    >
                      {element.option}
                    </div>
                  );
                })}
              </div> */}
              </div>
            </div>
            <div className="all-job-box">
              <h6 className="all-job-title">Open Applications</h6>
              <div className="d-flex flex-wrap" style={{ gap: "16px" }}>
                <DraftCard title="Junior UX and UI designer" jobName="Design" />
                <DraftCard title="Senior UX Researcher" jobName="Product" />
                <DraftCard title="Web Developer" jobName="Developer" />
                <DraftCard title="Web Developer" jobName="Developer" />
                <DraftCard title="Senior UX Researcher" jobName="Product" />
                <DraftCard title="Junior UX and UI designer" jobName="Design" />
              </div>
            </div>
            <div className="all-job-box">
              <h6 className="all-job-title">Open Applications</h6>
              <div className="d-flex flex-wrap" style={{ gap: "16px" }}>
                <DraftCard title="Junior UX and UI designer" jobName="Design" />
                <DraftCard title="Senior UX Researcher" jobName="Product" />
                <DraftCard title="Web Developer" jobName="Developer" />
                <DraftCard title="Web Developer" jobName="Developer" />
                <DraftCard title="Senior UX Researcher" jobName="Product" />
                <DraftCard title="Junior UX and UI designer" jobName="Design" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AllJobs;
