import React, { useContext, useEffect, useState } from "react";
import "./header.css";
import logo from "../../assets/logo.svg";
import profile from "../../assets/user-profile.svg";
import mobileLogo from "../../assets/mobile-logo.svg";
import leftArrow from "../../assets/stories/left-arrow.svg";
import rightArrow from "../../assets/recruiter-subHeader/left.svg";
import searchImg from "../../assets/white-search.svg";
import upgrade from "../../assets/upgrade.svg";
import { Link, useNavigate } from "react-router-dom";
import UpgradeModal from "../../dashboard/UpgradeModal";
import Dropdown from "../../assets/talent/header-down.svg";
import { useMain } from "../../hook/useMain";
import AllModalContext from "../../context/allModal/AllModalContext";
import UpgradeRecruiterModel from "../../dashboard/UpgradeRecruiterModel";
import NotificationModal from "../NotificationModal";
import BellIcon from "../../assets/notifications.svg"

const searchItem = [
  {
    name: "Hotel Management",
  },
  {
    name: "UX and UI Designer",
  },
  {
    name: "Graphic Designer",
  },
  {
    name: "Management Internships",
  },
  {
    name: "UI Designer",
  },
];

function RecruiterHeader({ title, search }) {
  const [toggleSideBar, setSidebarToggle] = useState(false);
  const [searchInput, setSearchInput] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [name, setName] = useState("");
  const modal = useContext(AllModalContext);
  const { toggleRecruitierUpgrade } = modal;
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { setSidebarSearch, getNotification } = useMain();
  const [dummyId, setDummyId]=useState("")

  useEffect(() => {
    const recruiterToken = localStorage.getItem("recruiterToken");
    const recruiterId = localStorage.getItem("recruiteId");
    if (
      recruiterToken &&
      recruiterId &&
      recruiterToken != "undefined" &&
      recruiterId != "undefined"
    ) {
      setName(JSON.parse(localStorage.getItem("recruiterData")).name);
    } else {
      setName("Me");
    }
  }, []);

  const showNotificationModal = () => {
    setShowNotification(true);
  };
  const closeNotificationModal = () => {
    setShowNotification(false);
  };

  const handleNotification =()=>{
    setNotifications([])
  }

  const getnotti = async (id) => {
    const res = await getNotification(id);
    console.log(res);
    if (res?.statusCode == 200) {
      setNotifications(res?.data);
    }
  };

  useEffect(() => {
    const recruiterId = localStorage.getItem("recruiteId");
    if (recruiterId) {
      setDummyId(recruiterId)
      getnotti(recruiterId);
    }
  }, []);

  const auth = localStorage.getItem("auth");

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const navigate = useNavigate();

  function openSidebar() {
    setSidebarToggle(!toggleSideBar);
  }
  function openSearch() {
    setSearchInput(!searchInput);
  }

  function handleOnLogin() {
    navigate("/log-in");
  }
  function handleUpgrade() {
    navigate("/recruiter/upgrade");
  }

  const throwOnDashboard = () => {
    const recruiterId = localStorage.getItem("recruiteId");
    const recruiterToken = localStorage.getItem("recruiterToken");
    if (recruiterId && recruiterToken) {
      navigate("/recruiter-dashboard");
    }
  };

  return (
    <div>
      <header className="color-header">
        <div
          className="search-container"
          style={{ display: searchInput ? "block" : "none" }}
        >
          <div
            className="search-input"
            style={{ display: searchInput ? "flex" : "none" }}
          >
            <input
              type="text"
              onChange={(e) => setSidebarSearch(e.target.value)}
            />
            <img src={searchImg} onClick={openSearch} alt="" />
          </div>
          <div
            className="search-items-box"
            style={{ display: searchInput ? "flex" : "none" }}
          >
            <ul>
              {searchItem?.map((item, i) => {
                return (
                  <>
                    <li key={i} className="search-d-item">
                      {item?.name}
                    </li>
                  </>
                );
              })}
            </ul>
            <div className="search-border"></div>
            <button className="apply-filter">
              Apply Filters <img src={rightArrow} alt="" />
            </button>
          </div>
        </div>

        <div
          className="overlay"
          onClick={() => {
            openSidebar();
          }}
          style={toggleSideBar ? { right: 0 } : { right: "100%" }}
        ></div>
        <div className="logo">
          {/* <Link to="/"> */}
          <img
            src={logo}
            alt=""
            className="img-fluid"
            onClick={throwOnDashboard}
          />
          {/* </Link> */}
        </div>
        <div
          className="mobile-logo"
          style={{ display: searchInput ? "none" : "block" }}
        >
          {title === "" ? (
            <img
              src={mobileLogo}
              alt=""
              className="img-fluid"
              onClick={throwOnDashboard}
            />
          ) : (
            <div className="build-btn" onClick={() => navigate(-1)}>
              <img src={leftArrow} style={{ cursor: "pointer" }} alt="" />{" "}
              <button>{title}</button>
            </div>
          )}
        </div>
        <div
          className="list align-items-center"
          style={{
            display: searchInput ? "block" : "flex",
          }}
        >
          {search ? (
            <button
              className="header-search-btn"
              style={{
                display: searchInput ? "none" : "flex",
              }}
              onClick={openSearch}
            >
              <img src={searchImg} alt="" />
            </button>
          ) : null}
          <button className="upgrade-btn" onClick={toggleRecruitierUpgrade}>
            Plan Upgrade
          </button>
          <img
            className="me-4 upgrade-btn-img"
            src={notifications?.length>0?upgrade:BellIcon}
            alt=""
            onClick={showNotificationModal}
          />
          <span className="notification-count-2">{notifications?.length||0}</span>
          {auth == undefined ? (
            <button
              className="login-btn"
              style={{
                display: searchInput ? "none" : "block",
              }}
              onClick={handleOnLogin}
            >
              Get Started
            </button>
          ) : null}
          {auth ? (
            <button
              className="menu-dropdown align-items-center"
              style={{
                display: searchInput ? "none" : "flex",
                gap: "8px",
              }}
              onClick={handleDropdown}
            >
              <img src={profile} alt="" /> Me <img src={Dropdown} alt="" />
            </button>
          ) : null}
          {dropdown ? (
            <ul className="menu">
              <li
                className="menu-item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="d-flex align-items-center">
                  {/* <Link
                    to={
                      pathname === "/recruiter-dashboard"
                        ? "/recruiter-set"
                        : "/set-job"
                    }
                  >
                    Profile
                  </Link>
                  <p
                    className="m-0"
                    style={{
                      color: "#0D9488",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      paddingLeft: "20px",
                      lineHeight: "16px",
                    }}
                  >
                    60% complete
                  </p> */}
                </div>
              </li>
              <li
                className="menu-item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Link to="/setting">Profile and Settings </Link>
                {/* <button
                  style={{
                    color: "#FFF",
                    borderRadius: "100px",
                    padding: "2px 8px",
                    border: "none",
                    background: "#D97706",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "16px",
                  }}
                  onClick={() => navigate("/help-us")}
                >
                  Verify Adhaar
                </button> */}
              </li>
              <li className="menu-item">
                <Link to="/recruiter/help-and-support">Help and Support</Link>
              </li>
              {/* <li className="menu-item">
                <Link to="">Contact us</Link>
              </li> */}
              <li
                className="menu-item"
                onClick={() => {
                  localStorage.clear();
                  navigate("/home");
                }}
              >
                <Link to="">Log out</Link>
              </li>
              <button className="menu-upgrade-btn" onClick={handleUpgrade}>
                Upgrade Plans
              </button>
            </ul>
          ) : null}
        </div>
        <button onClick={() => openSidebar()} className="bras-btn">
          <i className="fa fa-regular fa-bars"></i>
        </button>
      </header>
      <UpgradeRecruiterModel />
      <NotificationModal
        open={showNotification}
        onClose={closeNotificationModal}
        notifications={notifications}
        handleNotification={handleNotification}
        userId={dummyId}
      />
    </div>
  );
}

export default RecruiterHeader;
