import React, { useState } from "react";
import arrow from "../../assets/left-arrow.svg";
import arrowHover from "../../assets/our/right-arrow-btn.svg";
import { useNavigate } from "react-router-dom";
import FormHeader from "../../components/header/FormHeader";
import '../../App.css'
import CareerHead from "../header/CareerHead";

function CareerComplete() {
  const navigate = useNavigate();
  const [dashHover, setDashHover] = useState(false);
  function handleOnClick() {
    navigate("/career/table");
  }
  return (
    <>
      <CareerHead />
      <section className="complete-section">
        <div className="complete-box">
          <div className="wrapper"></div>
          <div>
            <h2 className="complete-title">Yaay!</h2>
            <p className="m-0 complete-text">
              Your application is complete. <br />
              Grab your dream job now.
            </p>
          </div>
          <button onMouseEnter={() => setDashHover(true)} onMouseLeave={() => setDashHover(false)} className="dashboard-btn d-flex align-items-center justify-content-center" onClick={handleOnClick}>
            Dashboard <img src={dashHover ? arrowHover : arrow} style={{marginLeft: "8px", height: "16px"}} alt="" />
          </button>
        </div>
      </section>
    </>
  );
}

export default CareerComplete;
