import React, { useState } from "react";
import Done from "../../assets/interview/complete.svg";
import './account.css'
import FormHeader from "../header/FormHeader";
import Header from "../header/Header";

function BugReport() {
  const [OpenModal, setOpenModal] = useState(false);
  function HandleModal() {
    setOpenModal(!OpenModal);
  }
  return (
    <>
      <Header title="Contact Us" />
      <div
        className="setting-page"
        style={{ height: "100vh", marginTop: "48px", position: "unset" }}
      >
        <div className="help-container">
          <div>
            <h4 className="help-title">What’s wrong?</h4>
            <p
              className="help-text"
              style={{ width: "auto", padding: "0 12px" }}
            >
              Describe what is wrong and we will get back to you with the
              solution as soon as we can!
            </p>
            <div
              className="desktop-show-hide"
              style={{ borderBottom: "1px solid #E2E8F0", margin: "16px 0" }}
            ></div>
            <textarea
              name=""
              id=""
              style={{ resize: "none" }}
              cols="30"
              rows="3"
              className="bug-report-input"
              placeholder="Describe your job roles & responsibilities"
            ></textarea>
            <div
              className="text-center desktop-show-hide"
              style={{ marginTop: "12px" }}
            >
              <button className="help-back-btn">Back</button>
              <button
                className="setting-save-btn"
                onClick={HandleModal}
                style={{ width: "362px" }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-btn mobile-show-hide">
        <div className="d-flex flex-column">
          <button className="save-btn">Submit</button>
          <button className="skip-btn">Back</button>
        </div>
      </div>
      {OpenModal ? (
        <div className="report-modal">
          <div className="report-overlay"></div>
          <div className="report-box">
            <img src={Done} alt="" />
            <div
              className="d-flex flex-column text-center"
              style={{ gap: "12px" }}
            >
              <h6 className="m-0 report-modal-title">
                Report Submitted Successfully
              </h6>
              <p className="m-0 report-modal-text">
                Thank you for your feedback! <br />
                Our executives will review it and try to resolve the issue.
              </p>
            </div>
            <button className="close-btn-" onClick={HandleModal}>
              Close
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default BugReport;
