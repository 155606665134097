import React, { useEffect, useState } from "react";
import { useMain } from "../hook/useMain";
import box from "../assets/office-building.png";
import save from "../assets/save-btn.svg";
import left from "../assets/left-arrow.svg";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader/Loader";
import savedImg from "../assets/saved-application.svg";
import { toast } from "react-toastify";

const SaveCard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sortData, setSortData] = useState([]);

  const { getsaveJob, saveJob, sidebarSearch } = useMain();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const getData = async (id) => {
    setLoader(true);
    const res = await getsaveJob(id);
    if (res?.statusCode === 200) {
      setLoader(false);
      setData(res?.data?.savedjobs);
      setSortData(res?.data?.savedjobs);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };
  useEffect(() => {
    const { _id } = JSON.parse(localStorage.getItem("userData"))||{};
    if (_id) {
      getData(_id);
    }
  }, []);

  const handleSave = async (jobid) => {
    setLoader(true);
    const { _id } = JSON.parse(localStorage.getItem("userData"));
    if (_id && jobid) {
      const res = await saveJob(jobid, { userId: _id });
      if (res?.statusCode == 200) {
        toast.success("Job unsaved successfully.");

        localStorage.setItem("userData", JSON.stringify(res?.data));
        setLoader(false);
        getData(_id);
      } else {
        toast.error("Failed to unsave job.");

        setLoader(false);
      }
    }
  };

  function handleOnApply(jobId) {
    navigate(`/dashboard/job-description/${jobId}`);
  }

  const formatToCurrency = (number) => {
    if (number >= 1000 && number < 10000) {
      return `₹${number}`;
    } else if (number >= 100000 && number < 10000000) {
      return `₹${(number / 100000).toFixed(0)}L`;
    } else if (number >= 10000000) {
      return `₹${(number / 100000).toFixed(0)}C`;
    } else {
      return `₹${number}`;
    }
  };

  useEffect(() => {
    filteredDrafts(sidebarSearch);
  }, [sidebarSearch]);

  const filteredDrafts = (sidebarSearch) => {
    const filterVal =
      sortData &&
      sortData.length > 0 &&
      sortData?.filter((item) => {
        const regex = new RegExp(sidebarSearch, "i"); // 'i' for case-insensitive search
        return (
          sidebarSearch.trim() === "" ||
          Object.values(item.jobInfo).some((value) => regex.test(String(value)))
        );
      });
    setData(filterVal);
  };

  return (
    <>
      {loader && <Loader />}
      {data.length === 0 ? (
        <div className="unsaved-image">
          <img src={savedImg} alt="" />
          <h6 className="unsaved-text m-0">No Saved Applications</h6>
        </div>
      ) : (
        data &&
        data.length > 0 && (
          <div className="recommended-container" style={{ gap: "16px" }}>
            {data.map((job, ind) => (
              <div className="save-card m-0" key={`savecard-${ind}`}>
                <button className="save-actively">Actively hiring</button>
                <div className="d-flex align-items-center">
                  <img
                    src={job?.companyLogo ? job?.companyLogo : box}
                    alt=""
                    style={{ height: "25px", width: "25px" }}
                  />
                  <div className="ps-3">
                    <h6 className="save-title m-0">{job?.jobInfo?.position}</h6>
                    <p className="save-text m-0">{job?.companyName}</p>
                  </div>
                </div>
                <div className="save-card-border"></div>
                <ul className="pl-2 m-0">
                  {/* <li className="save-item">Start up</li> */}
                  <li className="save-item">{job?.jobInfo?.jobType}</li>
                  {/* <li className="save-item">11-{job?.companyStrength} employees</li> */}
                  <li className="save-item">{job?.jobInfo?.workPlace}</li>
                  <li className="save-item">
                    {formatToCurrency(Number(job?.jobInfo?.salaryMin))} -{" "}
                    {formatToCurrency(Number(job?.jobInfo?.salaryMax))}
                  </li>
                </ul>
                {/* <div className="d-flex align-items-center">
              <p className="save-item m-0">{job?.jobInfo?.workPlace}</p>
              <div className="save-dot"></div>
              <p className="save-item m-0">Start up</p>
              <div className="save-dot"></div>
              <p className="save-item m-0">{job?.jobInfo?.jobType}</p>
              <div className="save-dot"></div>
              <p className="save-item m-0">Immediately</p>
            </div> */}
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="apply-btn"
                    style={{
                      backgroundColor: userData?.appliedjobs.includes(job?._id)
                        ? "green"
                        : null,
                      color: userData?.appliedjobs.includes(job?._id)
                        ? "white"
                        : null,
                    }}
                  >
                    <button
                      onClick={() => handleOnApply(job._id)}
                      style={{
                        color: userData?.appliedjobs.includes(job?._id)
                          ? "white"
                          : null,
                      }}
                    >
                      {userData?.appliedjobs.includes(job?._id)
                        ? "Applied"
                        : "Apply"}
                    </button>
                    <img src={left} alt="" />
                  </div>
                  <button
                    className="save-btn"
                    onClick={() => handleSave(job?._id)}
                  >
                    <img src={save} alt="" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )
      )}
    </>
  );
};

export default SaveCard;
