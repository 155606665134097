import React, { useContext, useEffect } from "react";
import Header from "../header/Header";
import RecruiterSidebar from "../sideBar/RecruiterSidebar";
import SideBarContext from "../../context/SidebarContext";
import { useNavigate } from "react-router-dom";

function PostNewJob() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const sidebar = useContext(SideBarContext);
  const { isSidebarOpen } = sidebar;
  return (
    <>
      <Header />
      <section className="dashboard ">
        <div className="dashboard-box">
          <RecruiterSidebar />
          <div
            className="post-job-container "
            style={{ left: isSidebarOpen ? "85px" : "354px" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="post-jov-title">Create Job Description</h6>
              <button className="view-draft-btn" onClick={() => navigate("/dashboard/post-new-job/draft")}>View Drafts</button>
            </div>
            <div
              className="acc-input w-50"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="company-name">Company Name</label>
              <input type="text" placeholder="Youth Empower Foundation" />
            </div>
            <div
              className="acc-input w-100"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="company-name">About Company</label>
              <textarea
                name=""
                cols="30"
                rows="5"
                placeholder="About Company"
              ></textarea>
            </div>
            <div style={{ borderBottom: "1px solid #E2E8F0" }}></div>
            <div
              className="acc-input w-50"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="company-name">Position Requirement</label>
              <input type="text" placeholder="Job title" />
            </div>
            <div
              className="acc-input w-100"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="company-name">Job Brief</label>
              <textarea
                name=""
                cols="30"
                rows="3"
                placeholder="Describe about the job requirement"
              ></textarea>
            </div>
            <div
              className="acc-input w-100"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="company-name">Job Responsibilities</label>
              <textarea
                name=""
                cols="30"
                rows="3"
                placeholder="Describe about the job requirement"
              ></textarea>
            </div>
            <div
              className="acc-input w-100"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="company-name">
                Candidate qualifications requirement
              </label>
              <textarea
                name=""
                cols="30"
                rows="3"
                placeholder="Describe about the job requirement"
              ></textarea>
            </div>
            <div
              className="acc-input w-100"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="company-name">Benefits</label>
              <textarea
                name=""
                cols="30"
                rows="3"
                placeholder="Describe about the job requirement"
              ></textarea>
            </div>
            <div style={{ borderBottom: "1px solid #E2E8F0" }}></div>
            <div
              className="acc-input w-100"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="company-name">ADDItional</label>
              <input
                type="text"
                className="w-50"
                style={{ marginBottom: "8px" }}
                placeholder="Title"
              />
              <textarea name="" id="" cols="30" rows="3"></textarea>
            </div>
            <div className="d-flex justify-content-end">
              <button className="remove-btn">Clear All</button>
              <button className="invite-btn">Preview</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PostNewJob;
