import React, { useContext, useEffect, useState } from "react";
import "./recruiter.css";
import DummyInterview from "../../assets/recruiter/interview-dummy.svg";
import addIcon from "../../recruiter-img/add-icon.svg";
import SubHeader from "../subHeader/SubHeader";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import AccUp from "../../recruiter-img/acco-up.svg";
import box from "../../assets/images.png";
import Upcoming from "../../assets/recruiter-dashboard/upcoming.svg";
import RightArrow from "../../assets/recruiter-dashboard/right-arrow.svg";
import LightStar from "../../assets/recruiter-dashboard/light-star.svg";
import star from "../../assets/recruiter-dashboard/star.svg";
// import SubscribeSlider from "../subscribeSlider/SubscribeYearly";
import CourseSlider from "../courseSlider/CourseSlider";
import ArticleSlider from "../courseSlider/ArticlesSlider";
import RecruiterSubMenu from "../mobileMenu/RecruiterSubMenu";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import MobileHome from "./MobileHome";
import DummyUpcoming from "../../assets/recruiter/upcoming-dummy.svg";
import ApiCalendar from "react-google-calendar-api";
import { Link, NavLink, useNavigate } from "react-router-dom";
import facebook from "../../assets/search-btn/facebook.svg";
import Linked from "../../assets/search-btn/linked.svg";
import instagram from "../../assets/search-btn/instagram.svg";
import SubscribeYearly from "../subscribeSlider/SubscribeYearly";
import SubscribeMonthly from "../subscribeSlider/SubscribeMonthly";
import "./recruiter.css";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";
import moment from "moment";
import RecruiterHeader from "../header/RecruiterHeader";
import YearlySlider from "../subscribeSlider/YearlySlider";
import MonthlySlider from "../subscribeSlider/MonthlySlider";
import FirstChart from "./Chart/FirstChart";
import SecondChart from "./Chart/SecondChart";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import RecruiterAlertModel from "./RecruiterAlertModel";
import CalenderSlider from "./calender/CalenderSlider";

ChartJS.register(ArcElement, Tooltip, Legend);

function UpcomingCard({ data }) {
  return (
    <>
      {data && data?.length > 0 ? (
        data?.map((item, index) => (
          <div key={index} className="upcoming-card">
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex align-items-start" style={{ gap: "8px" }}>
                <img src={Upcoming} alt="" />
                <div className="d-flex flex-column" style={{ gap: "2px" }}>
                  <h6 className="upcoming-title m-0">{item?.userId?.name}</h6>
                  <p className="upcoming-category m-0">{item?.title}</p>
                  <p className="upcoming-date m-0">
                    {item?.date} | {item?.startTime}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between">
                <img src={RightArrow} alt="" />
                <p className="m-0 upcoming-date"> By Me</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center">
          <img src={DummyUpcoming} alt="" className="img-fluid" />
          <h6
            style={{
              color: "#B3B3B3",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "16px",
              marginBottom: "20px",
            }}
          >
            No interviews scheduled
          </h6>
        </div>
      )}
    </>
  );
}

function DraftCard({ draft }) {
  const [createDate, setCreateDate] = useState("");
  useEffect(() => {
    timeAgo(draft?.createdAt);
  }, []);

  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  return (
    <>
      <div className="draft-card">
        <div className="d-flex align-items-start">
          <img
            src={
              JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                ? JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                : box
            }
            alt=""
            style={{ marginRight: "8px", height: "25px", width: "25px" }}
          />
          <div className="w-100" style={{height:"50px"}}>
            <h2 className="draft-card-title">{draft?.jobInfo?.position}</h2>
            <div className="d-flex justify-content-between align-items-center">
              <p className="draft-card-text">{draft?.companyName}</p>
              <p className="draft-days">{createDate}</p>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
        <ul className="draft-list">
          <li>
            {draft?.jobInfo?.jobType} · {draft?.jobInfo?.workPlaceType}
          </li>
          <li>
            {draft?.userId?.companyStrength} employees ·
            {draft?.userId?.industry}
          </li>
          {/* <li>See how you compare to 843 applicants.</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li> */}
        </ul>
        <Link
          style={{ textDecoration: "none" }}
          to={`/dashboard/all-application/review/${draft?._id}`}
        >
          <div className="draft-edit-btn">Edit</div>
        </Link>
      </div>
    </>
  );
}

function FeedbackCard({ title, data, value }) {
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    let totalCount = 0;

    if (data && data?.length > 0) {
      data?.forEach((item) => {
        totalCount += item[value];
      });
    }

    setAverageRating(totalCount / data.length);
  }, [data, value]);

  const Star = (counts) => {
    const ragtingStar = Array.from({ length: 5 }, (ele, index) => {
      let number = index + 1;
      return (
        <span key={index}>
          {Math.floor(counts) >= number ? (
            <img src={LightStar} alt="" />
          ) : (
            <img src={star} alt="" />
          )}
        </span>
      );
    });
    return ragtingStar;
  };

  return (
    <>
      {/* {data?.length > 0 &&
        data?.map((item, index) => ( */}
      <div className="recruiter-feedback-card">
        <h2 className="title">{title}</h2>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="d-flex flex-row" style={{ gap: "8px" }}>
            {Star(averageRating)}
          </div>
          <h3 className="rating">{Math.floor(averageRating)}/5</h3>
        </div>
        <div className="center-border"></div>
        {data?.length > 0 &&
          data?.map((item, index) => (
            <div key={index} className="w-100">
              <div className="rating-item">
                <div className="d-flex align-items-center">
                  <img src={LightStar} style={{ height: "16px" }} alt="" />
                  <h6 className="m-0 rating-num">{item?.[value]}/5</h6>
                </div>
                <h6 className="m-0 rating-name">{item?.userId?.name}</h6>
                <p className="m-0 rating-text">({item?.userId?.jobTitle})</p>
              </div>
              <p className="m-0 rating-comment">{item.desc}</p>
              <div className="center-border"></div>
            </div>
          ))}
      </div>
      {/* ))} */}
    </>
  );
}

function RecruiterDashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("auth") === "true";
    if (!isAuthenticated) {
      navigate("/log-in");
      return;
    }
  }, []);
  const token = localStorage.getItem("recruiterToken");
  const id = localStorage.getItem("recruiteId");

  useEffect(() => {
    if (token && id) {
      getData();
      getData2();
      draftData();
      getInterviewByRecruitr(id);
      getFeedback(id);
      getAllanalysist(id);
    }
  }, [token, id]);

  const config = {
    clientId:
      "<550814863959-dktt4vs2opva4h2roep053p58kt0fbu7.apps.googleusercontent.com>",
    apiKey: "<AIzaSyA2evqeadVUXL1EAc1jLvw5NInLqC7K5oA>",
    scope: "https://www.googleapis.com/auth/calendar",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
  };

  // const apiCalendar = new ApiCalendar(config);
  const menuItem = useContext(MobileContext);
  const { handleRecruiterToggle, recruiterMenu } = menuItem;
  const [accOpen1, setAccOpen1] = useState(true);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [radio, setRadio] = useState("Yearly");
  const [data, setData] = useState("");
  const [data2, setData2] = useState([]);
  const [draft, setDraft] = useState([]);
  const [draftLoader, setDraftLoader] = useState(false);
  const [getInterviewer, setGetInterviewer] = useState([]);
  const [feedback, setFeedBack] = useState([]);
  const [analysisData, setAnalysisData] = useState({});
  const [completeProfile, setCompleteProfile] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  var OpenApp = 23;
  var ClosedApp = 10;
  var InProgress = 650;
  var Rejected = 300;
  var Selected = 50;
  const chartTotal = OpenApp + ClosedApp;
  const TotalApp = InProgress + Rejected + Selected;

  const number1 = data?.length; // Replace this with your first number
  const number2 = 0; // Replace this with your second number

  const maxPercentage = 100;
  const gradientPercentage1 = Math.min(
    number1 * (maxPercentage / (number1 + number2)),
    maxPercentage
  );
  const gradientPercentage2 = Math.min(
    number2 * (maxPercentage / (number1 + number2)),
    maxPercentage
  );

  const {
    allPostByRecruiter,
    getAllByCompany,
    getAllDraft,
    getInterviewByRecruitrId,
    getFeedbackByUser,
  } = useMain();
  const navigation = useNavigate();

  const getData = async () => {
    setLoader1(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await allPostByRecruiter(token);
    if (res?.statusCode === 200) {
      setLoader1(false);
      setData(res?.data?.data);
    } else {
      toast.error("Somthing went wrong");
      setLoader1(false);
    }
  };

  const getData2 = async () => {
    setLoader2(true);
    const recruiterId = localStorage.getItem("recruiteId");
    // const res = await getAllByCompany(token);

    try {
      const response = await fetch(
        `https://recrutify-backend.vercel.app/api/recruiter/allapp/${recruiterId}`
      );
      const res = await response.json();
      if (res?.statusCode === 200) {
        setLoader2(false);
        setData2(res?.data?.newData);
      } else {
        // alert("Somthing went wrong");
        setLoader2(false);
      }
    } catch (error) {}
  };

  const getAllanalysist = async (id) => {
    setLoader2(true);
    const recruiterId = localStorage.getItem("recruiteId");
    // const res = await getAllByCompany(token);
    try {
      const response = await fetch(
        `https://recrutify-backend.vercel.app/api/recruiter/analysis/${id}`
      );
      const res = await response.json();
      if (res?.statusCode === 200) {
        setLoader2(false);
        setAnalysisData(res?.data?.data);
      } else {
        setLoader2(false);
      }
    } catch (error) {}
  };
  const draftData = async () => {
    const token = localStorage.getItem("recruiterToken");

    setDraftLoader(true);
    if (token) {
      const res = await getAllDraft(token);
      if (res?.statusCode === 200) {
        setDraftLoader(false);
        setDraft(res?.data);
      } else {
        // alert("Somthing went wrong");
        setDraftLoader(false);
      }
    } else {
      setDraftLoader(false);
    }
  };

  const getInterviewByRecruitr = async (id) => {
    setLoader3(true);
    const result = await getInterviewByRecruitrId(id);
    if (result?.statusCode === 200) {
      setLoader3(false);
      const currentDate = new Date();

      // Use the filter method to create a new array with only the relevant items
      const filteredData = result?.data?.filter((item, index) => {
        const giveDate = item?.date?.split("/");
        if (giveDate && giveDate.length > 0) {
          const givenDate = new Date(giveDate[2], giveDate[1] - 1, giveDate[0]);

          // Return true if the given date is greater than or equal to the current date
          return givenDate.getTime() >= currentDate.getTime();
        }
      });

      setGetInterviewer(filteredData);
    } else {
      setLoader3(false);
    }
  };

  const getFeedback = async (id) => {
    // setLoader4(true);
    const result = await getFeedbackByUser(id);
    if (result?.statusCode === 200) {
      // setLoader4(false);
      setFeedBack(result?.data);
    } else {
      // setLoader4(false);
    }
  };

  console.log(analysisData);

  useEffect(() => {
    const recruiterData = JSON.parse(localStorage.getItem("recruiterData"));
    if (!recruiterData) {
      setCompleteProfile(false);
      setIsModalOpen(true);
    } else {
      const requiredFields = [
        "name",
        "mobileNo",
        "email",
        "companyName",
        "about",
        "industry",
        "companyStrength",
        "yearFounded",
        "headQuater",
        "weblink",
      ];
      for (let field of requiredFields) {
        if (!recruiterData[field] || recruiterData[field] == "") {
          setCompleteProfile(false);
          setIsModalOpen(true);
          break;
        }
      }
    }
  }, []);

  const hadlecloseModel = () => {
    setIsModalOpen(false);
  };

  const getMonthName = (monthNumber) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months[monthNumber];
  };

  return (
    <>
      <AlertContainer />
      <RecruiterHeader title="" search={false} />
      <div className="mobile-show-hide">
        <MobileHome />
        <RecruiterSubMenu />
        <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
          <img
            src={top}
            alt=""
            style={{
              transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 150ms ease",
              height: "14px",
            }}
          />
          <img src={jobIcon} style={{ height: "24px" }} alt="" />
        </div>
      </div>
      <div className="desktop-show-hide">
        <SubHeader />
        <div className="recruiter-dashboard">
          <div className="recruiter-dashboard-container">
            <div className="chart-container">
              {loader1 ? (
                <Loader />
              ) : (
                <div className="chart-box">
                  <h6 className="chart-title">Analysis on Applications</h6>
                  <div className="d-flex align-items-center">
                    <div style={{ height: "150px", position: "relative" }}>
                      {analysisData?.jobpost && analysisData?.jobpost > 0 ? (
                        <>
                          <FirstChart newdata={analysisData} />
                          <div className="total-box-center">
                            {analysisData?.jobpost}
                          </div>
                        </>
                      ) : (
                        <div className="zero-circle">
                          <div className="zero-box-center">0</div>
                        </div>
                      )}
                    </div>
                    {/* <div
                      className="pie-chart"
                      style={{
                      background:
                        analysisData?.activejobpost === 0
                          ? "#FFF"
                          : `conic-gradient(#026D92 0 50%, #FFEAD7 0 70% )`,
                    }}
                    >
                      <div className="pie-total">
                        {analysisData?.closejobpost > 0
                          ? analysisData?.closejobpost
                          : ""}
                      </div>
                      <div className="pie-closed">
                        {number1 > 0 ? number1 : ""}
                      </div>
                      <div className="chart-total">{analysisData?.jobpost}</div>
                    </div> */}
                    <div className="chart-text" style={{ marginLeft: "32px" }}>
                      <div className="total-box">
                        <p className="box"></p> <p> Total Applications</p>
                      </div>
                      <div className="open-box">
                        <p className="box"></p> <p>Open Applications</p>
                      </div>
                      <div className="closed-box">
                        <p className="box"></p> <p>Closed Applications</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="chart-box">
                <h6 className="chart-title">Analysis on Applicants</h6>
                <div className="d-flex align-items-center">
                  <div style={{ height: "150px", position: "relative" }}>
                    {analysisData.totalapplication &&
                    analysisData.totalapplication > 0 ? (
                      <>
                        <SecondChart newdata={analysisData} />
                        <div className="total-box-center">
                          {analysisData.totalapplication}
                        </div>
                      </>
                    ) : (
                      <div className="zero-circle">
                        <div className="zero-box-center">0</div>
                      </div>
                    )}
                  </div>
                  {/* <div
                    className="pie-chart-2"
                    style={{
                      background:
                        analysisData?.totalapplication === 0
                          ? "#FFF"
                          : `conic-gradient(#026D92 0 50%, #FFEAD7 0 70%, #CBD5E1 0 )`,
                    }}
                  >
                    {analysisData?.totalapplication === 0 ? (
                      " "
                    ) : (
                      <div className="pie-total-2">
                        {analysisData?.rejected}
                      </div>
                    )}
                    {analysisData?.totalapplication === 0 ? (
                      " "
                    ) : (
                      <div className="pie-closed-2">
                        {analysisData?.totalapplication -
                          analysisData?.rejected -
                          analysisData?.pending || 0}
                      </div>
                    )}
                    {analysisData?.totalapplication === 0 ? (
                      " "
                    ) : (
                      <div className="pie-process-2">
                        {analysisData?.hirecandidate}
                      </div>
                    )}
                    <div className="chart-total">
                      {analysisData?.totalapplication}
                    </div>
                  </div> */}
                  {/* <div className="chart-round">
                  <div className="chart-total">{TotalApp}</div> */}
                  {/* <Pie
                    data={data2}
                    style={{ width: "130px", height: "130px" }}
                  /> */}
                  {/* </div> */}
                  <div className="chart-text" style={{ marginLeft: "32px" }}>
                    <div className="total-box">
                      <p className="box"></p> <p> Total Applications</p>
                    </div>
                    <div className="open-box">
                      <p className="box"></p> <p>In-progress Applicants</p>
                    </div>
                    <div className="closed-box">
                      <p className="box"></p> <p>Rejected Applicants</p>
                    </div>
                    <div className="selected-box">
                      <p className="box"></p> <p>Selected Applicants</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="interviewers">
              {loader2 ? (
                <Loader />
              ) : (
                <div
                  className="d-flex align-items-center w-100 justify-content-between"
                  style={{
                    borderRadius: "4px",
                    background: "#F1F5F9",
                    padding: "4px 8px",
                  }}
                >
                  <h6 className="interviewers-title">Interviewers</h6>
                  <h6 className="interviewers-title">{data2?.length}</h6>
                </div>
              )}
              <div className="d-flex flex-wrap" style={{ gap: "12px" }}></div>

              {data2 && data2?.length > 0 ? (
                data2?.map((item, i) => (
                  <div
                    key={i}
                    className="interview-accordion"
                    style={{
                      display: accOpen1 === i ? "flex" : "unset",
                      borderLeft:
                        accOpen1 === i
                          ? "1px solid #026D92"
                          : "1px solid  rgba(2, 109, 146, 0.30)",
                    }}
                  >
                    <div
                      onClick={() => setAccOpen1(i)}
                      className="d-flex justify-content-between align-items-center w-100"
                    >
                      <div>
                        <h2 className="acc-title">{item?.name}</h2>
                      </div>
                      <img
                        src={AccUp}
                        style={{
                          transform:
                            accOpen1 === i ? "rotate(180deg)" : "rotate(0deg)",
                          transition: "transform 150ms ease",
                        }}
                        alt=""
                      />
                    </div>
                    <div
                      className="acc-box"
                      style={{
                        height: accOpen1 === i ? "auto" : "0",
                        display: accOpen1 === i ? "flex" : "none",
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0 interview-text">Total Applications</p>
                        <p className="m-0 interview-text-number">
                          {item?.jobAppId?.length}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0 interview-text">Total Applicants</p>
                        <p className="m-0 interview-text-number">
                          {item?.jobApplicationCount}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0 interview-text">
                          Pending Application
                        </p>
                        <p className="m-0 interview-text-number">
                          {item?.statusCounts?.PENGING &&
                          item.statusCounts?.INTERVIEWING
                            ? Number(item?.statusCounts?.PENGING) +
                              Number(item.statusCounts?.INTERVIEWING)
                            : 0}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0 interview-text">Hire Candidate</p>
                        <p className="m-0 interview-text-number">
                          {item?.statusCounts?.HIRE
                            ? item?.statusCounts?.HIRE
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <img src={DummyInterview} className="img-fluid" alt="" />
                  <h6
                    style={{
                      color: "#B3B3B3",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "16px",
                      marginBottom: "20px",
                    }}
                  >
                    No interviewers added
                  </h6>
                  <button
                    className="new-job-add-btn"
                    style={{ display: "unset" }}
                    onClick={() => navigation("/dashboard/add-new-hire")}
                  >
                    <img src={addIcon} alt="" style={{ marginRight: "8px" }} />
                    Add Interviewers
                  </button>
                </div>
              )}
            </div>
            <div className="recruiter-calender">
              <div className="calender-">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h6 className="calender-box-title p-0">
                    My Calendar
                  </h6>
                  <button className="calender-btn">{getMonthName(new Date().getMonth())}</button>
                </div>
                {/* <h5 className="text-center">It's empty in here</h5> */}
                <div className="calender-slide">
                  <CalenderSlider data={getInterviewer} />
                </div>
              </div>
              <div className="upcoming">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h6 className="upcoming-box-title p-0">
                    Upcoming Interviewes
                  </h6>
                  {/* <button className="view-all-btn-recruiter">
                    Schedule Now
                  </button> */}
                </div>
                <div className="up-scroll">
                  <UpcomingCard data={getInterviewer} />
                </div>
              </div>
            </div>
          </div>
          <div className="feedback-container">
            {feedback?.length === 0 ? null : (
              <div className="feedback-box">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h6 className="feedback-box-title p-0">Feedback</h6>
                  <button className="view-all-btn-recruiter">View All</button>
                </div>
                <div className="feedbacks-box" style={{ gap: "12px" }}>
                  {feedback && feedback?.length > 0 ? (
                    <>
                      <FeedbackCard
                        title="HR Follow Up"
                        data={feedback}
                        value={"hrFollowUps"}
                      />
                      <FeedbackCard
                        title="HR Communication"
                        data={feedback}
                        value={"hrCommunication"}
                      />
                    </>
                  ) : (
                    <h5 className="text-center">It's empty in here</h5>
                  )}
                </div>
              </div>
            )}

            <div
              className="subscribe-box"
              style={{ width: feedback?.length === 0 ? "100%" : "50%" }}
            >
              <div
                className="d-flex w-100 align-items-center justify-content-between"
                style={{ padding: "0 20px", marginBottom: "16px" }}
              >
                <h6 className="title">Subscribe Now</h6>
                {/* <div className="d-flex flex-row" style={{ gap: "8px" }}>
                  <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="radio1"
                      name="date"
                    />
                    <label className="radio-title m-0" htmlFor="radio1">
                      <span
                        className="radio-button__custom"
                        style={{ top: "2px" }}
                      ></span>
                      Monthly
                    </label>
                  </div>
                  <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="radio2"
                      name="date"
                    />
                    <label className="radio-title m-0" htmlFor="radio2">
                      <span
                        className="radio-button__custom"
                        style={{ top: "2px" }}
                      ></span>
                      Yearly
                    </label>
                  </div>
                </div> */}
                <div className="d-flex flex-row" style={{ gap: "8px" }}>
                  <div className="radio-button">
                    <input
                      type="radio"
                      name="radio-group"
                      className="radio-button__input"
                      value={"Monthly"}
                      id="month1"
                      onChange={(e) => setRadio(e.target.value)}
                    />
                    <label className="radio-title m-0" htmlFor="month1">
                      <span
                        className="radio-button__custom"
                        style={{ top: "2px" }}
                      ></span>
                      Monthly
                    </label>
                  </div>
                  <div className="radio-button">
                    <input
                      type="radio"
                      name="radio-group"
                      className="radio-button__input"
                      value={"Yearly"}
                      id="month2"
                      onChange={(e) => setRadio(e.target.value)}
                    />
                    <label className="radio-title m-0" htmlFor="month2">
                      <span
                        className="radio-button__custom"
                        style={{ top: "2px" }}
                      ></span>
                      Yearly
                    </label>
                  </div>
                </div>
              </div>
              {feedback?.length === 0 ? (
                <div
                  style={{ marginLeft: "20px" }}
                  className="subscribe-slider"
                >
                  {radio === "Yearly" ? <YearlySlider /> : <MonthlySlider />}
                </div>
              ) : (
                <div
                  style={{ marginLeft: "20px" }}
                  className="subscribe-slider"
                >
                  {radio === "Yearly" ? (
                    <SubscribeYearly />
                  ) : (
                    <SubscribeMonthly />
                  )}
                </div>
              )}
            </div>
          </div>
          {draft?.length === 0 ? null : (
            <div className="recruiter-draft">
              <div className="d-flex justify-content-between w-100 align-items-center">
                <h6
                  style={{
                    color: "#000",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    margin: 0,
                  }}
                >
                  Draft
                </h6>
                <NavLink to={"/dashboard/draft"}>
                  <button className="view-all-btn-recruiter">View All</button>
                </NavLink>
              </div>
              <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
                {draftLoader ? (
                  <Loader />
                ) : draft && draft?.length > 0 ? (
                  draft?.map((draft, index) => (
                    <DraftCard key={index} draft={draft} />
                  ))
                ) : (
                  <h5 className="text-center">It's empty in here</h5>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="course-container">
          <h6 className="course-title">Recommended Courses</h6>
          <CourseSlider />
        </div>
        <div className="article-container">
          <h6 className="article-title">Articles and Blogs</h6>
          <ArticleSlider />
        </div>
      </div>
      <div className="dummy-footer">
        <div className="dummy-reach">
          <h6 className="dummy-footer-title">Reach Us</h6>
          <div>
            <Link className="dummy-link">support@recruitifie.com</Link>
            <Link
              className="dummy-link"
              to="https://www.thesocialsynapse.com/communities"
              target="_blank"
              rel="noopener noreferrer"
            >
              Community
            </Link>
          </div>
          <div className="text-center">
            <Link
              className="dummy-contact"
              target="_blank"
              rel="noopener noreferrer"
              to="https://wa.me/message/5OZBHPGGLH7TH1"
            >
              +91 6006989302
            </Link>
          </div>
        </div>
        <div>
          <div className="dummy-follow">
            <h6 className="dummy-footer-title">Follow Us</h6>
            <div className="d-flex" style={{ gap: "12px" }}>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.linkedin.com/company/recruitifie/"
              >
                <img src={facebook} alt="facebook" />
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.facebook.com/people/Recruitifie/100091479496126/"
              >
                <img src={Linked} alt="linked in" />
              </Link>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.instagram.com/Recruitifie"
              >
                <img src={instagram} alt="instagram" />
              </Link>
            </div>
          </div>
          <div className="text-right">
            <p className="copyright-text">
              ©2023 by Recruitifie. All Rights Reserved. Terms & Conditions
            </p>
          </div>
        </div>
      </div>
      <RecruiterAlertModel open={isModalOpen} onClose={hadlecloseModel} />
    </>
  );
}

export default RecruiterDashboard;
