import React, { useEffect, useRef, useState } from "react";
import Header from "../header/Header";
import closeBtn from "../../assets/blue-close-btn.svg";
import box from "../../assets/office-building.png";
import dotsBtn from "../../assets/chat-modal/dot-btn.svg";
import sendBtn from "../../assets/modal-send-btn.svg";
import emojiBtn from "../../assets/chat-modal/emoji-btn.svg";
import linkBtn from "../../assets/chat-modal/link-btn.svg";
import calenderBtn from "../../assets/chat-modal/calender-btn.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import moment from "moment";

function MobileChat() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [show, setShow] = useState(true);
  const handleShow = () => setShow(!show);
  const location = useLocation();
  const { data } = location?.state||{};

  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");
  const bottomRef = useRef(null);
  const { getAllChats, userChat, userInterviewerChat } = useMain();
  const userId = JSON.parse(localStorage.getItem("userData"))?._id;
  useEffect(() => {
    const interval = setInterval(() => {
      if (userId) {
        getCaht(userId);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getCaht = async (userId) => {
    const result = await userInterviewerChat(userId, data.interviewerId);
    if (result?.statusCode === 200) {
      setChat(result?.data?.message);
    }
  };

  const sendMessage = async () => {
    const data2 = {
      receiverId: data.interviewerId,
      msg: message,
      receiverName: "interviewer",
      chatpanel: "interviewer",
    };

    const result = await userChat(data2, userId);
    if (result?.statusCode === 200) {
      setMessage("");
      getCaht(userId);
    }
  };

  return (
    <>
      <Header />
      <div className="dashboard-box">
        <div className="mobile-show-hide mobile-recommended">
          <div className="chat-mobile-box">
          <button className="close-btn" style={{position: "absolute", right: "-6px", top: "-26px"}}>
            <img src={closeBtn} alt="" onClick={() => navigate(-1)} />
          </button>
            <div className="chat-mobile-card">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex">
                  <img src={box} alt="" style={{ paddingRight: "8px" }} />
                  <div>
                    <h6 className="chat-mobile-modal-title m-0">{data?.name}</h6>
                    <p className="chat-mobile-modal-text m-0">{data?.company}</p>
                  </div>
                </div>
                {/* <button className="dot-btn" onClick={handleShow}>
                  <img src={dotsBtn} alt="" />
                </button> */}
              </div>
              <div
                style={{ display: show ? "none" : "block" }}
                className="chat-dropdown"
              >
                <button>Delete Chat</button>
                <button>Report Chat</button>
                <button>Give feedback</button>
              </div>
              <div className="card-mobile-chat-border"></div>
              <div className="chat-mobile-container">
                <div className="text-center">
                  <h6 className="chat-mobile-date m-0">5th Aug, 2020</h6>
                </div>

                {chat?.length > 0 &&
                  chat?.map((item, index) => (
                    <div
                      key={index}
                      className="chat-modal-container"
                      style={{ height: "max-content" }}
                      ref={bottomRef}
                    >
                      {item?.receiver === "user" && (
                        <div
                          className="chat-message-left"
                          style={{ width: "max-content" }}
                        >
                          <p className="m-0">{item?.content}</p>
                          <p className="chat-message-time m-0">
                            {moment(item?.time).format("h:mm A")}
                          </p>
                        </div>
                      )}

                      {item?.receiver === "interviewer" && (
                        <div
                          className="chat-message-right"
                          style={{ width: "max-content" }}
                        >
                          <p className="m-0">{item?.content}</p>
                          <p className="chat-message-time m-0">
                            {moment(item?.time).format("h:mm A")}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="send-mobile-message-input-box">
          <div className="send-mobile-message-input">
            <textarea
              name="message"
              id=""
              cols="10"
              rows="1"
              className="send-message w-100"
              style={{ resize: "none" }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <button className="send-btn">
              <img src={sendBtn} alt="" onClick={() => sendMessage()} />
            </button>
          </div>
          {/* <button className="emoji-btn">
            <img src={emojiBtn} alt="" />
          </button> */}
          {/* <button className="emoji-btn">
            <img src={linkBtn} alt="" />
          </button>
          <button className="emoji-btn">
            <img src={calenderBtn} alt="" />
          </button> */}
        </div>
      </div>
    </>
  );
}

export default MobileChat;
