import { baseUrl, headers } from "../common/data";

export const createInterviewer = async (data, token) => {
  try {
    const response = await fetch(`${baseUrl}/interviewer/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getAllByCompany = async (token) => {
  try {
    const response = await fetch(`${baseUrl}/interviewer/getbycompany`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const singleInterviewer = async (id) => {
  try {
    const response = await fetch(`${baseUrl}/interviewer/getbyid/${id}`, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const deleteInterviewer = async (id) => {
  try {
    const response = await fetch(`${baseUrl}/interviewer/delete/${id}`, {
      method: "DELETE",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updateInterviewer = async (id, data) => {
  try {
    const response = await fetch(`${baseUrl}/interviewer/update/${id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const loginInterviewer = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/interviewer/login`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updateApplication = async (data, id) => {
  try {
    const response = await fetch(
      `${baseUrl}/interviewer/update/application/${id}`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updateInterviewStatus = async (data, id) => {
  try {
    const response = await fetch(
      `${baseUrl}/interviewer/update/interviewDetail/${id}`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ interviewDetail: data }),
      }
    );
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const assignInterviewer = async (interviewerId, Id, token) => {
  try {
    const response = await fetch(
      `${baseUrl}/job/multipleassign/interviewer/${interviewerId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ postId: Id }),
      }
    );
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};



export const getInterviewDataForUser = async (id) => {
  try {
    const response = await fetch(`${baseUrl}/interviewer/interview/${id}`, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

