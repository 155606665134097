import React, { useEffect, useRef, useState } from "react";
// import Header from "../components/header/Header";
import { useNavigate } from "react-router-dom";
import FormHeader from "../../components/header/FormHeader";
import downArrow from "../../assets/mobile/down-arrow.svg";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
import uploadBtn from "../../assets/calender/upload.svg";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";
import useOutsideClick from "../../pages/outsideClick/useOutsideClick";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import RecruiterHeader from "../header/RecruiterHeader";

const selectLanguage = [
  {
    id: 1,
    option: "GSTIN",
    selected: false,
  },
  {
    id: 2,
    option: "Company PAN Card",
    selected: false,
  },
  {
    id: 3,
    option: "LLPIN",
    selected: false,
  },
  {
    id: 4,
    option: "Company TAN Card",
    selected: false,
  },
  {
    id: 4,
    option: "MSME Certificate",
    selected: false,
  },
  {
    id: 4,
    option: "Proprietor Certificate",
    selected: false,
  },
];

function RecruiterHelp() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [government, setGovernment] = useState(false);
  const [dropHover1, setDropHover1] = useState(true);
  const [doctype, setDoctype] = useState(
    JSON.parse(localStorage.getItem("recruiterData"))?.verificationDoc?.doctype
      ? JSON.parse(localStorage.getItem("recruiterData"))?.verificationDoc
          ?.doctype
      : "Choose Document"
  );
  const [loader, setLoader] = useState(false);
  const [image, setimage] = useState("");
  // const navigate = useNavigate();
  const imageRef = useRef();
  const id = JSON.parse(localStorage.getItem("recruiterData"))?._id;
  const { UpdateVerification } = useMain();

  const InitialValues = {
    phoneNumber: "",
    image: null,
  };

  const [dropdown1, setDropdown1] = useState(false);
  const ref1 = useOutsideClick(handleOutsideClick1);

  function handleOnClick1(event) {
    event.preventDefault();
    if (dropdown1 === false) {
      setDropdown1(true);
    }
  }
  function handleOutsideClick1() {
    setDropdown1(false);
  }
  const [formData, setFormData] = useState(InitialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImage = (e, img) => {
    setFormData({ ...formData, [img]: e.target?.files[0] });
    setimage(e.target.files[0]);
  };

  async function GetOtp() {
    if (
      doctype &&
      doctype !== "Choose Document" &&
      image?.type === "application/pdf"
    ) {
      setLoader(true);
      const result = await UpdateVerification(doctype, image, id);
      if (result?.statusCode === 200) {
        setLoader(false);
        navigate("/recruiter-complete");
        toast.success(result.message);
        localStorage.setItem("recruiterData", JSON.stringify(result?.data));
      } else {
        toast.error("Something went wrong.");
        setLoader(false);
      }
    } else {
      toast.info("Please fill in all details.");
      setLoader(false);
    }
  }

  return (
    <>
      <AlertContainer/>
      <RecruiterHeader title="ID Verification" />
      {loader ? (
        <Loader />
      ) : (
        <section className="help-us-section">
          <div className="profile-build">
            <div className="help-us-line desktop-show-hide"></div>
            <h6 className="build-title">Help us verify your Company</h6>
            <p className="build-text">
              Build your profile for a seamless hiring experience
            </p>
            <div className="border-bottom desktop-show-hide"></div>
            <div className="resume-box">
              <h6 className="resume-title">Verification</h6>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div
                    ref={ref1}
                    className="dropdown"
                    onMouseEnter={() => setDropHover1(true)}
                    onMouseLeave={() => setDropHover1(false)}
                  >
                    <div
                      onClick={handleOnClick1}
                      style={{
                        outline: dropdown1 ? "2px solid #94A3B8" : "none",
                      }}
                      className="dropdown-btn"
                    >
                      {doctype}
                      <img
                        src={dropHover1 ? downArrowHover : downArrow}
                        alt=""
                      />
                    </div>
                    <div
                      className="dropdown-content"
                      style={{ display: dropdown1 ? "block" : "none" }}
                    >
                      {selectLanguage.map((element, index) => {
                        return (
                          <div
                            onClick={(e) => {
                              setDoctype(e.target.textContent);
                              setDropdown1(false);
                            }}
                            className="item"
                          >
                            {element.option}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <label for="file-upload" class="custom-file-upload">
                    {formData.image ? formData.image.name : "Upload Dcoument"}
                    <input
                      id="file-upload"
                      onChange={(e) => handleImage(e, "image")}
                      ref={imageRef}
                      className="custom-file-input"
                      type="file"
                      accept="application/pdf"
                    />
                    <img src={uploadBtn} alt="" />
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <button
                    className="get-otp-btn desktop-show-hide"
                    onClick={GetOtp}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="text-center desktop-show-hide">
                <button
                  style={{
                    color: "#64748B",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "24px",
                    border: "none",
                    backgroundColor: "transparent",
                    padding: "10px 16px",
                    marginBottom: "24px",
                  }}
                  onClick={() => navigate("/recruiter-complete")}
                >
                  Skip for now
                </button>
              </div>
            </div>
          </div>
          <div className="bottom-btn mobile-show-hide">
            <div className="d-flex flex-column">
              <button className="save-btn" onClick={GetOtp}>
                Submit
              </button>
              <button
                className="skip-btn"
                onClick={() => navigate("/recruiter-complete")}
              >
                Skip for now
              </button>
            </div>
          </div>
          {/* <div className="bottom-btn mobile-show-hide">
            <div className="d-flex flex-column">
              <button className="get-otp-btn" onClick={GetOtp}>
                Submit
              </button>
            </div>
          </div> */}
        </section>
      )}
    </>
  );
}

export default RecruiterHelp;
