export const baseUrl = `https://recrutify-backend.vercel.app/api`;
// export const baseUrl = `http://localhost:8080/api`;

export const headers = { "Content-Type": "application/json" };

export const recruiterHeader = {
  "Content-Type": "application/json",
  authorization: `Bearer ${localStorage.getItem("recruiterToken")}`,
};

// export const logoutRecruiter = () =>{
//     localStorage.removeItem("")
// }
