import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormHeader from "../components/header/FormHeader";
import "../App.css";
import { useMain } from "../hook/useMain";
import AadharContext from "../context/AadharContext";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import Header from "../components/header/Header";

function OtpPage() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("auth") === "true";
    if (!isAuthenticated) {
      navigate("/log-in");
      return;
    }
  }, []);
  const aadhar = useContext(AadharContext);
  const { referenceId, transactionId } = aadhar;

  const [otp, setOtp] = useState("");
  const { submitOtp } = useMain();
// otp is update now 
const handleOtp=()=>{
  
}
  const HandleOnSubmit = async () => {
    const userId = localStorage.getItem("userId");
    const data = {
      reference_id: referenceId,
      transaction_id: transactionId,
      otp: parseInt(otp),
      userId: userId,
    };
    const result = await submitOtp(data);
    console.log(result);
    if (result?.data?.status === "success") {
      const userLocal = JSON.parse(localStorage.getItem("userData"));
      if (userLocal && userLocal.hasOwnProperty("aadharDetails")) {
        userLocal.aadharDetails.isVerified = true;
        localStorage.setItem("userData", JSON.stringify(userLocal));
      }
      toast.success(result?.data?.message);
      navigate("/dashboard");
    } else {
      toast.error("Invalid otp");
    }
  };

  return (
    <>
      <AlertContainer />
      <Header title="OTP Verification" />
      <section className="help-us-section">
        <div className="profile-build">
          {/* <div className="help-us-line desktop-show-hide"></div> */}
          <h6 className="build-title">Enter One-Time-Passcode</h6>
          <p className="build-text">
            Your OTP has been sent on your registered mobile number
          </p>
          <div className="border-bottom desktop-show-hide"></div>
          <div className="resume-box">
            <div className="otp-container">
              <div className="d-flex">
                <div className="otp-content">
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="resume-input m-0"
                  />
                  {/* <button
                className="resend-btn"
                style={{ display: Resend ? "block" : "none" }}
              >
                Resend OTP
              </button> */}
                </div>
                <div className="otp-content mb-4 ms-3 desktop-show-hide">
                  <button className="get-otp-btn mb-1" onClick={HandleOnSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-btn mobile-show-hide">
          <div className="d-flex flex-column">
            <button className="get-otp-btn mb-1" onClick={HandleOnSubmit}>
              Submit
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default OtpPage;
