import { async } from "q";
import { baseUrl, headers } from "../common/data";

export const CarrerSignUp = async (data, token) => {
  try {
    const response = await fetch(`${baseUrl}/counselor/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const CarrerSignIn = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/counselor/login`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const CarrerResumeBuild = async (data, id) => {
  try {
    const response = await fetch(`${baseUrl}/counselor/update/${id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const GetAllCarrers = async () => {
  try {
    const response = await fetch(`${baseUrl}/counselor/all`, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const GetSingleCarrers = async (id) => {
  try {
    const response = await fetch(`${baseUrl}/counselor/getsingle/${id}`, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const BookCounsleores = async (data, id) => {
  try {
    const response = await fetch(`${baseUrl}/counselor/apply/${id}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};
