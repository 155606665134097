import React, { useContext, useEffect, useRef, useState } from "react";
import AllModalContext from "../context/allModal/AllModalContext";
import closeBtn from "../assets/blue-close-btn.svg";
import "./dashboard.css";
// import leftArrow from "../assets/left-arrow.svg";
import box from "../assets/office-building.png";
import all from "../assets/all.svg";
import sendBtn from "../assets/modal-send-btn.svg";
import emojiBtn from "../assets/chat-modal/emoji-btn.svg";
import linkBtn from "../assets/chat-modal/link-btn.svg";
import calenderBtn from "../assets/chat-modal/calender-btn.svg";
import { useMain } from "../hook/useMain";
import moment from "moment";

function ChatModal({ setToggle, interviewerId, name, company }) {
  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");
  const bottomRef = useRef(null);
  const { getAllChats, userChat, userInterviewerChat } = useMain();
  const userId = JSON.parse(localStorage.getItem("userData"))._id;
  useEffect(() => {
    const interval = setInterval(() => {
      if (userId) {
        getCaht(userId);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getCaht = async (userId) => {
    const result = await userInterviewerChat(userId, interviewerId);
    if (result?.statusCode === 200) {
      setChat(result?.data?.message);
    }
  };

  const sendMessage = async () => {
    const data = {
      receiverId: interviewerId,
      msg: message,
      receiverName: "interviewer",
      chatpanel: "interviewer",
    };

    const result = await userChat(data, userId);
    if (result?.statusCode === 200) {
      setMessage("");
      getCaht(userId);
    }
  };

  return (
    <>
      <div className="chat-modal">
        <div className="chat-filter-box">
          <button className="close-btn">
            <img src={closeBtn} alt="" onClick={() => setToggle("")} />
          </button>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <img src={box} alt="" style={{ paddingRight: "8px" }} />
              <div>
                <h6 className="chat-modal-title m-0">{name}</h6>
                <p className="chat-modal-text m-0">{company}</p>
              </div>
            </div>
            <div className="d-flex">
              {/* <button className="chat-modal-btn">Report</button>
              <button className="chat-modal-btn">Delete Chat</button>
              <button className="chat-modal-btn pe-0">
                <img src={all} alt="" />
              </button> */}
            </div>
          </div>
          <div className="chat-modal-border"></div>
          <div className="text-center">
            {/* <p className="m-0 chat-modal-date">5th Aug, 2020</p> */}
            <div
              className="mb-5"
              style={{
                height: "380px",
                overflow: "auto",
                scrollbarWidth: "none",
              }}
            >
              {chat?.length > 0 &&
                chat?.map((item, index) => (
                  <div
                    key={index}
                    className="chat-modal-container"
                    style={{ height: "max-content" }}
                    ref={bottomRef}
                  >
                    {item?.receiver === "user" && (
                      <div
                        className="chat-message-left"
                        style={{ width: "max-content" }}
                      >
                        <p className="m-0">{item?.content}</p>
                        <p className="chat-message-time m-0">
                          {moment(item?.time).format("h:mm A")}
                        </p>
                      </div>
                    )}

                    {item?.receiver === "interviewer" && (
                      <div
                        className="chat-message-right"
                        style={{ width: "max-content" }}
                      >
                        <p className="m-0">{item?.content}</p>
                        <p className="chat-message-time m-0">
                          {moment(item?.time).format("h:mm A")}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <div className="send-message-input-box">
              <div className="send-message-input">
                <textarea
                  name="message"
                  id=""
                  cols="10"
                  rows="1"
                  className="send-message w-100"
                  style={{ resize: "none" }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <button className="send-btn">
                  <img src={sendBtn} alt="" onClick={() => sendMessage()} />
                </button>
              </div>
              {/* <button className="emoji-btn">
                <img src={emojiBtn} alt="" />
              </button> */}
              <button className="emoji-btn">
                <img src={linkBtn} alt="" />
              </button>
              {/* <button className="emoji-btn">
                <img src={calenderBtn} alt="" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatModal;
