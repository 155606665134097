import React, { useCallback, useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import { useNavigate } from "react-router-dom";
// import SubHeader from "../subHeader/SubHeader";
// import RecruiterSubMenu from "../mobileMenu/RecruiterSubMenu";
// import leftArrow from "../../assets/recruiter/left-arrow.svg";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import CareerHeader from "../subHeader/CareerHeader";
import CareerSubMenu from "../mobileMenu/CareerSubMenu";
import "../../App.css";
import { useMain } from "../../hook/useMain";
import { async } from "q";
import Loader from "../loader/Loader";
import AllModalContext from "../../context/allModal/AllModalContext";
import CareerChat from "./chat/CareerChat";
import CareerHead from "../header/CareerHead";

function TableCard() {
  return (
    <>
      <div className="table-card">
        <h6 className="table-card-title">Pavan Gandhi</h6>
        <div className="d-flex justify-content-between">
          <div>
            <h6 className="m-0 table-card-email">pavanghandhi009@gmail.com</h6>
            <h6 className="m-0 table-card-email p-0">+9162882534678</h6>
          </div>
          <div>
            <p className="table-card-date m-0" style={{ width: "74px" }}>
              Scheduled Date & Time
            </p>
            <p className="table-card-date-text m-0">23/10/2023</p>
          </div>
        </div>
        <div className="table-card-border"></div>
        <p className="table-card-summary m-0">Summary:</p>
        {/* <p className="table-card-summary-text m-0">
          Candidate seems to have good potential for learning at a fast pace
          with so Candidate seems to have good potential for learning at a fast
          pace with soCandidate seems to have good potential for learning at a
          fast pace with...
        </p> */}
        <button className="view-profile-btn">View Profile</button>
      </div>
    </>
  );
}

function CareerTable() {
  const modal = useContext(AllModalContext);
  const { toggleCareer } = modal;
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const menudata = useContext(MobileContext);
  const [toggle, setToggle] = useState(false);

  const { handleRecruiterToggle, recruiterMenu } = menudata;
  const { GetSingleCarrers } = useMain();
  const navigate = useNavigate();

  useEffect(() => {
    const id = localStorage.getItem("career_id");

    if (id) {
      getData(id);
    }
  }, []);

  const getData = async (id) => {
    setLoader(true);
    const result = await GetSingleCarrers(id);
    if (result?.statusCode === 200) {
      setData(result?.data?.counsiluser);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  return (
    <>
      <CareerHead link="/career/sign-in" />
      <div className="desktop-show-hide">
        <CareerHeader />
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div
          className="recruiter-container desktop-show-hide"
          style={{ paddingRight: "0" }}
        >
          <div className="table-container">
            <div className="d-flex justify-content-between align-datas-center w-100">
              <div
                className="d-flex align-datas-center"
                // onClick={() => navigate(-1)}
              >
                {/* <img src={leftArrow} style={{ marginRight: "8px" }} alt="" /> */}
                <p className="m-0 table-title">Scheduled Appointments</p>
              </div>
              {/* <div style={{ display: "flex" }}>
              <select
                value={interviewerId}
                onChange={handleAssign}
                className="table-selector"
              >
                <option value={""}>Select Interviwer</option>
                {interview &&
                  interview.length > 0 &&
                  interviewer?.map((e, i) => (
                    <option key={e?._id} value={e?._id}>
                      {e?.name}
                    </option>
                  ))}
              </select>
              &nbsp;&nbsp;&nbsp;
              <button className="download-btn">
                Download
                <img src={download} style={{ marginLeft: "6px" }} alt="" />
              </button>
            </div> */}
            </div>
            <div className="table-overflow">
              <table className="table-body" style={{ width: "1800px" }}>
                <thead>
                  <tr>
                    <th style={{ width: "116px" }}>Candidate</th>
                    <th style={{ width: "100px" }}>Resume</th>
                    <th style={{ width: "180px" }}>Summary</th>
                    <th style={{ width: "180px" }}>Phone Number</th>
                    <th style={{ width: "170px" }}>Email ID</th>
                    <th style={{ width: "170px" }}>Institute name</th>
                    <th style={{ width: "170px" }}>Current State</th>
                    <th style={{ width: "200px" }}>Scheduled Date & Time</th>
                    <th style={{ width: "108px" }}>Notes</th>
                    <th style={{ width: "108px" }}>Chat</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 &&
                    data?.map((data, index) => (
                      <tr>
                        <td className="first-box">{data?.name}</td>
                        <td className="second-box">
                          <a href={data?.resumeUrl} target={"_blank"}>
                            Open
                          </a>
                        </td>
                        <td className="third-box">{data?.carrierdesc}</td>
                        <td className="four-box">{data?.phone}</td>
                        <td className="five-box">{data?.email}</td>
                        <td className="five-box">Institute name</td>
                        <td className="four-box">{data?.state}</td>
                        <td className="screen-box">
                          Candidate seems to have good potential for learning at
                          a fast pace with so...
                        </td>
                        <td className="ten-box">Notes is messing</td>
                        <td
                          className="ten-box"
                          onClick={() => setToggle(index)}
                          style={{ cursor: "pointer" }}
                        >
                          Chat
                        </td>
                        {toggle === index && (
                          <CareerChat
                            setToggle={setToggle}
                            userId={data?._id}
                            name={data?.name}
                            jobTitle={data?.jobTitle}
                          />
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className="table-container">
          <div className="d-flex justify-content-between align-datas-center w-100">
            <div
              className="d-flex align-datas-center"
              onClick={() => navigate(-1)}
            >
              <p className="m-0 table-title">Scheduled Appointments</p>
            </div>
          </div> */}
          {/* <div className="table-overflow">
            <table className="table-body">
              <thead>
                <tr>
                  <th style={{ width: "116px" }}>Candidate</th>
                  <th style={{ width: "100px" }}>Resume</th>
                  <th style={{ width: "180px" }}>Summary</th>
                  <th>Phone Number</th>
                  <th style={{ width: "170px" }}>Email ID</th>
                  <th style={{ width: "156px" }}>Institute name</th>
                  <th>Current State</th>
                  <th>Scheduled Date & Time</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody> */}
          {/* {data.map((data, index) => ( */}
          {/* <tr>
                  <td className="first-box">Pavan Gandhi</td>
                  <td className="second-box">Open</td>
                  <td className="third-box">
                    Candidate seems to have good potential for learning at a
                    fast pace with so...
                  </td>
                  <td className="four-box">+916882534678</td>
                  <td className="five-box">Pavansghandhi009@g...</td>
                  <td className="six-box">
                    Joseph’s Highend College, Mumbai, Ma...
                  </td>
                  <td className="seven-box">Bihar</td>
                  <td className="eight-box">23/10/2023</td>
                  <td className="eight-box">
                    Candidate seems to have good potential for learning at a
                    fast pace with so...
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
          {/* </div> */}
        </div>
      )}

      <div
        className="recruiter-container mobile-show-hide"
        style={{ marginTop: "80px" }}
      >
        <div className="d-flex flex-column" style={{ gap: "8px" }}>
          <TableCard />
          <TableCard />
          <TableCard />
          <TableCard />
          <TableCard />
          <TableCard />
        </div>
        <CareerSubMenu />
        <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
          <img
            src={top}
            alt=""
            style={{
              transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 150ms ease",
              height: "14px",
            }}
          />
          <img src={jobIcon} style={{ height: "24px" }} alt="" />
        </div>
      </div>
      {/* <CareerChat /> */}
    </>
  );
}

export default CareerTable;
