import React from "react";
import Slider from "react-slick";
import MonthlyTwo from "./MonthlyTwo";
import MonthlyThree from "./MonthlyThree";
import MonthlyFour from "./MonthlyFour";
import MonthlyOne from "./MonthlyOne";

function SubscribeMonthly() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    arrows: false,
    autoplay: false,
    centerMode: true,
    centerPadding: "18%",
    speed: 2000,
    // autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1850,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "13%",
        },
      },
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "8%",
        },
      },
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "5%",
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "22%",
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "22%",
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "7%",
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "9%",
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "7%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "11%",
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "8%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "5%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "8%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <MonthlyTwo />
        </div>
        <div>
          <MonthlyThree />
        </div>
        <div>
          <MonthlyFour />
        </div>
        <div>
          <MonthlyOne />
        </div>
        {/* <div>
          <MonthlyTwo />
        </div>
        <div>
          <MonthlyThree />
        </div>
        <div>
          <MonthlyFour />
        </div>
        <div>
          <MonthlyOne />
        </div> */}
      </Slider>
    </>
  );
}

export default SubscribeMonthly;
