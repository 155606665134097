import React from "react";
import Header from "../components/header/Header";
import { useNavigate } from "react-router-dom";
import '../App.css';
import LoginHeader from "../components/header/LoginHeader";

function SignUpSwitch() {
  const navigate = useNavigate();

  return (
    <>
      <LoginHeader btnName="Sign Up" link="/sign-in" />
      <section className="job-section">
        <div className="switch-modal">
          <div className="text-center">
            <h6 className="switch-modal-title">I am a</h6>
          </div>
          <button className="create-resume-btn" onClick={() => navigate('/sign-in')}>
            Job Seeker
          </button>
         <button className="upload-resume-btn"  onClick={() => navigate('/recruiter-sign-in')}>Recruiter
         </button>
        </div>
      </section>
    </>
  );
}

export default SignUpSwitch;
