import React, { useContext, useState } from "react";
import Header from "../header/Header";
import SubHeader from "../subHeader/SubHeader";
import './recruiter.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import box from "../../assets/calender/box.svg";
// import Upcoming from "../../assets/recruiter-dashboard/upcoming.svg";
// import RightArrow from "../../assets/recruiter-dashboard/right-arrow.svg";
// import LightStar from "../../assets/recruiter-dashboard/light-star.svg";
// import Star from "../../assets/recruiter-dashboard/star.svg";
import CourseSlider from "../courseSlider/CourseSlider";
import ArticleSlider from "../courseSlider/ArticlesSlider";
import RecruiterSubMenu from "../mobileMenu/RecruiterSubMenu";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import DummyInterview from "../../assets/recruiter/interview-dummy.svg";
import DummyUpcoming from "../../assets/recruiter/upcoming-dummy.svg";
import top from "../../assets/top-arrow.svg";
import addIcon from "../../recruiter-img/add-icon.svg";
import DummyMobileHome from "./DummyMobileHome";
import { Link } from "react-router-dom";
import facebook from "../../assets/search-btn/facebook.svg";
import Linked from "../../assets/search-btn/linked.svg";
import instagram from "../../assets/search-btn/instagram.svg";
import YearlySlider from "../subscribeSlider/YearlySlider";
import MonthlySlider from "../subscribeSlider/MonthlySlider";
import RecruiterHeader from "../header/RecruiterHeader";

ChartJS.register(ArcElement, Tooltip, Legend);

// function UpcomingCard() {
//   return (
//     <>
//       <div className="upcoming-card">
//         <div className="d-flex justify-content-between w-100">
//           <div className="d-flex align-items-start" style={{ gap: "8px" }}>
//             <img src={Upcoming} alt="" />
//             <div className="d-flex flex-column" style={{ gap: "2px" }}>
//               <h6 className="upcoming-title m-0">Harry James</h6>
//               <p className="upcoming-category m-0">Human Resource</p>
//               <p className="upcoming-date m-0">12th Oct, 2023 | 12:30pm</p>
//             </div>
//           </div>
//           <div className="d-flex flex-column justify-content-between">
//             <img src={RightArrow} alt="" />
//             <p className="m-0 upcoming-date"> By Me</p>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// function DraftCard({ title }) {
//   return (
//     <>
//       <div className="draft-card">
//         <div className="d-flex align-items-start">
//           <img src={box} alt="" style={{ marginRight: "8px" }} />
//           <div className="w-100">
//             <h2 className="draft-card-title">{title}</h2>
//             <div className="d-flex justify-content-between align-items-center">
//               <p className="draft-card-text">product</p>
//               <p className="draft-days">2 days ago</p>
//             </div>
//           </div>
//         </div>
//         <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
//         <ul className="draft-list">
//           <li>Full-time · Entry level</li>
//           <li>11-50 employees · IT Services and IT Consulting</li>
//           <li>See how you compare to 843 applicants.</li>
//           <li>Skills: Wireframing, User Experience (UX), +8 more</li>
//           <li>Skills: Wireframing, User Experience (UX), +8 more</li>
//         </ul>
//         <div className="draft-edit-btn">Edit</div>
//       </div>
//     </>
//   );
// }

// function FeedbackCard({ title }) {
//   return (
//     <>
//       <div className="recruiter-feedback-card">
//         <h2 className="title">{title}</h2>
//         <div className="d-flex w-100 align-items-center justify-content-between">
//           <div className="d-flex flex-row" style={{ gap: "8px" }}>
//             <img src={LightStar} alt="" />
//             <img src={LightStar} alt="" />
//             <img src={LightStar} alt="" />
//             <img src={LightStar} alt="" />
//             <img src={Star} alt="" />
//           </div>
//           <h3 className="rating">4/5</h3>
//         </div>
//         <div className="center-border"></div>
//         <div className="rating-item">
//           <div className="d-flex align-items-center">
//             <img src={LightStar} style={{ height: "16px" }} alt="" />
//             <h6 className="m-0 rating-num">4/5</h6>
//           </div>
//           <h6 className="m-0 rating-name">Hardik Pandya</h6>
//           <p className="m-0 rating-text">(UX/UI Designer)</p>
//         </div>
//         <p className="m-0 rating-comment">Any comment by the candidate</p>
//         <div className="center-border"></div>
//         <div className="rating-item">
//           <div className="d-flex align-items-center">
//             <img src={LightStar} style={{ height: "16px" }} alt="" />
//             <h6 className="m-0 rating-num">3/5</h6>
//           </div>
//           <h6 className="m-0 rating-name">Hardik Pandya</h6>
//           {/* <p className="m-0 rating-text">(UX/UI Designer)</p> */}
//         </div>
//         <p className="m-0 rating-comment">Any comment by the candidate</p>
//         <div className="center-border"></div>
//         <div className="rating-item">
//           <div className="d-flex align-items-center">
//             <img src={LightStar} style={{ height: "16px" }} alt="" />
//             <h6 className="m-0 rating-num">3/5</h6>
//           </div>
//           <h6 className="m-0 rating-name">Hardik Pandya</h6>
//           {/* <p className="m-0 rating-text">(UX/UI Designer)</p> */}
//         </div>
//         <p className="m-0 rating-comment">Any comment by the candidate</p>
//         <div className="center-border"></div>
//         <div className="rating-item">
//           <div className="d-flex align-items-center">
//             <img src={LightStar} style={{ height: "16px" }} alt="" />
//             <h6 className="m-0 rating-num">3/5</h6>
//           </div>
//           <h6 className="m-0 rating-name">Hardik Pandya</h6>
//           {/* <p className="m-0 rating-text">(UX/UI Designer)</p> */}
//         </div>
//         <p className="m-0 rating-comment">Any comment by the candidate</p>
//       </div>
//     </>
//   );
// }

function DummyDashboard() {
  const menuItem = useContext(MobileContext);
  const { handleRecruiterToggle, recruiterMenu } = menuItem;

  const [radio, setRadio] = useState("Yearly");

  return (
    <>
      <RecruiterHeader />
      <div className="mobile-show-hide">
        <DummyMobileHome />
        <RecruiterSubMenu />
        <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
          <img
            src={top}
            alt=""
            style={{
              transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 150ms ease",
              height: "14px",
            }}
          />
          <img src={jobIcon} style={{ height: "24px" }} alt="" />
        </div>
      </div>
      <div className="desktop-show-hide">
        <SubHeader />
        <div className="recruiter-dashboard">
          <div className="recruiter-dashboard-container">
            <div className="chart-container">
              <div className="chart-box">
                <h6 className="chart-title">Analysis on Applications</h6>
                <div className="d-flex align-items-center">
                  <div
                    className="pie-chart"
                    style={{
                      background: `#FFF`,
                    }}
                  >
                    <div className="chart-total">0</div>
                  </div>
                  <div className="chart-text" style={{ marginLeft: "32px" }}>
                    <div className="total-box">
                      <p className="box"></p> <p> Total Applications</p>
                    </div>
                    <div className="open-box">
                      <p className="box" style={{ background: "#FFF" }}></p>{" "}
                      <p>Open Applications</p>
                    </div>
                    <div className="closed-box">
                      <p className="box" style={{ background: "#FFF" }}></p>{" "}
                      <p>Closed Applications</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-box">
                <h6 className="chart-title">Analysis on Applicants</h6>
                <div className="d-flex align-items-center">
                  <div
                    className="pie-chart-2"
                    style={{
                      background: `#FFF`,
                    }}
                  >
                    <div className="chart-total">0</div>
                  </div>
                  <div className="chart-text" style={{ marginLeft: "32px" }}>
                    <div className="total-box">
                      <p className="box"></p> <p> Total Applications</p>
                    </div>
                    <div className="open-box">
                      <p className="box" style={{ background: "#FFF" }}></p>{" "}
                      <p>In-progress Applicants</p>
                    </div>
                    <div className="closed-box">
                      <p className="box" style={{ background: "#FFF" }}></p>{" "}
                      <p>Rejected Applicants</p>
                    </div>
                    <div className="selected-box">
                      <p className="box" style={{ background: "#FFF" }}></p>{" "}
                      <p>Selected Applicants</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="interviewers">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <h6 className="interviewers-title">Interviewers</h6>
                <h6 className="interviewers-title">0</h6>
              </div>
              <div
                style={{ borderBottom: "1px solid #E2E8F0", width: "100%" }}
              ></div>
              <div className="text-center">
                <img src={DummyInterview} className="img-fluid" alt="" />
                <h6
                  style={{
                    color: "#B3B3B3",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "16px",
                    marginBottom: "20px",
                  }}
                >
                  No interviewers added
                </h6>
                <button
                  className="new-job-add-btn"
                  style={{ display: "unset" }}
                >
                  <img src={addIcon} alt="" style={{ marginRight: "8px" }} />
                  Add Interviewers
                </button>
              </div>
            </div>
            <div className="recruiter-calender">
              <div className="calender-">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h6 className="calender-box-title p-0">My July Calendar</h6>
                  <button className="calender-btn">Month</button>
                </div>
              </div>
              <div className="upcoming">
                <div className="d-flex align-items-center w-100 justify-content-between">
                  <h6 className="upcoming-box-title p-0">
                    Upcoming Interviewes
                  </h6>
                  <button className="view-all-btn-recruiter">
                    Schedule Now
                  </button>
                </div>
                <div className="text-center">
                  <img src={DummyUpcoming} alt="" className="img-fluid" />
                  <h6
                    style={{
                      color: "#B3B3B3",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "16px",
                      marginBottom: "20px",
                    }}
                  >
                    No interviews scheduled
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="feedback-container">
            <div
              className="subscribe-box"
              style={{ width: "100%", paddingBottom: "40px" }}
            >
              <div
                className="d-flex w-100 align-items-center justify-content-between"
                style={{ padding: "0 20px", marginBottom: "16px" }}
              >
                <h6 className="title">Subscribe Now</h6>
                <div className="d-flex flex-row" style={{ gap: "8px" }}>
                  <div className="radio-button">
                    <input
                      type="radio"
                      name="radio-group"
                      className="radio-button__input"
                      value={"Monthly"}
                      id="month1"
                      onChange={(e) => setRadio(e.target.value)}
                    />
                    <label className="radio-title m-0" htmlFor="month1">
                      <span
                        className="radio-button__custom"
                        style={{ top: "2px" }}
                      ></span>
                      Monthly
                    </label>
                  </div>
                  <div className="radio-button">
                    <input
                      type="radio"
                      name="radio-group"
                      className="radio-button__input"
                      value={"Yearly"}
                      id="month2"
                      onChange={(e) => setRadio(e.target.value)}
                    />
                    <label className="radio-title m-0" htmlFor="month2">
                      <span
                        className="radio-button__custom"
                        style={{ top: "2px" }}
                      ></span>
                      Yearly
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "20px" }} className="subscribe-slider">
                {radio === "Yearly" ? <YearlySlider /> : <MonthlySlider />}
              </div>
            </div>
          </div>
        </div>
        <div className="course-container">
          <h6 className="course-title">Recommended Courses</h6>
          <CourseSlider />
        </div>
        <div className="article-container">
          <h6 className="article-title">Articles and Blogs</h6>
          <ArticleSlider />
        </div>
      </div>
      <div className="dummy-footer">
        <div className="dummy-reach">
          {" "}
          <h6 className="dummy-footer-title">Reach Us</h6>{" "}
          <div>
            <Link className="dummy-link">support@recruitifie</Link>
            <Link className="dummy-contact">+91 6006989302</Link>
          </div>
        </div>
        <div>
          <div className="dummy-follow">
            <h6 className="dummy-footer-title">Follow Us</h6>
            <div className="d-flex" style={{ gap: "12px" }}>
              <Link>
                <img src={facebook} alt="facebook" />
              </Link>
              <Link>
                <img src={Linked} alt="linked in" />
              </Link>
              <Link>
                <img src={instagram} alt="instagram" />
              </Link>
            </div>
          </div>
          <div className="text-right">
            <p className="copyright-text">
              ©2023 by Recruitifie. All Rights Reserved. Terms & Conditions
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default DummyDashboard;
