import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "react-tooltip/dist/react-tooltip.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "font-awesome/css/font-awesome.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RouterProvider } from "react-router-dom";
import router from "./config/router";
import SidebarState from "./context/SidebarState";
import MobileState from "./context/mobile/MobileState";
import AllModalState from "./context/allModal/AllModalState";
import CalenderState from "./context/calender/CalenderState";
import "react-calendar/dist/Calendar.css";
import "react-circular-progressbar/dist/styles.css";
import MainState from "./contextApi/MainState";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AadharState from "./context/AadharState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <GoogleOAuthProvider clientId="550814863959-dktt4vs2opva4h2roep053p58kt0fbu7.apps.googleusercontent.com">
      <AllModalState>
        <MobileState>
          <SidebarState>
            <CalenderState>
              <MainState>
                <AadharState>
                  <RouterProvider router={router} />
                </AadharState>
              </MainState>
            </CalenderState>
          </SidebarState>
        </MobileState>
      </AllModalState>
    </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
