import React from "react";
import Header from "../../components/header/Header";
import "../dashboard.css";
import leftArrow from "../../assets/left-arrow.svg"
import leftImg from "../../assets/talent/left-arrow.svg";
import { useNavigate } from "react-router-dom";

function FilterMobilePage() {
    const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="mobile-show-hide mobile-filter">
        <div className="d-flex align-items-center apply-mobile-text" onClick={() => navigate(-1)}>
          <img src={leftImg} alt="" /> Apply Filters
        </div>
        <div className="d-flex flex-column" style={{ gap: "4px" }}>
          <div className="mobile-job-box">
            <button className="sort-btn" style={{ marginBottom: "12px" }}>
              Sort by
            </button>
            <div className="row">
              <div className="col-6">
                <div className="radio-button">
                  <input
                    type="radio"
                    className="radio-button__input"
                    id="sort1"
                    value="Most Recent"
                    name="Sort by"
                  />
                  <label className="radio-button__label" htmlFor="sort1">
                    <span className="radio-button__custom"></span>
                    Most Recent
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="radio-button">
                  <input
                    type="radio"
                    className="radio-button__input"
                    id="sort2"
                    value="Most Relevant"
                    name="Sort by"
                  />
                  <label className="radio-button__label" htmlFor="sort2">
                    <span className="radio-button__custom"></span>
                    Most Relevant
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-job-box">
            <button className="sort-btn" style={{ marginBottom: "12px" }}>
              Date posted
            </button>
            <div className="row">
              <div className="col-6">
                <div className="radio-button">
                  <input
                    type="radio"
                    className="radio-button__input"
                    id="date1"
                    name="Date posted"
                  />
                  <label className="radio-button__label" htmlFor="date1">
                    <span className="radio-button__custom"></span>
                    Any time
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="radio-button">
                  <input
                    type="radio"
                    className="radio-button__input"
                    id="date2"
                    name="Date posted"
                  />
                  <label className="radio-button__label" htmlFor="date2">
                    <span className="radio-button__custom"></span>
                    Past week
                  </label>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "12px" }}>
              <div className="col-6">
                <div className="radio-button">
                  <input
                    type="radio"
                    className="radio-button__input"
                    id="date-3"
                    name="Date posted"
                  />
                  <label className="radio-button__label" htmlFor="date-3">
                    <span className="radio-button__custom"></span>
                    Most Relevant
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="radio-button">
                  <input
                    type="radio"
                    className="radio-button__input"
                    id="date-4"
                    name="Date posted"
                  />
                  <label className="radio-button__label" htmlFor="date-4">
                    <span className="radio-button__custom"></span>
                    Most Relevant
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-job-box">
            <button className="sort-btn" style={{ marginBottom: "12px" }}>
              Experience level
            </button>
            <div className="row">
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Experience"
                    className="checkbox-button-input"
                    id="experience1"
                  />
                  <label
                    className="checkbox-button__label"
                    htmlFor="experience1"
                  >
                    <span className="checkbox-button__custom"></span>
                    Internship
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Experience"
                    className="checkbox-button-input"
                    id="experience2"
                  />
                  <label
                    className="checkbox-button__label"
                    htmlFor="experience2"
                  >
                    <span className="checkbox-button__custom"></span>
                    Entry level
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Experience"
                    className="checkbox-button-input"
                    id="experience3"
                  />
                  <label
                    className="checkbox-button__label"
                    htmlFor="experience3"
                  >
                    <span className="checkbox-button__custom"></span>
                    Associate
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Experience"
                    className="checkbox-button-input"
                    id="experience4"
                  />
                  <label
                    className="checkbox-button__label"
                    htmlFor="experience4"
                  >
                    <span className="checkbox-button__custom"></span>
                    Mid-Senior level
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Experience"
                    className="checkbox-button-input"
                    id="experience5"
                  />
                  <label
                    className="checkbox-button__label"
                    htmlFor="experience5"
                  >
                    <span className="checkbox-button__custom"></span>
                    Director
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    className="checkbox-button-input"
                    id="experience6"
                    name="Experience"
                  />
                  <label
                    className="checkbox-button__label"
                    htmlFor="experience6"
                  >
                    <span className="checkbox-button__custom"></span>
                    Executive
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-job-box">
            <button className="sort-btn" style={{ marginBottom: "12px" }}>
              Job type
            </button>
            <div className="row">
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Job"
                    className="checkbox-button-input"
                    id="job1"
                  />
                  <label className="checkbox-button__label" htmlFor="job1">
                    <span className="checkbox-button__custom"></span>
                    Full-time
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Job"
                    className="checkbox-button-input"
                    id="job2"
                  />
                  <label className="checkbox-button__label" htmlFor="job2">
                    <span className="checkbox-button__custom"></span>
                    Part-time
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Job"
                    className="checkbox-button-input"
                    id="job3"
                  />
                  <label className="checkbox-button__label" htmlFor="job3">
                    <span className="checkbox-button__custom"></span>
                    Contract
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Job"
                    className="checkbox-button-input"
                    id="job4"
                  />
                  <label className="checkbox-button__label" htmlFor="job4">
                    <span className="checkbox-button__custom"></span>
                    Internship
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-job-box">
            <button className="sort-btn" style={{ marginBottom: "12px" }}>
              On-site/remote
            </button>
            <div className="row">
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Remote"
                    className="checkbox-button-input"
                    id="remote1"
                  />
                  <label className="checkbox-button__label" htmlFor="remote1">
                    <span className="checkbox-button__custom"></span>
                    On-site
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Remote"
                    className="checkbox-button-input"
                    id="remote2"
                  />
                  <label className="checkbox-button__label" htmlFor="remote2">
                    <span className="checkbox-button__custom"></span>
                    Remote
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Remote"
                    className="checkbox-button-input"
                    id="remote3"
                  />
                  <label className="checkbox-button__label" htmlFor="remote3">
                    <span className="checkbox-button__custom"></span>
                    Hybrid
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-job-box">
          <button className="sort-btn" style={{ marginBottom: "12px" }}>
              Industry
            </button>
            <div className="row">
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Industry"
                    className="checkbox-button-input"
                    id="industry1"
                  />
                  <label className="checkbox-button__label" htmlFor="industry1">
                    <span className="checkbox-button__custom"></span>
                    Think tanks
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Industry"
                    className="checkbox-button-input"
                    id="industry2"
                  />
                  <label className="checkbox-button__label" htmlFor="industry2">
                    <span className="checkbox-button__custom"></span>
                    NGO
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Industry"
                    className="checkbox-button-input"
                    id="industry3"
                  />
                  <label className="checkbox-button__label" htmlFor="industry3">
                    <span className="checkbox-button__custom"></span>
                    Fintech
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="checkbox-button d-flex align-items-center">
                  <input
                    type="radio"
                    name="Industry"
                    className="checkbox-button-input"
                    id="industry4"
                  />
                  <label className="checkbox-button__label" htmlFor="industry4">
                    <span className="checkbox-button__custom"></span>
                    IT
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button className="apply-btn">Apply <img src={leftArrow} alt="" /></button>
        </div>
        {/* <MobileMenu />
        <div className="mobile-menu-btn" onClick={handleMobileToggle}>
          <img
            src={top}
            alt=""
            style={{
              transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 150ms ease",
              height: "14px",
            }}
          />
          <img src={jobIcon} style={{ height: "24px" }} alt="" />
        </div> */}
      </div>
    </>
  );
}

export default FilterMobilePage;
