import React, { useEffect, useState } from "react";
// import Header from "../components/header/Header";
import info from "../../assets/information.svg";
import plush from "../../assets/plush.svg";
import Delete from "../../assets/delete.svg";
import { useDropzone } from "react-dropzone";
import close from "../../assets/x.svg";
import done from "../../assets/done.svg";
import { useNavigate } from "react-router-dom";
import number1 from "../../assets/mobile/number1.svg";
import number2 from "../../assets/mobile/number2.svg";
import number3 from "../../assets/mobile/number3.svg";
import number4 from "../../assets/mobile/number4.svg";
import downArrow from "../../assets/mobile/down-arrow.svg";
import FormHeader from "../../components/header/FormHeader";
import CalenderImg from "../../assets/calender/calender.svg";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
import closeBtn from "../../assets/close-bt.svg";
import { State } from "country-state-city";
import moment from "moment";
import Calendar from "react-calendar";
import Fuse from "fuse.js";
import { async } from "q";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";

import "../../App.css";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import {toast} from 'react-toastify'
import Header from "../header/Header";
const selectItem = [
  {
    id: 1,
    option: "Employed",
    selected: false,
  },
  {
    id: 2,
    option: "Serving Notice Period",
    selected: false,
  },
  {
    id: 3,
    option: "Unemployed",
    selected: false,
  },
  {
    id: 4,
    option: "Working Part-time",
    selected: false,
  },
  {
    id: 5,
    option: "Student",
    selected: false,
  },
];
const selectItemTwo = [
  {
    id: 1,
    option: "Married",
    selected: false,
  },
  {
    id: 2,
    option: "Single",
    selected: false,
  },
  {
    id: 3,
    option: "Divorced",
    selected: false,
  },
];

function CareerResume() {
  const [dateBirth, setDateBirth] = useState("");
  const [dateModal, setDateModal] = useState(false);
  var birthDate = moment(dateBirth).format("DD/MM/YYYY");

  function handleDateOpen() {
    setDateModal(!dateModal);
  }

  // const [year, setYear] = useState("");
  const [dropdown1, setDropdown1] = useState(true);
  const [dropdown2, setDropdown2] = useState(true);
  const [dropdown3, setDropdown3] = useState(true);
  const [dropHover1, setDropHover1] = useState(false);
  const [dropHover2, setDropHover2] = useState(false);
  const [dropHover3, setDropHover3] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [search, setSearch] = useState(false);
  const [selected, setIsSelected] = useState("");
  const [filterText, setFilterText] = useState("");
  const [loader, setLoader] = useState(false);

  const InitialValues = {
    name: "",
    phoneNumber: "",
    subcategories: [],
    image: null,
  };
  const [formData, setFormData] = useState(InitialValues);
  const { CarrerResumeBuild } = useMain();
  const carrerData = JSON.parse(localStorage.getItem("career_data"));
  const id = localStorage.getItem("career_id");

  const [certificates, setCertificates] = useState([
    { universityName: "", price: "" },
  ]);
  function AddCertificates() {
    const updatedList = [...certificates, { universityName: "", price: "" }];
    setCertificates(updatedList);
  }
  const allState = State.getStatesOfCountry("IN", "TG").map((city) => ({
    value: city.name,
    displayValue: city.name,
  }));
  const navigate = useNavigate();
  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    multiple: false,
    maxSize: 10 * 1024 * 1024, // 10MB in bytes
    onDrop: async (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
      // const result = await UpdateResume(acceptedFiles[0], id);
      // if (result?.statusCode === 200) {
      //   alert(result?.message);
      //   // setSelectedFile(null);
      // } else {
      //   alert("Somthig went wrong");
      // }
    },
  });

  function removeFile() {
    setSelectedFile(null);
  }

  function Dropdown1() {
    setDropdown2(false);
    setDropdown3(false);
    setDropdown1(!dropdown1);
  }
  function Dropdown2() {
    setDropdown1(false);
    setDropdown3(false);
    setDropdown2(!dropdown2);
  }
  function Dropdown3() {
    setDropdown2(false);
    setDropdown1(false);
    setDropdown3(!dropdown3);
  }

  const [state, setState] = useState(false);
  const [working, setWorking] = useState(false);
  const [mariatial, setMariatial] = useState(false);
  const [selectedState, setSelectedState] = useState("Current State");
  const [selectedWorking, setSelectedWorking] = useState("Working status");
  const [selectedMariatial, setSelectedMariatial] =
    useState("Mariatial Status");

  function DeleteCertificates(id) {
    const certificatesList = Object.assign([], certificates);
    let filteredArray = certificatesList.filter((item, index) => index !== id);
    setCertificates(filteredArray);
  }

  const fuse = new Fuse(allState, {
    includeScore: true,
    keys: ["displayValue"],
  });

  const pattern = filterText;
  const result = fuse.search(pattern);
  useEffect(() => {
    if (selected.trim() !== "") {
      if (!formData?.subcategories?.includes(selected))
        setFormData({
          ...formData,
          subcategories: [...formData?.subcategories, selected],
        });
    }
  }, [selected]);

  const [academicData, setAcademicData] = useState({
    name: "",
    phone: "",
    email: "",
    dob: "",
    city: "",
    state: "",
    pincode: "",
    workingStatus: "Working Status",
    maritalStatus: "Marital Status",
    carrierdesc: "",
    weblink: "",
    linkedinLink: "",
    portfolioURL: "",
  });

  async function handleOnSave() {
    setLoader(true);
    const result = await CarrerResumeBuild(academicData, id);
    if (result?.statusCode === 200) {
      toast.success(result?.message);
      localStorage.setItem("carrer_data", JSON.stringify(result?.data));
      setLoader(false);
      navigate("/career/complete");
    } else {
      toast.error("Something went wrong.");
      setLoader(false);
    }
  }

  return (
    <>
    <AlertContainer />
      <Header title="Build Profile" />
      {loader ? (
        <Loader />
      ) : (
        <section className="create-resume">
          <div className="mobile-show-hide">
            <div className="mobile-line-box">
              <div className="mobile-line"></div>
              <div className="d-flex justify-content-around number-line">
                <img src={number1} alt="" />
                <img src={number2} alt="" />
                <img src={number3} alt="" />
                <img src={number4} alt="" />
              </div>
            </div>
            <div className="build-box-mobile">
              <h6 className="build-title">Build your profile</h6>
              <div className="text-center send-resume-btn" {...getRootProps()}>
                <input {...getInputProps()} />
                {selectedFile === null ? (
                  <label htmlFor="file" className="auto-button">
                    Auto upload Resume
                  </label>
                ) : (
                  <button className="done-btn">
                    Uploaded
                    <img className="ps-2" src={done} alt="" />
                  </button>
                )}
                {selectedFile === null ? (
                  <p className="information">
                    <img src={info} alt="" /> Upload max 10MB .pdf file only
                  </p>
                ) : (
                  <p className="file-name">
                    {selectedFile.name}{" "}
                    <button onClick={removeFile} className="close-btn">
                      <img src={close} alt="" />
                    </button>
                  </p>
                )}
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown1}
              >
                <p className="personal-title m-0">Personal Details</p>
                <img src={downArrow} alt="" />
              </div>
              <div
                className="resume-box"
                style={
                  dropdown1 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="resume-input"
                      name="name"
                      value={
                        academicData?.name
                          ? academicData.name
                          : carrerData?.name
                      }
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="number"
                      placeholder="Phone number"
                      className="resume-input"
                      name="phone"
                      value={
                        academicData?.phone
                          ? academicData?.phone
                          : carrerData?.phone
                      }
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      className="resume-input"
                      value={
                        academicData?.email
                          ? academicData?.email
                          : carrerData?.email
                      }
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="calender-date-input"
                      onClick={handleDateOpen}
                    >
                      <input
                        placeholder="Enter DOB"
                        className="input-date"
                        type="text"
                        value={academicData?.dob}
                        name="dob"
                      />
                      <img
                        src={CalenderImg}
                        className="input-calender"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Current city"
                      className="resume-input"
                      name="city"
                      value={academicData.city}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="dropdown"
                      onMouseEnter={() => setDropHover1(true)}
                      onMouseLeave={() => setDropHover1(false)}
                    >
                      <div
                        style={{
                          outline: isActive ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                        onClick={() => {
                          setIsActive(!isActive);
                        }}
                      >
                        {search ? (
                          selected
                        ) : (
                          <input
                            type="text"
                            placeholder="Current State"
                            name="state"
                            className="search-input-box"
                            onChange={(e) => {
                              setAcademicData({
                                ...academicData,
                                [e.target.name]: e.target.value,
                              });
                              setSearch(false);
                            }}
                            value={academicData.state}
                          />
                        )}
                        <img
                          src={dropHover1 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: isActive ? "block" : "none" }}
                      >
                        {search
                          ? allState.map((element, index) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    setIsSelected(e.target.textContent);
                                    setSearch(!search);
                                    setIsActive(!isActive);
                                  }}
                                  className="item"
                                >
                                  {element.displayValue}
                                </div>
                              );
                            })
                          : result.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={(e) => {
                                    setIsSelected(e.target.textContent);
                                    setSearch(!search);
                                    setIsActive(!isActive);
                                  }}
                                  className="item"
                                >
                                  {element.item.displayValue}
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Pin code"
                      className="resume-input"
                      name="pincode"
                      value={academicData.pincode}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="dropdown"
                      onMouseEnter={() => setDropHover2(true)}
                      onMouseLeave={() => setDropHover2(false)}
                    >
                      <div
                        onClick={(e) => {
                          setWorking(!working);
                        }}
                        style={{
                          outline: working ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData.workingStatus}
                        <img
                          src={dropHover2 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: working ? "block" : "none" }}
                      >
                        {selectItem.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["workingStatus"]: e.target.textContent,
                                });
                                setWorking(!working);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="dropdown"
                      onMouseEnter={() => setDropHover3(true)}
                      onMouseLeave={() => setDropHover3(false)}
                    >
                      <div
                        onClick={(e) => {
                          setMariatial(!mariatial);
                        }}
                        style={{
                          outline: mariatial ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData?.maritalStatus}
                        <img
                          src={dropHover3 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: mariatial ? "block" : "none" }}
                      >
                        {selectItemTwo.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["maritalStatus"]: e.target.textContent,
                                });
                                setMariatial(!mariatial);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown2}
              >
                <p className="personal-title m-0">summarize your career</p>
                <img src={downArrow} alt="" />
              </div>
              <div
                className="resume-box"
                style={
                  dropdown2 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                <div className="row">
                  <div className="col-12 ">
                    <textarea
                      name="carrierdesc"
                      id=""
                      style={{ resize: "none" }}
                      placeholder="Write about yourself and the highlights of your journey "
                      className="resume-input m-0"
                      cols="30"
                      rows="10"
                      value={academicData?.carrierdesc}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown3}
              >
                <p className="personal-title m-0">Add Links</p>
                <img src={downArrow} alt="" />
              </div>
              <div
                className="resume-box"
                style={
                  dropdown3 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                <div className="resume-box">
                  {certificates.map((element, index) => {
                    return (
                      <>
                        <div className="row" key={index}>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <input
                              type="text"
                              placeholder="Website URL"
                              className="resume-input"
                              name="weblink"
                              value={academicData?.weblink}
                              onChange={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <input
                              type="text"
                              placeholder="Linkedin URL"
                              className="resume-input"
                              name="linkedinLink"
                              value={academicData?.linkedinLink}
                              onChange={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <input
                              type="text"
                              placeholder="Portfolio URL"
                              className="resume-input"
                              name="portfolioURL"
                              value={academicData?.portfolioURL}
                              onChange={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #CBD5E1",
                            marginBottom: "16px",
                          }}
                        ></div>
                        {/* <div className="d-flex align-items-center justify-content-between">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            onClick={AddCertificates}
                            className="plush-text p-0"
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add Link
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text p-0"
                            disabled={certificates.length === 1}
                            onClick={() => DeleteCertificates(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div> */}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="bottom-btn">
              <div className="text-center">
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <button className="skip-btn">Skip for now</button>
              </div>
            </div>
          </div>

          {/* Desktop Screen */}
          <div className="desktop-show-hide">
            <div className="profile-build">
              <div className="profile-line"></div>
              <h6 className="build-title">Build your profiles</h6>
              <div className="text-center send-resume-btn" {...getRootProps()}>
                <input {...getInputProps()} />
                {selectedFile === null ? (
                  <label htmlFor="file" className="auto-button">
                    Auto upload Resume
                  </label>
                ) : (
                  <button className="done-btn">
                    Uploaded
                    <img className="ps-2" src={done} alt="" />
                  </button>
                )}
                {selectedFile === null ? (
                  <p className="information">
                    <img src={info} alt="" /> Upload max 10MB .pdf file only
                  </p>
                ) : (
                  <p className="file-name">
                    {selectedFile.name}{" "}
                    <button onClick={removeFile} className="close-btn">
                      <img src={close} alt="" />
                    </button>
                  </p>
                )}
              </div>
              <div className="border-bottom"></div>
              <div className="resume-box">
                <h6 className="resume-title">Personal Details</h6>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="resume-input"
                      name="name"
                      value={
                        academicData?.name
                          ? academicData.name
                          : carrerData?.name
                      }
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="number"
                      placeholder="Phone number"
                      className="resume-input"
                      name="phone"
                      value={
                        academicData?.phone
                          ? academicData?.phone
                          : carrerData?.phone
                      }
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      className="resume-input"
                      value={
                        academicData?.email
                          ? academicData?.email
                          : carrerData?.email
                      }
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="calender-date-input"
                      onClick={handleDateOpen}
                    >
                      <input
                        placeholder="Enter DOB"
                        className="input-date"
                        type="text"
                        value={academicData?.dob}
                        name="dob"
                      />
                      <img
                        src={CalenderImg}
                        className="input-calender"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Current city"
                      className="resume-input"
                      name="city"
                      value={academicData.city}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="dropdown"
                      onMouseEnter={() => setDropHover1(true)}
                      onMouseLeave={() => setDropHover1(false)}
                    >
                      <div
                        style={{
                          outline: isActive ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                        onClick={() => {
                          setIsActive(!isActive);
                        }}
                      >
                        {search ? (
                          selected
                        ) : (
                          <input
                            type="text"
                            placeholder="Current State"
                            name="state"
                            className="search-input-box"
                            onChange={(e) => {
                              setAcademicData({
                                ...academicData,
                                [e.target.name]: e.target.value,
                              });
                              setSearch(false);
                            }}
                            value={academicData.state}
                          />
                        )}
                        <img
                          src={dropHover1 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: isActive ? "block" : "none" }}
                      >
                        {search
                          ? allState.map((element, index) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    setIsSelected(e.target.textContent);
                                    setSearch(!search);
                                    setIsActive(!isActive);
                                  }}
                                  className="item"
                                >
                                  {element.displayValue}
                                </div>
                              );
                            })
                          : result.map((element, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={(e) => {
                                    setIsSelected(e.target.textContent);
                                    setSearch(!search);
                                    setIsActive(!isActive);
                                  }}
                                  className="item"
                                >
                                  {element.item.displayValue}
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <input
                      type="text"
                      placeholder="Pin code"
                      className="resume-input"
                      name="pincode"
                      value={academicData.pincode}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="dropdown"
                      onMouseEnter={() => setDropHover2(true)}
                      onMouseLeave={() => setDropHover2(false)}
                    >
                      <div
                        onClick={(e) => {
                          setWorking(!working);
                        }}
                        style={{
                          outline: working ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData.workingStatus}
                        <img
                          src={dropHover2 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: working ? "block" : "none" }}
                      >
                        {selectItem.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["workingStatus"]: e.target.textContent,
                                });
                                setWorking(!working);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="dropdown"
                      onMouseEnter={() => setDropHover3(true)}
                      onMouseLeave={() => setDropHover3(false)}
                    >
                      <div
                        onClick={(e) => {
                          setMariatial(!mariatial);
                        }}
                        style={{
                          outline: mariatial ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData?.maritalStatus}
                        <img
                          src={dropHover3 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: mariatial ? "block" : "none" }}
                      >
                        {selectItemTwo.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["maritalStatus"]: e.target.textContent,
                                });
                                setMariatial(!mariatial);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-bottom"></div>
              <div className="resume-box">
                <h6 className="resume-title">summarize your career</h6>
                <div className="row">
                  <div className="col-12 ">
                    <textarea
                      name="carrierdesc"
                      id=""
                      style={{ resize: "none" }}
                      placeholder="Write about yourself and the highlights of your journey  "
                      className="resume-input m-0"
                      cols="30"
                      rows="3"
                      value={academicData?.carrierdesc}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
              <div
                className="border-bottom"
                style={{ marginTop: "10px" }}
              ></div>
              <div className="resume-box">
                {certificates.map((element, index) => {
                  return (
                    <>
                      <h6 className="resume-title">Add Links</h6>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Website URL"
                            className="resume-input"
                            name="weblink"
                            value={academicData?.weblink}
                            onChange={(e) => {
                              setAcademicData({
                                ...academicData,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Linkedin URL"
                            className="resume-input"
                            name="linkedinLink"
                            value={academicData?.linkedinLink}
                            onChange={(e) => {
                              setAcademicData({
                                ...academicData,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Portfolio URL"
                            className="resume-input"
                            name="portfolioURL"
                            value={academicData?.portfolioURL}
                            onChange={(e) => {
                              setAcademicData({
                                ...academicData,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-between">
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onClick={AddCertificates}
                      className="plush-text p-0"
                    >
                      <img src={plush} className="pe-2" alt="" />
                      Add Link
                    </button>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      disabled={certificates.length === 1}
                      onClick={() => DeleteCertificates(index)}
                      className="plush-text p-0"
                    >
                      <img src={Delete} className="pe-2" alt="" />
                      Delete
                    </button>
                  </div> */}
                    </>
                  );
                })}
              </div>
              <div className="text-center pb-4">
                <button className="back-btn" onClick={() => navigate(-1)}>
                  Back
                </button>
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <button className="skip-btn">Skip for now</button>
              </div>
            </div>
          </div>
        </section>
      )}

      {dateModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleDateOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date</h6>
              <div className="calender">
                <Calendar
                  onChange={(e) =>
                    {setAcademicData({
                      ...academicData,
                      ["dob"]: moment(e).format("DD-MM-YYYY"),
                    })
                    setDateModal(!dateModal);}
                  }
                  value={dateBirth}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default CareerResume;
