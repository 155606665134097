import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import leftArrow from "../../assets/recruiter/left-arrow.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import download from "../../assets/recruiter/download.svg";
import "./interviewer.css";
import Video from "../../assets/interview/video.svg";
import CalenderImg from "../../assets/calender/calender.svg";
import dropDown from "../../assets/recruiter/dropdown.svg";
import dropUp from "../../assets/recruiter/drop-up.svg";
import downloadImg from "../../assets/recruiter/download-btn.svg";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import closeBtn from "../../assets/close-bt.svg";
import moment from "moment";
import Calendar from "react-calendar";
import InterviewHeader from "../subHeader/InterviewHeader";
import InterviewSubMenu from "../mobileMenu/InterviewSubMenu";
import TableFeedbackModal from "../../dashboard/TableFeedbackModal";
import AllModalContext from "../../context/allModal/AllModalContext";
import { useMain } from "../../hook/useMain";
import { ShowStars } from "../showStars/ShowStars";
import "./interviewer.css";
import InterviewChat from "./chat/InterviewChat";
import ScreenModal from "../../dashboard/ScreenModal";
import Loader from "../loader/Loader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import InterviewerHeader2 from "../header/InterviewerHeader2";
import { baseUrl } from "../../contextApi/common/data";

const selectItem = [
  {
    id: 1,
    option: "1",
    selected: false,
  },
  {
    id: 2,
    option: "2",
    selected: false,
  },
  {
    id: 3,
    option: "3",
    selected: false,
  },
  {
    id: 4,
    option: "4",
    selected: false,
  },
  {
    id: 5,
    option: "5",
    selected: false,
  },
];
const Status = [
  {
    id: 1,
    option: "INTERVIEWING",
    selected: false,
  },
  {
    id: 2,
    option: "DECISION NEEDED",
    selected: false,
  },
  {
    id: 3,
    option: "HIRE",
    selected: false,
  },
  {
    id: 4,
    option: "NOT HIRE",
    selected: false,
  },
];

function TableCard() {
  const [company, setCompany] = useState(false);
  const [dropHover1, setDropHover1] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("Interviewing");

  return (
    <>
      <div className="table-card">
        <h6 className="table-card-title">Pavan Gandhi</h6>
        <div className="d-flex justify-content-between">
          <div>
            <h6 className="m-0 table-card-email">pavanghandhi009@gmail.com</h6>
            <h6 className="m-0 table-card-email p-0">+9162882534678</h6>
          </div>
          <div>
            <p className="table-card-date m-0">Interview Date</p>
            <p className="table-card-date-text m-0">23/10/2023</p>
          </div>
        </div>
        <div className="table-card-border"></div>
        <p className="table-card-summary m-0">Summary:</p>
        {/* <p className="table-card-summary-text m-0">
          Candidate seems to have good potential for learning at a fast pace
          with so Candidate seems to have good potential for learning at a fast
          pace with soCandidate seems to have good potential for learning at a
          fast pace with...
        </p> */}
        <div className="table-card-border"></div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="m-0 table-card-status">STATUS:</p>
          <div
            className="table-dropdown"
            onMouseEnter={() => setDropHover1(true)}
            onMouseLeave={() => setDropHover1(false)}
          >
            <div
              onClick={() => {
                setCompany(!company);
              }}
              className="dropdown-btn d-flex"
            >
              <p
                className="m-0"
                style={{
                  color: "#000",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                {selectedCompany}
              </p>
              <img src={dropHover1 ? dropDown : dropUp} alt="" />
            </div>
            <div
              className="dropdown-content"
              style={{
                display: company ? "block" : "none",
                left: "unset",
                width: "unset",
              }}
            >
              {Status.map((element, index) => {
                return (
                  <div
                    onClick={(e) => {
                      setSelectedCompany(e.target.textContent);
                      setCompany(!company);
                    }}
                    className="item"
                  >
                    {element.option}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <button className="view-profile-btn">View Profile</button>
      </div>
    </>
  );
}

function InterviewTable() {
  const menuItem = useContext(MobileContext);
  const modal = useContext(AllModalContext);
  const { toggleInterview } = modal;
  const { handleRecruiterToggle, recruiterMenu } = menuItem;
  const [toggle, setToggle] = useState(false);

  const navigate = useNavigate();
  const [dropHover1, setDropHover1] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("Interviewing");
  const [company, setCompany] = useState(false);
  const [data, setData] = useState("");
  const [dateModal, setDateModal] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [screen, setScreen] = useState("");
  const [date, setDate] = useState("");
  const [interviewModal, setInterviewModal] = useState(false);
  const [interviewername, setInterviewerName] = useState("");
  const [received, setReceived] = useState("");
  const [receivedModal, setReceivedModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userDataId, setUserDataId] = useState({
    id: "",
    name: "",
    email: "",
  });
  const [interviewDetail, setInterviewDetail] = useState({
    title: "",
    date: "",
    startTime: "",
    endTime: "",
    meetLink: "",
    desc: "",
  });
  const [downloadData, setDownloadData] = useState([]);
  const [receivedDate, setReceivedData] = useState("");
  const location = useLocation();
  const { name } = location?.state||"";
  function handleReceivedOpen() {
    setReceivedModal(!receivedModal);
  }
  const handleReceivedClost = () => {
    setReceivedModal(!receivedModal);
    setReceivedData(moment(received).format("DD/MM/YYYY"));
  };
  const handleInterviewOpen = (id, name, email) => {
    setUserDataId({ id, name, email });
    setInterviewModal(true);
  };
  function handleInterview() {
    setUserDataId({
      id: "",
      name: "",
    });
    setInterviewDetail({
      title: "",
      date: "",
      startTime: "",
      endTime: "",
      meetLink: "",
      desc: "",
    });
    setInterviewModal(false);
  }

  function handleDateOpen() {
    setDateModal(!dateModal);
  }
  const { id } = useParams();
  const { getAllApplication, updateInterviewStatus, updateApplication } =
    useMain();

  const getData = async (id) => {
    setLoader(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await getAllApplication(id, token);
    // const res1 = await getAllByCompany();
    if (res?.statusCode === 200) {
      setLoader(false);
      setData(res?.data);
    } else {
      setLoader(false);
    }
  };
  const filterData = () => {
    const update =
      data &&
      data.length > 0 &&
      data.map((e) => {
        // Clean up newline characters from the summary dynamically
        const cleanSummary = e?.userId?.summary
          ? e?.userId?.summary.replace(/\n/g, " ")
          : "";

        return {
          candidate: e?.name || "",
          resume: e?.userId?.resumeUrl || "",
          summary: cleanSummary,
          contact: e?.phone || "",
          mail: e?.email || "",
          city: e?.userId?.city !== "undefined" ? e?.userId?.city : "",
          state: e?.userId?.state !== "undefined" ? e?.userId?.state : "",
          status: e?.status || "",
          score: e?.score || "",
          notes: e?.notes || "",
          interviewtitle: e?.interviewDetail?.title || "",
          interviewdate: e?.interviewDetail?.date || "",
          interviewstartTime: e?.interviewDetail?.startTime || "",
          interviewendTime: e?.interviewDetail?.endTime || "",
          interviewmeetLink: e?.interviewDetail?.meetLink || "",
          interviewdesc: e?.interviewDetail?.desc || "",
          questions:
            e?.screeningQuestions && e?.screeningQuestions?.length > 0
              ? e?.screeningQuestions?.map((q) => ({
                  question: q.question,
                  answer: q.answer,
                }))
              : "",
        };
      });

    setDownloadData(update);
  };

  useEffect(() => {
    const name = JSON.parse(localStorage.getItem("Interviewer_data")).name;
    if (name) setInterviewerName(name);
    if (id) {
      getData(id);
    }
  }, [id]);

  useEffect(() => {
    filterData();
  }, [data]);

  console.log(data);

  const updateStatus = async (e, id1) => {
    const { value, name } = e.target;
    const data = { [name]: value };
    setLoader(true);
    const res = await updateApplication(data, id1);
    if (res?.statusCode === 200) {
      toast.success("Status updated.");
      setLoader(false);

      getData(id);
    } else {
      setLoader(false);
    }
  };
  const scheduleIntertiew = async () => {
    if (
      interviewDetail.title !== "" &&
      interviewDetail.startTime !== "" &&
      interviewDetail.endTime !== "" &&
      interviewDetail.desc !== "" &&
      userDataId.email !== "" &&
      // interviewDetail.meetLink !== "" &&
      received !== ""
    ) {
      setLoader(true);
      const { title, startTime, endTime, desc } = interviewDetail;
      const formattedStartTime = moment(startTime, "HH:mm").format("HH:mm");
      const formattedEndTime = moment(endTime, "HH:mm").format("HH:mm");
      const startDateString = moment(received).format("YYYY-MM-DD");
      const startDateTimeString =
        startDateString + "T" + formattedStartTime + ":00+05:30";
      const endDateTimeString =
        startDateString + "T" + formattedEndTime + ":00+05:30";
      const formattedData = {
        title: title,
        startDateTime: startDateTimeString,
        endDateTime: endDateTimeString,
        desc: desc,
        attendees: [{"email":userDataId.email}],
        location:`/interview/table/${id}`
      };
      const res = await updateInterviewStatus(
        { ...interviewDetail, date: moment(received).format("DD/MM/YYYY") },
        userDataId?.id
      );
      if (res?.statusCode === 200) {
        localStorage.setItem("interviewData", JSON.stringify(formattedData));
        window.location.href = `${baseUrl}/google`;


        // toast.success("Interview Schedule Success.");
        setDateModal(false);
        setInterviewDetail({
          title: "",
          date: "",
          startTime: "",
          endTime: "",
          meetLink: "",
          desc: "",
        });
        getData(id);
        setLoader(false);
      } else {
        toast.error("Interview schedule failed.");
        setLoader(false);
      }
    } else {
      toast.info("Please fill in all details.");
      setLoader(false);
    }
  };

  const downloadFile = () => {
    const csvData = convertToCSV(downloadData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "your_file.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (downloadData) => {
    const header = [
      "candidate",
      "resume",
      "summary",
      "contact",
      "mail",
      "city",
      "state",
      "status",
      "score",
      "notes",
      "interviewtitle",
      "interviewdate",
      "interviewstartTime",
      "interviewendTime",
      "interviewmeetLink",
      "interviewdesc",
      "questions",
    ].join(",");

    const rows = downloadData.map((obj) => {
      const summary = obj.summary
        ? `"${obj.summary.replace(/"/g, '""').replace(/\n/g, " ")}"`
        : "";

      const questionsString = Array.isArray(obj.questions)
        ? obj.questions.map((q) => `${q.question}: ${q.answer}`).join("; ") // Adjust this format as needed
        : "";

      const values = [
        `"${obj.candidate}"`,
        `"${obj.resume}"`,
        summary,
        `"${obj.contact}"`,
        `"${obj.mail}"`,
        `"${obj.city}"`,
        `"${obj.state}"`,
        `"${obj.status}"`,
        `"${obj.score}"`,
        `"${obj.notes}"`,
        `"${obj.interviewtitle}"`,
        `"${obj.interviewdate}"`,
        `"${obj.interviewstartTime}"`,
        `"${obj.interviewendTime}"`,
        `"${obj.interviewmeetLink}"`,
        `"${obj.interviewdesc}"`,
        `"${questionsString}"`,
      ];

      return values.join(",");
    });

    return [header, ...rows].join("\n");
  };

  console.log(data);
  return (
    <>
      <AlertContainer />
      <InterviewerHeader2 />
      <div className="desktop-show-hide">
        <InterviewHeader />
      </div>
      {loader && <Loader />}
      <div
        className="recruiter-container desktop-show-hide"
        style={{ paddingRight: "0" }}
      >
        <div className="table-container">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div
              className="d-flex align-items-center"
              onClick={() => navigate(-1)}
            >
              <img src={leftArrow} style={{ marginRight: "8px" }} alt="" />
              <p className="m-0 table-title">{name}</p>
            </div>
            <button className="download-btn" onClick={downloadFile}>
              Download
              <img src={download} style={{ marginLeft: "6px" }} alt="" />
            </button>
          </div>
          <div className="table-overflow">
            <table className="table-body" style={{ width: "2000px" }}>
              <thead>
                <tr>
                  <th style={{ width: "165px" }}>Candidate</th>
                  <th style={{ width: "100px" }}>Resume</th>
                  <th style={{ width: "280px" }}>Summary</th>
                  <th>Phone Number</th>
                  <th style={{ width: "170px" }}>Email ID</th>
                  <th style={{ width: "170px" }}>Current State</th>
                  <th style={{ width: "200px" }}>Screening Question</th>
                  <th style={{ width: "210px" }}>Status</th>
                  <th>Hiring Manager</th>
                  <th>Schedule Interview</th>
                  <th>Score</th>
                  <th style={{ width: "180px" }}>Feedback</th>
                  <th style={{ width: "180px" }}>Notes</th>
                  <th style={{ width: "180px" }}>Chat</th>
                </tr>
              </thead>
              <tbody style={{ height: "500px" }}>
                {data &&
                  data?.length > 0 &&
                  data?.map((item, index) => (
                    <tr>
                      <td className="first-box">{item.name}</td>
                      <td className="second-box">
                        <a href={item?.resume || item?.userId?.resumeUrl} style={{color: "#0D9488"}} target={"_blank"}>
                          Open
                        </a>
                      </td>
                      <td className="third-box" style={{height: "100px"}}>
                        <div className="summary-box">
                          {item?.summary || item?.userId?.summary}
                        </div>
                      </td>
                      <td className="four-box">{item?.phone}</td>
                      <td className="five-box">{item?.email}</td>
                      <td className="four-box">
                        {item?.userId?.state !== "undefined"
                          ? item?.userId?.state
                          : ""}
                      </td>
                      <td className="screen-box">
                        <button
                          style={{
                            borderBottom: "1px solid #000",
                            background: "transparent",
                            border: "none",
                            textDecoration: "underline",
                            color: "#0D9488",
                          }}
                          onClick={() => setScreen(item?._id)}
                        >
                          View
                        </button>
                        <ScreenModal
                          id={item?._id}
                          showModal={screen}
                          setShowModal={setScreen}
                          question={item?.screeningQuestions}
                        />
                      </td>
                      {/* <ScreenModal
                          showModal={screen}
                          setShowModal={setScreen}
                          userId={item?.userId?._id}
                        /> */}
                      <td className="six-box">
                        {/* <div className="table-dropdown">
                          <div
                            onClick={() => {
                              setDropHover2(!dropHover2);
                            }}
                            className="dropdown-btn d-flex"
                          >
                            <p
                              className="m-0"
                              style={{
                                color: "#000",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              {item.status}
                            </p>
                            <img src={dropHover2 ? dropDown : dropUp} alt="" />
                          </div>
                          <div
                            className="dropdown-content"
                            style={{
                              display: dropHover2 ? "block" : "none",
                              left: "unset",
                              width: "unset",
                            }}
                          >
                            {Status.map((element, index) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    setStatus(e.target.textContent);
                                    setDropHover2(!dropHover2);
                                  }}
                                  className="item"
                                >
                                  {element.option}
                                </div>
                              );
                            })}
                          </div>
                        </div> */}
                        <select
                          name="status"
                          className="table-select-btn"
                          value={item?.status}
                          style={{
                            backgroundColor:
                              item.status === "INTERVIEWING"
                                ? "#F59E0B"
                                : "" || item?.status === "DECISION NEEDED"
                                ? "#DBD9D9"
                                : "" || item?.status === "HIRE"
                                ? "#2DD4BF"
                                : "" || item?.status === "NOT HIRE"
                                ? "#FB7185"
                                : "",
                            border: "none",
                            outline: "none",
                            appearance: "none",
                            " -webkit-appearance": "none",
                            "-moz-appearance": "none",
                            backgroundImage: `url(${dropDown})`,
                            backgroundPosition: "right center",
                            backgroundRepeat: "no-repeat",
                            paddingRight: "20px",
                          }}
                          onChange={(e) => updateStatus(e, item?._id)}
                        >
                          {<option value="">Select Status</option>}
                          {Status.map((e) => (
                            <option
                              // className="dropdown-content"
                              // selected={
                              //   item.status === e.option
                              //     ? item.status
                              //     : "Select Status"
                              // }
                              value={e.option}
                            >
                              {e.option}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="seven-box">
                        <button>{interviewername}</button>
                      </td>
                      <td className="eight-box">
                        <a
                          style={{ textDecoration: "underline" }}
                          onClick={() =>
                            handleInterviewOpen(item?._id, item?.name, item?.userId?.email)
                          }
                        >
                          {!item?.interviewDetail?.date
                            ? "Set"
                            : item?.interviewDetail?.date}
                        </a>
                        {/* <a onClick={handleDateOpen}>
                          {moment(item?.interviewDate).format("DD-MM-YYYY")}
                        </a> */}

                        {/* {dateModal ? (
                          <div className="calender-modal">
                            <div className="calender-box-2">
                              <button
                                className="close-btn"
                                onClick={handleDateOpen}
                              >
                                <img src={closeBtn} alt="" />
                              </button>
                              <div className="text-center">
                                <h6 className="calender-title m-0">
                                  Choose date
                                </h6>
                                <div className="calender">
                                  <Calendar
                                    onChange={(e) => updateDate(e, item?._id)}
                                    // value={date}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </td>
                      <td className="eight-box">
                        {/* <div className="table-dropdown">
                          <div
                            onClick={() => {
                              setDropHover1(!dropHover1);
                            }}
                            className="dropdown-btn d-flex"
                          >
                            <p
                              className="m-0"
                              style={{
                                color: "#000",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              {selectedCompany}
                            </p>
                            <img src={dropHover1 ? dropDown : dropUp} alt="" />
                          </div>
                          <div
                            className="dropdown-content"
                            style={{
                              display: dropHover1 ? "block" : "none",
                              left: "unset",
                              width: "unset",
                            }}
                          >
                            {selectItem.map((element, index) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    setSelectedCompany(e.target.textContent);
                                    setDropHover1(!dropHover1);
                                  }}
                                  className="item"
                                >
                                  {element.option}
                                </div>
                              );
                            })}
                          </div>
                        </div> */}

                        <select
                          name="score"
                          value={item?.score}
                          style={{
                            border: "none",
                            outline: "none",
                            appearance: "none",
                            " -webkit-appearance": "none",
                            "-moz-appearance": "none",
                            backgroundImage: `url(${dropDown})`,
                            backgroundPosition: "right center",
                            backgroundRepeat: "no-repeat",
                            paddingRight: "20px",
                          }}
                          onChange={(e) => updateStatus(e, item?._id)}
                        >
                          <option value="">Select Score</option>
                          {selectItem.map((e) => (
                            <option
                              // selected={item.status === e.option}
                              value={e.option}
                            >
                              {e.option}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="nine-box">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => setShowModal(item?._id)}
                        >
                          {/* <ShowStars feedback={item?.feedback} /> */}
                          {/* <td className="second-box p-0 ">Given feedback</td> */}
                          <td
                            className="second-box p-0"
                            style={{ color: "#0D9488" }}
                          >
                            Give feedback
                          </td>
                        </div>
                        <TableFeedbackModal
                          id={item?._id}
                          getData={getData}
                          feedback={item?.feedback}
                          interviewId={id}
                          showModal={showModal}
                          setShowModal={setShowModal}
                          recruiterId={item?.recruiterId}
                          userId={item?.userId?._id}
                          userName={item.name}
                        />
                      </td>
                      <td className="ten-box">
                        <input
                          type="text"
                          name="notes"
                          defaultValue={item?.notes}
                          onBlur={(e) => updateStatus(e, item?._id)}
                        />
                      </td>
                      <td
                        className="ten-box"
                        style={{
                          textDecoration: "underline",
                          color: "#0D9488",
                        }}
                        onClick={() => setToggle(index)}
                      >
                        Chat
                      </td>
                      {toggle === index && (
                        <InterviewChat
                          setToggle={setToggle}
                          userId={item?.userId?._id}
                          name={item?.name}
                          company={item?.jobId?.companyName}
                        />
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="recruiter-container mobile-show-hide"
        style={{ marginTop: "80px" }}
      >
        <div className="table-box">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ marginBottom: "16px" }}
          >
            <h6 className="table-title m-0">{name}</h6>
            <img src={downloadImg} alt="" onClick={downloadFile} />
          </div>
          <div className="d-flex flex-column" style={{ gap: "8px" }}>
            {data &&
              data.length > 0 &&
              data.map((item, index) => (
                <>
                  <div className="table-card">
                    <h6 className="table-card-title">{item?.name}</h6>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h6 className="m-0 table-card-email">{item?.email}</h6>
                        <h6 className="m-0 table-card-email p-0">
                          {item?.phone}
                        </h6>
                      </div>
                      <div>
                        <p className="table-card-date m-0">Interview Date</p>
                        <p className="table-card-date-text m-0">
                          <a
                            onClick={() =>
                              handleInterviewOpen(item?._id, item?.name, item?.userId?.email)
                            }
                          >
                            {!item?.interviewDetail?.date
                              ? "Set"
                              : item?.interviewDetail?.date}
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="table-card-border"></div>
                    <p className="table-card-summary m-0">Summary:</p>
                    <p className="table-card-summary-text m-0">
                      <input
                        type="text"
                        name="notes"
                        style={{ border: "none", outline: "none" }}
                        defaultValue={item?.notes}
                        onBlur={(e) => updateStatus(e, item?._id)}
                      />
                    </p>
                    <div className="table-card-border"></div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="m-0 table-card-status">STATUS:</p>
                      <select
                        name="status"
                        className="table-select-btn"
                        value={item?.status}
                        style={{
                          backgroundColor:
                            item.status === "INTERVIEWING"
                              ? "#F59E0B"
                              : "" || item?.status === "PENDING"
                              ? "#DBD9D9"
                              : "" || item?.status === "HIRE"
                              ? "#2DD4BF"
                              : "" || item?.status === "NOT HIRE"
                              ? "#FB7185"
                              : "",
                          border: "none",
                          outline: "none",
                          appearance: "none",
                          " -webkit-appearance": "none",
                          "-moz-appearance": "none",
                          backgroundImage: `url(${dropDown})`,
                          backgroundPosition: "right center",
                          backgroundRepeat: "no-repeat",
                          paddingRight: "20px",
                        }}
                        onChange={(e) => updateStatus(e, item?._id)}
                      >
                        {<option value="">Select Status</option>}
                        {Status.map((e) => (
                          <option
                            // className="dropdown-content"
                            // selected={
                            //   item.status === e.option
                            //     ? item.status
                            //     : "Select Status"
                            // }
                            value={e.option}
                          >
                            {e.option}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button
                      className="view-profile-btn"
                      onClick={() =>
                        navigate(`/interview/table/view-profile/${item?._id}`)
                      }
                    >
                      View Profile
                    </button>
                  </div>
                </>
              ))}
          </div>

          <InterviewSubMenu />
          <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
            <img
              src={top}
              alt=""
              style={{
                transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 150ms ease",
                height: "14px",
              }}
            />
            <img src={jobIcon} style={{ height: "24px" }} alt="" />
          </div>
        </div>
      </div>
      {receivedModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleReceivedClost}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar onChange={setReceived} value={received} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {interviewModal ? (
        <div className="interview-modal">
          <div className="interview-box">
            <button className="close-btn" onClick={handleInterview}>
              <img src={closeBtn} alt="" />
            </button>
            <h6 className="interview-modal-title m-0">Schedule Interview</h6>
            <div className="d-flex">
              <p className="m-0 interview-modal-to">
                to: <span className="to-span">{userDataId?.name}</span>
              </p>
            </div>
            <div className="w-100">
              <p className="m-0 interview-input-title">Add title</p>
              <input
                type="text"
                className="interview-input"
                placeholder="e.g. FIrst round interview with Tech lead"
                value={interviewDetail?.title}
                onChange={(e) =>
                  setInterviewDetail({
                    ...interviewDetail,
                    title: e.target.value,
                  })
                }
              />
            </div>
            <div className="w-100">
              <p className="m-0 interview-input-title">Interview Type</p>
              <div
                className="d-flex align-items-center"
                style={{ gap: "12px" }}
              >
                <button className="active-item">
                  <img src={Video} alt="" /> Video Call
                </button>
                {/* <button className="inactive-item">
                  <img src={call} alt="" /> Phone
                </button>
                <button className="inactive-item">
                  <img src={office} alt="" /> In Office
                </button> */}
              </div>
            </div>
            <div className="w-100">
              <p className="m-0 interview-input-title">Interview Date </p>
              <div className="interview-calender" onClick={handleReceivedOpen}>
                <input
                  placeholder={"Select Date"}
                  style={{ border: "none", outline: "none" }}
                  type="text"
                  value={receivedDate != "" ? receivedDate : ""}
                />
                <img src={CalenderImg} className="input-calender" alt="" />
              </div>
            </div>
            <div className="w-100">
              <p className="m-0 interview-input-title">
                Interview Time{" "}
                <span
                  style={{
                    color: "#4D4D4D",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                >
                  IST (+5:30 hours GMT)
                </span>
              </p>
              <div
                className="d-flex align-items-center"
                style={{ gap: "12px" }}
              >
                <input
                  type="time"
                  className="interview-input"
                  placeholder="Select start time"
                  value={interviewDetail?.startTime}
                  onChange={(e) =>
                    setInterviewDetail({
                      ...interviewDetail,
                      startTime: e.target.value,
                    })
                  }
                />
                to
                <input
                  type="time"
                  className="interview-input"
                  placeholder="Select End time"
                  value={interviewDetail?.endTime}
                  onChange={(e) =>
                    setInterviewDetail({
                      ...interviewDetail,
                      endTime: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            {/* <div className="w-100">
              <p className="m-0 interview-input-title">Share video call link</p>
              <input
                type="text"
                className="interview-input"
                placeholder="e.g. https://meet.google.com/dwr-pduw-htr"
                value={interviewDetail?.meetLink}
                onChange={(e) =>
                  setInterviewDetail({
                    ...interviewDetail,
                    meetLink: e.target.value,
                  })
                }
              />
            </div>
            <p
              className="m-0"
              style={{
                color: "#4D4D4D",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "18px",
              }}
            >
              Unable to create & share video call link?{" "}
              <span
                style={{
                  color: "#11274B",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "18px",
                }}
              >
                Need help
              </span>
            </p> */}
            <div className="w-100">
              <p className="m-0 interview-input-title">
                Add description & Contact number
              </p>
              <textarea
                name=""
                id=""
                style={{ resize: "none" }}
                placeholder="Send the message to the candidate"
                className="interview-input"
                cols="30"
                rows="3"
                value={interviewDetail?.desc}
                onChange={(e) =>
                  setInterviewDetail({
                    ...interviewDetail,
                    desc: e.target.value,
                  })
                }
              ></textarea>
            </div>
            <button className="send-invite" onClick={scheduleIntertiew}>
              Send Invite
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default InterviewTable;

{
  /* <TableFeedbackModal
        id={item._id}
        toggleTable={toggleTable}
        tableF={tableF}
        rat={rat}
        setRating={setRating}
        excellent={excellent}
        setExcellent={setExcellent}
        outStanding={outStanding}
        setOutStanding={setOutStanding}
        HrUpdate={HrUpdate}
        setHrUpdate={setHrUpdate}
        academic={academic}
        setAcademic={setAcademic}
        skill={skill}
        setSkill={setSkill}
        desc={desc}
        setDesc={setDesc}
        hover={hover}
        setHover={setHover}
        hover1={hover1}
        setHover1={setHover1}
        hover2={hover2}
        setHover2={setHover2}
        hover3={hover3}
        setHover3={setHover3}
        hover4={hover4}
        setHover4={setHover4}
        hover5={hover5}
        setHover5={setHover5}
      /> */
}
