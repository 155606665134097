import React from 'react'
import './resume.css'

function CoralCard3() {
  return (
    <>
    <div className="coral-card-3">
        <div className="d-flex justify-content-between">
            <h6 className="coral-title-3">Coral</h6>
            <p className="coral-text-3">Best for Business</p>
        </div>
        <div className="coral-img-3"></div>
        <button className="coral-apply-3">Apply</button>
    </div>
    </>
  )
}

export default CoralCard3