import React, { useEffect, useState } from "react";
import box from "../../assets/calender/box.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import "./interviewer.css";
import Loader from "../loader/Loader";

const DraftCardS = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [interviewer, setInterviewer] = useState({});
  const { singleInterviewer } = useMain();
  const getData = async (id) => {
    setLoader(true);
    const res = await singleInterviewer(id);
    if (res?.statusCode === 200) {
      setData(res?.data?.jobApplicantion);
      setInterviewer(res?.data);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    const { _id } = JSON.parse(localStorage.getItem("Interviewer_data"));
    getData(_id);
  }, []);

  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  return (
    <>
      {loader && <Loader />}
      {data &&
        data.length > 0 &&
        data.map((e, i) => (
          <div className="draft-card" key={`allaplicant-id-${i}`}>
            <div className="d-flex align-items-start">
              <img src={box} alt="" style={{ marginRight: "8px" }} />
              <div className="w-100" style={{height:"50px"}}>
                <h2 className="draft-card-title" >{e?.jobInfo?.position}</h2>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="draft-card-text">{e?.companyName}</p>
                  <p className="draft-days">{timeAgo(e?.createdAt)}</p>
                </div>
              </div>
            </div>
            <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
            <ul className="draft-list">
              <li>
                {e?.jobInfo?.jobType} · {e?.jobInfo?.workPlaceType}
              </li>
              <li>
                {e?.companyStrength} employees · {e?.industry}
              </li>
              {/* <li>See how you compare to 843 applicants.</li>
              <li>Skills: Wireframing, User Experience (UX), +8 more</li>
              <li>Skills: Wireframing, User Experience (UX), +8 more</li> */}
            </ul>
            {/* <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <h6 className="draft-sub-title">Skills you will gain</h6>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <p className="m-0 draft-skills">Creativity</p>
                <p className="m-0 draft-skills">Art</p>
                <p className="m-0 draft-skills">History</p>
                <p className="m-0 draft-skills">Art History</p>
                <p className="m-0 draft-skills">Art Direction</p>
              </div>
            </div> */}
            <Link
              className="draft-edit-btn text-decoration-none"
              onClick={() => navigate(`/interview/table/${e?._id}`)}
              state={{ name: e?.jobInfo?.position }}
            >
              View Applicants
            </Link>
          </div>
        ))}
    </>
  );
};

export default DraftCardS;
