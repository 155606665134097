import React, { useEffect, useState } from "react";
import FormHeader from "../header/FormHeader";
import Done from "../../assets/search-btn/done.svg";
import error from "../../assets/search-btn/error.svg";
import closeBtn from "../../assets/close-bt.svg";
import { useNavigate } from "react-router-dom";
import "./account.css";
import axios from "axios";
import { baseUrl } from "../../contextApi/common/data";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import RecruiterHeader from "../header/RecruiterHeader";

function SubmitModal({ isOpen, onClose, userId1, email, children }) {
  const [otp, setOtp] = useState(null);
  const handleUpdateEmail = async () => {
    if (otp.length < 6) {
      return toast.info("OTP must be a 6-digit number.");
    }
    try {
      const res = await axios.post(
        `${baseUrl}/recruiter/emailupdate/submitotp/${userId1}`,
        {
          email,
          otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status == 200) {
        toast.success("Email updated successfully.");
        onClose();
      } else {
        toast.error("Internal server error.");
      }
    } catch (error) {}
  };
  if (!isOpen) return null;
  return (
    <>
      <div className="custom-modal-overlay">
        <div className="custom-modal">
          <div className="d-flex justify-content-end" onClick={onClose}>
            <img src={closeBtn} alt="" />
          </div>
          <div>
            <input
              type="password"
              style={{marginTop: "50px"}}
              placeholder="OTP"
              className="sign-input"
              // className={
              //   passwordError === null ? "sign-input" : "sign-input-error"
              // }
              name="password"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
            <button className="sign-up-btn mb-5" onClick={handleUpdateEmail}>
              Submit
            </button>
        </div>
      </div>
    </>
  );
}

function Setting() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [num, setNum] = useState(false);
  const [email, setEmail] = useState(false);
  const recruiterToken = localStorage.getItem("recruiterToken");
  const recruiterId = localStorage.getItem("recruiteId");
  const data = localStorage.getItem("recruiterData");
  const userToken = localStorage.getItem("userToken");
  const userId = localStorage.getItem("userId");
  const [isOpen, setIsOpen] = useState(false);
  const [data1, setData1] = useState({});
  const [emailData, setEmailData] = useState("");
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDelete = async () => {
    try {
      if (recruiterId) {
        const res = await axios.put(`${baseUrl}/recruiter/deactive/${recruiterId}`);
        if (res.status == 200) {
          localStorage.clear();
          navigate("/home");
        }
      }
    } catch (error) {}
  };

  const hangleEmailChange = async () => {
    try {
      if (!data1?._id) {
        return toast.info("Unique ID not found. Please log in again.");
      }
      const res = await axios.post(
        `${baseUrl}/recruiter/emailupdate/sendotp/${data1._id}`,
        { email: emailData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.status == 200) {
        toast.success("OTP sent successfully.");
        setIsOpen(true);
      }
      console.log(res);
    } catch (error) {}
  };
  let dataObj;
  useEffect(() => {
    dataObj = JSON.parse(data);
    setEmailData(dataObj?.email);
    setData1(dataObj);
  }, []);

  console.log(data1);
  const navigateFunction = () => {
    if (recruiterToken && recruiterId) {
      navigate("/recruiter-set");
    }
    if (userToken && userId) {
      navigate("/set-job");
    }
  };

  function handleNumChange() {
    setNum(true);
  }
  function handleEmailChange() {
    setEmail(true);
  }

  return (
    <>
      <AlertContainer />
      <RecruiterHeader title="Account & Settings" />
      <div className="setting-page">
        <div className="setting-container">
          <div>
            <h4 className="setting-title">Account and Setting</h4>
            <p className="setting-text">
              Customize your job search experience by setting your job
              preferences today and let us do the rest!
            </p>
          </div>
          <div className="setting-option">
            <h6 className="setting-option-title">Account</h6>
            <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
              {num ? (
                <div className="setting-option-box flex-column">
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <h6 className="setting-option-box-title m-0">
                      Change Number
                    </h6>
                    <button
                      className="setting-change-btn"
                      onClick={() => setNum(false)}
                    >
                      Close
                    </button>
                  </div>
                  <div className="setting-input w-100">
                    <input type="text" value={dataObj?.mobileNo} />
                    <img src={Done} alt="" />
                  </div>
                </div>
              ) : (
                <div className="setting-option-box">
                  <div>
                    <h6 className="setting-option-box-title">Change Number</h6>
                    <div className="d-flex align-items-center">
                      <p className="setting-option-box-email" style={{display: "block"}}>
                        {data1?.mobileNo} &nbsp;
                      </p>
                      <span className="setting-option-box-span">
                        {dataObj?.mobileNo}
                        <img src={Done} alt="" />
                      </span>
                    </div>
                  </div>
                  <button
                    className="setting-change-btn"
                    onClick={handleNumChange}
                  >
                    Change
                  </button>
                </div>
              )}
              {email ? (
                <div className="setting-option-box flex-column">
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <h6 className="setting-option-box-title m-0">
                      Change Email
                    </h6>
                    <button
                      className="setting-change-btn"
                      onClick={() => setEmail(false)}
                    >
                      Close
                    </button>
                  </div>
                  <div className="setting-input w-100">
                    <input
                      type="text"
                      name="email"
                      value={emailData}
                      onChange={(e) => setEmailData(e.target.value)}
                    />
                    <button
                      className="setting-change-btn py-0"
                      onClick={hangleEmailChange}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              ) : (
                <div className="setting-option-box">
                  <div>
                    <h6 className="setting-option-box-title">Change Email</h6>
                    <div className="d-flex align-items-center">
                      <p className="setting-option-box-email" style={{display: "block"}}>
                        {emailData} &nbsp;
                      </p>
                      <span className="setting-option-box-span">
                        {dataObj?.email}
                        <img src={Done} alt="" />
                      </span>
                    </div>
                  </div>
                  <button
                    className="setting-change-btn"
                    onClick={handleEmailChange}
                  >
                    Change
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="setting-option">
            <h6 className="setting-option-title">Notifications</h6>
            <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
              <div className="setting-option-box">
                <div>
                  <h6 className="setting-option-box-title">Email Updates</h6>
                  <p className="setting-option-box-text">
                    Permission to send important updates via Email
                  </p>
                </div>
                <div>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div className="setting-option-box">
                <div>
                  <h6 className="setting-option-box-title">SMS Updates</h6>
                  <p className="setting-option-box-text">
                    Permission to send important updates via SMS
                  </p>
                </div>
                <div>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div className="setting-option-box">
                <div>
                  <h6 className="setting-option-box-title">Whatsapp Updates</h6>
                  <p className="setting-option-box-text">
                    Permission to send important updates on WhatsApp
                  </p>
                </div>
                <div>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div className="setting-option-box">
                <div>
                  <h6 className="setting-option-box-title">Promotional</h6>
                  <p className="setting-option-box-text">
                    Get updates when there are new partners and features!
                  </p>
                </div>
                <div>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="setting-option">
            <h6 className="setting-option-title">Permissions</h6>
            <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
              <div className="setting-option-box">
                <div>
                  <h6 className="setting-option-box-title">Location</h6>
                  <p className="setting-option-box-text">
                    Allow Recruitifie to locate you to get best opportunity and
                    offers
                  </p>
                </div>
                <div>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
              <div className="setting-option-box">
                <div>
                  <h6 className="setting-option-box-title">Read SMS</h6>
                  <p className="setting-option-box-text">
                    Allow Recruitifie to read your SMS to verify OTPs
                  </p>
                </div>
                <div>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap gap-2 justify-content-center justify-content-md-btween">
            <button
              className="setting-save-btn d-block"
              onClick={navigateFunction}
            >
              Edit Your Profile
            </button>
            <button className="setting-save-btn d-block">Save</button>
            {/* <button className="setting-mobile-change-btn">
              Save and Continue
            </button> */}
          </div>
        </div>
        <div className="setting-container" style={{ marginTop: "12px" }}>
          <div className="setting-option-box-2 w-100">
            <div>
              <h6 className="setting-option-box-title">
                Help us improve our website
              </h6>
              <p className="setting-option-box-text">
                If you are facing any difficulty to perform an action, report a
                bug here
              </p>
            </div>
            <button
              className="setting-delete-btn"
              onClick={() => navigate("/setting/bug-report")}
            >
              Report a Bug
            </button>
          </div>
        </div>
        <div className="setting-container" style={{ marginTop: "12px" }}>
          <div className="setting-option-box-2 w-100">
            <div>
              <h6 className="setting-option-box-title">Deactivate Account</h6>
              <p className="setting-option-box-text">
                If you deactivate your account you will nopt be able to receive
                notifications
              </p>
            </div>
            <button className="setting-delete-btn">Deactivate</button>
          </div>
          <div className="setting-option-box-2 w-100">
            <div>
              <h6 className="setting-option-box-title">Delete Account</h6>
              <p className="setting-option-box-text">
                If you delete your account permanently you will lose on many
                opportunities
              </p>
            </div>
            <button className="setting-delete-btn" onClick={handleDelete}>Delete</button>
          </div>
        </div>
      </div>
      <SubmitModal
        isOpen={isOpen}
        onClose={closeModal}
        userId1={data1?._id}
        email={emailData}
      />
    </>
  );
}

export default Setting;
