import { useEffect, useRef, useState } from "react";
import downArrow from "../assets/select-down.svg";
import './css/dropdown.css';
import downArrowHover from "../assets/mobile/down-arrow-hover.svg";

const CitySearch = ({ options, label, id, selectedVal, handleChange, placeHolder }) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option[label]);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };

  const filter = (options) => {
    return options.filter(
      (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  return (
    <div className="dropdown">
      <div className="dropdown-btn">
        <input
          ref={inputRef}
          type="text"
          style={{ border: "none", outline: "none" }}
          placeholder={placeHolder}
          value={
            selectedVal === "" ? "" : getDisplayValue()
            // selectedVal === getDisplayValue()
          }
          name="searchTerm"
          onChange={(e) => {
            setQuery(e.target.value);
            handleChange(null);
          }}
          onClick={toggle}
        />
        <img src={isOpen ? downArrowHover : downArrow} alt="" />
      </div>
      {/* <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div> */}

      <div className={`dropdown-content ${isOpen ? "open" : ""}`}>
        {filter(options).map((option, index) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => selectOption(option)}
              className={`option ${
                option[label] === selectedVal ? "selected" : ""
              }`}
              key={`${id}-${index}`}
            >
              {option[label]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CitySearch;
