import React, { useState } from "react";
import AadharContext from "./AadharContext";

const AadharState = (props) => {
  const [referenceId, setReferenceId] = useState("");
  const [transactionId, setTransactionId] = useState("");
  return (
    <AadharContext.Provider
      value={{ referenceId, setReferenceId, transactionId, setTransactionId }}
    >
      {props.children}
    </AadharContext.Provider>
  );
};

export default AadharState;
