import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/Header";
import Sidebar from "../components/sideBar/Sidebar";
import "./dashboard.css";
import SideBarContext from "../context/SidebarContext";
import searchImg from "../assets/stories/dashboard-search.svg";
import locationImg from "../assets/location.png";
import MBusinessCard from "./MBusinessCard";
import top from "../assets/top-arrow.svg";
import jobIcon from "../assets/mobile/menu-job.svg";
import MobileMenu from "../components/mobileMenu/MobileMenu";
import MobileContext from "../context/mobile/MobileContext";
import AllModalContext from "../context/allModal/AllModalContext";
import AllModal from "./AllModal";
import downArrowHover from "../assets/mobile/down-arrow-hover.svg";
import downArrow from "../assets/mobile/down-arrow.svg";
import BusinessCard from "./BusinessCard";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import closeBtn from "../assets/close-bt.svg";
import leftArrow from "../assets/left-arrow.svg";
import leftImg from "../assets/talent/left-arrow.svg";
import UserAlertModel from "./UserAlertModel";

const DatePosted = [
  {
    id: 1,
    option: "Any Time",
    selected: false,
  },
  {
    id: 2,
    option: "Past Week",
    selected: false,
  },
  {
    id: 3,
    option: "Most Relevant",
    selected: false,
  },
  {
    id: 4,
    option: "Last 2 months",
    selected: false,
  },
];
const Experience = [
  {
    id: 1,
    option: "Internship",
    selected: false,
  },
  {
    id: 2,
    option: "Entry level",
    selected: false,
  },
  {
    id: 3,
    option: "Associate",
    selected: false,
  },
  {
    id: 4,
    option: "Mid-Senior level",
    selected: false,
  },
  {
    id: 5,
    option: "Director",
    selected: false,
  },
  {
    id: 6,
    option: "Executive",
    selected: false,
  },
];
const JobType = [
  {
    id: 1,
    option: "Full-time",
    selected: false,
  },
  {
    id: 2,
    option: "Part-time",
    selected: false,
  },
  {
    id: 3,
    option: "Contract",
    selected: false,
  },
  {
    id: 4,
    option: "Internship",
    selected: false,
  },
];
const OnsiteRemote = [
  {
    id: 1,
    option: "In-Office",
    selected: false,
  },
  {
    id: 2,
    option: "Remote",
    selected: false,
  },
  {
    id: 3,
    option: "Hybrid",
    selected: false,
  },
];
const company = [
  {
    id: 1,
    option: "Think tanks",
    selected: false,
  },
  {
    id: 2,
    option: "NGO",
    selected: false,
  },
  {
    id: 3,
    option: "Fintech",
    selected: false,
  },
  {
    id: 4,
    option: "IT",
    selected: false,
  },
];

function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("auth") === "true";
    if (!isAuthenticated) {
      navigate("/log-in");
      return;
    }
  }, []);
  const sidebar = useContext(SideBarContext);
  const menuItem = useContext(MobileContext);
  const modal = useContext(AllModalContext);
  const { isSidebarOpen } = sidebar;
  const { handleMobileToggle, mobileMenu } = menuItem;
  const { toggleModal } = modal;
  const [fiterToggle, setFilterToggle] = useState(false);

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);
  const [dropdown1, setDropdown1] = useState("Full-time");
  const [dropdown2, setDropdown2] = useState("Any Time");
  const [dropdown3, setDropdown3] = useState("Company");
  const [dropdown4, setDropdown4] = useState("Experience level");
  const [dropdown5, setDropdown5] = useState("Onsite/Remote");
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState("");
  const [filter, setFilter] = useState("");
  const [data, setData] = useState([]);
  const [sortData, setSortData] = useState([]);
  const { getAllUserPost, saveJob, sidebarSearch } = useMain();
  const [loader, setLoader] = useState(false);
  const [completeProfile, setCompleteProfile] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(dropdown2);
  const getData = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    setLoader(true);
    const res = await getAllUserPost();
    if (res?.statusCode === 200) {
      setLoader(false);
      if (userData) {
        const initialData = [];
        const filteredData = res?.data?.data?.filter((item) => {
          const jobInfo = item.jobInfo;
          const isCityIndore = item.headQuater === userData?.city;
          const isJobTitleDeveloper = jobInfo.position
            .toLowerCase()
            .includes(userData?.jobTitle);
          const hasJobPreference =
            jobInfo?.jobType &&
            jobInfo?.jobType
              .toLowerCase()
              .includes(
                userData?.jobPreference.map((item) => item.toLowerCase())
              );
          if (isCityIndore || isJobTitleDeveloper || hasJobPreference) {
            return isCityIndore || isJobTitleDeveloper || hasJobPreference;
          } else {
            initialData.push(item);
          }
        });
        setData([...filteredData, ...initialData]);
        setSortData([...filteredData, ...initialData]);
      }
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (!userData) {
      setCompleteProfile(false);
      setIsModalOpen(true);
    } else {
      const requiredFields = [
        "name",
        "phone",
        "email",
        "dob",
        "city",
        "state",
        "resumeUrl",
        "academic",
      ];
      for (let field of requiredFields) {
        if (!userData[field] || userData[field] == "") {
          setCompleteProfile(false);
          setIsModalOpen(true);
          break;
        }
      }
    }
  }, []);

  const hadlecloseModel = ()=>{
    setIsModalOpen(false)
  }

  const handleFind = () => {
    console.log("object", search, location);
    const lowerSearch = search.toLowerCase();
    const lowerLocation = location.toLowerCase();

    const filtered =
      sortData &&
      sortData.length > 0 &&
      sortData?.filter((e) => {
        const locationMatch = e?.jobInfo?.workPlace
          .toLowerCase()
          .includes(lowerLocation);
        const searchMatch =
          e?.jobInfo?.position.toLowerCase().includes(lowerSearch) ||
          e?.companyName?.toLowerCase().includes(lowerSearch);

        // Updated logic: Return true if at least one of the conditions is true
        return locationMatch && searchMatch;
      });
    setData(filtered);
  };

  const handleClearFilter = () => {
    setData(sortData);
  };
  const handleChange = () => {
    const lowertype = dropdown1.toLowerCase();
    const lowerdate = dropdown2.toLowerCase();
    const lowercom = dropdown3.toLowerCase();
    const lowerloc = dropdown5.toLowerCase();
    const filtered =
      sortData &&
      sortData.length > 0 &&
      sortData?.filter((e) => {
        const typeMatck = e?.jobInfo?.jobType.toLowerCase().includes(lowertype);
        const locMatch = e?.jobInfo?.workPlaceType
          .toLowerCase()
          .includes(lowerloc);

        const updatedAt = new Date(e?.createdAt);
        const currentDate = new Date();
        const lastSevenDays = new Date();
        lastSevenDays.setDate(currentDate.getDate() - 7);
        const lastTwoMonths = new Date();
        lastTwoMonths.setMonth(currentDate.getMonth() - 2);
        const relevantDate = new Date();
        relevantDate.setDate(currentDate.getDate() - 3);

        return (
          (typeMatck || locMatch) &&
          ((lowerdate === "past week" && updatedAt >= lastSevenDays) ||
            lowerdate === "any time" ||
            (lowerdate === "last 2 months" && updatedAt >= lastTwoMonths) ||
            (lowerdate === "most relevant" && updatedAt >= relevantDate))
        );
      });
    setData(filtered);
  };

  useEffect(() => {
    handleChange();
  }, [dropdown1, dropdown2, dropdown3, dropdown5]);

  useEffect(() => {
    filteredDrafts(sidebarSearch);
  }, [sidebarSearch]);

  const filteredDrafts = (sidebarSearch) => {
    const filterVal =
      sortData &&
      sortData.length > 0 &&
      sortData?.filter((item) => {
        const regex = new RegExp(sidebarSearch, "i"); // 'i' for case-insensitive search
        return (
          sidebarSearch.trim() === "" ||
          Object.values(item.jobInfo).some((value) => regex.test(String(value)))
        );
      });
    setData(filterVal);
  };

  return (
    <>
      <Header />
      <section className="dashboard">
        <div className="dashboard-box">
          <div className="sidebar-scroll">
            <Sidebar />
          </div>
          {loader ? (
            <Loader />
          ) : (
            <div
              className="job-seeker-dashboard"
              style={{ left: isSidebarOpen ? "85px" : "354px" }}
            >
              <div className="content">
                <div className="content-wrapper"></div>
                <h6 className="job-title">Find your dream job </h6>
                <div className="d-flex align-items-center justify-content-center mb-4">
                  <div className="job-input-">
                    <img src={searchImg} alt="" />
                    <input
                      type="text"
                      placeholder="What"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div className="job-input-">
                    <img src={locationImg} alt="" />
                    <input
                      type="text"
                      placeholder="Where"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                  <button onClick={handleFind} className="find-job-btn">
                    Find jobs
                  </button>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
                    <div
                      className="draft-dropdown"
                      onMouseEnter={() => setHover1(true)}
                      onMouseLeave={() => setHover1(false)}
                    >
                      <div className="dropdown-btn">
                        {dropdown1 || "Full-time/Part-time"}
                        <img
                          src={hover1 ? downArrowHover : downArrow}
                          style={{ marginLeft: "4px" }}
                          alt=""
                        />
                      </div>
                      <div className="draft-dropdown-content">
                        {JobType.map((element, index) => {
                          return (
                            <div
                              key={index}
                              onClick={(e) => {
                                setDropdown1(e.target.textContent);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="draft-dropdown"
                      onMouseEnter={() => setHover2(true)}
                      onMouseLeave={() => setHover2(false)}
                    >
                      <div className="dropdown-btn">
                        {dropdown2}
                        <img
                          src={hover2 ? downArrowHover : downArrow}
                          style={{ marginLeft: "4px" }}
                          alt=""
                        />
                      </div>
                      <div className="draft-dropdown-content">
                        {DatePosted.map((element, index) => {
                          return (
                            <div
                              key={index}
                              onClick={(e) => {
                                setDropdown2(e.target.textContent);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="draft-dropdown"
                      onMouseEnter={() => setHover3(true)}
                      onMouseLeave={() => setHover3(false)}
                    >
                      <div className="dropdown-btn">
                        {dropdown3}
                        <img
                          src={hover3 ? downArrowHover : downArrow}
                          style={{ marginLeft: "4px" }}
                          alt=""
                        />
                      </div>
                      <div className="draft-dropdown-content">
                        {company.map((element, index) => {
                          return (
                            <div
                              key={index}
                              onClick={(e) => {
                                setDropdown3(e.target.textContent);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* <div
          className="draft-dropdown"
          onMouseEnter={() => setHover4(true)}
          onMouseLeave={() => setHover4(false)}
        >
          <div className="dropdown-btn">
            {dropdown4}
            <img
              src={hover4 ? downArrowHover : downArrow}
              style={{ marginLeft: "4px" }}
              alt=""
            />
          </div>
          <div className="draft-dropdown-content">
            {Experience.map((element, index) => {
              return (
                <div
                  onClick={(e) => {
                    setDropdown4(e.target.textContent);
                  }}
                  className="item"
                >
                  {element.option}
                </div>
              );
            })}
          </div>
        </div> */}
                    <div
                      className="draft-dropdown"
                      onMouseEnter={() => setHover5(true)}
                      onMouseLeave={() => setHover5(false)}
                    >
                      <div className="dropdown-btn">
                        {dropdown5}
                        <img
                          src={hover5 ? downArrowHover : downArrow}
                          style={{ marginLeft: "4px" }}
                          alt=""
                        />
                      </div>
                      <div className="draft-dropdown-content">
                        {OnsiteRemote.map((element, index) => {
                          return (
                            <div
                              key={index}
                              onClick={(e) => {
                                setDropdown5(e.target.textContent);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="draft-dropdown">
                      <div onClick={handleClearFilter} className="dropdown-btn">
                        Clear Filter
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recommended">
                <h6 className="recommended-title">
                  Recommended Jobs/Internships
                </h6>
                <div className="recommended-container" style={{ gap: "16px" }}>
                  <BusinessCard
                    data={data}
                    filter={filter}
                    search={search}
                    location={location}
                    dropdownData={{
                      dropdown1,
                      dropdown2,
                      dropdown3,
                      dropdown4,
                      dropdown5,
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {loader ? (
            <Loader />
          ) : (
            <div className="mobile-show-hide mobile-recommended">
              <div className="mobile-search-job">
                <div className="job-input-">
                  <img src={searchImg} alt="" />
                  <input
                    type="text"
                    placeholder="What"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="job-input-">
                  <img src={locationImg} alt="" />
                  <input
                    type="text"
                    placeholder="Where"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "12px" }}
                >
                  <button onClick={handleFind} className="find-jobs-btn">
                    Find jobs
                  </button>
                  <button
                    className="apply-jobs-btn"
                    onClick={() => setFilterToggle(true)}
                    // onClick={() => navigate("/dashboard/job-filter")}
                  >
                    Apply Filter
                  </button>
                </div>
                <div className="search-job-border"></div>
              </div>
              <h6 className="mobile-recommended-title">Jobs/Internships</h6>
              {/* <MBusinessCard /> */}
              {/* <MBusinessCard /> */}
              {/* <p
              className="mobile-recommended-text m-0"
              style={{ paddingTop: "12px" }}
            >
              Communication
            </p> */}
              <MBusinessCard data={data} />
              {/* <MBusinessCard />
              <MBusinessCard />
              <MBusinessCard />
              <MBusinessCard />
              <MBusinessCard /> */}
              <MobileMenu />
              <div className="mobile-menu-btn" onClick={handleMobileToggle}>
                <img
                  src={top}
                  alt=""
                  style={{
                    transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "transform 150ms ease",
                    height: "14px",
                  }}
                />
                <img src={jobIcon} style={{ height: "24px" }} alt="" />
              </div>
            </div>
          )}
        </div>
      </section>
      {/* <AllModal /> */}
      {fiterToggle ? (
        <>
          <div className="all-modal">
            <div className="all-filter-box">
              <button
                className="close-btn"
                onClick={() => setFilterToggle(false)}
              >
                <img src={closeBtn} alt="" />
              </button>
              <h6
                className="modal-title"
                // onClick={toggleModal}
                onClick={() => setFilterToggle(false)}
                style={{ cursor: "pointer" }}
              >
                <img src={leftImg} alt="" />
                All Filters
              </h6>
              {/* <button className="sort-btn">Sort by</button>
              <div className="row">
                <div className="col-6">
                  <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="sort1"
                      value="Most Recent"
                      name="Sort by"
                    />
                    <label className="radio-button__label" htmlFor="sort1">
                      <span className="radio-button__custom"></span>
                      Most Recent
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="sort2"
                      value="Most Relevant"
                      name="Sort by"
                    />
                    <label className="radio-button__label" htmlFor="sort2">
                      <span className="radio-button__custom"></span>
                      Most Relevant
                    </label>
                  </div>
                </div>
              </div> */}
              {/* <div
                style={{ border: "1px solid #E2E8F0", margin: "6px 0" }}
              ></div> */}
              <button className="sort-btn" style={{ marginTop: "10px" }}>
                Date posted
              </button>
              <div className="row">
                <div className="col-6">
                  <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="date1"
                      name="Date posted"
                      onClick={() => {
                        setDropdown2("Any Time");
                      }}
                    />
                    <label className="radio-button__label" htmlFor="date1">
                      <span className="radio-button__custom"></span>
                      Any time
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="date2"
                      name="Date posted"
                      onClick={() => {
                        setDropdown2("Past Week");
                      }}
                    />
                    <label className="radio-button__label" htmlFor="date2">
                      <span className="radio-button__custom"></span>
                      Past week
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="date-3"
                      name="Date posted"
                      onClick={() => {
                        setDropdown2("Most Relevant");
                      }}
                    />
                    <label className="radio-button__label" htmlFor="date-3">
                      <span className="radio-button__custom"></span>
                      Most Relevant
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="radio-button">
                    <input
                      type="radio"
                      className="radio-button__input"
                      id="date-4"
                      name="Date posted"
                      onClick={() => {
                        setDropdown2("Last 2 months");
                      }}
                    />
                    <label className="radio-button__label" htmlFor="date-4">
                      <span className="radio-button__custom"></span>
                      Last 2 months
                    </label>
                  </div>
                </div>
              </div>
              <div
                style={{ border: "1px solid #E2E8F0", margin: "6px 0" }}
              ></div>
              {/* <button className="sort-btn" style={{ marginTop: "10px" }}>
                Experience level
              </button>
              <div className="row">
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Experience"
                      className="checkbox-button-input"
                      id="experience1"
                    />
                    <label
                      className="checkbox-button__label"
                      htmlFor="experience1"
                    >
                      <span className="checkbox-button__custom"></span>
                      Internship
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Experience"
                      className="checkbox-button-input"
                      id="experience2"
                    />
                    <label
                      className="checkbox-button__label"
                      htmlFor="experience2"
                    >
                      <span className="checkbox-button__custom"></span>
                      Entry level
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Experience"
                      className="checkbox-button-input"
                      id="experience3"
                    />
                    <label
                      className="checkbox-button__label"
                      htmlFor="experience3"
                    >
                      <span className="checkbox-button__custom"></span>
                      Associate
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Experience"
                      className="checkbox-button-input"
                      id="experience4"
                    />
                    <label
                      className="checkbox-button__label"
                      htmlFor="experience4"
                    >
                      <span className="checkbox-button__custom"></span>
                      Mid-Senior level
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Experience"
                      className="checkbox-button-input"
                      id="experience5"
                    />
                    <label
                      className="checkbox-button__label"
                      htmlFor="experience5"
                    >
                      <span className="checkbox-button__custom"></span>
                      Director
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      className="checkbox-button-input"
                      id="experience6"
                      name="Experience"
                    />
                    <label
                      className="checkbox-button__label"
                      htmlFor="experience6"
                    >
                      <span className="checkbox-button__custom"></span>
                      Executive
                    </label>
                  </div>
                </div>
              </div>
              <div
                style={{ border: "1px solid #E2E8F0", margin: "0 0 6px 0" }}
              ></div> */}
              <button className="sort-btn" style={{ marginTop: "10px" }}>
                Job type
              </button>
              <div className="row">
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Job"
                      className="checkbox-button-input"
                      id="job1"
                      onClick={() => {
                        setDropdown1("Full-time");
                      }}
                    />
                    <label className="checkbox-button__label" htmlFor="job1">
                      <span className="checkbox-button__custom"></span>
                      Full-time
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Job"
                      className="checkbox-button-input"
                      id="job2"
                      onClick={() => {
                        setDropdown1("Part-time");
                      }}
                    />
                    <label className="checkbox-button__label" htmlFor="job2">
                      <span className="checkbox-button__custom"></span>
                      Part-time
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Job"
                      className="checkbox-button-input"
                      id="job3"
                      onClick={() => {
                        setDropdown1("Contract");
                      }}
                    />
                    <label className="checkbox-button__label" htmlFor="job3">
                      <span className="checkbox-button__custom"></span>
                      Contract
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Job"
                      className="checkbox-button-input"
                      id="job4"
                      onClick={() => {
                        setDropdown1("Internship");
                      }}
                    />
                    <label className="checkbox-button__label" htmlFor="job4">
                      <span className="checkbox-button__custom"></span>
                      Internship
                    </label>
                  </div>
                </div>
              </div>
              <div
                style={{ border: "1px solid #E2E8F0", margin: "0 0 6px 0" }}
              ></div>
              <button className="sort-btn" style={{ marginTop: "10px" }}>
                On-site/remote
              </button>
              <div className="row">
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Remote"
                      className="checkbox-button-input"
                      id="remote1"
                      onClick={() => {
                        setDropdown5("In-Office");
                      }}
                    />
                    <label className="checkbox-button__label" htmlFor="remote1">
                      <span className="checkbox-button__custom"></span>
                      On-site
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Remote"
                      className="checkbox-button-input"
                      id="remote2"
                      onClick={() => {
                        setDropdown5("Remote");
                      }}
                    />
                    <label className="checkbox-button__label" htmlFor="remote2">
                      <span className="checkbox-button__custom"></span>
                      Remote
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Remote"
                      className="checkbox-button-input"
                      id="remote3"
                      onClick={() => {
                        setDropdown5("Hybrid");
                      }}
                    />
                    <label className="checkbox-button__label" htmlFor="remote3">
                      <span className="checkbox-button__custom"></span>
                      Hybrid
                    </label>
                  </div>
                </div>
              </div>
              <div
                style={{ border: "1px solid #E2E8F0", margin: "0 0 6px 0" }}
              ></div>
              <button className="sort-btn" style={{ marginTop: "10px" }}>
                Industry
              </button>
              <div className="row">
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Industry"
                      className="checkbox-button-input"
                      id="industry1"
                      onClick={() => {
                        setDropdown3("Think tanks");
                      }}
                    />
                    <label
                      className="checkbox-button__label"
                      htmlFor="industry1"
                    >
                      <span className="checkbox-button__custom"></span>
                      Think tanks
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Industry"
                      className="checkbox-button-input"
                      id="industry2"
                      onClick={() => {
                        setDropdown3("NGO");
                      }}
                    />
                    <label
                      className="checkbox-button__label"
                      htmlFor="industry2"
                    >
                      <span className="checkbox-button__custom"></span>
                      NGO
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Industry"
                      className="checkbox-button-input"
                      id="industry3"
                      onClick={() => {
                        setDropdown3("Fintech");
                      }}
                    />
                    <label
                      className="checkbox-button__label"
                      htmlFor="industry3"
                    >
                      <span className="checkbox-button__custom"></span>
                      Fintech
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="checkbox-button d-flex align-items-center">
                    <input
                      type="radio"
                      name="Industry"
                      className="checkbox-button-input"
                      id="industry4"
                      onClick={() => {
                        setDropdown3("IT");
                      }}
                    />
                    <label
                      className="checkbox-button__label"
                      htmlFor="industry4"
                    >
                      <span className="checkbox-button__custom"></span>
                      IT
                    </label>
                  </div>
                </div>
              </div>
              <button className="apply-btn" onClick={handleClearFilter}>
                Clear Filter <img src={leftArrow} alt="" />
              </button>
            </div>
          </div>
        </>
      ) : null}
      <UserAlertModel  open={isModalOpen} onClose={hadlecloseModel} />
    </>
  );
}

export default Home;
