import React, { useEffect, useRef, useState } from "react";
// import Header from "../components/header/Header";
import "../App.css";
import "./css/dropdown.css";
import info from "../assets/information.svg";
import plush from "../assets/plush.svg";
import { NavLink, useNavigate } from "react-router-dom";
import whiteNumber1 from "../assets/mobile/white-number-1.svg";
import whiteNumber2 from "../assets/mobile/white-number-2.svg";
import YellowNumber3 from "../assets/mobile/yellow-number-3.svg";
import number4 from "../assets/mobile/number4.svg";
import downArrow from "../assets/mobile/down-arrow.svg";
import FormHeader from "../components/header/FormHeader";
import CalenderImg from "../assets/calender/calender.svg";
import uploadBtn from "../assets/calender/upload.svg";
import downArrowHover from "../assets/mobile/down-arrow-hover.svg";
import Delete from "../assets/delete.svg";
import closeBtn from "../assets/close-bt.svg";
import Calendar from "react-calendar";
import moment from "moment";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import Header from "../components/header/Header";

const Modes = [
  {
    id: 1,
    option: "Online",
    selected: false,
  },
  {
    id: 2,
    option: "Offline",
    selected: false,
  },
];

function BuildResumeOne() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [receivedDate, setreceivedDate] = useState("");
  const [receivedDateModal, setreceivedDateModal] = useState(false);
  var receivedDateDate = moment(receivedDate).format("DD/MM/YYYY");
  const [loader, setLoader] = useState(false);

  const id = localStorage.getItem("userId");

  function handlereceivedDateOpen(index) {
    setreceivedDateModal(index);
  }

  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const imageRef3 = useRef();

  const InitialValues = {
    image1: null,
    image2: null,
  };

  const [formData, setFormData] = useState(InitialValues);

  const handleImage = (e, img) => {
    if (e?.target?.files[0].type === "application/pdf") {
      setFormData({ ...formData, [img]: e.target?.files[0] });
    } else {
      toast.info("Please select a valid PDF file.");
    }
  };

  const handleDivClick = () => {
    imageRef3.current.click();
  };

  const [cert1, setCert1] = useState(null);
  const [cert2, setCert2] = useState(null);
  const [cert3, setCert3] = useState(null);

  const cart = [cert1, cert2, cert3];

  const updateFiles = (e, index) => {
    if (e?.target?.files[0].type === "application/pdf") {
      if (index === 0) {
        setCert1(e.target.files[0]);
      } else if (index === 1) {
        setCert2(e.target.files[0]);
      } else {
        setCert3(e.target.files[0]);
      }
    } else {
      toast.info("Please select a valid PDF file.");
    }
  };

  const [dropdown1, setDropdown1] = useState(true);
  const [dropdown2, setDropdown2] = useState(true);
  const [dropdown3, setDropdown3] = useState(false);
  const [tooltip2, setTooltip2] = useState(false);
  const [certificate, setCertificate] = useState([
    {
      mode: "Mode",
      instituteName: "",
      CourseName: "",
      certificateWebLink: "",
      receivedDate: "",
      keyLearning: "",
    },
  ]);
  function AddCertificate() {
    if (certificate?.length < 3) {
      setCertificate([
        ...certificate,
        {
          mode: "Mode",
          instituteName: "",
          CourseName: "",
          certificateWebLink: "",
          receivedDate: "",
          keyLearning: "",
        },
      ]);
    }
  }

  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (userData) {
      if (
        userData?.matriculamCertificate &&
        userData?.matriculamCertificate != ""
      ) {
        setFile1("Matriculam certificate alreday uploaded");
      }
      if (
        userData?.highestqualification &&
        userData?.highestqualification != ""
      ) {
        setFile2("Highest qualification alreday uploaded");
      }
      setCertificate(
        userData?.certificatCourse?.length > 0
          ? userData?.certificatCourse
          : [
              {
                mode: "Mode",
                instituteName: "",
                CourseName: "",
                certificateWebLink: "",
                receivedDate: "",
                keyLearning: "",
              },
            ]
      );
    }
  }, []);

  function Dropdown1() {
    setDropdown2(false);
    setDropdown1(!dropdown1);
  }
  function Dropdown2() {
    setDropdown1(false);
    setDropdown2(!dropdown2);
  }

  const [platform, setPlatform] = useState(false);
  const [dropHover1, setDropHover1] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("Mode");
  function DeleteCertificate(id) {
    let filteredArray = certificate.filter((item, index) => index !== id);
    setCertificate(filteredArray);
  }

  const { buildResumeone } = useMain();

  async function handleOnSave() {
    setLoader(true);
    const result = await buildResumeone(
      formData?.image1,
      formData?.image2,
      certificate,
      cert1,
      cert2,
      cert3,
      id
    );
    if (result?.statusCode === 200) {
      toast.success(result?.message);
      setLoader(false);
      navigate("/build-resume-2");
      localStorage.setItem("userData", JSON.stringify(result?.data));
    } else {
      toast.error("Somthing went wrong");
      setLoader(false);
    }
  }

  return (
    <>
      <AlertContainer/>
      <Header title="Build Profile" />
      {loader && <Loader />}
      <section className="resume-build-1">
        <div className="mobile-show-hide">
          <div className="mobile-line-box">
            <div className="mobile-line-3"></div>
            <div className="d-flex justify-content-around number-line">
              <img src={whiteNumber1} alt="" />
              <img src={whiteNumber2} alt="" />
              <img src={YellowNumber3} alt="" />
              <img src={number4} alt="" />
            </div>
          </div>
          <div className="personal-from">
            <div
              className="d-flex align-items-center justify-content-between"
              onClick={Dropdown2}
            >
              <p
                className="personal-title m-0"
                style={{ paddingBottom: dropdown2 === true ? "12px" : "0" }}
              >
                academic certificates
              </p>
              <img
                src={downArrow}
                style={{ paddingBottom: dropdown2 === true ? "12px" : "0" }}
                alt=""
              />
            </div>
            <div
              className="resume-box"
              style={
                dropdown2 === true
                  ? { display: "block" }
                  : { display: "none", marginTop: "12px" }
              }
            >
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <label for="file-upload1" class="custom-file-upload">
                    {formData.image1
                      ? formData.image1.name
                      : "Matriculation certificate"}
                    <input
                      id="file-upload1"
                      className="custom-file-input"
                      onChange={(e) => handleImage(e, "image1")}
                      ref={imageRef1}
                      type="file"
                    />
                    <img src={uploadBtn} alt="" />
                  </label>
                  <p>{file1}</p>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <label for="file-upload2" class="custom-file-upload">
                    {formData.image2
                      ? formData.image2.name
                      : "Highest qualification certificate"}
                    <input
                      id="file-upload2"
                      className="custom-file-input"
                      onChange={(e) => handleImage(e, "image2")}
                      ref={imageRef2}
                      type="file"
                      accept="application.pdf"
                    />
                    <img src={uploadBtn} alt="" />
                  </label>
                  <p>{file2}</p>
                </div>
                {/*   <div
                      style={{
                        borderBottom: "1px solid rgb(203, 213, 225)",
                        marginBottom: "12px",
                        marginTop: "12px",
                      }}
                    ></div> */}

                {/*  <p className="plush-text p-0 m-0" onClick={AddMandatory}>
                <img src={plush} className="pe-2" alt="" />
                Add certificate
              </p> */}
              </div>
            </div>
          </div>
          <div className="personal-from">
            <div
              className="d-flex align-items-center justify-content-between"
              onClick={Dropdown1}
            >
              <p
                className="personal-title m-0"
                style={{ paddingBottom: dropdown1 === true ? "12px" : "0" }}
              >
                Certificate courses
              </p>
              <img
                src={downArrow}
                style={{ paddingBottom: dropdown1 === true ? "12px" : "0" }}
                alt=""
              />
            </div>
            <div
              className="resume-box"
              style={
                dropdown1 === true
                  ? { display: "block" }
                  : { display: "none", marginTop: "12px" }
              }
            >
              <div className="row">
                {certificate.map((element, index) => {
                  return (
                    <>
                      <div key={index} className="col-sm-12 col-md-6 col-lg-4">
                        <div>
                          {dropdown3 === index ? (
                            <div
                              className="select-overlay"
                              onClick={() => setDropdown3(false)}
                            ></div>
                          ) : (
                            ""
                          )}
                          <div
                            className="dropdown"
                            onMouseEnter={() => setDropHover1(true)}
                            onMouseLeave={() => setDropHover1(false)}
                            style={{
                              zIndex: dropdown3 === index ? "1112" : "",
                            }}
                          >
                            <div
                              onClick={(e) => setDropdown3(index)}
                              style={{
                                outline: dropdown3
                                  ? "2px solid #94A3B8"
                                  : "none",
                              }}
                              className="dropdown-btn"
                            >
                              {certificate[index]?.mode}
                              <img
                                src={dropHover1 ? downArrowHover : downArrow}
                                alt=""
                              />
                            </div>
                            <div
                              className="dropdown-content"
                              style={{
                                display: index === dropdown3 ? "block" : "none",
                              }}
                            >
                              {Modes.map((element, i) => {
                                return (
                                  <div
                                    onClick={(e) => {
                                      const updatedData = [...certificate];
                                      updatedData[index].mode =
                                        e.target.textContent;
                                      setCertificate(updatedData);
                                      setDropdown3(false);
                                    }}
                                    className="item"
                                  >
                                    {element.option}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          placeholder="Institute Name"
                          className="resume-input"
                          name="instituteName"
                          value={certificate[index].instituteName}
                          onChange={(e) => {
                            const updatedData = [...certificate];
                            updatedData[index].instituteName = e.target.value;
                            setCertificate(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          placeholder="Course name"
                          className="resume-input"
                          name="CourseName"
                          value={certificate[index].CourseName}
                          onChange={(e) => {
                            const updatedData = [...certificate];
                            updatedData[index].CourseName = e.target.value;
                            setCertificate(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div
                          class="custom-file-upload"
                          onClick={handleDivClick}
                        >
                          {cart[index] !== null
                            ? cart[index]?.name
                            : "Upload certificate"}
                          <input
                            id="file-upload"
                            // onChange={(e) => handleImage(e, "image")}
                            ref={imageRef3}
                            className="custom-file-input"
                            type="file"
                            name="certificateWebLink"
                            onChange={(e) => {
                              updateFiles(e, index);
                            }}
                          />
                          <img src={uploadBtn} alt="" />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          placeholder="Add certificate weblink"
                          className="resume-input"
                          name="certificateWebLink"
                          value={certificate[index].certificateWebLink}
                          onChange={(e) => {
                            const updatedData = [...certificate];
                            updatedData[index].certificateWebLink =
                              e.target.value;
                            setCertificate(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div
                          className="calender-date-input"
                          onClick={() => handlereceivedDateOpen(index)}
                        >
                          <input
                            placeholder="receivedDate (mm/yyyy)"
                            className="input-date"
                            type="text"
                            value={certificate[index]?.receivedDate}
                          />
                          <img
                            src={CalenderImg}
                            className="input-calender"
                            alt=""
                          />
                        </div>
                        {receivedDateModal === index ? (
                          <div className="calender-modal">
                            <div className="calender-box-2">
                              <button
                                className="close-btn"
                                onClick={() => setreceivedDateModal(false)}
                              >
                                <img src={closeBtn} alt="" />
                              </button>
                              <div className="text-center">
                                <h6 className="calender-title m-0">
                                  Choose date slot(s)
                                </h6>
                                <div className="calender">
                                  <Calendar
                                    onChange={(e) => {
                                      const updatedData = [...certificate];
                                      updatedData[index].receivedDate =
                                        moment(e).format("MM/YYYY");
                                      setCertificate(updatedData);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-12">
                        <textarea
                          name="keyLearning"
                          defaultValue={certificate[index].keyLearning}
                          onChange={(e) => {
                            const updatedData = [...certificate];
                            updatedData[index].keyLearning = e.target.value;
                            setCertificate(updatedData);
                          }}
                          id=""
                          cols="30"
                          style={{ resize: "none" }}
                          rows="3"
                          className="resume-input m-0"
                          placeholder="Write keyLearning your key learnings"
                        ></textarea>
                      </div>
                      <div className="tooltip-container">
                        <div
                          className="tooltip-box"
                          style={{
                            display: tooltip2 ? "block" : "none",
                            left: "-12px",
                          }}
                        >
                          <div
                            className="tooltip-close"
                            onClick={() => setTooltip2(false)}
                          ></div>
                          The highlights of your journey create a powerful
                          impact by showcasing your accomplishments, growth, and
                          potential. They provide insight into your professional
                          trajectory and demonstrate the value you bring to the
                          table.
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ paddingBottom: "12px" }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={info}
                            onClick={() => setTooltip2(!tooltip2)}
                            className="pe-2"
                            alt=""
                          />
                          <p className="information-text">
                            What impact would key learnings create
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottom: "1px solid rgb(203, 213, 225)",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      ></div>
                      <div className="d-flex pb-2 align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text p-0"
                          onClick={AddCertificate}
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add certificate
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text p-0"
                          disabled={certificate.length === 1}
                          onClick={() => DeleteCertificate(index)}
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="bottom-btn">
            <div className="d-flex flex-column">
              <button className="save-btn" onClick={handleOnSave}>
                Save and Continue
              </button>
              <button
                className="skip-btn"
                onClick={() => navigate("/build-resume-2")}
              >
                Skip for now
              </button>
            </div>
          </div>
        </div>

        {/* DeskTop View */}
        <div className="desktop-show-hide">
          <div className="profile-build">
            <div className="build-profile-one-line"></div>
            <div className="resume-box">
              <h6 className="resume-title">academic certificates </h6>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <label for="file-upload1" class="custom-file-upload">
                    {formData.image1
                      ? formData.image1.name
                      : "Matriculation certificate"}
                    <input
                      id="file-upload1"
                      className="custom-file-input"
                      accept="application/pdf"
                      onChange={(e) => handleImage(e, "image1")}
                      ref={imageRef1}
                      type="file"
                    />
                    <img src={uploadBtn} alt="" />
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <label for="file-upload2" class="custom-file-upload">
                    {formData.image2
                      ? formData.image2.name
                      : "Highest qualification certificate"}
                    <input
                      id="file-upload2"
                      className="custom-file-input"
                      onChange={(e) => handleImage(e, "image2")}
                      ref={imageRef2}
                      type="file"
                    />
                    <img src={uploadBtn} alt="" />
                  </label>
                </div>
              </div>
              <div className="border-bottom"></div>
              <h6 className="resume-title" style={{ paddingTop: "16px" }}>
                Certificate courses
              </h6>
              {certificate.map((element, index) => {
                return (
                  <>
                    <div className="row" key={index}>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div>
                          {dropdown3 === index ? (
                            <div
                              className="select-overlay"
                              onClick={() => setDropdown3(false)}
                            ></div>
                          ) : (
                            ""
                          )}
                          <div
                            className="dropdown"
                            onMouseEnter={() => setDropHover1(true)}
                            onMouseLeave={() => setDropHover1(false)}
                            style={{
                              zIndex: dropdown3 === index ? "1112" : "",
                            }}
                          >
                            <div
                              onClick={(e) => setDropdown3(index)}
                              style={{
                                outline: dropdown3
                                  ? "2px solid #94A3B8"
                                  : "none",
                              }}
                              className="dropdown-btn"
                            >
                              {certificate[index]?.mode}
                              <img
                                src={dropHover1 ? downArrowHover : downArrow}
                                alt=""
                              />
                            </div>
                            <div
                              className="dropdown-content"
                              style={{
                                display: index === dropdown3 ? "block" : "none",
                              }}
                            >
                              {Modes.map((element, i) => {
                                return (
                                  <div
                                    onClick={(e) => {
                                      const updatedData = [...certificate];
                                      updatedData[index].mode =
                                        e.target.textContent;
                                      setCertificate(updatedData);
                                      setDropdown3(false);
                                    }}
                                    className="item"
                                  >
                                    {element.option}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          placeholder="Institute Name"
                          className="resume-input"
                          name="instituteName"
                          value={certificate[index].instituteName}
                          onChange={(e) => {
                            const updatedData = [...certificate];
                            updatedData[index].instituteName = e.target.value;
                            setCertificate(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          placeholder="Course name"
                          className="resume-input"
                          name="CourseName"
                          value={certificate[index].CourseName}
                          onChange={(e) => {
                            const updatedData = [...certificate];
                            updatedData[index].CourseName = e.target.value;
                            setCertificate(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div
                          class="custom-file-upload"
                          onClick={handleDivClick}
                        >
                          {cart[index] !== null
                            ? cart[index]?.name
                            : "Upload certificate"}
                          <input
                            id="file-upload"
                            // onChange={(e) => handleImage(e, "image")}
                            ref={imageRef3}
                            className="custom-file-input"
                            type="file"
                            name="certificateWebLink"
                            onChange={(e) => {
                              updateFiles(e, index);
                            }}
                          />
                          <img src={uploadBtn} alt="" />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          placeholder="Add certificate weblink"
                          className="resume-input"
                          name="certificateWebLink"
                          value={certificate[index].certificateWebLink}
                          onChange={(e) => {
                            const updatedData = [...certificate];
                            updatedData[index].certificateWebLink =
                              e.target.value;
                            setCertificate(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div
                          className="calender-date-input"
                          onClick={() => handlereceivedDateOpen(index)}
                        >
                          <input
                            placeholder="receivedDate (mm/yyyy)"
                            className="input-date"
                            type="text"
                            value={certificate[index]?.receivedDate}
                          />
                          <img
                            src={CalenderImg}
                            className="input-calender"
                            alt=""
                          />
                        </div>
                        {receivedDateModal === index ? (
                          <div className="calender-modal">
                            <div className="calender-box-2">
                              <button
                                className="close-btn"
                                onClick={() => setreceivedDateModal(false)}
                              >
                                <img src={closeBtn} alt="" />
                              </button>
                              <div className="text-center">
                                <h6 className="calender-title m-0">
                                  Choose date slot(s)
                                </h6>
                                <div className="calender">
                                  <Calendar
                                    onChange={(e) => {
                                      const updatedData = [...certificate];
                                      updatedData[index].receivedDate =
                                        moment(e).format("MM/YYYY");
                                      setCertificate(updatedData);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-12">
                        <textarea
                          name="keyLearning"
                          defaultValue={certificate[index].keyLearning}
                          onChange={(e) => {
                            const updatedData = [...certificate];
                            updatedData[index].keyLearning = e.target.value;
                            setCertificate(updatedData);
                          }}
                          id=""
                          cols="30"
                          rows="3"
                          style={{ resize: "none" }}
                          className="resume-input m-0"
                          placeholder="Write keyLearning your key learnings"
                        ></textarea>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="tooltip-container">
                          <div
                            className="tooltip-box"
                            style={{
                              display: tooltip2 ? "block" : "none",
                              left: "-24px",
                            }}
                          >
                            <div
                              className="tooltip-close"
                              onClick={() => setTooltip2(false)}
                            ></div>
                            The highlights of your journey create a powerful
                            impact by showcasing your accomplishments, growth,
                            and potential. They provide insight into your
                            professional trajectory and demonstrate the value
                            you bring to the table.
                          </div>
                          <div
                            className="d-flex align-items-center"
                            style={{ paddingBottom: "12px" }}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={info}
                              onClick={() => setTooltip2(!tooltip2)}
                              className="pe-2"
                              alt=""
                            />
                            <p className="information-text">
                              What impact would key learnings create
                            </p>
                          </div>
                        </div>
                        <p
                          className="m-0"
                          style={{
                            color: "#808080",
                            textAlign: "right",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "600",
                            lineHeight: "16px",
                          }}
                        >
                          Look at examples
                        </p>
                      </div>
                      <div className="d-flex pb-2 align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text p-0"
                          onClick={AddCertificate}
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add certificate
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text p-0"
                          disabled={certificate.length === 1}
                          onClick={() => DeleteCertificate(index)}
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="text-center pb-4">
              <button className="back-btn" onClick={() => navigate(-1)}>
                Back
              </button>
              <button className="save-btn" onClick={handleOnSave}>
                Save and Continue
              </button>
              <NavLink to={"/build-resume-2"}>
                <button className="skip-btn">Skip for now</button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BuildResumeOne;
