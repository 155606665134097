import React, { useContext, useRef, useState } from "react";
import Header from "../header/Header";
import search from "../../assets/recruiter/search.svg";
import Close from "../../assets/recruiter/close-input.svg";
import CoralCard from "./CoralCard";
import CoralSlider from "./CoralSlider";
import BasicCard from "./BasicCard";
import SubCard from "./SubCard";
import ResumeImg from "../../assets/profile-photo.svg";
import CoralCard3 from "./CoralCard3";
import jobIcon from "../../assets/job-icon.svg";
import top from "../../assets/top-arrow.svg";
import MobileMenu from "../mobileMenu/MobileMenu";
import MobileContext from "../../context/mobile/MobileContext";
import { useNavigate } from "react-router-dom";
import Sidebar from "../sideBar/Sidebar";
import SideBarContext from "../../context/SidebarContext";
import "./resume.css";
import html2pdf from "html2pdf.js";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import Coming from "../../pages/Coming";

function Templates() {
  const menuItem = useContext(MobileContext);
  const { handleMobileToggle, mobileMenu } = menuItem;
  const sidebar = useContext(SideBarContext);
  const { isSidebarOpen } = sidebar;
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  function handleOnEdit() {
    navigate("/resume-builder/templates-paid");
  }

  const [loader, setLoader] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  const designBodyRef = useRef(null);

  const { UpdateResume } = useMain();

  const handleDownloadPDF = async () => {
    const designBody = designBodyRef.current;

    // Check if the designBody element exists
    if (!designBody) {
      console.error("Design body element not found");
      return;
    }

    // Use html2pdf to convert HTML content to a PDF
    html2pdf(designBody, {
      margin: 10,
      filename: "resume.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      output: async (pdf) => {
        setPdfBlob(pdf);
        // Now, you can send the pdf Blob to your server via a POST request
        // For example, using axios:
        // axios.post('YOUR_SERVER_ENDPOINT', pdf)
        //   .then(response => console.log('PDF uploaded successfully:', response.data))
        //   .catch(error => console.error('Error uploading PDF:', error.message));
        // const result = await UpdateResume(pdf, data?._id);
        // if (result?.statusCode === 200) {
        //   alert(result?.message);
        // }
        // console.log(result)
      },
    });
  };

  const uploadeResume = async (e) => {
    const data = e.target.files[0];
    const id = localStorage.getItem("userId");
    if (data?.type === "application/pdf") {
      setLoader(true);
      const result = await UpdateResume(data, id);
      if (result?.statusCode === 200) {
        toast.success(result?.message);
        setLoader(false);
      }
    } else {
      toast.info("Please select pdf");
      setLoader(false);
    }
  };

  return (
    <>
      <AlertContainer />
      <Header />
      {loader && <Loader />}

      <div className="desktop-show-hide">
        <section className="dashboard">
          <div className="dashboard-box ">
            <div className="sidebar-scroll">
              <Sidebar />
            </div>
            <div
              className="job-seeker-dashboard"
              style={{ left: isSidebarOpen ? "85px" : "354px" }}
            >
              <Coming />
              {/* <div className="template-container">
                <div className="desktop-show-hide"> */}
                  {/* ye bhi uncomment mat krna ye bhi pehele se hi tha <div className="d-flex justify-content-end">
                    <div className="template-search mb-3">
                      <img src={search} alt="" />
                      <input type="text" placeholder="Choose category" />
                      <img src={Close} alt="" />
                    </div>
                  </div> */}
                  {/* <div className="template-body">
                    <div className="design-container">
                      <div className="design-head">
                        <div className="d-flex align-items-center">
                          <div className="design-img"></div>
                          <div>
                            <h6 className="design-title">Basic CV Design</h6>
                            <p className="design-text">Best for Business</p>
                          </div>
                        </div>
                        <div className="design-btns">
                          <button
                            className="design-edit"
                            onClick={() => handleOnEdit()}
                          >
                            Edit
                          </button>
                          <button
                            onClick={handleDownloadPDF}
                            className="design-edit"
                          >
                            Download
                          </button>
                          <button className="design-save">Save</button>
                          <div>
                            <input
                              type="file"
                              accept="application/pdf"
                              onChange={(e) => uploadeResume(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div ref={designBodyRef} className="design-body">
                        <div id="my-resume" className="resume-design">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <div className="design-item">
                              <h6 className="design-name">{userData?.name}</h6>
                              <p className="design-designation">
                                {userData?.jobTitle}
                              </p>
                              <div style={{ display: "flex" }}>
                                <p className="design-experience-text-1">
                                  {userData?.email}
                                </p>{" "}
                                &nbsp; &nbsp;
                                <p className="design-experience-text-1">
                                  {userData?.phone}
                                </p>
                              </div>
                            </div>
                            <div></div>
                          </div>
                          <div>
                            <div className="d-flex ">
                              <div className="design-experience">
                                <div>
                                  <h6 className="design-experience-text">
                                    DOB - {userData?.dob}
                                  </h6>
                                  <h6 className="design-experience-text">
                                    Marital status - {userData?.maritalStatus}
                                  </h6>
                                  <h6 className="design-experience-text">
                                    Address - {userData?.city},{" "}
                                    {userData?.state}
                                  </h6>
                                  <h6 className="design-experience-text">
                                    Working Status - {userData?.workingStatus}
                                  </h6>
                                </div>
                                {userData?.certificates &&
                                  userData.certificates.length > 0 && (
                                    <div>
                                      <p className="design-experience-title-2">
                                        Certificate
                                      </p>
                                      {userData?.certificates?.map(
                                        (item, index) => (
                                          <div
                                            style={{ marginTop: "2px" }}
                                            key={index}
                                          >
                                            <h6 className="design-experience-titles">
                                              {item?.certificateName} -{" "}
                                              <a href={item?.certificateLink}>
                                                link
                                              </a>
                                            </h6>
                                            <p className="design-experience-text">
                                              Issue Date - {item?.issueDate}
                                            </p>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                {userData?.workExperience &&
                                  userData?.workExperience.length > 0 && (
                                    <div>
                                      <p className="design-experience-title-2">
                                        Experience
                                      </p>
                                      {userData?.workExperience?.map(
                                        (item, index) => (
                                          <div
                                            style={{ marginTop: "3px" }}
                                            key={index}
                                          >
                                            <h6 className="design-experience-titles">
                                              {item?.designation}
                                            </h6>
                                            <p className="design-experience-text">
                                              {item?.companyName}
                                            </p>
                                            <p className="design-experience-text-1">
                                              {item?.from} - {item?.to}
                                              {item?.companyType}
                                            </p>
                                            <p className="design-experience-text">
                                              {item?.describeRole}
                                            </p>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                              </div>
                              <div
                                className="design-skill"
                                style={{ marginLeft: "1rem" }}
                              > */}
                                {/* ye comment ko uncomment mt krna ye pehele se hi tha   
                                <div>
                                <p className="design-experience-text-1">
                                    {userData?.email}
                                  </p>
                                  <p className="design-experience-text-1">
                                    {userData?.phone}
                                  </p>
                                <p className="design-experience-text-1">
                                    {userData?.city === "undefined"
                                      ? ""
                                      : userData?.city}
                                  </p>
                                </div>
                                ye comment ko uncomment mt krna ye pehele se hi tha  */}

                                {/* {userData?.academic &&
                                  userData?.academic.length > 0 && (
                                    <div>
                                      <p className="design-experience-title-2">
                                        Academic Details
                                      </p>
                                      {userData?.academic?.map(
                                        (item, index) => (
                                          <div
                                            style={{ marginTop: "3px" }}
                                            key={index}
                                          >
                                            <h6 className="design-experience-titles">
                                              {item?.instituteName} -{" "}
                                              {item?.degreeName}
                                            </h6>
                                            <p className="design-experience-text-1">
                                              {item?.startYear} -{" "}
                                              {item?.endYear}
                                            </p>
                                            <p className="design-experience-text">
                                              {item?.degreeType}
                                            </p>
                                            <p className="design-experience-text">
                                              {item?.highlight}
                                            </p>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                {userData?.skills && (
                                  <div>
                                    <div>
                                      <p className="design-experience-titles">
                                        Skills
                                      </p>
                                    </div>
                                    <div>
                                      {userData?.skills.map((item, index) => (
                                        <p className="design-experience-text">
                                          {item}
                                        </p>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="resume-tamp">
                      <h6 className="resume-tamp-title">Resume Templates</h6>
                      <div>
                        <p className="resume-tamp-text">Saved</p>
                        <div className="coral-slider">
                          <CoralSlider />
                        </div>
                      </div>
                      <div>
                        <p className="resume-tamp-text">More Options</p>
                        <div className="more-options">
                          <BasicCard />
                          <CoralCard />
                          <SubCard />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
      <div className="mobile-show-hide">
        <Coming />
        <MobileMenu />
        <div className="mobile-menu-btn" onClick={handleMobileToggle}>
          <img
            src={top}
            alt=""
            style={{
              transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 150ms ease",
              height: "14px",
            }}
          />
          <img src={jobIcon} alt="" />
        </div>
        {/* <div className="template-container">
          <h6 className="resume-tamp-title">Resume Templates</h6>
          <div>
            <p className="resume-tamp-text">Pinned</p>
            <div className="coral-slider">
              <CoralSlider />
            </div>
          </div>
          <div style={{ marginTop: "12px" }}>
            <p className="resume-tamp-text">More Templates</p>
            <div className="d-flex flex-column" style={{ gap: "8px" }}>
              <CoralCard3 />
              <CoralCard3 />
              <CoralCard3 />
              <CoralCard3 />
              <CoralCard3 />
              <CoralCard3 />
            </div>
          </div>
          <MobileMenu />
          <div className="mobile-menu-btn" onClick={handleMobileToggle}>
            <img
              src={top}
              alt=""
              style={{
                transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 150ms ease",
                height: "14px",
              }}
            />
            <img src={jobIcon} alt="" />
          </div>
          <div className="bottom-btn mobile-show-hide">
            <button
              className="save-btn m-0"
              onClick={() => navigate("/resume-builder/templates/get-more")}
            >
              Get More Templates
            </button>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Templates;
