import { baseUrl, headers } from "../common/data";

export const RecruiterSigin = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/recruiter/register`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const UpdateCity = async (cityPreference, id) => {
  try {
    const response = await fetch(`${baseUrl}/recruiter/update1/${id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(cityPreference),
    });

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const UpdateLogo = async (data, id) => {
  try {
    var formdata = new FormData();
    formdata.append("image", data, data.name);

    const response = await fetch(`${baseUrl}/recruiter/uploadlogo1/${id}`, {
      method: "PATCH",
      body: formdata,
      redirect: "follow",
    });

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const UpdateCompanyDetails = async (data, id) => {
  try {
    const response = await fetch(`${baseUrl}/recruiter/update2/${id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const UpdateVerification = async (doctype, image, id) => {
  try {
    var formdata = new FormData();
    formdata.append("doctype", doctype);
    formdata.append("image", image);

    var requestOptions = {
      method: "PATCH",
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `${baseUrl}/recruiter/update3/${id}`,
      requestOptions
    );
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const recruiterSendMail = async (id) => {
  let data = {};
  try {
    await fetch(`${baseUrl}/recruiter/mailandWhatsapp/${id}`)
      .then((req) => req.json())
      .then((res) => {
        data = res;
        return res;
      })
      .catch((err) => console.log(err));
  } catch (err) {
    console.log(err);
  }
  return data;
};


export const RecruiterUpdateMobileNo = async (mobileNo, id) => {
  try {
    const response = await fetch(`${baseUrl}/recruiter/update/mobile/${id}`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(mobileNo),
    });

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};
