import React, { useEffect, useState } from "react";
import "./recruiter.css";
import Header from "../header/Header";
import SubHeader from "../subHeader/SubHeader";
import Loader from "../loader/Loader";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import RecruiterHeader from "../header/RecruiterHeader";
import Coming from "../../pages/Coming";
import { useNavigate } from "react-router-dom";

const events = [];
function Calender() {

  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [loader, setLoader] = useState(false);
  return (
    <>
      <RecruiterHeader />
      <div className="desktop-show-hide">
        <SubHeader />
      </div>
      {loader ? <Loader /> : <Coming />}
      {/* <div className="recruiter-container">
          <div className="new-job-container">
          <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                weekends={true}
                events={events} />
          </div>
        </div> */}
    </>
  );
}

export default Calender;
