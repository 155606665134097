import React from "react";
import Slider from "react-slick";
import CoralCard from "./CoralCard";
import './resume.css'

function CoralSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "1%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            dots: true,
            centerPadding: "20%",
          },
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            dots: true,
            centerPadding: "18%",
          },
        },
        {
          breakpoint: 375,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            dots: true,
            centerPadding: "14%",
          },
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            dots: true,
            centerPadding: "12%",
          },
        },
        {
          breakpoint: 330,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            dots: true,
            centerPadding: "10%",
          },
        },
        {
          breakpoint: 300,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            dots: true,
            centerPadding: "8%",
          },
        },
      ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <CoralCard />
        </div>
        <div>
          <CoralCard />
        </div>
        <div>
          <CoralCard />
        </div>
        <div>
          <CoralCard />
        </div>
      </Slider>
    </>
  );
}

export default CoralSlider;
