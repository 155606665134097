import box from "../assets/office-building.png";
import React, { useContext, useEffect, useState } from "react";
import "./dashboard.css";
import chat from "../assets/chat-track.svg";
import Header from "../components/header/Header";
import Sidebar from "../components/sideBar/Sidebar";
import SideBarContext from "../context/SidebarContext";
import MobileContext from "../context/mobile/MobileContext";
import AllModalContext from "../context/allModal/AllModalContext";
import ChatOpenSlider from "../components/OpenSlider/ChatOpenSlider";
import ChatCloseSlider from "../components/OpenSlider/ChatCloseSlider";
import MobileMenu from "../components/mobileMenu/MobileMenu";
import jobIcon from "../assets/job-icon.svg";
import top from "../assets/top-arrow.svg";
import savedImg from "../assets/saved-application.svg";
import ChatModal from "./ChatModal";
import { Link, useNavigate } from "react-router-dom";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";

function OpenMobileCard({ interviewer }) {
  const navigate = useNavigate();

  function OpenChat() {
    navigate("/dashboard/chat");
  }
  return (
    <>
      {interviewer?.length > 0 &&
        interviewer?.map((item, index) => (
          <div key={index} className="open-mobile-card">
            <div className="d-flex align-items-center">
              <img src={box} className="me-2" alt="" style={{width:"45px", height:"45px"}}/>
              <div>
                <h6 className="open-mobile-card-title m-0">
                  {item?.receiver?.jobInfo?.position}
                </h6>
                <p className="open-mobile-card-text m-0">
                  {item?.receiver?.companyName}
                </p>
              </div>
            </div>
            <div className="open-mobile-card-border"></div>
            <ul className="ps-4 m-0">
              <li className="open-card-item">
                {item?.receiver?.jobInfo?.workPlace}
              </li>
              {/* <li className="open-card-item">Start Up</li> */}
              <li className="open-card-item">
                {item?.receiver?.jobInfo?.jobType}
              </li>
              <li className="open-card-item">
                {item?.receiver?.jobInfo?.workPlaceType}
              </li>
              <li className="open-card-item">
                {item?.receiver?.jobInfo?.candidateJoining}
              </li>
            </ul>
            {/* <div className="d-flex align-items-center">
              <p className="open-mobile-card-item m-0">Delhi</p>
              <div className="open-mobile-card-dot"></div>
              <p className="open-mobile-card-item m-0">Start up</p>
              <div className="open-mobile-card-dot"></div>
              <p className="open-mobile-card-item m-0">Full-time</p>
              <div className="open-mobile-card-dot"></div>
              <p className="open-mobile-card-item m-0">Immediately</p>
            </div> */}
            <Link
              to={"/dashboard/chat"}
              state={{
                data: {
                  interviewerId: item?.receiver?._id,
                  name: item?.receiver?.jobInfo?.position,
                  company: item?.receiver?.companyName,
                },
              }}
              className="chat-mobile-btn"
            >
              <img src={chat} style={{ marginRight: "4px" }} alt="" />
              <h6 className="chat-btn-text m-0">Chat with HR</h6>
            </Link>
          </div>
        ))}
    </>
  );
}

function ChatPage() {
  const navigate =useNavigate()
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const modal = useContext(AllModalContext);
  const sidebar = useContext(SideBarContext);
  const menuItem = useContext(MobileContext);
  const [interviewer, setInterviewer] = useState([]);
  const [loader, setLoader] = useState(false);

  const { toggleModal } = modal;
  const { isSidebarOpen } = sidebar;
  const { handleMobileToggle, mobileMenu } = menuItem;

  const userId = localStorage.getItem("userId");
  const { chatRoomChats } = useMain();

  useEffect(() => {
    if (userId) {
      getAllChats(userId);
    }
  }, []);

  const getAllChats = async (userId) => {
    setLoader(true);
    const result = await chatRoomChats(userId, "interviewer");
    if (result?.statusCode === 200) {
      if (result?.statusCode === 200) {
        setInterviewer(result?.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    }
  };

  return (
    <>
      <Header />
      {loader && <Loader />}
      <section className="dashboard">
        <div className="dashboard-box">
          <div className="sidebar-scroll">
            <Sidebar />
          </div>
          <div
            className="desktop-show-hide chat-container"
            style={{ left: isSidebarOpen ? "85px" : "354px" }}
          >
            <div className="chat-box">
              <h6 className="chat-title">Open Chats</h6>
              {interviewer?.length === 0 ? (
                <div className="unsaved-image">
                  <img src={savedImg} alt="" />
                  <h6 className="unsaved-text m-0">No Open Chat</h6>
                </div>
              ) : (
                <ChatOpenSlider interviewer={interviewer} />
              )}
            </div>
            {/* <div className="close-box">
              <h6 className="close-title">Closed Chats</h6>
              <ChatCloseSlider toggleModal={toggleModal} />
              <ChatCloseSlider toggleModal={toggleModal} />
            </div> */}
          </div>

          {/* mobile */}
          <div className="mobile-show-hide mobile-recommended">
            <h6 className="chat-mobile-title">Chat Rooms</h6>
            <p className="chat-mobile-text m-0">Open Chats</p>
            {interviewer?.length === 0 ? (
              <div className="unsaved-image">
                <img src={savedImg} alt="" />
                <h6 className="unsaved-text m-0">No Open Chat</h6>
              </div>
            ) : (
              <OpenMobileCard interviewer={interviewer} />
            )}
            {/* <OpenMobileCard toggleModal={toggleModal} /> */}
            {/* <p className="chat-mobile-text m-0" style={{ paddingTop: "8px" }}>
              Closed Chats
            </p>
            {interviewer?.length === 0 ? (
              <div className="unsaved-image">
                <img src={savedImg} alt="" />
                <h6 className="unsaved-text m-0">No Close Chat</h6>
              </div>
            ) : (
              <OpenMobileCard interviewer={interviewer} />
            )} */}

            <MobileMenu />
            <div className="mobile-menu-btn" onClick={handleMobileToggle}>
              <img
                src={top}
                alt=""
                style={{
                  transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "transform 150ms ease",
                  height: "14px",
                }}
              />
              <img src={jobIcon} alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <ChatModal /> */}
    </>
  );
}

export default ChatPage;
