import { GoogleLogin } from "@react-oauth/google";
import React, { useState } from "react";
import { useMain } from "../../hook/useMain";
import { useNavigate } from "react-router";
import { jwtDecode } from "jwt-decode";
import { emailRegex } from "../regexPatterns/RegexPatterns";
import { Link } from "react-router-dom";
import arrow from "../../assets/login/left-arrow.svg";
import hoverArrow from "../../assets/login/left-arrow-hover.svg";
import "./css/login.css";
import Loader from "../loader/Loader";
import CareerHead from "../header/CareerHead";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";

export const CareerLogin = () => {
  const [showArrow, setShowArrow] = useState(false);
  const [formdata, setFormdata] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const navigate = useNavigate();
  const { CarrerSignIn } = useMain();

  const hendleSignin = async (e) => {
    e.preventDefault();

    if (formdata?.email === "" || !emailRegex.test(formdata?.email)) {
      setEmailError("email is required");
      return;
    }

    if (formdata?.password === "" || formdata?.password.length < 6) {
      setPasswordError("password is required");
      // alert("Password must be 6 characters");
      return;
    }

    if (formdata?.email && formdata?.password) {
      setLoader(true);

      const result = await CarrerSignIn(formdata);

      if (result?.statusCode === 200) {
        toast.success("Login successful.");
        localStorage.setItem("career_token", result?.data?.token);
        localStorage.setItem(
          "career_data",
          JSON.stringify(result?.data?.counselor)
        );
        localStorage.setItem("career_id", result?.data?.counselor?._id);
        setLoader(false);
        navigate("/career/table");
      } else {
        toast.error("Login failed.");
        setLoader(false);
      }
    } else {
      toast.error("Please try again after some time.");
      setLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
    if (name === "email") {
      if (value === "") {
        setEmailError("email is required");
      } else {
        setEmailError(null);
      }
    }
    if (name === "password") {
      if (value === "") {
        setPasswordError("password is required");
      } else {
        setPasswordError(null);
      }
    }
  };

  //   const googleLogin = async (data) => {
  //     if (Radio === "") {
  //       alert("Please select Profile.");
  //       return;
  //     }

  //     if (Radio === "Recruiter") {
  //       setLoader(true);
  //       const decode = jwtDecode(data?.credential);
  //       if (decode?.email_verified) {
  //         const result = await googleRecruierLogin({
  //           email: decode?.email,
  //           name: decode?.name,
  //         });
  //         if (result?.statusCode === 200) {
  //           setLoader(false);
  //           navigate("/recruiter-dashboard");
  //           localStorage.setItem("recruiteId", result?.data?.recruiter?._id);
  //           localStorage.setItem(
  //             "recruiterData",
  //             JSON.stringify(result?.data?.recruiter)
  //           );
  //           localStorage.setItem("recruiterToken", result?.data?.token);
  //         } else {
  //           alert("Somthing went wrong");
  //           setLoader(false);
  //         }
  //       }
  //     }

  //     if (Radio === "Job Seeker") {
  //       setLoader(true);
  //       const decode = jwtDecode(data?.credential);
  //       if (decode?.email_verified) {
  //         const result = await googleSeekLogin({
  //           email: decode?.email,
  //           name: decode?.name,
  //         });
  //         if (result?.statusCode === 200) {
  //           setLoader(false);
  //           navigate("/dashboard");
  //           localStorage.setItem("userToken", result?.data?.tokens?.accessToken);
  //           localStorage.setItem("userId", result?.data?.user?._id);
  //           localStorage.setItem("userData", JSON.stringify(result?.data?.user));
  //           localStorage.setItem("userName", JSON.stringify(result?.data?.name));
  //         } else {
  //           alert("Somthing went wrong");
  //           setLoader(false);
  //         }
  //       }
  //     }
  //   };

  return (
    <>
    <AlertContainer />
    <CareerHead link="/career/sign-up" />
      {loader ? (
        <Loader />
      ) : (
        <section>
          <div className="login-hero">
            <div className="sign-up m-0">
              <div className="text-center">
                <h6 className="sign-title">Sign in</h6>
                <p className="sign-text">
                  Sign in to your account for endless possibilities.
                </p>
                {/* <div className="d-flex w-100 justify-content-center">
                  <GoogleLogin
                    //   onSuccess={(credentialResponse) => {
                    //     googleLogin(credentialResponse);
                    //   }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div> */}
                <div
                  style={{
                    borderBottom: "1px solid #C7D2FE",
                    margin: "12px 0",
                  }}
                ></div>
                {/* <div className="row pt-2 mb-2">
            <div className="col-6">
              <div className="radio-button">
                <input
                  type="radio"
                  className="radio-button__input"
                  id="radio1"
                  onChange={(e) => setRadio(e.target.value)}
                  value="Job Seeker"
                  name="radio-group"
                />
                <label className="radio-button__label" htmlFor="radio1">
                  <span className="radio-button__custom"></span>
                  For Job Seeker
                </label>
              </div>
            </div>
            <div className="col-6">
              <div className="radio-button">
                <input
                  type="radio"
                  className="radio-button__input"
                  value="Recruiter"
                  onChange={(e) => setRadio(e.target.value)}
                  id="radio2"
                  name="radio-group"
                />
                <label className="radio-button__label" htmlFor="radio2">
                  <span className="radio-button__custom"></span>
                  For Recruiter
                </label>
              </div>
            </div>
          </div> */}
                <form>
                  <div>
                    <input
                      type="email"
                      placeholder="Email address"
                      className={
                        emailError === null ? "sign-input" : "sign-input-error"
                      }
                      name="email"
                      value={formdata?.email}
                      onChange={handleChange}
                    />
                    {emailError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{emailError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="password"
                      placeholder="Password"
                      className={
                        passwordError === null
                          ? "sign-input"
                          : "sign-input-error"
                      }
                      name="password"
                      value={formdata?.password}
                      onChange={handleChange}
                    />
                    {passwordError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{passwordError}</p>
                    )}
                  </div>
                  <Link to="/career/forget" className="forget-text">
                    Forgot password?
                  </Link>
                  <button
                    className="sign-up-btn"
                    onMouseEnter={() => setShowArrow(true)}
                    onMouseLeave={() => setShowArrow(false)}
                    onClick={hendleSignin}
                  >
                    Sign in
                    <img
                      src={showArrow ? hoverArrow : arrow}
                      style={{ marginLeft: "4px" }}
                      alt=""
                    />
                  </button>
                  <p className="link-btn m-0">
                    Don’t have an account?
                    <Link to={"/career/sign-up"}>
                      <span>Sign-up</span>
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
