import React from "react";
import './recruiter.css';
import Slider from "react-slick";
import Upcoming from "../../assets/recruiter-dashboard/upcoming.svg";
import RightArrow from "../../assets/recruiter-dashboard/right-arrow.svg";
import SubscribeSlider from "../subscribeSlider/SubscribeYearly";
import box from "../../assets/calender/box.svg";
import CourseSlider from "../courseSlider/CourseSlider";
import ArticleSlider from "../courseSlider/ArticlesSlider";

function ChartSlider() {
  var OpenApp = 23;
  var ClosedApp = 10;
  var InProgress = 650;
  var Rejected = 300;
  var Selected = 50;
  const chartTotal = OpenApp + ClosedApp;
  const TotalApp = InProgress + Rejected + Selected;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "8%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "2%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <div className="chart-box">
            <h6 className="chart-title">Analysis on Applications</h6>
            <div className="d-flex align-items-center">
              <div
                className="pie-chart"
                style={{
                  background: `conic-gradient(#026D92 0 70%, #CBD5E1 0 30%)`,
                }}
              >
                <div className="pie-total">10</div>
                <div className="pie-closed">23</div>
                <div className="chart-total">{chartTotal}</div>
              </div>
              <div className="chart-text" style={{ marginLeft: "32px" }}>
                <div className="total-box">
                  <p className="box"></p> <p> Total Applications</p>
                </div>
                <div className="open-box">
                  <p className="box"></p> <p>Open Applications</p>
                </div>
                <div className="closed-box">
                  <p className="box"></p> <p>Closed Applications</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="chart-box">
            <h6 className="chart-title">Analysis on Applicants</h6>
            <div className="d-flex align-items-center">
              <div
                className="pie-chart-2"
                style={{
                  background: `conic-gradient(#026D92 0 50%, #FFEAD7 0 70%, #CBD5E1 0 )`,
                }}
              >
                <div className="pie-total-2">650</div>
                <div className="pie-closed-2">300</div>
                <div className="pie-process-2">4</div>
                <div className="chart-total">{TotalApp}</div>
              </div>
              <div className="chart-text" style={{ marginLeft: "32px" }}>
                <div className="total-box">
                  <p className="box"></p> <p> Total Applications</p>
                </div>
                <div className="open-box">
                  <p className="box"></p> <p>In-progress Applicants</p>
                </div>
                <div className="closed-box">
                  <p className="box"></p> <p>Rejected Applicants</p>
                </div>
                <div className="selected-box">
                  <p className="box"></p> <p>Selected Applicants</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}
function UpcomingCard() {
  return (
    <>
      <div className="upcoming-card">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-start" style={{ gap: "8px" }}>
            <img src={Upcoming} alt="" />
            <div className="d-flex flex-column" style={{ gap: "2px" }}>
              <h6 className="upcoming-title m-0">Harry James</h6>
              <p className="upcoming-category m-0">Human Resource</p>
              <p className="upcoming-date m-0">12th Oct, 2023 | 12:30pm</p>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <img src={RightArrow} alt="" />
            <p className="m-0 upcoming-date"> By Me</p>
          </div>
        </div>
      </div>
    </>
  );
}
function UpcomingSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <UpcomingCard />
        </div>
        <div>
          <UpcomingCard />
        </div>
        <div>
          <UpcomingCard />
        </div>
        <div>
          <UpcomingCard />
        </div>
        <div>
          <UpcomingCard />
        </div>
        <div>
          <UpcomingCard />
        </div>
      </Slider>
    </>
  );
}
function InterviewCard() {
  return (
    <>
      <div className="interview-mobile-box">
        <h2 className="acc-title">Pranav Kumar</h2>
        <div className="acc-box">
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 interview-text">Open applications</p>
            <p className="m-0 interview-text-number">1200</p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 interview-text">Open applications</p>
            <p className="m-0 interview-text-number">1200</p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 interview-text">Open applications</p>
            <p className="m-0 interview-text-number">1200</p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 interview-text">Open applications</p>
            <p className="m-0 interview-text-number">1200</p>
          </div>
        </div>
      </div>
    </>
  );
}
function InterviewSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "17%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <div className="interviewers">
        <div className="d-flex align-items-center w-100 justify-content-between">
          <h6 className="interviewers-title">Interviewers</h6>
          <h6 className="interviewers-title">8</h6>
        </div>
        <div
          style={{ margin: "8px 0", borderBottom: "1px solid #E2E8F0" }}
        ></div>
        <Slider {...settings}>
          <div>
            <InterviewCard />
          </div>
          <div>
            <InterviewCard />
          </div>
          <div>
            <InterviewCard />
          </div>
          <div>
            <InterviewCard />
          </div>
        </Slider>
      </div>
    </>
  );
}
function DraftCard({ title }) {
  return (
    <>
      <div className="draft-card">
        <div className="d-flex align-items-start">
          <img src={box} alt="" style={{ marginRight: "8px" }} />
          <div className="w-100" style={{height:"50px"}}>
            <h2 className="draft-card-title">{title}</h2>
            <div className="d-flex justify-content-between align-items-center">
              <p className="draft-card-text">product</p>
              <p className="draft-days">2 days ago</p>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
        <ul className="draft-list">
          <li>Full-time · Entry level</li>
          <li>11-50 employees · IT Services and IT Consulting</li>
          <li>See how you compare to 843 applicants.</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
        </ul>
        <div className="draft-edit-btn">Edit</div>
      </div>
    </>
  );
}

function DraftSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "7%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "2%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <DraftCard title={"Junior UX and UI designer"} />
        </div>
        <div>
          <DraftCard title={"Senior UX Researcher"} />
        </div>
        <div>
          <DraftCard title={"Senior UX Researcher"} />
        </div>
        <div>
          <DraftCard title={"Senior UX Researcher"} />
        </div>
      </Slider>
    </>
  );
}

function DummyMobileHome() {
  return (
    <>
      <div className="recruiter-chart">
        <ChartSlider />
      </div>
      <div className="recruiter-dashboard">
        <div className="recruiter-calender">
          <div className="calender-">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h6 className="calender-box-title p-0">My July Calendar</h6>
              <button className="calender-btn">Month</button>
            </div>
          </div>
          <div className="upcoming">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h6 className="upcoming-box-title p-0">Upcoming Interviewes</h6>
              <button className="view-all-btn-recruiter">View All</button>
            </div>
            <div className="upcoming-slider">
              <UpcomingSlider />
            </div>
          </div>
          <div className="interview-slider">
            <InterviewSlider />
          </div>
        </div>
      </div>
      <div className="subscribe-box">
        <div
          className="d-flex w-100 align-items-center justify-content-between"
          style={{ padding: "0 20px", marginBottom: "16px" }}
        >
          <h6 className="title">Subscribe Now</h6>
          <div className="d-flex flex-row" style={{ gap: "8px" }}>
            <div className="radio-button">
              <input
                type="radio"
                className="radio-button__input"
                id="radio1"
                name="date"
              />
              <label className="radio-title m-0" htmlFor="radio1">
                <span
                  className="radio-button__custom"
                  style={{ top: "2px" }}
                ></span>
                Monthly
              </label>
            </div>
            <div className="radio-button">
              <input
                type="radio"
                className="radio-button__input"
                id="radio2"
                name="date"
              />
              <label className="radio-title m-0" htmlFor="radio2">
                <span
                  className="radio-button__custom"
                  style={{ top: "2px" }}
                ></span>
                Yearly
              </label>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "20px" }} className="subscribe-slider">
          <SubscribeSlider />
        </div>
      </div>
      <div style={{ background: "#F1F5F9", padding: "8px 0" }}>
        <div className="recruiter-draft">
          <div
            style={{ marginBottom: "12px" }}
            className="d-flex justify-content-between w-100 align-items-center"
          >
            <h6
              style={{
                color: "#000",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "20px",
                margin: 0,
              }}
            >
              Draft
            </h6>
            <button className="view-all-btn-recruiter">View All</button>
          </div>
          <DraftSlider />
        </div>
      </div>
      <div className="course-container">
        <h6 className="course-title">Recommended Courses</h6>
        <CourseSlider />
      </div>
      <div style={{ background: "#F1F5F9", padding: "0 0 17px 0" }}>
      <div className="article-container">
        <h6 className="article-title">Articles and Blogs</h6>
        <ArticleSlider />
      </div>
      </div>
    </>
  );
}

export default DummyMobileHome;
