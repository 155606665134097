import React from "react";
import "./dashboard.css";
import leftArrow from "../assets/left-arrow.svg";
import course1 from "../assets/cours/course-1.png";
import course2 from "../assets/cours/course-2.png";
import course3 from "../assets/cours/course-3.png";
import course4 from "../assets/cours/course-4.png";
import course5 from "../assets/cours/course-5.png";
import course6 from "../assets/cours/course-6.png";
import course7 from "../assets/cours/course-7.png";
import course8 from "../assets/cours/course-8.png";
import course9 from "../assets/cours/course-9.png";
import course10 from "../assets/cours/course-10.png";
import course11 from "../assets/cours/course-11.png";
import course12 from "../assets/cours/course-12.png";
import course13 from "../assets/cours/course-13.png";
import course14 from "../assets/cours/course-14.png";
import course15 from "../assets/cours/course-15.png";
import course16 from "../assets/cours/course-16.png";
import course17 from "../assets/cours/course-17.png";
import course18 from "../assets/cours/course-18.png";
import course19 from "../assets/cours/course-19.png";
import course20 from "../assets/cours/course-20.png";
import { Link } from "react-router-dom";

function InformationCard({ path, text1, text2, cardImg, title }) {
  return (
    <>
      <div className="up-skill-card">
      <div className="d-flex justify-content-between">
        <button className="course-btn">Certification</button>
        <p className="course-card-hour">10 Hour</p>
      </div>
        <img src={cardImg} className="img-fluid course-img" alt="" />
        <h6 className="course-content-title">{title}</h6>
        <p className="m-0 course-content-text">Unschool</p>
        <div className="course-border"></div>
        <p className=" course-fee">Course Fee</p>
        <ul className="course-list">
          <li>{text1}</li>
          <li>{text2.slice(0, 50) + "..."}</li>
        </ul>
        <Link to={path} style={{ textDecoration: "none" }}>
          <button className="view-more-btn p-0">
            Learn more <img src={leftArrow} className="img-fluid" alt="" />
          </button>
        </Link>
      </div>
    </>
  );
}
function UpSkillSlider() {
  return (
    <>
      <div className="recommended-container" style={{gap: "16px"}}>
        <InformationCard
          path={"https://bit.ly/40e3brx"}
          cardImg={course1}
          title="Advanced Full Stack Development Certification"
          text1="Learn how to work with HTML, CSS3 and JavaScript (ES6)"
          text2="  With the help of minor and major projects, learn how to design a
            beautiful UI using Bootstrap 4..."
        />
        <InformationCard
          path={"https://bit.ly/3tRMYfu"}
          cardImg={course2}
          title="Basic Java and Python Certification"
          text1="Learn Java & Python Programming from the basics
Develop a complete understanding of control flow"
          text2="Learn Java & Python Programming from the basics
Develop..."
        />
        <InformationCard
          path={"https://bit.ly/3Q97PTc"}
          cardImg={course3}
          title="Basic Digital Marketing Certification"
          text1="The course allows you to gain a detailed understanding of social media marketing"
          text2="Learn about concepts like Search Engine Optimisation (SEO) through the medium of projects and expert videos.."
        />
        <InformationCard
          path={"https://bit.ly/4956JjX"}
          cardImg={course4}
          title="Advanced Artificial Intelligence Certification"
          text1="Learn the basics of Artificial Intelligence works and how different AI system works."
          text2="Implement an algorithm in code
Organize your code and orchestration different tools and utilities."
        />
        <InformationCard
          path={"https://bit.ly/3FwBp04"}
          cardImg={course5}
          title="Advanced Microsoft Excel Certification"
          text1="You will learn everything about the software from the bottom-up"
          text2="This course will enable you to create attractive graphical representations of your data."
        />
        <InformationCard
          path={"https://bit.ly/3Fx9tsK"}
          cardImg={course6}
          title="Advanced Python Certification"
          text1="Improves Python skills in the students
Students will be able to implement the python concepts after finishing course"
          text2="Develop frameworks from scratch
Grab excellent career opportunities after going through this course"
        />
        <InformationCard
          path={"https://bit.ly/40dCm74"}
          cardImg={course7}
          title="Basic Data Science Certification"
          text1="Learn from renowned industry experts"
          text2="Explore the various career opportunities that await you in Data Science
Gain exposure to key technologies such as Python, Machine Learning tools & techniques, etc."
        />
        <InformationCard
          path={"https://bit.ly/3QvMV1R"}
          cardImg={course8}
          title="Basic Stock Market and Finance Certification"
          text1="You will develop an insight into the manner in which Stock Markets function"
          text2="Get a chance to learn the master tricks in trading and investment from experts in the field"
        />
        <InformationCard
          path={"https://bit.ly/460lemB"}
          cardImg={course9}
          title="Basic AWS Cloud computing Certification"
          text1="Learn skills to become a cloud architect"
          text2="Gain knowledge about the ins and outs of the workings of cloud computing
Build applications by using AWS- a leader in computing technology"
        />
        <InformationCard
          path={"https://bit.ly/3s6ZEyK"}
          cardImg={course10}
          title="Basic Business Management Certification"
          text1="Understand Sales and Marketing for New Business Ventures"
          text2="Get to know Business Innovation: Best Practices in Business Development
Contemporary Leadership Challenges & Risk Management"
        />
        <InformationCard
          path={"https://bit.ly/3s6ZEyK"}
          cardImg={course11}
          title="Basic C & C++ Language Certification"
          text1="Write and debug codes in the most popular coding languages
Write your"
          text2="Develop a career in C & C++
The online course allows you to interact with and learn from professionals in the field"
        />
        <InformationCard
          path={"https://bit.ly/475vlqU"}
          cardImg={course12}
          title="Advanced Stock Market & Personal Finance Certification"
          text1="Interested in becoming savvy stock traders
Get the confidence to trade "
          text2="Best return on your investment in a stock trading class
Offers a quality online stock trading course"
        />
        <InformationCard
          path={"https://bit.ly/3Q7bWz7"}
          cardImg={course13}
          title="Basic Graphic Designing Certification"
          text1="Gain knowledge of the various design tools available online and how "
          text2="Learn how to use platforms such as Adobe Photoshop, Powerpoint, Keynote, and Canva to create mind-blowing digital designs"
        />
        <InformationCard
          path={"https://bit.ly/46Y9gv3"}
          cardImg={course14}
          title="Basic Web Development Certification"
          text1="Learning how to develop a complete website is good to do as it allows "
          text2="Being creative which provides everything, work to become specialised in your skill."
        />
        <InformationCard
          path={"https://bit.ly/3rXNA2T"}
          cardImg={course15}
          title="Basic Ethical Hacking Certification "
          text1="Learn through an experiential model where theory and practice go hand "
          text2="Get equipped with skills that allow you to hack systems and secure them!
All the modules are designed and taught by industry experts"
        />
        <InformationCard
          path={"https://bit.ly/49iQKiu"}
          cardImg={course16}
          title="Basic Marketing Management Certification"
          text1="Get the opportunity to learn from global experts
Gain a comprehensive "
          text2="Acquire skills to build a market-oriented business
Gain the opportunity to intern in leading organizations"
        />
        <InformationCard
          path={"https://bit.ly/494t6WP"}
          cardImg={course17}
          title="Advanced Data Science Certification"
          text1="Help organizations forecast product and service demands for the future
An opportunity for you to recreate yourself."
          text2="Upskill yourself in statistics and computer science who can write, speak publicly, and interact well with other people.
Ability to process and interpret data and convert it into a useful resource."
        />
        <InformationCard
          path={"https://bit.ly/491k0Kx"}
          cardImg={course18}
          title="Advanced Marketing Management Certification"
          text1="How to perform a management role in marketing or brand"
          text2="What strategy is in the context of marketing and branding, and how to think and act strategically"
        />
        <InformationCard
          path={"https://bit.ly/3Q6xj3D"}
          cardImg={course19}
          title="Basic Soft Skills Certification"
          text1="Develop written and verbal skills that help you excel in communicating with"
          text2="Gain an understanding of conflict resolution
Get guidance from industry experts for interview success secrets"
        />
        <InformationCard
          path={"https://bit.ly/40a4oAc"}
          cardImg={course20}
          title="Basic Content Writing Certification"
          text1="Online course on content writing will help you understand the basics of content writing"
          text2="Learn how to set your content apart from the rest
Gain guidance on how to build your career as a professional content writer"
        />
      </div>
    </>
  );
}

export default UpSkillSlider;
