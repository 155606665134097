import React, { useContext, useState } from "react";
// import "./recruiter.css";
// import Header from "../header/Header";
// import SubHeader from "../subHeader/SubHeader";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import Loader from "../loader/Loader";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import RecruiterHeader from "../header/RecruiterHeader";
import Coming from "../../pages/Coming";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import InterviewerHeader2 from "../header/InterviewerHeader2";
import InterviewHeader from "../subHeader/InterviewHeader";
import InterviewSubMenu from "../mobileMenu/InterviewSubMenu";
import MobileContext from "../../context/mobile/MobileContext";

const events = [];
function InCalender() {
  const menuItem = useContext(MobileContext);
  const { handleRecruiterToggle, recruiterMenu } = menuItem;
  const [loader, setLoader] = useState(false);
  return (
    <>
      <AlertContainer />
      <InterviewerHeader2 />
      <div className="desktop-show-hide">
        <InterviewHeader />
      </div>
      <div className="mobile-show-hide">
        <InterviewSubMenu />
        <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
          <img
            src={top}
            alt=""
            style={{
              transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 150ms ease",
              height: "14px",
            }}
          />
          <img src={jobIcon} style={{ height: "24px" }} alt="" />
        </div>
      </div>
      {loader ? <Loader /> : <Coming />}
      {/* <div className="recruiter-container">
          <div className="new-job-container">
          <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                weekends={true}
                events={events} />
          </div>
        </div> */}
    </>
  );
}

export default InCalender;
