import React, { useEffect, useState } from "react";
import arrow from "../../assets/left-arrow.svg";
import arrowHover from "../../assets/our/right-arrow-btn.svg";
import { useNavigate } from "react-router-dom";
import FormHeader from "../../components/header/FormHeader";
import { useMain } from "../../hook/useMain";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import RecruiterHeader from "../header/RecruiterHeader";

function RecruiterComplete() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [dashHover, setDashHover] = useState(false);
  function handleOnClick() {
    navigate("/recruiter-dashboard");
  }

  const { recruiterSendMail } = useMain();

  useEffect(() => {
    const id = localStorage.getItem("recruiteId");
    if (id) {
      // sendMessage(id);
    }
  }, []);

  const sendMessage = async (id) => {
    const resutl = await recruiterSendMail(id);

    if (resutl?.statusCode === 200) {
      // toast.success(resutl?.data);
    }
  };

  return (
    <>
      <AlertContainer/>
      <RecruiterHeader title="Build Profile" />
      <section className="complete-section">
        <div className="complete-box">
          <div className="wrapper"></div>
          <div>
            <h2 className="complete-title">Yaay!</h2>
            <p className="m-0 complete-text">
              Your profile is complete. <br />
              Let’s get started on getting the right candidates
            </p>
          </div>
          <button
            onMouseEnter={() => setDashHover(true)}
            onMouseLeave={() => setDashHover(false)}
            className="dashboard-btn d-flex align-items-center justify-content-center"
            onClick={handleOnClick}
          >
            Dashboard{" "}
            <img
              src={dashHover ? arrowHover : arrow}
              style={{ marginLeft: "8px", height: "16px" }}
              alt=""
            />
          </button>
        </div>
      </section>
    </>
  );
}

export default RecruiterComplete;
