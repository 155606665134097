import React, { useContext, useEffect, useState } from "react";
import './recruiter.css';
import closeBtn from "../../assets/close-bt.svg";
import close from "../../assets/close-btn.svg";
import ShareIcon from "../../assets/recruiter/share-icon.svg";
import downArrow from "../../assets/select-down.svg";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
import AllModalContext from "../../context/allModal/AllModalContext";

const JobPre = [
  {
    id: 1,
    option: "Internship",
    selected: false,
  },
  {
    id: 2,
    option: "Full-Time",
    selected: false,
  },
  {
    id: 3,
    option: "Part-Time",
    selected: false,
  },
  {
    id: 4,
    option: "Remote",
    selected: false,
  },
  {
    id: 5,
    option: "Hybrid",
    selected: false,
  },
];
function AssignModal() {
  const modal = useContext(AllModalContext);
  const { toggleAssign, assignModal } = modal;
  const initialValue = {
    subcategories: [],
    job: [],
  };
  const [formData, setFormData] = useState(initialValue);
  const [selected, setIsSelected] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [hover1, setHover1] = useState(false);
  useEffect(() => {
    if (selected.trim() !== "") {
      if (!formData?.subcategories?.includes(selected))
        setFormData({
          ...formData,
          subcategories: [...formData?.subcategories, selected],
        });
    }
  }, [selected]);
  const handleDeleteSubcategory = (item) => {
    const subcategories = formData?.subcategories?.filter(
      (s, id) => id !== item
    );
    setFormData({ ...formData, subcategories });
  };
  if (assignModal) {
    return (
      <>
        <div className="assign-modal-bg">
          <div className="assign-modal">
            <button className="close-btn" onClick={toggleAssign}>
              <img src={closeBtn} alt="" />
            </button>
            <h6 className="assign-modal-title">Assign Application</h6>
            <p className="assign-modal-text">
              Send interview collaborations to your colleagues for the job
              posting
            </p>
            <div className="d-flex flex-column">
              <label htmlFor="" className="assign-job-label">
                Job Posting
              </label>
              <div
                className="dropdown"
                onMouseEnter={() => setHover1(true)}
                onMouseLeave={() => setHover1(false)}
                style={{ marginBottom: "8px" }}
              >
                <div
                  onClick={(e) => {
                    setIsActive(!isActive);
                  }}
                  style={{ outline: isActive ? "2px solid #94A3B8" : "none" }}
                  className="dropdown-btn"
                >
                  Choose Job Posting
                  <img src={hover1 ? downArrowHover : downArrow} alt="" />
                </div>
                <div
                  className="dropdown-content"
                  style={{ display: isActive ? "block" : "none" }}
                >
                  {JobPre.map((element, index) => {
                    return (
                      <div
                        onClick={(e) => {
                          setIsSelected(e.target.textContent);
                          setIsActive(!isActive);
                        }}
                        className="item"
                      >
                        {element.option}
                      </div>
                    );
                  })}
                </div>
              </div>
              {formData?.subcategories?.length > 0 && (
                <div className="d-flex">
                  {formData?.subcategories?.map((item, index) => (
                    <p className="select-option" key={index}>
                      {item}
                      <img
                        src={close}
                        style={{ marginLeft: "4px" }}
                        alt=""
                        onClick={() => handleDeleteSubcategory(index)}
                      />
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="assign-modal-btn">
              <button className="remove-modal-btn">
                Send Invite{" "}
                <img style={{ marginLeft: "6px" }} src={ShareIcon} alt="" />
              </button>
              <button className="no-modal-btn">Back </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
}

export default AssignModal;
