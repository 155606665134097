import React, { useEffect, useRef, useState } from "react";
import info from "../../assets/information.svg";
import plush from "../../assets/plush.svg";
import "./resume.css";
import Delete from "../../assets/delete.svg";
// import close from "../../assets/x.svg";
// import done from "../../assets/done.svg";
import downArrow from "../../assets/mobile/down-arrow.svg";
// import FormHeader from "../../components/header/FormHeader";
import CalenderImg from "../../assets/calender/calender.svg";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
// import uploadBtn from "../../assets/calender/upload.svg";
import closeBtn from "../../assets/close-bt.svg";
import { State } from "country-state-city";
import Calendar from "react-calendar";
import moment from "moment/moment";
import Fuse from "fuse.js";
import linkImg from "./img/link.svg";
// import { useNavigate } from "react-router-dom";
// import { useDropzone } from "react-dropzone";
// import { Grid, TextField } from "@mui/material";

const selectItem = [
  {
    id: 1,
    option: "Employed",
    selected: false,
  },
  {
    id: 2,
    option: "Serving Notice Period",
    selected: false,
  },
  {
    id: 3,
    option: "Unemployed",
    selected: false,
  },
  {
    id: 4,
    option: "Working Part-time",
    selected: false,
  },
  {
    id: 5,
    option: "Student",
    selected: false,
  },
];
// const selectItemTwo = [
//   {
//     id: 1,
//     option: "Married",
//     selected: false,
//   },
//   {
//     id: 2,
//     option: "Single",
//     selected: false,
//   },
//   {
//     id: 3,
//     option: "Divorced",
//     selected: false,
//   },
// ];
const selectItemThree = [
  {
    id: 1,
    option: "HSC",
    selected: false,
  },
  {
    id: 2,
    option: "SSC",
    selected: false,
  },
  {
    id: 3,
    option: "Bachelors",
    selected: false,
  },
  {
    id: 4,
    option: "Masters",
    selected: false,
  },
  {
    id: 5,
    option: "Post Graduate",
    selected: false,
  },
  {
    id: 6,
    option: "PGDM",
    selected: false,
  },
];
// const cgp = [
//   {
//     id: 1,
//     option: "CGPA",
//     selected: false,
//   },
//   {
//     id: 2,
//     option: "Percentage",
//     selected: false,
//   },
// ];
// const selectLanguage = [
//   {
//     id: 1,
//     option: "Hindi",
//     selected: false,
//   },
//   {
//     id: 2,
//     option: "English",
//     selected: false,
//   },
//   {
//     id: 3,
//     option: "Gujrati",
//     selected: false,
//   },
//   {
//     id: 4,
//     option: "Marathi",
//     selected: false,
//   },
// ];

function InputField({ handleChangeupdate }) {
  const InitialValues = {
    phoneNumber: "",
    subcategories: [],
    skills: [],
    image: null,
    fullname: "",
    email: "",
    dob: "",
    city: "",
    workingStatus: "",
    marritalStatus: "",
    designation: "",
    summary: "",
  };

  const [formData, setFormData] = useState(InitialValues);
  const [social, setSocial] = useState({
    linkedin: "",
    website: "",
    portfolio: "",
    other: "",
  });
  const [dateBirth, setDateBirth] = useState("");
  const [dateModal, setDateModal] = useState(false);
  var birthDate = moment(dateBirth).format("DD/MM/YYYY");
  function handleDateOpen() {
    setDateModal(!dateModal);
  }
  useEffect(() => {
    if (dateBirth.length != 0) {
      const dobformat = moment(dateBirth).format("DD/MM/YYYY");
      setFormData({ ...formData, dob: dobformat });
      localStorage.setItem(
        "resumeForm",
        JSON.stringify({ ...formData, dob: dobformat })
      );
      handleChangeupdate();
    }
  }, [dateBirth]);
  // useEffect(()=>{
  //   localStorage.setItem("resumeForm", JSON.stringify(formData))
  //   handleChangeupdate()
  // },[formData])
  const [startYear, setStartYear] = useState("");
  const [startModal, setStartModal] = useState(false);
  var startDate = moment(startYear).format("DD/MM/YYYY");
  function handleStartOpen() {
    setStartModal(!startModal);
  }

  const [passingYear, setPassingYear] = useState("");
  const [passingModal, setPassingModal] = useState(false);
  var passingDate = moment(passingYear).format("DD/MM/YYYY");

  function handlePassingOpen() {
    setPassingModal(!passingModal);
  }
  const [received, setReceived] = useState("");
  const [receivedModal, setReceivedModal] = useState(false);
  var receivedDate = moment(received).format("DD/MM/YYYY");

  function handleReceivedOpen() {
    setReceivedModal(!receivedModal);
  }

  const [from, setFrom] = useState("");
  const [fromModal, setFromModal] = useState(false);
  var fromDate = moment(from).format("DD/MM/YYYY");
  function handleFromOpen() {
    setFromModal(!fromModal);
  }

  const [to, setTo] = useState("");
  const [toModal, setToModal] = useState(false);
  var toDate = moment(to).format("DD/MM/YYYY");
  function handleToOpen() {
    setToModal(!toModal);
  }

  // const [year, setYear] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    localStorage.setItem(
      "resumeForm",
      JSON.stringify({ ...formData, [name]: value })
    );
    handleChangeupdate();
  };

  const handleSocial = (e) => {
    const { name, value } = e.target;
    setSocial({ ...social, [name]: value });
    localStorage.setItem(
      "sociallink",
      JSON.stringify({ ...social, [name]: value })
    );
    handleChangeupdate();
  };

  const [over, setOver] = useState(false);
  const [dropdowns, setDropdowns] = useState({
    dropdown1: true,
    dropdown2: true,
    dropdown3: true,
    dropdown4: true,
    dropdown5: true,
    dropdown6: true,
    dropdown7: true,
    dropdown8: true,
    dropdown9: true,
  });
  const [dropHover1, setDropHover1] = useState(false);
  const [language, setLanguage] = useState(false);
  const [language2, setLanguage2] = useState(false);
  const [dropHover2, setDropHover2] = useState(false);
  const [dropHover3, setDropHover3] = useState(false);
  const [dropHover4, setDropHover4] = useState(false);
  const [dropHover5, setDropHover5] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("Language");
  const [selectedLanguage2, setSelectedLanguage2] = useState("Language");

  const [education, setEducation] = useState([
    {
      universityName: "",
      degree: "",
      degreeName: "",
      percentage: "",
      cgpa: "",
      selectStartYear: "",
      selectEndYear: "",
      writehighlight: "",
    },
  ]);
  // useEffect(()=>{
  //   localStorage.setItem("resumeEducation", JSON.stringify(education))
  //   handleChangeupdate()
  // },[education])

  useEffect(() => {
    // localStorage.setItem("resumeForm", JSON.stringify(formData));
    if (localStorage.getItem("resumeForm")) {
      setFormData(JSON.parse(localStorage.getItem("resumeForm")));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("resumeEducation")) {
      setEducation(JSON.parse(localStorage.getItem("resumeEducation")));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("resumeLanguage")) {
      setLanguages(JSON.parse(localStorage.getItem("resumeLanguage")));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("resumeExperience")) {
      setExperience(JSON.parse(localStorage.getItem("resumeExperience")));
    }
  }, []);

  const handleInputChange = (index, key, value) => {
    // console.log(value);
    const updatedEducation = [...education];
    updatedEducation[index][key] = value;
    setEducation(updatedEducation);
    localStorage.setItem("resumeEducation", JSON.stringify(updatedEducation));
    handleChangeupdate();
  };
  function AddEducation() {
    const updatedList = [
      ...education,
      {
        universityName: "",
        degree: "",
        degreeName: "",
        percentage: "",
        cgpa: "",
        selectStartYear: "",
        selectEndYear: "",
        writehighlight: "",
      },
    ];
    setEducation(updatedList);
    localStorage.setItem("resumeEducation", JSON.stringify(updatedList));
    handleChangeupdate();
  }

  const [languages, setLanguages] = useState([
    { languageName: "", proficiency: "" },
  ]);
  // useEffect(()=>{
  //   localStorage.setItem("resumeLanguage", JSON.stringify(languages))
  //   handleChangeupdate()
  // },[languages])
  const handleProficiencyChange = (index, key, value) => {
    const updatedLanguages = [...languages];
    updatedLanguages[index][key] = value;
    setLanguages(updatedLanguages);
    localStorage.setItem("resumeLanguage", JSON.stringify(updatedLanguages));
    handleChangeupdate();
  };
  const [experience, setExperience] = useState([
    {
      companyName: "",
      companyType: "",
      jobRole: "",
      designation: "",
      form: "",
      to: "",
      describeRole: "",
      jobHighlight: "",
    },
  ]);
  // useEffect(()=>{
  //   localStorage.setItem("resumeExperience", JSON.stringify(experience))
  //   handleChangeupdate()
  // },[experience])
  const handleExperienceChange = (index, key, value) => {
    // console.log(value);
    const updatedExperience = [...experience];
    updatedExperience[index][key] = value;
    setExperience(updatedExperience);
    localStorage.setItem("resumeExperience", JSON.stringify(updatedExperience));
    handleChangeupdate();
  };
  function AddExperience() {
    setExperience([
      ...experience,
      {
        companyName: "",
        companyType: "",
        jobRole: "",
        designation: "",
        form: "",
        to: "",
        describeRole: "",
        jobHighlight: "",
      },
    ]);
    localStorage.setItem(
      "resumeExperience",
      JSON.stringify([
        ...experience,
        {
          companyName: "",
          companyType: "",
          jobRole: "",
          designation: "",
          form: "",
          to: "",
          describeRole: "",
          jobHighlight: "",
        },
      ])
    );
    handleChangeupdate();
  }
  function AddLanguages() {
    setLanguages([...languages, { languageName: "", proficiency: "" }]);
    localStorage.setItem(
      "resumeLanguage",
      JSON.stringify([...languages, { languageName: "", proficiency: "" }])
    );
    handleChangeupdate();
  }
  function DeleteLanguages(id) {
    let filteredArray = languages.filter((item, index) => index !== id);
    setLanguages(filteredArray);
    localStorage.setItem("resumeLanguage", JSON.stringify(filteredArray));
    handleChangeupdate();
  }

  function DeleteExperience(id) {
    let filteredArray = experience.filter((item, index) => index !== id);
    setExperience(filteredArray);
    localStorage.setItem("resumeExperience", JSON.stringify(filteredArray));
    handleChangeupdate();
  }
  const allState = State.getStatesOfCountry("IN", "TG").map((city) => ({
    value: city.name,
    displayValue: city.name,
  }));

  // Search State Start

  const [filterText, setFilterText] = useState("");
  const [selected, setIsSelected] = useState("");
  const [company, setCompany] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("Company type");

  const fuse = new Fuse(allState, {
    includeScore: true,
    keys: ["displayValue"],
  });

  const pattern = filterText;
  const result = fuse.search(pattern);
  useEffect(() => {
    if (selected.trim() !== "") {
      if (!formData?.subcategories?.includes(selected))
        setFormData({
          ...formData,
          subcategories: [...formData?.subcategories, selected],
        });
    }
  }, [selected]);

  // Search State End

  const handleDropdown = (dropdownName) => {
    const updatedDropdowns = { ...dropdowns };
    for (const dropdown in updatedDropdowns) {
      if (dropdown === dropdownName) {
        updatedDropdowns[dropdown] = !updatedDropdowns[dropdown];
      } else {
        updatedDropdowns[dropdown] = false;
      }
    }
    setDropdowns(updatedDropdowns);
  };
  const handleEnterPress = () => {
    if (filterText.trim() !== "") {
      setFormData({
        ...formData,
        skills: [...formData.skills, filterText.trim()],
      });
      localStorage.setItem(
        "resumeForm",
        JSON.stringify({
          ...formData,
          skills: [...formData.skills, filterText.trim()],
        })
      );
      handleChangeupdate();
      setFilterText("");
    }
  };

  const handleDeleteSubcategory = (item) => {
    const skills = formData?.skills?.filter((s, id) => id !== item);
    setFormData({ ...formData, skills });
  };

  const [working, setWorking] = useState(false);
  const [mariatial, setMariatial] = useState(false);
  const [degree, setDegree] = useState(false);
  const [CGPA, setCGPA] = useState(false);
  const [selectedWorking, setSelectedWorking] = useState("Working status");
  const [selectedMariatial, setSelectedMariatial] =
    useState("Mariatial Status");
  const [selectedDegree, setSelectedDegree] = useState("Degree type");
  const [selectedCGPA, setSelectedCGPA] = useState("CGPA");
  function DeleteEducation(id) {
    const educationList = Object.assign([], education);
    let filteredArray = educationList.filter((item, index) => index !== id);
    setEducation(filteredArray);
    localStorage.setItem("resumeEducation", JSON.stringify(filteredArray));
    handleChangeupdate();
  }
  return (
    <>
      <div
        className="desktop-show-hide"
        style={{ display: "flex", flexDirection: "column", gap: "12px" }}
      >
        <div className="personal-from-">
          <div
            className="personal-form-title"
            onClick={() => handleDropdown("dropdown1")}
          >
            <p
              className="personal-title m-0"
              style={{
                color: " #475569",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "12px",
                textTransform: "uppercase",
              }}
            >
              Personal Details
            </p>
            <img src={downArrow} alt="" />
          </div>
          <div
            className="resume-box"
            style={
              dropdowns?.dropdown1 === false
                ? { display: "none" }
                : { display: "block" }
            }
          >
            <input
              type="text"
              placeholder="Full Name"
              className="resume-input"
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Designation"
              className="resume-input"
              name="designation"
              value={formData.designation}
              onChange={handleChange}
            />

            <div className="calender-date-input" onClick={handleDateOpen}>
              <input
                placeholder={
                  dateBirth.length === 0 ? "Date Of Birth" : birthDate
                }
                className="input-date"
                type="text"
              />
              <img src={CalenderImg} className="input-calender" alt="" />
            </div>
            <input
              type="text"
              placeholder="Current city"
              className="resume-input"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
            <textarea
              name="summary"
              id=""
              style={{ resize: "none" }}
              cols="30"
              rows="3"
              className="resume-input m-0"
              placeholder="Write Summary"
              value={formData.summary}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className="personal-from-">
          <div
            className="personal-form-title"
            onClick={() => handleDropdown("dropdown2")}
          >
            <p
              className="personal-title m-0"
              style={{
                color: " #475569",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "12px",
                textTransform: "uppercase",
              }}
            >
              Academic Details
            </p>
            <img src={downArrow} alt="" />
          </div>
          <div
            className="resume-box"
            style={
              dropdowns?.dropdown2 === false
                ? { display: "none" }
                : { display: "block" }
            }
          >
            {education.map((element, index) => {
              return (
                <>
                  <div key={index}>
                    <input
                      type="text"
                      placeholder="Institute/University name"
                      className="resume-input"
                      value={element.universityName}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "universityName",
                          e.target.value
                        )
                      }
                    />
                    <div
                      className="dropdown"
                      onMouseEnter={() => setDropHover4(true)}
                      onMouseLeave={() => setDropHover4(false)}
                    >
                      <div
                        onClick={(e) => {
                          setDegree(!degree);
                        }}
                        style={{
                          outline: degree ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {element?.degree}
                        <img
                          src={dropHover4 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: degree ? "block" : "none" }}
                      >
                        {selectItemThree.map((element, ind) => {
                          return (
                            <div
                              onClick={(e) => {
                                setSelectedDegree(e.target.textContent);
                                handleInputChange(
                                  index,
                                  "degree",
                                  e.target.textContent
                                );
                                setDegree(!degree);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <input
                      type="text"
                      placeholder="Degree name"
                      className="resume-input"
                      value={element.degreeName}
                      onChange={(e) =>
                        handleInputChange(index, "degreeName", e.target.value)
                      }
                    />
                    {/* <input
                      type="text"
                      placeholder="0"
                      className="resume-input"
                      value={element.percentage}
                      onChange={(e) =>
                        handleInputChange(index, "percentage", e.target.value)
                      }
                    /> */}
                    {/* <div
                      className="dropdown"
                      onMouseEnter={() => setDropHover5(true)}
                      onMouseLeave={() => setDropHover5(false)}
                    >
                      <div
                        onClick={(e) => {
                          setCGPA(!CGPA);
                        }}
                        style={{
                          outline: CGPA ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {element?.cgpa}
                        <img
                          src={dropHover5 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: CGPA ? "block" : "none" }}
                      >
                        {cgp.map((element, ind) => {
                          return (
                            <div
                              onClick={(e) => {
                                setSelectedCGPA(e.target.textContent);
                                handleInputChange(
                                  index,
                                  "cgpa",
                                  e.target.textContent
                                );
                                setCGPA(!CGPA);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div> */}
                    <div
                      className="calender-date-input"
                      // onClick={handleStartOpen}
                    >
                      <input
                        placeholder={"Starting year (mm/yyyy)"}
                        className="input-date"
                        type="text"
                        value={element.selectStartYear}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "selectStartYear",
                            e.target.value
                          )
                        }
                      />
                      <img
                        src={CalenderImg}
                        className="input-calender"
                        alt=""
                      />
                    </div>
                    <div
                      className="calender-date-input"
                      // onClick={handlePassingOpen}
                    >
                      <input
                        placeholder={"Passing year (mm/yyyy)"}
                        className="input-date"
                        type="text"
                        value={element.selectEndYear}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "selectEndYear",
                            e.target.value
                          )
                        }
                      />
                      <img
                        src={CalenderImg}
                        className="input-calender"
                        alt=""
                      />
                    </div>
                    {/* <textarea
                      name={`writehighlight-${index}`}
                      id={`writehighlight-${index}`}
                      cols="30"
                      rows="3"
                      value={element.writehighlight}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "writehighlight",
                          e.target.value
                        )
                      }
                      placeholder="Write about the highlights of your journey"
                      className="resume-input m-0"
                    ></textarea> */}
                  </div>
                  <div className="tooltip-container">
                    <div
                      className="mobile-tooltip-box"
                      style={{ opacity: over ? 1 : 0 }}
                    >
                      <div
                        className="tooltip-close"
                        onClick={() => setOver(false)}
                      ></div>
                      The highlights of your journey create a powerful impact by
                      showcasing your accomplishments, growth, and potential.
                      They provide insight into your professional trajectory and
                      demonstrate the value you bring to the table.
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ paddingBottom: "12px" }}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        src={info}
                        onClick={() => setOver(!over)}
                        className="pe-2"
                        alt=""
                      />
                      <p className="information-text">
                        What impact would highlights create
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid #CBD5E1",
                      marginBottom: "12px",
                    }}
                  ></div>
                  <div className="d-flex align-items-center justify-content-between">
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onClick={AddEducation}
                      className="plush-text p-0"
                    >
                      <img src={plush} className="pe-2" alt="" />
                      Add education
                    </button>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      disabled={education.length === 1}
                      onClick={() => DeleteEducation(index)}
                      className="plush-text p-0"
                    >
                      <img src={Delete} className="pe-2" alt="" />
                      Delete
                    </button>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="personal-from-">
          <div
            className="personal-form-title"
            onClick={() => handleDropdown("dropdown3")}
          >
            <p
              className="personal-title m-0"
              style={{
                color: " #475569",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "12px",
                textTransform: "uppercase",
              }}
            >
              Contact information
            </p>
            <img src={downArrow} alt="" />
          </div>
          <div
            className="resume-box"
            style={
              dropdowns?.dropdown3 === false
                ? { display: "none" }
                : { display: "block" }
            }
          >
            <input
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={formData.phoneNumber}
              name="phoneNumber"
              onChange={handleChange}
              placeholder="Phone number"
              className="resume-input"
            />
            <input
              type="email"
              placeholder="Email address"
              className="resume-input"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="personal-from-">
          <div
            className="personal-form-title"
            onClick={() => handleDropdown("dropdown4")}
          >
            <p
              className="personal-title m-0"
              style={{
                color: " #475569",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "12px",
                textTransform: "uppercase",
              }}
            >
              Work Experience
            </p>
            <img src={downArrow} alt="" />
          </div>
          <div
            className="resume-box"
            style={
              dropdowns?.dropdown4 === true
                ? { display: "block" }
                : { display: "none", marginTop: "12px" }
            }
          >
            {experience.map((element, index) => {
              return (
                <>
                  <input
                    type="text"
                    placeholder="Company name"
                    className="resume-input"
                    value={element.companyName}
                    onChange={(e) =>
                      handleExperienceChange(
                        index,
                        "companyName",
                        e.target.value
                      )
                    }
                  />
                  <div
                    className="dropdown"
                    onMouseEnter={() => setDropHover1(true)}
                    onMouseLeave={() => setDropHover1(false)}
                  >
                    <div
                      onClick={(e) => {
                        setCompany(!company);
                      }}
                      style={{
                        outline: company ? "2px solid #94A3B8" : "none",
                      }}
                      className="dropdown-btn"
                    >
                      {element?.companyType}
                      <img
                        src={dropHover1 ? downArrowHover : downArrow}
                        alt=""
                      />
                    </div>
                    <div
                      className="dropdown-content"
                      style={{ display: company ? "block" : "none" }}
                    >
                      {selectItem.map((element) => {
                        return (
                          <div
                            onClick={(e) => {
                              setSelectedCompany(e.target.textContent);
                              handleExperienceChange(
                                index,
                                "companyType",
                                e.target.textContent
                              );
                              setCompany(!company);
                            }}
                            className="item"
                          >
                            {element.option}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* <input
                    type="text"
                    // onChange={handleChange}
                    placeholder="Job Role"
                    className="resume-input"
                    value={element.jobRole}
                    onChange={(e) =>
                      handleExperienceChange(index, "jobRole", e.target.value)
                    }
                  /> */}
                  <input
                    placeholder="Designation (if any)"
                    className="resume-input"
                    type="text"
                    value={element.designation}
                    onChange={(e) =>
                      handleExperienceChange(
                        index,
                        "designation",
                        e.target.value
                      )
                    }
                  />
                  <div
                    className="calender-date-input"
                    // onClick={handleFromOpen}
                  >
                    <input
                      placeholder="From (mm/yyyy)"
                      className="input-date"
                      type="text"
                      value={element.form}
                      onChange={(e) =>
                        handleExperienceChange(index, "form", e.target.value)
                      }
                    />
                    <img src={CalenderImg} className="input-calender" alt="" />
                  </div>
                  <div
                    className="calender-date-input"
                    // -onClick={handleToOpen}
                  >
                    <input
                      placeholder="To (mm/yyyy)"
                      className="input-date"
                      type="text"
                      value={element.to}
                      onChange={(e) =>
                        handleExperienceChange(index, "to", e.target.value)
                      }
                    />
                    <img src={CalenderImg} className="input-calender" alt="" />
                  </div>
                  <textarea
                    name=""
                    id=""
                    style={{ resize: "none" }}
                    cols="30"
                    rows="3"
                    className="resume-input m-0"
                    placeholder="Describe your job roles & responsibilities"
                    value={element.describeRole}
                    onChange={(e) =>
                      handleExperienceChange(
                        index,
                        "describeRole",
                        e.target.value
                      )
                    }
                  ></textarea>
                  <p
                    className="information-text pb-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="The highlights of your journey create a powerful impact by showcasing your accomplishments, growth, and potential. They provide insight into your professional trajectory and demonstrate the value you bring to the table."
                  >
                    <img src={info} className="pe-2" alt="" />
                    What impact would responsibilities create
                  </p>
                  {/* <div className="col-12">
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      style={{ resize: "none" }}
                      rows="3"
                      className="resume-input m-0"
                      placeholder="Write about the highlights of your journey "
                      value={element.jobHighlight}
                      onChange={(e) =>
                        handleExperienceChange(
                          index,
                          "jobHighlight",
                          e.target.value
                        )
                      }
                    ></textarea>
                  </div> */}
                  <div className="d-flex align-items-center justify-content-between">
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      className="plush-text"
                      onClick={AddExperience}
                    >
                      <img src={plush} className="pe-2" alt="" />
                      Add more
                    </button>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      className="plush-text"
                      disabled={experience.length === 1}
                      onClick={() => DeleteExperience(index)}
                    >
                      <img src={Delete} className="pe-2" alt="" />
                      Delete
                    </button>
                  </div>
                  {/* mobile tooltip addded */}
                </>
              );
            })}
          </div>
        </div>
        <div className="personal-from-">
          <div
            className="personal-form-title"
            onClick={() => handleDropdown("dropdown5")}
          >
            <p
              className="personal-title m-0"
              style={{
                color: " #475569",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "12px",
                textTransform: "uppercase",
              }}
            >
              Skills
            </p>
            <img src={downArrow} alt="" />
          </div>
          <div
            className="resume-box"
            style={
              dropdowns?.dropdown5 === true
                ? { display: "block" }
                : { display: "none", marginTop: "12px" }
            }
          >
            {["experience"].map((element, index) => {
              return (
                <>
                  <input
                    type="text"
                    placeholder="Add skills and Press Enter"
                    className="resume-input m-0"
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                    value={filterText}
                  />
                  <div className="d-flex">
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      className="plush-text w-100"
                      onClick={handleEnterPress}
                    >
                      Add more
                    </button>
                    <p
                      style={{
                        color: "#64748B",
                        fontFamily: "Inter",
                        marginTop: "4px",
                        marginBottom: "8px",
                        fontSize: "12px",
                        textAlign: "right",
                        fontStyle: "normal",
                        width: "100%",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      Add Max 20
                    </p>
                  </div>
                  <div className="d-flex-inline mt-2">
                    {formData?.skills?.length > 0 && (
                      <div className="d-flex-inline">
                        {formData?.skills?.map((item, index) => (
                          <p className="select-option-box" key={index}>
                            {item}
                            <img
                              src={closeBtn}
                              style={{ marginLeft: "6px", opacity: "0.6" }}
                              alt=""
                              onClick={() => handleDeleteSubcategory(index)}
                            />
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="personal-from-">
          <div
            className="personal-form-title"
            onClick={() => handleDropdown("dropdown6")}
          >
            <p
              className="personal-title m-0"
              style={{
                color: " #475569",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "12px",
                textTransform: "uppercase",
              }}
            >
              Languages
            </p>
            <img src={downArrow} alt="" />
          </div>
          <div
            className="resume-box"
            style={
              dropdowns?.dropdown6 === true
                ? { display: "block" }
                : { display: "none", marginTop: "12px" }
            }
          >
            {languages.map((element, index) => {
              return (
                <>
                  <input
                    placeholder="Language"
                    className="resume-input"
                    type="text"
                    value={element.designation}
                    onChange={(e) =>
                      handleProficiencyChange(
                        index,
                        "languageName",
                        e.target.value
                      )
                    }
                  />
                  <p className="build-resume-two m-0">Choose proficiency</p>
                  <div className="d-flex flex-column">
                    <div className="radio-button">
                      <input
                        type="radio"
                        className="radio-button__input"
                        id={`radio-${index}-1`}
                        name="radio-group"
                        value="Elementary"
                        onChange={() =>
                          handleProficiencyChange(
                            index,
                            "proficiency",
                            "Elementary"
                          )
                        }
                      />
                      <label
                        className="radio-button__label"
                        htmlFor={`radio-${index}-1`}
                      >
                        <span className="radio-button__custom"></span>
                        Elementary
                      </label>
                    </div>
                    <div className="radio-button">
                      <input
                        type="radio"
                        className="radio-button__input"
                        id={`radio-${index}-2`}
                        name="radio-group"
                        value="Limited"
                        onChange={() =>
                          handleProficiencyChange(
                            index,
                            "proficiency",
                            "Limited"
                          )
                        }
                      />
                      <label
                        className="radio-button__label"
                        htmlFor={`radio-${index}-2`}
                      >
                        <span className="radio-button__custom"></span>
                        Limited Working
                      </label>
                    </div>
                    <div className="radio-button">
                      <input
                        type="radio"
                        className="radio-button__input"
                        id={`radio-${index}-3`}
                        name="radio-group"
                        value="Professional"
                        onChange={() =>
                          handleProficiencyChange(
                            index,
                            "proficiency",
                            "Professional"
                          )
                        }
                      />
                      <label
                        className="radio-button__label"
                        htmlFor={`radio-${index}-3`}
                      >
                        <span className="radio-button__custom"></span>
                        Professional Working
                      </label>
                    </div>
                    <div className="radio-button">
                      <input
                        type="radio"
                        className="radio-button__input"
                        id={`radio-${index}-4`}
                        name="radio-group"
                        value="Full-Professional"
                        onChange={() =>
                          handleProficiencyChange(
                            index,
                            "proficiency",
                            "Full-Professional"
                          )
                        }
                      />
                      <label
                        className="radio-button__label"
                        htmlFor={`radio-${index}-4`}
                      >
                        <span className="radio-button__custom"></span>
                        Full Professional
                      </label>
                    </div>
                    <div className="radio-button">
                      <input
                        type="radio"
                        className="radio-button__input"
                        id={`radio-${index}-5`}
                        name="radio-group"
                        value="Native"
                        onChange={() =>
                          handleProficiencyChange(
                            index,
                            "proficiency",
                            "Native"
                          )
                        }
                      />
                      <label
                        className="radio-button__label"
                        htmlFor={`radio-${index}-5`}
                      >
                        <span className="radio-button__custom"></span>
                        Native/Bilingual
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      borderBottom: "1px solid rgb(203, 213, 225)",
                      margin: "12px 0",
                    }}
                  ></div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      className="plush-text px-0"
                      onClick={AddLanguages}
                    >
                      <img src={plush} className="pe-2" alt="" />
                      Add Language
                    </button>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      className="plush-text px-0"
                      disabled={languages.length === 1}
                      onClick={() => DeleteLanguages(index)}
                    >
                      <img src={Delete} className="pe-2" alt="" />
                      Delete
                    </button>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="personal-from-">
          <div
            className="personal-form-title"
            onClick={() => handleDropdown("dropdown7")}
          >
            <p
              className="personal-title m-0"
              style={{
                color: " #475569",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "12px",
                textTransform: "uppercase",
              }}
            >
              Social Links
            </p>
            <img src={downArrow} alt="" />
          </div>
          <div
            className="resume-box"
            style={
              dropdowns?.dropdown7 === true
                ? { display: "block" }
                : { display: "none", marginTop: "12px" }
            }
          >
            <div className="link-inputs">
              <img src={linkImg} alt="" />
              <input
                placeholder="LinkedIn"
                type="text"
                name="linkedin"
                value={social?.linkedin}
                onChange={handleSocial}
              />
            </div>
            <div className="link-inputs">
              <img src={linkImg} alt="" />
              <input
                placeholder="Portfolio"
                type="text"
                name="portfolio"
                value={social?.portfolio}
                onChange={handleSocial}
              />
            </div>
            <div className="link-inputs">
              <img src={linkImg} alt="" />
              <input
                placeholder="Website"
                type="text"
                name="website"
                value={social?.website}
                onChange={handleSocial}
              />
            </div>
            <div className="link-inputs">
              <img src={linkImg} alt="" />
              <input
                placeholder="Other"
                type="text"
                name="other"
                value={social?.other}
                onChange={handleSocial}
              />
            </div>
          </div>
        </div>
        <div className="personal-from-">
          <div
            className="personal-form-title"
            onClick={() => handleDropdown("dropdown8")}
          >
            <p
              className="personal-title m-0"
              style={{
                color: " #475569",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "12px",
                textTransform: "uppercase",
              }}
            >
              Extra Curricular Activities
            </p>
            <img src={downArrow} alt="" />
          </div>
          <div
            className="resume-box"
            style={
              dropdowns?.dropdown8 === true
                ? { display: "block" }
                : { display: "none", marginTop: "12px" }
            }
          >
            {/* input yaha pr add kr dena */}
          </div>
        </div>
        <div className="personal-from-">
          <div
            className="personal-form-title"
            onClick={() => handleDropdown("dropdown9")}
          >
            <p
              className="personal-title m-0"
              style={{
                color: " #475569",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "12px",
                textTransform: "uppercase",
              }}
            >
              Certificate Courses
            </p>
            <img src={downArrow} alt="" />
          </div>
          <div
            className="resume-box"
            style={
              dropdowns?.dropdown9 === true
                ? { display: "block" }
                : { display: "none", marginTop: "12px" }
            }
          >
            {/* input yaha pr add kr dena */}
          </div>
        </div>
      </div>

      {dateModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleDateOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar
                  onChange={setDateBirth}
                  onClick={handleDateOpen}
                  value={dateBirth}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {startModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleStartOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar onChange={setStartYear} value={startYear} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {passingModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handlePassingOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar onChange={setPassingYear} value={passingYear} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {receivedModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleReceivedOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar onChange={setReceived} value={received} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default InputField;
