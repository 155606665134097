import React, { useEffect, useState } from "react";
import "./recruiter.css";
import { Link, useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import Profile from "../../assets/recruiter/profile-circle.svg";
import ProfileIcon from "../../assets/3682281.png";
import dotIcon from "../../assets/recruiter/dot-btn.svg";
import Loader from "../loader/Loader";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
import downArrow from "../../assets/select-down.svg";
import close from "../../assets/close-btn.svg";
import savedImg from "../../assets/saved-application.svg";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import box from "../../assets/office-building.png";
import chat from "../../assets/chat-track.svg";
import axios from "axios";
import { baseUrl } from "../../contextApi/common/data";
import RecruiterChat2 from "./chat/RecruiterChat2";

const RecruiterCard3 = ({ inputVal }) => {
  useEffect(() => {
    // getData();
    // recruiterPost();
  }, []);
  const navigate = useNavigate();
  const [dotBtn, setDotBtn] = useState(-1);
  const [loader, setLoader] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState("");
  const [recruiterPosts, setRecruiterPosts] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [formData, setFormData] = useState({
    subcategories: [],
    job: [],
  });
  const [selected, setIsSelected] = useState("");
  const [postId, setPostId] = useState([]);

  const [filterData, setFilterData] = useState("");

  const { getAllByCompany, allPostByRecruiter, assignInterviewer } = useMain();

  const getData = async () => {
    setLoader(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await getAllByCompany(token);
    if (res?.statusCode === 200) {
      setData(res?.data);
      setFilterData(res?.data);
      setLoader(false);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };

  const findChats = async (data) => {
    try {
      const res = await axios.post(
        `${baseUrl}/message/all/interview/user`,
        JSON.stringify({ ids: data }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res);
      if (res?.status == 200) {
        setFilterData(res?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const jobsId = JSON.parse(localStorage.getItem("recruiterData"))?.jobPost;
    console.log(jobsId);
    if (jobsId && jobsId.length > 0) {
      findChats(jobsId);
    }
  }, []);

  const handelDialog = (val) => {
    if (dotBtn == val) {
      setDotBtn(-1);
    } else {
      setDotBtn(val);
    }
  };

  useEffect(() => {
    // filteredDrafts(inputVal);
  }, [inputVal]);

  const filteredDrafts = (inputVal) => {
    const filterVal = data?.filter(
      (item) =>
        inputVal?.trim() === "" ||
        Object.values(item)?.some((value) =>
          String(value)?.toLowerCase()?.includes(inputVal?.toLowerCase())
        )
    );
    setFilterData(filterVal);
  };

  const recruiterPost = async () => {
    setLoader(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await allPostByRecruiter(token);
    if (res?.statusCode === 200) {
      setRecruiterPosts(res?.data?.data);
      setLoader(false);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };

//   useEffect(() => {
//     if (selected.trim() !== "") {
//       if (!formData?.subcategories?.includes(selected))
//         setFormData({
//           ...formData,
//           subcategories: [...formData?.subcategories, selected],
//         });
//     }
//   }, [selected]);
  const handleDeleteSubcategory = (item) => {
    const subcategories = formData?.subcategories?.filter(
      (s, id) => id !== item
    );
    const deleteId = postId.filter((_, id) => id !== item);
    setPostId(deleteId);
    setFormData({ ...formData, subcategories });
  };

  const assignPost = async (id) => {
    if (postId.length > 0) {
      try {
        setLoader(true);
        const token = localStorage.getItem("recruiterToken");
        const Id = [...new Set(postId)];
        const result = await assignInterviewer(id, Id, token);

        if (result?.statusCode == 200) {
          // Handle success, e.g., update state, show a message, etc.
          toast.success(result?.message);
          setLoader(false);
          setPostId([]);
          setFormData({
            subcategories: [],
            job: [],
          });
          setModal(false);
          setIsSelected("");
        } else if (result?.statusCode === 500) {
          // Handle error, e.g., show an error message
          toast.error(result?.message);
          setLoader(false);
          setPostId([]);
          setFormData({
            subcategories: [],
            job: [],
          });
          setModal(false);
          setIsSelected("");
        }
      } catch (error) {
        setLoader(false);
        setPostId([]);
        setFormData({
          subcategories: [],
          job: [],
        });
        setModal(false);
        setIsSelected("");
      }
    }
  };

  return (
    <>
      <AlertContainer />
      {loader ? (
        <Loader />
      ) : filterData && filterData.length > 0 ? (
        filterData.map((item, index) => (
          <>
            <div key={index} className="open-mobile-card">
              <div className="d-flex align-items-center">
                <img
                  src={box}
                  className="me-2"
                  alt=""
                  style={{ width: "45px", height: "45px" }}
                />
                <div>
                  <h6 className="open-mobile-card-title m-0">
                    {item?.receiver?.jobInfo?.position}
                  </h6>
                  <p className="open-mobile-card-text m-0">
                    {item?.receiver?.companyName}
                  </p>
                </div>
              </div>
              <div className="open-mobile-card-border"></div>
              <ul className="ps-4 m-0">
                <li className="open-card-item">
                  {item?.receiver?.jobInfo?.workPlace}
                </li>
                {/* <li className="open-card-item">Start Up</li> */}
                <li className="open-card-item">
                  {item?.receiver?.jobInfo?.jobType}
                </li>
                <li className="open-card-item">
                  {item?.receiver?.jobInfo?.workPlaceType}
                </li>
                <li className="open-card-item">
                  {item?.receiver?.jobInfo?.candidateJoining}
                </li>
              </ul>
              <Link
                // to={"/dashboard/chat"}
                state={{
                  data: {
                    interviewerId: item?.receiver?._id,
                    name: item?.receiver?.jobInfo?.position,
                    company: item?.receiver?.companyName,
                  },
                }}
                onClick={() => setToggle(index)}
                className="chat-mobile-btn"
              >
                <img src={chat} style={{ marginRight: "4px" }} alt="" />
                <h6 className="chat-btn-text m-0">Chat with HR</h6>
              </Link>
              {toggle === index && (
                <RecruiterChat2
                  setToggle={setToggle}
                  userId={item?.sender?._id}
                  name={item?.sender?.name}
                  company={item?.receiver?.companyName}
                  jobId={item?.receiver?._id}
                />
              )}
            </div>
          </>
        ))
      ) : (
        <div className="unsaved-image">
          <img src={savedImg} alt="" />
          <h6 className="unsaved-text m-0">No Interviewer</h6>
        </div>
      )}
    </>
  );
};

export default RecruiterCard3;
