import React from "react";
import "./sub-header.css";
import dashboard from "../../assets/recruiter-subHeader/sub-dash.svg";
import post from "../../assets/recruiter-subHeader/add.svg";
import all from "../../assets/recruiter-subHeader/all.svg";
import Interviewers from "../../assets/recruiter-subHeader/interview.svg";
import Automate from "../../assets/recruiter-subHeader/automate.svg";
import ActiveDashboard from "../../assets/recruiter-subHeader/light-dashboard.svg";
import ActiveAdd from "../../assets/recruiter-subHeader/light-add.svg";
import ActiveAll from "../../assets/recruiter-subHeader/light-all.svg";
import ActiveInterview from "../../assets/recruiter-subHeader/light-interview.svg";
import Calender from "../../assets/recruiter-subHeader/calender.svg";
import CalenderActive from "../../assets/recruiter-subHeader/calender-active.svg";
// import ActiveAutomate from "../../assets/recruiter-subHeader/light-automate.svg";
import { Link, useLocation } from "react-router-dom";

function SubHeader() {
  let path = useLocation();
  let pathName = path.pathname;
  return (
    <>
      <div className="sub-header-container">
        <div className="sub-header">
          <div className="d-flex" style={{ gap: "24px" }}>
            <Link
              to="/recruiter-dashboard"
              className={`${
                pathName === "/recruiter-dashboard"
                  ? "sub-active"
                  : "sub-header-dashboard-btn"
              } `}
            >
              <img
                src={
                  pathName === "/recruiter-dashboard"
                    ? ActiveDashboard
                    : dashboard
                }
                style={{ marginRight: "4px" }}
                alt=""
              />
              Dashboard
            </Link>
            <Link
              to="/dashboard/new-job"
              className={`${
                pathName === "/dashboard/new-job"
                  ? "sub-active"
                  : "sub-header-dashboard-btn"
              } `}
            >
              <img
                src={pathName === "/dashboard/new-job" ? ActiveAdd : post}
                style={{ marginRight: "4px" }}
                alt=""
              />
              Post Job
            </Link>
          </div>
          <div style={{ display: "flex", gap: "16px" }}>
            <Link
              to="/dashboard/all-application"
              className={`${
                pathName === "/dashboard/all-application"
                  ? "sub-active"
                  : "sub-header-dashboard-btn"
              } `}
            >
              <img
                src={
                  pathName === "/dashboard/all-application" ? ActiveAll : all
                }
                style={{ marginRight: "4px" }}
                alt=""
              />
              Applications
            </Link>
            <Link
              to="/dashboard/all-interviews"
              className={`${
                pathName === "/dashboard/all-interviews"
                  ? "sub-active"
                  : "sub-header-dashboard-btn"
              } `}
            >
              <img
                src={
                  pathName === "/dashboard/all-interviews"
                    ? ActiveInterview
                    : Interviewers
                }
                style={{ marginRight: "4px" }}
                alt=""
              />
              Interviewers
            </Link>
            <Link className="sub-header-dashboard-btn">
              <img src={Automate} style={{ marginRight: "4px" }} alt="" />
              Automate
            </Link>
            <Link
              to="/dashboard/calender"
              className={`${
                pathName === "/dashboard/calender"
                  ? "sub-active"
                  : "sub-header-dashboard-btn"
              } `}
            >
              <img
                src={
                  pathName === "/dashboard/calender" ? CalenderActive : Calender
                }
                style={{ marginRight: "4px" }}
                alt=""
              />
              Calender
            </Link>
            <Link
              to="/dashboard/recruiter/chat"
              className={`${
                pathName === "/dashboard/recruiter/chat"
                  ? "sub-active"
                  : "sub-header-dashboard-btn"
              } `}
            >
              <img
                src={
                  pathName === "/dashboard/recruiter/chat" ? CalenderActive : Calender
                }
                style={{ marginRight: "4px" }}
                alt=""
              />
              Chat
            </Link>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
}

export default SubHeader;
