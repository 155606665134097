import React, { useContext, useState } from "react";
import AllModalContext from "../context/allModal/AllModalContext";
import closeBtn from "../assets/close-bt.svg";
import Light from "../assets/recruiter/right-icon.svg";
import Dark from "../assets/right-light.png";
import "./dashboard.css";
import SubscribeYearly from "../components/subscribeSlider/SubscribeYearly";
import { initializePayment } from "../utils/paymentUtils";
import { toast } from "react-toastify";

function UpgradeRecruiterModel() {
  const modal = useContext(AllModalContext);
  const { recruiterUpgrade, toggleRecruitierUpgrade } = modal;
  const [radio, setRadio] = useState("Monthly");
  const handlePlaceOrder = async (amount, planName, validity_time) => {
    const userData = JSON.parse(localStorage.getItem("recruiterData"));
    console.log(userData);
    if (!userData) {
      return toast.error("Please log in first.");

    }
    const { name, email, mobileNo, _id } = userData;
    if (!name || !email || !mobileNo || !_id) {
      return toast.error("Please complete your profile first.");

    }
    let start_time, end_time;
    const today = new Date();
    if (validity_time === "Year") {
      start_time = today;
      end_time = new Date(today);
      end_time.setDate(today.getDate() + 365);
    }
    if (validity_time === "Month") {
      start_time = today;
      end_time = new Date(today);
      end_time.setDate(today.getDate() + 30);
    }
    const response = await initializePayment(
      amount,
      "recruiter",
      _id,
      planName,
      start_time,
      end_time,
      name,
      email,
      mobileNo
    );
    if (response?.statusCode === 200) {
      console.log(response);
    } else {
      console.error("Payment initialization failed:", response?.Message);
    }
  };
  if (recruiterUpgrade) {
    return (
      <>
        <div className="all-modal-2">
          <div className="upgrade-box">
            <button className="close-btn" onClick={toggleRecruitierUpgrade}>
              <img src={closeBtn} alt="" />
            </button>
            <h6 className="upgrade-title">Upgrade to [Plan name]</h6>
            <p className="upgrade-text">
              Get hired faster with better opportunities
            </p>
            <div className="d-flex justify-content-center">
              <div className="radio-button" style={{ marginLeft: "8px" }}>
                <input
                  type="radio"
                  className="radio-button__input"
                  id="radio1"
                  value="Monthly"
                  onChange={(e) => setRadio(e.target.value)}
                  name="Sortby"
                />
                <label className="upgrade-radio-button" htmlFor="radio1">
                  <span className="radio-button__custom"></span>
                  Monthly
                </label>
              </div>
              <div className="radio-button" style={{ marginLeft: "8px" }}>
                <input
                  type="radio"
                  className="radio-button__input"
                  id="radio2"
                  onChange={(e) => setRadio(e.target.value)}
                  name="Sortby"
                  value="Yearly"
                />
                <label className="upgrade-radio-button" htmlFor="radio2">
                  <span className="radio-button__custom"></span>
                  Yearly
                </label>
              </div>
            </div>
            <div className="upgrade-border"></div>
            {radio === "Yearly" ? (
              <div className="row align-items-center">
                <div className="col-4">
                  <div className="upgrade-box-one">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="upgrade-box-title m-0">Premium</h6>
                      <p className="upgrade-box-text m-0">34,999/-</p>
                    </div>
                    <div className="upgrade-box-border"></div>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" />5 Active Job Post - Month
                    </p>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" /> 5000 Candidates - Month
                    </p>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" /> ATS
                    </p>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" /> Interviewer (Admin + 2)
                    </p>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" /> Work Flow Automation
                    </p>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" /> Interview Scheduler
                    </p>
                    <p className="upgrade-card-one-bold m-0">
                      Add ons <button className="extra-one">@Extra cost</button>
                    </p>
                    <p className="upgrade-card-one-bold m-0">
                      <img src={Light} style={{ paddingRight: "6px" }} alt="" />
                      +1 Interviewer @499/-
                    </p>
                    <button className="upgrade-starter-btn"
                      onClick={() => handlePlaceOrder(34999, "Premium", "Year")}
                    >
                      Upgrade to Premium
                    </button>
                  </div>
                </div>
                <div className="col-4">
                  <div className="upgrade-box-two">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="upgrade-box-two-title m-0">Premium Pro</h6>
                      <p className="upgrade-box-two-text m-0">49,999/-</p>
                    </div>
                    <div className="upgrade-box-two-border"></div>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> 10 Active Job Post Every Month
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> 10000 Candidates Every Month
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> ATS
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> Interviewer (Admin + 5)
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> Work Flow Automation
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> Interview Scheduler
                    </p>
                    <p
                      className="upgrade-card-one-bold m-0"
                      style={{ color: "#262626" }}
                    >
                      Add ons <button className="extra-one">@Extra cost</button>
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> Job Post @499/-
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> 1000 Candidates @999/-
                    </p>
                    <button className="upgrade-text-two-btn"
                      onClick={() => handlePlaceOrder(49999, "Premium_Pro", "Year")}
                    >
                      Upgrade to Premium Pro
                    </button>
                  </div>
                </div>
                <div className="col-4">
                  <div className="upgrade-box-three">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="upgrade-box-three-title m-0">Basic</h6>
                      <p className="upgrade-box-three-text m-0">19,999/-</p>
                    </div>
                    <div className="upgrade-box-three-border"></div>
                    <p className="upgrade-text-three-bold m-0">
                      <img src={Light} alt="" /> 3 Active Job Post Every Month
                    </p>
                    <p className="upgrade-text-three-bold m-0">
                      <img src={Light} alt="" /> 3000 Candidates Every Month
                    </p>
                    <p className="upgrade-text-three-bold m-0">
                      <img src={Light} alt="" /> ATS
                    </p>
                    <p className="upgrade-text-three-bold m-0">
                      <img src={Light} alt="" /> Interviewer (Admin + 1)
                    </p>
                    <p className="upgrade-text-three-bold m-0">
                      <img src={Light} alt="" /> Interview Scheduler
                    </p>
                    <button className="upgrade-text-three-btn"
                      onClick={() => handlePlaceOrder(19999, "Basic", "Year")}
                    >
                      Upgrade to Basic
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row align-items-center">
                <div className="col-4">
                  <div className="upgrade-box-one">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="upgrade-box-title m-0">Premium</h6>
                      <p className="upgrade-box-text m-0">3,499/-</p>
                    </div>
                    <div className="upgrade-box-border"></div>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" />5 Active Job Post - Month
                    </p>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" /> 5000 Candidates - Month
                    </p>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" /> ATS
                    </p>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" /> Interviewer (Admin + 2)
                    </p>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" /> Work Flow Automation
                    </p>
                    <p className="upgrade-text-one-bold m-0">
                      <img src={Light} alt="" /> Interview Scheduler
                    </p>
                    <p className="upgrade-card-one-bold m-0">
                      Add ons <button className="extra-one">@Extra cost</button>
                    </p>
                    <p className="upgrade-card-one-bold m-0">
                      <img src={Light} style={{ paddingRight: "6px" }} alt="" />
                      +1 Interviewer @499/-
                    </p>
                    <button className="upgrade-starter-btn"
                    onClick={() => handlePlaceOrder(3499, "Premium", "Month")}
                    >
                      Upgrade to Premium
                    </button>
                  </div>
                </div>
                <div className="col-4">
                  <div className="upgrade-box-two">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="upgrade-box-two-title m-0">Premium Pro</h6>
                      <p className="upgrade-box-two-text m-0">4,999/-</p>
                    </div>
                    <div className="upgrade-box-two-border"></div>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> 10 Active Job Post Every Month
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> 10000 Candidates Every Month
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> ATS
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> Interviewer (Admin + 5)
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> Work Flow Automation
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> Interview Scheduler
                    </p>
                    <p
                      className="upgrade-card-one-bold m-0"
                      style={{ color: "#262626" }}
                    >
                      Add ons <button className="extra-one">@Extra cost</button>
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> Job Post @499/-
                    </p>
                    <p className="upgrade-text-two-bold m-0">
                      <img src={Dark} alt="" /> 1000 Candidates @999/-
                    </p>
                    <button className="upgrade-text-two-btn"
                      onClick={() => handlePlaceOrder(4999, "Premium_Pro", "Month")}
                    >
                      Upgrade to Premium Pro
                    </button>
                  </div>
                </div>
                <div className="col-4">
                  <div className="upgrade-box-three">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="upgrade-box-three-title m-0">Basic</h6>
                      <p className="upgrade-box-three-text m-0">1,999/-</p>
                    </div>
                    <div className="upgrade-box-three-border"></div>
                    <p className="upgrade-text-three-bold m-0">
                      <img src={Light} alt="" /> 3 Active Job Post Every Month
                    </p>
                    <p className="upgrade-text-three-bold m-0">
                      <img src={Light} alt="" /> 3000 Candidates Every Month
                    </p>
                    <p className="upgrade-text-three-bold m-0">
                      <img src={Light} alt="" /> ATS
                    </p>
                    <p className="upgrade-text-three-bold m-0">
                      <img src={Light} alt="" /> Interviewer (Admin + 1)
                    </p>
                    <p className="upgrade-text-three-bold m-0">
                      <img src={Light} alt="" /> Interview Scheduler
                    </p>
                    <button className="upgrade-text-three-btn"
                      onClick={() => handlePlaceOrder(1999, "Basic", "Month")}
                    >
                      Upgrade to Basic
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return null;
}

export default UpgradeRecruiterModel;
