import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import search from "../../assets/sideBar/search.svg";
import whiteSearch from "../../assets/white-search.svg";
import jobs from "../../assets/sideBar/job.svg";
import track from "../../assets/sideBar/track.svg";
import save from "../../assets/sideBar/save.svg";
import career from "../../assets/sideBar/career.svg";
import upSkill from "../../assets/sideBar/upSkill.svg";
import analysis from "../../assets/sideBar/analysis.svg";
import resume from "../../assets/sideBar/resume.svg";
import chat from "../../assets/sideBar/chat.svg";
import company from "../../assets/sideBar/company.svg";
import lightJobs from "../../assets/sideBar/light-job.svg";
import lightTrack from "../../assets/sideBar/light-track.svg";
import lightSave from "../../assets/sideBar/light-save.svg";
import lightCareer from "../../assets/sideBar/light-career.svg";
import lightUpSkill from "../../assets/sideBar/light-upSkills.svg";
import lightAnalysis from "../../assets/sideBar/light-analysis.svg";
// import lightResume from "../../assets/sideBar/light-resume.svg";
import lightChat from "../../assets/sideBar/light-chat.svg";
// import lightCompany from "../../assets/sideBar/light-company.svg";
import lightLogout from "../../assets/sideBar/light-logOut.svg";
import sideOff from "../../assets/sideBar/sidebar-off.svg";
import SideBarContext from "../../context/SidebarContext";
import { useMain } from "../../hook/useMain";

function Sidebar() {
  const sidebar = useContext(SideBarContext);
  const { isSidebarOpen, handleSidebarToggle /* hovered, toggleHover */ } =
    sidebar;
  const { sidebarSearch, setSidebarSearch } = useMain();

  const path = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div
        className="side-icon-box"
        style={{
          borderRadius: isSidebarOpen ? "0px 0 24px 24px" : "0px 0 0 24px",
        }}
      >
        <div className="sidebar-icon">
          <Link to="">
            <img src={search} alt="" />
          </Link>
          <div
            className="border"
            style={{ marginTop: "18px", marginBottom: "14px" }}
          ></div>
          <Link
            to="/dashboard"
            className={`${path.pathname === "/dashboard" ? "active" : ""}`}
          >
            {path.pathname === "/dashboard" ? (
              <img src={lightJobs} alt="" />
            ) : (
              <img src={jobs} alt="" />
            )}
          </Link>
          <Link
            to="/dashboard/track"
            className={`${
              path.pathname === "/dashboard/track" ? "active" : ""
            }`}
          >
            {path.pathname === "/dashboard/track" ? (
              <img src={lightTrack} alt="" />
            ) : (
              <img src={track} alt="" />
            )}
          </Link>
          <Link
            to="/dashboard/saved-applications"
            className={`${
              path.pathname === "/dashboard/saved-applications" ? "active" : ""
            }`}
          >
            {path.pathname === "/dashboard/saved-applications" ? (
              <img src={lightSave} alt="" />
            ) : (
              <img src={save} alt="" />
            )}
          </Link>
          <Link
            to="/dashboard/career"
            className={`${
              path.pathname === "/dashboard/career" ? "active" : ""
            }`}
          >
            {path.pathname === "/dashboard/career" ? (
              <img src={lightCareer} alt="" />
            ) : (
              <img src={career} alt="" />
            )}
          </Link>
          <Link
            to="/dashboard/up-skill"
            className={`${
              path.pathname === "/dashboard/up-skill" ? "active" : ""
            }`}
          >
            {path.pathname === "/dashboard/up-skill" ? (
              <img src={lightUpSkill} alt="" />
            ) : (
              <img src={upSkill} alt="" />
            )}
          </Link>
          <div
            className="border"
            style={{ marginTop: "18px", marginBottom: "14px" }}
          ></div>
          {path.pathname === "/dashboard/analysis" ? (
            <Link
              to=""
              className={`${
                path.pathname === "/dashboard/analysis" ? "active" : ""
              }`}
            >
              {path.pathname === "/dashboard/analysis" ? (
                <img src={lightAnalysis} alt="" />
              ) : (
                <img src={analysis} alt="" />
              )}
            </Link>
          ) : (
            <Link
              to=""
              className={`${
                path.pathname === "/dashboard/analysis/upgrade" ? "active" : ""
              }`}
            >
              {path.pathname === "/dashboard/analysis/upgrade" ? (
                <img src={lightAnalysis} alt="" />
              ) : (
                <img src={analysis} alt="" />
              )}
            </Link>
          )}
          <Link to="" className={`${path.pathname === "" ? "active" : ""}`}>
            {/* {path.pathname === "/dashboard/track" ? <img src={lightTrack} alt="" /> : <img src={track} alt="" />} */}
            <img src={resume} alt="" />
          </Link>
          <div
            className="border"
            style={{ marginTop: "18px", marginBottom: "14px" }}
          ></div>
          <Link
            to="/dashboard/chat-room"
            className={`${
              path.pathname === "/dashboard/chat-room" ? "active" : ""
            }`}
          >
            {path.pathname === "/dashboard/chat-room" ? (
              <img src={lightChat} alt="" />
            ) : (
              <img src={chat} alt="" />
            )}
          </Link>
          {/* <Link to="" className={`${path.pathname === "" ? "active" : ""}`}> */}
          {/* {path.pathname === "" ? <img src={lightTrack} alt="" /> : <img src={track} alt="" />} */}
          {/* <img src={company} alt="" />
          </Link> */}
          <Link to="" onClick={handleSidebarToggle}>
            {isSidebarOpen ? (
              <img src={sideOff} alt="" />
            ) : (
              <img src={lightLogout} alt="" />
            )}
          </Link>
        </div>
      </div>
      <div className={`sidebar ${isSidebarOpen ? "closed" : "open"}`}>
        <div className="sidebar-top-wrapper"></div>
        <div className="sidebar-text">
          <div className="sidebar-wrapper">
            <ul className="sidemenu">
              <li style={{ padding: "0 10px 0 0" }}>
                <div className="search-btn">
                  <div className="d-flex align-items-center justify-content-between">
                    <input
                      type="text"
                      className="search-input"
                      placeholder="Search"
                      value={sidebarSearch}
                      onFocus={() => navigate("/dashboard")}
                      onChange={(e) => setSidebarSearch(e.target.value)}
                    />
                    <img src={whiteSearch} alt="" />
                  </div>
                </div>
              </li>
              <li style={{ padding: "0 10px 0 0" }}>
                <p className="media-title m-0">Career</p>
              </li>
              <li
                className={`${path.pathname === "/dashboard" ? "active" : ""}`}
              >
                <Link to="/dashboard">Jobs/Internships</Link>
              </li>
              <li
                className={`${
                  path.pathname === "/dashboard/track" ? "active" : ""
                }`}
              >
                <Link to="/dashboard/track">Track Applications</Link>
              </li>
              <li
                className={`${
                  path.pathname === "/dashboard/saved-applications"
                    ? "active"
                    : ""
                }`}
              >
                <Link to="/dashboard/saved-applications">
                  Saved Applications
                </Link>
              </li>
              <li
                className={`${
                  path.pathname === "/dashboard/career" ? "active" : ""
                }`}
              >
                <Link to="/dashboard/career">Career Guidance</Link>
              </li>
              <li
                className={`${
                  path.pathname === "/dashboard/up-skill" ? "active" : ""
                }`}
              >
                <Link to="/dashboard/up-skill">Upskill Career</Link>
              </li>
              <li style={{ padding: "15px 10px 5px 0" }}>
                <p className="media-title m-0 p-0">Analytics and Tools</p>
              </li>
              {path.pathname === "/dashboard/analysis" ? (
                <li
                  className={`${
                    path.pathname === "/dashboard/analysis" ? "active" : ""
                  }`}
                >
                  <Link to="/dashboard/analysis/upgrade">Analysis</Link>
                </li>
              ) : (
                <li
                  className={`${
                    path.pathname === "/dashboard/analysis/upgrade"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link to="/dashboard/analysis">Analysis</Link>
                </li>
              )}
              <li
                className={`${
                  path.pathname === "/resume-builder/templates" ? "active" : ""
                }`}
              >
                <Link to="/resume-builder/templates">Resume Builder</Link>
              </li>
              <li style={{ padding: "0 10px 0 0" }}>
                <p className="media-title m-0">Others</p>
              </li>
              <li
                className={`${
                  path.pathname === "/dashboard/chat-room" ? "active" : ""
                }`}
              >
                <Link to="/dashboard/chat-room">Chat Room</Link>
              </li>
              {/* <li className={`${path.pathname === "" ? "active" : ""}`}>
                <Link to="">Company Ratings</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
