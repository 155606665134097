import React, { useContext, useRef, useState } from "react";
import Header from "../header/Header";
import downloadImg from "../../assets/recruiter/download-img.svg";
import editImg from "../../assets/recruiter/edit-img.svg";
import MobileResume from "../../assets/recruiter/mobile-resume.svg";
import pinImg from "../../assets/recruiter/pin-img.svg";
import jobIcon from "../../assets/job-icon.svg";
import top from "../../assets/top-arrow.svg";
import MobileContext from "../../context/mobile/MobileContext";
import MobileMenu from "../mobileMenu/MobileMenu";
import CoralSlider from "./CoralSlider";
import "./resume.css";
import { useNavigate } from "react-router-dom";
import { html2pdf } from "html2pdf.js";
import ResumeImg from "../../assets/profile-photo.svg";
import { AlertContainer } from "../Alerts/ToastifyContainer";

function GetMoreTemplate() {
  const [pdfBlob, setPdfBlob] = useState(null);

  const menuItem = useContext(MobileContext);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const { handleMobileToggle, mobileMenu } = menuItem;
  const navigate = useNavigate();
  const designBodyRef = useRef(null);

  function handleOnEdit() {
    navigate("/resume-builder/templates-paid");
  }

  const handleDownloadPDF = async () => {
    const designBody = designBodyRef.current;

    // Check if the designBody element exists
    if (!designBody) {
      console.error("Design body element not found");
      return;
    }

    // Use html2pdf to convert HTML content to a PDF
    html2pdf(designBody, {
      margin: 10,
      filename: "resume.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      output: async (pdf) => {
        setPdfBlob(pdf);
        // Now, you can send the pdf Blob to your server via a POST request
        // For example, using axios:
        // axios.post('YOUR_SERVER_ENDPOINT', pdf)
        //   .then(response => console.log('PDF uploaded successfully:', response.data))
        //   .catch(error => console.error('Error uploading PDF:', error.message));
        // const result = await UpdateResume(pdf, data?._id);
        // if (result?.statusCode === 200) {
        //   alert(result?.message);
        // }
        // console.log(result)
      },
    });
  };
  return (
    <>
      <AlertContainer/>
      <Header />
      <div className="template-container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h6 className="design-title">Basic CV Design</h6>
            <p className="design-text">Best for Business</p>
          </div>
          <div className="d-flex" style={{ gap: "16px" }}>
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                padding: "0",
              }}
              onClick={handleDownloadPDF}
            >
              <img src={downloadImg} alt="download" />
            </button>
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                padding: "0",
              }}
              onClick={() => handleOnEdit()}
            >
              <img src={editImg} alt="edi" />
            </button>
          </div>
        </div>
        <div ref={designBodyRef} className="design-body">
          {/* <img src={ResumeImg} className="img-fluid" alt="" /> */}
          <div id="my-resume" className="resume-design">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "0.5rem",
              }}
            >
              <div className="design-item">
                <h6 className="design-name">{userData?.name}</h6>
                <p className="design-designation">{userData?.jobTitle}</p>
                <div style={{ display: "flex" }}>
                  <p className="design-experience-text-1">{userData?.email}</p>{" "}
                  &nbsp; &nbsp;
                  <p className="design-experience-text-1">{userData?.phone}</p>
                </div>
              </div>
              <div>
                <img src={ResumeImg} alt="" style={{ marginRight: "2rem" }} />
              </div>
            </div>
            <div>
              <div className="d-flex ">
                <div className="design-experience">
                  <div>
                    <h6 className="design-experience-text">
                      DOB - {userData?.dob}
                    </h6>
                    <h6 className="design-experience-text">
                      Marital status - {userData?.maritalStatus}
                    </h6>
                    <h6 className="design-experience-text">
                      Address - {userData?.city}, {userData?.state}
                    </h6>
                    <h6 className="design-experience-text">
                      Working Status - {userData?.workingStatus}
                    </h6>
                  </div>
                  {userData?.certificates &&
                    userData.certificates.length > 0 && (
                      <div>
                        <p className="design-experience-title-2">Certificate</p>
                        {userData?.certificates?.map((item, index) => (
                          <div style={{ marginTop: "2px" }} key={index}>
                            <h6 className="design-experience-titles">
                              {item?.certificateName} -{" "}
                              <a href={item?.certificateLink}>link</a>
                            </h6>
                            <p className="design-experience-text">
                              Issue Date - {item?.issueDate}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  {userData?.workExperience &&
                    userData?.workExperience.length > 0 && (
                      <div>
                        <p className="design-experience-title-2">Experience</p>
                        {userData?.workExperience?.map((item, index) => (
                          <div style={{ marginTop: "3px" }} key={index}>
                            <h6 className="design-experience-titles">
                              {item?.designation}
                            </h6>
                            <p className="design-experience-text">
                              {item?.companyName}
                            </p>
                            <p className="design-experience-text-1">
                              {item?.from} - {item?.to}
                              {item?.companyType}
                            </p>
                            <p className="design-experience-text">
                              {item?.describeRole}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                </div>
                <div className="design-skill" style={{ marginLeft: "1rem" }}>
                  {/* <div> */}
                  {/* <p className="design-experience-text-1">
                                    {userData?.email}
                                  </p>
                                  <p className="design-experience-text-1">
                                    {userData?.phone}
                                  </p> */}
                  {/* <p className="design-experience-text-1">
                                    {userData?.city === "undefined"
                                      ? ""
                                      : userData?.city}
                                  </p> */}
                  {/* </div> */}

                  {/* skills  */}
                  {userData?.academic && userData?.academic.length > 0 && (
                    <div>
                      <p className="design-experience-title-2">
                        Academic Details
                      </p>
                      {userData?.academic?.map((item, index) => (
                        <div style={{ marginTop: "3px" }} key={index}>
                          <h6 className="design-experience-titles">
                            {item?.instituteName} - {item?.degreeName}
                          </h6>
                          <p className="design-experience-text-1">
                            {item?.startYear} - {item?.endYear}
                          </p>
                          <p className="design-experience-text">
                            {item?.degreeType}
                          </p>
                          <p className="design-experience-text">
                            {item?.highlight}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                  {userData?.skills && (
                    <div>
                      <div>
                        <p className="design-experience-titles">Skills</p>
                      </div>
                      <div>
                        {userData?.skills.map((item, index) => (
                          // <div key={index}>
                          // </div>
                          <p className="design-experience-text">{item}</p>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="design-save-btn">
          Save <img src={pinImg} alt="" />
        </button>
        <div style={{ marginTop: "24px" }}>
          <p className="resume-tamp-text">More Designs</p>
          <div className="coral-slider">
            <CoralSlider />
          </div>
        </div>

        <MobileMenu />
        <div className="mobile-menu-btn" onClick={handleMobileToggle}>
          <img
            src={top}
            alt=""
            style={{
              transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 150ms ease",
              height: "14px",
            }}
          />
          <img src={jobIcon} alt="" />
        </div>
      </div>
    </>
  );
}

export default GetMoreTemplate;
