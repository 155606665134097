import React, { useState } from "react";
import Header from "../../components/header/Header";
// import cap from "../../assets/login/cap.svg";
// import img1 from "../../assets/login/ats-logo.svg";
// import img2 from "../../assets/login/apply-logo.svg";
// import img3 from "../../assets/login/skill-logo.svg";
// import img4 from "../../assets/login/up-skills.svg";
import google from "../../assets/login/google-icon.svg";
import arrow from "../../assets/login/left-arrow.svg";
import hoverArrow from "../../assets/login/left-arrow-hover.svg";
import { Link, useNavigate } from "react-router-dom";
import "../recruiterPages/css/sign-in.css";
import { useMain } from "../../hook/useMain";
import {
  emailRegex,
  nameRegex,
  phoneRegex,
} from "../regexPatterns/RegexPatterns";
import Loader from "../loader/Loader";
import CareerHead from "../header/CareerHead";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from 'react-toastify'

function CareerSignUp() {
  const [showArrow, setShowArrow] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    mobileNo: "",
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [mobileNoError, setMobileNoError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const { CarrerSignUp } = useMain();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "name") {
      if (value === "") {
        setNameError("email is required");
      } else {
        setNameError(null);
      }
    }
    if (name === "mobileNo") {
      if (value === "") {
        setMobileNoError("mobileNo is required");
      } else {
        setMobileNoError(null);
      }
    }
    if (name === "email") {
      if (value === "") {
        setEmailError("email is required");
      } else {
        setEmailError(null);
      }
    }
    if (name === "password") {
      if (value === "") {
        setPasswordError("password is required");
      } else {
        setPasswordError(null);
      }
    }
  };

  async function SignIn(e) {
    e.preventDefault();
    const { name, mobileNo, email, password } = formData;
    if (name === "" || !nameRegex.test(name)) {
      setNameError("Name is required");
      return;
    }
    if (mobileNo === "" || !phoneRegex.test(mobileNo)) {
      setMobileNoError("Please enter valid number");
      return;
    }
    if (email === "" || !emailRegex.test(email)) {
      setEmailError("Please enter valid email");
      return;
    }
    if (password === "" || password.length < 6) {
      setPasswordError("Password must be 6 characters");
      return;
    }

    if (name && mobileNo && email && password) {
      setLoader(true);

      const data = {
        name,
        mobileNo,
        email,
        password,
      };
      const token = localStorage.getItem("recruiterToken");
      const result = await CarrerSignUp(data, token);

      if (result?.statusCode === 200) {
        setLoader(false);
        navigate("/career/create-profile");
        localStorage.setItem("career_id", result?.data._id);
        localStorage.setItem("career_data", JSON.stringify(result?.data));
        toast.success(result?.message);
      } else if (result?.statusCode === 400) {
        toast.error(result?.message);
        setLoader(false);
      } else {
        toast.error("Something went wrong.");
        setLoader(false);
      }
    } else {
      toast.info("All fields are required.");
      setLoader(false);
    }
  }

  // const initialValue = {
  //   name: "",
  //   content: "",
  //   image: {},
  // };
  return (
    <>
      <AlertContainer />
      <CareerHead link="/career/sign-in" />
      {loader ? (
        <Loader />
      ) : (
        <section className="login-hero">
          <div className="login-container">
            <div className="sign-up m-0" style={{ width: "400px" }}>
              <div className="text-center">
                <h6 className="sign-title">Sign up</h6>
                {/* <button className="google-btn">
                  <img src={google} className="pe-2" alt="" /> Google
                </button> */}
                <div className="form-border"></div>
                <form>
                  <div>
                    <input
                      type="text"
                      placeholder="Name"
                      className="sign-input"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {nameError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{nameError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="mobileNo Number"
                      className="sign-input"
                      name="mobileNo"
                      value={formData.mobileNo}
                      onChange={handleChange}
                    />
                    {mobileNoError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{mobileNoError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder="Email address"
                      className="sign-input"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {emailError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{emailError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="password"
                      placeholder="Password"
                      className="sign-input"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {passwordError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{passwordError}</p>
                    )}
                  </div>
                  <button
                    className="sign-up-btn"
                    onMouseEnter={() => setShowArrow(true)}
                    onMouseLeave={() => setShowArrow(false)}
                    onClick={SignIn}
                  >
                    Sign up to Start{" "}
                    <img
                      src={showArrow ? hoverArrow : arrow}
                      style={{ marginLeft: "4px" }}
                      alt=""
                    />
                  </button>
                  <p className="link-btn m-0">
                    I already have an account{" "}
                    <Link to="/career/sign-in">
                      <span>Sign-in</span>
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default CareerSignUp;
