import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { useNavigate } from "react-router-dom";
import closeBtn from "../assets/close-btn.svg";
import downArrow from "../assets/select-down.svg";
import downArrowHover from "../assets/mobile/down-arrow-hover.svg";
import { City } from "country-state-city";
import { useMain } from "../hook/useMain";
import "../App.css";
import "react-select-search/style.css";
import Loader from "../components/loader/Loader";
import "./css/dropdown.css";
import SearchBar from "./SearchBar";
import { cityList } from "../context/searchList/cityList";
import useOutsideClick from "./outsideClick/useOutsideClick";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
// import rightCheck from "../assets/select-item-check.svg";

function UserMobileUpdate() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("auth") === "true";
    if (!isAuthenticated) {
      navigate("/log-in");
      return;
    }
  }, []);
  const id = localStorage.getItem("userId");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const { UserUpdateMobileNo } = useMain();
  const [phone, setPhone] = useState("");
  const [loader, setLoader] = useState(false);

  async function SetPreferences() {
    const data = {
      phone: phone,
    };

    if (phone) {
      setLoader(true);
      const result = await UserUpdateMobileNo(data, id);
      if (result?.statusCode === 200) {
        setLoader(false);
        toast.success(result?.message);
        setTimeout(() => {
          localStorage.setItem("userData", JSON.stringify(result?.data));
          // navigate("/set-job");
          navigate("/dashboard");
        }, 3000);
      } else {
        setLoader(false);
        toast.error("Somthing went wrong");
      }
    } else {
      return toast.info("Please fill in all fields.");

      setLoader(false);
    }
  }
  const handleNuber = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^+\d]/g, "");
    setPhone(inputValue);
  };

  return (
    <>
      <AlertContainer />
      <Header />
      {loader ? (
        <Loader />
      ) : (
        <section className="job-section">
          <div className="set-job-box">
            <h6 className="set-job-title">Update Mobile Number</h6>
            {/* <p className="set-job-text">
              Customize your job search experience by setting your job
              preferences today and let us do the rest!
            </p> */}

            <div className="form-border"></div>
            <label htmlFor="" className="job-label">
              Mobile Number
            </label>
            <input
              type="text"
              className="job-input"
              placeholder="Enter Mobile Number"
              value={phone}
              onChange={handleNuber}
              maxLength={13}
            />

            <button
              className="set-job-btn"
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              onClick={SetPreferences}
            >
              Update Number
            </button>
          </div>
        </section>
      )}
    </>
  );
}

export default UserMobileUpdate;
