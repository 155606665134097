import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import search from "../../assets/sideBar/search.svg";
import whiteSearch from "../../assets/white-search.svg";
import addJob from "../../assets/recruiter-dashboard/add-job.svg";
import allJob from "../../assets/recruiter-dashboard/all-job.svg";
import manage from "../../assets/recruiter-dashboard/manage.svg";
import calender from "../../assets/recruiter-dashboard/calender.svg";
import collaborate from "../../assets/recruiter-dashboard/collaborate.svg";
import career from "../../assets/sideBar/career.svg";


import lightAddJob from "../../assets/recruiter-dashboard/add-job-hover.svg";
import lightAllJob from "../../assets/recruiter-dashboard/all-job-hover.svg";
import lightManage from "../../assets/recruiter-dashboard/manage-hover.svg";
import lightCalender from "../../assets/recruiter-dashboard/calender-hover.svg";
import lightCollaborate from "../../assets/recruiter-dashboard/collaborate-hover.svg";
import lightCareer from "../../assets/sideBar/light-career.svg";
import lightLogout from "../../assets/sideBar/light-logOut.svg";
import sideOff from "../../assets/sideBar/sidebar-off.svg";
import SideBarContext from "../../context/SidebarContext";

function RecruiterSidebar() {
  const sidebar = useContext(SideBarContext);
  const { isSidebarOpen, handleSidebarToggle } = sidebar;

  const path = useLocation();

  return (
    <>
      <div
        className="side-icon-box"
        style={{
          borderRadius: isSidebarOpen ? "0px 0 24px 24px" : "0px 0 0 24px",
        }}
      >
        <div className="recruiter-icon">
          <Link to="">
            <img src={search} alt="" />
          </Link>
          <div
            className="border"
            style={{ marginTop: "18px", marginBottom: "14px" }}
          ></div>
          {/* <Link
            to="/dashboard/recruiter"
            className={`${
              path.pathname === "/dashboard/recruiter" ? "active" : ""
            }`}
          >
            {path.pathname === "/dashboard/recruiter" ? (
              <img src={lightDashboard} alt="" />
            ) : (
              <img src={dashboard} alt="" />
            )}
          </Link> */}
          <Link
            to="/dashboard/post-new-job"
            className={`${
              path.pathname === "/dashboard/post-new-job" ? "active" : ""
            }`}
          >
            {path.pathname === "/dashboard/post-new-job" ? (
              <img src={lightAddJob} alt="" />
            ) : (
              <img src={addJob} alt="" />
            )}
          </Link>
          <Link to="/dashboard/all-job" className={`${path.pathname === "/dashboard/all-job" ? "active" : ""}`}>
            {path.pathname === "/dashboard/all-job" ? (
              <img src={lightAllJob} alt="" />
            ) : (
              <img src={allJob} alt="" />
            )}
          </Link>
          <Link to="" className={`${path.pathname === "" ? "active" : ""}`}>
            {path.pathname === "" ? (
              <img src={lightManage} alt="" />
            ) : (
              <img src={manage} alt="" />
            )}
          </Link>
          <div
            className="border"
            style={{ marginTop: "18px", marginBottom: "14px" }}
          ></div>
          <Link to="" className={`${path.pathname === "" ? "active" : ""}`}>
            {path.pathname === "" ? (
              <img src={lightCareer} alt="" />
            ) : (
              <img src={career} alt="" />
            )}
          </Link>
          <div
            className="border"
            style={{ marginTop: "18px", marginBottom: "14px" }}
          ></div>
          <Link to="" className={`${path.pathname === "" ? "active" : ""}`}>
            {path.pathname === "" ? (
              <img src={lightCalender} alt="" />
            ) : (
              <img src={calender} alt="" />
            )}
          </Link>
          <Link
            to="/dashboard/chat-room"
            className={`${
              path.pathname === "/dashboard/chat-room" ? "active" : ""
            }`}
          >
            {path.pathname === "/dashboard/chat-room" ? (
              <img src={lightCollaborate} alt="" />
            ) : (
              <img src={collaborate} alt="" />
            )}
          </Link>
          {/* <Link to="" className={`${path.pathname === "" ? "active" : ""}`}> */}
          {/* {path.pathname === "" ? <img src={lightTrack} alt="" /> : <img src={track} alt="" />} */}
          {/* <img src={company} alt="" />
          </Link> */}
          <Link to="" onClick={handleSidebarToggle}>
            {isSidebarOpen ? (
              <img src={sideOff} alt="" />
            ) : (
              <img src={lightLogout} alt="" />
            )}
          </Link>
        </div>
      </div>
      <div className={`recruiter-sidebar ${isSidebarOpen ? "closed" : "open"}`}>
        <div className="sidebar-top-wrapper"></div>
        <div className="sidebar-text">
          <div className="sidebar-wrapper">
            <ul className="sidemenu">
              <li style={{ padding: "0 10px 0 0" }}>
                <div className="search-btn">
                  <div className="d-flex align-items-center justify-content-between">
                    <input
                      type="text"
                      className="search-input"
                      placeholder="Search"
                    />
                    <img src={whiteSearch} alt="" />
                  </div>
                </div>
              </li>
              <li style={{ padding: "0 10px 0 0" }}>
                <p className="media-title m-0">Dashboard</p>
              </li>
              {/* <li
                className={`${
                  path.pathname === "/dashboard/recruiter" ? "active" : ""
                }`}
              >
                <Link to="/dashboard/recruiter">Dashboard</Link>
              </li> */}
              <li
                className={`${
                  path.pathname === "/dashboard/post-new-job" ? "active" : ""
                }`}
              >
                <Link to="/dashboard/post-new-job">Post New Job</Link>
              </li>
              <li className={`${path.pathname === "/dashboard/all-job" ? "active" : ""}`}>
                <Link to="/dashboard/all-job">All Jobs</Link>
              </li>
              <li className={`${path.pathname === "" ? "active" : ""}`}>
                <Link to="">Manage Application</Link>
              </li>
              <li style={{ padding: "15px 10px 5px 0" }}>
                <p className="media-title m-0 p-0">New Tools</p>
              </li>
              <li className={`${path.pathname === "" ? "active" : ""}`}>
                <Link to="">Automatic Application</Link>
              </li>
              <li style={{ padding: "0 10px 0 0" }}>
                <p className="media-title m-0">Analytics and Tools</p>
              </li>
              <li className={`${path.pathname === "" ? "active" : ""}`}>
                <Link to="">Calendar</Link>
              </li>
              <li className={`${path.pathname === "" ? "active" : ""}`}>
                <Link to="">Collaborate</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruiterSidebar;
