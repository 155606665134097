import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/Header";
import Sidebar from "../components/sideBar/Sidebar";
import "./dashboard.css";
import SideBarContext from "../context/SidebarContext";
import top from "../assets/top-arrow.svg";
import jobIcon from "../assets/job-icon.svg";
import "./dashboard.css";
import box from "../assets/office-building.png";
import left from "../assets/left-arrow.svg";
import MobileMenu from "../components/mobileMenu/MobileMenu";
import MobileContext from "../context/mobile/MobileContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import axios from "axios";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";

function BusinessCard({ data }) {
  console.log(data)
  const navigate = useNavigate();
  function handleOnApply(id) {
    navigate(`/dashboard/job-description/${id}`);
  }
  return (
    <>
      <div className="jobs-card">
        <button className="jobs-actively">Actively hiring</button>
        <div className="d-flex align-items-center">
          <img src={data?.recruiter[0]?.companyLogo ? data?.recruiter[0]?.companyLogo : box} alt=""  style={{width:"45px", height:"45px"}}/>
          <div className="ps-2">
            <h6 className="jobs-title m-0">
              {data?.jobInfo?.position || "Human Resources"}
            </h6>
            <p className="jobs-text m-0">
              {data?.companyName || "Youth Empower Foundation"}
            </p>
          </div>
        </div>
        <div className="jobs-card-border"></div>
        <ul className="pl-2 m-0">
          <li className="save-item">{data?.jobInfo?.workPlace || "Delhi"}</li>
          <li className="save-item">
            {" "}
            {data?.jobInfo?.jobType || "Full-Time"}
          </li>
          <li className="save-item">
            {" "}
            {data?.jobInfo?.candidateJoining || "Immediately"}
          </li>
        </ul>
        {/* <div className="d-flex align-items-center"> */}
        {/* <p className="jobs-item m-0">{data?.jobInfo?.workPlace || "Delhi"}</p> */}
        {/* <div className="jobs-dot"></div>
          <p className="jobs-item m-0">
            {data?.jobInfo?.jobType || "Full-Time"}
          </p>
          <div className="jobs-dot"></div>
          <p className="jobs-item m-0">
            {data?.jobInfo?.candidateJoining || "Immediately"}
            </p>
          </div> */}
        <div className="d-flex align-items-center justify-content-between">
          <div
            className="job-apply-btn w-100"
            onClick={(e) => handleOnApply(data?._id)}
            >
            <button>Apply</button>
            <img src={left} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

function JobDescription() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [loader, setLoader] = useState(false);
  const [applied, setApplied] = useState(false);
  const [createDate, setCreateDate] = useState("");
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getData(id);
      const appliedId = JSON.parse(
        localStorage.getItem("userData")
      )?.appliedjobs;

      const data = appliedId?.filter((item) => item === id);
      setApplied(data);
    }
  }, [id]);
  const [random, setRandom] = useState([]);
  const [random1, setRandom1] = useState([]);
  const getRandomData = async () => {
    try {
      const res1 = await axios.get(
        "https://recrutify-backend.vercel.app/api/user/random"
      );
      const res2 = await axios.get(
        "https://recrutify-backend.vercel.app/api/user/random2"
      );
      setRandom(res1?.data?.data);
      setRandom1(res2?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getRandomData();
  }, [id]);
  const { getSingleUserPost } = useMain();
  const [post, setPost] = useState({});
  const getData = async (id) => {
    setLoader(true);
    const res = await getSingleUserPost(id);
    if (res?.statusCode === 200) {
      setPost(res?.data);
      timeAgo(res?.data.createdAt);
      setLoader(false);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };

  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  const sidebar = useContext(SideBarContext);
  const menuItem = useContext(MobileContext);
  const { isSidebarOpen } = sidebar;
  const { handleMobileToggle, mobileMenu } = menuItem;
  function HandleApply(id) {
    navigate(`/dashboard/job-apply/${id}`);
  }

  return (
    <>
      <AlertContainer/>
      <Header />
      {loader ? (
        <Loader />
      ) : (
        <section className="dashboard">
          <div className="dashboard-box">
            <div className="sidebar-scroll">
              <Sidebar />
            </div>
            <div className="desktop-show-hide">
              <div
                className="job-detail-page"
                style={{ left: isSidebarOpen ? "85px" : "354px" }}
              >
                <div>
                  <div className="job-description">
                    <h6
                      className="job-description-title"
                      style={{ marginTop: "8px" }}
                    >
                      More Jobs
                    </h6>
                    <div style={{ height: "40vh", overflowY: "scroll" }}>
                      {random &&
                        random.length > 0 &&
                        random.map((e) => <BusinessCard data={e} />)}
                    </div>
                    {/* <BusinessCard /> */}
                  </div>
                  <div className="job-description">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="job-description-title">
                        Recommended Jobs
                      </h6>
                      <p className="view-all-btn">View all</p>
                    </div>
                    <div style={{ height: "40vh", overflowY: "scroll" }}>
                      {random1 &&
                        random1.length > 0 &&
                        random1.map((e) => <BusinessCard data={e} />)}
                    </div>
                  </div>
                </div>
                <div className="job-details">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ marginBottom: "16px" }}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={post?.userId?.companyLogo ? post?.userId?.companyLogo : box}
                        style={{ height: "60px", width: "60px" }}
                        alt=""
                      />
                      <div className="ps-3">
                        <h6 className="jobs-details-title">
                          {post?.jobInfo?.position}
                        </h6>
                        <p className="jobs-details-text m-0">
                          {post?.companyName} . {createDate}
                        </p>
                      </div>
                    </div>
                    <button
                      className="detail-apply-btn cursor-pointer"
                      disabled={applied[0] == id && "green"}
                      style={{
                        background: applied[0] == id && "green",
                        color: applied[0] == id && "white",
                      }}
                      onClick={() => HandleApply(post?._id)}
                    >
                      {applied[0] == id ? " Applied" : " Apply"}
                    </button>
                  </div>
                  <ul className="job-details-ul">
                    <li>
                      {post?.jobInfo?.jobType} · {post?.jobInfo?.workPlaceType}
                    </li>
                    <li>
                      {post?.userId?.companyStrength} employees ·{" "}
                      {post?.userId?.industry}
                    </li>
                    {/* <li>See how you compare to 843 applicants.</li>
                    <li>Skills: Wireframing, User Experience (UX), +8 more</li>
                    <li>Skills: Wireframing, User Experience (UX), +8 more</li> */}
                  </ul>
                  <h6 className="jobs-details-title">About Company</h6>
                  <p className="job-details-text m-0">
                    {post?.about?.split("\n")?.map((e) => (
                      <>
                        {e}
                        <br />
                      </>
                    ))}
                  </p>
                  <h6
                    className="jobs-details-title"
                    style={{ marginTop: "12px" }}
                  >
                    Job Brief
                  </h6>
                  <p className="job-details-text m-0">
                    {post?.jobInfo?.jobBrief?.split("\n")?.map((e) => (
                      <>
                        {e}
                        <br />
                      </>
                    ))}
                  </p>
                  <h6
                    className="jobs-details-title"
                    style={{ marginTop: "12px" }}
                  >
                    Responsibilities
                  </h6>
                  <p className="job-details-text m-0">
                    The incumbents overall responsibilities will include, but
                    are not limited to, the following:
                  </p>
                  <ul className="job-details-ul">
                    {post?.jobInfo?.responsibility?.split("\n")?.map((e, i) => (
                      <li
                        className="post-review-list"
                        key={`responsibility-${i}`}
                      >
                        {e}
                      </li>
                    ))}
                  </ul>
                  <h6
                    className="jobs-details-title"
                    style={{ marginTop: "12px" }}
                  >
                    Qualification
                  </h6>
                  <ul className="job-details-ul">
                    {post?.jobInfo?.qualification?.split("\n")?.map((e, i) => (
                      <li
                        className="post-review-list"
                        key={`qualification-${i}`}
                      >
                        {e}
                      </li>
                    ))}
                  </ul>
                  <h6
                    className="jobs-details-title"
                    style={{ marginTop: "12px" }}
                  >
                    Benefits
                  </h6>
                  <ul className="job-details-ul">
                    {post?.jobInfo?.benefits?.split("\n")?.map((e, i) => (
                      <li className="post-review-list" key={`banefits-${i}`}>
                        {e}
                      </li>
                    ))}
                  </ul>
                  <h6
                    className="jobs-details-title"
                    style={{ marginTop: "12px" }}
                  >
                    Learn more about the company
                  </h6>
                  <div className="row">
                    <div className="col-2">
                      <h6 className="job-detail-link-title">Website</h6>
                      <Link className="job-detail-link">{post?.weblink}</Link>
                    </div>
                    <div className="col-2">
                      <h6 className="job-detail-link-title">Phone Number</h6>
                      <Link className="job-detail-link">{post?.mobileNo}</Link>
                    </div>
                    {/* <div className="col-3">
                      <h6 className="job-detail-link-title">Company rating</h6>
                      <Link className="job-detail-link">
                        View company reviews
                      </Link>
                    </div> */}
                  </div>
                  <div className="row my-3">
                    <div className="col-2">
                      <h6 className="job-detail-link-title">Industry</h6>
                      <Link className="job-detail-link">
                        {post?.industry}
                      </Link>
                    </div>
                    <div className="col-2">
                      <h6 className="job-detail-link-title">Company Size</h6>
                      <Link className="job-detail-link">{post?.companyStrength} employess</Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <h6 className="job-detail-link-title">Head Quarters</h6>
                      <Link className="job-detail-link">
                        {post?.headQuater}
                      </Link>
                    </div>
                    <div className="col-2">
                      <h6 className="job-detail-link-title">Founded</h6>
                      <Link className="job-detail-link">
                        {post?.yearFounded}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mobile-show-hide mobile-recommended">
              <div className="job-mobile-details">
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ marginBottom: "16px" }}
                >
                  <div className="d-flex align-items-center">
                    <img src={post?.userId?.companyLogo ? post?.userId?.companyLogo : box} alt="" style={{width:"45px", height:"45px"}} />
                    <div className="ps-3">
                      <h6 className="jobs-details-title">
                        {post?.jobInfo?.position}
                      </h6>
                      <p className="jobs-details-text m-0">
                        {post?.companyName} . {createDate}
                      </p>
                    </div>
                  </div>
                  <button
                    className="detail-apply-btn"
                    disabled={applied[0] == id && "green"}
                    style={{
                      background: applied[0] == id && "green",
                      color: applied[0] == id && "white",
                    }}
                    onClick={() => HandleApply(post?._id)}
                  >
                    {applied[0] == id ? "Applied" : "Apply"}
                  </button>
                </div>
                <div className="detail-border"></div>
                <ul className="job-details-ul">
                  <li>
                    {post?.jobInfo?.jobType} · {post?.jobInfo?.workPlaceType}
                  </li>
                  <li>
                    {post?.userId?.companyStrength} employees ·{" "}
                    {post?.userId?.industry}
                  </li>
                  {/* <li>See how you compare to 843 applicants.</li>
                  <li>Skills: Wireframing, User Experience (UX), +8 more</li>
                  <li>Skills: Wireframing, User Experience (UX), +8 more</li> */}
                </ul>
                <div className="detail-border"></div>
                <h6 className="jobs-details-title">About Company</h6>
                <p className="job-details-text m-0">
                  {post?.about?.split("\n")?.map((e) => (
                    <>
                      {e}
                      <br />
                    </>
                  ))}
                </p>

                <div className="detail-border"></div>
                <h6
                  className="jobs-details-title"
                  style={{ marginTop: "12px" }}
                >
                  Job Brief
                </h6>
                <p className="job-details-text m-0">
                  {post?.jobInfo?.jobBrief?.split("\n")?.map((e) => (
                    <>
                      {e}
                      <br />
                    </>
                  ))}
                </p>

                <h6
                  className="jobs-details-title"
                  style={{ marginTop: "12px" }}
                >
                  Responsibilities
                </h6>
                <p className="job-details-text m-0">
                  The incumbents overall responsibilities will include, but are
                  not limited to, the following:
                </p>
                <ul className="job-details-ul">
                  {post?.jobInfo?.responsibility?.split("\n")?.map((e, i) => (
                    <li
                      className="post-review-list"
                      key={`responsibility-${i}`}
                    >
                      {e}
                    </li>
                  ))}
                </ul>
                <h6
                  className="jobs-details-title"
                  style={{ marginTop: "12px" }}
                >
                  Qualification
                </h6>
                <ul className="job-details-ul">
                  {post?.jobInfo?.qualification?.split("\n")?.map((e, i) => (
                    <li className="post-review-list" key={`qualification-${i}`}>
                      {e}
                    </li>
                  ))}
                </ul>
                <h6
                  className="jobs-details-title"
                  style={{ marginTop: "12px" }}
                >
                  Benefits
                </h6>
                <ul className="job-details-ul">
                  {post?.jobInfo?.benefits?.split("\n")?.map((e, i) => (
                    <li className="post-review-list" key={`banefits-${i}`}>
                      {e}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="job-link-box">
                <h6
                  className="jobs-details-title"
                  style={{ marginTop: "12px" }}
                >
                  Learn more about the company
                </h6>
                <div className="row">
                  <div className="col-6">
                    <h6 className="job-detail-link-title">Website</h6>
                    <Link className="job-detail-link">{post?.weblink}</Link>
                  </div>
                  <div className="col-6">
                    <h6 className="job-detail-link-title">Phone Number</h6>
                    <Link className="job-detail-link">{post?.mobileNo}</Link>
                  </div>
                  {/* <div className="col-6">
                    <h6 className="job-detail-link-title">Company rating</h6>
                    <Link className="job-detail-link">
                      View company reviews
                    </Link>
                  </div> */}
                  <div className="col-6">
                    <h6 className="job-detail-link-title">Company Size</h6>
                    <Link className="job-detail-link">{post?.companyStrength} employess</Link>
                  </div>
                  <div className="col-6">
                    <h6 className="job-detail-link-title">Industry</h6>
                    <Link className="job-detail-link">{post?.industry}</Link>
                  </div>
                  <div className="col-6">
                    <h6 className="job-detail-link-title">Head Quarters</h6>
                    <Link className="job-detail-link">{post?.headQuater}</Link>
                  </div>
                  <div className="col-6">
                    <h6 className="job-detail-link-title">Founded</h6>
                    <Link className="job-detail-link">{post?.yearFounded}</Link>
                  </div>
                </div>
              </div>
              <MobileMenu />
              <div className="mobile-menu-btn" onClick={handleMobileToggle}>
                <img
                  src={top}
                  alt=""
                  style={{
                    transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "transform 150ms ease",
                    height: "14px",
                  }}
                />
                <img src={jobIcon} alt="" />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default JobDescription;
