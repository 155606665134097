import React from "react";
import "./sub-header.css";
import Calender from "../../assets/recruiter-subHeader/calender.svg";
import chatRoom from "../../assets/recruiter-subHeader/chatroom.svg";
import dashboard from "../../assets/recruiter-subHeader/dashboard.svg";
import ActiveDashboard from "../../assets/recruiter-subHeader/light-dashboard.svg";
import CalenderActive from "../../assets/recruiter-subHeader/calender-active.svg";
// import ActiveAutomate from "../../assets/recruiter-subHeader/light-automate.svg";
import { Link, useLocation } from "react-router-dom";

function CareerHeader() {
  let path = useLocation();
  let pathName = path.pathname;
  return (
    <>
      <div className="sub-header-container">
        <div className="sub-header justify-content-center">
          <div style={{ display: "flex", gap: "16px" }}>
            <Link
              to="/career/table"
              className={`${
                pathName === "/career/table"
                  ? "sub-active"
                  : "sub-header-dashboard-btn"
              } `}
            >
              <img
                src={pathName === "/career/table" ? ActiveDashboard : dashboard}
                style={{ marginRight: "4px" }}
                alt=""
              />
              Dashboard
            </Link>
            <Link to="/career/calender"  className={`${
                pathName === "/career/calender" ? "sub-active" : "sub-header-dashboard-btn"
              } `}>
              <img src={pathName === "/career/calender" ? CalenderActive : Calender} style={{ marginRight: "4px" }} alt="" />
              Calender
            </Link>
            <Link
              to=""
              className={`${
                pathName === "" ? "sub-active" : "sub-header-dashboard-btn"
              } `}
            >
              <img src={chatRoom} style={{ marginRight: "4px" }} alt="" />
              Chatroom
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default CareerHeader;
