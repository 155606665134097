import React, { useContext, useEffect, useRef, useState } from "react";
import closeBtn from "../../../assets/blue-close-btn.svg";
import "../../../dashboard/dashboard.css";
// import leftArrow from "../assets/left-arrow.svg";
import box from "../../../assets/desktop-box.svg";
import all from "../../../assets/all.svg";
import sendBtn from "../../../assets/modal-send-btn.svg";
import emojiBtn from "../../../assets/chat-modal/emoji-btn.svg";
import linkBtn from "../../../assets/chat-modal/link-btn.svg";
import calenderBtn from "../../../assets/chat-modal/calender-btn.svg";
import AllModalContext from "../../../context/allModal/AllModalContext";
import ProfileIcon from "../../../assets/3682281.png"
import useLogo from "../../../assets/user.svg"
import { useMain } from "../../../hook/useMain";
import moment from "moment";
import { useParams } from "react-router-dom";

function RecruiterChat2({ setToggle, userId, name, company, jobId }) {
  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState("");
  const bottomRef = useRef(null);
  const { userInterviewerChat, interviewerChat } = useMain();

//   const { id } = useParams();

  useEffect(() => {
    const interval = setInterval(() => {
      if (jobId) {
        getCaht(jobId);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getCaht = async (id) => {
    const result = await userInterviewerChat(userId, id);
    if (result?.statusCode === 200) {
      setChat(result?.data?.message);
    }
  };

  const sendMessage = async () => {
    const interviewerId = JSON.parse(
      localStorage.getItem("Interviewer_data")
    )?._id;
    const data = {
      receiverId: userId,
      msg: message,
      receiverName: "user",
      chatpanel: "interviewer",
    };

    const result = await interviewerChat(data, jobId);
    if (result?.statusCode === 200) {
      setMessage("");
      getCaht(jobId);
    }
  };
  return (
    <>
      <div className="chat-modal">
        <div className="chat-filter-box">
          <button className="close-btn">
            <img src={closeBtn} alt="" onClick={() => setToggle("")} />
          </button>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <img src={useLogo} alt="" style={{ paddingRight: "8px" }} />
              <div>
                <h6 className="chat-modal-title m-0">{name}</h6>
                <p className="chat-modal-text m-0">{company}</p>
              </div>
            </div>
            <div className="d-flex">
              {/* <button className="chat-modal-btn">Report</button>
              <button className="chat-modal-btn">Delete Chat</button> */}
              {/* <button className="chat-modal-btn pe-0">
                <img src={all} alt="" />
              </button> */}
            </div>
          </div>
          <div className="chat-modal-border"></div>
          <div className="text-center">
            {/* <p className="m-0 chat-modal-date">5th Aug, 2020</p> */}
            <div
              className="mb-5"
              style={{
                height: "380px",
                overflow: "auto",
                scrollbarWidth: "none",
              }}
            >
              {chat?.length > 0 &&
                chat?.map((item, index) => (
                  <div
                    className="chat-modal-container"
                    style={{ height: "max-content" }}
                    ref={bottomRef}
                  >
                    {item?.receiver === "user" && (
                      <div
                        className="chat-message-right"
                        style={{ width: "max-content" }}
                      >
                        <p className="m-0">{item?.content}</p>
                        <p className="chat-message-time m-0">
                          {moment(item?.time).format("h:mm A")}
                        </p>
                      </div>
                    )}

                    {item?.receiver === "interviewer" && (
                      <div
                        className="chat-message-left"
                        style={{ width: "max-content" }}
                      >
                        <p className="m-0">{item?.content}</p>
                        <p className="chat-message-time m-0">
                          {moment(item?.time).format("h:mm A")}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <div className="send-message-input-box">
              <div className="send-message-input">
                <textarea
                  name="message"
                  id=""
                  cols="10"
                  rows="1"
                  className="send-message w-100"
                  style={{ resize: "none" }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <button className="send-btn">
                  <img src={sendBtn} alt="" onClick={() => sendMessage()} />
                </button>
              </div>
              <button className="emoji-btn">
                <img src={emojiBtn} alt="" />
              </button>
              {/* <button className="emoji-btn">
                <img src={linkBtn} alt="" />
              </button>
              <button className="emoji-btn">
                <img src={calenderBtn} alt="" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruiterChat2;
