import React, { useContext } from "react";
import "./recruiterMenu.css";
import MobileContext from "../../context/mobile/MobileContext";
import { Link, useLocation } from "react-router-dom";
import dashboard from "../../assets/recruiter-subHeader/dashboard.svg";
import ActiveDashboard from "../../assets/recruiter-subHeader/light-dashboard.svg";
import Calender from "../../assets/recruiter-subHeader/calender.svg";
import Chatroom from "../../assets/recruiter-subHeader/chatroom.svg";

function CareerSubMenu() {
  const menuItem = useContext(MobileContext);
  const { recruiterMenu } = menuItem;
  let path = useLocation();
  let pathName = path.pathname;

  return (
    <>
      <div
        className="mobile-recruiter-menu-box"
        style={{
          height: recruiterMenu ? "0px" : "auto",
          overflow: recruiterMenu ? "hidden" : "visible",
          padding: recruiterMenu ? "0" : "20px 22px 0 22px",
        }}
      >
        <div className="mobile-recruiter-menu">
          <Link
            to="/career/table"
            className={`${
              pathName === "/career/table"
                ? "sub-active"
                : "sub-header-dashboard-btn"
            } `}
          >
            <img
              src={pathName === "/career/table" ? ActiveDashboard : dashboard}
              style={{ marginRight: "4px" }}
              alt=""
            />
            Dashboard
          </Link>
          <Link
            to=""
            className={`${
              pathName === ""
                ? "sub-active"
                : "sub-header-dashboard-btn"
            } `}
          >
            <img
              src={Calender}
              style={{ marginRight: "4px" }}
              alt=""
            />
            Calender
          </Link>
          <Link
            to=""
            className={`${
              pathName === ""
                ? "sub-active"
                : "sub-header-dashboard-btn"
            } `}
          >
            <img
              src={Chatroom}
              style={{ marginRight: "4px" }}
              alt=""
            />
            Chatroom
          </Link>
        </div>
        <div className="mobile-recruiter-menu-wrapper"></div>
      </div>
    </>
  );
}

export default CareerSubMenu;
