import React, { useContext } from "react";
import "./mobileMenu.css";
import MobileContext from "../../context/mobile/MobileContext";
import { Link, useLocation } from "react-router-dom";
import job from "../../assets/mobile/menu-job.svg";
import track from "../../assets/mobile/menu-track.svg";
import save from "../../assets/mobile/menu-save.svg";
import career from "../../assets/mobile/menu-career.svg";
import skill from "../../assets/mobile/menu-skill.svg";
import analysis from "../../assets/mobile/menu-analysis.svg";
import resume from "../../assets/mobile/menu-resume.svg";
import chat from "../../assets/mobile/menu-chat.svg";
// import company from "../../assets/mobile/menu-company.svg";

import hoverNotification from "../../assets/mobile/notification.svg";
import notification from "../../assets/notifications.svg";
import hoverJob from "../../assets/sideBar/light-job.svg";
import hoverTrack from "../../assets/sideBar/light-track.svg";
import hoverSave from "../../assets/sideBar/light-save.svg";
import hoverCareer from "../../assets/sideBar/light-career.svg";
import hoverSkill from "../../assets/sideBar/light-upSkills.svg";
import hoverAnalysis from "../../assets/sideBar/light-analysis.svg";
import hoverResume from "../../assets/sideBar/light-resume.svg";
import hoverChat from "../../assets/sideBar/light-chat.svg";
// import hoverCompany from "../../assets/sideBar/light-company.svg";

function MobileMenu() {
  const menuItem = useContext(MobileContext);
  const { mobileMenu, handleMobileToggle } = menuItem;
  const path = useLocation()

  return (
    <>
      <div
        className="mobile-menu-box"
        style={{
          height: mobileMenu ? "0px" : "auto",
          overflow: mobileMenu ? "hidden" : "visible",
          padding: mobileMenu ? "0" : "20px 22px 0 22px",
        }}
      >
        <div className="mobile-menu">
          <div className={`${path.pathname === "/members" ? "active-header" : "mobile-menu-item"}`} onClick={handleMobileToggle}>
            <img src={notification} className="hide-on-hover" alt="" />
            <img src={hoverNotification} className="show-on-hover" alt="" />
            <Link>Notifications</Link>
          </div>
          <p className="mobile-media-title m-0">Career</p>
          <div className={`${path.pathname === "/dashboard" ? "active-header" : "mobile-menu-item"}`} onClick={handleMobileToggle}>
            <img
              src={job}
              style={{ height: "19px" }}
              className="hide-on-hover"
              alt=""
            />
            <img src={hoverJob} className="show-on-hover" alt="" />
            <Link to="/dashboard">Jobs/Internships</Link>
          </div>
          <div  className={`${path.pathname === "/dashboard/track" ? "active-header" : "mobile-menu-item"}`} onClick={handleMobileToggle}>
            <img
              src={track}
              style={{ height: "19px" }}
              className="hide-on-hover"
              alt=""
            />
            <img src={hoverTrack} className="show-on-hover" alt="" />
            <Link to="/dashboard/track">Track Applications</Link>
          </div>
          <div  className={`${path.pathname === "/dashboard/saved-applications" ? "active-header" : "mobile-menu-item"}`}onClick={handleMobileToggle}>
            <img
              src={save}
              style={{ height: "19px" }}
              className="hide-on-hover"
              alt=""
            />
            <img src={hoverSave} className="show-on-hover" alt="" />
            <Link to="/dashboard/saved-applications">Saved Applications</Link>
          </div>
          <div  className={`${path.pathname === "/dashboard/career" ? "active-header" : "mobile-menu-item"}`}onClick={handleMobileToggle}>
            <img
              src={career}
              style={{ height: "19px" }}
              className="hide-on-hover"
              alt=""
            />
            <img src={hoverCareer} className="show-on-hover" alt="" />
            <Link to="/dashboard/career">Career Guidance</Link>
          </div>
          <div  className={`${path.pathname === "/dashboard/up-skill" ? "active-header" : "mobile-menu-item"}`}onClick={handleMobileToggle}>
            <img
              src={skill}
              style={{ height: "19px" }}
              className="hide-on-hover"
              alt=""
            />
            <img src={hoverSkill} className="show-on-hover" alt="" />
            <Link to="/dashboard/up-skill">Upskill Career</Link>
          </div>
          <p className="mobile-media-title m-0">Analytics and Tools</p>
          <div  className={`${path.pathname === "/dashboard/analysis" ? "active-header" : "mobile-menu-item"}`}onClick={handleMobileToggle}>
            <img
              src={analysis}
              style={{ height: "19px" }}
              className="hide-on-hover"
              alt=""
            />
            <img src={hoverAnalysis} className="show-on-hover" alt="" />
            <Link to="/dashboard/analysis">Analysis</Link>
          </div>
          <div  className={`${path.pathname === "/resume-builder/templates" ? "active-header" : "mobile-menu-item"}`}onClick={handleMobileToggle}>
            <img
              src={resume}
              style={{ height: "19px" }}
              className="hide-on-hover"
              alt=""
            />
            <img src={hoverResume} className="show-on-hover" alt="" />
            <Link to="/resume-builder/templates">Resume Builder</Link>
          </div>
          <p className="mobile-media-title m-0">Other</p>
          <div  className={`${path.pathname === "/dashboard/chat-room" ? "active-header" : "mobile-menu-item"}`}onClick={handleMobileToggle}>
            <img
              src={chat}
              style={{ height: "19px" }}
              className="hide-on-hover"
              alt=""
            />
            <img src={hoverChat} className="show-on-hover" alt="" />
            <Link to="/dashboard/chat-room">Chat Room</Link>
          </div>
          {/* <div  className={`${path.pathname === "" ? "active-header" : "mobile-menu-item"}`}onClick={handleMobileToggle}>
            <img
              src={company}
              style={{ height: "19px" }}
              className="hide-on-hover"
              alt=""
            />
            <img src={hoverCompany} className="show-on-hover" alt="" />
            <Link>Company Ratings</Link>
          </div> */}
        </div>
        <div className="mobile-menu-wrapper"></div>
      </div>
    </>
  );
}

export default MobileMenu;
