import React from "react";
import "./resume.css";

function CoralCard2({ handleOnSelect, cardNum }) {
  return (
    <>
      <div className="coral-card-2" onClick={() => handleOnSelect(cardNum)}>
        <div className="d-flex justify-content-between">
          <h6 className="coral-title">Coral</h6>
          <p className="coral-text">Business</p>
        </div>
        <div className="coral-2-img"></div>
      </div>
    </>
  );
}

export default CoralCard2;
