import React, { useContext, useEffect, useState } from "react";
import "./recruiter.css";
import Header from "../header/Header";
import SubHeader from "../subHeader/SubHeader";
import search from "../../assets/recruiter/search.svg";
import leftArrow from "../../assets/recruiter/left-arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import box from "../../assets/images.png";
import RecruiterSubMenu from "../mobileMenu/RecruiterSubMenu";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import Loader from "../loader/Loader";
import { useMain } from "../../hook/useMain";
import RecruiterHeader from "../header/RecruiterHeader";
import { AlertContainer } from "../Alerts/ToastifyContainer";

function DraftCard() {
  return (
    <>
      <div className="draft-card" style={{ width: "100%" }}>
        <div className="d-flex align-items-start">
          <img
            src={
              JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                ? JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                : box
            }
            alt=""
            style={{ marginRight: "8px", height: "25px", width: "25px" }}
          />
          <div className="w-100">
            <h2 className="draft-card-title">Product manager</h2>
            <div className="d-flex justify-content-between align-items-center">
              <p className="draft-card-text">product</p>
              <p className="draft-days">2 days ago</p>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
        <ul className="draft-list">
          <li>Full-time · Entry level</li>
          <li>11-50 employees · IT Services and IT Consulting</li>
          <li>See how you compare to 843 applicants.</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
        </ul>
        <div className="draft-edit-btn">Edit</div>
      </div>
    </>
  );
}
function DraftCardS({ draft }) {
  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  return (
    <>
      <div className="draft-card">
        <div className="d-flex align-items-start">
          <img
            src={
              JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                ? JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                : box
            }
            alt=""
            style={{ marginRight: "8px", height: "25px", width: "25px" }}
          />
          <div className="w-100" style={{height:"50px"}}>
            <h2 className="draft-card-title">{draft?.jobInfo?.position}</h2>
            <div className="d-flex justify-content-between align-items-center">
              <p className="draft-card-text">{draft?.companyName}</p>
              <p className="draft-days">{timeAgo(draft.createdAt)}</p>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
        <ul className="draft-list">
          <li>
            {draft?.jobInfo?.jobType} · {draft?.jobInfo?.workPlaceType}
          </li>
          <li>
            {draft?.userId?.companyStrength} employees ·{" "}
            {draft?.userId?.industry}
          </li>
          {/* <li>See how you compare to 843 applicants.</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li> */}
        </ul>
        <Link to={`/dashboard/all-application/review/${draft?._id}`} style={{textDecoration: 'none'}}>
          <div className="draft-edit-btn">Edit</div>
        </Link>
      </div>
    </>
  );
}

function Draft() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const menuItem = useContext(MobileContext);
  const { handleRecruiterToggle, recruiterMenu } = menuItem;

  const [draft, setDraft] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [filterData, setFilterData] = useState("");
  const [loader, setLoader] = useState(false);

  const { getAllDraft } = useMain();
  const getdata = async () => {
    const token = localStorage.getItem("recruiterToken");
    setLoader(true);
    if (token) {
      const res = await getAllDraft(token);
      if (res?.statusCode === 200) {
        setLoader(false);
        setDraft(res?.data);
        setFilterData(res?.data);
      } else {
        // alert("Somthing went wrong");
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    getdata();
  }, []);

  const filteredDrafts = (inputVal) => {
    setInputVal(inputVal);
    const data = draft?.filter(
      (item) =>
        inputVal.trim() === "" ||
        Object.values(item.jobInfo).some((value) =>
          String(value).toLowerCase().includes(inputVal.toLowerCase())
        )
    );
    setFilterData(data);
  };
  return (
    <>
      <AlertContainer />
      <RecruiterHeader title="Drafts" search={true} />
      <div className="desktop-show-hide">
        <SubHeader />
      </div>
      {loader && <Loader />}
      <div className="recruiter-container desktop-show-hide">
        <div className="d-flex justify-content-end">
          <div className="draft-search">
            <input
              type="text"
              placeholder="Search"
              value={inputVal}
              onChange={(e) => filteredDrafts(e.target.value)}
            />
            <img src={search} alt="" />
          </div>
        </div>
        <div className="draft-container">
          <div
            className="d-flex align-items-center"
            onClick={() => navigate(-1)}
          >
            <img src={leftArrow} style={{ marginRight: "8px" }} alt="" />
            <p className="m-0 draft-title">Drafts</p>
          </div>
          <div className="draft-boxes">
            {filterData && filterData?.length > 0 ? (
              filterData?.map((item, index) => (
                <DraftCardS key={index} draft={item} />
              ))
            ) : (
              <h5 className="text-center">It's empty in here</h5>
            )}
          </div>
        </div>
      </div>
      <div
        className="recruiter-container mobile-show-hide"
        style={{ marginTop: "80px" }}
      >
        <div className="draft-boxes" style={{ gap: "8px" }}>
          {filterData && filterData?.length > 0 ? (
            filterData?.map((item, index) => (
              <DraftCardS key={index} draft={item} />
            ))
          ) : (
            <h5 className="text-center">It's empty in here</h5>
          )}
        </div>
        <RecruiterSubMenu />
        <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
          <img
            src={top}
            alt=""
            style={{
              transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 150ms ease",
              height: "14px",
            }}
          />
          <img src={jobIcon} style={{ height: "24px" }} alt="" />
        </div>
      </div>
    </>
  );
}

export default Draft;
