import React, { useEffect, useState } from "react";
import arrow from "../../assets/left-arrow.svg";
import arrowHover from "../../assets/our/right-arrow-btn.svg";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation hook
import FormHeader from "../../components/header/FormHeader";
import { useMain } from "../../hook/useMain";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import RecruiterHeader from "../header/RecruiterHeader";
import axios from "axios";
import { baseUrl } from "../../contextApi/common/data";

function AuthPage() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    if (code) {
      console.log("Code:", code);
      const data = JSON.parse(localStorage.getItem("interviewData"))
      if(!data){
        toast.error("Please try again.");
      }
      meetSchedule({...data, code})
    } else {
      // navigate('/error');
    }
  }, [location.search, navigate]);

  const meetSchedule = async(data)=>{
    try {
      const res = await axios.post(`${baseUrl}/google/redirect`, data )
      if(res.status==200){
        // toast.success("Meet Schedule Done.")
        navigate(`${data?.location}`)
      }
    } catch (error) {
      toast.error("Meeting schedule failed.");
    }
  }
  const [dashHover, setDashHover] = useState(false);

  return (
    <>
      <AlertContainer/>
      <section className="complete-section">
        <div className="complete-box">
          <div className="wrapper"></div>
          <div>
            <p className="m-0 complete-text">
              Please Wait <br />
              Don't Close the Tab
              <br /> Meet is Scheduling.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default AuthPage;
