import React, { useState } from "react";
import MobileContext from "./MobileContext";

const MobileState = (props) => {
  const [mobileMenu, setMobileMenu] = useState(true);
  const [recruiterMenu, setRecruiterMenu] = useState(true);
  const handleMobileToggle = () => {
    setMobileMenu(!mobileMenu);
  };
  const handleRecruiterToggle = () => {
    setRecruiterMenu(!recruiterMenu);
  };
  return (
    <MobileContext.Provider
      value={{ handleMobileToggle, mobileMenu, setMobileMenu, recruiterMenu, setRecruiterMenu, handleRecruiterToggle }}
    >
      {props.children}
    </MobileContext.Provider>
  );
};

export default MobileState;
