import React, { useState } from "react";
import pdfImg from "../../assets/recruiter/pdf-img.svg";
import downloadIcon from "../../assets/recruiter/download-icon.svg";
import viewIcon from "../../assets/recruiter/edit-icon.svg";
import downImg from "./img/download.svg";
import deleteImg from "./img/delete.svg";
import closeImg from "./img/close.svg";
import "./resume.css";
import { baseUrl } from "../../contextApi/common/data";
import axios from "axios"
import { toast } from "react-toastify";

function FIleView({resume, getAllResume}) {
  console.log(resume);
  const [showPdfDialog, setShowPdfDialog] = useState(false);

  // Function to open PDF in a fullscreen dialog
  const handleViewPDF = () => {
    setShowPdfDialog(true);
  };

  // Function to close the fullscreen dialog
  const handleClosePDFDialog = () => {
    setShowPdfDialog(false);
  };
  const handleViewPDF2 = (url) => {
    window.open(resume?.url, "_blank");
  };
  

  
  const handleDelete = async () => {
    try {
      const res = await axios.delete(`${baseUrl}/user-resume/${resume?._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });
      if (res?.status == 200) {
        toast.success("Resume delete successful.");
        getAllResume();
      }
    } catch (error) {
      toast.error("Failed to delete resume.");
    }
  };
  return (
    <>
      <div className="resume-file-card">
        <div className="d-flex align-items-center" style={{ gap: "16px" }}>
          <img src={pdfImg} className="img-fluid" alt="pdf" />
          <div>
            <h6 className="resume-file-card-title">
              CV_24th Jan_Harsh A Manoj
            </h6>
            <p className="resume-file-card-text">{new Date(resume?.createdAt||"").toDateString()}</p>
          </div>
        </div>
        <div className="design-btns">
          <button className="design-edit" onClick={handleViewPDF} >View</button>
          <button className="p-0" style={{ border: "none", background: "transparent" }}>
            <img src={downImg} alt="" onClick={handleViewPDF2} />
          </button>
          <button className="p-0" style={{ border: "none", background: "transparent" }}
           onClick={handleDelete}>
            <img src={deleteImg} alt="" />
          </button>
        </div>
        <div className="design-btns-mobile">
          <button
            style={{ padding: "0", border: "none", background: "transparent" }}
            onClick={handleViewPDF}
          >
            <img src={viewIcon} alt="" />
          </button>
          <button
            style={{ padding: "0", border: "none", background: "transparent" }}
            onClick={handleViewPDF2}
          >
            <img src={downloadIcon} alt="" />
          </button>
          <button
            style={{ padding: "0", border: "none", background: "transparent" }}
            onClick={handleDelete}
          >
            <img src={deleteImg} alt="" />
          </button>
        </div>
        {showPdfDialog && (
        <div className="pdf-dialog">
          <iframe src={resume?.url} className="pdf-iframe" title="PDF Viewer"></iframe>
          <button className="close-btn" onClick={handleClosePDFDialog}>
            <img src={closeImg} alt="Close" />
          </button>
        </div>
      )}
      </div>
    </>
  );
}

export default FIleView;
