import React, { useState } from "react";
import lightArrow from "../../assets/recruiter-dashboard/light-down-arrow.svg";
import "./account.css";
import profile from "../../assets/recruiter-dashboard/profile.svg";
import job from "../../assets/recruiter-dashboard/job.svg";
import email from "../../assets/recruiter-dashboard/mail.svg";
import backImg from "../../assets/recruiter/back-img.svg";
import { useNavigate } from "react-router-dom";
import FormHeader from "../header/FormHeader";
import RecruiterHeader from "../header/RecruiterHeader";

function Accordion({ title, accLink, content }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div
        className="faq-custom-accordion"
        onClick={() => setIsActive(!isActive)}
        style={{ backgroundColor: isActive ? "#2E3D55" : "#FFF" }}
      >
        {/* <Link to={accLink}> */}
        <div className="faq-custom-accordion-box">
          <div>
            <h2
              className="faq-acc-title"
              style={{ color: isActive ? "#fff" : "#262626" }}
            >
              {title}
            </h2>
          </div>
          <div
            className={isActive ? "img-active" : "acc-up-img"}
            style={{
              backgroundImage: isActive ? `url(${lightArrow})` : "",
              height: "32px",
              width: "32px",
            }}
          ></div>
        </div>
        {/* </Link> */}
        {isActive && <div className="faq-acc-content">{content}</div>}
      </div>
    </>
  );
}

const accordionData = [
  {
    title: "Overview",
    content: `We respect your privacy and strive to provide a safe, secure user experience. This privacy statement sets forth our online data collection and usage policies and practices. By using our services, you consent to the policies and practices described in this statement. Your data will be stored and processed on our servers which may be inside or outside India and your usage of the Services constitutes consent to the transfer of your data out of India. Our Services may contain links to other website over which we have no control and we are not responsible for the privacy policies or practices of other websites to which you navigate from our Services. We encourage you to review the privacy policies of these other websites so you can understand how they collect, use and share your information. This privacy statement applies solely to the information we collect on Recruitifie.com and its sub-domains and not to information collected otherwise.`,
  },
  {
    title: "Collection of Information",
    content: `Personal information provided by you: In some areas of our Services, we request you to provide or submit personal information including your name, address, email address, telephone number, contact information, billing information, education details, work place details, and any other information from which your identity is discernible. Recruitifie also collects or may collect demographic information that is not unique to you such as your ZIP code, age, preferences and gender. Example of such a situation could include, but is not limited to when you sign up to use our service, post a resume or enter a contest or survey.`,
  },
];

function RecruiterPrivacyPolicy() {
  const navigate = useNavigate();
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);
  const [isActive7, setIsActive7] = useState(false);
  const [isActive8, setIsActive8] = useState(false);
  const [isActive9, setIsActive9] = useState(false);
  const [isActive10, setIsActive10] = useState(false);
  const [isActive11, setIsActive11] = useState(false);
  return (
    <>
      <RecruiterHeader title="Privacy Policy" />
      <div className="privacy-page">
        <div className="faq-container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={() => navigate(-1)}
              >
                <img src={backImg} className="prev-page" alt="" />
              </button>
            </div>
            <div>
              <h6 className="faq-title">Privacy Policy</h6>
              <p className="faq-text">
                This Privacy Policy applies to the online services offered by
                Recruitifie at Recruitifie.com, its subdomains and Recruitifie’s
                app on Google Play Store. These are henceforth collectively
                known as Services
              </p>
            </div>
            <div></div>
          </div>
          <div
            style={{ borderBottom: "1px solid #E2E8F0", margin: "16px 0" }}
          ></div>
          <div className="faq-accordion-box">
            {accordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} />
            ))}
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive1(!isActive1)}
              style={{ backgroundColor: isActive1 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive1 ? "#fff" : "#262626" }}
                  >
                    Information collected when you use third party services
                  </h2>
                </div>
                <div
                  className={isActive1 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive1 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive1 && (
                <div className="faq-acc-content">
                  For a better experience, and in order to provide our service,
                  we may require you to provide us, either directly or
                  indirectly, with certain personally identifiable information,
                  including but not limited to user name. The information that
                  we request will be retained by us and used as described in
                  this privacy policy. Recruitifie uses third-party services
                  that may collect information used to identify you. Find below
                  a list of services used, and the privacy policies of these
                  services:
                  <ul style={{ margin: "0" }}>
                    <li>Google Sign-In privacy policy</li>
                    <li>YouTube privacy policy</li>
                    <li>Facebook privacy policy</li>
                  </ul>
                  We may collect, process and store your information associated
                  with your Google account if you choose to sign in using
                  Google. When you sign in to your account with your Google
                  account information, you consent to our collection, storage,
                  and use of the information that you make available to us
                  through your Google account in accordance with this Privacy
                  Policy. This could include your name, email address and phone
                  number associated with your Google account. If you are
                  registering as an employer and choose to authenticate your
                  company using social media, then with your explicit consent to
                  our data collection, we will collect your page name and count
                  of followers as available to us through your Google or
                  Facebook or Instagram account. We also use Youtube API
                  services to collect your channel name and count of subscribers
                  for account verification purposes. This data will be
                  collected, processed and stored in accordance with this
                  Privacy Policy. <br />
                  Information about your contacts: When you choose to share
                  content on Recruitifie with friends using the Google+ share
                  feature and sign in using your Google account, we may collect,
                  process and store information of the contacts (email addresses
                  of contacts) associated with your Google account. Also, when
                  you share content with your friends through SMS share option
                  on Recruitifie, we may collect, process and store information
                  of the contacts (phone numbers) to whom you choose to send the
                  SMS. <br />
                  Information collected through use of service: We also gather
                  or may gather certain information about your use of our
                  Services, such as what areas you visit and what features you
                  access. Moreover, there is information about your computer
                  hardware and software that is or may be collected by us. This
                  information can include without limitation your IP address,
                  browser type, domain names, access times and referring website
                  addresses and can be used by Recruitifie as it deems fit. If
                  you message applicants through the Recruitifie chat platform,
                  we also collect and may access your chat history <br />
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive2(!isActive2)}
              style={{ backgroundColor: isActive2 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive2 ? "#fff" : "#262626" }}
                  >
                    Retention of Information
                  </h2>
                </div>
                <div
                  className={isActive2 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive2 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive2 && (
                <div className="faq-acc-content">
                  <ul style={{ margin: 0 }}>
                    <li>
                      If you are an employer, information related to your
                      internship and/or job post such as organisation name, URL,
                      and description, job description, skills required etc. is
                      published online and can be viewed by anyone visiting
                      Recruitifie site and app. This information may also appear
                      in search websites like Google. Further, we may share your
                      personal information (including contact details) with
                      students who apply to your internships and/or jobs or get
                      hired by you through Recruitifie.
                    </li>
                    <li>
                      If you are an applicant, we may share your personal
                      information with employers whose internships and/or jobs
                      you apply to or whose internships and/or jobs we feel may
                      be relevant for you or who may come across your profile
                      through a search of our user base.
                    </li>
                    <li>
                      We may share your information with third party
                      individuals, contractors, companies and other service
                      providers that perform services on our behalf or in
                      partnership with us. For example, we may require to pass
                      on your data to a partner who might be responsible for the
                      delivery of a training program that you may have
                      voluntarily signed up for.
                    </li>
                    <li>
                      Additionally, you may have the option to share your
                      information and activities on Recruitifie through social
                      media. We do not have control over information shared with
                      third party social networks and you should review the
                      policies of those networks before consenting to share
                      information with them.
                    </li>
                    <li>
                      If you post any of your personal information in public
                      areas of Recruitifie, such as in online forums, chat
                      rooms, comments section etc. this information will be
                      accessible to everyone on the public internet. It may be
                      collected and used by others over whom Recruitifie has no
                      control. We are not responsible for the use made by third
                      parties of information you post or otherwise make
                      available in public areas of this website.
                    </li>
                    <li>
                      When we send you an email or SMS, we use a third party
                      service. In this case, it becomes necessary to pass on
                      your email address/ mobile number to the third party.
                      While we only work with reputed service providers in this
                      regard, we are not responsible for the use made by them of
                      this information.
                    </li>
                    <li>
                      Recruitifie reserves the right to publish the internship
                      and/or job post of companies on social media platforms and
                      3rd party websites like LinkedIn, Indeed, Sheroes, Qween,
                      etc.along with newspapers and magazines, in order to
                      increase the visibility of the post.
                    </li>
                    <li>
                      We may also share your information in order to
                      investigate, prevent, or take action about possible
                      illegal activities or to comply with legal process. We may
                      also share your information with third parties to
                      investigate and address violations of this Privacy Policy
                      or the Terms of Service, or to investigate and address
                      violations of the rights of third parties and/or
                      Recruitifie, our employees, users, or the public. This may
                      involve the sharing of your information with law
                      enforcement, government agencies, courts, and/or other
                      organizations.
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive3(!isActive3)}
              style={{ backgroundColor: isActive3 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive3 ? "#fff" : "#262626" }}
                  >
                    Editing information
                  </h2>
                </div>
                <div
                  className={isActive3 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive3 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive3 && (
                <div className="faq-acc-content">
                  If you are a student, you may edit your personal information
                  and profile by visiting your resume section. Certain data
                  (such as applications you may have made) cannot be edited.{" "}
                  <br />
                  If you are an employer, you may edit your personal information
                  and information about your organization by visiting the
                  profile (personal details and organization details) section.
                  Certain data (such as internships you may post, applications
                  you may have processed) cannot be edited.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive4(!isActive4)}
              style={{ backgroundColor: isActive4 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive4 ? "#fff" : "#262626" }}
                  >
                    Downloading information
                  </h2>
                </div>
                <div
                  className={isActive4 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive4 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive4 && (
                <div className="faq-acc-content">
                  Our customer support team will be happy to help you with this.
                  Please write to us at privacy@Recruitifie.com.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive5(!isActive5)}
              style={{ backgroundColor: isActive5 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive5 ? "#fff" : "#262626" }}
                  >
                    Communication policy
                  </h2>
                </div>
                <div
                  className={isActive5 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive5 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive5 && (
                <div className="faq-acc-content">
                  For opt-in <br />
                  For a user to receive our weekly newsletter, student/employer
                  digests and/or any other marketing communication from us over
                  email; Recruitifie follows a double opt-in policy where after
                  submitting your email address you need to verify it by
                  clicking on a verification link that we would send to you over
                  given email address. Until the email is verified, you would
                  not receive the newsletter or any marketing communication from
                  us. <br />
                  For opt-out <br />
                  Marketing/ Digests/ Newsletter: Every newsletter and
                  promotional communication from Recruitifie contains an
                  'Unsubscribe' link that you can click on to stop receiving any
                  future newsletters and promotional content from us on your
                  email. It may take upto 7 days for your unsubscription request
                  to be processed. <br />
                  Transactional: We require to send users transactional
                  communication as this is required for proper functioning of
                  our service. If you wish to discontinue receiving any
                  transactional communication from Recruitifie, write to us at
                  privacy@Recruitifie.com. <br />
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive6(!isActive6)}
              style={{ backgroundColor: isActive6 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive6 ? "#fff" : "#262626" }}
                  >
                    Cookies and pixel tags
                  </h2>
                </div>
                <div
                  className={isActive6 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive6 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive6 && (
                <div className="faq-acc-content">
                  We and third parties with whom we partner, may use cookies,
                  web beacons, pixel tags etc. to collect information regarding
                  your use of Recruitifie and third party websites. A cookie is
                  a small text file that is stored on your computer that enables
                  us to remember you (for example, as a registered user) and
                  your actions when you visit our website. This helps us
                  remember your preferences and searches, improve your
                  experience (for example, by keeping you logged in), customize
                  content according to your preferences and perform analytics,
                  and assist with security and administrative functions. Cookies
                  may be persistent or stored only during an individual session
                  ,as necessary. A pixel tag is a tiny graphic with a unique
                  identifier embedded invisibly in an email and may be used to
                  track whether the email has been opened and for other
                  analytics. <br />
                  Most modern browsers will allow you to disable some/all
                  cookies for any website. However, this is not recommended and
                  doing so may interfere with normal functioning of Recruitifie.
                  If you do not turn cookies off, please make sure you logout
                  when you finish using a shared computer.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive7(!isActive7)}
              style={{ backgroundColor: isActive7 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive7 ? "#fff" : "#262626" }}
                  >
                    Children
                  </h2>
                </div>
                <div
                  className={isActive7 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive7 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive7 && (
                <div className="faq-acc-content">
                  Recruitifie is not intended for children under 13 years of
                  age. If you are less than 13 years old at the time of your
                  first visit to Recruitifie, you are prohibited from using the
                  website further entirely on your own. You may do so under
                  parental guidance. However, please note that we have no way of
                  determining your age when you visit our website or whether you
                  have parental supervision available or not. We do not intend
                  to and do not knowingly collect personal information from
                  children under 13. However, since we do not collect
                  information on a user's birth date and proof of same, there is
                  no foolproof way for us to ensure the same.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive8(!isActive8)}
              style={{ backgroundColor: isActive8 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive8 ? "#fff" : "#262626" }}
                  >
                    Security of information
                  </h2>
                </div>
                <div
                  className={isActive8 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive8 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive8 && (
                <div className="faq-acc-content">
                  We have implemented generally accepted industry standards in
                  terms of security measures to protect your information on
                  Recruitifie. The third party payment service providers
                  (payment gateways) are all validated as compliant with the
                  payment card industry standard (generally referred to as PCI
                  compliant service providers). <br />
                  While we try our best to safeguard information, there may be
                  factors beyond our control that may result in unwanted
                  disclosure of information. We assume no liability or
                  responsibility for disclosure of your information due to
                  causes beyond our control. <br />
                  In order to keep personal information secure, you must not
                  share your password or other security information (for
                  example, unique keys) of your Recruitifie account with anyone.
                  If you are using a shared computer, please make sure you
                  logout after every use. If we receive instructions using your
                  email and password, we will consider that the instructions
                  have been authorized by you. <br />
                  GDPR entitlement <br />
                  EU General Data Protection Regulation (GDPR) has provided the
                  below rights to the EU residents: <br />
                  <ul style={{ margin: 0 }}>
                    <li>
                      Right to information - the purposes for processing
                      Personal Information and the rights of the individual.
                    </li>
                    <li>
                      Right to access the Personal Information that are
                      processed.
                    </li>
                    <li>Right to erasure (”Right to be forgotten”).</li>
                    <li>Right to rectification.</li>
                    <li>Right to restriction of processing.</li>
                    <li>Right to object (opt-out) to processing.</li>
                  </ul>
                  EU residents can exercise these rights by raising a request
                  directly at privacy@Recruitifie.com.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive9(!isActive9)}
              style={{ backgroundColor: isActive9 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive9 ? "#fff" : "#262626" }}
                  >
                    No Guarantees
                  </h2>
                </div>
                <div
                  className={isActive9 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive9 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive9 && (
                <div className="faq-acc-content">
                  While this Privacy Policy states our standards for maintenance
                  of data and we will make efforts to meet them, we are not in a
                  position to guarantee these standards. There may be factors
                  beyond our control that may result in disclosure of data. As a
                  consequence, we disclaim any warranties or representations
                  relating to maintenance or nondisclosure of data.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive10(!isActive10)}
              style={{ backgroundColor: isActive10 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive10 ? "#fff" : "#262626" }}
                  >
                    Changes to this Privacy Policy
                  </h2>
                </div>
                <div
                  className={isActive10 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive10 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive10 && (
                <div className="faq-acc-content">
                  While this Privacy Policy states our standards for maintenance
                  of data and we will make efforts to meet them, we are not in a
                  position to guarantee these standards. There may be factors
                  beyond our control that may result in disclosure of data. As a
                  consequence, we disclaim any warranties or representations
                  relating to maintenance or nondisclosure of data.
                </div>
              )}
            </div>
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive11(!isActive11)}
              style={{ backgroundColor: isActive11 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive11 ? "#fff" : "#262626" }}
                  >
                    Grievance officer
                  </h2>
                </div>
                <div
                  className={isActive11 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive11 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive11 && (
                <div className="faq-acc-content">
                  <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
                    <div className="grievance-card">
                      <img src={profile} alt="" />
                      <div style={{ marginLeft: "8px" }}>
                        <h6 className="grievance-card-title">Officer Name</h6>
                        <p className="grievance-card-text">Jayesh</p>
                      </div>
                    </div>
                    <div className="grievance-card">
                      <img src={job} alt="" />
                      <div style={{ marginLeft: "8px" }}>
                        <h6 className="grievance-card-title">Designation</h6>
                        <p className="grievance-card-text">CTO </p>
                      </div>
                    </div>
                    <div className="grievance-card">
                      <img src={email} alt="" />
                      <div style={{ marginLeft: "8px" }}>
                        <h6 className="grievance-card-title">Email us</h6>
                        <p className="grievance-card-text">
                          privacy@recruitifie.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruiterPrivacyPolicy;
