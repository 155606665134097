import React, { useState } from "react";
import "./header.css";
import logo from "../../assets/logo.svg";
import mobileLogo from "../../assets/mobile-logo.svg";
import searchImg from "../../assets/white-search.svg";
import { Link, useNavigate } from "react-router-dom";

function IntervieewerHeader({ btnName, link }) {
  const [toggleSideBar, setSidebarToggle] = useState(false);
  const [searchInput, setSearchInput] = useState(false);

  const navigate = useNavigate();

  function openSidebar() {
    setSidebarToggle(!toggleSideBar);
  }
  function openSearch() {
    setSearchInput(!searchInput);
  }

  const throwOnDashboard = () => {
    const InterviewerData = JSON.parse(localStorage.getItem("Interviewer_data"));
    const InterviewerToken = localStorage.getItem("Interviewer_token");
    if (InterviewerData && InterviewerToken) {
      navigate("/interview/all-application");
    }
  };
  return (
    <>
      <div>
        <header className="color-header">
          <div
            className="search-input"
            style={{ display: searchInput ? "flex" : "none" }}
          >
            <input type="text" />
            <img src={searchImg} onClick={openSearch} alt="" />
          </div>
          <div
            className="overlay"
            onClick={() => {
              openSidebar();
            }}
            style={toggleSideBar ? { right: 0 } : { right: "100%" }}
          ></div>
          <div className="logo">
            <img
              src={logo}
              alt=""
              className="img-fluid"
              onClick={throwOnDashboard}
            />
          </div>
          <div
            className="mobile-logo"
            style={{ display: searchInput ? "none" : "block" }}
          >
            <Link to="" onClick={throwOnDashboard}>
              <img src={mobileLogo} alt="" className="img-fluid" />
            </Link>
          </div>
          <div
            className="list"
            style={{
              display: searchInput ? "none" : "inline-block",
            }}
          >
            {/* <button className="header-search-btn" onClick={openSearch}>
              <img src={searchImg} alt="" />
            </button> */}
            <button className="login-btn" onClick={() => navigate(link)}>
              Sign In
            </button>
          </div>
          <button onClick={() => openSidebar()} className="bras-btn">
            <i className="fa fa-regular fa-bars"></i>
          </button>
        </header>
      </div>
    </>
  );
}

export default IntervieewerHeader;
