import React, { useState } from "react";
import MainContext from "./MainContext";
import {
  createRecruiter,
  loginRecruiter,
  SignInUser,
  Preferences,
  UpdateResume,
  CreateResumeOnce,
  buildResumeone,
  BuildResumeOnce,
  BuildResumeThree,
  sendOtp,
  submitOtp,
  googleRecruierLogin,
  googleSeekLogin,
  recruiterFindByEmal,
  userFindByEmal,
  completeUser,
  UserUpdateMobileNo,
} from "./contextPages/RecruiterAuthContext";
import { loginJobseeker } from "./contextPages/UserAuthContext";
import {
  createPost,
  getAllDraft,
  getPostById,
  updatePost,
  makePost,
  allPostByRecruiter,
  getInterviewByRecruitrId,
  deletePost,
  closePost,
  getClosePost,
} from "./contextPages/JobPostContext";
import {
  getAllUserPost,
  getSingleUserPost,
  applyJob,
  getapplyJob,
  saveJob,
  getsaveJob,
} from "./contextPages/UserJobContext";
import {
  getAllApplication,
  asigntoInterviewer,
  getSingleApplication,
} from "./contextPages/ApplicationContext";
import {
  createInterviewer,
  getAllByCompany,
  singleInterviewer,
  deleteInterviewer,
  updateInterviewer,
  loginInterviewer,
  updateApplication,
  updateInterviewStatus,
  assignInterviewer,
  getInterviewDataForUser,
} from "./contextPages/InterviewerContext";

import {
  RecruiterSigin,
  UpdateCity,
  UpdateLogo,
  UpdateCompanyDetails,
  UpdateVerification,
  recruiterSendMail,
  RecruiterUpdateMobileNo,
} from "./contextPages/RecruiterSigninContext";

import {
  CarrerSignUp,
  CarrerSignIn,
  CarrerResumeBuild,
  GetAllCarrers,
  GetSingleCarrers,
  BookCounsleores,
} from "./contextPages/CarrerContext";

import {
  getAllChats,
  interviewerChat,
  counselorChat,
  userChat,
  userChat1,
  chatRoomChats,
  userCounselorChat,
  userInterviewerChat,
  getNotification,
  notificaitonMarkRead,
} from "./contextPages/ChatContext";

import {
  sendFeedbackByUser,
  getFeedbackByUser,
  sendFeedbackByInterviwer,
  getFeedbackByInterviwer,
} from "./contextPages/FeedbackContext";

const MainState = (props) => {
  const [sidebarSearch, setSidebarSearch] = useState("");
  return (
    <>
      <MainContext.Provider
        value={{
          createRecruiter,
          loginRecruiter,
          SignInUser,
          Preferences,
          UpdateResume,
          CreateResumeOnce,
          BuildResumeOnce,
          getSingleApplication,
          buildResumeone,
          BuildResumeThree,
          sendOtp,
          submitOtp,
          googleRecruierLogin,
          googleSeekLogin,
          recruiterFindByEmal,
          userFindByEmal,
          completeUser,
          UserUpdateMobileNo,

          loginJobseeker,

          createPost,
          getAllDraft,
          getPostById,
          updatePost,
          makePost,
          allPostByRecruiter,
          getInterviewByRecruitrId,
          deletePost,
          closePost,
          getClosePost,

          getAllUserPost,
          getSingleUserPost,
          applyJob,
          getapplyJob,
          saveJob,
          getsaveJob,

          getAllApplication,
          asigntoInterviewer,

          createInterviewer,
          getAllByCompany,
          singleInterviewer,
          deleteInterviewer,
          updateInterviewer,
          loginInterviewer,
          updateInterviewStatus,
          updateApplication,
          assignInterviewer,
          getInterviewDataForUser,

          RecruiterSigin,
          UpdateCity,
          UpdateLogo,
          UpdateCompanyDetails,
          UpdateVerification,
          recruiterSendMail,
          RecruiterUpdateMobileNo,

          CarrerSignUp,
          CarrerSignIn,
          CarrerResumeBuild,
          GetAllCarrers,
          GetSingleCarrers,
          BookCounsleores,

          getAllChats,
          interviewerChat,
          counselorChat,
          userChat,
          userChat1,
          chatRoomChats,
          userCounselorChat,
          userInterviewerChat,
          getNotification,
          notificaitonMarkRead,

          sendFeedbackByUser,
          getFeedbackByUser,
          sendFeedbackByInterviwer,
          getFeedbackByInterviwer,

          // states
          sidebarSearch,
          setSidebarSearch,
        }}
      >
        {props.children}
      </MainContext.Provider>
    </>
  );
};

export default MainState;
