import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";

function SetJobSwitch() {
  const id = localStorage.getItem("userId");
  const { UpdateResume } = useMain();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  function HandleOnCreate() {
    navigate("/create-resume");
  }
  // const [selectedFile, setSelectedFile] = useState();
  const fileChangedHandler = async (e) => {
    const file = e.target.files[0];
    if (file?.type == "application/pdf") {
      setLoader(true);
      const result = await UpdateResume(file, id);
      console.log(result)
      if (result?.statusCode === 200) {
        toast.success(result?.message);
        navigate("/create-resume");
        // setSelectedFile(true);
        setLoader(false);
      } else {
        toast.error("Somthig went wrong");
        // setSelectedFile(null);
        setLoader(false);
      }
    } else {
      toast.info("Please select valid pdf.");
      // setSelectedFile(null);
      setLoader(false);
    }
  };
  return (
    <>
      <AlertContainer/>
      <Header />
      {loader && <Loader />}

      <section className="job-section">
        <div className="switch-modal">
          <div className="text-center">
            <h6 className="switch-modal-title">
              How would you like to build profile?
            </h6>
          </div>
          <button className="create-resume-btn" onClick={HandleOnCreate}>
            Create Resume
          </button>
          <input
            type="file"
            hidden
            id="file"
            inputprops={{ accept: "application/pdf" }}
            accept="application/pdf"
            name="file"
            onChange={fileChangedHandler}
          />
          <label htmlFor="file" className="upload-resume-btn">
            Upload Resume
          </label>
        </div>
      </section>
    </>
  );
}

export default SetJobSwitch;
