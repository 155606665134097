import { baseUrl, headers } from "../common/data";
import axios from "axios";

export const createRecruiter = async (data) => {
  try {
    const response = await axios.post(
      `${baseUrl}/recruiter/register`,
      JSON.stringify(data),
      { headers }
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const loginRecruiter = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/recruiter/login`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const SignInUser = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/user/register`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const Preferences = async (data, id) => {
  try {
    const response = await fetch(`${baseUrl}/user/update1/${id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const UpdateResume = async (data, id) => {
  try {
    var formdata = new FormData();
    formdata.append("resume", data, data.name);

    const response = await fetch(`${baseUrl}/user/uploadresume/${id}`, {
      method: "PATCH",
      body: formdata,
      redirect: "follow",
    });

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const CreateResumeOnce = async (
  academicData,
  academic2,
  certificate,
  cert1,
  cert2,
  cert3,
  cert4,
  cert5,
  id
) => {
  try {
    var formdata = new FormData();
    formdata.append("name", academicData?.name);
    formdata.append("phone", academicData?.phone);
    formdata.append("email", academicData?.email);
    formdata.append("dob", academicData?.dob);
    formdata.append("city", academicData?.city);
    formdata.append("state", academicData?.state);
    formdata.append("pincode", academicData?.pincode||"");
    formdata.append(
      "workingStatus",
      academicData?.workingStatus === "Working Status"
        ? ""
        : academicData?.workingStatus
    );
    formdata.append(
      "maritalStatus",
      academicData?.maritalStatus === "Marital Status"
        ? ""
        : academicData?.maritalStatus
    );
    formdata.append("academic", JSON.stringify(academic2));
    formdata.append("certificates", JSON.stringify(certificate));
    formdata.append("cert1", cert1);
    formdata.append("cert2", cert2);
    formdata.append("cert3", cert3);
    formdata.append("cert4", cert4);
    formdata.append("cert5", cert5);

    const response = await fetch(`${baseUrl}/user/update2/${id}`, {
      method: "PATCH",
      body: formdata,
      redirect: "follow",
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const BuildResumeOnce = async (
  experience,
  cert1,
  cert2,
  cert3,
  cert4,
  cert5,
  jobAchievement,
  id
) => {
  try {
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");

    var formdata = new FormData();
    formdata.append("workExperience", JSON.stringify(experience));
    formdata.append("cert1", cert1);
    formdata.append("cert2", cert2);
    formdata.append("cert3", cert3);
    formdata.append("cert4", cert4);
    formdata.append("cert5", cert5);
    formdata.append("jobAchievement", JSON.stringify(jobAchievement));
    const response = await fetch(`${baseUrl}/user/update3/${id}`, {
      method: "PATCH",
      // headers: myHeaders,
      body: formdata,
      redirect: "follow",
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const buildResumeone = async (
  matricert,
  highestcert,
  certificatCourse,
  cert1,
  cert2,
  cert3,
  id
) => {
  try {
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");

    var formdata = new FormData();
    formdata.append("matricert", matricert);
    formdata.append("highestcert", highestcert);
    formdata.append("certificatCourse", JSON.stringify(certificatCourse));
    formdata.append("cert1", cert1);
    formdata.append("cert2", cert2);
    formdata.append("cert3", cert3);

    const response = await fetch(`${baseUrl}/user/update4/${id}`, {
      method: "PATCH",
      // headers: myHeaders,
      body: formdata,
      redirect: "follow",
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const BuildResumeThree = async (data, id) => {
  try {
    const response = await fetch(`${baseUrl}/user/update5/${id}`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const googleRecruierLogin = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/recruiter/google/login`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const googleSeekLogin = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/user/google/login`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const sendOtp = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/aadhar/sendotp`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const submitOtp = async (data) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append(
      "x-parse-rest-api-key",
      "4f2d87de7f4974eb896be685914887b1"
    );
    myHeaders.append("x-parse-application-id", "yamrecritllp_kyc_uat");
    myHeaders.append("Content-Type", "application/json");
    const response = await fetch(`${baseUrl}/aadhar/submitotp`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const recruiterFindByEmal = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/recruiter/findbymail`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const userFindByEmal = async (data) => {
  try {
    const response = await fetch(`${baseUrl}/user/findbymail`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    const resdata = await response.json();
    return resdata;
  } catch (err) {
    console.log(err);
  }
};

export const completeUser = async (id) => {
  try {
    let data = [];
    await fetch(`${baseUrl}/user/mailwhatsapp/${id}`)
      .then((req) => req.json())
      .then((res) => {
        data = res;
        return res;
      })
      .catch((err) => console.log(err));

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const UserUpdateMobileNo = async (mobileNo, id) => {
  try {
    const response = await fetch(`${baseUrl}/user/update/mobile/${id}`, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(mobileNo),
    });

    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};
