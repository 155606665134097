import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import facebook from "../../assets/facebook.svg";
import twitter from "../../assets/twitter.svg";
import linkedin from "../../assets/linkedin.svg";
import instagram from "../../assets/instagram.svg";
import footerLogo from "../../assets/footer-logo.svg";

function Footer() {
  return (
    <>
      <footer>
        <div className="footer-wrapper"></div>
        <div className="footer-content">
          <div className="footer-list">
            <div className="social-media">
              <div className="row">
                <div className="col-6 p-0">
                  <h6 className="footer-title">Recruitifie</h6>
                  <Link to="/about-us">About us</Link>
                  <Link to="/dashboard">Job Seeker</Link>
                  <Link to="/recruiter-dashboard">Recruiter</Link>
                  <Link to="/help-and-support/faq">FAQs</Link>
                  <Link to="https://www.thesocialsynapse.com/communities" target="_blank" rel="noopener noreferrer">Community</Link>
                </div>
                <div className="col-6 p-0">
                  <h6 className="footer-title">Contact Us</h6>
                  <Link to="">support@recruitifie</Link>
                  <Link target='_blank' rel='noopener noreferrer' to="https://wa.me/message/5OZBHPGGLH7TH1">+91 6006989302</Link>
                </div>
              </div>
            </div>
            <div className="follow-media">
              <div className="row">
                <div className="col-6 p-0">
                  <h6 className="footer-title">Follow Us</h6>
                  <div className="social-btn">
                    <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/people/Recruitifie/100091479496126/">
                      <img src={facebook} alt="" />
                    </Link>
                    <Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/recruitifie">
                      <img src={twitter} alt="" />
                    </Link>
                    <Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/company/recruitifie/">
                      <img src={linkedin} alt="" />
                    </Link>
                    <Link target="_blank" rel="noopener noreferrer" to="https://www.instagram.com/Recruitifie">
                      <img src={instagram} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-6">
                </div>
              </div>
              <p className="footer-text d-flex">
                ©2023 by Recruitifie. All Rights Reserved.
                <Link to="/help-and-support/terms-and-conditions">Terms & Conditions</Link>
              </p>
            </div>
          </div>
          <div className="footer-logo">
            <img src={footerLogo} className="img-fluid" alt="" />
            <p className="m-0 footer-logo-title">
              "Elevating Talent, Catalyzing Growth: Recruitifie Pioneers the
              Path to Professional Excellence!"
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
