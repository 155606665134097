import React from "react";
import Header from "../header/Header";
import './account.css'
import FormHeader from "../header/FormHeader";

function Delete() {
  return (
    <>
      <FormHeader title="Help & Support" />
      <div className="logout-page">
        <div className="logout-box">
          <p className="logout-text" style={{width: "300px"}}>Are you sure you want to delete your account?</p>
          <div className="logout-btn-box">
            <button className="logout-btn">Delete</button>
            <button className="logout-back-btn">Back</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Delete;
