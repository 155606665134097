import React, { useState } from "react";
import AllModalContext from "./AllModalContext";

const AllModalState = (props) => {
  const [showModal, updateShowModal] = useState(false);
  const [Upgrade, setUpgrade] = useState(false);
  const [recruiterUpgrade, setRecruiterUpgrade]=useState(false);
  const [remove, setRemove] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [tableF, setTableF] = useState(false);
  const [career, setCareer] = useState(false);
  const [track, setTrack] = useState(false);
  const [recruiterModal, setRecruiterModal] = useState(false);
  const [interview, setInterview] = useState(false);

  function toggleCareer() {
    setCareer(!career);
  }
  function toggleInterview() {
    setInterview(!interview);
  }
  function toggleRecruiter() {
    setRecruiterModal(!recruiterModal);
  }
  function toggleTrack() {
    setTrack(!track);
  }
  function toggleModal() {
    updateShowModal(!showModal);
  }
  function toggleUpgrade() {
    setUpgrade(!Upgrade);
  }
  function toggleRecruitierUpgrade() {
    setRecruiterUpgrade(!recruiterUpgrade);
  }
  function toggleRemove() {
    setRemove(!remove);
  }
  function toggleHelp() {
    setHelpModal(!helpModal);
  }
  function toggleAssign() {
    setAssignModal(!assignModal);
  }
  function toggleTable() {
    setTableF(!tableF);
  }

  return (
    <AllModalContext.Provider
      value={{
        showModal,
        updateShowModal,
        recruiterModal,
        toggleRecruiter,
        interview,
        toggleInterview,
        track,
        toggleTrack,
        career,
        toggleCareer,
        toggleModal,
        Upgrade,
        setUpgrade,
        toggleUpgrade,
        remove,
        setRemove,
        toggleRemove,
        helpModal,
        setHelpModal,
        toggleHelp,
        assignModal,
        setAssignModal,
        toggleAssign,
        toggleTable,
        tableF,
        setTableF,
        recruiterUpgrade,
        toggleRecruitierUpgrade,
      }}
    >
      {props.children}
    </AllModalContext.Provider>
  );
};

export default AllModalState;
