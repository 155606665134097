import React, { useContext, useEffect, useState } from "react";
// import Header from "../components/header/Header";
import { json, useNavigate } from "react-router-dom";
import "../App.css";
import FormHeader from "../components/header/FormHeader";
import downArrow from "../assets/mobile/down-arrow.svg";
import downArrowHover from "../assets/mobile/down-arrow-hover.svg";
import axios from "axios";
import { useMain } from "../hook/useMain";
import AadharContext from "../context/AadharContext";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import Header from "../components/header/Header";

const selectLanguage = [
  {
    id: 1,
    option: "Hindi",
    selected: false,
  },
  {
    id: 2,
    option: "English",
    selected: false,
  },
  {
    id: 3,
    option: "Gujrati",
    selected: false,
  },
  {
    id: 4,
    option: "Marathi",
    selected: false,
  },
];

function HelpUs() {
  const aadhar = useContext(AadharContext);
  const { setReferenceId, setTransactionId } = aadhar;
  const [number, setNumber] = useState(
    JSON.parse(localStorage.getItem("userData"))?.aadharDetails?.number != 0
      ? JSON.parse(localStorage.getItem("userData"))?.aadharDetails?.number
      : ""
  );

  const [loader, setLoader] = useState(false);

  const { sendOtp } = useMain();
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  // async function GetOtp() {
  //   var formDataUpdated = new FormData();
  //   formDataUpdated.append("reference_id", "yamrecritllp_kyc_uat");
  //   formDataUpdated.append(
  //     "transaction_id",
  //     "4f2d87de7f4974eb896be685914887b1"
  //   );
  //   formDataUpdated.append("captcha", "65266a6ff143b4119c0c06b8");
  //   formDataUpdated.append("source", "65266a6ff143b4119c0c06b8");
  //   try {
  //     const res = await axios({
  //       method: "post",
  //       url: "https://kyc-uat.signdesk.in/api/sandbox/aadhaarXMLVerification",
  //       data: formDataUpdated,
  //       headers: {
  //         "content-type": "multipart/form-data",
  //         // authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     });
  //   } catch (e) {}
  //   // navigate("/help-us/enter-otp");
  // }

  const GetOtp = async () => {
    if (number == "") {
      return toast.info("Please enter your Aadhar number.");
    }
    if (number) {
      setLoader(true);
      const result = await sendOtp({ aadharNo: number });
      if (result?.data?.status === "success") {
        setLoader(false);
        setReferenceId(result?.data?.reference_id);
        setTransactionId(result?.data?.transaction_id);
        navigate("/help-us/enter-otp");
        setNumber("");
      } else {
        toast.error("Please provide a valid Aadhar number.");
        setLoader(false);
      }
    }
  };

  // const submitData = async () => {
  //   const data = {
  //     reference_id: referenceId,
  //     transaction_id: transactionId,
  //     otp: otp,
  //   };
  //   const result = await submitOtp(data);

  //   if (result?.data?.status === "success") {
  //     alert(result?.data?.message);
  //     navigate("/complete-resume");
  //     setShowModal(false);
  //   } else {
  //     alert("Invalid otp");
  //   }
  // };

  function SkipNow() {
    navigate("/complete-resume");
  }

  return (
    <>
      <AlertContainer/>
      <Header title="ID Verification" />
      <section className="help-us-section">
        <div className="profile-build">
          {/* <div className="help-us-line desktop-show-hide"></div> */}
          <h6 className="build-title">Help us verify you</h6>
          <p className="build-text">
            Your security is our priority. Help us verify your account for a
            safe and trusted user experience.
          </p>
          <div className="border-bottom desktop-show-hide"></div>
          <div className="resume-box">
            <h6 className="resume-title">Verification</h6>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <h6
                  className="resume-input"
                  style={{
                    marginBottom: "16px",
                    backgroundColor: "#EEF7FD",
                    color: "#000",
                    fontWeight: 500,
                  }}
                >
                  Aadhar Card
                </h6>
                {/* <div
                  className="dropdown"
                  onMouseEnter={() => setDropHover1(true)}
                  onMouseLeave={() => setDropHover1(false)}
                >
                  <div
                    onClick={(e) => {
                      setGovernment(!government);
                    }}
                    style={{
                      outline: government ? "2px solid #94A3B8" : "none",
                    }}
                    className="dropdown-btn"
                  >
                    {selectedGovernment}
                    <img src={dropHover1 ? downArrowHover : downArrow} alt="" />
                  </div>
                  <div
                    className="dropdown-content"
                    style={{ display: government ? "block" : "none" }}
                  >
                    {selectLanguage.map((element, index) => {
                      return (
                        <div
                          onClick={(e) => {
                            setSelectedGovernment(e.target.textContent);
                            setGovernment(!government);
                          }}
                          className="item"
                        >
                          {element.option}
                        </div>
                      );
                    })}
                  </div>
                </div> */}
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <input
                  type="text"
                  style={{ marginBottom: "16px" }}
                  placeholder="ID Number"
                  className="resume-input"
                  name="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <button
                  className="get-otp-btn desktop-show-hide"
                  onClick={GetOtp}
                >
                  Get OTP
                </button>
              </div>
            </div>
            <div className="text-center">
              <button className="skip-for-now-btn" onClick={SkipNow}>
                Skip for now
              </button>
            </div>
          </div>
        </div>
        <div className="bottom-btn mobile-show-hide">
          <div className="d-flex flex-column">
            <button className="save-btn" onClick={GetOtp}>
              Get OTP
            </button>
            <button className="skip-btn" onClick={SkipNow}>
              Skip for now
            </button>
          </div>
        </div>
        {/* <div className="bottom-btn mobile-show-hide">
          <div className="d-flex flex-column">
            <button className="get-otp-btn" onClick={GetOtp}>
              Get OTP
            </button>
            <button className="skip-btn" onClick={SkipNow}>
              Skip for now
            </button>
          </div>
        </div> */}
      </section>

      {/* {shoModal ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            top: "0",
            left: "0",
            background: "black",
            opacity: "0.9",
          }}
        >
          <div className="switch-modal" >
            <input
              type="number"
              placeholder="Enter Otp"
              className="resume-input"
              onChange={(e) => setOtp(e.target.value)}
            />
            <button
              className="create-resume-btn m-0 w-100"
              onClick={submitData}
            >
              Submit
            </button>
          </div>
        </div>
      ) : null} */}
    </>
  );
}

export default HelpUs;
