import React, { useState } from "react";
import lightArrow from "../../assets/recruiter-dashboard/light-down-arrow.svg";
import "./account.css";
import backImg from "../../assets/recruiter/back-img.svg";
import { useNavigate } from "react-router-dom";
import FormHeader from "../header/FormHeader";
import RecruiterHeader from "../header/RecruiterHeader";

function RecruiterRefundPage() {
  const navigate = useNavigate();
  const [isActive1, setIsActive1] = useState(true);

  return (
    <>
      <RecruiterHeader title="Refund & Cancellation..." />
      <div className="privacy-page refund">
        <div className="faq-container">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <button
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={() => navigate(-1)}
              >
                <img src={backImg} style={{ paddingLeft: "24px" }} alt="" />
              </button>
            </div>
            <div>
              <h6 className="faq-title">Refund & Cancellation Policy</h6>
              <p className="faq-text">
                Read about Refund & Cancellation Policy
              </p>
            </div>
            <div></div>
          </div>
          <div
            style={{ borderBottom: "1px solid #E2E8F0", margin: "16px 0" }}
          ></div>
          <div className="faq-accordion-box">
            <div
              className="faq-custom-accordion"
              onClick={() => setIsActive1(!isActive1)}
              style={{ backgroundColor: isActive1 ? "#2E3D55" : "#FFF" }}
            >
              <div className="faq-custom-accordion-box">
                <div>
                  <h2
                    className="faq-acc-title"
                    style={{ color: isActive1 ? "#fff" : "#262626" }}
                  >
                    Refund & Cancellation Policies
                  </h2>
                </div>
                <div
                  className={isActive1 ? "img-active" : "acc-up-img"}
                  style={{
                    backgroundImage: isActive1 ? `url(${lightArrow})` : "",
                    height: "32px",
                    width: "32px",
                  }}
                ></div>
              </div>
              {isActive1 && (
                <div className="faq-acc-content">
                  <ol style={{ margin: 0 }}>
                    <li>
                      {" "}
                      For individual Career counselling offered on Recruitifie
                      Career Guidance, a user may request a refund anytime
                      before their chosen timeslot. A transaction processing
                      charge of 20% of the fee paid by the user will be deducted
                      and the remaining will be refunded within 15 working days
                      (Monday to Friday, excluding national holidays) of the
                      refund request being raised by the user. However, once the
                      user has made the payment and accessed the counselling, no
                      refund would be permitted. No refund will be provided for
                      the premium plan offered on Recruitifie once the payment
                      has been made.
                    </li>
                    <li>
                      For the Recruitifie Basic, Premium & Premium pro plan, no
                      refund/cancellation would be permitted after payment.
                    </li>
                    <li>
                      For the Recruitifie Student plan, no refund/cancellation
                      would be permitted after payment.
                    </li>
                    <li>Please note:</li>
                    <li>
                      In case of violation of any Terms and Conditions,
                      Recruitifie reserves the right to refuse or cancel the
                      refund request.
                    </li>
                    <li>
                      Refund isn't available for Premium Plan and bulk plans.
                    </li>
                    <li>
                      For advertisers who advertise their products, offerings,
                      and services on different Recruitifie channels (Website,
                      Newsletter, Facebook page, other social media channels
                      etc.), no refund/cancellation would be permitted after the
                      advertising campaign has begun or the payment is received,
                      whichever is earlier. If an advertising campaign is
                      cancelled by Recruitifie due to its internal policies, the
                      advertiser would be entitled to a refund on a pro-rata
                      basis (after deducting the amount for the deliverables
                      already served)
                    </li>
                    <li>
                      Not with-standing this policy, any specific terms and
                      conditions mentioned on individual product & service page,
                      and/or as agreed with a user before/during/after the
                      transaction, will override the terms and conditions
                      mentioned here in case of a conflict.
                    </li>
                    <li>
                      We may make changes to our products & services, and
                      associated terms & conditions (including terms and
                      conditions on refund and cancellation), from time to time.
                      You are advised to refer to our latest terms and
                      conditions on this page and associated product/service
                      specific terms and conditions page before making a
                      purchase.
                    </li>
                  </ol>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruiterRefundPage;
