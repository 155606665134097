import { baseUrl } from "../common/data";

export const sendFeedbackByUser = async (data, userId) => {
  try {
    let res = [];
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    await fetch(`${baseUrl}/feedback/give/${userId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        res = result;
        return result;
      })
      .catch((error) => console.log("error", error));
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getFeedbackByUser = async (id) => {
  try {
    let data = [];
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`${baseUrl}/feedback/get/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        data = result;
        return result;
      })
      .catch((error) => console.log("error", error));
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const sendFeedbackByInterviwer = async (data, recruiterId) => {
  try {
    let res = [];
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };

    await fetch(
      `${baseUrl}/feedback/give/foruser/${recruiterId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        res = result;
        return result;
      })
      .catch((error) => console.log("error", error));
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getFeedbackByInterviwer = async (id) => {
  try {
    let data = [];
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`${baseUrl}/feedback/get/foruser/${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        data = result;
        return result;
      })
      .catch((error) => console.log("error", error));
    return data;
  } catch (err) {
    console.log(err);
  }
};
