import React, { useContext, useEffect, useState } from "react";
import "./recruiter.css";
import "./css/form.css";
import Header from "../header/Header";
import SubHeader from "../subHeader/SubHeader";
import AccUp from "../../recruiter-img/acco-up.svg";
import addIcon from "../../recruiter-img/add-icon.svg";
import Delete from "../../assets/delete.svg";
import downArrow from "../../assets/mobile/down-arrow.svg";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
// import box from "../../assets/calender/box.svg";
import View from "../../assets/chat-modal/view-all.svg";
import { useNavigate, useParams } from "react-router-dom";
import DraftSlider from "./DraftSlider";
import RecruiterSubMenu from "../mobileMenu/RecruiterSubMenu";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import { useMain } from "../../hook/useMain";
import SaveDraft from "./SaveDraft";
import Loader from "../loader/Loader";
import useOutsideClick from "../../pages/outsideClick/useOutsideClick";
import RecruiterHeader from "../header/RecruiterHeader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import { Button, Modal, Typography } from "@mui/material";
import RecruiterAlertModel from "./RecruiterAlertModel";

const selectItem = [
  {
    id: 1,
    option: "Employed",
    selected: false,
  },
  {
    id: 2,
    option: "Serving Notice Period",
    selected: false,
  },
  {
    id: 3,
    option: "Unemployed",
    selected: false,
  },
  {
    id: 4,
    option: "Working Part-time",
    selected: false,
  },
  {
    id: 5,
    option: "Student",
    selected: false,
  },
];

const JobPre = [
  {
    id: 1,
    option: "INTERNSHIP",
    selected: false,
  },
  {
    id: 2,
    option: "FULL-TIME",
    selected: false,
  },
  {
    id: 3,
    option: "PART-TIME",
    selected: false,
  },
  // {
  //   id: 4,
  //   option: "Remote",
  //   selected: false,
  // },
  // {
  //   id: 5,
  //   option: "Hybrid",
  //   selected: false,
  // },
];
const TypeWorkPlace = [
  {
    id: 1,
    option: "REMOTE",
    selected: false,
  },
  {
    id: 2,
    option: "IN-OFFICE",
    selected: false,
  },
  {
    id: 3,
    option: "HYBRID",
    selected: false,
  },
];

const join = [
  {
    id: 1,
    option: "Within 1 Week",
    selected: false,
  },
  {
    id: 2,
    option: "Within 2 Week",
    selected: false,
  },
  {
    id: 4,
    option: "Within 1 months",
    selected: false,
  },
  {
    id: 3,
    option: "Within 2 months",
    selected: false,
  },
  {
    id: 5,
    option: "Within 3 months",
    selected: false,
  },
];

const initialState = {
  companyName: "",
  about: "",
  interviewRounds: "",
  position: "",
  jobType: "",
  workPlace: "",
  workPlaceType: "",
  benefits: "",
  responsibility: "",
  qualification: "",
  candidateJoining: "",
  jobBrief: "",
  salaryMin: "",
  salaryMax: "",
  interviewRounds: "",
};

function NewJob() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("auth") === "true";
    if (!isAuthenticated) {
      navigate("/log-in");
      return;
    }
  }, []);
  const [accOpen1, setAccOpen1] = useState(true);
  const [accOpen2, setAccOpen2] = useState(true);
  const [accOpen3, setAccOpen3] = useState(true);
  const [accOpen4, setAccOpen4] = useState(true);
  const [loader, setLoader] = useState(false);

  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const [dropdown5, setDropdown5] = useState(false);
  const [dropdown6, setDropdown6] = useState(false);
  const ref1 = useOutsideClick(handleOutsideClick1);
  function handleOnClick1(event) {
    event.preventDefault();
    setDropdown5(!dropdown5);
  }

  function handleOutsideClick1() {
    setDropdown5(false);
  }
  // ------------------ formdata code started here. -----------
  //-----------------------------------------------------------
  //-----------------------------------------------------------
  const { id } = useParams();
  const menuItem = useContext(MobileContext);
  const { handleRecruiterToggle, recruiterMenu } = menuItem;
  const [formdata, setFormdata] = useState(initialState);
  const [completeProfile, setCompleteProfile] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interview, setInterview] = useState(0);
  const [questions, setQuestions] = useState([
    { question: "", questionType: "" },
  ]);
  const handleQuestionChange = (index, fieldName, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][fieldName] = value;
    setQuestions(updatedQuestions);
  };
  const [field, setField] = useState([
    {
      position: "Job Title",
      jobType: "Job Type",
      workPlace: "",
      workPlaceType: "Work Place Type",
      joining: "Candidate joining",
      salaryMin: "",
      salaryMax: "",
      jobBrief: "",
      jobResp: "",
      candidate: "",
      benefits: "",
    },
  ]);
  const [error, setError] = useState([
    {
      companyName: null,
    },
  ]);
  const [compError, setCompError] = useState(null);
  const [aboutError, setAboutError] = useState(null);
  const [positionError, setPositionError] = useState(null);
  const [jobTypeError, setJobTypeError] = useState(null);
  const [workPlaceError, setWorkPlaceError] = useState(null);
  const [workPlaceTypeError, setWorkPlaceTypeError] = useState(null);
  const [joiningError, setJoiningError] = useState(null);
  const [sminError, setSMinError] = useState(null);
  const [smaxError, setSMaxError] = useState(null);
  const [briefError, setBriefError] = useState(null);
  const [respError, setRespError] = useState(null);
  const [qualiError, setQualiError] = useState(null);
  const [BanefError, setBanefError] = useState(null);
  const [iRoundError, setIRoundError] = useState(null);

  function AddQuestion() {
    const updatedList = [...questions, { question: "", questionType: "" }];
    setQuestions(updatedList);
  }

  function DeleteQuestion(id) {
    const questionList = Object.assign([], questions);
    let filteredArray = questionList.filter((item, index) => index !== id);
    setQuestions(filteredArray);
  }
  function DeleteField(id) {
    const fieldList = Object.assign([], field);
    let filteredArray = fieldList.filter((item, index) => index !== id);
    setField(filteredArray);
  }
  // const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };

  const { createPost, getPostById, updatePost } = useMain();

  const hadlecloseModel = ()=>{
    setIsModalOpen(false)
  }

  useEffect(() => {
    const recruiterData = JSON.parse(localStorage.getItem("recruiterData"));
    if (!recruiterData) {
      setCompleteProfile(false);
      setIsModalOpen(true);
    } else {
      const requiredFields = [
        "name",
        "mobileNo",
        "email",
        "companyName",
        "about",
        "industry",
        "companyStrength",
        "yearFounded",
        "headQuater",
        "weblink",
      ];
      for (let field of requiredFields) {
        if (!recruiterData[field] || recruiterData[field] == "") {
          setCompleteProfile(false);
          setIsModalOpen(true);
          break;
        }
      }
    }
  }, []);

  const resetErrors = () => {
    setBriefError(null);
    setSMinError(null);
    setSMaxError(null);
    setJoiningError(null);
    setQualiError(null);
    setCompError(null);
    setAboutError(null);
    setPositionError(null);
    setJobTypeError(null);
    setWorkPlaceError(null);
    setWorkPlaceTypeError(null);
    setBanefError(null);
    setRespError(null);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const token = localStorage.getItem("recruiterToken");
    const {
      companyName,
      about,
      position,
      jobType,
      workPlace,
      workPlaceType,
      benefits,
      responsibility,
      qualification,
      candidateJoining,
      jobBrief,
      salaryMin,
      salaryMax,
      interviewRounds,
    } = formdata;
    if (companyName == "") {
      setCompError("company is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (jobBrief == "") {
      setBriefError("Job Brief is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (salaryMin == "") {
      setSMinError("Minimum Salary is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (salaryMax == "") {
      setSMaxError("Maximum Salary is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (candidateJoining == "") {
      setJoiningError("Candidate Joining is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (qualification == "") {
      setQualiError("Qualification is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (about == "") {
      setAboutError("About is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (position == "") {
      setPositionError("Position is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (jobType == "") {
      setJobTypeError("Job Type is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (workPlace == "") {
      setWorkPlaceError("work place is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (workPlaceType == "") {
      setWorkPlaceTypeError("work place type is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (benefits == "") {
      setBanefError("Banefits is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (responsibility == "") {
      setRespError("Responsebility is required");
      toast.error("Please fill in all details.");
      return;
    }
    if (id) {
      setLoader(true);
      const token = localStorage.getItem("recruiterToken");
      const response = await updatePost(
        {
          ...formdata,
          screeningQuestions: questions,
        },
        id,
        token
      );
      // resetErrors();
      if (response?.statusCode == 200) {
        toast.success("Job post updated successfully.");
        setLoader(false);
        navigate(`/dashboard/all-application/review/${id}`);
      }
      if (response?.statusCode == 404) {
        toast.error(response?.message);
        setLoader(false);
      }
      if (response?.statusCode == 400) {
        toast.info("Session expired. Please log in again.");
        setLoader(false);
      }
    } else {
      if (token) {
        setLoader(true);
        const response = await createPost(
          {
            ...formdata,
            screeningQuestions: questions,
          },
          token
        );
        // resetErrors();
        if (response?.statusCode == 200) {
          setLoader(false);
          toast.success("Job post updated successfully.");
          const newId = response?.data?._id;
          navigate(`/dashboard/all-application/review/${newId}`);
        }
        if (response?.statusCode == 404) {
          toast.error(response?.message);
          setLoader(false);
        }
        if (response?.statusCode == 400) {
          setLoader(false);
          toast.info("Session expired. Please log in again.");

        }
      }
    }
  };
  const getData = async (id, token) => {
    setLoader(true);
    const result = await getPostById(id, token);
    if (result?.statusCode === 200) {
      setLoader(false);
      const {
        about,
        companyName,
        jobInfo,
        screeningQuestions,
        interviewRounds,
      } = result?.data;
      console.log(result);
      setFormdata({
        ...formdata,
        about,
        companyName,
        position: jobInfo?.position,
        jobType: jobInfo?.jobType,
        workPlace: jobInfo?.workPlace,
        workPlaceType: jobInfo?.workPlaceType,
        benefits: jobInfo?.benefits,
        responsibility: jobInfo?.responsibility,
        qualification: jobInfo?.qualification,
        candidateJoining: jobInfo?.candidateJoining,
        jobBrief: jobInfo?.jobBrief,
        salaryMax: jobInfo?.salaryMax,
        salaryMin: jobInfo?.salaryMin,
        interviewRounds: interviewRounds,
      });
      setQuestions(screeningQuestions);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("recruiterToken");
    if (id && token) {
      getData(id, token);
    } else {
      const recruiterData = JSON.parse(localStorage.getItem("recruiterData"));
      setFormdata({
        ...formdata,
        companyName: recruiterData?.companyName,
        about: recruiterData?.about,
      });
    }
  }, []);

  return (
    <>
      <AlertContainer />
      <RecruiterHeader title="New Job Posting" search={false} />
      <div className="desktop-show-hide">
        <SubHeader />
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="recruiter-container desktop-show-hide">
          <div className="new-job-container">
            <h6 className="new-job-title">Add New Job Posting</h6>
            <div
              className="custom-accordion"
              style={{ display: accOpen1 ? "flex" : "unset" }}
            >
              <div
                onClick={() => setAccOpen1(!accOpen1)}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <h2 className="acc-title">About the Company</h2>
                  <p className="acc-text">Details about the company</p>
                </div>
                <img
                  src={AccUp}
                  style={{
                    transform: accOpen1 ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 150ms ease",
                  }}
                  alt=""
                />
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen1 ? "208px" : "0" }}
              >
                <div className="row">
                  <div className="col-6">
                    <div
                      className="acc-input"
                      style={{ display: accOpen1 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">Company Name</label>
                      <input
                        type="text"
                        className={compError == null ? "" : "acc-active"}
                        placeholder="Youth Empower Foundation"
                        name="companyName"
                        value={formdata?.companyName}
                        onChange={handleChange}
                      />
                      {compError == null ? (
                        ""
                      ) : (
                        <p className="input-error-css">{compError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="acc-input m-0"
                      style={{ display: accOpen1 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">About Company</label>
                      <textarea
                        name="about"
                        id=""
                        cols="30"
                        rows="4"
                        value={formdata?.about}
                        onChange={handleChange}
                        className={aboutError == null ? "" : "acc-active"}
                      ></textarea>
                      {aboutError == null ? (
                        ""
                      ) : (
                        <p className="input-error-css">{aboutError}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="custom-accordion"
              style={{ display: accOpen2 ? "flex" : "unset" }}
            >
              <div
                onClick={() => setAccOpen2(!accOpen2)}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <h2 className="acc-title">Add Job Description</h2>
                  <p className="acc-text">
                    Fill all the details to post a job description
                  </p>
                </div>
                <img
                  src={AccUp}
                  style={{
                    transform: accOpen2 ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 150ms ease",
                  }}
                  alt=""
                />
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen2 ? "auto" : "0" }}
              >
                {field.map((element, index) => {
                  return (
                    <>
                      <div
                        className="row"
                        style={{ marginTop: field.length === 1 ? 0 : "8px" }}
                        key={`field-${index}`}
                      >
                        <div className="col-6">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">
                              Position Requirement
                            </label>
                            {/* <div>
                              {dropdown1 === index ? (
                                <div
                                  className="select-overlay"
                                  onClick={() => setDropdown1(false)}
                                ></div>
                              ) : (
                                ""
                              )}
                              <div
                                className="dropdown"
                                style={{
                                  zIndex: dropdown1 === index ? "1112" : "",
                                }}
                                // onMouseEnter={() => setDropHover4(true)}
                                // onMouseLeave={() => setDropHover4(false)}
                              >
                                <div
                                  onClick={(e) => setDropdown1(index)}
                                  style={{
                                    outline: dropdown1
                                      ? "2px solid #94A3B8"
                                      : "none",
                                  }}
                                  className="dropdown-btn"
                                >
                                  {field[index]?.position}
                                  <img
                                    src={
                                      dropdown1 === index
                                        ? downArrowHover
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </div>
                                <div
                                  className="dropdown-content"
                                  style={{
                                    display:
                                      index === dropdown1 ? "block" : "none",
                                  }}
                                >
                                  {selectItem.map((element, i) => {
                                    return (
                                      <div
                                        onClick={(e) => {
                                          const updatedData = [...field];
                                          updatedData[index].position =
                                            e.target.textContent;
                                          setField(updatedData);
                                          setDropdown1(false);
                                        }}
                                        className="item"
                                      >
                                        {element.option}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div> */}

                            <input
                              type="text"
                              placeholder="Job Title"
                              name="position"
                              value={formdata?.position}
                              onChange={handleChange}
                              className={
                                positionError === null ? "" : "acc-active"
                              }
                            />
                            {positionError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">{positionError}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Job Type</label>
                            <div>
                              {dropdown2 === index ? (
                                <div
                                  className="select-overlay"
                                  onClick={() => setDropdown2(false)}
                                ></div>
                              ) : (
                                ""
                              )}
                              <div
                                className="dropdown"
                                style={{
                                  zIndex: dropdown2 === index ? "1112" : "",
                                }}
                                // onMouseEnter={() => setDropHover4(true)}
                                // onMouseLeave={() => setDropHover4(false)}
                              >
                                <div
                                  onClick={(e) => setDropdown2(index)}
                                  style={{
                                    outline: dropdown2
                                      ? "2px solid #94A3B8"
                                      : "none",
                                  }}
                                  className={`dropdown-btn ${
                                    jobTypeError === null ? "" : "acc-active"
                                  }`}
                                >
                                  {formdata?.jobType || "Job Type"}
                                  <img
                                    src={
                                      dropdown2 === index
                                        ? downArrowHover
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </div>
                                {jobTypeError === null ? (
                                  ""
                                ) : (
                                  <p className="input-error-css">
                                    {jobTypeError}
                                  </p>
                                )}
                                <div
                                  className="dropdown-content"
                                  style={{
                                    display:
                                      index === dropdown2 ? "block" : "none",
                                  }}
                                >
                                  {JobPre.map((element, i) => {
                                    return (
                                      <div
                                        onClick={(e) => {
                                          // const updatedData = [...field];
                                          // updatedData[index].jobType =
                                          //   e.target.textContent;
                                          // setField(updatedData);
                                          setFormdata({
                                            ...formdata,
                                            jobType: e.target.textContent,
                                          });
                                          setDropdown2(false);
                                        }}
                                        className="item"
                                      >
                                        {element.option}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            {/* <input
                              type="text"
                              placeholder="Youth Empower Foundation"
                              name="jobType"
                              value={formdata?.jobType}
                              onChange={handleChange}
                            /> */}
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Work Place</label>
                            <input
                              type="text"
                              placeholder="Enter location"
                              name="workPlace"
                              value={formdata?.workPlace}
                              onChange={handleChange}
                              className={
                                workPlaceError === null ? "" : "acc-active"
                              }
                            />
                            {workPlaceError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">
                                {workPlaceError}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">
                              Work Place Type
                            </label>
                            <div>
                              {dropdown3 === index ? (
                                <div
                                  className="select-overlay"
                                  onClick={() => setDropdown3(false)}
                                ></div>
                              ) : (
                                ""
                              )}
                              <div
                                className="dropdown"
                                style={{
                                  zIndex: dropdown3 === index ? "1112" : "",
                                }}
                                // onMouseEnter={() => setDropHover4(true)}
                                // onMouseLeave={() => setDropHover4(false)}
                              >
                                <div
                                  onClick={(e) => setDropdown3(index)}
                                  style={{
                                    outline: dropdown3
                                      ? "2px solid #94A3B8"
                                      : "none",
                                  }}
                                  className={`dropdown-btn ${
                                    workPlaceTypeError === null
                                      ? ""
                                      : "acc-active"
                                  }`}
                                  // className="dropdown-btn"
                                >
                                  {formdata.workPlaceType || "Work Place Type"}
                                  <img
                                    src={
                                      dropdown3 === index
                                        ? downArrowHover
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </div>
                                {workPlaceTypeError === null ? (
                                  ""
                                ) : (
                                  <p className="input-error-css">
                                    {workPlaceTypeError}
                                  </p>
                                )}
                                <div
                                  className="dropdown-content"
                                  style={{
                                    display:
                                      index === dropdown3 ? "block" : "none",
                                  }}
                                >
                                  {TypeWorkPlace.map((element, i) => {
                                    return (
                                      <div
                                        onClick={(e) => {
                                          // const updatedData = [...field];
                                          // updatedData[index].workPlaceType =
                                          //   e.target.textContent;
                                          // setField(updatedData);
                                          setFormdata({
                                            ...formdata,
                                            workPlaceType: e.target.textContent,
                                          });
                                          setDropdown3(false);
                                        }}
                                        className="item"
                                      >
                                        {element.option}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            {/* <input
                              type="text"
                              placeholder="Youth Empower Foundation"
                              name="workPlaceType"
                              value={formdata?.workPlaceType}
                              onChange={handleChange}
                            /> */}
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">
                              Candidate joining
                            </label>
                            <div>
                              {dropdown4 === index ? (
                                <div
                                  className="select-overlay"
                                  onClick={() => setDropdown4(false)}
                                ></div>
                              ) : (
                                ""
                              )}
                              <div
                                className="dropdown"
                                style={{
                                  zIndex: dropdown4 === index ? "1112" : "",
                                }}
                                // onMouseEnter={() => setDropHover4(true)}
                                // onMouseLeave={() => setDropHover4(false)}
                              >
                                <div
                                  onClick={(e) => setDropdown4(index)}
                                  style={{
                                    outline: dropdown4
                                      ? "2px solid #94A3B8"
                                      : "none",
                                  }}
                                  className={`dropdown-btn ${
                                    joiningError === null ? "" : "acc-active"
                                  }`}
                                >
                                  {formdata.candidateJoining ||
                                    "Candidate Joining"}
                                  <img
                                    src={
                                      dropdown4 === index
                                        ? downArrowHover
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </div>
                                {joiningError === null ? (
                                  ""
                                ) : (
                                  <p className="input-error-css">
                                    {joiningError}
                                  </p>
                                )}
                                <div
                                  className="dropdown-content"
                                  style={{
                                    display:
                                      index === dropdown4 ? "block" : "none",
                                  }}
                                >
                                  {join.map((element, i) => {
                                    return (
                                      <div
                                        onClick={(e) => {
                                          // const updatedData = [...field];
                                          // updatedData[index].joining =
                                          //   e.target.textContent;
                                          // setField(updatedData);
                                          setFormdata({
                                            ...formdata,
                                            candidateJoining:
                                              e.target.textContent,
                                          });
                                          setDropdown4(false);
                                        }}
                                        className="item"
                                      >
                                        {element.option}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            {/* <input
                              type="text"
                              placeholder="Youth Empower Foundation"
                              name="candidateJoining"
                              value={formdata?.candidateJoining}
                              onChange={handleChange}
                            /> */}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <div
                                className="acc-input"
                                style={{ display: accOpen2 ? "flex" : "none" }}
                              >
                                <label htmlFor="company-name">
                                  Salary range
                                </label>
                                <input
                                  type="text"
                                  placeholder="₹-Min "
                                  name="SalaryMin"
                                  value={formdata?.salaryMin}
                                  onChange={(e) =>
                                    setFormdata({
                                      ...formdata,
                                      salaryMin: e.target.value,
                                    })
                                  }
                                  className={
                                    sminError === null ? "" : "acc-active"
                                  }
                                />
                                {sminError === null ? (
                                  ""
                                ) : (
                                  <p className="input-error-css">{sminError}</p>
                                )}
                              </div>
                            </div>
                            <div className="col-6">
                              <div
                                className="acc-input"
                                style={{ display: accOpen2 ? "flex" : "none" }}
                              >
                                <label
                                  htmlFor="company-name"
                                  style={{ opacity: "0" }}
                                >
                                  Salary range
                                </label>
                                <input
                                  type="text"
                                  placeholder="₹-Max"
                                  name="SalaryMin"
                                  value={formdata?.salaryMax}
                                  onChange={(e) =>
                                    setFormdata({
                                      ...formdata,
                                      salaryMax: e.target.value,
                                    })
                                  }
                                  className={
                                    smaxError === null ? "" : "acc-active"
                                  }
                                />
                                {smaxError === null ? (
                                  ""
                                ) : (
                                  <p className="input-error-css">{smaxError}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Job Brief</label>
                            <textarea
                              name="jobBrief"
                              placeholder="Describe about the job requirement"
                              id=""
                              cols="3"
                              rows="3"
                              value={formdata?.jobBrief}
                              onChange={(e) =>
                                setFormdata({
                                  ...formdata,
                                  jobBrief: e.target.value,
                                })
                              }
                              className={
                                briefError === null ? "" : "acc-active"
                              }
                            ></textarea>
                            {briefError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">{briefError}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">
                              Job Responsibilities
                            </label>
                            <textarea
                              name="responsibility"
                              placeholder="Describe about the job requirement"
                              id=""
                              cols="30"
                              rows="3"
                              value={formdata?.responsibility}
                              onChange={(e) =>
                                setFormdata({
                                  ...formdata,
                                  responsibility: e.target.value,
                                })
                              }
                              className={respError === null ? "" : "acc-active"}
                            ></textarea>
                            {respError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">{respError}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">
                              Candidate qualifications requirement
                            </label>
                            <textarea
                              name="qualification"
                              placeholder="Describe about the job requirement"
                              id=""
                              cols="30"
                              rows="3"
                              value={formdata?.qualification}
                              onChange={(e) =>
                                setFormdata({
                                  ...formdata,
                                  qualification: e.target.value,
                                })
                              }
                              className={
                                qualiError === null ? "" : "acc-active"
                              }
                            ></textarea>
                            {qualiError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">{qualiError}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Benefits</label>
                            <textarea
                              name="benefits"
                              placeholder="Describe about the job requirement"
                              id=""
                              cols="30"
                              rows="3"
                              value={formdata?.benefits}
                              onChange={(e) =>
                                setFormdata({
                                  ...formdata,
                                  benefits: e.target.value,
                                })
                              }
                              className={
                                BanefError === null ? "" : "acc-active"
                              }
                            ></textarea>
                            {BanefError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">{BanefError}</p>
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          {/* <button
                            style={{ display: accOpen2 ? "flex" : "none" }}
                            // onClick={(e) =>
                            //   setField([
                            //     ...field,
                            //     {
                            //       position: "Job Title",
                            //       jobType: "Job Type",
                            //       workPlace: "",
                            //       workPlaceType: "Work Place Type",
                            //       joining: "Candidate joining",
                            //       salaryMin: "",
                            //       salaryMax: "",
                            //       jobBrief: "",
                            //       jobResp: "",
                            //       candidate: "",
                            //       benefits: "",
                            //     },
                            //   ])
                            // }
                            className="new-job-add-btn"
                          >
                            <img
                              src={addIcon}
                              alt=""
                              style={{ marginRight: "8px" }}
                            />
                            Add Field
                          </button> */}
                          {/* <button
                            style={{
                              border: "none",
                              display: accOpen2 ? "flex" : "none",
                              backgroundColor: "transparent",
                            }}
                            disabled={field.length === 1}
                            // onClick={() => DeleteField(index)}
                            className="plush-text p-0"
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button> */}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div
              className="custom-accordion"
              style={{ display: accOpen4 ? "flex" : "unset" }}
            >
              <div
                onClick={() => setAccOpen4(!accOpen4)}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <h2 className="acc-title">
                    Select Number of Interview Rounds
                  </h2>
                  <p className="acc-text">
                    Let the process be transparent for the candidate
                  </p>
                </div>
                <img
                  src={AccUp}
                  style={{
                    transform: accOpen4 ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 150ms ease",
                  }}
                  alt=""
                />
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen4 ? "54px" : "0" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div
                      className="acc-input m-0"
                      style={{ display: accOpen4 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">
                        No. of Interview Rounds
                      </label>
                      {/* <div ref={ref1} className="dropdown">
                        <div
                          onClick={handleOnClick1}
                          style={{
                            outline: dropdown5 ? "2px solid #94A3B8" : "none",
                          }}
                          className="dropdown-btn"
                        >
                          {interview}
                          <img
                            src={dropdown5 ? downArrowHover : downArrow}
                            alt=""
                          />
                        </div>
                        <div
                          className="dropdown-content"
                          style={{ display: dropdown5 ? "block" : "none" }}
                        >
                          {selectItem.map((element, index) => {
                            return (
                              <div
                                onClick={(e) => {
                                  setInterview(e.target.textContent);
                                  setDropdown5(false);
                                }}
                                className="item"
                              >
                                {element.option}
                              </div>
                            );
                          })}
                        </div>
                      </div> */}
                      <input
                        type="text"
                        placeholder="Eg: 3"
                        value={formdata?.interviewRounds}
                        onChange={(e) =>
                          setFormdata({
                            ...formdata,
                            interviewRounds: e.target.value,
                          })
                        }
                        className={iRoundError === null ? "" : "acc-active"}
                      />
                      {iRoundError === null ? (
                        ""
                      ) : (
                        <p className="input-error-css">{iRoundError}</p>
                      )}
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="custom-accordion"
              style={{ display: accOpen3 ? "flex" : "unset" }}
            >
              <div
                onClick={() => setAccOpen3(!accOpen3)}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <h2 className="acc-title">Add Screening Questions</h2>
                  <p className="acc-text">
                    Screening questions help with better filtering the right
                    candidates
                  </p>
                </div>
                <img
                  src={AccUp}
                  style={{
                    transform: accOpen3 ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 150ms ease",
                  }}
                  alt=""
                />
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen3 ? "auto" : "0" }}
              >
                {questions.map((element, index) => {
                  return (
                    <>
                      <div
                        className="row"
                        style={{
                          marginTop: questions.length === 1 ? 0 : "8px",
                        }}
                        key={`questions-${index}`}
                      >
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen3 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Question</label>
                            <input
                              type="text"
                              placeholder="Type your question"
                              id={`question-${index}`}
                              value={element.question}
                              onChange={(e) =>
                                handleQuestionChange(
                                  index,
                                  "question",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="col-6">
                          <div
                            className="acc-input"
                            style={{ display: accOpen3 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Question Type</label>
                            <input
                              type="text"
                              placeholder="Type your question"
                              id={`question-type-${index}`}
                              value={element.questionType}
                              onChange={(e) =>
                                handleQuestionChange(
                                  index,
                                  "questionType",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div> */}
                      </div>
                      <div
                        style={{
                          borderBottom: "1px solid #E2E8F0",
                          display: accOpen3 ? "block" : "none",
                        }}
                      ></div>
                      <div className="d-flex justify-content-between">
                        <button
                          style={{ display: accOpen3 ? "flex" : "none" }}
                          className="new-job-add-btn mt-2"
                          onClick={AddQuestion}
                        >
                          <img
                            src={addIcon}
                            alt=""
                            style={{ marginRight: "8px" }}
                          />
                          Add question
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          disabled={questions.length === 1}
                          onClick={() => DeleteQuestion(index)}
                          className="plush-text p-0"
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="clear-btn"
                onClick={(e) => {
                  setFormdata(initialState);
                  setQuestions([{ question: "", questionType: "" }]);
                }}
              >
                Clear All
              </button>
              <button
                className="preview-btn"
                disabled={completeProfile == false}
                onClick={handleSubmit}
              >
                Preview
              </button>
            </div>
          </div>
          <div className="draft-container">
            <h6 className="new-job-title">Saved Drafts</h6>
            <div className="draft-boxes">
              <SaveDraft />
              <div className="draft-view-all-box">
                <img src={View} alt="" />
                <button
                  className="view-all-box-btn"
                  onClick={() => navigate("/dashboard/draft")}
                >
                  View All
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <div
          className="recruiter-container mobile-show-hide"
          style={{ marginTop: "84px" }}
        >
          <div className="d-flex flex-column" style={{ gap: "8px" }}>
            <div
              className="custom-accordion"
              style={{ display: accOpen1 ? "flex" : "block" }}
            >
              <div
                onClick={() => setAccOpen1(!accOpen1)}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <h2 className="acc-title">About the Company</h2>
                  <p className="acc-text">Details about the company</p>
                </div>
                <img
                  src={AccUp}
                  style={{
                    transform: accOpen1 ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "transform 150ms ease",
                  }}
                  alt=""
                />
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen1 ? "180px" : "0" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div
                      className="acc-input"
                      style={{ display: accOpen1 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">Company Name</label>
                      <input
                        type="text"
                        className={
                          compError === null ? "w-100" : "acc-active w-100"
                        }
                        placeholder="Youth Empower Foundation"
                        name="companyName"
                        value={formdata?.companyName}
                        onChange={handleChange}
                      />
                      {compError === null ? (
                        ""
                      ) : (
                        <p className="input-error-css">{compError}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className="acc-input m-0"
                      style={{ display: accOpen1 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">About Company</label>
                      <textarea
                        name="about"
                        id=""
                        cols="30"
                        // className="W-100"
                        style={{ resize: "none" }}
                        rows="4"
                        value={formdata?.about}
                        onChange={handleChange}
                        className={`W-100 ${
                          aboutError == null ? "" : "acc-active"
                        }`}
                      ></textarea>
                      {aboutError == null ? (
                        ""
                      ) : (
                        <p className="input-error-css">{aboutError}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="custom-accordion"
              style={{ display: accOpen2 ? "flex" : "unset" }}
            >
              <div
                onClick={() => setAccOpen2(!accOpen2)}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <h2 className="acc-title">Add Job Description</h2>
                  <p className="acc-text">
                    Fill all the details to post a job description
                  </p>
                </div>
                <img
                  src={AccUp}
                  style={{
                    transform: accOpen2 ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 150ms ease",
                  }}
                  alt=""
                />
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen2 ? "auto" : "0" }}
              >
                {field.map((element, index) => {
                  return (
                    <>
                      <div className="row" key={`field2-${index}`}>
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">
                              Position Requirement
                            </label>
                            <input
                              type="text"
                              placeholder="Job Title"
                              name="position"
                              value={formdata?.position}
                              onChange={handleChange}
                              className={
                                positionError === null ? "" : "acc-active"
                              }
                            />
                            {positionError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">{positionError}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Job Type</label>
                            <div>
                              {dropdown2 === index ? (
                                <div
                                  className="select-overlay"
                                  onClick={() => setDropdown2(false)}
                                ></div>
                              ) : (
                                ""
                              )}
                              <div
                                className="dropdown m-0"
                                style={{
                                  zIndex: dropdown2 === index ? "1112" : "",
                                }}
                                // onMouseEnter={() => setDropHover4(true)}
                                // onMouseLeave={() => setDropHover4(false)}
                              >
                                <div
                                  onClick={(e) => setDropdown2(index)}
                                  style={{
                                    padding: "8px 10px",
                                    fontSize: "14px",
                                    outline: dropdown2
                                      ? "2px solid #94A3B8"
                                      : "none",
                                  }}
                                  // className="dropdown-btn"
                                  className={`dropdown-btn ${
                                    jobTypeError === null ? "" : "acc-active"
                                  }`}
                                >
                                  {formdata?.jobType || "Job Type"}
                                  <img
                                    src={
                                      dropdown2 === index
                                        ? downArrowHover
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </div>
                                {jobTypeError === null ? (
                                  ""
                                ) : (
                                  <p className="input-error-css">
                                    {jobTypeError}
                                  </p>
                                )}
                                <div
                                  className="dropdown-content"
                                  style={{
                                    display:
                                      index === dropdown2 ? "block" : "none",
                                  }}
                                >
                                  {JobPre.map((element, i) => {
                                    return (
                                      <div
                                        key={i}
                                        onClick={(e) => {
                                          // const updatedData = [...field];
                                          // updatedData[index].jobType =
                                          //   e.target.textContent;
                                          // setField(updatedData);
                                          setFormdata({
                                            ...formdata,
                                            jobType: e.target.textContent,
                                          });
                                          setDropdown2(false);
                                        }}
                                        className="item"
                                      >
                                        {element.option}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Work Place</label>
                            <input
                              type="text"
                              placeholder="Enter location"
                              name="workPlace"
                              value={formdata?.workPlace}
                              onChange={handleChange}
                              className={
                                workPlaceError === null ? "" : "acc-active"
                              }
                            />
                            {workPlaceError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">
                                {workPlaceError}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">
                              Work Place Type
                            </label>
                            <div>
                              {dropdown3 === index ? (
                                <div
                                  className="select-overlay"
                                  onClick={() => setDropdown3(false)}
                                ></div>
                              ) : (
                                ""
                              )}
                              <div
                                className="dropdown m-0"
                                style={{
                                  zIndex: dropdown3 === index ? "1112" : "",
                                }}
                                // onMouseEnter={() => setDropHover4(true)}
                                // onMouseLeave={() => setDropHover4(false)}
                              >
                                <div
                                  onClick={(e) => setDropdown3(index)}
                                  style={{
                                    padding: "8px 10px",
                                    fontSize: "14px",
                                    outline: dropdown3
                                      ? "2px solid #94A3B8"
                                      : "none",
                                  }}
                                  className={`dropdown-btn ${
                                    workPlaceTypeError === null
                                      ? ""
                                      : "acc-active"
                                  }`}
                                >
                                  {formdata.workPlaceType || "Work Place Type"}
                                  <img
                                    src={
                                      dropdown3 === index
                                        ? downArrowHover
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </div>
                                {workPlaceTypeError === null ? (
                                  ""
                                ) : (
                                  <p className="input-error-css">
                                    {workPlaceTypeError}
                                  </p>
                                )}
                                <div
                                  className="dropdown-content"
                                  style={{
                                    display:
                                      index === dropdown3 ? "block" : "none",
                                  }}
                                >
                                  {TypeWorkPlace.map((element, i) => {
                                    return (
                                      <div
                                        key={i}
                                        onClick={(e) => {
                                          // const updatedData = [...field];
                                          // updatedData[index].workPlaceType =
                                          //   e.target.textContent;
                                          // setField(updatedData);
                                          setFormdata({
                                            ...formdata,
                                            workPlaceType: e.target.textContent,
                                          });
                                          setDropdown3(false);
                                        }}
                                        className="item"
                                      >
                                        {element.option}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">
                              Candidate joining
                            </label>
                            <div>
                              {dropdown4 === index ? (
                                <div
                                  className="select-overlay"
                                  onClick={() => setDropdown4(false)}
                                ></div>
                              ) : (
                                ""
                              )}
                              <div
                                className="dropdown m-0"
                                style={{
                                  zIndex: dropdown4 === index ? "1112" : "",
                                }}
                                // onMouseEnter={() => setDropHover4(true)}
                                // onMouseLeave={() => setDropHover4(false)}
                              >
                                <div
                                  onClick={(e) => setDropdown4(index)}
                                  style={{
                                    padding: "8px 10px",
                                    fontSize: "14px",
                                    outline: dropdown4
                                      ? "2px solid #94A3B8"
                                      : "none",
                                  }}
                                  className={`dropdown-btn ${
                                    joiningError === null ? "" : "acc-active"
                                  }`}
                                >
                                  {formdata.candidateJoining ||
                                    "Candidate Joining"}
                                  <img
                                    src={
                                      dropdown4 === index
                                        ? downArrowHover
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </div>
                                {joiningError === null ? (
                                  ""
                                ) : (
                                  <p className="input-error-css">
                                    {joiningError}
                                  </p>
                                )}
                                <div
                                  className="dropdown-content"
                                  style={{
                                    display:
                                      index === dropdown4 ? "block" : "none",
                                  }}
                                >
                                  {join.map((element, i) => {
                                    return (
                                      <div
                                        key={i}
                                        onClick={(e) => {
                                          // const updatedData = [...field];
                                          // updatedData[index].joining =
                                          //   e.target.textContent;
                                          // setField(updatedData);
                                          setFormdata({
                                            ...formdata,
                                            candidateJoining:
                                              e.target.textContent,
                                          });
                                          setDropdown4(false);
                                        }}
                                        className="item"
                                      >
                                        {element.option}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-6">
                              <div
                                className="acc-input"
                                style={{ display: accOpen2 ? "flex" : "none" }}
                              >
                                <label htmlFor="company-name">
                                  Salary range
                                </label>
                                <input
                                  type="text"
                                  placeholder="₹-Min "
                                  name="SalaryMin"
                                  value={formdata?.salaryMin}
                                  onChange={(e) =>
                                    setFormdata({
                                      ...formdata,
                                      salaryMin: e.target.value,
                                    })
                                  }
                                  className={
                                    sminError === null ? "" : "acc-active"
                                  }
                                />
                                {sminError === null ? (
                                  ""
                                ) : (
                                  <p className="input-error-css">{sminError}</p>
                                )}
                              </div>
                            </div>
                            <div className="col-6">
                              <div
                                className="acc-input"
                                style={{ display: accOpen2 ? "flex" : "none" }}
                              >
                                <label
                                  htmlFor="company-name"
                                  style={{ opacity: "0" }}
                                >
                                  Salary range
                                </label>
                                <input
                                  type="text"
                                  placeholder="₹-Max"
                                  name="SalaryMin"
                                  value={formdata?.salaryMax}
                                  onChange={(e) =>
                                    setFormdata({
                                      ...formdata,
                                      salaryMax: e.target.value,
                                    })
                                  }
                                  className={
                                    smaxError === null ? "" : "acc-active"
                                  }
                                />
                                {smaxError === null ? (
                                  ""
                                ) : (
                                  <p className="input-error-css">{smaxError}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Job Brief</label>
                            <textarea
                              name="jobBrief"
                              placeholder="Describe about the job requirement"
                              id=""
                              cols="3"
                              rows="3"
                              value={formdata?.jobBrief}
                              onChange={(e) =>
                                setFormdata({
                                  ...formdata,
                                  jobBrief: e.target.value,
                                })
                              }
                              className={
                                briefError === null ? "" : "acc-active"
                              }
                            ></textarea>
                            {briefError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">{briefError}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">
                              Job Responsibilities
                            </label>
                            <textarea
                              name="responsibility"
                              placeholder="Describe about the job requirement"
                              id=""
                              cols="30"
                              rows="3"
                              value={formdata?.responsibility}
                              onChange={(e) =>
                                setFormdata({
                                  ...formdata,
                                  responsibility: e.target.value,
                                })
                              }
                              className={respError === null ? "" : "acc-active"}
                            ></textarea>
                            {respError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">{respError}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">
                              Candidate qualifications requirement
                            </label>
                            <textarea
                              name="qualification"
                              placeholder="Describe about the job requirement"
                              id=""
                              cols="30"
                              rows="3"
                              value={formdata?.qualification}
                              onChange={(e) =>
                                setFormdata({
                                  ...formdata,
                                  qualification: e.target.value,
                                })
                              }
                              className={
                                qualiError === null ? "" : "acc-active"
                              }
                            ></textarea>
                            {qualiError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">{qualiError}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen2 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Benefits</label>
                            <textarea
                              name="benefits"
                              placeholder="Describe about the job requirement"
                              id=""
                              cols="30"
                              rows="3"
                              value={formdata?.benefits}
                              onChange={(e) =>
                                setFormdata({
                                  ...formdata,
                                  benefits: e.target.value,
                                })
                              }
                              className={
                                BanefError === null ? "" : "acc-active"
                              }
                            ></textarea>
                            {BanefError === null ? (
                              ""
                            ) : (
                              <p className="input-error-css">{BanefError}</p>
                            )}
                          </div>
                          {/* <div className="col-12">
                            <div className="d-flex justify-content-between">
                              <button
                                style={{ display: accOpen2 ? "flex" : "none" }}
                                className="new-job-add-btn"
                                // onClick={AddField}
                                onClick={(e) =>
                                  setField([
                                    ...field,
                                    {
                                      position: "Job Title",
                                      jobType: "Job Type",
                                      workPlace: "",
                                      workPlaceType: "Work Place Type",
                                      joining: "Candidate joining",
                                      salaryMin: "",
                                      salaryMax: "",
                                      jobBrief: "",
                                      jobResp: "",
                                      candidate: "",
                                      benefits: "",
                                    },
                                  ])
                                }
                              >
                                <img
                                  src={addIcon}
                                  alt=""
                                  style={{ marginRight: "8px" }}
                                />
                                Add Field
                              </button>
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "transparent",
                                  display: accOpen2 ? "flex" : "none",
                                }}
                                disabled={field.length === 1}
                                onClick={() => DeleteField(index)}
                                className="plush-text p-0"
                              >
                                <img src={Delete} className="pe-2" alt="" />
                                Delete
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div
              className="custom-accordion"
              style={{ display: accOpen4 ? "flex" : "unset" }}
            >
              <div
                onClick={() => setAccOpen4(!accOpen4)}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <h2 className="acc-title">
                    Select Number of Interview Rounds
                  </h2>
                  <p className="acc-text">
                    Let the process be transparent for the candidate
                  </p>
                </div>
                <img
                  src={AccUp}
                  style={{
                    transform: accOpen4 ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 150ms ease",
                  }}
                  alt=""
                />
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen4 ? "54px" : "0" }}
              >
                <div className="row">
                  <div className="col-12">
                    <div
                      className="acc-input m-0"
                      style={{ display: accOpen4 ? "flex" : "none" }}
                    >
                      <label htmlFor="company-name">
                        No. of Interview Rounds
                      </label>
                      <input
                        type="text"
                        placeholder="Eg: 3"
                        value={formdata?.interviewRounds}
                        onChange={(e) =>
                          setFormdata({
                            ...formdata,
                            interviewRounds: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="custom-accordion"
              style={{ display: accOpen3 ? "flex" : "unset" }}
            >
              <div
                onClick={() => setAccOpen3(!accOpen3)}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  <h2 className="acc-title">Add Screening Questions</h2>
                  <p className="acc-text">
                    Screening questions help with better filtering the right
                    candidates
                  </p>
                </div>
                <img
                  src={AccUp}
                  style={{
                    transform: accOpen3 ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 150ms ease",
                  }}
                  alt=""
                />
              </div>
              <div
                className="acc-box"
                style={{ height: accOpen3 ? "auto" : "0" }}
              >
                {questions.map((element, index) => {
                  return (
                    <>
                      <div
                        className="row"
                        style={{
                          marginTop: questions.length === 1 ? 0 : "8px",
                        }}
                        key={`question2-${index}`}
                      >
                        <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen3 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Question</label>
                            <input
                              type="text"
                              placeholder="Type your question"
                              id={`question-${index}`}
                              value={element.question}
                              onChange={(e) =>
                                handleQuestionChange(
                                  index,
                                  "question",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="col-12">
                          <div
                            className="acc-input"
                            style={{ display: accOpen3 ? "flex" : "none" }}
                          >
                            <label htmlFor="company-name">Question Type</label>
                            <input
                              type="text"
                              placeholder="Type your question"
                              id={`question-type-${index}`}
                              value={element.questionType}
                              onChange={(e) =>
                                handleQuestionChange(
                                  index,
                                  "questionType",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="d-flex justify-content-between">
                        <button
                          style={{ display: accOpen3 ? "flex" : "none" }}
                          className="new-job-add-btn"
                          onClick={AddQuestion}
                        >
                          <img
                            src={addIcon}
                            alt=""
                            style={{ marginRight: "8px" }}
                          />
                          Add question
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            display: accOpen3 ? "flex" : "none",
                          }}
                          disabled={questions.length === 1}
                          onClick={() => DeleteQuestion(index)}
                          className="plush-text p-0"
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <RecruiterSubMenu />
            <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
              <img
                src={top}
                alt=""
                style={{
                  transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "transform 150ms ease",
                  height: "14px",
                }}
              />
              <img src={jobIcon} style={{ height: "24px" }} alt="" />
            </div>
          </div>
        </div>

        <div className="mobile-show-hide" style={{ overflow: "hidden" }}>
          {/* <div className="draft-container m-0">
            <div className="d-flex justify-content-between">
              <h6 className="new-job-title">Drafts</h6>
              <button
                className="view-all-box-btn p-0"
                style={{ marginRight: "20px" }}
                onClick={() => navigate("/dashboard/draft")}
              >
                View All
              </button>
            </div>
            <div style={{ paddingBottom: "20px" }}>
            </div>
          </div> */}
          <DraftSlider />
          <div className="bottom-box">
            <button className="bottom-preview-btn" onClick={handleSubmit}>
              Preview
            </button>
            <button
              className="bottom-clear-btn"
              onClick={(e) => {
                setFormdata(initialState);
                setQuestions([{ question: "", questionType: "" }]);
              }}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
            <RecruiterAlertModel  open={isModalOpen} onClose={hadlecloseModel} />
      
    </>
  );
}

export default NewJob;
