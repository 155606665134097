import React from "react";
import Subscribe from "../../assets/recruiter/sub-done.svg";

function YearlyFour() {
  return (
    <>
      <div className={`upgrade-card-four`}>
        <div className="d-flex flex-column h-100 justify-content-between">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="upgrade-box-title m-0">Premium Pro</h6>
              <p className="upgrade-box-text m-0" style={{textTransform: "capitalize"}}>49,999/- per year</p>
            </div>
            <div className={`upgrade-box-border-four`}></div>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              10 Active Job Post Every Month
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              10000 Candidates Every Month
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" /> ATS
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              Interviewer (Admin + 5)
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              Work Flow Automation
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              Interview Scheduler
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              Bulk Download
            </p>
            <p className="upgrade-card-one-bold m-0">
              Add ons <button className="extra-one">@Extra cost</button>
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />1
              Job Post @499/-
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              1000 Candidates @999/-
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              +1 Interviewer @499/-
            </p>
          </div>
          <button className="upgrade-basic-btn">Upgrade to Premium Pro</button>
        </div>
      </div>
    </>
  );
}

export default YearlyFour;
