import React, { useContext, useEffect, useState } from "react";
import "./header.css";
import logo from "../../assets/logo.svg";
import mobileLogo from "../../assets/mobile-logo.svg";
import searchImg from "../../assets/white-search.svg";
import rightArrow from "../../assets/recruiter-subHeader/left.svg";
import upgrade from "../../assets/upgrade.svg";
import { Link, useNavigate } from "react-router-dom";
import UpgradeModal from "../../dashboard/UpgradeModal";
import Dropdown from "../../assets/talent/header-down.svg";
import { useMain } from "../../hook/useMain";
import AllModalContext from "../../context/allModal/AllModalContext";
import NotificationModal from "../NotificationModal";
import BellIcon from "../../assets/notifications.svg"

const searchItem = [
  {
    name: "Hotel Management",
  },
  {
    name: "UX and UI Designer",
  },
  {
    name: "Graphic Designer",
  },
  {
    name: "Management Internships",
  },
  {
    name: "UI Designer",
  },
];
function InterviewerHeader2() {
  const [toggleSideBar, setSidebarToggle] = useState(false);
  const [searchInput, setSearchInput] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [name, setName] = useState("");
  const [isverify, setIsverify] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { setSidebarSearch, getNotification } = useMain();
  const modal = useContext(AllModalContext);
  const { toggleUpgrade } = modal;
  const [dummyId, setDummyId]=useState("")

  const handleNotification =()=>{
    setNotifications([])
  }

  const auth = localStorage.getItem("auth");


  const showNotificationModal = () => {
    setShowNotification(true);
  };
  const closeNotificationModal = () => {
    setShowNotification(false);
  };

  const getnotti = async (id) => {
    const res = await getNotification(id);
    console.log(res);
    if (res?.statusCode == 200) {
      setNotifications(res?.data);
    }
  };

  useEffect(() => {
    const interviewerData = JSON.parse(localStorage.getItem("Interviewer_data"));
    if (interviewerData?._id) {
      setDummyId(interviewerData?._id)
      getnotti(interviewerData?._id);
    }
  }, []);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const navigate = useNavigate();

  function openSidebar() {
    setSidebarToggle(!toggleSideBar);
  }
  function openSearch() {
    setSearchInput(!searchInput);
  }

  function handleOnLogin() {
    navigate("/log-in");
  }
  function handleUpgrade() {
    navigate("/upgrade");
  }

  const throwOnDashboard = () => {
    const InterviewerData = JSON.parse(localStorage.getItem("Interviewer_data"));
    const InterviewerToken = localStorage.getItem("Interviewer_token");
    if (InterviewerData && InterviewerToken) {
      navigate("/interview/all-application");
    }
  };

  return (
    <div>
      <header className="color-header">
        <div
          className="search-container"
          style={{ display: searchInput ? "block" : "none" }}
        >
          <div
            className="search-input"
            style={{ display: searchInput ? "flex" : "none" }}
          >
            <input
              type="text"
              onChange={(e) => setSidebarSearch(e.target.value)}
            />
            <img src={searchImg} onClick={openSearch} alt="" />
          </div>
          <div
            className="search-items-box"
            style={{ display: searchInput ? "flex" : "none" }}
          >
            <ul>
              {searchItem?.map((item, i) => {
                return (
                  <>
                    <li key={i} className="search-d-item">
                      {item?.name}
                    </li>
                  </>
                );
              })}
            </ul>
            <div className="search-border"></div>
            <button className="apply-filter">
              Apply Filters <img src={rightArrow} alt="" />
            </button>
          </div>
        </div>
        <div
          className="overlay"
          onClick={() => {
            openSidebar();
          }}
          style={toggleSideBar ? { right: 0 } : { right: "100%" }}
        ></div>
        <div className="logo">
          {/* <Link to="/"> */}
          <img
            src={logo}
            alt=""
            className="img-fluid"
            onClick={throwOnDashboard}
          />
          {/* </Link> */}
        </div>
        <div
          className="mobile-logo"
          style={{ display: searchInput ? "none" : "block" }}
        >
          {/* <Link to="/"> */}
          <img
            src={mobileLogo}
            alt=""
            className="img-fluid"
            onClick={throwOnDashboard}
          />
          {/* </Link> */}
        </div>
        <div
          className="list"
          style={{
            display: searchInput ? "none" : "inline-block",
          }}
        >
          {/* <button className="header-search-btn" onClick={openSearch}>
            <img src={searchImg} alt="" />
          </button> */}
          {/* <button className="upgrade-btn" onClick={toggleUpgrade}>
            Plan Upgrade
          </button> */}
          <img className="me-4 upgrade-btn-img" src={notifications?.length>0?upgrade:BellIcon} alt="" onClick={showNotificationModal} />
          <span className="notification-count-2">{notifications?.length||0}</span>
          {/* {auth == undefined ? (
            <button className="login-btn" onClick={handleOnLogin}>
              Get Started
            </button>
          ) : null}
          {auth ? ( */}
            <button className="menu-dropdown" onClick={handleDropdown}>
              Me <img src={Dropdown} alt="" />
            </button>
          {/* ) : null} */}
          {dropdown ? (
            <ul className="menu" style={{width:"100px"}}>
              {/* <li
                className="menu-item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="d-flex align-items-center"> */}
                  {/* <Link
                    to={
                      pathname === "/recruiter-dashboard"
                        ? "/recruiter-set"
                        : "/set-job"
                    }
                  >
                    Profile
                  </Link>
                  <p
                    className="m-0"
                    style={{
                      color: "#0D9488",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      paddingLeft: "20px",
                      lineHeight: "16px",
                    }}
                  >
                    60% complete
                  </p> */}
                {/* </div>
              </li> */}
              {/* <li
                className="menu-item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              > */}
                {/* <Link to="/user-setting">Profile and Settings </Link> */}
                {/* <button
                  style={{
                    color: "#FFF",
                    borderRadius: "100px",
                    padding: "2px 8px",
                    border: "none",
                    background: "#D97706",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "16px",
                  }}
                  onClick={() => navigate("/help-us")}
                >
                  {isverify?"Verify Aadhar":"Not Verify Aadhar"}
                </button> */}
                {/* {isverify ? (
                  <button
                    style={{
                      color: "#FFF",
                      borderRadius: "100px",
                      padding: "2px 8px",
                      border: "none",
                      background: "#28A745",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "16px",
                    }}
                  >
                    Verified
                  </button>
                ) : (
                  <button
                    style={{
                      color: "#FFF",
                      borderRadius: "100px",
                      padding: "2px 8px",
                      border: "none",
                      background: "#D97706",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "16px",
                    }}
                    onClick={() => navigate("/help-us")}
                  >
                    Not Verified
                  </button>
                )} */}
              {/* </li> */}
              {/* <li className="menu-item">
                <Link to="/help-and-support">Help and Support</Link>
              </li> */}
              {/* <li className="menu-item">
                <Link to="">Contact us</Link>
              </li> */}
              <li
                className="menu-item"
                onClick={() => {
                  localStorage.clear();
                  navigate("/interview/sign-in");
                }}
              >
                <Link to="">Log out</Link>
              </li>
              {/* <button className="menu-upgrade-btn" onClick={handleUpgrade}>
                Upgrade Plans
              </button> */}
            </ul>
          ) : null}
        </div>
        <button onClick={() => openSidebar()} className="bras-btn">
          <i className="fa fa-regular fa-bars"></i>
        </button>
      </header>
      <UpgradeModal />
      <NotificationModal
        open={showNotification}
        onClose={closeNotificationModal}
        notifications={notifications}
        handleNotification={handleNotification}
        userId={dummyId}
      />
    </div>
  );
}

export default InterviewerHeader2;
