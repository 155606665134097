import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./recruiter.css";
import box from "../../assets/calender/box.svg";
import { useMain } from "../../hook/useMain";
import { Link, useNavigate } from "react-router-dom";

function DraftCard({ item }) {
  return (
    <>
      <div className="draft-card">
        <div className="d-flex align-items-start">
          <img src={JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                    ? JSON.parse(localStorage.getItem("recruiterData"))
                        .companyLogo
                    : box} alt="" style={{ marginRight: "8px", width:"45px", height:"45px"}} />
          <div className="w-100" style={{height:"50px"}}>
            <h2 className="draft-card-title">{item?.jobInfo?.position}</h2>
            <div className="d-flex justify-content-between align-items-center">
              <p className="draft-card-text">{item?.companyName}</p>
              <p className="draft-days">
                {Math.floor(
                  (new Date() - new Date(`${item?.createdAt}`)) /
                    (24 * 60 * 60 * 1000)
                )}{" "}
                days ago
              </p>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
        <ul className="draft-list">
          <li>
            {item?.jobInfo?.jobType} · {item?.jobInfo?.workPlaceType}
          </li>
          <li>
            {item?.userId?.companyStrength} employees · {item?.userId?.industry}
          </li>
          {/* <li>See how you compare to 843 applicants.</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li> */}
        </ul>
        <Link to={`/dashboard/all-application/review/${item?._id}`}>
          <div className="draft-edit-btn">Edit</div>
        </Link>
      </div>
    </>
  );
}

function DraftSlider() {
  const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: true,
    centerPadding: "18%",
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "13%",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "8%",
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "14%",
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "24%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          dots: true,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "14%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "13%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "9%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          dots: true,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "4%",
        },
      },
    ],
  };

  const [draft, setDraft] = useState([]);
  const [loader, setLoader] = useState(false);
  const { getAllDraft } = useMain();
  const getdata = async () => {
    const token = localStorage.getItem("recruiterToken");
    if (token) {
      setLoader(true);
      const res = await getAllDraft(token);
      if (res?.statusCode === 200) {
        setLoader(false);
        setDraft(res?.data);
      } else {
        // alert("Somthing went wrong");
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <>
      {draft.length === 0 ? (
        ""
      ) : (
        <div className="draft-container m-0">
          <div className="d-flex justify-content-between">
            <h6 className="new-job-title">Drafts</h6>
            <button
              className="view-all-box-btn p-0"
              style={{ marginRight: "20px" }}
              onClick={() => navigate("/dashboard/draft")}
            >
              View All
            </button>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Slider {...settings}>
              {draft.length > 0 &&
                draft?.map((item, index) => (
                  <div key={index}>
                    <DraftCard item={item} />
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
}

export default DraftSlider;
