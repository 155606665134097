import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import "./css/dropdown.css";
// import Header from "../components/header/Header";
import info from "../assets/information.svg";
import plush from "../assets/plush.svg";
import Delete from "../assets/delete.svg";
import { NavLink, useNavigate } from "react-router-dom";
import whiteNumber1 from "../assets/mobile/white-number-1.svg";
import YellowNumber2 from "../assets/mobile/yellow-number-2.svg";
import number3 from "../assets/mobile/number3.svg";
import number4 from "../assets/mobile/number4.svg";
import downArrow from "../assets/mobile/down-arrow.svg";
import FormHeader from "../components/header/FormHeader";
import CalenderImg from "../assets/calender/calender.svg";
import uploadBtn from "../assets/calender/upload.svg";
import downArrowHover from "../assets/mobile/down-arrow-hover.svg";
import closeBtn from "../assets/close-bt.svg";
import moment from "moment";
import Calendar from "react-calendar";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import useOutsideClick from "./outsideClick/useOutsideClick";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import Header from "../components/header/Header";

const selectItem = [
  {
    id: 1,
    option: "MNC",
    selected: false,
  },
  {
    id: 2,
    option: "START-UP",
    selected: false,
  },
  {
    id: 3,
    option: "SME",
    selected: false,
  },
  {
    id: 4,
    option: "FIRM",
    selected: false,
  },
  {
    id: 5,
    option: "NON-PROFIT",
    selected: false,
  },
  {
    id: 6,
    option: "GOVERNMENT",
    selected: false,
  },
  {
    id: 7,
    option: "OTHER",
    selected: false,
  },
];

function BuildResume() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  useEffect(() => {
    if (userData) {
      setExperience(
        userData?.workExperience.length > 0
          ? userData?.workExperience
          : [
              {
                companyName: "",
                companyType: "Company Type",
                role: "",
                designation: "",
                from: "",
                to: "",
                describeRole: "",
                jobHighlight: "",
              },
            ]
      );
      setJobAchievement(
        userData?.jobAchievement.length > 0
          ? userData?.jobAchievement
          : [
              {
                certificateName: "",
                issueDate: "",
              },
            ]
      );
    }
  }, []);

  const [fromModal, setFromModal] = useState(false);
  function handleFromOpen() {
    setFromModal(!fromModal);
  }

  const [toModal, setToModal] = useState(false);
  function handleToOpen() {
    setToModal(!toModal);
  }

  const [receivedModal, setReceivedModal] = useState(false);

  function handleReceivedOpen(index) {
    setReceivedModal(index);
  }

  const imageRef = useRef();

  
  const [tooltip1, setTooltip1] = useState(false);
  const [tooltip2, setTooltip2] = useState(false);
  const [dropdown1, setDropdown1] = useState(true);
  const [dropdown2, setDropdown2] = useState(true);

  const [dropdown3, setDropdown3] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);

  const ref1 = useOutsideClick(handleOutsideClick1);
  const ref2 = useOutsideClick(handleOutsideClick2);

  function handleOnClick1(event) {
    event.preventDefault();
    if (dropdown3 === false) {
      setDropdown3(true);
    }
  }

  function handleOutsideClick1() {
    setDropdown3(false);
  }
  function handleOutsideClick2() {
    setDropdown4(false);
  }

  const handleDivClick = () => {
    imageRef?.current?.click();
  };

  const [dropHover1, setDropHover1] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loader, setLoader] = useState(false);

  const [jobAchievement, setJobAchievement] = useState([
    {
      certificateName: "",
      issueDate: "",
    },
  ]);
  const [experience, setExperience] = useState([
    {
      companyName: "",
      companyType: "Company Type",
      role: "",
      designation: "",
      from: "",
      to: "",
      describeRole: "",
      jobHighlight: "",
    },
  ]);
  function AddExperience() {
    setExperience([
      ...experience,
      {
        companyName: "",
        companyType: "Company Type",
        role: "",
        designation: "",
        from: "",
        to: "",
        describeRole: "",
        jobHighlight: "",
      },
    ]);
  }
  function AddjobAchievement() {
    if (jobAchievement?.length < 5) {
      setJobAchievement([
        ...jobAchievement,
        {
          certificateName: "",
          issueDate: "",
        },
      ]);
    }
  }

  const handleFileChange = (index, e) => {
    const data = e.target.files[0];
    const updatedData = [...jobAchievement];
    // Store only necessary file information in the state, not the entire File object
    updatedData[index].certificateLink = data ? data : "";
    setJobAchievement(updatedData);
  };

  const { BuildResumeOnce } = useMain();
  const id = localStorage.getItem("userId");

  function Dropdown1() {
    setDropdown2(false);
    setDropdown1(!dropdown1);
  }
  function Dropdown2() {
    setDropdown1(false);
    setDropdown2(!dropdown2);
  }

  function DeleteExperience(id) {
    let filteredArray = experience.filter((item, index) => index !== id);
    setExperience(filteredArray);
  }
  function DeletejobAchievement(id) {
    let filteredArray = jobAchievement.filter((item, index) => index !== id);
    setJobAchievement(filteredArray);
  }

  const [cert1, setCert1] = useState(null);
  const [cert2, setCert2] = useState(null);
  const [cert3, setCert3] = useState(null);
  const [cert4, setCert4] = useState(null);
  const [cert5, setCert5] = useState(null);

  const cart = [cert1, cert2, cert3, cert4, cert5];

  const updateFiles = (e, index) => {
    if (e?.target?.files[0].type === "application/pdf") {
      if (index === 0) {
        setCert1(e.target.files[0]);
      } else if (index === 1) {
        setCert2(e.target.files[0]);
      } else if (index === 2) {
        setCert3(e.target.files[0]);
      } else if (index === 3) {
        setCert4(e.target.files[0]);
      } else {
        setCert5(e.target.files[0]);
      }
    } else {
      toast.info("Please select a valid PDF file.");
    }
  };

  async function handleOnSave() {
    setLoader(true);
    // const data = {
    //   workExperience: experience,
    //   jobAchievement: jobAchievement,
    // };
    const result = await BuildResumeOnce(
      experience,
      cert1,
      cert2,
      cert3,
      cert4,
      cert5,
      jobAchievement,
      id
    );
    if (result?.statusCode === 200) {
      setLoader(false);
      toast.success(result?.message);
      navigate("/build-resume-1");
      localStorage.setItem("userData", JSON.stringify(result?.data));
    } else {
      toast.error("Somthing went wrong");
      setLoader(false);
    }
  }

  return (
    <>
      <AlertContainer/>
      <Header title="Build Profile" />
      {loader ? (
        <Loader />
      ) : (
        <section className="resume-build">
          <div className="mobile-show-hide">
            <div className="mobile-line-box">
              <div className="mobile-line-2"></div>
              <div className="d-flex justify-content-around number-line">
                <img src={whiteNumber1} alt="" />
                <img src={YellowNumber2} alt="" />
                <img src={number3} alt="" />
                <img src={number4} alt="" />
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown1}
              >
                <p
                  className="personal-title m-0"
                  style={{ paddingBottom: dropdown1 === true ? "12px" : "0" }}
                >
                  Work Experience
                </p>
                <img
                  src={downArrow}
                  alt=""
                  style={{ paddingBottom: dropdown1 === true ? "12px" : "0" }}
                />
              </div>
              <div
                className="resume-box"
                style={
                  dropdown1 === true
                    ? { display: "block" }
                    : { display: "none", marginTop: "12px" }
                }
              >
                <div className="row">
                  {experience.map((element, index) => {
                    return (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            name="companyName"
                            placeholder="Company name"
                            className="resume-input"
                            value={experience[index]?.companyName}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            ref={ref2}
                            className="dropdown"
                            onMouseEnter={() => setDropHover1(true)}
                            onMouseLeave={() => setDropHover1(false)}
                          >
                            <div
                              onClick={() => setDropdown4(true)}
                              style={{
                                outline: dropdown4
                                  ? "2px solid #94A3B8"
                                  : "none",
                              }}
                              className="dropdown-btn"
                            >
                              {experience[index]?.companyType}
                              <img
                                src={dropHover1 ? downArrowHover : downArrow}
                                alt=""
                              />
                            </div>
                            <div
                              className="dropdown-content"
                              style={{ display: dropdown4 ? "block" : "none" }}
                            >
                              {selectItem.map((element, i) => {
                                return (
                                  <div
                                    onClick={(e) => {
                                      const updatedData = [...experience];
                                      updatedData[index].companyType =
                                        e.target.textContent;
                                      setExperience(updatedData);
                                      setDropdown4(false);
                                    }}
                                    className="item"
                                  >
                                    {element.option}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Job Role"
                            className="resume-input"
                            name="role"
                            value={experience[index]?.role}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            placeholder="Designation (if any)"
                            className="resume-input"
                            type="text"
                            name="designation"
                            value={experience[index]?.designation}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={handleFromOpen}
                          >
                            <input
                              placeholder="From (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={experience[index]?.from}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {fromModal ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={handleFromOpen}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...experience];
                                        updatedData[index].from =
                                          moment(e).format("MM-YYYY");
                                        setExperience(updatedData);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={handleToOpen}
                          >
                            <input
                              placeholder="To (mm/yyyy)"
                              className="input-date"
                              disabled={checked === true}
                              type="text"
                              value={experience[index]?.to}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {toModal ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={handleToOpen}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...experience];
                                        updatedData[index].to =
                                          moment(e).format("MM-YYYY");
                                        setExperience(updatedData);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12">
                          <textarea
                            name="describeRole"
                            id=""
                            style={{ resize: "none" }}
                            cols="30"
                            rows="3"
                            className="resume-input m-0"
                            placeholder="Describe your job roles & responsibilities"
                            value={experience[index]?.describeRole}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          ></textarea>
                        </div>
                        <p
                          className="information-text pb-1"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="The highlights of your journey create a powerful impact by showcasing your accomplishments, growth, and potential. They provide insight into your professional trajectory and demonstrate the value you bring to the table."
                        >
                          <img src={info} className="pe-2" alt="" />
                          What impact would responsibilities create
                        </p>
                        <div className="col-12">
                          <textarea
                            name="jobHighlight"
                            id=""
                            cols="30"
                            style={{ resize: "none" }}
                            rows="3"
                            className="resume-input m-0"
                            placeholder="Write about the highlights of your journey "
                            value={experience[index]?.jobHighlight}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          ></textarea>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text"
                            onClick={AddExperience}
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add more
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text"
                            disabled={experience.length === 1}
                            onClick={() => DeleteExperience(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div>
                        {/* mobile tooltip addded */}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown2}
              >
                <p
                  className="personal-title m-0"
                  style={{ paddingBottom: dropdown2 === true ? "12px" : "0" }}
                >
                  Certificates awarded
                </p>
                <img
                  src={downArrow}
                  style={{ paddingBottom: dropdown2 === true ? "12px" : "0" }}
                  alt=""
                />
              </div>
              <div
                className="resume-box"
                style={
                  dropdown2 === true
                    ? { display: "block" }
                    : { display: "none", marginTop: "12px" }
                }
              >
                <div className="row">
                  {jobAchievement?.map((element, index) => {
                    return (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Company name"
                            className="resume-input"
                            name="certificateName"
                            value={jobAchievement[index]?.certificateName}
                            onChange={(e) => {
                              const updatedData = [...jobAchievement];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setJobAchievement(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            htmlFor={`file-upload${index}`}
                            class="custom-file-upload"
                            onClick={handleDivClick}
                          >
                            {cart[index] !== null
                              ? cart[index]?.name
                              : "Upload certificate"}
                            <input
                              id={`file-upload${index}`}
                              // onChange={(e) => handleImage(e, "image")}
                              ref={imageRef}
                              className="custom-file-input"
                              type="file"
                              accept="application/pdf"
                              name="certificateLink"
                              onChange={(e) => {
                                updateFiles(e, index);
                              }}
                            />
                            <img src={uploadBtn} alt="" />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={handleReceivedOpen}
                          >
                            <input
                              placeholder="Received (mm/yyyy)"
                              className="input-date"
                              type="text"
                              name="issueDate"
                              value={jobAchievement[index]?.issueDate}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {receivedModal ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={() => setReceivedModal(false)}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...jobAchievement];
                                        updatedData[index].issueDate =
                                          moment(e).format("MM-YYYY");
                                        setJobAchievement(updatedData);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            style={{
                              borderBottom: "1px solid rgb(203, 213, 225)",
                              marginBottom: "12px",
                              marginTop: "12px",
                            }}
                          ></div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text"
                            onClick={AddjobAchievement}
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add more
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            disabled={jobAchievement.length === 1}
                            className="plush-text"
                            onClick={() => DeletejobAchievement(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="bottom-btn">
              <div className="d-flex flex-column">
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <button
                  className="skip-btn"
                  onClick={() => navigate("/build-resume-1")}
                >
                  Skip for now
                </button>
              </div>
            </div>
          </div>

          {/* DeskTop View */}

          <div className="profile-build desktop-show-hide">
            <div className="build-profile-line"></div>
            <div className="resume-box">
              <h6 className="resume-title">Work Experience</h6>
              <div className="row">
                {experience.map((element, index) => {
                  return (
                    <>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          name="companyName"
                          placeholder="Company name"
                          className="resume-input"
                          value={experience[index]?.companyName}
                          onChange={(e) => {
                            const updatedData = [...experience];
                            updatedData[index][e.target.name] = e.target.value;
                            setExperience(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div
                          ref={ref1}
                          className="dropdown"
                          onMouseEnter={() => setDropHover1(true)}
                          onMouseLeave={() => setDropHover1(false)}
                        >
                          <div
                            onClick={handleOnClick1}
                            style={{
                              outline: dropdown3 ? "2px solid #94A3B8" : "none",
                            }}
                            className="dropdown-btn"
                          >
                            {experience[index]?.companyType}
                            <img
                              src={dropHover1 ? downArrowHover : downArrow}
                              alt=""
                            />
                          </div>
                          <div
                            className="dropdown-content"
                            style={{ display: dropdown3 ? "block" : "none" }}
                          >
                            {selectItem.map((element, i) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    const updatedData = [...experience];
                                    updatedData[index].companyType =
                                      e.target.textContent;
                                    setExperience(updatedData);
                                    setDropdown3(false);
                                  }}
                                  className="item"
                                >
                                  {element.option}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          placeholder="Job Role"
                          className="resume-input"
                          name="role"
                          value={experience[index]?.role}
                          onChange={(e) => {
                            const updatedData = [...experience];
                            updatedData[index][e.target.name] = e.target.value;
                            setExperience(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          placeholder="Designation (if any)"
                          className="resume-input"
                          type="text"
                          name="designation"
                          value={experience[index]?.designation}
                          onChange={(e) => {
                            const updatedData = [...experience];
                            updatedData[index][e.target.name] = e.target.value;
                            setExperience(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div
                          className="calender-date-input"
                          onClick={handleFromOpen}
                        >
                          <input
                            placeholder="From (mm/yyyy)"
                            className="input-date"
                            type="text"
                            value={experience[index]?.from}
                          />
                          <img
                            src={CalenderImg}
                            className="input-calender"
                            alt=""
                          />
                        </div>
                        {fromModal ? (
                          <div className="calender-modal">
                            <div className="calender-box-2">
                              <button
                                className="close-btn"
                                onClick={handleFromOpen}
                              >
                                <img src={closeBtn} alt="" />
                              </button>
                              <div className="text-center">
                                <h6 className="calender-title m-0">
                                  Choose date slot(s)
                                </h6>
                                <div className="calender">
                                  <Calendar
                                    onChange={(e) => {
                                      const updatedData = [...experience];
                                      updatedData[index].from =
                                        moment(e).format("MM-YYYY");
                                      setExperience(updatedData);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <button
                          className="calender-date-input"
                          disabled={checked === true}
                          style={{ opacity: checked ? "0.5" : "1" }}
                          onClick={handleToOpen}
                        >
                          <input
                            placeholder="To (mm/yyyy)"
                            className="input-date"
                            disabled={experience[index].to === "Present"}
                            type="text"
                            value={experience[index]?.to}
                          />
                          <img
                            src={CalenderImg}
                            className="input-calender"
                            alt=""
                          />
                        </button>
                        {toModal ? (
                          <div className="calender-modal">
                            <div className="calender-box-2">
                              <button
                                className="close-btn"
                                onClick={handleToOpen}
                              >
                                <img src={closeBtn} alt="" />
                              </button>
                              <div className="text-center">
                                <h6 className="calender-title m-0">
                                  Choose date slot(s)
                                </h6>
                                <div className="calender">
                                  <Calendar
                                    onChange={(e) => {
                                      const updatedDataa = [...experience];
                                      updatedDataa[index].to =
                                        moment(e).format("MM-YYYY");
                                      setExperience(updatedDataa);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-12">
                        <div className="checkbox-button d-flex align-items-center">
                          <label>
                            <input
                              id={`language1${Date.now()}`}
                              type="checkbox"
                              // checked={checked}
                              // className="checkbox-button-input"
                              // value="Elementary"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const updatedData = [...experience];
                                  updatedData[index].to = "Present";
                                  setExperience(updatedData);
                                } else {
                                  const updatedData = [...experience];
                                  updatedData[index].to = "";
                                  setExperience(updatedData);
                                }
                              }}
                            />
                            am currently working here
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <textarea
                          name="describeRole"
                          id=""
                          style={{ resize: "none" }}
                          cols="30"
                          rows="3"
                          className="resume-input m-0"
                          placeholder="Describe your job roles & responsibilities"
                          value={experience[index]?.describeRole}
                          onChange={(e) => {
                            const updatedData = [...experience];
                            updatedData[index][e.target.name] = e.target.value;
                            setExperience(updatedData);
                          }}
                        ></textarea>
                      </div>
                      <div className="tooltip-container">
                        <div
                          className="tooltip-box"
                          style={{
                            display: tooltip1 ? "block" : "none",
                            left: "-12px",
                          }}
                        >
                          <div
                            className="tooltip-close"
                            onClick={() => setTooltip1(false)}
                          ></div>
                          The highlights of your journey create a powerful
                          impact by showcasing your accomplishments, growth, and
                          potential. They provide insight into your professional
                          trajectory and demonstrate the value you bring to the
                          table.
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ paddingBottom: "12px" }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={info}
                            onClick={() => setTooltip1(!tooltip1)}
                            className="pe-2"
                            alt=""
                          />
                          <p className="information-text">
                            What impact would responsibilities create
                          </p>
                        </div>
                      </div>
                      <div className="col-12">
                        <textarea
                          name="jobHighlight"
                          id=""
                          cols="30"
                          style={{ resize: "none" }}
                          rows="3"
                          className="resume-input m-0"
                          placeholder="Write about the highlights of your journey "
                          value={experience[index]?.jobHighlight}
                          onChange={(e) => {
                            const updatedData = [...experience];
                            updatedData[index][e.target.name] = e.target.value;
                            setExperience(updatedData);
                          }}
                        ></textarea>
                      </div>
                      <div className="tooltip-container">
                        <div
                          className="tooltip-box"
                          style={{
                            display: tooltip2 ? "block" : "none",
                            left: "-12px",
                          }}
                        >
                          <div
                            className="tooltip-close"
                            onClick={() => setTooltip2(false)}
                          ></div>
                          The highlights of your journey create a powerful
                          impact by showcasing your accomplishments, growth, and
                          potential. They provide insight into your professional
                          trajectory and demonstrate the value you bring to the
                          table.
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ paddingBottom: "12px" }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={info}
                            onClick={() => setTooltip2(!tooltip2)}
                            className="pe-2"
                            alt=""
                          />
                          <p className="information-text">
                            What impact would highlights create
                          </p>
                        </div>
                      </div>
                      <div className="d-flex pb-2 align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text"
                          onClick={AddExperience}
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add more
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text"
                          disabled={experience.length === 1}
                          onClick={() => DeleteExperience(index)}
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="border-bottom"></div>
            <div className="resume-box">
              <h6 className="resume-title">
                Certificates awarded
              </h6>
              <div className="row">
                {jobAchievement?.map((element, index) => {
                  return (
                    <>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          placeholder="Company name"
                          className="resume-input"
                          name="certificateName"
                          value={jobAchievement[index]?.certificateName}
                          onChange={(e) => {
                            const updatedData = [...jobAchievement];
                            updatedData[index][e.target.name] = e.target.value;
                            setJobAchievement(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div
                          class="custom-file-upload"
                          onClick={handleDivClick}
                        >
                          {cart[index] !== null
                            ? cart[index]?.name
                            : "Upload certificate"}
                          <input
                            id="file-upload"
                            // onChange={(e) => handleImage(e, "image")}
                            ref={imageRef}
                            className="custom-file-input"
                            type="file"
                            name="certificateLink"
                            onChange={(e) => {
                              updateFiles(e, index);
                            }}
                          />
                          <img src={uploadBtn} alt="" />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div
                          className="calender-date-input"
                          onClick={() => handleReceivedOpen(index)}
                        >
                          <input
                            placeholder="Received (mm/yyyy)"
                            className="input-date"
                            type="text"
                            name="issueDate"
                            value={jobAchievement[index]?.issueDate}
                          />
                          <img
                            src={CalenderImg}
                            className="input-calender"
                            alt=""
                          />
                        </div>
                        {receivedModal === index ? (
                          <div className="calender-modal">
                            <div className="calender-box-2">
                              <button
                                className="close-btn"
                                onClick={() => setReceivedModal(false)}
                              >
                                <img src={closeBtn} alt="" />
                              </button>
                              <div className="text-center">
                                <h6 className="calender-title m-0">
                                  Choose date slot(s)
                                </h6>
                                <div className="calender">
                                  <Calendar
                                    onChange={(e) => {
                                      const updatedData = [...jobAchievement];
                                      updatedData[index].issueDate =
                                        moment(e).format("MM-YYYY");
                                      setJobAchievement(updatedData);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="d-flex pb-2 align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text"
                          onClick={AddjobAchievement}
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add more
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text"
                          disabled={jobAchievement.length === 1}
                          onClick={() => DeletejobAchievement(index)}
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="text-center pb-4">
              <button className="back-btn" onClick={() => navigate(-1)}>
                Back
              </button>
              <button className="save-btn" onClick={handleOnSave}>
                Save and Continue
              </button>
              <NavLink to={"/build-resume-1"}>
                <button className="skip-btn">Skip for now</button>
              </NavLink>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default BuildResume;
