import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import "./recruiter.css";
import SubHeader from "../subHeader/SubHeader";
import search from "../../assets/recruiter/search.svg";
import AddImg from "../../assets/recruiter/add-icon.svg";
// import Profile from "../../assets/recruiter/profile-circle.svg";
// import dotIcon from "../../assets/recruiter/dot-btn.svg";
import PlushIcon from "../../assets/recruiter/plush-icon.svg";
import ProfileIcon from "../../assets/3682281.png"
import AllModalContext from "../../context/allModal/AllModalContext";
import { useNavigate } from "react-router-dom";
import AssignModal from "./AssignModal";
import RecruiterSubMenu from "../mobileMenu/RecruiterSubMenu";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import InterviewersCard from "./InterviewersCard";
import RecruiterHeader from "../header/RecruiterHeader";

// function InterviewersCard({ toggleAssign }) {
//   const navigate = useNavigate();
//   const [dotBtn, setDotBtn] = useState(false);

//   return (
//     <>
//       <div className="interview-card">
//         <div className="d-flex align-items-center justify-content-between">
//           <div className="d-flex align-items-start">
//             <img src={Profile} style={{ marginRight: "8px" }} alt="" />
//             <div>
//               <h6 className="interview-card-title m-0">Piyush Sharma </h6>
//               <p className="interview-card-text m-0">CEO </p>
//             </div>
//           </div>
//           <button className="dot-btn" onClick={() => setDotBtn(!dotBtn)}>
//             <img src={dotIcon} alt="" />
//           </button>
//           <div
//             className="interview-card-dropdown"
//             style={{ display: dotBtn ? "flex" : "none" }}
//           >
//             <button onClick={toggleAssign}>Assign Application</button>
//             <button onClick={() => navigate('/dashboard/profile-detail')}>View Details</button>
//           </div>
//         </div>
//         <div
//           style={{ margin: "12px 0", borderBottom: "1px solid #F1F5F9" }}
//         ></div>
//         <button className="open-record-btn">Open Record</button>
//       </div>
//     </>
//   );
// }

function AllInterviews() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const modal = useContext(AllModalContext);
  const menuItem = useContext(MobileContext);
  const { toggleAssign } = modal;
  const { handleRecruiterToggle, recruiterMenu } = menuItem;
  const [inputVal, setInputVal] = useState("");

  return (
    <>
      <RecruiterHeader title="Interviewers"  />
      <div className="desktop-show-hide">
        <SubHeader />
      </div>
      <div className="desktop-show-hide">
        <div className="recruiter-container">
          <div className="d-flex justify-content-end">
            <div className="draft-search" style={{ marginBottom: "16px" }}>
              <input
                type="text"
                placeholder="Search"
                value={inputVal}
                onChange={(e) => setInputVal(e.target.value)}
              />
              <img src={search} alt="" />
            </div>
          </div>
          <div className="interviews-container">
            <div className="d-flex align-items-center justify-content-between w-100">
              <h6 className="interview-title">All Interviewers</h6>
              <button
                className="interview-btn"
                onClick={() => navigate("/dashboard/add-new-hire")}
              >
                <img src={AddImg} style={{ marginRight: "6px" }} alt="" /> Add
                Interviewer
              </button>
            </div>
            <div className="card-container">
              <InterviewersCard inputVal={inputVal} setInputVal={setInputVal} />
              {/* <InterviewersCard toggleAssign={toggleAssign} />
              <InterviewersCard toggleAssign={toggleAssign} />
              <InterviewersCard toggleAssign={toggleAssign} />
              <InterviewersCard toggleAssign={toggleAssign} />
              <InterviewersCard toggleAssign={toggleAssign} />
              <InterviewersCard toggleAssign={toggleAssign} />
              <InterviewersCard toggleAssign={toggleAssign} />
              <InterviewersCard toggleAssign={toggleAssign} /> */}
            </div>
          </div>
        </div>
      </div>

      {/* mobile view */}
      <div className="mobile-show-hide">
        <div className="recruiter-container" style={{ marginTop: "60px" }}>
          {/* <InterviewersCard toggleAssign={toggleAssign} />
          <InterviewersCard toggleAssign={toggleAssign} />
          <InterviewersCard toggleAssign={toggleAssign} />
          <InterviewersCard toggleAssign={toggleAssign} />
          <InterviewersCard toggleAssign={toggleAssign} />
          <InterviewersCard toggleAssign={toggleAssign} /> */}
        </div>
        <RecruiterSubMenu />
        <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
          <img
            src={top}
            alt=""
            style={{
              transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
              transition: "transform 150ms ease",
              height: "14px",
            }}
          />
          <img src={jobIcon} style={{ height: "24px" }} alt="" />
        </div>
        <div className="bottom-btn mobile-show-hide">
          <div className="d-flex flex-column" style={{ gap: "20px" }}>
            <button
              className="bottom-preview-btn"
              onClick={() => navigate("/dashboard/add-new-hire")}
            >
              <img src={PlushIcon} style={{ marginRight: "6px" }} alt="" /> Add
              Interviewer
            </button>

            <button
              className="bottom-clear-btn"
              onClick={() => navigate("/recruiter-dashboard")}
            >
              Back
            </button>
          </div>
        </div>
        <InterviewersCard />
      </div>
      <AssignModal />
    </>
  );
}

export default AllInterviews;
