import React, { useContext, useEffect } from "react";
import './recruiter.css';
import Header from "../header/Header";
import RecruiterSidebar from "../sideBar/RecruiterSidebar";
import SideBarContext from "../../context/SidebarContext";
import box from "../../assets/recruiter-dashboard/box.svg";
import star from "../../assets/recruiter/table-star.svg";
import RecruiterHeader from "../header/RecruiterHeader";
import { useNavigate } from "react-router-dom";

function PostJobReview() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const sidebar = useContext(SideBarContext);
  const { isSidebarOpen } = sidebar;
  return (
    <>
      <RecruiterHeader />
      <section className="dashboard desktop-show-hide">
        <div className="dashboard-box ">
          <RecruiterSidebar />
          <div
            className="post-review-container"
            style={{ left: isSidebarOpen ? "85px" : "354px" }}
          >
            <div className="d-flex align-items-center">
              <img src={box} alt="" />
              <div style={{ marginLeft: "16px" }}>
                <h6 className="post-review-title">Human Resources</h6>
                <p className="post-review-text">
                  Youth Empower Foundation . 2 weeks ago
                </p>
              </div>
            </div>
            <ul style={{ marginTop: "22px", paddingLeft: "16px" }}>
              <li className="post-review-list">Full-time · Entry level</li>
              <li className="post-review-list">
                11-50 employees · IT Services and IT Consulting
              </li>
              <li className="post-review-list">
                See how you compare to 843 applicants.
              </li>
              <li className="post-review-list">
                Skills: Wireframing, User Experience (UX), +8 more
              </li>
              <li className="post-review-list">
                Skills: Wireframing, User Experience (UX), +8 more
              </li>
            </ul>
            <div className="d-flex flex-column" style={{ gap: "12px" }}>
              <h6 className="post-job-review-title">About Company</h6>
              <p className="post-job-review-text m-0">
                CNL is committed to providing an atmosphere free from barriers
                that promotes equity, diversity and inclusion in achieving our
                mission. CNL welcomes and celebrates employees, stakeholders and
                partners of all racial, cultural, and ethnic identities. CNL
                also supports a workplace environment and a corporate culture
                that is built on our Core Values: Respect, Teamwork,
                Accountability, Safety, Integrity and Excellence which encourage
                equitable employment practices and career prospects inclusive of
                accommodations for all employees. CNL is committed to being an
                equal-opportunity employer. If you require accommodation
                measures during any phase of the hiring process, please indicate
                via our ATS when applying. All information received in relation
                to accommodation requests will be kept confidential.
              </p>
              <h6 className="post-job-review-title">Job Brief</h6>
              <p className="post-job-review-text m-0">
                We are looking for a UI/UX Designer to turn our software into
                easy-to-use products for our clients.UI/UX Designer
                responsibilities include gathering user requirements, designing
                graphic elements and building navigation components. To be
                successful in this role, you should have experience with design
                software and wireframe tools. If you also have a portfolio of
                professional design projects that includes work with web/mobile
                applications, we’d like to meet you.
                <br />
                Ultimately, you’ll create both functional and appealing features
                that address our clients’ needs and help us grow our customer
                base.
              </p>
              <h6
                className="post-job-review-title"
                style={{ fontSize: "10px" }}
              >
                Responsibilities
              </h6>
              <p className="post-job-review-text m-0">
                The incumbents overall responsibilities will include, but are
                not limited to, the following:
                <ul style={{ paddingLeft: "16px", marginBottom: "0" }}>
                  <li className="post-review-list">
                    Evaluate and develop imaging and therapeutic strategies for
                    novel isotopes (including alpha emitters) and radio
                    immune-conjugates /probes/biomarkers in animal tissues and
                    cells.
                  </li>
                  <li className="post-review-list">
                    Apply practical and theoretical knowledge and provide
                    guidance for preclinical imaging equipment, instrumentation
                    and software analysis for a variety of imaging modalities,
                    which may include CT, PET, SPECT, Cerenkov, and Optical
                    systems.
                  </li>
                  <li className="post-review-list">
                    Act as technical subject matter expert for relationships
                    with academic researchers, imaging-related vendors,
                    commercial customers and stakeholders.
                  </li>
                  <li className="post-review-list">
                    Manage and configure imaging-related software packages and
                    software licenses, data acquisition, image repositories and
                    data pipelines in consultation with CNL IT department end
                    users and vendors.
                  </li>
                  <li className="post-review-list">
                    Manage all aspects of in vivo rodent Imaging Facility to
                    support drug discovery, pre-clinical research and basic
                    research, including research protocols, radiation
                    safety/shielding, dosimetry.
                  </li>
                </ul>
              </p>
              <h6
                className="post-job-review-title"
                style={{ fontSize: "10px" }}
              >
                Qualification
              </h6>
              <p className="post-job-review-text m-0">
                <ul style={{ paddingLeft: "16px", marginBottom: "0" }}>
                  <li className="post-review-list">
                    An advanced understanding of theoretical and practical
                    aspects of equipment and software applications used for
                    preclinical imaging using PET, SPECT, CT, Optical or other
                    relevant imaging modalities.
                  </li>
                  <li className="post-review-list">
                    Operational Experience coordinating, conducting and
                    analysing preclinical imaging studies and previous
                    experience working at an in vivo imaging platform with
                    multi-modality imaging expertise is desirable.
                  </li>
                  <li className="post-review-list">
                    Experience with imaging data analysis with common imaging
                    applications and software and experience with programming
                    languages is highly desirable.
                  </li>
                  <li className="post-review-list">
                    Demonstrated independence in experimental design, data
                    analysis and interpretation and track record of successful
                    internal and external scientific collaborations.
                  </li>
                </ul>
              </p>
              <h6
                className="post-job-review-title"
                style={{ fontSize: "10px" }}
              >
                Benefits
              </h6>
              <p className="post-job-review-text m-0">
                <ul style={{ paddingLeft: "16px", marginBottom: "0" }}>
                  <li className="post-review-list">Medical Insurance</li>
                  <li className="post-review-list">Flexible leaves</li>
                  <li className="post-review-list">Paid vacations</li>
                </ul>
              </p>
              <h6
                className="post-job-review-title"
                style={{ fontSize: "10px" }}
              >
                Learn more about the company
              </h6>
              <div className="row">
                <div className="col-2">
                  <h6 className="preview-link-title">Website</h6>
                  <p className="preview-link">Website link</p>
                  <h6 className="preview-link-title">Industry</h6>
                  <p className="preview-link">Industry type</p>
                  <h6 className="preview-link-title">Head Quarters</h6>
                  <p className="preview-link">Industry type</p>
                </div>
                <div className="col-2">
                  <h6 className="preview-link-title">Phone Number</h6>
                  <p className="preview-link">Phone Number</p>
                  <h6 className="preview-link-title">Company Size</h6>
                  <p className="preview-link">11-50 employess</p>
                  <h6 className="preview-link-title">Founded</h6>
                  <p className="preview-link">Phone number</p>
                </div>
                <div className="col-2">
                  <h6 className="preview-link-title">
                    Company rating <img src={star} alt="" />
                  </h6>
                  <p className="preview-link">View company reviews</p>
                </div>
              </div>
              <div className="d-flex justify-content-end">
              <div className="d-flex">
                <button className="remove-btn">Edit</button>
                <button className="invite-btn">Post</button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PostJobReview;
