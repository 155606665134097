import React, { useState } from "react";
import "../../pages/css/login.css";
import Header from "../../components/header/Header";
import cap from "../../assets/login/cap.svg";
import img1 from "../../assets/login/ats-logo.svg";
import img2 from "../../assets/login/apply-logo.svg";
import img3 from "../../assets/login/skill-logo.svg";
import img4 from "../../assets/login/up-skills.svg";
import google from "../../assets/login/google-icon.svg";
import arrow from "../../assets/login/left-arrow.svg";
import hoverArrow from "../../assets/login/left-arrow-hover.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import eyesHide from "../../assets/eyes-show.svg";
import eyes from "../../assets/eyes-hide.svg";
import "./css/sign-in.css";
import {
  emailRegex,
  nameRegex,
  phoneRegex,
} from "../regexPatterns/RegexPatterns";
import Loader from "../loader/Loader";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import LoginHeader from "../header/LoginHeader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";

function RecruiterSignIn() {
  const [showArrow, setShowArrow] = useState(false);
  const initialState = {
    name: "",
    mobileNo: "",
    email: "",
    password: "",
  };
  // const [Radio, setRadio] = useState("Recruiter");

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyes);
  const [formData, setFormData] = useState(initialState);
  const [nameError, setNameError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { RecruiterSigin, googleRecruierLogin, recruiterFindByEmal } =
    useMain();

  const handleToggle = (e) => {
    e.preventDefault();
    if (type === "password") {
      setIcon(eyesHide);
      setType("text");
    } else {
      setIcon(eyes);
      setType("password");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "name") {
      if (value === "") {
        setNameError("email is required");
      } else {
        setNameError(null);
      }
    }
    if (name === "mobileNo") {
      if (value === "") {
        setPhoneError("phone number is required");
      } else {
        setPhoneError(null);
      }
    }
    if (name === "email") {
      if (value === "") {
        setEmailError("email is required");
      } else {
        setEmailError(null);
      }
    }
    if (name === "password") {
      if (value === "") {
        setPasswordError("password is required");
      } else {
        setPasswordError(null);
      }
    }
  };

  async function SignIn(e) {
    e.preventDefault();
    const { name, mobileNo, email, password } = formData;
    if (name === "" || !nameRegex.test(name)) {
      setNameError("Name is required");
      return;
    }
    if (mobileNo === "" || !phoneRegex.test(mobileNo)) {
      setPhoneError("Please enter valid number");
      return;
    }
    if (email === "" || !emailRegex.test(email)) {
      setEmailError("Please enter valid email");
      return;
    }
    if (password === "" || password.length < 6) {
      setPasswordError("Password must be 6 characters");
      return;
    }

    if (name && mobileNo && email && password) {
      setLoader(true);
      const data = {
        name,
        mobileNo,
        email,
        password,
      };
      const result = await RecruiterSigin(data);
      if (result?.statusCode === 200) {
        setLoader(false);
        navigate("/recruiter-dashboard");
        // navigate("/recruiter-set");
        localStorage.setItem("recruiterToken", result?.data?.tokens);
        localStorage.setItem(
          "recruiterData",
          JSON.stringify(result?.data?.recruiter)
        );
        localStorage.setItem("recruiteId", result?.data?.recruiter?._id);
        localStorage.setItem("auth", true);
        toast.success(result?.message);
      } else if (result?.statusCode === 400) {
        toast.error(result?.message);
        setLoader(false);
      } else {
        toast.error("somthing went wrong");
        setLoader(false);
      }
    } else {
      toast.info("All fields are required.");
      setLoader(false);
    }
  }

  const googleLogin = async (data) => {
    setLoader(true);
    const decode = jwtDecode(data?.credential);

    if (decode?.email_verified) {
      const result = await recruiterFindByEmal({ email: decode?.email });
      if (result?.statusCode == 200) {
        setLoader(false);
        toast.info("Already have an account. Please log in.");
      } else if (result?.statusCode === 404) {
        const result = await googleRecruierLogin({
          email: decode?.email,
          name: decode?.name,
        });
        if (result?.statusCode === 200) {
          setLoader(false);
          // navigate("/recruiter-set");
          navigate("/recruiter-update-mobile");
          localStorage.setItem("recruiteId", result?.data?.recruiter?._id);
          localStorage.setItem(
            "recruiterData",
            JSON.stringify(result?.data?.recruiter)
          );
          localStorage.setItem("recruiterToken", result?.data?.token);
          localStorage.setItem("auth", true);
        } else {
          toast.error("Somthing went wrong");
          setLoader(false);
        }
      }
    }
  };

  return (
    <>
      <AlertContainer />
      <LoginHeader btnName="Sign In" link="/recruiter-login" />
      {loader ? (
        <Loader />
      ) : (
        <section className="login-hero">
          <div className="login-container">
            <div className="login-content">
              <h2 className="login-title">Work smart to land your</h2>
              <div className="span">
                Perfect Candidate
                <img src={cap} className="img-fluid" alt="" />
              </div>
              <div className="desktop-show-hide">
                <p className="login-text m-0">
                  Here’s how we help you reach your goals faster.
                </p>
                <div className="login-map">
                  <div className="d-flex align-items-center login-map-content">
                    <img src={img1} alt="" className="login-img" />
                    <p className="m-0 login-map-text">
                      Access to Industry standard resume
                    </p>
                  </div>
                  <div className="d-flex align-items-center login-map-content">
                    <img src={img2} alt="" className="login-img" />
                    <p className="m-0 login-map-text">Free ATS </p>
                  </div>
                  <div className="d-flex align-items-center login-map-content">
                    <img src={img3} alt="" className="login-img" />
                    <p className="m-0 login-map-text">
                      Application analytics for better results
                    </p>
                  </div>
                  <div className="d-flex align-items-center login-map-content">
                    <img src={img4} alt="" className="login-img" />
                    <p className="m-0 login-map-text">
                      Add Interviewers & View Their Progress
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sign-up">
              <div className="text-center">
                <h6 className="sign-title">Sign up</h6>
                <p className="sign-text">
                  Ready to take the next step? Sign up today and embark on a
                  journey towards professional success
                </p>
                <div className="d-flex w-100 justify-content-center">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      googleLogin(credentialResponse);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
                <div className="form-border"></div>
                <form>
                  <div>
                    <input
                      type="text"
                      placeholder="Name"
                      value={formData?.name}
                      name="name"
                      className={
                        nameError === null ? "sign-input" : "sign-input-error"
                      }
                      onChange={handleChange}
                    />
                    {nameError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{nameError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      value={formData?.mobileNo}
                      name="mobileNo"
                      placeholder="Phone number"
                      className={
                        phoneError === null ? "sign-input" : "sign-input-error"
                      }
                      onChange={handleChange}
                    />
                    {phoneError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{phoneError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="email"
                      name="email"
                      value={formData?.email}
                      placeholder="Email address"
                      className={
                        emailError === null ? "sign-input" : "sign-input-error"
                      }
                      onChange={handleChange}
                    />
                    {emailError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{emailError}</p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="pass"
                      className={
                        passwordError === null
                          ? "sign-input"
                          : "sign-input-error"
                      }
                    >
                      <input
                        type={type}
                        placeholder="Password"
                        id="pass"
                        style={{
                          border: "none",
                          outline: "none",
                          width: "100%",
                          left: "0",
                        }}
                        name="password"
                        value={formData?.password}
                        onChange={handleChange}
                      />
                      <button
                        className="show-password-btn"
                        onClick={handleToggle}
                      >
                        <img src={icon} alt="" />
                      </button>
                    </label>
                    {passwordError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{passwordError}</p>
                    )}
                  </div>
                  <button
                    className="sign-up-btn"
                    onMouseEnter={() => setShowArrow(true)}
                    onMouseLeave={() => setShowArrow(false)}
                    onClick={SignIn}
                  >
                    Sign up to Start
                    <img
                      src={showArrow ? hoverArrow : arrow}
                      style={{ marginLeft: "4px" }}
                      alt=""
                    />
                  </button>
                  <p className="link-btn m-0">
                    I already have an account.
                    <Link to="/log-in">
                      <span style={{ color: "#11274B" }}>Sign-In</span>
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default RecruiterSignIn;
