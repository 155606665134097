import React from "react";
import Slider from "react-slick";
import article1 from "../../assets/article/article-1.webp";
import article2 from "../../assets/article/article-2.webp";
import article3 from "../../assets/article/article-3.webp";
import article4 from "../../assets/article/article-4.webp";
import article5 from "../../assets/article/article-5.webp";
import article6 from "../../assets/article/article-6.webp";
import article7 from "../../assets/article/article-7.webp";
import article8 from "../../assets/article/article-8.webp";
import article9 from "../../assets/article/article-9.webp";
import article10 from "../../assets/article/article-10.webp";
import { Link } from "react-router-dom";

function InformationCard({ image, text, path, title }) {
  return (
    <>
      <div className="article-card">
        <img
          src={image}
          style={{ width: "100%", height: "209px", objectFit: "cover", borderRadius: "8px" }}
          alt=""
        />
        <div style={{ marginTop: "12px" }}>
          <h6 className="article-title" style={{textTransform: "capitalize"}}>{title.slice(0, 15) + "..."}</h6>
          <p className="article-text">{text.slice(0, 40) + "..."}</p>
          <Link to={path} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
            <button className="article-read-more"> Read More </button>
          </Link>
        </div>
      </div>
    </>
  );
}

function ArticleSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "25%",
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 5,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 5,
          centerMode: true,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "16%",
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "14%",
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "24%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "22%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "14%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <InformationCard
            image={article3}
            path="https://www.thesocialsynapse.com/post/the-art-of-win-win-partnerships-building-sustainable-business-relationships"
            title="The art of win-win partnerships: building sustainable Business relationships"
            text="In the ever-evolving landscape of business and collaboration, the concept of win-win partnerships has emerged as a guiding principle for..."
          />
        </div>
        <div>
          <InformationCard
            image={article4}
            path="https://www.thesocialsynapse.com/post/the-catalyst-of-career-advancement-the-role-of-a-mentor"
            title="The Catalyst Of Career Advancement: The Role Of A Mentor"
            text="In the quest for career advancement, there's a powerful but often underestimated factor that plays a pivotal role: mentorship. Many..."
          />
        </div>
        <div>
          <InformationCard
            image={article5}
            path="https://www.thesocialsynapse.com/post/health-and-wellness-for-founders-balancing-stress"
            title="Health And Wellness For Founders: Balancing Stress With Social Synapse"
            text="As a founder, the journey of building and scaling a business can be incredibly rewarding, but it's not without its challenges. One of the..."
          />
        </div>
        <div>
          <InformationCard
            image={article6}
            path="https://www.thesocialsynapse.com/post/the-power-of-thought-leadership-in-industries"
            title="The Power Of Thought Leadership"
            text="In today's dynamic and interconnected business landscape, thought leadership has emerged as a formidable force that can significantly..."
          />
        </div>
        <div>
          <InformationCard
            image={article7}
            path="https://www.thesocialsynapse.com/post/navigating-the-global-startup-ecosystem-insights-from-social-synapse"
            title="Navigating The Global Startup Ecosystem: Insights From Social Synapse"
            text="In a world of boundless opportunities and interconnected markets, startups are not just local ventures; they are global endeavors...."
          />
        </div>
        <div>
          <InformationCard
            image={article8}
            path="https://www.thesocialsynapse.com/post/mastering-the-art-of-elevator-pitches-a-guide"
            title="Mastering the Art of Elevator Pitches: A Guide"
            text="In today's fast-paced world, the ability to make a lasting impression in a short amount of time is invaluable. Whether you're a job..."
          />
        </div>
        <div>
          <InformationCard
            image={article9}
            path="https://www.thesocialsynapse.com/post/the-power-of-networking-communication-brand-building-job"
            title="The Power of Networking: Building Bridges to Success"
            text="Introduction In today's interconnected world, success often hinges on not just what you know, but who you know. Networking, the art of..."
          />
        </div>
        <div>
          <InformationCard
            image={article10}
            path="https://www.thesocialsynapse.com/post/unleashing-the-power-of-networking-join-the-social-synapse-community"
            title="Unleashing the Power of Networking: Join The Social Synapse"
            text="In an ever-evolving professional landscape, the significance of networking cannot be overstated. It is the lifeblood of success, the..."
          />
        </div>
        <div>
          <InformationCard
            image={article1}
            path="https://www.thesocialsynapse.com/post/social-synapse-s-impact-on-local-economies-supporting-startup-ecosystems-worldwide"
            title="Social Synapse's Impact On Local Economies: Supporting Startup Ecosystems Worldwide"
            text="Introduction: The modern world is witnessing a global wave of innovation and entrepreneurship, driven by startups that are..."
          />
        </div>
        <div>
          <InformationCard
            image={article2}
            path="https://www.thesocialsynapse.com/post/embracing-innovation-navigating-the-ever-evolving-landscape"
            title="Embracing Innovation: Navigating The Ever-evolving Landscape"
            text="In today's fast-paced world, innovation isn't just a buzzword; it's the driving force behind progress. From disruptive technologies to..."
          />
        </div>
      </Slider>
    </>
  );
}

export default ArticleSlider;
