import React from "react";
import "./recruiter.css";
import { useState } from "react";
import { useMain } from "../../hook/useMain";
import { useEffect } from "react";
import box from "../../assets/images.png";
import { Link } from "react-router-dom";
import Loader from "../loader/Loader";
import { AlertContainer } from "../Alerts/ToastifyContainer";


const SaveDraft = () => {
  const [draft, setDraft] = useState([]);
  const [loader, setLoader] = useState(false);

  const { getAllDraft } = useMain();
  const getdata = async () => {
    const token = localStorage.getItem("recruiterToken");
    setLoader(true);
    if (token) {
      const res = await getAllDraft(token);
      if (res?.statusCode === 200) {
        setLoader(false);
        setDraft(res?.data);
      } else {
        // alert("Somthing went wrong");
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    getdata();
  }, []);

  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  console.log(draft);

  return (
    <>
      {/* <AlertContainer/> */}
      {loader ? (
        <Loader />
      ) : (
        draft?.length > 0 &&
        draft &&
        draft?.map((e, ind) => (
          <div className="draft-card" key={`saveDraft-${ind}`}>
            <div className="d-flex align-items-start">
              <img
                src={
                  JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                    ? JSON.parse(localStorage.getItem("recruiterData"))
                        .companyLogo
                    : box
                }
                alt=""
                style={{ marginRight: "8px", height: "25px", width: "25px" }}
              />
              <div className="w-100" style={{height:"50px"}}>
                <h2 className="draft-card-title">{e?.jobInfo?.position}</h2>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="draft-card-text">{e?.companyName}</p>
                  <p className="draft-days">{timeAgo(e.createdAt)}</p>
                </div>
              </div>
            </div>
            <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
            <ul className="draft-list">
              <li>
                {e?.jobInfo?.jobType} · {e?.jobInfo?.workPlaceType}
              </li>
              <li>
                {e?.userId?.companyStrength} employees · {e?.userId?.industry}
              </li>
              {/* <li>See how you compare to 843 applicants.</li>
              <li>Skills: Wireframing, User Experience (UX), +8 more</li>
              <li>Skills: Wireframing, User Experience (UX), +8 more</li> */}
            </ul>
            <Link to={`/dashboard/all-application/review/${e?._id}`}>
              <div className="draft-edit-btn">Edit</div>
            </Link>
          </div>
        ))
      )}
    </>
  );
};

export default SaveDraft;
