import React, { useContext, useEffect, /* useEffect, */ useState } from "react";
import "./header.css";
import logo from "../../assets/logo.svg";
// import mobileLogo from "../../assets/mobile-logo.svg";
import searchImg from "../../assets/white-search.svg";
import upgrade from "../../assets/upgrade.svg";
import { Link, useNavigate } from "react-router-dom";
import AllModalContext from "../../context/allModal/AllModalContext";
import UpgradeModal from "../../dashboard/UpgradeModal";
import leftArrow from "../../assets/stories/left-arrow.svg";
import Dropdown from "../../assets/talent/header-down.svg";

function FormHeader({ title }) {
  const [toggleSideBar, setSidebarToggle] = useState(false);
  const [searchInput, setSearchInput] = useState(false);
  const [dropdown, setDropdown] = React.useState(false);
  const auth = localStorage.getItem("auth");

  const [name, setName] = useState("");

  useEffect(() => {
    const recruiterToken = localStorage.getItem("recruiterToken");
    const recruiterId = localStorage.getItem("recruiteId");
    const userToken = localStorage.getItem("userToken");
    const userId = localStorage.getItem("userId");
    if (recruiterToken && recruiterId) {
      setName(JSON.parse(localStorage.getItem("recruiterData")).name);
    } else if (userToken && userId) {
      setName(JSON.parse(localStorage.getItem("userData")).name);
    } else {
      setName("Me");
    }
  }, []);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };
  const modal = useContext(AllModalContext);
  const { toggleUpgrade } = modal;

  const navigate = useNavigate();

  function openSidebar() {
    setSidebarToggle(!toggleSideBar);
  }
  function openSearch() {
    setSearchInput(!searchInput);
  }
  // useEffect(() => {
  //   $(window).on("scroll", function () {
  //     if ($(window).scrollTop() > 50) {
  //       $("header").css("background-color", "");
  //     } else {
  //       $("header").css("background-color", "#172742");
  //     }
  //   });
  // }, []);

  function handleOnLogin() {
    navigate("/sign-in");
  }
  function handleUpgrade() {
    navigate("/upgrade");
  }

  const throwOnDashboard = () => {
    const career_id = localStorage.getItem("career_id");
    const career_token = localStorage.getItem("career_token");
    if (career_id && career_token) {
      navigate("/career/table");
    }
  };

  return (
    <div>
      <header className="form-header">
        <div
          className="search-input"
          style={{ display: searchInput ? "flex" : "none" }}
        >
          <input type="text" />
          <img src={searchImg} onClick={openSearch} alt="" />
        </div>
        <div
          className="overlay"
          onClick={() => {
            openSidebar();
          }}
          style={toggleSideBar ? { right: 0 } : { right: "100%" }}
        ></div>
        <div className="logo">
          {/* <Link to="/"> */}
          <img
            src={logo}
            alt=""
            className="img-fluid"
            onClick={throwOnDashboard}
          />
          {/* </Link> */}
        </div>
        <div
          className="mobile-logo"
          style={{ display: searchInput ? "none" : "block" }}
        >
          <div className="build-btn" onClick={() => navigate(-1)}>
            <img src={leftArrow} style={{ cursor: "pointer" }} alt="" />{" "}
            <button>{title}</button>
          </div>
        </div>
        <div
          className="list"
          style={{
            display: searchInput ? "none" : "inline-block",
          }}
        >
          {/* <button className="header-search-btn" onClick={openSearch}>
            <img src={searchImg} alt="" />
          </button> */}
          {/* <button className="upgrade-btn" onClick={toggleUpgrade}>
            Plan Upgrade
          </button> */}
          <img className="me-4 upgrade-btn-img" src={upgrade} alt="" />
          {auth === undefined ? (
            <button className="login-btn" onClick={handleOnLogin}>
              Get Started
            </button>
          ) : null}
          {auth ? (
            <button className="menu-dropdown" onClick={handleDropdown}>
              Me <img src={Dropdown} alt="" />
            </button>
          ) : null}
          {dropdown ? (
            <ul className="menu">
              {/* <li
                className="menu-item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="d-flex align-items-center"> */}
              {/* <Link
                    to={
                      pathname === "/recruiter-dashboard"
                        ? "/recruiter-set"
                        : "/set-job"
                    }
                  >
                    Profile
                  </Link>
                  <p
                    className="m-0"
                    style={{
                      color: "#0D9488",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      paddingLeft: "20px",
                      lineHeight: "16px",
                    }}
                  >
                    60% complete
                  </p> */}
              {/* </div>
              </li> */}
              <li className="menu-item">
                <div className="d-flex justify-content-between">
                  <Link to="/career-setting">Profile and Settings </Link>
                  <button
                    style={{
                      color: "#FFF",
                      borderRadius: "100px",
                      padding: "2px 8px",
                      border: "none",
                      background: "#D97706",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "16px",
                    }}
                    onClick={() => navigate("/help-us")}
                  >
                    Verify Adhaar
                  </button>
                </div>
              </li>
              <li className="menu-item">
                <Link to="/help-and-support">Help and Support</Link>
              </li>
              {/* <li className="menu-item">
                <Link to="">Contact us</Link>
              </li> */}
              <li
                className="menu-item"
                onClick={() => {
                  localStorage.clear();
                  navigate("/home");
                }}
              >
                <Link to="">Log out</Link>
              </li>
              <button className="menu-upgrade-btn" onClick={handleUpgrade}>
                Upgrade Plans
              </button>
            </ul>
          ) : null}
        </div>
        <button onClick={() => openSidebar()} className="bras-btn">
          <i className="fa fa-regular fa-bars"></i>
        </button>
      </header>
      <UpgradeModal />
    </div>
  );
}

export default FormHeader;
