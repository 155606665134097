import React, { useEffect, useState } from "react";
// import Header from "../components/header/Header";
// import info from "../assets/information.svg";
import "../App.css";
import "./css/dropdown.css";
import plush from "../assets/plush.svg";
import Delete from "../assets/delete.svg";
import closeBtn from "../assets/close-btn.svg";
import { Grid } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import whiteNumber1 from "../assets/mobile/white-number-1.svg";
import whiteNumber2 from "../assets/mobile/white-number-2.svg";
import YellowNumber3 from "../assets/mobile/yellow-number-3.svg";
import number4 from "../assets/mobile/number4.svg";
import downArrow from "../assets/mobile/down-arrow.svg";
import FormHeader from "../components/header/FormHeader";
import Fuse from "fuse.js";
// import uploadBtn from "../assets/calender/upload.svg";
import downArrowHover from "../assets/mobile/down-arrow-hover.svg";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import Header from "../components/header/Header";

const selectLanguage = [
  {
    id: 1,
    option: "Hindi",
    selected: false,
  },
  {
    id: 2,
    option: "English",
    selected: false,
  },
  {
    id: 3,
    option: "Gujrati",
    selected: false,
  },
  {
    id: 4,
    option: "Marathi",
    selected: false,
  },
];
const Skills = [
  {
    id: 1,
    option: "Figma",
    selected: false,
  },
  {
    id: 2,
    option: "Designing",
    selected: false,
  },
  {
    id: 3,
    option: "Information Technology",
    selected: false,
  },
  {
    id: 4,
    option: "Web Developer",
    selected: false,
  },
];

function BuildResumeTwo() {
  const initialValue = {
    subcategories: [],
  };
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [dropdown1, setDropdown1] = useState(true);
  const [dropdown2, setDropdown2] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [search, setSearch] = useState(false);
  const [loader, setLoader] = useState(false);
  const [languages, setLanguages] = useState([
    { langName: "Language", proficiency: "" },
  ]);
  const [selected, setIsSelected] = useState("");

  const fuse = new Fuse(Skills, {
    includeScore: true,
    keys: ["option"],
  });
  const pattern = filterText;
  const result = fuse.search(pattern);

  const { BuildResumeThree } = useMain();
  const id = localStorage.getItem("userId");

  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (userData) {
      setLanguages(
        userData?.language?.length > 0
          ? userData?.language
          : [{ langName: "Language", proficiency: "" }]
      );
      setFormData({
        ...formData,
        subcategories: userData?.skills,
      });
    }
  }, []);

  useEffect(() => {
    if (selected.trim() !== "") {
      if (!formData?.subcategories?.includes(selected))
        setFormData({
          ...formData,
          subcategories: [...formData?.subcategories, selected],
        });
    }
  }, [selected]);

  // function handleAddSkill(e) {
  const handleEnterPress = (e) => {
    if (e.key === "Enter" && filterText.trim() !== "") {
      setFormData({
        ...formData,
        subcategories: [...formData?.subcategories, filterText.trim()],
      });
      setFilterText("");
    }
  };
  // if (e.key === "Enter") {
  //   if (filterText.trim() !== "") {
  //     if (!formData?.subcategories?.includes(filterText))
  //       setFormData({
  //         ...formData,
  //         subcategories: [...formData?.subcategories, filterText],
  //       });
  //   }
  // }
  // }
  function AddLanguages() {
    setLanguages([...languages, { langName: "Language", proficiency: "" }]);
  }

  async function handleOnSave() {
    setLoader(true);
    const data = {
      language: languages,
      skills: formData.subcategories,
    };
    const result = await BuildResumeThree(data, id);
    if (result?.statusCode === 200) {
      setLoader(false);
      toast.success(result?.message);
      localStorage.setItem("userData", JSON.stringify(result?.data));
      navigate("/help-us");
    } else {
      toast.error("Somthing went wrong");
      setLoader(false);
    }
  }

  function Dropdown1() {
    setDropdown2(false);
    setDropdown1(!dropdown1);
  }
  function Dropdown2() {
    setDropdown1(false);
    setDropdown2(!dropdown2);
  }

  const [language, setLanguage] = useState(false);

  const [dropHover1, setDropHover1] = useState(false);
  const [dropHover3, setDropHover3] = useState(false);
  const [formData, setFormData] = useState(initialValue);

  function DeleteLanguages(id) {
    let filteredArray = languages.filter((item, index) => index !== id);
    setLanguages(filteredArray);
  }
  useEffect(() => {
    if (selected.trim() !== "") {
      if (!formData?.subcategories?.includes(selected))
        setFormData({
          ...formData,
          subcategories: [...formData?.subcategories, selected],
        });
    }
  }, [selected]);
  const handleDeleteSubcategory = (item) => {
    const subcategories = formData?.subcategories?.filter(
      (s, id) => id !== item
    );
    setFormData({ ...formData, subcategories });
  };

  return (
    <>
      <AlertContainer />
      <Header title="Build Profile" />
      {loader ? (
        <Loader />
      ) : (
        <section className="resume-two-build">
          {/* Mobile View */}
          <div className="mobile-show-hide">
            <div className="mobile-line-box">
              <div className="mobile-line-4"></div>
              <div className="d-flex justify-content-around number-line">
                <img src={whiteNumber1} alt="" />
                <img src={whiteNumber2} alt="" />
                <img src={YellowNumber3} alt="" />
                <img src={number4} alt="" />
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown1}
              >
                <p
                  className="personal-title m-0"
                  style={{ paddingBottom: dropdown1 === true ? "12px" : "0" }}
                >
                  Languages
                </p>
                <img
                  src={downArrow}
                  alt=""
                  style={{ paddingBottom: dropdown1 === true ? "12px" : "0" }}
                />
              </div>
              <div
                className="resume-box"
                style={
                  dropdown1 === true
                    ? { display: "block" }
                    : { display: "none", marginTop: "12px" }
                }
              >
                {languages.map((element, index) => {
                  return (
                    <>
                      <div className="row pt-2">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div>
                            {language === index ? (
                              <div
                                className="select-overlay"
                                onClick={() => setLanguage(false)}
                              ></div>
                            ) : (
                              ""
                            )}
                            <div
                              className="dropdown"
                              onMouseEnter={() => setDropHover1(true)}
                              onMouseLeave={() => setDropHover1(false)}
                              style={{
                                zIndex: language === index ? "1112" : "",
                              }}
                            >
                              <div
                                onClick={(e) => {
                                  setLanguage(index);
                                }}
                                style={{
                                  outline:
                                    index === language
                                      ? "2px solid #94A3B8"
                                      : "none",
                                }}
                                className="dropdown-btn"
                              >
                                {languages[index]?.langName}
                                <img
                                  src={dropHover1 ? downArrowHover : downArrow}
                                  alt=""
                                />
                              </div>
                              <div
                                className="dropdown-content"
                                style={{
                                  display:
                                    index === language ? "block" : "none",
                                }}
                              >
                                {selectLanguage.map((element, i) => {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        const updatedData = [...languages];
                                        updatedData[index].langName =
                                          e.target.textContent;
                                        setLanguages(updatedData);
                                        setLanguage(false);
                                      }}
                                      className="item"
                                    >
                                      {element.option}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-none col-md-none col-lg-4"></div>
                        <div className="col-sm-none col-md-6 col-lg-4"></div>
                        <p className="build-resume-two m-0">
                          Choose proficiency
                        </p>
                        <Grid
                          container
                          columns={{ xs: 4, sm: 8, md: 12, lg: 20 }}
                        >
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className="radio-button">
                              <label>
                                <input
                                  className="mb-2 lang-input"
                                  id={`language1${Date.now()}`}
                                  type="radio"
                                  value="Elementary"
                                  checked={
                                    languages[index]?.proficiency ===
                                    "Elementary"
                                  }
                                  onChange={(e) => {
                                    const updatedData = [...languages];
                                    updatedData[index].proficiency =
                                      e.target.value;
                                    setLanguages(updatedData);
                                  }}
                                />
                                Elementary
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className="radio-button">
                              <label>
                                <input
                                  className="mb-2 lang-input"
                                  type="radio"
                                  id={`language2${Date.now()}`}
                                  value="Limited Working"
                                  checked={
                                    languages[index]?.proficiency ===
                                    "Limited Working"
                                  }
                                  onChange={(e) => {
                                    const updatedData = [...languages];
                                    updatedData[index].proficiency =
                                      e.target.value;
                                    setLanguages(updatedData);
                                  }}
                                />
                                Limited Working
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className="radio-button">
                              <label>
                                <input
                                  className="mb-2 lang-input"
                                  type="radio"
                                  id={`language3${Date.now()}`}
                                  value="Professional Working"
                                  checked={
                                    languages[index]?.proficiency ===
                                    "Professional Working"
                                  }
                                  onChange={(e) => {
                                    const updatedData = [...languages];
                                    updatedData[index].proficiency =
                                      e.target.value;
                                    setLanguages(updatedData);
                                  }}
                                />
                                Professional Working
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className="radio-button">
                              <label>
                                <input
                                  className="mb-2 lang-input"
                                  type="radio"
                                  id={`language4${Date.now()}`}
                                  value="Full Professional"
                                  checked={
                                    languages[index]?.proficiency ===
                                    "Full Professional"
                                  }
                                  onChange={(e) => {
                                    const updatedData = [...languages];
                                    updatedData[index].proficiency =
                                      e.target.value;
                                    setLanguages(updatedData);
                                  }}
                                />
                                Full Professional
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className="radio-button">
                              <label>
                                <input
                                  className="mb-2 lang-input"
                                  type="radio"
                                  id={`language5${Date.now()}`}
                                  value="Native/Bilingual"
                                  checked={
                                    languages[index]?.proficiency ===
                                    "Native/Bilingual"
                                  }
                                  onChange={(e) => {
                                    const updatedData = [...languages];
                                    updatedData[index].proficiency =
                                      e.target.value;
                                    setLanguages(updatedData);
                                  }}
                                />
                                Native/Bilingual
                              </label>
                            </div>
                            <div
                              style={{
                                borderBottom: "1px solid rgb(203, 213, 225)",
                                marginBottom: "12px",
                              }}
                            ></div>
                          </Grid>
                        </Grid>
                      </div>

                      <div className="d-flex align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text px-0"
                          onClick={AddLanguages}
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add Language
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text px-0"
                          disabled={languages.length === 1}
                          onClick={() => DeleteLanguages(index)}
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown2}
              >
                <p
                  className="personal-title m-0"
                  style={{ paddingBottom: dropdown1 === true ? "12px" : "0" }}
                >
                  Add Skills
                </p>
                <img
                  src={downArrow}
                  alt=""
                  style={{ paddingBottom: dropdown2 === true ? "12px" : "0" }}
                />
              </div>
              {/* <div
                className="resume-box"
                style={
                  dropdown2 === true
                    ? { display: "block" }
                    : { display: "none", marginTop: "12px" }
                }
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div
                      className="dropdown m-0"
                      onMouseEnter={() => setDropHover3(true)}
                      onMouseLeave={() => setDropHover3(false)}
                    >
                      <div
                        style={{
                          outline: isActive ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                        onClick={() => {
                          setIsActive(!isActive);
                        }}
                      >
                        {search ? (
                          selected
                        ) : (
                          <input
                            type="text"
                            placeholder="Choose skills"
                            className="search-input-box"
                            onKeyPress={handleEnterPress}
                            onChange={(e) => {
                              setFilterText(e.target.value);
                              setSearch(false);
                            }}
                            value={filterText}
                          />
                        )}
                        <img
                          src={dropHover3 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: isActive ? "block" : "none" }}
                      >
                        {search
                          ? Skills.map((element, index) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    setIsSelected(e.target.textContent);
                                    setSearch(!search);
                                    setIsActive(!isActive);
                                  }}
                                  className="item"
                                >
                                  {element.option}
                                </div>
                              );
                            })
                          : result.map((element, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={(e) => {
                                    setIsSelected(e.target.textContent);
                                    setSearch(!search);
                                    setIsActive(!isActive);
                                  }}
                                  className="item"
                                >
                                  {element.item.option}
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p
                        className="m-0"
                        style={{
                          color: "#64748B",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          paddingTop: "6px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "16px",
                        }}
                      >
                        Add Max 20
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex-inline mt-2">
                  {formData?.subcategories?.length > 0 && (
                    <div className="d-flex-inline">
                      {formData?.subcategories?.map((item, index) => (
                        <p className="select-option-box" key={index}>
                          {item}
                          <img
                            src={closeBtn}
                            style={{ marginLeft: "6px", opacity: "0.6" }}
                            alt=""
                            onClick={() => handleDeleteSubcategory(index)}
                          />
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div> */}
              <div className="resume-box">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="input-container mt-2">
                      <input
                        type="text"
                        placeholder="Add skills and Press Enter"
                        className="search-input-box"
                        onKeyPress={handleEnterPress}
                        onChange={(e) => {
                          setFilterText(e.target.value);
                        }}
                        value={filterText}
                      />
                    </div>
                    <div className="d-flex-inline mt-2">
                      {formData?.subcategories?.length > 0 && (
                        <div className="d-flex-inline">
                          {formData?.subcategories?.map((item, index) => (
                            <p className="select-option-box" key={index}>
                              {item}
                              <img
                                src={closeBtn}
                                style={{ marginLeft: "6px", opacity: "0.6" }}
                                alt=""
                                onClick={() => handleDeleteSubcategory(index)}
                              />
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-btn">
              <div className="d-flex flex-column">
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <button
                  className="skip-btn"
                  onClick={() => navigate("/help-us")}
                >
                  Skip for now
                </button>
              </div>
            </div>
          </div>

          {/* Desktop View */}
          <div className="desktop-show-hide">
            <div className="profile-build">
              <div className="build-profile-two-line"></div>
              <div className="resume-box">
                <h6 className="resume-title">Languages</h6>
                <div className="row">
                  {languages.map((element, index) => {
                    return (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div>
                            {language === index ? (
                              <div
                                className="select-overlay"
                                onClick={() => setLanguage(false)}
                              ></div>
                            ) : (
                              ""
                            )}
                            <div
                              className="dropdown"
                              onMouseEnter={() => setDropHover1(true)}
                              onMouseLeave={() => setDropHover1(false)}
                              style={{
                                zIndex: language === index ? "1112" : "",
                              }}
                            >
                              <div
                                onClick={(e) => {
                                  setLanguage(index);
                                }}
                                style={{
                                  outline: language
                                    ? "2px solid #94A3B8"
                                    : "none",
                                }}
                                className="dropdown-btn"
                              >
                                {languages[index]?.langName}
                                <img
                                  src={dropHover1 ? downArrowHover : downArrow}
                                  alt=""
                                />
                              </div>
                              <div
                                className="dropdown-content"
                                style={{
                                  display:
                                    index === language ? "block" : "none",
                                }}
                              >
                                {selectLanguage.map((element, i) => {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        const updatedData = [...languages];
                                        updatedData[index].langName =
                                          e.target.textContent;
                                        setLanguages(updatedData);
                                        setLanguage(false);
                                      }}
                                      className="item"
                                    >
                                      {element.option}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-none col-md-none col-lg-4"></div>
                        <div className="col-sm-none col-md-6 col-lg-4"></div>
                        <p className="build-resume-two m-0">
                          Choose proficiency
                        </p>
                        <Grid
                          container
                          columns={{ xs: 4, sm: 8, md: 12, lg: 20 }}
                        >
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className="radio-button">
                              <label>
                                <input
                                  className="me-2 lang-input"
                                  id={`language1${Date.now()}`}
                                  type="radio"
                                  value="Elementary"
                                  checked={
                                    languages[index]?.proficiency ===
                                    "Elementary"
                                  }
                                  onChange={(e) => {
                                    const updatedData = [...languages];
                                    updatedData[index].proficiency =
                                      e.target.value;
                                    setLanguages(updatedData);
                                  }}
                                />
                                Elementary
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className="radio-button">
                              <label>
                                <input
                                  className="me-2 lang-input"
                                  type="radio"
                                  id={`language2${Date.now()}`}
                                  value="Limited Working"
                                  checked={
                                    languages[index]?.proficiency ===
                                    "Limited Working"
                                  }
                                  onChange={(e) => {
                                    const updatedData = [...languages];
                                    updatedData[index].proficiency =
                                      e.target.value;
                                    setLanguages(updatedData);
                                  }}
                                />
                                Limited Working
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className="radio-button">
                              <label>
                                <input
                                  className="me-2 lang-input"
                                  type="radio"
                                  id={`language3${Date.now()}`}
                                  value="Professional Working"
                                  checked={
                                    languages[index]?.proficiency ===
                                    "Professional Working"
                                  }
                                  onChange={(e) => {
                                    const updatedData = [...languages];
                                    updatedData[index].proficiency =
                                      e.target.value;
                                    setLanguages(updatedData);
                                  }}
                                />
                                Professional Working
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className="radio-button">
                              <label>
                                <input
                                  className="me-2 lang-input"
                                  type="radio"
                                  id={`language4${Date.now()}`}
                                  value="Full Professional"
                                  checked={
                                    languages[index]?.proficiency ===
                                    "Full Professional"
                                  }
                                  onChange={(e) => {
                                    const updatedData = [...languages];
                                    updatedData[index].proficiency =
                                      e.target.value;
                                    setLanguages(updatedData);
                                  }}
                                />
                                Full Professional
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}>
                            <div className="radio-button">
                              <label>
                                <input
                                  className="me-2 lang-input"
                                  type="radio"
                                  id={`language5${Date.now()}`}
                                  value="Native/Bilingual"
                                  checked={
                                    languages[index]?.proficiency ===
                                    "Native/Bilingual"
                                  }
                                  onChange={(e) => {
                                    const updatedData = [...languages];
                                    updatedData[index].proficiency =
                                      e.target.value;
                                    setLanguages(updatedData);
                                  }}
                                />
                                Native/Bilingual
                              </label>
                            </div>
                          </Grid>
                        </Grid>

                        <div className="d-flex pb-2 pt-2 align-items-center justify-content-between">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text px-0"
                            onClick={AddLanguages}
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add Language
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text px-0"
                            disabled={languages.length === 1}
                            onClick={() => DeleteLanguages(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="border-bottom"></div>
              <div className="resume-box">
                <h6 className="resume-title pt-3">Add Skills</h6>
                <div className="d-flex justify-content-between align-items-center ">
                  {/* <div className="d-flex align-items-center mb-4">
                    <div
                      className="dropdown m-0"
                      onMouseEnter={() => setDropHover3(true)}
                      onMouseLeave={() => setDropHover3(false)}
                    >
                      <div
                        style={{
                          outline: isActive ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                        onClick={() => {
                          setIsActive(!isActive);
                        }}
                      >
                        {search ? (
                          selected
                        ) : (
                          <input
                            type="text"
                            placeholder="Choose skills"
                            className="search-input-box"
                            onKeyPress={handleEnterPress}
                            onChange={(e) => {
                              setFilterText(e.target.value);
                              setSearch(false);
                            }}
                            value={filterText}
                          />
                        )}
                        <img
                          src={dropHover3 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: isActive ? "block" : "none" }}
                      >
                        {search
                          ? Skills.map((element, index) => {
                              return (
                                <div
                                  onClick={(e) => {
                                    setIsSelected(e.target.textContent);
                                    setSearch(!search);
                                    setIsActive(!isActive);
                                  }}
                                  className="item"
                                >
                                  {element.option}
                                </div>
                              );
                            })
                          : result.map((element, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={(e) => {
                                    setIsSelected(e.target.textContent);
                                    setSearch(!search);
                                    setIsActive(!isActive);
                                  }}
                                  className="item"
                                >
                                  {element.item.option}
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    <p
                      className="m-0"
                      style={{
                        color: "#64748B",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        width: "100px",
                        marginLeft: "20px",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}
                    >
                      Add Max 20
                    </p>
                  </div> */}
                  <div className="d-flex align-items-center mb-4">
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Add skills and Press Enter"
                        className="search-input-box"
                        onKeyPress={handleEnterPress}
                        onChange={(e) => {
                          setFilterText(e.target.value);
                          setSearch(false);
                        }}
                        value={filterText}
                      />
                    </div>
                    <p  className="m-0"
                      style={{
                        color: "#64748B",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        width: "100px",
                        marginLeft: "20px",
                        fontWeight: "400",
                        lineHeight: "16px",
                      }}>Add Max 20</p>
                  </div>
                  <div></div>
                </div>
                {/* <div className="d-flex-inline">
                  {formData?.subcategories?.length > 0 && (
                    <div className="d-flex-inline">
                      {formData?.subcategories?.map((item, index) => (
                        <p className="select-option-box" key={index}>
                          {item}
                          <img
                            src={closeBtn}
                            style={{ marginLeft: "6px", opacity: "0.6" }}
                            alt=""
                            onClick={() => handleDeleteSubcategory(index)}
                          />
                        </p>
                      ))}
                    </div>
                  )}
                </div> */}
                <div className="d-flex-inline">
                  {formData?.subcategories?.length > 0 && (
                    <div className="d-flex-inline">
                      {formData?.subcategories?.map((item, index) => (
                        <p className="select-option-box" key={index}>
                          {item}
                          <img
                            src={closeBtn}
                            style={{ marginLeft: "6px", opacity: "0.6" }}
                            alt=""
                            onClick={() => handleDeleteSubcategory(index)}
                          />
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="text-center pb-4">
                <button className="back-btn" onClick={() => navigate(-1)}>
                  Back
                </button>
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <NavLink to="/help-us">
                  <button className="skip-btn">Skip For Now</button>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default BuildResumeTwo;
