import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/Header";
import Sidebar from "../components/sideBar/Sidebar";
import SideBarContext from "../context/SidebarContext";
import calender from "../assets/calender-track.svg";
import filter from "../assets/filter-track.svg";
import Upcoming from "../assets/recruiter-dashboard/upcoming.svg";
import RightArrow from "../assets/recruiter-dashboard/right-arrow.svg";
import DummyUpcoming from "../assets/recruiter/upcoming-dummy.svg";
import "./dashboard.css";
import savedImg from "../assets/saved-application.svg";
import box from "../assets/office-building.png";
import chat from "../assets/chat-track.svg";
import star from "../assets/star-track.svg";
import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material";
import whiteCalender from "../assets/talent/white-calender.png";
import whiteFilter from "../assets/talent/white-filter.png";
import DownArrow from "../assets/talent/down-arrow.png";
import MobileMenu from "../components/mobileMenu/MobileMenu";
import MobileContext from "../context/mobile/MobileContext";
import jobIcon from "../assets/job-icon.svg";
import top from "../assets/top-arrow.svg";
import FeedbackModal from "./FeedbackModal";
import AllModalContext from "../context/allModal/AllModalContext";
import { useNavigate } from "react-router-dom";
import closeBtn from "../assets/close-bt.svg";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import TrackChat from "./Chat/TrackChat";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";

const steps = [
  "Application submitted",
  "Application viewed",
  "Interviewing",
  "Decision needed",
  "Hired/Not-Hired",
];
const stepsTwo = [
  "Application submitted",
  "Application viewed",
  "Choose Interview slot",
  "Interview Round 2 ",
  "Offer extended",
];
const pastSteps = [
  "Application submitted",
  "Application viewed",
  "Interview Round 1",
  "View Feedback",
  "Offer extended",
];

const months = [
  { name: "Jan", id: 1 },
  { name: "Feb", id: 2 },
  { name: "Mrc", id: 3 },
  { name: "Apr", id: 4 },
  { name: "May", id: 5 },
  { name: "Jun", id: 6 },
  { name: "Jul", id: 7 },
  { name: "Aug", id: 8 },
  { name: "Sep", id: 9 },
  { name: "Oct", id: 10 },
  { name: "Nov", id: 11 },
  { name: "Dec", id: 12 },
];
const year = [
  { name: "2023", id: 1 },
  { name: "2024", id: 2 },
  { name: "2025", id: 3 },
  { name: "2026", id: 4 },
  { name: "2027", id: 5 },
  { name: "2028", id: 6 },
  { name: "2029", id: 7 },
  { name: "2030", id: 8 },
];
const events = [];
const renderEventContent = () => {
  return (
    <>
      <b>hello</b>
    </>
  );
};
const renderDayCellContent = (args) => {
  const { date } = args;
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
  const year = date.getFullYear();
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${
    month < 10 ? "0" : ""
  }${month}-${year}`;

  // Check if the formatted date is in the attendance data
  // const isPresent = allattendance?.includes(formattedDate);
  const isPresent = null;

  const isToday = date.toDateString() === new Date().toDateString();
  // console.log(data)
  return (
    <div>
      {/* <div>{day}</div> */}
      {/* {isToday && !isPresent && (
        <button
          size="small"
          variant="outlined"
          color="primary"
          // onClick={handleModalOpen}
          style={{
            position: 'absolute',
            top: '10px',
            right: '2.5rem',
            fontSize: '10px',
          }}
        >
          Add Attendance
        </button>
      )} */}
      {/* <button
        size="small"
        variant="outlined"
        color="primary"
        onClick={() => openAppointmentDrawer(date.toISOString())}
        style={{
          position: 'absolute',
          top: '90px',
          right: '35px',
          fontSize: '10px',
        }}
      >
        Appointment
      </button> */}
    </div>
  );
};

function TrackPage() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem("auth") === "true";
    if (!isAuthenticated) {
      navigate("/log-in");
      return;
    }
  }, []);
  const { getapplyJob, sidebarSearch, getInterviewDataForUser } = useMain();
  const [data, setData] = useState([]);
  const [sortData, setSortData] = useState([]);
  const [interviewData, setInterviewData] = useState([]);
  const [loader, setLoader] = useState(false);
  const getData = async (id) => {
    setLoader(true);
    const res = await getapplyJob(id);
    if (res?.statusCode === 200) {
      setLoader(false);
      setData(res?.data);
      setSortData(res?.data);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };
  const getInterviewData = async (id) => {
    const res1 = await getInterviewDataForUser(id);
    if (res1?.statusCode === 200) {
      console.log(res1);
      setInterviewData(res1?.data);
    }
  };
  useEffect(() => {
    const { _id } = JSON.parse(localStorage.getItem("userData")) || {};
    if (_id) {
      getData(_id);
      getInterviewData(_id);
    }
  }, []);
  const modal = useContext(AllModalContext);
  const { toggleModal, toggleTrack } = modal;
  const sidebar = useContext(SideBarContext);
  const menuItem = useContext(MobileContext);
  const { isSidebarOpen } = sidebar;
  const { handleMobileToggle, mobileMenu } = menuItem;
  const [aria, setAria] = useState(false);
  const [ariaTwo, setAriaTwo] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);

  function OpenFilter() {
    setFilterOpen(!filterOpen);
  }

  function OpenCalender() {
    setCalenderOpen(!calenderOpen);
  }

  function handleAria(index) {
    setAria(index);
  }

  function handleAriaTwo() {
    setAriaTwo(!ariaTwo);
  }

  function MobileRating() {
    navigate("/dashboard/track/rating");
  }
  const getStatusStepIndex = (status) => {
    switch (status) {
      case "HIRE":
      case "Hire":
        return "Hire";
      case "NOT-HIRE":
      case "Not Hire":
      case "NOT HIRE":
        return "Not Hire";
      case "DECISION NEEDED":
      case "Decision Needed":
        return 4;
      case "INTERVIEWING":
      case "Interviewing":
        return 3;
      case "PENDING":
      case "Pending":
        return 2;
      default:
        return 1;
    }
  };

  // console.log(data);

  return (
    <>
      <AlertContainer />
      <Header />
      {loader ? (
        <Loader />
      ) : (
        <section className="dashboard">
          <div className="dashboard-box">
            <div className="sidebar-scroll">
              <Sidebar />
            </div>
            <div
              className="track-box desktop-show-hide"
              style={{ left: isSidebarOpen ? "85px" : "354px" }}
            >
              <div className="track-box-content">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="track-title">Track Ongoing Applications </h6>
                  <div className="d-flex">
                    <div
                      className="d-flex align-items-center me-4"
                      onClick={OpenCalender}
                    >
                      <img src={calender} alt="" />
                      <p className="m-0 track-btn">My Calendar</p>
                    </div>
                    <div
                      className="d-flex align-items-center me-4"
                      onClick={OpenFilter}
                    >
                      <img src={filter} alt="" />
                      <p className="m-0 track-btn">Filter</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column" style={{ gap: "8px" }}>
                  {data?.length === 0 ? (
                    <div className="unsaved-image">
                      <img src={savedImg} alt="" />
                      <h6 className="unsaved-text m-0">
                        No Track Applications{" "}
                      </h6>
                    </div>
                  ) : (
                    data?.map((e, index) => (
                      <div key={index} className="d-flex">
                        <div className="trackk-card">
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                e?.jobId?.companyLogo
                                  ? e?.jobId?.companyLogo
                                  : box
                              }
                              alt=""
                              style={{ height: "25px", width: "25px" }}
                            />
                            <div className="ps-3">
                              <h6 className="track-title m-0">
                                {e?.jobId?.jobInfo?.position}
                              </h6>
                              <p className="track-text m-0">
                                {e?.jobId?.companyName}
                              </p>
                            </div>
                          </div>
                          <div className="track-card-border"></div>
                          <div className="d-flex align-items-center">
                            <p
                              className="track-item m-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              {e?.jobId?.jobInfo?.workPlace}
                            </p>
                            <div className="track-dot"></div>
                            <p
                              className="track-item m-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              {e?.jobId?.jobInfo?.workPlaceType}
                            </p>
                            <div className="track-dot"></div>
                            <p
                              className="track-item m-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              {e?.jobId?.jobInfo?.jobType}
                            </p>
                            {/* <div className="business-dot"></div>
                      <p className="business-item m-0">Immediately</p> */}
                          </div>
                          <div className="d-flex mt-3">
                            <div
                              className="track-card"
                              onClick={() => setToggle(index)}
                            >
                              <img src={chat} alt="" />
                              <h6 className="track-text">Chat with HR</h6>
                            </div>

                            <div
                              className="track-card"
                              onClick={() => setToggle2(index)}
                            >
                              <img src={star} alt="" />
                              <h6 className="track-text">
                                Rate your experience
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="stepper">
                          <Stepper
                            activeStep={
                              [
                                "Hire",
                                "HIRE",
                                "Not Hire",
                                "Not-Hire",
                                "NOT-HIRE",
                              ].includes(getStatusStepIndex(e?.status))
                                ? 5
                                : getStatusStepIndex(e?.status)
                            }
                            alternativeLabel
                          >
                            {steps.slice(0, 5).map((label, index) => (
                              <Step key={label}>
                                <StepLabel>
                                  {index === 4 &&
                                  [
                                    "Hire",
                                    "HIRE",
                                    "Not Hire",
                                    "Not-Hire",
                                    "NOT-HIRE",
                                  ].includes(getStatusStepIndex(e?.status))
                                    ? e?.status
                                    : label}
                                </StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </div>
                        {toggle === index && (
                          <TrackChat
                            setToggle={setToggle}
                            interviewerId={e?.jobId?._id}
                            name={e?.jobId?.jobInfo?.position}
                            company={e?.jobId?.companyName}
                          />
                        )}
                        {toggle2 === index && (
                          <FeedbackModal
                            setToggle={setToggle2}
                            compayId={e.recruiterId}
                            name={e?.jobId?.jobInfo?.position}
                            company={e?.jobId?.companyName}
                          />
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>

              {/* <div className="track-box-content">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="track-title">Past applications</h6>
                  <div className="d-flex">
                    <div className="d-flex align-items-center me-4">
                      <img src={filter} alt="" />
                      <p className="m-0 track-btn">Filter</p>
                    </div>
                  </div>
                </div>
                <p
                  className="m-0"
                  style={{
                    color: "#4D4D4D",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    paddingBottom: "4px",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                >
                  2023
                </p>
                <div className="d-flex">
                  <div>
                    <div className="d-flex">
                      <div className="trackk-card">
                        <div className="d-flex align-items-center">
                          <img src={box} alt="" />
                          <div className="ps-3">
                            <h6 className="track-title m-0">Human Resources</h6>
                            <p className="track-text m-0">
                              Youth Empower Foundation
                            </p>
                          </div>
                        </div>
                        <div className="track-card-border"></div>
                        <ul className="pl-2 m-0">
                          <li className="save-item">Delhi</li>
                          <li className="save-item">Start up</li>
                          <li className="save-item">Full-time</li>
                          <li className="save-item">Immediately</li>
                        </ul>
                        <div className="d-flex mt-3">
                          <div className="track-card">
                            <img src={chat} alt="" />
                            <h6 className="track-text">Chat with HR</h6>
                          </div>
                          <div className="track-card" onClick={toggleModal}>
                            <img src={star} alt="" />
                            <h6 className="track-text">Rate your experience</h6>
                          </div>
                        </div>
                      </div>
                      <div className="stepper">
                        <Box sx={{ width: "100%" }}>
                          <Stepper activeStep={1} alternativeLabel>
                            {pastSteps.map((label) => (
                              <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </Box>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="trackk-card">
                        <div className="d-flex align-items-center">
                          <img src={box} alt="" />
                          <div className="ps-3">
                            <h6 className="track-title m-0">Human Resources</h6>
                            <p className="track-text m-0">
                              Youth Empower Foundation
                            </p>
                          </div>
                        </div>
                        <div className="track-card-border"></div>
                        <ul className="pl-2 m-0">
                          <li className="save-item">Delhi</li>
                          <li className="save-item">Start up</li>
                          <li className="save-item">Full-time</li>
                          <li className="save-item">Immediately</li>
                        </ul>
                        <div className="d-flex mt-3">
                          <div className="track-card">
                            <img src={chat} alt="" />
                            <h6 className="track-text">Chat with HR</h6>
                          </div>
                          <div className="track-card" onClick={toggleModal}>
                            <img src={star} alt="" />
                            <h6 className="track-text">Rate your experience</h6>
                          </div>
                        </div>
                      </div>
                      <div className="stepper">
                        <Box sx={{ width: "100%" }}>
                          <Stepper activeStep={1} alternativeLabel>
                            {pastSteps.map((label) => (
                              <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            {/* Mobile View */}
            <div className="mobile-show-hide mobile-recommended">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="mobile-track-title m-0">Track Applications</h6>
                <div>
                  <img
                    src={whiteCalender}
                    onClick={OpenCalender}
                    className="me-3"
                    alt=""
                  />
                  {/* <img src={whiteFilter}   onClick={OpenFilter} alt="" /> */}
                </div>
              </div>
              <p className="m-0 mobile-box-text">Ongoing</p>
              {data?.length === 0 ? (
                <div className="unsaved-image">
                  <img src={savedImg} alt="" />
                  <h6 className="unsaved-text m-0">No Track Applications </h6>
                </div>
              ) : (
                data?.map((e, index) => (
                  <div key={index} className="mobile-track-box">
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          e?.jobId?.companyLogo ? e?.jobId?.companyLogo : box
                        }
                        alt=""
                        style={{ width: "45px", height: "45px" }}
                      />
                      <div className="ps-3">
                        <h6 className="mobile-business-title m-0">
                          {e?.jobId?.jobInfo?.position}
                        </h6>
                        <p className="mobile-business-text m-0">
                          {e?.jobId?.companyName}
                        </p>
                      </div>
                    </div>
                    <div className="mobile-business-card-border"></div>
                    <ul className="pl-2 m-0">
                      <li className="save-item">
                        {e?.jobId?.jobInfo?.workPlace}
                      </li>
                      <li className="save-item">Start up</li>
                      <li className="save-item">
                        {e?.jobId?.jobInfo?.workPlaceType}
                      </li>
                      <li className="save-item">
                        {e?.jobId?.jobInfo?.jobType}
                      </li>
                    </ul>
                    {/* <div className="d-flex align-items-center">
                      <p className="mobile-business-item m-0">
                        {e?.jobId?.jobInfo?.workPlace}
                      </p>
                      <div className="mobile-business-dot"></div>
                      <p className="mobile-business-item m-0">
                        {e?.jobId?.jobInfo?.workPlaceType}
                      </p>
                      <div className="mobile-business-dot"></div>
                      <p className="mobile-business-item m-0">
                        {e?.jobId?.jobInfo?.jobType}
                      </p> */}
                    {/* <div className="mobile-business-dot"></div>
                      <p className="mobile-business-item m-0">Immediately</p> */}
                    {/* </div> */}
                    <div className="accordion-item">
                      <h2
                        className="accordion-header-track d-flex"
                        id="flush-headingOne"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          // data-bs-toggle="collapse"
                          // data-bs-target="#flush-collapseOne"
                          onClick={() =>
                            handleAria(aria == index ? null : index)
                          }
                          // aria-expanded={aria === index}
                          // aria-controls="flush-collapseOne"
                        >
                          Track Application
                        </button>
                        <img
                          src={DownArrow}
                          style={{ rotate: aria == index ? "360deg" : "" }}
                          alt=""
                          // onClick={() => handleAria(null)}
                        />
                      </h2>
                      {aria === index && (
                        <div
                        // id="flush-collapseOne"
                        // className="accordion-collapse collapse"
                        // aria-labelledby="flush-headingOne"
                        // data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <Stepper
                              activeStep={
                                [
                                  "Hire",
                                  "HIRE",
                                  "Not Hire",
                                  "Not-Hire",
                                  "NOT-HIRE",
                                ].includes(getStatusStepIndex(e?.status))
                                  ? 5
                                  : getStatusStepIndex(e?.status)
                              }
                              orientation="vertical"
                            >
                              {steps.slice(0, 5).map((label, index) => (
                                <Step key={label}>
                                  <StepLabel>
                                    {index == 4 &&
                                    [
                                      "Hire",
                                      "HIRE",
                                      "Not Hire",
                                      "Not-Hire",
                                      "NOT-HIRE",
                                    ].includes(getStatusStepIndex(e?.status))
                                      ? e?.status
                                      : label}
                                  </StepLabel>
                                </Step>
                              ))}
                              {/* {steps.map((label, index) => (
                              <Step key={label}>
                                <StepLabel>
                                  {index === 3 ? e?.status : label}
                                </StepLabel>
                              </Step>
                            ))} */}
                            </Stepper>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="d-flex mt-3">
                      <div
                        className="mobile-track-card"
                        onClick={() => setToggle(index)}
                      >
                        <img src={chat} className="me-2" alt="" />
                        <h6 className="track-text m-0">Chat with HR</h6>
                      </div>
                      <div
                        className="mobile-track-card"
                        onClick={() => setToggle2(index)}
                      >
                        <img src={star} className="me-2" alt="" />
                        <h6 className="track-text m-0">Rate your experience</h6>
                      </div>
                    </div>
                    {toggle === index && (
                      <TrackChat
                        setToggle={setToggle}
                        interviewerId={e?.jobId?._id}
                        name={e?.jobId?.jobInfo?.position}
                        company={e?.jobId?.companyName}
                      />
                    )}
                    {toggle2 === index && (
                      <FeedbackModal
                        setToggle={setToggle2}
                        compayId={e.recruiterId}
                        name={e?.jobId?.jobInfo?.position}
                        company={e?.jobId?.companyName}
                      />
                    )}
                  </div>
                ))
              )}

              {/* <p className="m-0 mobile-box-text">Past Applications</p> */}
              {/* <div className="mobile-track-box">
                <div className="d-flex align-items-center">
                  <img src={box} alt="" />
                  <div className="ps-3">
                    <h6 className="mobile-business-title m-0">
                      Human Resources
                    </h6>
                    <p className="mobile-business-text m-0">
                      Youth Empower Foundation
                    </p>
                  </div>
                </div>
                <div className="mobile-business-card-border"></div>
                <ul className="pl-2 m-0">
                  <li className="save-item">Delhi</li>
                  <li className="save-item">Start up</li>
                  <li className="save-item">Full-time</li>
                  <li className="save-item">Immediately</li>
                </ul>
                <div className="accordion-item">
                  <h2
                    className="accordion-header-track d-flex"
                    id="flush-headingOne"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      onClick={handleAriaTwo}
                      aria-expanded={ariaTwo}
                      aria-controls="flush-collapseThree"
                    >
                      Track Application
                    </button>
                    <img
                      src={DownArrow}
                      style={{ rotate: ariaTwo ? "360deg" : "" }}
                      alt=""
                    />
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <Stepper activeStep={2} orientation="vertical">
                        {pastSteps.map((step, index) => (
                          <Step key={step}>
                            <StepLabel
                              optional={
                                index === 2 ? (
                                  <Typography variant="caption">
                                    Last step
                                  </Typography>
                                ) : null
                              }
                            >
                              {step}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="mobile-track-card">
                    <img src={chat} className="me-2" alt="" />
                    <h6 className="track-text m-0">Chat with HR</h6>
                  </div>
                  <div className="mobile-track-card" onClick={MobileRating}>
                    <img src={star} className="me-2" alt="" />
                    <h6 className="track-text m-0">Rate your experience</h6>
                  </div>
                </div>
              </div> */}
              {/* <div className="mobile-track-box">
                <div className="d-flex align-items-center">
                  <img src={box} alt="" />
                  <div className="ps-3">
                    <h6 className="mobile-business-title m-0">
                      Human Resources
                    </h6>
                    <p className="mobile-business-text m-0">
                      Youth Empower Foundation
                    </p>
                  </div>
                </div>
                <div className="mobile-business-card-border"></div>
                <ul className="pl-2 m-0">
                  <li className="save-item">Delhi</li>
                  <li className="save-item">Start up</li>
                  <li className="save-item">Full-time</li>
                  <li className="save-item">Immediately</li>
                </ul>
                <div className="accordion-item">
                  <h2
                    className="accordion-header-track d-flex"
                    id="flush-headingOne"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFour"
                      onClick={handleAriaTwo}
                      aria-expanded={ariaTwo}
                      aria-controls="flush-collapseFour"
                    >
                      Track Application
                    </button>
                    <img
                      src={DownArrow}
                      style={{ rotate: ariaTwo ? "360deg" : "" }}
                      alt=""
                    />
                  </h2>
                  <div
                    id="flush-collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFour"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <Stepper activeStep={2} orientation="vertical">
                        {stepsTwo.map((step, index) => (
                          <Step key={step}>
                            <StepLabel
                              optional={
                                index === 2 ? (
                                  <Typography variant="caption">
                                    Last step
                                  </Typography>
                                ) : null
                              }
                            >
                              {step}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="mobile-track-card">
                    <img src={chat} className="me-2" alt="" />
                    <h6 className="track-text m-0">Chat with HR</h6>
                  </div>
                  <div className="mobile-track-card">
                    <img src={star} className="me-2" alt="" />
                    <h6 className="track-text m-0">Rate your experience</h6>
                  </div>
                </div>
              </div>
              <div className="mobile-track-box">
                <div className="d-flex align-items-center">
                  <img src={box} alt="" />
                  <div className="ps-3">
                    <h6 className="mobile-business-title m-0">
                      Human Resources
                    </h6>
                    <p className="mobile-business-text m-0">
                      Youth Empower Foundation
                    </p>
                  </div>
                </div>
                <div className="mobile-business-card-border"></div>
                <ul className="pl-2 m-0">
                  <li className="save-item">Delhi</li>
                  <li className="save-item">Start up</li>
                  <li className="save-item">Full-time</li>
                  <li className="save-item">Immediately</li>
                </ul>
                <div className="accordion-item">
                  <h2
                    className="accordion-header-track d-flex"
                    id="flush-headingOne"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseFive"
                      onClick={handleAriaTwo}
                      aria-expanded={ariaTwo}
                      aria-controls="flush-collapseFive"
                    >
                      Track Application
                    </button>
                    <img
                      src={DownArrow}
                      style={{ rotate: ariaTwo ? "360deg" : "" }}
                      alt=""
                    />
                  </h2>
                  <div
                    id="flush-collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingFive"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <Stepper activeStep={2} orientation="vertical">
                        {stepsTwo.map((step, index) => (
                          <Step key={step}>
                            <StepLabel
                              optional={
                                index === 2 ? (
                                  <Typography variant="caption">
                                    Last step
                                  </Typography>
                                ) : null
                              }
                            >
                              {step}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="mobile-track-card">
                    <img src={chat} className="me-2" alt="" />
                    <h6 className="track-text m-0">Chat with HR</h6>
                  </div>
                  <div className="mobile-track-card" onClick={MobileRating}>
                    <img src={star} className="me-2" alt="" />
                    <h6 className="track-text m-0">Rate your experience</h6>
                  </div>
                </div>
              </div> */}

              <MobileMenu />
              <div className="mobile-menu-btn" onClick={handleMobileToggle}>
                <img
                  src={top}
                  alt=""
                  style={{
                    transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                    transition: "transform 150ms ease",
                    height: "14px",
                  }}
                />
                <img src={jobIcon} alt="" />
              </div>
            </div>
          </div>
        </section>
      )}

      {filterOpen && (
        <div className="filter-modal">
          <div className="filter-box">
            <button className="close-btn" onClick={OpenFilter}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="d-flex">
              <div className="filter-list">
                <ul className="filter-item" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#year"
                      type="button"
                      role="tab"
                      aria-controls="year"
                      aria-selected="false"
                    >
                      Year
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#month"
                      type="button"
                      role="tab"
                      aria-controls="month"
                      aria-selected="true"
                    >
                      Month
                    </button>
                  </li>
                </ul>
                {/* <ul>
                  <li>Year</li>
                  <li>Month</li>
                  <li>Company</li>
                  <li>Skills</li>
                </ul> */}
              </div>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="year"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <ul className="filter-option">
                    {year.map((element, index) => {
                      return (
                        <li key={index}>
                          <div className="checkbox-button d-flex align-items-center">
                            <input
                              type="radio"
                              name="Month"
                              className="checkbox-button-input"
                              id={`month${element.id}`}
                            />
                            <label
                              className="checkbox-button__label"
                              htmlFor={`month${element.id}`}
                              style={{ margin: 0 }}
                            >
                              <span className="checkbox-button__custom"></span>
                              {element.name}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div
                  class="tab-pane fade"
                  id="month"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <ul className="filter-option">
                    {months.map((element, index) => {
                      return (
                        <li key={index}>
                          <div className="checkbox-button d-flex align-items-center">
                            <input
                              type="radio"
                              name="Month"
                              className="checkbox-button-input"
                              id={`month${element.id}`}
                            />
                            <label
                              className="checkbox-button__label"
                              htmlFor={`month${element.id}`}
                              style={{ margin: 0 }}
                            >
                              <span className="checkbox-button__custom"></span>
                              {element.name}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {calenderOpen && (
        <div className="ca-modal">
          <div className="ca-box">
            <button className="close-btn" onClick={OpenCalender}>
              <img src={closeBtn} alt="" />
            </button>
            <UpcomingCard data={interviewData} />
            {/* <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              weekends={true}
              events={events}
              eventContent={renderEventContent}
              dayCellContent={renderDayCellContent}
            /> */}
          </div>
        </div>
      )}
      {/* <TrackChat /> */}
    </>
  );
}

export default TrackPage;

function UpcomingCard({ data }) {
  return (
    <>
      {data && data?.length > 0 ? (
        data?.map((item, index) => (
          <div key={index} className="upcoming-card">
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex align-items-start" style={{ gap: "8px" }}>
                <img src={Upcoming} alt="" />
                <div className="d-flex flex-column" style={{ gap: "2px" }}>
                  <h6 className="upcoming-title m-0">{item?.userId?.name}</h6>
                  <p className="upcoming-category m-0">
                    Interview Title :{item?.title}
                  </p>
                  <p className="upcoming-date m-0">
                    Interview Date :{item?.date}{" "}
                  </p>
                  <p className="upcoming-date m-0">
                    Start Time :{item?.startTime}
                  </p>
                  <p className="upcoming-date m-0">
                    Meeting Link : {item?.meetLink}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between">
                {/* <img src={RightArrow} alt="" /> */}
                <p className="m-0 upcoming-date">
                  Company Name : {item?.jobId?.companyName}
                </p>
                <p className="m-0 upcoming-date">
                  Position : {item?.jobId?.jobInfo?.position}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center">
          <img src={DummyUpcoming} alt="" className="img-fluid" />
          <h6
            style={{
              color: "#B3B3B3",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "16px",
              marginBottom: "20px",
            }}
          >
            No interviews scheduled
          </h6>
        </div>
      )}
    </>
  );
}
