import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import search from "../../assets/recruiter/search.svg";
import Close from "../../assets/recruiter/close-input.svg";
import CoralSlider from "./CoralSlider";
import ResumeImg from "../../assets/profile-photo.svg";
import jobIcon from "../../assets/job-icon.svg";
import top from "../../assets/top-arrow.svg";
import CoralCard2 from "./CoralCard2";
import FIleView from "./FIleView";
import CoralCard3 from "./CoralCard3";
import MobileContext from "../../context/mobile/MobileContext";
import MobileMenu from "../mobileMenu/MobileMenu";
import downloadImg from "../../assets/recruiter/download-img.svg";
import editImg from "../../assets/recruiter/edit-img.svg";
import MobileResume from "../../assets/recruiter/mobile-resume.svg";
import pinImg from "../../assets/recruiter/pin-img.svg";
import InputField from "./InputField";
import Sidebar from "../sideBar/Sidebar";
import SideBarContext from "../../context/SidebarContext";

function TemplatesSix() {
  const menuItem = useContext(MobileContext);
  const { handleMobileToggle, mobileMenu } = menuItem;
  const sidebar = useContext(SideBarContext);
  const { isSidebarOpen } = sidebar;
  const [inputToggle, setInputToggel] = useState(false);
  const handleChangeupdate = () => {
    setInputToggel(!inputToggle);
  };
  const [formData, setFormData] = useState(null);
  const [education, setEducation] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [experience, setExperience] = useState(null);
  useEffect(() => {
    setFormData(JSON.parse(localStorage.getItem("resumeForm")));
    setEducation(JSON.parse(localStorage.getItem("resumeEducation")));
    setLanguages(JSON.parse(localStorage.getItem("resumeLanguage")));
    setExperience(JSON.parse(localStorage.getItem("resumeExperience")));
  }, [inputToggle]);
  // console.log(formData, education, languages, experience);
  return (
    <>
      <Header />
      <section className="dashboard">
        <div className="desktop-show-hide">
          <div className="dashboard-box ">
            <div className="sidebar-scroll">
              <Sidebar />
            </div>
            <div
              className="job-seeker-dashboard"
              style={{ left: isSidebarOpen ? "85px" : "354px" }}
            >
              <div className="template-container">
                <div className="template-body">
                  <div className="design-container">
                    <div className="design-head">
                      <div className="design-btns">
                        <button className="design-edit">Download</button>
                      </div>
                      <div className="design-btns">
                        <button className="design-save">Save</button>
                      </div>
                    </div>
                    <div className="design-body">
                      <div className="resume-box-4">
                        <div className="resume-box-4-top">
                          <div className="d-flex gap-4 align-items-center">
                            <img src={ResumeImg} alt="" />
                            <div>
                              <p className="r-title-4-1">Kate Bishop</p>
                              <p className="r-text-4-1">Product Designer</p>
                            </div>
                          </div>
                          <p className="r-text-1-black">
                            Over 5 years of professional experience conducting
                            UX research and designing interactive end-to-end
                            user flows. I enjoy working in close collaboration
                            with teams across technology, business and design.
                          </p>
                        </div>
                        <div className="resume-box-4-bottom">
                          <div className="resume-box-4-left">
                            <p className="r-title-4-left">Work experience</p>
                            <p className="r-title-4-1-black">Product Designer</p>
                            <p className="r-text-4-black">Product Designer</p>
                          </div>
                          <div className="resume-box-4-right">
                            <p className="r-title-4-left">Education & Learning</p>
                            <p className="r-title-4-1-black">
                              Master’s in Human-Computer Interaction
                            </p>
                            <p className="r-text-4-black">Product Designer</p>
                          </div>
                        </div>
                      </div>
                      {/* <img src={ResumeImg} className="img-fluid" alt="" /> */}
                    </div>
                    <div className="resume-file">
                      <h6 className="resume-file-title">Resumes</h6>
                      <FIleView />
                      <FIleView />
                      <FIleView />
                      <FIleView />
                    </div>
                  </div>
                  <div className="paid-tamp">
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                          className="nav-link active"
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-home"
                          type="button"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          Templates
                        </button>
                        <button
                          className="nav-link"
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-profile"
                          type="button"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                        >
                          Input fields
                        </button>
                        {/* <button
                    className="nav-link"
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                  >
                    Uploads
                  </button> */}
                      </div>
                    </nav>
                    <div
                      className="tab-content"
                      style={{ borderLeft: "none" }}
                      id="nav-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        <div className="coral-2-container">
                          <div className="template-search">
                            <img src={search} alt="" />
                            <input type="text" placeholder="Choose category" />
                            <img src={Close} alt="" />
                          </div>
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="paid-title">Saved Resume</h6>
                              <button className="paid-btn">View all</button>
                            </div>
                            <div className="coral-2-box">
                              <CoralCard2 />
                              <CoralCard2 />
                            </div>
                          </div>
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="paid-title">More</h6>
                              <button className="paid-btn">View all</button>
                            </div>
                            <div className="coral-2-box">
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        <div className="coral-2-container">
                          <InputField handleChangeupdate={handleChangeupdate} />
                        </div>
                      </div>
                      {/* <div
                  className="tab-pane fade"
                  id="nav-contact"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                >
                  Upload
                </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/*  Mobile Screen View */}
            </div>
          </div>
        </div>
      </section>
      {/*  Mobile Screen View */}
      <div className="template-container">
        <div className="mobile-show-hide">
          <div className="paid-tamp">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  className="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#templates"
                  type="button"
                  role="tab"
                  aria-controls="templates"
                  aria-selected="true"
                >
                  Templates
                </button>
                <button
                  className="nav-link"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#input-fields"
                  type="button"
                  role="tab"
                  aria-controls="input-fields"
                  aria-selected="false"
                >
                  Input fields
                </button>
                {/* <button
                  className="nav-link"
                  id="nav-contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#upload"
                  type="button"
                  role="tab"
                  aria-controls="upload"
                  aria-selected="false"
                >
                  Uploads
                </button> */}
              </div>
            </nav>
            <div
              className="tab-content"
              style={{ borderLeft: "none" }}
              id="nav-tabContent"
            >
              <div
                className="tab-pane fade show active"
                id="templates"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="coral-2-container">
                  <div style={{ marginTop: "12px" }}>
                    <p className="resume-tamp-text">Pinned</p>
                    <div className="coral-slider">
                      <CoralSlider />
                    </div>
                  </div>
                  <div style={{ marginTop: "12px" }}>
                    <p className="resume-tamp-text">More Templates</p>
                    <div className="d-flex flex-column" style={{ gap: "8px" }}>
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <h6 className="design-title">Basic CV Design</h6>
                    <p className="design-text">Best for Business</p>
                  </div>
                  <div className="d-flex" style={{ gap: "16px" }}>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        padding: "0",
                      }}
                    >
                      <img src={downloadImg} alt="download" />
                    </button>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        padding: "0",
                      }}
                    >
                      <img src={editImg} alt="edi" />
                    </button>
                  </div>
                </div>
                <img
                  src={MobileResume}
                  className="img-fluid w-100 mt-3"
                  alt=""
                />
                <button className="design-save-btn">
                  Save <img src={pinImg} alt="" />
                </button>
                <div className="resume-file">
                  <h6 className="resume-file-title">Resumes</h6>
                  <FIleView />
                  <FIleView />
                  <FIleView />
                  <FIleView />
                </div>
                <div className="mt-2">
                  <p className="resume-tamp-text">More Designs</p>
                  <div className="coral-slider">
                    <CoralSlider />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="input-fields"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <InputField handleChangeupdate={handleChangeupdate} />
              </div>
              {/* <div
                className="tab-pane fade"
                id="upload"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                Upload
              </div> */}
            </div>
          </div>
          <MobileMenu />
          <div className="mobile-menu-btn" onClick={handleMobileToggle}>
            <img
              src={top}
              alt=""
              style={{
                transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 150ms ease",
                height: "14px",
              }}
            />
            <img src={jobIcon} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default TemplatesSix;
