import React from "react";
import Subscribe from "../../assets/recruiter/sub-done.svg";

function YearlyTwo() {
  return (
    <>
      <div className={`upgrade-card-one`}>
        <div className="d-flex flex-column h-100 justify-content-between">
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="upgrade-box-title m-0">Basic</h6>
              <p className="upgrade-box-text m-0" style={{textTransform: "capitalize"}}>19,999/- per year</p>
            </div>
            <div className={`upgrade-box-border-one`}></div>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />3
              Active Job Post Every Month
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              3000 Candidates Every Month
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" /> ATS
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              Interviewer (Admin + 1)
            </p>
            <p className="upgrade-card-one-bold m-0">
              <img src={Subscribe} style={{ paddingRight: "6px" }} alt="" />
              Interview Scheduler
            </p>
          </div>
          <button className="upgrade-basic-btn">Upgrade to Basic</button>
        </div>
      </div>
    </>
  );
}

export default YearlyTwo;
