import React, { useContext, useEffect, useState } from "react";
import "./recruiter.css";
import Header from "../header/Header";
import SubHeader from "../subHeader/SubHeader";
import search from "../../assets/recruiter/search.svg";
import box from "../../assets/images.png";
import savedImg from "../../assets/saved-application.svg";
import { Link, useNavigate } from "react-router-dom";
import downArrow from "../../assets/select-down.svg";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
import Slider from "react-slick";
import RecruiterSubMenu from "../mobileMenu/RecruiterSubMenu";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import OpenApplication from "./OpenApplication";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";
import RecruiterHeader from "../header/RecruiterHeader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";

const DatePosted = [
  {
    id: 1,
    option: "Any Time",
    selected: false,
  },
  {
    id: 2,
    option: "Past Week",
    selected: false,
  },
  {
    id: 3,
    option: "Most Relevant",
    selected: false,
  },
  {
    id: 4,
    option: "Last 2 months",
    selected: false,
  },
];
const Experience = [
  {
    id: 1,
    option: "Internship",
    selected: false,
  },
  {
    id: 2,
    option: "Entry level",
    selected: false,
  },
  {
    id: 3,
    option: "Associate",
    selected: false,
  },
  {
    id: 4,
    option: "Mid-Senior level",
    selected: false,
  },
  {
    id: 5,
    option: "Director",
    selected: false,
  },
  {
    id: 6,
    option: "Executive",
    selected: false,
  },
];
const JobType = [
  {
    id: 1,
    option: "Full-time",
    selected: false,
  },
  {
    id: 2,
    option: "Part-time",
    selected: false,
  },
  {
    id: 3,
    option: "Contract",
    selected: false,
  },
  {
    id: 4,
    option: "Internship",
    selected: false,
  },
];
const OnsiteRemote = [
  {
    id: 1,
    option: "In-Office",
    selected: false,
  },
  {
    id: 2,
    option: "Remote",
    selected: false,
  },
  {
    id: 3,
    option: "Hybrid",
    selected: false,
  },
];

function DraftCard() {
  const navigate = useNavigate();
  return (
    <>
      <div className="draft-card" style={{ width: "100%" }}>
        <div className="d-flex align-items-start">
          <img
            src={box}
            alt=""
            style={{ marginRight: "8px", height: "25px", width: "25px" }}
          />
          <div className="w-100">
            <h2 className="draft-card-title">Product manager</h2>
            <div className="d-flex justify-content-between align-items-center">
              <p className="draft-card-text">product</p>
              <p className="draft-days">2 days ago</p>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
        <ul className="draft-list">
          <li>Full-time · Entry level</li>
          <li>11-50 employees · IT Services and IT Consulting</li>
          <li>See how you compare to 843 applicants.</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
        </ul>
        {/* <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <h6 className="draft-sub-title">Skills you will gain</h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <p className="m-0 draft-skills">Creativity</p>
            <p className="m-0 draft-skills">Art</p>
            <p className="m-0 draft-skills">History</p>
            <p className="m-0 draft-skills">Art History</p>
            <p className="m-0 draft-skills">Art Direction</p>
          </div>
        </div> */}
        <button
          className="draft-edit-btn"
          onClick={() => navigate("/dashboard/table")}
        >
          View Applicants
        </button>
        <button
          className="draft-mange-btn"
          onClick={() => navigate("/dashboard/all-application/review")}
        >
          Manage Application
        </button>
      </div>
    </>
  );
}
function DraftCardS({ item }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="draft-card">
        <div className="d-flex align-items-start">
          <img
            src={
              JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                ? JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                : box
            }
            alt=""
            style={{ marginRight: "8px", height: "25px", width: "25px" }}
          />
          <div className="w-100" style={{height:"50px"}}>
            <h2 className="draft-card-title">{item?.jobInfo?.position}</h2>
            <div className="d-flex justify-content-between align-items-center">
              <p className="draft-card-text">{item?.companyName}</p>
              <p className="draft-days">
                {Math.floor(
                  (new Date() - new Date(`${item?.createdAt}`)) /
                    (24 * 60 * 60 * 1000)
                )}
                days ago
              </p>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
        <ul className="draft-list">
          <li>
            {item?.jobInfo?.jobType} · {item?.jobInfo?.workPlaceType}
          </li>
          <li>
            {item?.userId?.companyStrength} employees · {item?.userId?.industry}
          </li>
          {/* <li>See how you compare to 843 applicants.</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li> */}
        </ul>
        {/* <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <h6 className="draft-sub-title">Skills you will gain</h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <p className="m-0 draft-skills">Creativity</p>
            <p className="m-0 draft-skills">Art</p>
            <p className="m-0 draft-skills">History</p>
            <p className="m-0 draft-skills">Art History</p>
            <p className="m-0 draft-skills">Art Direction</p>
          </div>
        </div> */}
        <Link
          state={{ name: item?.jobInfo?.position }}
          className="draft-edit-btn text-decoration-none"
          onClick={() => navigate(`/dashboard/table/${item?._id}`)}
        >
          View Applicants
        </Link>
        <button
          className="draft-mange-btn"
          onClick={() =>
            navigate(`/dashboard/all-application/review/${item?._id}`)
          }
        >
          Manage Application
        </button>
      </div>
    </>
  );
}
function ClosePost({ data }) {
  const navigate = useNavigate();

  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  return (
    <>
      {data && data?.length > 0 ? (
        data?.map((item, index) => (
          <div key={index} className="draft-card">
            <div className="d-flex align-items-start">
              <img
                src={
                  JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                    ? JSON.parse(localStorage.getItem("recruiterData"))
                        .companyLogo
                    : box
                }
                alt=""
                style={{ marginRight: "8px", height: "25px", width: "25px" }}
              />
              <div className="w-100" style={{height:"50px"}}>
                <h2 className="draft-card-title">{item?.jobInfo?.position}</h2>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="draft-card-text">{item?.companyName}</p>
                  <p className="draft-days">{timeAgo(item?.createdAt)}</p>
                </div>
              </div>
            </div>
            <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
            <ul className="draft-list">
              <li>
                {item?.jobInfo?.jobType} · {item?.jobInfo?.workPlaceType}
              </li>
              <li>
                {item?.userId?.companyStrength} employees ·{" "}
                {item?.userId?.industry}
              </li>
              {/* <li>See how you compare to 843 applicants.</li>
              <li>Skills: Wireframing, User Experience (UX), +8 more</li>
              <li>Skills: Wireframing, User Experience (UX), +8 more</li> */}
            </ul>
            {/* <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <h6 className="draft-sub-title">Skills you will gain</h6>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <p className="m-0 draft-skills">Creativity</p>
                <p className="m-0 draft-skills">Art</p>
                <p className="m-0 draft-skills">History</p>
                <p className="m-0 draft-skills">Art History</p>
                <p className="m-0 draft-skills">Art Direction</p>
              </div>
            </div> */}
            <Link
              className="draft-edit-btn text-decoration-none"
              onClick={() => navigate(`/dashboard/table/${item?._id}`)}
              state={{ name: item?.jobInfo?.position }}
            >
              View Applicants
            </Link>
            <button
              className="draft-mange-btn"
              onClick={() =>
                navigate(`/dashboard/all-application/review/${item?._id}`)
              }
            >
              Manage Application
            </button>
          </div>
        ))
      ) : (
        <div className="unsaved-image">
          <img src={savedImg} alt="" />
          <h6 className="unsaved-text m-0">No Closed Applications</h6>
        </div>
      )}
    </>
  );
}

function DraftSlider({ data }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "18%",
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "13%",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "8%",
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "14%",
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "24%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "5%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          dots: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {data.length > 0 &&
          data?.map((item, index) => (
            <div key={index}>
              <DraftCardS item={item} />
            </div>
          ))}
      </Slider>
    </>
  );
}

function AllApplication() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  useEffect(() => {
    const id = localStorage.getItem("recruiteId");
    if (id) {
      getData();
      getClosePostData(id);
    }
  }, []);

  const menuItem = useContext(MobileContext);
  const { handleRecruiterToggle, recruiterMenu } = menuItem;

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [dropdown1, setDropdown1] = useState("Open");
  const [dropdown2, setDropdown2] = useState("Full-time/Part-time");
  const [dropdown3, setDropdown3] = useState("Any Time");
  const [dropdown4, setDropdown4] = useState("Onsite/Remote");
  const [data, setData] = useState([]);
  const [closePost, setClosePost] = useState([]);
  const [loader, setLoader] = useState(false);
  const [inputVal, setInputVal] = useState("");
  // for mobile screens only
  const { allPostByRecruiter, getClosePost } = useMain();

  const getData = async () => {
    setLoader(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await allPostByRecruiter(token);
    if (res?.statusCode === 200) {
      setData(res?.data?.data);
      setLoader(false);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };

  const getClosePostData = async (id) => {
    setLoader(true);
    const res = await getClosePost(id);

    if (res?.statusCode === 200) {
      setClosePost(res?.data);
      setLoader(false);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };
  return (
    <>
      <AlertContainer />
      <RecruiterHeader title="All Applications"  />
      <div className="desktop-show-hide">
        <SubHeader />
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="recruiter-container desktop-show-hide">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
              {/* <div
                className="draft-dropdown"
                onMouseEnter={() => setHover1(true)}
                onMouseLeave={() => setHover1(false)}
              >
                <div className="dropdown-btn">
                  {dropdown1}
                  <img
                    src={hover1 ? downArrowHover : downArrow}
                    style={{ marginLeft: "4px" }}
                    alt=""
                  />
                </div>
                <div className="draft-dropdown-content">
                  {JobType.map((element, index) => {
                    return (
                      <div
                        onClick={(e) => {
                          setDropdown1(e.target.textContent);
                        }}
                        className="item"
                      >
                        {element.option}
                      </div>
                    );
                  })}
                </div>
              </div> */}
              <div
                className="draft-dropdown"
                onMouseEnter={() => setHover2(true)}
                onMouseLeave={() => setHover2(false)}
              >
                <div className="dropdown-btn">
                  {dropdown2}
                  <img
                    src={hover2 ? downArrowHover : downArrow}
                    style={{ marginLeft: "4px" }}
                    alt=""
                  />
                </div>
                <div className="draft-dropdown-content">
                  {JobType.map((element, index) => {
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          setDropdown2(e.target.textContent);
                        }}
                        className="item"
                      >
                        {element.option}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="draft-dropdown"
                onMouseEnter={() => setHover3(true)}
                onMouseLeave={() => setHover3(false)}
              >
                <div className="dropdown-btn">
                  {dropdown3}
                  <img
                    src={hover3 ? downArrowHover : downArrow}
                    style={{ marginLeft: "4px" }}
                    alt=""
                  />
                </div>
                <div className="draft-dropdown-content">
                  {DatePosted.map((element, index) => {
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          setDropdown3(e.target.textContent);
                        }}
                        className="item"
                      >
                        {element.option}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="draft-dropdown"
                onMouseEnter={() => setHover4(true)}
                onMouseLeave={() => setHover4(false)}
              >
                <div className="dropdown-btn">
                  {dropdown4}
                  <img
                    src={hover4 ? downArrowHover : downArrow}
                    style={{ marginLeft: "4px" }}
                    alt=""
                  />
                </div>
                <div className="draft-dropdown-content">
                  {OnsiteRemote.map((element, index) => {
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          setDropdown4(e.target.textContent);
                        }}
                        className="item"
                      >
                        {element.option}
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <div className="draft-dropdown">
                <div
                  // onClick={(e) => {
                  //   setIsActive(!isActive);
                  // }}
                  className="dropdown-btn"
                >
                  All Filter
                </div>
              </div> */}
            </div>
            <div className="draft-search">
              <input
                type="text"
                placeholder="Search"
                value={inputVal}
                onChange={(e) => setInputVal(e.target.value)}
              />
              <img src={search} alt="" />
            </div>
          </div>
          <div className="draft-container">
            <p
              className="m-0"
              style={{
                color: "#808080",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              Open Applications
            </p>
            <div className="draft-boxes">
              <OpenApplication
                inputVal={inputVal}
                dropdown2={dropdown2}
                dropdown3={dropdown3}
                dropdown4={dropdown4}
              />
            </div>
          </div>
          <div className="draft-container">
            <p
              className="m-0"
              style={{
                color: "#808080",
                fontFamily: "Inter",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              Closed Applications
            </p>
            <div className="draft-boxes">
              <ClosePost data={closePost} />
            </div>
          </div>
        </div>
      )}

      {loader ? (
        <Loader />
      ) : (
        <div className="mobile-show-hide">
          <div className="recruiter-container" style={{ marginTop: "86px" }}>
            <div className="d-flex justify-content-between">
              <p
                className="m-0"
                style={{
                  color: "#262626",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "18px",
                }}
              >
                Open Applications
              </p>
              <button className="new-job-add-btn">View All</button>
            </div>
            <div style={{ marginTop: "12px", paddingBottom: "20px" }}>
              {data?.length === 0 ? (
                <div className="unsaved-image">
                  <img src={savedImg} alt="" />
                  <h6 className="unsaved-text m-0">No Open Applications</h6>
                </div>
              ) : (
                <DraftSlider data={data} />
              )}
            </div>
            <div
              className="draft-container"
              style={{ position: "unset", padding: "16px" }}
            >
              <p
                className="m-0"
                style={{
                  color: "#262626",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "18px",
                }}
              >
                Closed Applications
              </p>
              <div style={{ marginTop: "12px", paddingBottom: "20px" }}>
                {closePost?.length === 0 ? (
                  <div className="unsaved-image">
                    <img src={savedImg} alt="" />
                    <h6 className="unsaved-text m-0">No Closed Applications</h6>
                  </div>
                ) : (
                  <DraftSlider data={closePost} />
                )}
              </div>
            </div>
          </div>
          <RecruiterSubMenu />
          <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
            <img
              src={top}
              alt=""
              style={{
                transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 150ms ease",
                height: "14px",
              }}
            />
            <img src={jobIcon} style={{ height: "24px" }} alt="" />
          </div>
        </div>
      )}
    </>
  );
}

export default AllApplication;
