import React, { useState } from "react";
import "../../components/recruiter/recruiter.css";
import Header from "../header/Header";
import Loader from "../loader/Loader";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import CareerHeader from "../subHeader/CareerHeader";
import "../../App.css";
import CareerHead from "../header/CareerHead";

const events = [];
function CareerCalender() {
  const [loader, setLoader] = useState(false);
  return (
    <>
      <CareerHead />
      <div className="desktop-show-hide">
        <CareerHeader />
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="recruiter-container">
          <div className="new-job-container">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              weekends={true}
              events={events}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CareerCalender;
