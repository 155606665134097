import React, { useEffect } from "react";
import job from "../assets/talent/job-seeker.svg";
import jobMobile from "../assets/talent/job-seeker-mobile.svg";
import recruiter from "../assets/talent/recruiter.svg";
import recruiterMobile from "../assets/talent/recruiter-mobile.svg";
import item1 from "../assets/talent/icon1.svg";
import item2 from "../assets/talent/icon2.svg";
import item3 from "../assets/talent/icon3.svg";
import item4 from "../assets/talent/icon4.svg";
import item5 from "../assets/talent/icon5.svg";
import item6 from "../assets/talent/icon6.svg";
import item7 from "../assets/talent/icon7.svg";
import item8 from "../assets/talent/icon8.svg";
import product1 from "../assets/celebration/item-1.svg";
import product2 from "../assets/celebration/item-2.svg";
import product3 from "../assets/celebration/item-3.svg";
import product4 from "../assets/celebration/item-4.svg";
import product5 from "../assets/celebration/item-5.svg";
import product6 from "../assets/celebration/item-6.svg";
import product7 from "../assets/celebration/item-7.svg";
import product8 from "../assets/celebration/item-8.svg";
import product9 from "../assets/celebration/item-9.svg";
import product10 from "../assets/celebration/item-10.svg";
import product11 from "../assets/celebration/item-11.svg";
import product12 from "../assets/celebration/item-12.svg";
import product13 from "../assets/celebration/item-13.svg";
import product14 from "../assets/celebration/item-14.svg";
import product15 from "../assets/celebration/item-15.svg";
import productM1 from "../assets/celebration/item-m1.svg";
import productM2 from "../assets/celebration/item-m2.svg";
import productM3 from "../assets/celebration/item-m3.svg";
import productM4 from "../assets/celebration/item-m4.svg";
import productM5 from "../assets/celebration/item-m5.svg";
import productM6 from "../assets/celebration/item-m6.svg";
import productM7 from "../assets/celebration/item-m7.svg";
import productM8 from "../assets/celebration/item-m8.svg";
import productM9 from "../assets/celebration/item-m9.svg";
import productM10 from "../assets/celebration/item-m10.svg";
import productM11 from "../assets/celebration/item-m11.svg";
import productM12 from "../assets/celebration/item-m12.svg";
import productM13 from "../assets/celebration/item-m13.svg";
import productM14 from "../assets/celebration/item-m14.svg";
import productM15 from "../assets/celebration/item-m15.svg";
import ourLogo1 from "../assets/our/our-1.svg";
import ourLogo2 from "../assets/our/our-2.svg";
import ourLogo3 from "../assets/our/our-3.svg";
import ourLogo4 from "../assets/our/our-4.svg";
import ourLogo5 from "../assets/our/our-5.svg";
import ourLogo6 from "../assets/our/our-6.svg";
import ourLogo7 from "../assets/our/our-7.svg";
import ourLogo8 from "../assets/our/our-8.svg";
import ourLogo9 from "../assets/our/our-9.svg";
import homeDrop from "../assets/home-drop.svg";
import CountUp from "react-countup";
import HealthSlider from "../components/healthSlider/HealthSlider";
import InformationSlider from "../components/informationSlider/InformationSlider";
import StoriesSlider from "../components/storiesSlider/StoriesSlider";
import Footer from "../components/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import HomeHeader from "../components/header/HomeHeader";
import Slider from "react-slick";

function TalentCard({ addMargin, img, title, text1, text2, imgBg, text3 }) {
  return (
    <>
      <div className="talent-card" style={{ marginRight: addMargin }}>
        <img src={img} className={`img-fluid ${imgBg}`} alt="" />
        <h6 className="job-card-title">{title}</h6>
        <ul className="job-detail">
          <li>{text1}</li>
          <li>{text2}</li>
          <li>{text3}</li>
        </ul>
      </div>
    </>
  );
}
function TalentCard1({ img, title, text1, text2, imgBg, text3, addMargin }) {
  return (
    <>
      {/* <div className="col-6 p-0"> */}
      <div className="talent-card" style={{ marginRight: addMargin }}>
        <img src={img} className={`img-fluid ${imgBg}`} alt="" />
        <h6 className="job-card-title">{title}</h6>
        <ul className="job-detail">
          <li>{text1}</li>
          <li>{text2}</li>
          <li>{text3}</li>
        </ul>
      </div>
      {/* </div> */}
    </>
  );
}

function Homepage() {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    arrows: false,
    centerPadding: "18%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "24%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "16%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "11%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    console.log(section)
    if (section) {
      const topPos = section.offsetTop - 90;
      window.scrollTo({ top: topPos, behavior: 'smooth' });
    }
  };
  return (
    <>
      <HomeHeader />
      <section className="mobile-home-hero">
        <div className="container">
          <div className="hero-content text-center">
            <h1 className="hero-title">Recruitment Redefined</h1>
            <p className="hero-text">Unleashing End-to-End Hiring Mastery! </p>
            <div className="hero-btn ">
              <button
                className="hero-left-btn"
                onClick={() => navigate("/recruiter-sign-in")}
              >
                Hire Candidates
              </button>
              <button
                className="hero-right-btn"
                onClick={() => navigate("/sign-in")}
              >
                Get Hired
              </button>
            </div>
          </div>
        </div>
        <div className="text-center">
          <p className="learn-more m-0">
            Learn more about our Work
              <img src={homeDrop} style={{ marginLeft: "8px", cursor: "pointer" }} alt="" onClick={() => scrollToSection("section2")} />
          </p>
        </div>
      </section>
      <div className="container">
      <div className="box"></div>
    </div>
      <section className="talent-section" id="section2">
        <h2 className="talent-title">"Recruitifie: The Talent Matchmakers!"</h2>
        <div className="talent-box">
          <div className="container">
            <div className="mobile-show-hide">
              <div className="jov-box">
                <div className="row">
                  <div className="col-sm-12 col-lg-4 text-center">
                    <div className="mobile-bg">
                      <img
                        src={jobMobile}
                        alt=""
                        className="img-fluid job-img"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-8">
                    <div className="row mt-3 mb-4">
                      <TalentCard
                        title="Application Tracking"
                        img={item1}
                        addMargin={"7px"}
                        imgBg="bg-yellow"
                        text1="Stay updated, organized, succeed."
                        text2="Real-time updates, centralized management."
                        text3="Maximize dream job chances."
                      />
                      <TalentCard
                        title="Up Skill"
                        img={item2}
                        imgBg="bg-yellow"
                        text1="Streamlined ATS: Simplify application management."
                        text2="Centralize and organize candidate profiles."
                        text3="Securely review and filter candidates."
                      />
                      <TalentCard
                        title="Career Counselling"
                        img={item3}
                        addMargin={"7px"}
                        imgBg="bg-normal"
                        text1="Advanced matching: Comprehensive candidate profiles."
                        text2="Machine learning, AI: Analyzing skills."
                        text3="Holistic understanding: Candidate capabilities."
                      />
                      <TalentCard
                        title="ATS- Friendly Resume Building"
                        img={item4}
                        imgBg="bg-normal"
                        text1="Customized hiring process: Recruitifie`s Automation."
                        text2="Define stages, automate notifications."
                        text3="Seamless workflow, efficient evaluations."
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="recruiter-box">
                <div className="row">
                  <div
                    className="col-sm-12 col-lg-4 text-center"
                  >
                    <div className="mobile-bg">
                      <img
                        src={recruiterMobile}
                        alt=""
                        className="img-fluid recruiter-img"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-8">
                    <div className="row">
                      <TalentCard1
                        title="Hiring Analysis"
                        img={item5}
                        addMargin={"7px"}
                        imgBg="bg-blue"
                        text1="Powerful analytics, valuable insights: Recruitifie."
                        text2="Optimize hiring process with data."
                        text3="Track metrics, make informed decisions. "
                      />
                      <TalentCard1
                        title="Inbuilt ATS"
                        img={item6}
                        imgBg="bg-blue"
                        text1="Streamlined ATS: Simplify application management."
                        text2="Centralize and organize candidate profiles."
                        text3="Securely review and filter candidates."
                      />
                      <TalentCard1
                        title="Advance Matching Algorithim"
                        img={item7}
                        imgBg="bg-blue"
                        addMargin={"7px"}
                        text1="Advanced matching: Comprehensive candidate profiles."
                        text2="Machine learning, AI: Analyzing skills."
                        text3="Holistic understanding: Candidate capabilities."
                      />
                      <TalentCard1
                        title="Work Flow Automation"
                        img={item8}
                        imgBg="bg-blue"
                        text1="Customized hiring process: Recruitifie`s Automation."
                        text2="Define stages, automate notifications."
                        text3="Seamless workflow, efficient evaluations."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="desktop-show-hide">
              <div className="talent-container">
                <div className="jov-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-sm-12 col-lg-4">
                      <img src={job} alt="" className="img-fluid job-img" />
                    </div>
                    <div className="col-sm-12 col-lg-8">
                      <div className="d-flex ms-4">
                        <TalentCard
                          title="Application Tracking"
                          img={item1}
                          imgBg="bg-yellow"
                          text1="Stay updated, organized, succeed."
                          text2="Real-time updates, centralized management."
                          text3="Maximize dream job chances."
                        />
                        <TalentCard
                          title="Up Skill"
                          img={item2}
                          imgBg="bg-yellow"
                          text1="Streamlined ATS: Simplify application management."
                          text2="Centralize and organize candidate profiles."
                          text3="Securely review and filter candidates."
                        />
                      </div>
                      <div className="d-flex ms-4">
                        <TalentCard
                          title="Career Counselling"
                          img={item3}
                          imgBg="bg-normal"
                          text1="Advanced matching: Comprehensive candidate profiles."
                          text2="Machine learning, AI: Analyzing skills."
                          text3="Holistic understanding: Candidate capabilities."
                        />
                        <TalentCard
                          title="ATS- Friendly Resume Building"
                          img={item4}
                          imgBg="bg-normal"
                          text1="Customized hiring process: Recruitifie`s Automation."
                          text2="Define stages, automate notifications."
                          text3="Seamless workflow, efficient evaluations."
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="recruiter-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-sm-12 col-lg-8">
                      <div className="d-flex">
                        <TalentCard1
                          title="Hiring Analysis"
                          img={item5}
                          imgBg="bg-blue"
                          text1="Powerful analytics, valuable insights: Recruitifie."
                          text2="Optimize hiring process with data."
                          text3="Track metrics, make informed decisions. "
                        />
                        <TalentCard1
                          title="Inbuilt ATS"
                          img={item6}
                          imgBg="bg-blue"
                          text1="Streamlined ATS: Simplify application management."
                          text2="Centralize and organize candidate profiles."
                          text3="Securely review and filter candidates."
                        />
                      </div>
                      <div className="d-flex">
                        <TalentCard1
                          title="Advance Matching Algorithim"
                          img={item7}
                          imgBg="bg-blue"
                          text1="Advanced matching: Comprehensive candidate profiles."
                          text2="Machine learning, AI: Analyzing skills."
                          text3="Holistic understanding: Candidate capabilities."
                        />
                        <TalentCard1
                          title="Work Flow Automation"
                          img={item8}
                          imgBg="bg-blue"
                          text1="Customized hiring process: Recruitifie`s Automation."
                          text2="Define stages, automate notifications."
                          text3="Seamless workflow, efficient evaluations."
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 d-flex justify-content-end">
                      <img src={recruiter} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="count-section">
        <div className="count-box">
          <div className="mobile-show-hide">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div
                  style={{ marginBottom: "32px" }}
                  className="d-flex flex-column align-items-center"
                >
                  <h6 className="count-number m-0">
                    <CountUp end={1} />K +
                  </h6>
                  <p className="count-text m-0">Companies Hiring</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div
                  style={{ marginBottom: "32px" }}
                  className="d-flex flex-column align-items-center"
                >
                  <h6 className="count-number m-0">
                    <CountUp end={25} />K +
                  </h6>
                  <p className="count-text m-0">Active Job Seekers</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div
                  style={{ marginBottom: "32px" }}
                  className="d-flex flex-column align-items-center"
                >
                  <h6 className="count-number m-0">
                    <CountUp end={5} />K +
                  </h6>
                  <p className="count-text m-0">New Openings Everyday</p>
                </div>
              </div>
            </div>
          </div>
          <div className="desktop-show-hide">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div
                  style={{ marginBottom: "0px" }}
                  className="d-flex flex-column align-items-center"
                >
                  <h6 className="count-number m-0">
                    <CountUp end={1} />K +
                  </h6>
                  <p className="count-text m-0">Companies Hiring</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div
                  style={{ marginBottom: "0px" }}
                  className="d-flex flex-column align-items-center"
                >
                  <h6 className="count-number m-0">
                    <CountUp end={25} />K +
                  </h6>
                  <p className="count-text m-0">Active Job Seekers</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div
                  style={{ marginBottom: "0px" }}
                  className="d-flex flex-column align-items-center"
                >
                  <h6 className="count-number m-0">
                    <CountUp end={5} />K +
                  </h6>
                  <p className="count-text m-0">New Openings Everyday</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-about-section">
        <div className="mobile-show-hide">
          <h6 className="our-title">Our Partners</h6>
          <Slider {...settings}>
            <div>
              <div className="hiring-box">
                <h6 className="m-0 hiring-title">HIring Partners</h6>
                <div
                  className="d-flex"
                  style={{ gap: "8px", flexWrap: "wrap" }}
                >
                  <img src={ourLogo1} className="our-mobile-logos" alt="" />
                  <img src={ourLogo2} className="our-mobile-logos" alt="" />
                  <img src={ourLogo3} className="our-mobile-logos" alt="" />
                  <img src={ourLogo8} className="our-mobile-logos" alt="" />
                  <img src={ourLogo4} className="our-mobile-logos" alt="" />
                  <img src={ourLogo7} className="our-mobile-logos" alt="" />
                </div>
              </div>
            </div>
            <div>
              <div className="know-box">
                <h6 className="m-0 know-title">KNowledge Partners</h6>
                <div
                  className="d-flex"
                  style={{ gap: "8px", flexWrap: "wrap" }}
                >
                  <img src={ourLogo6} className="our-mobile-logos" alt="" />
                  <img src={ourLogo4} className="our-mobile-logos" alt="" />
                  {/* <img
                    src={ourLogo9}
                    alt=""
                  /> */}
                </div>
              </div>
            </div>
            <div>
              <div className="tech-box">
                <h6 className="m-0 tech-title">Tech Partners</h6>
                <div
                  className="d-flex"
                  style={{ gap: "8px", flexWrap: "wrap" }}
                >
                  <img src={ourLogo5} className="our-mobile-logos" alt="" />
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="desktop-show-hide">
          <h6 className="our-title">Our Partners</h6>
          <div className="our-about-box">
            <div className="hiring-box">
              <h6 className="m-0 hiring-title">HIring Partners</h6>
              <div className="d-flex" style={{ gap: "16px", flexWrap: "wrap" }}>
                <img src={ourLogo4} className="our-mobile-logos" alt="" />
                <img src={ourLogo2} className="our-mobile-logos" alt="" />
                <img src={ourLogo3} className="our-mobile-logos" alt="" />
                <img src={ourLogo8} className="our-mobile-logos" alt="" />
                <img src={ourLogo4} className="our-mobile-logos" alt="" />
                <img src={ourLogo7} className="our-mobile-logos" alt="" />
              </div>
            </div>
            <div className="tech-box">
              <h6 className="m-0 tech-title">KNowledge Partners</h6>
              <div className="d-flex" style={{ gap: "16px", flexWrap: "wrap" }}>
                <img src={ourLogo6} className="our-mobile-logos" alt="" />
                <img src={ourLogo4} className="our-mobile-logos" alt="" />
                {/* <img src={ourLogo9} alt="" /> */}
              </div>
            </div>
            <div className="tech-box">
              <h6 className="m-0 tech-title">Tech Partners</h6>
              <div className="d-flex" style={{ gap: "16px", flexWrap: "wrap" }}>
                <img src={ourLogo5} className="our-mobile-logos" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="courses-section">
        <div className="courses-box">
          <div className="mobile-course-body w-100">
            <h2 className="courses-title">Courses for you</h2>
            <p className="m-0 courses-text">
              Elevate your skills with our skill enhancement courses and take
              charge of your professional advancement.
            </p>
          </div>
          <InformationSlider />
          <div className="text-center">
            <button
              className="view-more mt-5"
              onClick={() => navigate("/community")}
            >
              View All
            </button>
          </div>
          {/* <div className="information-slider">
            <HealthSlider />
          </div> */}
        </div>
      </section>
      <section className="celebration-section">
        <div className="container">
          <h2 className="celebration-title">Celebrating Excellence</h2>
          <p className="celebration-text m-0">Our Awards and Recognition</p>
          <div className="mobile-show-hide">
            <div className="celebration-box ">
              <Grid container justifyContent={"center"} columnGap={"16px"}>
                <Grid item xs={3}>
                  <img src={productM1} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM2} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM3} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM4} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM5} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM6} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM7} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM8} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM9} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM10} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM11} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM12} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM13} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM14} alt="" />
                </Grid>
                <Grid item xs={3}>
                  <img src={productM15} alt="" />
                </Grid>
              </Grid>
              {/* <div className="row">
                <div className="col d-flex justify-content-center">
                  <img src={product1} alt="" />
                </div>
                <div className="col d-flex justify-content-center">
                  <img src={product2} alt="" />
                </div>
                <div className="col d-flex justify-content-center">
                  <img src={product3} alt="" />
                </div>
              </div> */}
            </div>
            {/* <div className="celebration-box ">
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <img src={product4} alt="" />
                </div>
                <div className="col d-flex justify-content-center">
                  <img src={product5} alt="" />
                </div>
                <div className="col d-flex justify-content-center">
                  <img src={product6} alt="" />
                </div>
              </div>
            </div>
            <div className="celebration-box ">
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <img src={product7} alt="" />
                </div>
                <div className="col d-flex justify-content-center">
                  <img src={product8} alt="" />
                </div>
                <div className="col d-flex justify-content-center">
                  <img src={product9} alt="" />
                </div>
              </div>
            </div> */}
            {/* <div className="celebration-box ">
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <img src={product10} alt="" />
                </div>
                <div className="col d-flex justify-content-center">
                  <img src={product11} alt="" />
                </div>
                <div className="col d-flex justify-content-center">
                  <img src={product12} alt="" />
                </div>
              </div>
            </div>
            <div className="celebration-box ">
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <img src={product13} alt="" />
                </div>
                <div className="col d-flex justify-content-center">
                  <img src={product14} alt="" />
                </div>
                <div className="col d-flex justify-content-center">
                  <img src={product15} alt="" />
                </div>
              </div>
            </div> */}
          </div>
          {/* Desktop View */}
          <div className="desktop-show-hide">
            <div className="celebration-box ">
              <Grid
                container
                justifyContent={"center"}
                columnGap={"50px"}
                columns={{ md: 12 }}
              >
                <Grid item lg={2}>
                  <img src={product1} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product2} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product3} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product4} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product5} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product6} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product7} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product8} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product9} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product10} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product11} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product12} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product13} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product14} alt="" />
                </Grid>
                <Grid item lg={2}>
                  <img src={product15} alt="" />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
      <section className="stories-section">
        <h2 className="stories-title">Success Stories</h2>
        <StoriesSlider />
      </section>
      <Footer />
    </>
  );
}

export default Homepage;
