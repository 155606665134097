import React, { useContext } from "react";
import "./recruiterMenu.css";
import MobileContext from "../../context/mobile/MobileContext";
import { Link, useLocation } from "react-router-dom";
import hoverNotification from "../../assets/mobile/notification.svg";
import notification from "../../assets/notifications.svg";
// import hoverCompany from "../../assets/sideBar/light-company.svg";
//////////////////////////////////////////////////////////////////////////////
import dashboard from "../../assets/recruiter-subHeader/dashboard.svg";
import post from "../../assets/recruiter-subHeader/add.svg";
import cal from "../../assets/recruiter-subHeader/cal.svg";
import inn from "../../assets/recruiter-subHeader/inn.svg";
import app from "../../assets/recruiter-subHeader/app.svg";
import Auto from "../../assets/recruiter-subHeader/auto.svg";
import Automate from "../../assets/recruiter-subHeader/automate.svg";
import ActiveDashboard from "../../assets/recruiter-subHeader/light-dashboard.svg";
import ActiveAdd from "../../assets/recruiter-subHeader/light-add.svg";
import ActiveAll from "../../assets/recruiter-subHeader/light-all.svg";
import ActiveInterview from "../../assets/recruiter-subHeader/light-interview.svg";

function RecruiterSubMenu() {
  const menuItem = useContext(MobileContext);
  const { recruiterMenu, handleRecruiterToggle } = menuItem;
  let path = useLocation();
  let pathName = path.pathname;

  return (
    <>
      <div
        className="mobile-recruiter-menu-box"
        style={{
          height: recruiterMenu ? "0px" : "auto",
          overflow: recruiterMenu ? "hidden" : "visible",
          padding: recruiterMenu ? "0" : "20px 22px 0 22px",
        }}
      >
        <div className="mobile-recruiter-menu">
          <div
            className={`${
              path.pathname === "/members"
                ? "active-header"
                : "mobile-menu-item"
            }`}
            onClick={handleRecruiterToggle}
          >
            <img src={notification} className="hide-on-hover" alt="" />
            <img src={hoverNotification} className="show-on-hover" alt="" />
            <Link>Notifications</Link>
          </div>
          <div className="menu-border"></div>
          <div
            className={
              pathName === "/recruiter-dashboard"
                ? "active-header"
                : "mobile-menu-item"
            }
            onClick={handleRecruiterToggle}
          >
            <img src={ActiveDashboard} className="hide-on-hover" alt="" />
            <img src={dashboard} className="show-on-hover" alt="" />
            <Link to="/recruiter-dashboard">Dashboard</Link>
          </div>
          <div
            className={
              pathName === "/dashboard/new-job"
                ? "active-header"
                : "mobile-menu-item"
            }
            onClick={handleRecruiterToggle}
          >
            <img src={ActiveAdd} className="hide-on-hover" alt="" />
            <img src={post} className="show-on-hover" alt="" />
            <Link to="/dashboard/new-job"> Post Job</Link>
          </div>
          <div className="menu-border"></div>
          <div
            className={
              pathName === "/dashboard/all-application"
                ? "active-header"
                : "mobile-menu-item"
            }
            onClick={handleRecruiterToggle}
          >
            <img src={app} className="hide-on-hover" alt="" />
            <img src={ActiveAll} className="show-on-hover" alt="" />
            <Link to="/dashboard/all-application"> Applications</Link>
          </div>
          <div
            className={
              pathName === "/dashboard/all-interviews"
                ? "active-header"
                : "mobile-menu-item"
            }
            onClick={handleRecruiterToggle}
          >
            <img src={inn} className="hide-on-hover" alt="" />
            <img src={ActiveInterview} className="show-on-hover" alt="" />
            <Link to="/dashboard/all-interviews"> Interviewers</Link>
          </div>
          <div
            className={
              pathName === "/dashboard/all-sdasd"
                ? "active-header"
                : "mobile-menu-item"
            }
            onClick={handleRecruiterToggle}
          >
            <img src={Automate} className="hide-on-hover" alt="" />
            <img src={Automate} className="show-on-hover" alt="" />
            <Link>Automate</Link>
          </div>
          <div
            className={
              pathName === "/dashboard/all-sasdasdasd"
                ? "active-header"
                : "mobile-menu-item"
            }
            onClick={handleRecruiterToggle}
          >
            <img src={cal} className="hide-on-hover" alt="" />
            <img src={cal} className="show-on-hover" alt="" />
            <Link>Calender</Link>
          </div>
          <div
            className={
              pathName === "/dashboard/recruiter/chat"
                ? "active-header"
                : "mobile-menu-item"
            }
            onClick={handleRecruiterToggle}
          >
            <img src={cal} className="hide-on-hover" alt="" />
            <img src={cal} className="show-on-hover" alt="" />
            <Link to="/dashboard/recruiter/chat">Chat</Link>
          </div>
        </div>
        <div className="mobile-recruiter-menu-wrapper"></div>
      </div>
    </>
  );
}

export default RecruiterSubMenu;
