import React, { useEffect, useRef, useState } from "react";
import info from "../assets/information.svg";
import plush from "../assets/plush.svg";
import Delete from "../assets/delete.svg";
import "../App.css";
import "./css/dropdown.css";
import { NavLink, useNavigate } from "react-router-dom";
import number1 from "../assets/mobile/number1.svg";
import number2 from "../assets/mobile/number2.svg";
import number3 from "../assets/mobile/number3.svg";
import number4 from "../assets/mobile/number4.svg";
import downArrow from "../assets/mobile/down-arrow.svg";
import downArrowHover from "../assets/mobile/down-arrow-hover.svg";
import FormHeader from "../components/header/FormHeader";
import CalenderImg from "../assets/calender/calender.svg";
import uploadBtn from "../assets/calender/upload.svg";
import { State } from "country-state-city";
import Calendar from "react-calendar";
import closeBtn from "../assets/close-bt.svg";
import moment from "moment/moment";
import { useMain } from "../hook/useMain";
import Loader from "../components/loader/Loader";
import SearchBar from "./SearchBar";
import CitySearch from "./CitySearch";
import { cityList } from "../context/searchList/cityList";
import useOutsideClick from "./outsideClick/useOutsideClick";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
const selectItem = [
  {
    id: 1,
    option: "Employed",
    selected: false,
  },
  {
    id: 2,
    option: "Serving Notice Period",
    selected: false,
  },
  {
    id: 3,
    option: "Unemployed",
    selected: false,
  },
  {
    id: 4,
    option: "Working Part-time",
    selected: false,
  },
  {
    id: 5,
    option: "Student",
    selected: false,
  },
];
const selectItemTwo = [
  {
    id: 1,
    option: "Married",
    selected: false,
  },
  {
    id: 2,
    option: "Single",
    selected: false,
  },
  {
    id: 3,
    option: "Divorced",
    selected: false,
  },
];
const selectItemThree = [
  {
    id: 1,
    option: "HSC",
    selected: false,
  },
  {
    id: 2,
    option: "SSC",
    selected: false,
  },
  {
    id: 3,
    option: "Bachelors",
    selected: false,
  },
  {
    id: 4,
    option: "Masters",
    selected: false,
  },
  {
    id: 5,
    option: "Post Graduate",
    selected: false,
  },
  {
    id: 6,
    option: "PGDM",
    selected: false,
  },
];
const cgp = [
  {
    id: 1,
    option: "CGPA",
    selected: false,
  },
  {
    id: 2,
    option: "Percentage",
    selected: false,
  },
];
const Modes = [
  {
    id: 1,
    option: "Online",
    selected: false,
  },
  {
    id: 2,
    option: "Offline",
    selected: false,
  },
];

function JobEdit() {
  const imageRef = useRef();
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const imageRef3 = useRef();
  const imageRef4 = useRef();
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const [dropdown5, setDropdown5] = useState(false);
  const [dropdown6, setDropdown6] = useState(false);
  const [dropdown7, setDropdown7] = useState(false);
  const [dropdown8, setDropdown8] = useState(false);
  const [dropdown9, setDropdown9] = useState(false);
  const [mobile1, setMobile1] = useState(true);
  const [mobile2, setMobile2] = useState(true);
  const [mobile3, setMobile3] = useState(true);
  const [mobile4, setMobile4] = useState(true);
  const [mobile5, setMobile5] = useState(true);
  const [mobile6, setMobile6] = useState(true);
  const [mobile7, setMobile7] = useState(true);
  const [dropHover1, setDropHover1] = useState(false);
  const [dropHover2, setDropHover2] = useState(false);
  const [dropHover3, setDropHover3] = useState(false);
  const [dropHover4, setDropHover4] = useState(false);
  const [tooltip1, setTooltip1] = useState(false);
  const [tooltip2, setTooltip2] = useState(false);
  const ref1 = useOutsideClick(handleOutsideClick1);
  const ref2 = useOutsideClick(handleOutsideClick2);
  const ref3 = useOutsideClick(handleOutsideClick3);
  const ref4 = useOutsideClick(handleOutsideClick4);
  const ref5 = useOutsideClick(handleOutsideClick5);
  const ref6 = useOutsideClick(handleOutsideClick6);
  const InitialValues = {
    image1: null,
    image2: null,
  };

  const [formData, setFormData] = useState(InitialValues);
  const handleImage = (e, img) => {
    setFormData({ ...formData, [img]: e.target?.files[0] });
  };

  function handleOnClick1(event) {
    event.preventDefault();
    if (dropdown1 === false) {
      setDropdown1(true);
    }
  }
  function handleOnClick2(event) {
    event.preventDefault();
    if (dropdown2 === false) {
      setDropdown2(true);
    }
  }
  function handleOnClick3(event) {
    event.preventDefault();
    if (dropdown5 === false) {
      setDropdown5(true);
    }
  }
  function handleOnClick4(event) {
    event.preventDefault();
    if (dropdown6 === false) {
      setDropdown6(true);
    }
  }
  function handleOnClick4(event) {
    event.preventDefault();
    if (dropdown6 === false) {
      setDropdown6(true);
    }
  }
  function handleOnClick5(event) {
    event.preventDefault();
    if (dropdown7 === false) {
      setDropdown7(true);
    }
  }
  function handleOnClick6(event) {
    event.preventDefault();
    if (dropdown9 === false) {
      setDropdown9(true);
    }
  }

  function handleOutsideClick1() {
    setDropdown1(false);
  }
  function handleOutsideClick2() {
    setDropdown2(false);
  }
  function handleOutsideClick3() {
    setDropdown5(false);
  }
  function handleOutsideClick4() {
    setDropdown6(false);
  }
  function handleOutsideClick5() {
    setDropdown7(false);
  }
  function handleOutsideClick6() {
    setDropdown9(false);
  }

  const [dateBirth, setDateBirth] = useState("");
  const [dateModal, setDateModal] = useState(false);
  function handleDateOpen() {
    setDateModal(!dateModal);
  }

  const [startModal, setStartModal] = useState(false);
  function handleStartOpen(index) {
    setStartModal(index);
  }

  const [passingModal, setPassingModal] = useState(false);
  function handlePassingOpen(index) {
    setPassingModal(index);
  }

  const [receivedDateModal, setreceivedDateModal] = useState(false);
  function handlereceivedDateOpen(index) {
    setreceivedDateModal(index);
  }

  const [receivedModal, setReceivedModal] = useState(false);
  function handleReceivedOpen(index) {
    setReceivedModal(index);
  }

  const [over, setOver] = useState(false);
  const id = localStorage.getItem("userId");
  const allState = State.getStatesOfCountry("IN", "TG").map((city) => ({
    value: city.name,
    displayValue: city.name,
  }));

  // Search State Start
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  function Dropdown1() {
    setMobile1(!mobile1);
    setMobile2(false);
    setMobile3(false);
    setMobile4(false);
    setMobile5(false);
    setMobile6(false);
    setMobile7(false);
  }
  function Dropdown2() {
    setMobile1(false);
    setMobile2(!mobile2);
    setMobile3(false);
    setMobile4(false);
    setMobile5(false);
    setMobile6(false);
    setMobile7(false);
  }
  function Dropdown3() {
    setMobile1(false);
    setMobile2(false);
    setMobile3(!mobile3);
    setMobile4(false);
    setMobile5(false);
    setMobile6(false);
    setMobile7(false);
  }
  function Dropdown4() {
    setMobile1(false);
    setMobile2(false);
    setMobile3(false);
    setMobile4(!mobile4);
    setMobile5(false);
    setMobile6(false);
    setMobile7(false);
  }
  function Dropdown5() {
    setMobile1(false);
    setMobile2(false);
    setMobile3(false);
    setMobile4(false);
    setMobile5(!mobile5);
    setMobile6(false);
    setMobile7(false);
  }
  function Dropdown6() {
    setMobile1(false);
    setMobile2(false);
    setMobile3(false);
    setMobile4(false);
    setMobile5(false);
    setMobile6(!mobile6);
    setMobile7(false);
  }
  function Dropdown7() {
    setMobile1(false);
    setMobile2(false);
    setMobile3(false);
    setMobile4(false);
    setMobile5(false);
    setMobile6(false);
    setMobile7(!mobile7);
  }

  const [working, setWorking] = useState(false);
  const [value, setValue] = useState("Select state");
  const [city, setCity] = useState("");

  const userData = JSON.parse(localStorage.getItem("userData"));

  function DeleteCertificates(id) {
    const certificatesList = Object.assign([], certificate);
    let filteredArray = certificatesList.filter((item, index) => index !== id);
    setCerttificate(filteredArray);
  }

  const { CreateResumeOnce, UpdateResume } = useMain();
  const [checked, setChecked] = useState(false);
  const [fromModal, setFromModal] = useState(false);
  function handleFromOpen() {
    setFromModal(!fromModal);
  }
  const [toModal, setToModal] = useState(false);
  function handleToOpen() {
    setToModal(!toModal);
  }

  const [cert1, setCert1] = useState(null);
  const [cert2, setCert2] = useState(null);
  const [cert3, setCert3] = useState(null);
  const [cert4, setCert4] = useState(null);
  const [cert5, setCert5] = useState(null);
  const cart = [cert1, cert2, cert3, cert4, cert5];
  const handleDivClick = () => {
    imageRef.current.click();
  };

  const updateFiles = (e, index) => {
    if (index === 0) {
      setCert1(e.target.files[0]);
    } else if (index === 1) {
      setCert2(e.target.files[0]);
    } else if (index === 2) {
      setCert3(e.target.files[0]);
    } else if (index === 3) {
      setCert4(e.target.files[0]);
    } else {
      setCert5(e.target.files[0]);
    }
  };

  const [avd1, setAvd1] = useState(null);
  const [avd2, setAvd2] = useState(null);
  const [avd3, setAvd3] = useState(null);
  const [avd4, setAvd4] = useState(null);
  const [avd5, setAvd5] = useState(null);
  const averd = [avd1, avd2, avd3, avd4, avd5];

  const handleAweClick = () => {
    imageRef4.current.click();
  };

  const updateAwe = (e, index) => {
    if (index === 0) {
      setAvd1(e.target.files[0]);
    } else if (index === 1) {
      setAvd2(e.target.files[0]);
    } else if (index === 2) {
      setAvd3(e.target.files[0]);
    } else if (index === 3) {
      setAvd4(e.target.files[0]);
    } else {
      setAvd5(e.target.files[0]);
    }
  };
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);
  const [img3, setImg3] = useState(null);
  const [img4, setImg4] = useState(null);
  const [img5, setImg5] = useState(null);
  const images = [img1, img2, img3, img4, img5];
  const handleImgClick = () => {
    imageRef3.current.click();
  };

  const updateImg = (e, index) => {
    if (index === 0) {
      setImg1(e.target.files[0]);
    } else if (index === 1) {
      setImg2(e.target.files[0]);
    } else if (index === 2) {
      setImg3(e.target.files[0]);
    } else if (index === 3) {
      setImg4(e.target.files[0]);
    } else {
      setImg5(e.target.files[0]);
    }
  };

  useEffect(() => {
    setAcademicData((pre) => {
      return {
        ...pre,
        name: userData?.name,
        email: userData?.email,
        phone: userData?.phone,
      };
    });
  }, []);

  const [academicData, setAcademicData] = useState({
    name: "",
    phone: "",
    email: "",
    dob: "",
    city: city,
    state: value,
    pincode: "",
    workingStatus: "Working Status",
    maritalStatus: "Marital Status",
  });

  const [academic2, setAcademic2] = useState([
    {
      instituteName: "",
      degreeName: "",
      degreeType: "Degree Type",
      percentage: "",
      percentageType: "CGPA",
      startYear: "",
      endYear: "",
      highlight: "",
    },
  ]);
  const [jobAchievement, setJobAchievement] = useState([
    {
      certificateName: "",
      issueDate: "",
    },
  ]);

  const [experience, setExperience] = useState([
    {
      companyName: "",
      companyType: "Company Type",
      role: "",
      designation: "",
      from: "",
      to: "",
      describeRole: "",
      jobHighlight: "",
    },
  ]);

  function AddExperience() {
    setExperience([
      ...experience,
      {
        companyName: "",
        companyType: "Company Type",
        role: "",
        designation: "",
        from: "",
        to: "",
        describeRole: "",
        jobHighlight: "",
      },
    ]);
  }
  function AddjobAchievement() {
    if (jobAchievement?.length < 5) {
      setJobAchievement([
        ...jobAchievement,
        {
          certificateName: "",
          issueDate: "",
        },
      ]);
    }
  }
  function DeleteExperience(id) {
    let filteredArray = experience.filter((item, index) => index !== id);
    setExperience(filteredArray);
  }
  function DeletejobAchievement(id) {
    let filteredArray = jobAchievement.filter((item, index) => index !== id);
    setJobAchievement(filteredArray);
  }

  const [certificate, setCerttificate] = useState([
    {
      certificateName: "",
      certificateLink: "",
      issueDate: "",
    },
  ]);
  const [certificate1, setCertificate] = useState([
    {
      mode: "Mode",
      instituteName: "",
      CourseName: "",
      certificateWebLink: "",
      receivedDate: "",
      keyLearning: "",
    },
  ]);
  function AddCertificate1() {
    if (certificate1?.length < 3) {
      setCertificate([
        ...certificate1,
        {
          mode: "Mode",
          instituteName: "",
          CourseName: "",
          certificateWebLink: "",
          receivedDate: "",
          keyLearning: "",
        },
      ]);
    }
  }
  function DeleteCertificate1(id) {
    let filteredArray = certificate1.filter((item, index) => index !== id);
    setCertificate(filteredArray);
  }

  const handleInputChange = (event, index, property) => {
    const updatedData2 = [...academic2];
    updatedData2[index][property] = event.target.value;
    setAcademicData(updatedData2);
  };

  function DeleteEducation(id) {
    const educationList = Object.assign([], academic2);
    let filteredArray = educationList.filter((item, index) => index !== id);
    setAcademic2(filteredArray);
  }
  async function handleOnSave() {
    console.log(academicData);
    // setLoader(true);
    // const result = await CreateResumeOnce(
    //   { ...academicData, city, state: value },
    //   academic2,
    //   certificate,
    //   cert1,
    //   cert2,
    //   cert3,
    //   cert4,
    //   cert5,
    //   id
    // );
    // if (result?.statusCode === 200) {
    //   alert(result?.message);
    //   setLoader(false);
    //   localStorage.setItem("userData", JSON.stringify(result?.data));
    //   navigate("/build-resume");
    // } else {
    //   alert("Somthing went wrong");
    //   setLoader(false);
    // }
  }
  return (
    <>
      <AlertContainer/>
      <FormHeader title="Build Profile" />
      {loader ? (
        <Loader />
      ) : (
        <section className="create-resume">
          <div className="mobile-show-hide">
            <div className="mobile-line-box">
              <div className="mobile-line"></div>
              <div className="d-flex justify-content-around number-line">
                <img src={number1} alt="" />
                <img src={number2} alt="" />
                <img src={number3} alt="" />
                <img src={number4} alt="" />
              </div>
            </div>
            <div className="build-box-mobile">
              <h6 className="build-title">Edit your profile</h6>
              <p className="build-text">
                With a well-crafted professional profile, you'll showcase your
                expertise and attract the attention of potential employers. Good
                luck!
              </p>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown1}
              >
                <p className="personal-title m-0">Personal Details</p>
                <img src={downArrow} alt="" />
              </div>
              <div
                className="resume-box"
                style={
                  mobile1 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-xl-4">
                    <input
                      type="text"
                      placeholder="Full name"
                      className="resume-input"
                      name="name"
                      value={academicData?.name}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-xl-4">
                    <input
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      name="phone"
                      value={academicData?.phone}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                      placeholder="phoneNumber number"
                      className="resume-input"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-xl-4">
                    {/* email */}
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      className="resume-input"
                      value={academicData?.email}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-xl-4">
                    <div
                      className="calender-date-input"
                      onClick={handleDateOpen}
                    >
                      <input
                        placeholder="Enter DOB"
                        className="input-date"
                        type="text"
                        value={academicData?.dob}
                        name="dob"
                      />
                      <img
                        src={CalenderImg}
                        className="input-calender"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-xl-4">
                    <CitySearch
                      options={cityList}
                      label="name"
                      selectedVal={city}
                      handleChange={(val) => setCity(val)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-xl-4">
                    <SearchBar
                      options={allState}
                      label="displayValue"
                      selectedVal={value}
                      handleChange={(val) => {
                        setValue(val);
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-xl-4">
                    <div
                      ref={ref3}
                      className="dropdown"
                      onMouseEnter={() => setDropHover2(true)}
                      onMouseLeave={() => setDropHover2(false)}
                    >
                      <div
                        onClick={handleOnClick3}
                        style={{
                          outline: dropdown5 ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData.workingStatus}
                        <img
                          src={dropdown5 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: dropdown5 ? "block" : "none" }}
                      >
                        {selectItem.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["workingStatus"]: e.target.textContent,
                                });
                                setDropdown5(false);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-xl-4">
                    <div
                      ref={ref4}
                      className="dropdown"
                      onMouseEnter={() => setDropHover3(true)}
                      onMouseLeave={() => setDropHover3(false)}
                    >
                      <div
                        onClick={handleOnClick4}
                        style={{
                          outline: dropdown6 ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData?.maritalStatus}
                        <img
                          src={dropdown6 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: dropdown6 ? "block" : "none" }}
                      >
                        {selectItemTwo.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["maritalStatus"]: e.target.textContent,
                                });
                                setDropdown6(false);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown2}
              >
                <p className="personal-title m-0">Academic Details</p>
                <img src={downArrow} alt="" />
              </div>
              <div
                className="resume-box"
                style={
                  mobile2 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                {academic2.map((element, index) => {
                  return (
                    <>
                      <div className="row" key={index}>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <input
                            type="text"
                            placeholder="Institute/University name"
                            className="resume-input"
                            value={academic2[index]?.instituteName}
                            onChange={(e) => {
                              const updatedData2 = [...academic2];
                              updatedData2[index]["instituteName"] =
                                e.target.value;
                              setAcademic2(updatedData2);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div>
                            {dropdown3 === index ? (
                              <div
                                className="select-overlay"
                                onClick={() => setDropdown3(false)}
                              ></div>
                            ) : (
                              ""
                            )}
                            <div
                              className="dropdown"
                              onMouseEnter={() => setDropHover4(true)}
                              onMouseLeave={() => setDropHover4(false)}
                              style={{
                                zIndex: dropdown3 === index ? "1112" : "",
                              }}
                            >
                              <div
                                onClick={(e) => setDropdown3(index)}
                                style={{
                                  outline: dropdown3
                                    ? "2px solid #94A3B8"
                                    : "none",
                                }}
                                className="dropdown-btn"
                              >
                                {academic2[index]?.degreeType}
                                <img
                                  src={dropHover4 ? downArrowHover : downArrow}
                                  alt=""
                                />
                              </div>
                              <div
                                className="dropdown-content"
                                style={{
                                  display:
                                    index === dropdown3 ? "block" : "none",
                                }}
                              >
                                {selectItemThree.map((element, i) => {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].degreeType =
                                          e.target.textContent;
                                        setAcademic2(updatedData);
                                        setDropdown3(false);
                                      }}
                                      className="item"
                                    >
                                      {element.option}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <input
                            type="text"
                            placeholder="Degree name"
                            className="resume-input"
                            value={academic2[index]?.degreeName}
                            onChange={(e) =>
                              handleInputChange(e, index, "degreeName")
                            }
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div className="row">
                            <div className="col-6">
                              <input
                                type="text"
                                placeholder="0"
                                className="resume-input"
                                value={academic2[index].percentage}
                                onChange={(e) =>
                                  handleInputChange(e, index, "percentage")
                                }
                              />
                            </div>
                            <div className="col-6">
                              <div>
                                {dropdown4 === index ? (
                                  <div
                                    className="select-overlay"
                                    onClick={() => setDropdown4(false)}
                                  ></div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="dropdown"
                                  onMouseEnter={() => setDropHover4(true)}
                                  onMouseLeave={() => setDropHover4(false)}
                                  style={{
                                    zIndex: dropdown4 === index ? "1112" : "",
                                  }}
                                >
                                  <div
                                    onClick={(e) => setDropdown4(index)}
                                    style={{
                                      outline:
                                        index === dropdown4
                                          ? "2px solid #94A3B8"
                                          : "none",
                                    }}
                                    className="dropdown-btn"
                                  >
                                    {academic2[index]?.percentageType}
                                    <img
                                      src={
                                        dropHover4 ? downArrowHover : downArrow
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    className="dropdown-content"
                                    style={{
                                      display:
                                        index === dropdown4 ? "block" : "none",
                                    }}
                                  >
                                    {cgp.map((element, i) => {
                                      return (
                                        <div
                                          onClick={(e) => {
                                            const updatedData = [...academic2];
                                            updatedData[index].percentageType =
                                              e.target.textContent;
                                            setAcademic2(updatedData);
                                            setDropdown4(false);
                                          }}
                                          className="item"
                                        >
                                          {element.option}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handleStartOpen(index)}
                          >
                            <input
                              placeholder="Starting year (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={academic2[index]?.startYear}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {startModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button className="close-btn">
                                  <img
                                    src={closeBtn}
                                    alt=""
                                    onClick={() => setStartModal(false)}
                                  />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].startYear =
                                          moment(e).format("MM-YYYY");
                                        setAcademic2(updatedData);
                                      }}
                                      // value={academic2[index]?.startYear}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handlePassingOpen(index)}
                          >
                            <input
                              placeholder="Passing year (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={academic2[index]?.endYear}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {passingModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button className="close-btn">
                                  <img
                                    src={closeBtn}
                                    alt=""
                                    onClick={() => setPassingModal(false)}
                                  />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].endYear =
                                          moment(e).format("MM-YYYY");
                                        setAcademic2(updatedData);
                                      }}
                                      // value={academic2[index]?.endYear}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 ">
                          <textarea
                            name=""
                            id=""
                            style={{ resize: "none" }}
                            placeholder="Write about the highlights of your journey "
                            className="resume-input m-0"
                            cols="30"
                            rows="3"
                            value={academic2[index]?.highlight}
                            onChange={(e) => {
                              const updatedData = [...academic2];
                              updatedData[index].highlight = e.target.value;
                              setAcademic2(updatedData);
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="tooltip-container">
                        <div
                          className="mobile-tooltip-box"
                          style={{ display: over ? "block" : "none" }}
                        >
                          <div
                            className="tooltip-close"
                            onClick={() => setOver(false)}
                          ></div>
                          The highlights of your journey create a powerful
                          impact by showcasing your accomplishments, growth, and
                          potential. They provide insight into your professional
                          trajectory and demonstrate the value you bring to the
                          table.
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ paddingBottom: "12px" }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={info}
                            onClick={() => setOver(!over)}
                            className="pe-2"
                            alt=""
                          />
                          <p className="information-text">
                            What impact would highlights create
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottom: "1px solid #CBD5E1",
                          marginBottom: "12px",
                        }}
                      ></div>
                      <div className="d-flex align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={(e) =>
                            setAcademic2([
                              ...academic2,
                              {
                                instituteName: "",
                                degreeName: "",
                                degreeType: "Degree Type",
                                percentage: "",
                                percentageType: "CGPA",
                                startYear: "",
                                endYear: "",
                                highlight: "",
                              },
                            ])
                          }
                          className="plush-text p-0"
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add education
                        </button>
                        <button
                          style={{
                            border: "none",
                            cursor: "pointer",
                            backgroundColor: "transparent",
                          }}
                          disabled={academic2.length === 1}
                          onClick={() => DeleteEducation(index)}
                          className="plush-text p-0"
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown3}
              >
                <p className="personal-title m-0">Certificates awarded</p>
                <img src={downArrow} alt="" />
              </div>
              <div
                className="resume-box"
                style={
                  mobile3 === false
                    ? { display: "none" }
                    : { display: "block", marginTop: "12px" }
                }
              >
                <div className="resume-box">
                  <p
                    className="resume-text mb-2"
                    style={{
                      color: "#4D4D4D",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "12px",
                    }}
                  >
                    Upload certificates awarded during your Academic years
                  </p>
                  {certificate.map((element, index) => {
                    return (
                      <>
                        <div className="row" key={index}>
                          <div className="col-sm-12 col-md-6 col-xl-4">
                            <input
                              type="text"
                              placeholder="Certificate Name"
                              name="certificateName"
                              className="resume-input"
                              value={certificate[index].certificateName}
                              onChange={(e) => {
                                const updatedData = [...certificate];
                                updatedData[index][e.target.name] =
                                  e.target.value;
                                setCerttificate(updatedData);
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-xl-4">
                            <div
                              htmlFor="file-upload"
                              onClick={handleDivClick}
                              className="custom-file-upload"
                            >
                              {cart[index] !== null
                                ? cart[index]?.name
                                : "Upload certificate"}
                              <input
                                id="file-upload"
                                ref={imageRef}
                                className="custom-file-input"
                                type="file"
                                name="certificateLink"
                                onChange={(e) => {
                                  updateFiles(e, index);
                                }}
                              />
                              <img src={uploadBtn} alt="" />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-xl-4">
                            <div
                              className="calender-date-input"
                              onClick={() => handleReceivedOpen(index)}
                            >
                              <input
                                placeholder="Received (mm/yyyy)"
                                className="input-date"
                                type="text"
                                name="issueDate"
                              />
                              <img
                                src={CalenderImg}
                                className="input-calender"
                                alt=""
                              />
                            </div>
                            {receivedModal === index ? (
                              <div className="calender-modal">
                                <div className="calender-box-2">
                                  <button
                                    className="close-btn"
                                    onClick={() => setReceivedModal(false)}
                                  >
                                    <img src={closeBtn} alt="" />
                                  </button>
                                  <div className="text-center">
                                    <h6 className="calender-title m-0">
                                      Choose date slot(s)
                                    </h6>
                                    <div className="calender">
                                      <Calendar
                                        onChange={(e) => {
                                          const updatedData = [
                                            ...jobAchievement,
                                          ];
                                          updatedData[index].issueDate =
                                            moment(e).format("MM-YYYY");
                                          setJobAchievement(updatedData);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            borderBottom: "1px solid #CBD5E1",
                            marginBottom: "16px",
                          }}
                        ></div>
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            onClick={(e) =>
                              setCerttificate([
                                ...certificate,
                                {
                                  certificateName: "",
                                  certificateLink: "",
                                  issueDate: "",
                                },
                              ])
                            }
                            className="plush-text p-0"
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add more
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text p-0"
                            disabled={certificate.length === 1}
                            onClick={() => DeleteCertificates(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown4}
              >
                <p
                  className="personal-title m-0"
                  style={{ paddingBottom: mobile4 === true ? "12px" : "0" }}
                >
                  Work Experience
                </p>
                <img
                  src={downArrow}
                  alt=""
                  style={{ paddingBottom: mobile4 === true ? "12px" : "0" }}
                />
              </div>
              <div
                className="resume-box"
                style={
                  mobile4 === true
                    ? { display: "block" }
                    : { display: "none", marginTop: "12px" }
                }
              >
                <div className="row">
                  {experience.map((element, index) => {
                    return (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            name="companyName"
                            placeholder="Company name"
                            className="resume-input"
                            value={experience[index]?.companyName}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            ref={ref6}
                            className="dropdown"
                            onMouseEnter={() => setDropHover1(true)}
                            onMouseLeave={() => setDropHover1(false)}
                          >
                            <div
                              onClick={handleOnClick6}
                              style={{
                                outline: dropdown9
                                  ? "2px solid #94A3B8"
                                  : "none",
                              }}
                              className="dropdown-btn"
                            >
                              {experience[index]?.companyType}
                              <img
                                src={dropHover1 ? downArrowHover : downArrow}
                                alt=""
                              />
                            </div>
                            <div
                              className="dropdown-content"
                              style={{ display: dropdown9 ? "block" : "none" }}
                            >
                              {selectItem.map((element, i) => {
                                return (
                                  <div
                                    onClick={(e) => {
                                      const updatedData = [...experience];
                                      updatedData[index].companyType =
                                        e.target.textContent;
                                      setExperience(updatedData);
                                      setDropdown9(false);
                                    }}
                                    className="item"
                                  >
                                    {element.option}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Job Role"
                            className="resume-input"
                            name="role"
                            value={experience[index]?.role}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            placeholder="Designation (if any)"
                            className="resume-input"
                            type="text"
                            name="designation"
                            value={experience[index]?.designation}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={handleFromOpen}
                          >
                            <input
                              placeholder="From (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={experience[index]?.from}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {fromModal ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={handleFromOpen}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...experience];
                                        updatedData[index].from =
                                          moment(e).format("MM-YYYY");
                                        setExperience(updatedData);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={handleToOpen}
                          >
                            <input
                              placeholder="To (mm/yyyy)"
                              className="input-date"
                              disabled={checked === true}
                              type="text"
                              value={experience[index]?.to}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {toModal ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={handleToOpen}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...experience];
                                        updatedData[index].to =
                                          moment(e).format("MM-YYYY");
                                        setExperience(updatedData);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12">
                          <textarea
                            name="describeRole"
                            id=""
                            style={{ resize: "none" }}
                            cols="30"
                            rows="3"
                            className="resume-input m-0"
                            placeholder="Describe your job roles & responsibilities"
                            value={experience[index]?.describeRole}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          ></textarea>
                        </div>
                        <p
                          className="information-text pb-1"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="The highlights of your journey create a powerful impact by showcasing your accomplishments, growth, and potential. They provide insight into your professional trajectory and demonstrate the value you bring to the table."
                        >
                          <img src={info} className="pe-2" alt="" />
                          What impact would responsibilities create
                        </p>
                        <div className="col-12">
                          <textarea
                            name="jobHighlight"
                            id=""
                            cols="30"
                            style={{ resize: "none" }}
                            rows="3"
                            className="resume-input m-0"
                            placeholder="Write about the highlights of your journey "
                            value={experience[index]?.jobHighlight}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          ></textarea>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text"
                            onClick={AddExperience}
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add more
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text"
                            disabled={experience.length === 1}
                            onClick={() => DeleteExperience(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div>
                        {/* mobile tooltip addded */}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="personal-from">
              <div
                className="d-flex align-items-center justify-content-between"
                onClick={Dropdown5}
              >
                <p
                  className="personal-title m-0"
                  style={{ paddingBottom: mobile5 === true ? "12px" : "0" }}
                >
                  jobAchievement Details
                </p>
                <img
                  src={downArrow}
                  style={{ paddingBottom: mobile5 === true ? "12px" : "0" }}
                  alt=""
                />
              </div>
              <div
                className="resume-box"
                style={
                  mobile5 === true
                    ? { display: "block" }
                    : { display: "none", marginTop: "12px" }
                }
              >
                <div className="row">
                  {jobAchievement.map((element, index) => {
                    return (
                      <>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <input
                            type="text"
                            placeholder="Certification/Achievement"
                            className="resume-input"
                            name="certificateName"
                            value={jobAchievement[index]?.certificateName}
                            onChange={(e) => {
                              const updatedData = [...jobAchievement];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setJobAchievement(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="custom-file-upload"
                            onClick={handleAweClick}
                          >
                            {averd[index] !== null
                              ? averd[index]?.name
                              : "Upload certificate"}
                            <input
                              id="file-upload"
                              ref={imageRef4}
                              className="custom-file-input"
                              type="file"
                              name="certificateLink"
                              onChange={(e) => {
                                updateAwe(e, index);
                              }}
                            />
                            <img src={uploadBtn} alt="" />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handleReceivedOpen(index)}
                          >
                            <input
                              placeholder="Received (mm/yyyy)"
                              className="input-date"
                              type="text"
                              name="issueDate"
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {receivedModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={() => setReceivedModal(false)}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...jobAchievement];
                                        updatedData[index].issueDate =
                                          moment(e).format("MM-YYYY");
                                        setJobAchievement(updatedData);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            style={{
                              borderBottom: "1px solid rgb(203, 213, 225)",
                              marginBottom: "12px",
                              marginTop: "12px",
                            }}
                          ></div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text"
                            onClick={AddjobAchievement}
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add more
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            disabled={jobAchievement.length === 1}
                            className="plush-text"
                            onClick={() => DeletejobAchievement(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="personal-from">
            <div
              className="d-flex align-items-center justify-content-between"
              onClick={Dropdown6}
            >
              <p
                className="personal-title m-0"
                style={{ paddingBottom: mobile6 === true ? "12px" : "0" }}
              >
                academic certificates
              </p>
              <img
                src={downArrow}
                style={{ paddingBottom: mobile6 === true ? "12px" : "0" }}
                alt=""
              />
            </div>
            <div
              className="resume-box"
              style={
                mobile6 === true
                  ? { display: "block" }
                  : { display: "none", marginTop: "12px" }
              }
            >
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <label for="file-upload1" class="custom-file-upload">
                    {formData.image1
                      ? formData.image1.name
                      : "Matriculation certificate"}
                    <input
                      id="file-upload1"
                      className="custom-file-input"
                      onChange={(e) => handleImage(e, "image1")}
                      ref={imageRef1}
                      type="file"
                    />
                    <img src={uploadBtn} alt="" />
                  </label>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <label for="file-upload2" class="custom-file-upload">
                    {formData.image2
                      ? formData.image2.name
                      : "Highest qualification certificate"}
                    <input
                      id="file-upload2"
                      className="custom-file-input"
                      onChange={(e) => handleImage(e, "image2")}
                      ref={imageRef2}
                      type="file"
                    />
                    <img src={uploadBtn} alt="" />
                  </label>
                </div>
                {/*   <div
                      style={{
                        borderBottom: "1px solid rgb(203, 213, 225)",
                        marginBottom: "12px",
                        marginTop: "12px",
                      }}
                    ></div> */}

                {/*  <p className="plush-text p-0 m-0" onClick={AddMandatory}>
                <img src={plush} className="pe-2" alt="" />
                Add certificate
              </p> */}
              </div>
            </div>
          </div>
          <div className="personal-from">
            <div
              className="d-flex align-items-center justify-content-between"
              onClick={Dropdown7}
            >
              <p
                className="personal-title m-0"
                style={{ paddingBottom: mobile7 === true ? "12px" : "0" }}
              >
                Certificate courses
              </p>
              <img
                src={downArrow}
                style={{ paddingBottom: mobile7 === true ? "12px" : "0" }}
                alt=""
              />
            </div>
            <div
              className="resume-box"
              style={
                mobile7 === true
                  ? { display: "block" }
                  : { display: "none", marginTop: "12px" }
              }
            >
              <div className="row">
                {certificate1.map((element, index) => {
                  return (
                    <>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div>
                          {dropdown3 === index ? (
                            <div
                              className="select-overlay"
                              onClick={() => setDropdown3(false)}
                            ></div>
                          ) : (
                            ""
                          )}
                          <div
                            className="dropdown"
                            onMouseEnter={() => setDropHover1(true)}
                            onMouseLeave={() => setDropHover1(false)}
                            style={{
                              zIndex: dropdown3 === index ? "1112" : "",
                            }}
                          >
                            <div
                              onClick={(e) => setDropdown3(index)}
                              style={{
                                outline: dropdown3
                                  ? "2px solid #94A3B8"
                                  : "none",
                              }}
                              className="dropdown-btn"
                            >
                              {certificate1[index]?.mode}
                              <img
                                src={dropHover1 ? downArrowHover : downArrow}
                                alt=""
                              />
                            </div>
                            <div
                              className="dropdown-content"
                              style={{
                                display: index === dropdown3 ? "block" : "none",
                              }}
                            >
                              {Modes.map((element, i) => {
                                return (
                                  <div
                                    onClick={(e) => {
                                      const updatedData = [...certificate1];
                                      updatedData[index].mode =
                                        e.target.textContent;
                                      setCertificate(updatedData);
                                      setDropdown3(false);
                                    }}
                                    className="item"
                                  >
                                    {element.option}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="number"
                          placeholder="Institute Name"
                          className="resume-input"
                          name="instituteName"
                          onChange={(e) => {
                            const updatedData = [...certificate1];
                            updatedData[index].instituteName = e.target.value;
                            setCertificate(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          placeholder="Course name"
                          className="resume-input"
                          name="CourseName"
                          onChange={(e) => {
                            const updatedData = [...certificate1];
                            updatedData[index].CourseName = e.target.value;
                            setCertificate(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                      <div
                            className="custom-file-upload"
                            onClick={handleImgClick}
                          >
                            {images[index] !== null
                              ? images[index]?.name
                              : "Upload certificate"}
                            <input
                              id="file-upload"
                              // onChange={(e) => handleImage(e, "image")}
                              ref={imageRef3}
                              className="custom-file-input"
                              type="file"
                              name="certificateWebLink"
                              onChange={(e) => {
                                updateImg(e, index);
                              }}
                            />
                            <img src={uploadBtn} alt="" />
                          </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <input
                          type="text"
                          placeholder="Add certificate weblink"
                          className="resume-input"
                          name="certificateWebLink"
                          onChange={(e) => {
                            const updatedData = [...certificate1];
                            updatedData[index].certificateWebLink =
                              e.target.value;
                            setCertificate(updatedData);
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <div
                          className="calender-date-input"
                          onClick={() => handlereceivedDateOpen(index)}
                        >
                          <input
                            placeholder="receivedDate (mm/yyyy)"
                            className="input-date"
                            type="text"
                            value={certificate1[index]?.receivedDate}
                          />
                          <img
                            src={CalenderImg}
                            className="input-calender"
                            alt=""
                          />
                        </div>
                        {receivedDateModal === index ? (
                          <div className="calender-modal">
                            <div className="calender-box-2">
                              <button
                                className="close-btn"
                                onClick={() => setreceivedDateModal(false)}
                              >
                                <img src={closeBtn} alt="" />
                              </button>
                              <div className="text-center">
                                <h6 className="calender-title m-0">
                                  Choose date slot(s)
                                </h6>
                                <div className="calender">
                                  <Calendar
                                    onChange={(e) => {
                                      const updatedData = [...certificate1];
                                      updatedData[index].receivedDate =
                                        moment(e).format("MM/YYYY");
                                      setCertificate(updatedData);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-12">
                        <textarea
                          name="keyLearning"
                          onChange={(e) => {
                            const updatedData = [...certificate1];
                            updatedData[index].keyLearning = e.target.value;
                            setCertificate(updatedData);
                          }}
                          id=""
                          cols="30"
                          style={{ resize: "none" }}
                          rows="3"
                          className="resume-input m-0"
                          placeholder="Write keyLearning your key learnings"
                        ></textarea>
                      </div>
                      <div className="tooltip-container">
                        <div
                          className="tooltip-box"
                          style={{
                            display: tooltip2 ? "block" : "none",
                            left: "-12px",
                          }}
                        >
                          <div
                            className="tooltip-close"
                            onClick={() => setTooltip2(false)}
                          ></div>
                          The highlights of your journey create a powerful
                          impact by showcasing your accomplishments, growth, and
                          potential. They provide insight into your professional
                          trajectory and demonstrate the value you bring to the
                          table.
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ paddingBottom: "12px" }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={info}
                            onClick={() => setTooltip2(!tooltip2)}
                            className="pe-2"
                            alt=""
                          />
                          <p className="information-text">
                            What impact would key learnings create
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          borderBottom: "1px solid rgb(203, 213, 225)",
                          marginBottom: "12px",
                          marginTop: "12px",
                        }}
                      ></div>
                      <div className="d-flex pb-2 align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text p-0"
                          onClick={AddCertificate1}
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add certificate
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text p-0"
                          disabled={certificate.length === 1}
                          onClick={() => DeleteCertificate1(index)}
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>

            <div className="bottom-btn">
              <div className="text-center">
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <button className="skip-btn">Skip for now</button>
              </div>
            </div>
          </div>

          {/* Desktop Screen */}
          <div className="desktop-show-hide">
            <div className="profile-build">
              <div className="profile-line"></div>
              <h6 className="build-title">Edit your profiles</h6>
              <p className="build-text">
                With a well-crafted professional profile, you'll showcase your
                expertise and attract the attention of potential employers. Good
                luck!
              </p>
              <div className="border-bottom"></div>
              <div className="resume-box">
                <h6 className="resume-title">Personal Details</h6>
                <div className="row">
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <input
                      type="text"
                      name="name"
                      placeholder="full name"
                      className="resume-input"
                      value={academicData?.name}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <input
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      name="phone"
                      value={academicData?.phone}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                      placeholder="phone number"
                      className="resume-input"
                    />
                  </div>
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      className="resume-input"
                      value={academicData?.email}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <div
                      className="calender-date-input"
                      onClick={handleDateOpen}
                    >
                      <input
                        placeholder="Enter DOB"
                        className="input-date"
                        type="text"
                        value={academicData?.dob}
                        name="dob"
                      />
                      <img
                        src={CalenderImg}
                        className="input-calender"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <CitySearch
                      options={cityList}
                      label="name"
                      selectedVal={city}
                      handleChange={(val) => setCity(val)}
                    />
                  </div>
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <SearchBar
                      options={allState}
                      label="displayValue"
                      selectedVal={value}
                      handleChange={(val) => setValue(val)}
                    />
                  </div>
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <input
                      type="text"
                      placeholder="Pin code"
                      className="resume-input"
                      name="pincode"
                      value={academicData.pincode}
                      onChange={(e) =>
                        setAcademicData({
                          ...academicData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <div
                      ref={ref1}
                      className="dropdown"
                      onMouseEnter={() => setDropHover2(true)}
                      onMouseLeave={() => setDropHover2(false)}
                    >
                      <div
                        onClick={handleOnClick1}
                        style={{
                          outline: dropdown1 ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData.workingStatus}
                        <img
                          src={dropHover2 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: dropdown1 ? "block" : "none" }}
                      >
                        {selectItem.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["workingStatus"]: e.target.textContent,
                                });
                                setDropdown1(false);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-6 col-xl-4">
                    <div
                      ref={ref2}
                      className="dropdown"
                      onMouseEnter={() => setDropHover3(true)}
                      onMouseLeave={() => setDropHover3(false)}
                    >
                      <div
                        onClick={handleOnClick2}
                        style={{
                          outline: dropdown2 ? "2px solid #94A3B8" : "none",
                        }}
                        className="dropdown-btn"
                      >
                        {academicData?.maritalStatus}
                        <img
                          src={dropHover3 ? downArrowHover : downArrow}
                          alt=""
                        />
                      </div>
                      <div
                        className="dropdown-content"
                        style={{ display: dropdown2 ? "block" : "none" }}
                      >
                        {selectItemTwo.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setAcademicData({
                                  ...academicData,
                                  ["maritalStatus"]: e.target.textContent,
                                });
                                setDropdown2(false);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-bottom"></div>
              <div className="resume-box">
                {academic2?.map((element, index) => {
                  return (
                    <>
                      <h6 className="resume-title">Academic Details</h6>
                      <div className="row">
                        <div className="col-sm-12 col-lg-6 col-xl-4">
                          <input
                            type="text"
                            placeholder="Institute/University name"
                            className="resume-input"
                            name="instituteName"
                            value={academic2[index]?.instituteName}
                            onChange={(e) => {
                              const updatedData2 = [...academic2];
                              updatedData2[index][e.target.name] =
                                e.target.value;
                              setAcademic2(updatedData2);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-6 col-xl-4">
                          <div>
                            {dropdown3 === index ? (
                              <div
                                className="select-overlay"
                                onClick={() => setDropdown3(false)}
                              ></div>
                            ) : (
                              ""
                            )}
                            <div
                              className="dropdown"
                              style={{
                                zIndex: dropdown3 === index ? "1112" : "",
                              }}
                              onMouseEnter={() => setDropHover4(true)}
                              onMouseLeave={() => setDropHover4(false)}
                            >
                              <div
                                onClick={(e) => setDropdown3(index)}
                                style={{
                                  outline: dropdown3
                                    ? "2px solid #94A3B8"
                                    : "none",
                                }}
                                className="dropdown-btn"
                              >
                                {academic2[index]?.degreeType}
                                <img
                                  src={
                                    dropdown3 === index
                                      ? downArrowHover
                                      : downArrow
                                  }
                                  alt=""
                                />
                              </div>
                              <div
                                className="dropdown-content"
                                style={{
                                  display:
                                    index === dropdown3 ? "block" : "none",
                                }}
                              >
                                {selectItemThree.map((element, i) => {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].degreeType =
                                          e.target.textContent;
                                        setAcademic2(updatedData);
                                        setDropdown3(false);
                                      }}
                                      className="item"
                                    >
                                      {element.option}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 col-xl-4">
                          <input
                            type="text"
                            placeholder="Degree name"
                            className="resume-input"
                            name="degreeName"
                            value={academic2[index]?.degreeName}
                            onChange={(e) => {
                              const updatedData2 = [...academic2];
                              updatedData2[index][e.target.name] =
                                e.target.value;
                              setAcademic2(updatedData2);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-6 col-xl-4">
                          <div className="row">
                            <div className="col-6">
                              <input
                                type="text"
                                placeholder="0"
                                className="resume-input"
                                name="percentage"
                                value={academic2[index].percentage}
                                onChange={(e) => {
                                  const updatedData2 = [...academic2];
                                  updatedData2[index][e.target.name] =
                                    e.target.value;
                                  setAcademic2(updatedData2);
                                }}
                              />
                            </div>
                            <div className="col-6">
                              <div>
                                {dropdown4 === index ? (
                                  <div
                                    className="select-overlay"
                                    onClick={() => setDropdown4(false)}
                                  ></div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className="dropdown"
                                  style={{
                                    zIndex: dropdown4 === index ? "1112" : "",
                                  }}
                                  onMouseEnter={() => setDropHover4(true)}
                                  onMouseLeave={() => setDropHover4(false)}
                                >
                                  <div
                                    onClick={(e) => setDropdown4(index)}
                                    style={{
                                      outline:
                                        index === dropdown4
                                          ? "2px solid #94A3B8"
                                          : "none",
                                    }}
                                    className="dropdown-btn"
                                  >
                                    {academic2[index]?.percentageType}
                                    <img
                                      src={
                                        dropHover4 ? downArrowHover : downArrow
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    className="dropdown-content"
                                    style={{
                                      display:
                                        index === dropdown4 ? "block" : "none",
                                    }}
                                  >
                                    {cgp.map((element, i) => {
                                      return (
                                        <div
                                          onClick={(e) => {
                                            const updatedData = [...academic2];
                                            updatedData[index].percentageType =
                                              e.target.textContent;
                                            setAcademic2(updatedData);
                                            setDropdown4(false);
                                          }}
                                          className="item"
                                        >
                                          {element.option}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 col-xl-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handleStartOpen(index)}
                          >
                            <input
                              placeholder="Starting year (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={academic2[index]?.startYear}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {startModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button className="close-btn">
                                  <img
                                    src={closeBtn}
                                    alt=""
                                    onClick={() => setStartModal(false)}
                                  />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].startYear =
                                          moment(e).format("MM-YYYY");
                                        setAcademic2(updatedData);
                                      }}
                                      // value={academic2[index]?.startYear}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-12 col-lg-6 col-xl-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handlePassingOpen(index)}
                          >
                            <input
                              placeholder="Passing year (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={academic2[index]?.endYear}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {passingModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button className="close-btn">
                                  <img
                                    src={closeBtn}
                                    alt=""
                                    onClick={() => setPassingModal(false)}
                                  />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...academic2];
                                        updatedData[index].endYear =
                                          moment(e).format("MM-YYYY");
                                        setAcademic2(updatedData);
                                      }}
                                      // value={academic2[index]?.endYear}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 ">
                          <textarea
                            name=""
                            id=""
                            style={{ resize: "none" }}
                            placeholder="Write about the highlights of your journey "
                            className="resume-input m-0"
                            cols="30"
                            rows="3"
                            value={academic2[index]?.highlight}
                            onChange={(e) => {
                              const updatedData = [...academic2];
                              updatedData[index].highlight = e.target.value;
                              setAcademic2(updatedData);
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="tooltip-container">
                        <div
                          className="tooltip-box"
                          style={{ display: over ? "block" : "none" }}
                        >
                          <div
                            className="tooltip-close"
                            onClick={() => setOver(false)}
                          ></div>
                          The highlights of your journey create a powerful
                          impact by showcasing your accomplishments, growth, and
                          potential. They provide insight into your professional
                          trajectory and demonstrate the value you bring to the
                          table.
                        </div>
                        <div
                          className="d-flex align-items-center"
                          style={{ paddingBottom: "12px" }}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={info}
                            onClick={() => setOver(!over)}
                            className="pe-2"
                            alt=""
                          />
                          <p className="information-text">
                            What impact would highlights create
                          </p>
                        </div>
                      </div>
                      <div className="d-flex mb-2 align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          className="plush-text p-0"
                          onClick={(e) =>
                            setAcademic2([
                              ...academic2,
                              {
                                instituteName: "",
                                degreeName: "",
                                degreeType: "Degree Type",
                                percentage: "",
                                percentageType: "CGPA",
                                startYear: "",
                                endYear: "",
                                highlight: "",
                              },
                            ])
                          }
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add education
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            cursor: "pointer",
                          }}
                          disabled={academic2.length === 1}
                          className="plush-text p-0"
                          onClick={() => DeleteEducation(index)}
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="border-bottom"></div>
              <div className="resume-box">
                {certificate.map((element, index) => {
                  return (
                    <>
                      <h6 className="resume-title">
                        Certificates awarded during your Academic years
                      </h6>
                      <div className="row">
                        <div className="col-sm-12 col-lg-6 col-xl-4">
                          <input
                            type="text"
                            placeholder="Certificate Name"
                            name="certificateName"
                            className="resume-input"
                            value={certificate[index].certificateName}
                            onChange={(e) => {
                              const updatedData = [...certificate];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setCerttificate(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-lg-6 col-xl-4">
                          <div
                            htmlFor="file-upload"
                            onClick={() => handleDivClick()}
                            className="custom-file-upload"
                          >
                            {cart[index] !== null
                              ? cart[index]?.name
                              : "Upload certificate"}
                            <input
                              id="file-upload"
                              ref={imageRef}
                              className="custom-file-input"
                              type="file"
                              name="certificateLink"
                              value={""}
                              onChange={(e) => {
                                updateFiles(e, index);
                              }}
                            />
                            <img src={uploadBtn} alt="" />
                          </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 col-xl-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handleReceivedOpen(index)}
                          >
                            <input
                              placeholder="Received (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={certificate[index]?.issueDate}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {receivedModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={() => setReceivedModal(false)}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...certificate];
                                        updatedData[index].issueDate =
                                          moment(e).format("MM-YYYY");
                                        setCerttificate(updatedData);
                                      }}
                                      // value={received}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={(e) => {
                            if (certificate?.length < 5) {
                              setCerttificate([
                                ...certificate,
                                {
                                  certificateName: "",
                                  certificateLink: "",
                                  issueDate: "",
                                },
                              ]);
                            }
                          }}
                          className="plush-text p-0"
                        >
                          <img src={plush} className="pe-2" alt="" />
                          Add more
                        </button>
                        <button
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          disabled={certificate.length === 1}
                          onClick={() => DeleteCertificates(index)}
                          className="plush-text p-0"
                        >
                          <img src={Delete} className="pe-2" alt="" />
                          Delete
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="border-bottom mt-2"></div>
              <div className="resume-box">
                <h6 className="resume-title">Work Experience</h6>
                <div className="row">
                  {experience.map((element, index) => {
                    return (
                      <>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <input
                            type="text"
                            name="companyName"
                            placeholder="Company name"
                            className="resume-input"
                            value={experience[index]?.companyName}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div ref={ref5} className="dropdown">
                            <div
                              onClick={handleOnClick5}
                              style={{
                                outline: dropdown7
                                  ? "2px solid #94A3B8"
                                  : "none",
                              }}
                              className="dropdown-btn"
                            >
                              {experience[index]?.companyType}
                              <img
                                src={dropdown7 ? downArrowHover : downArrow}
                                alt=""
                              />
                            </div>
                            <div
                              className="dropdown-content"
                              style={{ display: dropdown7 ? "block" : "none" }}
                            >
                              {selectItem.map((element, i) => {
                                return (
                                  <div
                                    onClick={(e) => {
                                      const updatedData = [...experience];
                                      updatedData[index].companyType =
                                        e.target.textContent;
                                      setExperience(updatedData);
                                      setDropdown7(false);
                                    }}
                                    className="item"
                                  >
                                    {element.option}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <input
                            type="text"
                            placeholder="Job Role"
                            className="resume-input"
                            name="role"
                            value={experience[index]?.role}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <input
                            placeholder="Designation (if any)"
                            className="resume-input"
                            type="text"
                            name="designation"
                            value={experience[index]?.designation}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div
                            className="calender-date-input"
                            onClick={handleFromOpen}
                          >
                            <input
                              placeholder="From (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={experience[index]?.from}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {fromModal ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={handleFromOpen}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...experience];
                                        updatedData[index].from =
                                          moment(e).format("MM-YYYY");
                                        setExperience(updatedData);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <button
                            className="calender-date-input"
                            disabled={checked === true}
                            style={{ opacity: checked ? "0.5" : "1" }}
                            onClick={handleToOpen}
                          >
                            <input
                              placeholder="To (mm/yyyy)"
                              className="input-date"
                              disabled={experience[index].to === "Present"}
                              type="text"
                              value={experience[index]?.to}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </button>
                          {toModal ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={handleToOpen}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedDataa = [...experience];
                                        updatedDataa[index].to =
                                          moment(e).format("MM-YYYY");
                                        setExperience(updatedDataa);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12">
                          <div className="checkbox-button d-flex align-items-center">
                            <label>
                              <input
                                id={`language1${Date.now()}`}
                                type="checkbox"
                                // checked={checked}
                                // className="checkbox-button-input"
                                // value="Elementary"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    const updatedData = [...experience];
                                    updatedData[index].to = "Present";
                                    setExperience(updatedData);
                                  } else {
                                    const updatedData = [...experience];
                                    updatedData[index].to = "";
                                    setExperience(updatedData);
                                  }
                                }}
                              />
                              am currently working here
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <textarea
                            name="describeRole"
                            id=""
                            style={{ resize: "none" }}
                            cols="30"
                            rows="3"
                            className="resume-input m-0"
                            placeholder="Describe your job roles & responsibilities"
                            value={experience[index]?.describeRole}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          ></textarea>
                        </div>
                        <div className="tooltip-container">
                          <div
                            className="tooltip-box"
                            style={{
                              display: tooltip1 ? "block" : "none",
                              left: "-12px",
                            }}
                          >
                            <div
                              className="tooltip-close"
                              onClick={() => setTooltip1(false)}
                            ></div>
                            The highlights of your journey create a powerful
                            impact by showcasing your accomplishments, growth,
                            and potential. They provide insight into your
                            professional trajectory and demonstrate the value
                            you bring to the table.
                          </div>
                          <div
                            className="d-flex align-items-center"
                            style={{ paddingBottom: "12px" }}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={info}
                              onClick={() => setTooltip1(!tooltip1)}
                              className="pe-2"
                              alt=""
                            />
                            <p className="information-text">
                              What impact would responsibilities create
                            </p>
                          </div>
                        </div>
                        <div className="col-12">
                          <textarea
                            name="jobHighlight"
                            id=""
                            cols="30"
                            style={{ resize: "none" }}
                            rows="3"
                            className="resume-input m-0"
                            placeholder="Write about the highlights of your journey "
                            value={experience[index]?.jobHighlight}
                            onChange={(e) => {
                              const updatedData = [...experience];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setExperience(updatedData);
                            }}
                          ></textarea>
                        </div>
                        <div className="tooltip-container">
                          <div
                            className="tooltip-box"
                            style={{
                              display: tooltip2 ? "block" : "none",
                              left: "-12px",
                            }}
                          >
                            <div
                              className="tooltip-close"
                              onClick={() => setTooltip2(false)}
                            ></div>
                            The highlights of your journey create a powerful
                            impact by showcasing your accomplishments, growth,
                            and potential. They provide insight into your
                            professional trajectory and demonstrate the value
                            you bring to the table.
                          </div>
                          <div
                            className="d-flex align-items-center"
                            style={{ paddingBottom: "12px" }}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={info}
                              onClick={() => setTooltip2(!tooltip2)}
                              className="pe-2"
                              alt=""
                            />
                            <p className="information-text">
                              What impact would highlights create
                            </p>
                          </div>
                        </div>
                        <div className="d-flex pb-2 align-items-center justify-content-between">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text"
                            onClick={AddExperience}
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add more
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text"
                            disabled={experience.length === 1}
                            onClick={() => DeleteExperience(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="border-bottom"></div>
              <div className="resume-box">
                <h6 className="resume-title">
                  Achievements & Certificates awarded during your working years
                </h6>
                <div className="row">
                  {jobAchievement.map((element, index) => {
                    return (
                      <>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <input
                            type="text"
                            placeholder="Certification/Achievement"
                            className="resume-input"
                            name="certificateName"
                            value={jobAchievement[index]?.certificateName}
                            onChange={(e) => {
                              const updatedData = [...jobAchievement];
                              updatedData[index][e.target.name] =
                                e.target.value;
                              setJobAchievement(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div
                            className="custom-file-upload"
                            onClick={handleAweClick}
                          >
                            {averd[index] !== null
                              ? averd[index]?.name
                              : "Upload certificate"}
                            <input
                              id="file-upload"
                              ref={imageRef4}
                              className="custom-file-input"
                              type="file"
                              name="certificateLink"
                              onChange={(e) => {
                                updateAwe(e, index);
                              }}
                            />
                            <img src={uploadBtn} alt="" />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handleReceivedOpen(index)}
                          >
                            <input
                              placeholder="Received (mm/yyyy)"
                              className="input-date"
                              type="text"
                              name="issueDate"
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {receivedModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={() => setReceivedModal(false)}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...jobAchievement];
                                        updatedData[index].issueDate =
                                          moment(e).format("MM-YYYY");
                                        setJobAchievement(updatedData);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-flex pb-2 align-items-center justify-content-between">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            onClick={AddjobAchievement}
                            className="plush-text"
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add more
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            onClick={() => DeletejobAchievement(index)}
                            className="plush-text"
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="resume-box">
                <h6 className="resume-title">academic certificates </h6>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-xl-4">
                    <label
                      htmlFor="file-upload1"
                      className="custom-file-upload"
                    >
                      {formData.image1
                        ? formData.image1.name
                        : "Matriculation certificate"}
                      <input
                        id="file-upload1"
                        className="custom-file-input"
                        accept="application/pdf"
                        onChange={(e) => handleImage(e, "image1")}
                        ref={imageRef1}
                        type="file"
                      />
                      <img src={uploadBtn} alt="" />
                    </label>
                  </div>
                  <div className="col-sm-12 col-md-6 col-xl-4">
                    <label
                      htmlFor="file-upload2"
                      className="custom-file-upload"
                    >
                      {formData.image2
                        ? formData.image2.name
                        : "Highest qualification certificate"}
                      <input
                        id="file-upload2"
                        className="custom-file-input"
                        onChange={(e) => handleImage(e, "image2")}
                        ref={imageRef2}
                        type="file"
                      />
                      <img src={uploadBtn} alt="" />
                    </label>
                  </div>
                </div>
                <div className="border-bottom"></div>
                <h6 className="resume-title" style={{ paddingTop: "16px" }}>
                  Certificate courses
                </h6>
                {certificate1.map((element, index) => {
                  return (
                    <>
                      <div className="row" key={index}>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div>
                            {dropdown8 === index ? (
                              <div
                                className="select-overlay"
                                onClick={() => setDropdown8(false)}
                              ></div>
                            ) : (
                              ""
                            )}
                            <div
                              className="dropdown"
                              onMouseEnter={() => setDropHover1(true)}
                              onMouseLeave={() => setDropHover1(false)}
                              style={{
                                zIndex: dropdown8 === index ? "1112" : "",
                              }}
                            >
                              <div
                                onClick={(e) => setDropdown8(index)}
                                style={{
                                  outline: dropdown8
                                    ? "2px solid #94A3B8"
                                    : "none",
                                }}
                                className="dropdown-btn"
                              >
                                {certificate1[index]?.mode}
                                <img
                                  src={dropHover1 ? downArrowHover : downArrow}
                                  alt=""
                                />
                              </div>
                              <div
                                className="dropdown-content"
                                style={{
                                  display:
                                    index === dropdown8 ? "block" : "none",
                                }}
                              >
                                {Modes.map((element, i) => {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        const updatedData = [...certificate1];
                                        updatedData[index].mode =
                                          e.target.textContent;
                                        setCertificate(updatedData);
                                        setDropdown8(false);
                                      }}
                                      className="item"
                                    >
                                      {element.option}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <input
                            type="text"
                            placeholder="Institute Name"
                            className="resume-input"
                            name="instituteName"
                            onChange={(e) => {
                              const updatedData = [...certificate1];
                              updatedData[index].instituteName = e.target.value;
                              setCertificate(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <input
                            type="text"
                            placeholder="Course name"
                            className="resume-input"
                            name="CourseName"
                            onChange={(e) => {
                              const updatedData = [...certificate1];
                              updatedData[index].CourseName = e.target.value;
                              setCertificate(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div
                            className="custom-file-upload"
                            onClick={handleImgClick}
                          >
                            {images[index] !== null
                              ? images[index]?.name
                              : "Upload certificate"}
                            <input
                              id="file-upload"
                              // onChange={(e) => handleImage(e, "image")}
                              ref={imageRef3}
                              className="custom-file-input"
                              type="file"
                              name="certificateWebLink"
                              onChange={(e) => {
                                updateImg(e, index);
                              }}
                            />
                            <img src={uploadBtn} alt="" />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <input
                            type="text"
                            placeholder="Add certificate weblink"
                            className="resume-input"
                            name="certificateWebLink"
                            onChange={(e) => {
                              const updatedData = [...certificate1];
                              updatedData[index].certificateWebLink =
                                e.target.value;
                              setCertificate(updatedData);
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-xl-4">
                          <div
                            className="calender-date-input"
                            onClick={() => handlereceivedDateOpen(index)}
                          >
                            <input
                              placeholder="receivedDate (mm/yyyy)"
                              className="input-date"
                              type="text"
                              value={certificate1[index]?.receivedDate}
                            />
                            <img
                              src={CalenderImg}
                              className="input-calender"
                              alt=""
                            />
                          </div>
                          {receivedDateModal === index ? (
                            <div className="calender-modal">
                              <div className="calender-box-2">
                                <button
                                  className="close-btn"
                                  onClick={() => setreceivedDateModal(false)}
                                >
                                  <img src={closeBtn} alt="" />
                                </button>
                                <div className="text-center">
                                  <h6 className="calender-title m-0">
                                    Choose date slot(s)
                                  </h6>
                                  <div className="calender">
                                    <Calendar
                                      onChange={(e) => {
                                        const updatedData = [...certificate1];
                                        updatedData[index].receivedDate =
                                          moment(e).format("MM/YYYY");
                                        setCertificate(updatedData);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12">
                          <textarea
                            name="keyLearning"
                            onChange={(e) => {
                              const updatedData = [...certificate1];
                              updatedData[index].keyLearning = e.target.value;
                              setCertificate(updatedData);
                            }}
                            id=""
                            cols="30"
                            rows="3"
                            style={{ resize: "none" }}
                            className="resume-input m-0"
                            placeholder="Write keyLearning your key learnings"
                          ></textarea>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="tooltip-container">
                            <div
                              className="tooltip-box"
                              style={{
                                display: tooltip2 ? "block" : "none",
                                left: "-24px",
                              }}
                            >
                              <div
                                className="tooltip-close"
                                onClick={() => setTooltip2(false)}
                              ></div>
                              The highlights of your journey create a powerful
                              impact by showcasing your accomplishments, growth,
                              and potential. They provide insight into your
                              professional trajectory and demonstrate the value
                              you bring to the table.
                            </div>
                            <div
                              className="d-flex align-items-center"
                              style={{ paddingBottom: "12px" }}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={info}
                                onClick={() => setTooltip2(!tooltip2)}
                                className="pe-2"
                                alt=""
                              />
                              <p className="information-text">
                                What impact would key learnings create
                              </p>
                            </div>
                          </div>
                          <p
                            className="m-0"
                            style={{
                              color: "#808080",
                              textAlign: "right",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: "600",
                              lineHeight: "16px",
                            }}
                          >
                            Look at examples
                          </p>
                        </div>
                        <div className="d-flex pb-2 align-items-center justify-content-between">
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text p-0"
                            onClick={AddCertificate1}
                          >
                            <img src={plush} className="pe-2" alt="" />
                            Add certificate
                          </button>
                          <button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="plush-text p-0"
                            disabled={certificate1.length === 1}
                            onClick={() => DeleteCertificate1(index)}
                          >
                            <img src={Delete} className="pe-2" alt="" />
                            Delete
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="text-center pb-4">
                <button className="back-btn" onClick={() => navigate(-1)}>
                  Back
                </button>
                <button className="save-btn" onClick={handleOnSave}>
                  Save and Continue
                </button>
                <NavLink to={"/build-resume"}>
                  <button className="skip-btn">Skip for now</button>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
      )}

      {dateModal ? (
        <div className="calender-modal">
          <div className="calender-box-2">
            <button className="close-btn" onClick={handleDateOpen}>
              <img src={closeBtn} alt="" />
            </button>
            <div className="text-center">
              <h6 className="calender-title m-0">Choose date slot(s)</h6>
              <div className="calender">
                <Calendar
                  onChange={(e) =>
                    setAcademicData({
                      ...academicData,
                      ["dob"]: moment(e).format("DD-MM-YYYY"),
                    })
                  }
                  onClick={handleDateOpen}
                  value={dateBirth}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default JobEdit;
