import React, { useState } from "react";
import "../../pages/css/login.css";
import cap from "../../assets/login/cap.svg";
import img1 from "../../assets/login/ats-logo.svg";
import img2 from "../../assets/login/apply-logo.svg";
import img3 from "../../assets/login/skill-logo.svg";
import img4 from "../../assets/login/up-skills.svg";
import eyesHide from "../../assets/eyes-show.svg";
import eyes from "../../assets/eyes-hide.svg";
import google from "../../assets/login/google-icon.svg";
import arrow from "../../assets/login/left-arrow.svg";
import hoverArrow from "../../assets/login/left-arrow-hover.svg";
import { Link, json, useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import { emailRegex } from "../regexPatterns/RegexPatterns";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Loader from "../loader/Loader";
import LoginHeader from "../header/LoginHeader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";

const initialState = {
  email: "",
  password: "",
};
function RecruiterLogin() {
  const [showArrow, setShowArrow] = useState(false);
  const [formdata, setFormdata] = useState(initialState);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyes);
  const [phoneError, setPhoneError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [Radio, setRadio] = useState("");
  const [loader, setLoader] = useState(false);

  const handleToggle = (e) => {
    e.preventDefault();
    if (type === "password") {
      setIcon(eyesHide);
      setType("text");
    } else {
      setIcon(eyes);
      setType("password");
    }
  };
  const navigate = useNavigate();
  const {
    loginRecruiter,
    loginJobseeker,
    googleRecruierLogin,
    googleSeekLogin,
  } = useMain();

  const hendleSignin = async (e) => {
    e.preventDefault();

    if (Radio == "") {
      toast.info("Please select a profile.");
      return;
    }

    if (formdata?.email == "" || !emailRegex.test(formdata?.email)) {
      toast.info("Invalid email.");

      return;
    }

    if (formdata?.password == "" || formdata?.password.length < 6) {
      toast.info("Password must be 6 characters.");

      return;
    }

    if (Radio === "Recruiter") {
      setLoader(true);
      const signRecruiter = await loginRecruiter(formdata);
      localStorage.setItem("recruiterToken", signRecruiter?.data?.token);
      localStorage.setItem("recruiteId", signRecruiter?.data._id);
      localStorage.setItem(
        "recruiterData",
        JSON.stringify(signRecruiter?.data?.recruiter)
      );
      if (signRecruiter?.statusCode === 200) {
        toast.success("Login successful.");

        setLoader(false);
        navigate("/recruiter-dashboard");
        // navigate("/dummy-dashboard");
      } else {
        toast.error("Login failed.");

        setLoader(false);
      }
    } else if (Radio === "Job Seeker") {
      setLoader(true);
      const signUser = await loginJobseeker(formdata);
      console.log(signUser);
      localStorage.setItem("userToken", signUser?.data?.tokens?.accessToken);
      localStorage.setItem("userId", signUser?.data?.user?._id);
      localStorage.setItem("userData", JSON.stringify(signUser?.data?.user));
      localStorage.setItem("userName", JSON.stringify(signUser?.data?.name));
      if (signUser?.statusCode == 200) {
        toast.success("Login successful.");

        setLoader(false);
        navigate("/dashboard");
      } else {
        toast.error("Login failed.");

        setLoader(false);
      }
    } else {
      toast.info("Please try again later.");

      setLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
    if (name === "email") {
      if (value === "") {
        setEmailError("email is required");
      } else {
        setEmailError(null);
      }
    }
    if (name === "password") {
      if (value === "") {
        setPasswordError("password is required");
      } else {
        setPasswordError(null);
      }
    }
  };

  const googleLogin = async (data) => {
    if (Radio == "") {
      toast.info("Please select a profile.");

      return;
    }

    if (Radio === "Recruiter") {
      setLoader(true);
      const decode = jwtDecode(data?.credential);
      if (decode?.email_verified) {
        const result = await googleRecruierLogin({
          email: decode?.email,
          name: decode?.name,
        });
        if (result?.statusCode === 200) {
          setLoader(false);
          navigate("/recruiter-dashboard");
          localStorage.setItem("recruiteId", result?.data?.recruiter?._id);
          localStorage.setItem(
            "recruiterData",
            JSON.stringify(result?.data?.recruiter)
          );
          localStorage.setItem("recruiterToken", result?.data?.token);
        } else {
          toast.error("Something went wrong.");

          setLoader(false);
        }
      }
    }

    if (Radio === "Job Seeker") {
      setLoader(true);
      const decode = jwtDecode(data?.credential);
      if (decode?.email_verified) {
        const result = await googleSeekLogin({
          email: decode?.email,
          name: decode?.name,
        });
        if (result?.statusCode === 200) {
          setLoader(false);
          navigate("/dashboard");
          localStorage.setItem("userToken", result?.data?.tokens?.accessToken);
          localStorage.setItem("userId", result?.data?.user?._id);
          localStorage.setItem("userData", JSON.stringify(result?.data?.user));
        } else {
          toast.error("Something went wrong.");

          setLoader(false);
        }
      }
    }
  };

  return (
    <>
      <AlertContainer/>
    <LoginHeader btnName="Sign Up" link="/recruiter-sign-in" />
      {loader ? (
        <Loader />
      ) : (
        <section className="login-hero">
          <div className="login-container">
            {Radio === "Recruiter" ? (
              <div className="login-content">
                <h2 className="login-title">Work smart to land your</h2>
                <div className="span">
                  Perfect Candidate{" "}
                  <img src={cap} className="img-fluid" alt="" />
                </div>
                <div className="desktop-show-hide">
                  <p className="login-text m-0">
                    Here’s how we help you reach your goals faster.
                  </p>

                  <div className="login-map">
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img1} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Access to Industry standard resume
                      </p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img2} alt="" className="login-img" />
                      <p className="m-0 login-map-text">Free ATS</p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img3} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Application analytics for better results
                      </p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img4} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Add Interviewers & View Their Progress
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="login-content">
                <h2 className="login-title">Work smart to land your</h2>
                <div className="span">
                  Dream Job <img src={cap} className="img-fluid" alt="" />
                </div>
                <div className="desktop-show-hide">
                  <p className="login-text m-0">
                    Here’s how we help you reach your goals faster.
                  </p>
                  <div className="login-map">
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img1} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Access to Industry standard resume building
                      </p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img2} alt="" className="login-img" />
                      <p className="m-0 login-map-text">Easy apply </p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img3} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Application analytics for better results
                      </p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img4} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Skill upgradation assets and career assistance{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="sign-up">
              <div className="text-center">
                <h6 className="sign-title">Sign in</h6>
                <p className="sign-text">
                  Sign in to your account for endless possibilities.
                </p>
                <div className="d-flex w-100 justify-content-center">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      googleLogin(credentialResponse);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>

                <div
                  style={{
                    borderBottom: "1px solid #C7D2FE",
                    margin: "12px 0",
                  }}
                ></div>
                <div className="row pt-2 mb-2">
                  <div className="col-6">
                    <div className="radio-button">
                      <input
                        type="radio"
                        className="radio-button__input"
                        id="radio1"
                        onChange={(e) => setRadio(e.target.value)}
                        value="Job Seeker"
                        name="radio-group"
                      />
                      <label className="radio-button__label" htmlFor="radio1">
                        <span className="radio-button__custom"></span>
                        For Job Seeker
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="radio-button">
                      <input
                        type="radio"
                        value="Recruiter"
                        onChange={(e) => setRadio(e.target.value)}
                        className="radio-button__input"
                        id="radio2"
                        name="radio-group"
                      />
                      <label className="radio-button__label" htmlFor="radio2">
                        <span className="radio-button__custom"></span>
                        For Recruiter
                      </label>
                    </div>
                  </div>
                </div>
                <form>
                  <div>
                    <input
                      type="email"
                      placeholder="Email address"
                      className={
                        emailError === null ? "sign-input" : "sign-input-error"
                      }
                      name="email"
                      value={formdata?.email}
                      onChange={handleChange}
                    />
                    {emailError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{emailError}</p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="pass"
                      className={
                        passwordError === null
                          ? "sign-input"
                          : "sign-input-error"
                      }
                    >
                      <input
                        type={type}
                        placeholder="Password"
                        id="pass"
                        style={{
                          border: "none",
                          outline: "none",
                          width: "100%",
                          left: "0",
                        }}
                        name="password"
                        value={formdata?.password}
                        onChange={handleChange}
                      />
                      <button
                        className="show-password-btn"
                        onClick={handleToggle}
                      >
                        <img src={icon} alt="" />
                      </button>
                    </label>
                    {passwordError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{passwordError}</p>
                    )}
                  </div>
                  <button
                    className="sign-up-btn"
                    onMouseEnter={() => setShowArrow(true)}
                    onMouseLeave={() => setShowArrow(false)}
                    onClick={hendleSignin}
                  >
                    Sign in
                    <img
                      src={showArrow ? hoverArrow : arrow}
                      style={{ marginLeft: "4px" }}
                      alt=""
                    />
                  </button>
                  <p className="link-btn m-0">
                    Don’t have an account?
                    <Link to="/sign-up-switch">
                      <span style={{ color: "#11274B" }}>Sign-up</span>
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default RecruiterLogin;
