import React, { useEffect, useState } from "react";
import closeBtn from "../../../assets/blue-close-btn.svg";
import "../../../dashboard/dashboard.css";
// import leftArrow from "../assets/left-arrow.svg";
import box from "../../../assets/office-building.png";
import all from "../../../assets/all.svg";
import sendBtn from "../../../assets/modal-send-btn.svg";
import emojiBtn from "../../../assets/chat-modal/emoji-btn.svg";
import linkBtn from "../../../assets/chat-modal/link-btn.svg";
import calenderBtn from "../../../assets/chat-modal/calender-btn.svg";
import { useMain } from "../../../hook/useMain";
import moment from "moment";

function RecruiterChat({ setToggle, userId, interviewerId }) {
  const [chat, setChat] = useState([]);
  return (
    <>
      <div className="chat-modal">
        <div className="chat-filter-box">
          <button className="close-btn">
            <img src={closeBtn} alt="" onClick={() => setToggle("")} />
          </button>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <img src={box} alt="" style={{ paddingRight: "8px" }} />
              <div>
                <h6 className="chat-modal-title m-0">Human Resources</h6>
                <p className="chat-modal-text m-0">Youth Empower Foundation</p>
              </div>
            </div>
            <div className="d-flex">
              <button className="chat-modal-btn">Report</button>
              <button className="chat-modal-btn">Delete Chat</button>
              <button className="chat-modal-btn pe-0">
                <img src={all} alt="" />
              </button>
            </div>
          </div>
          <div className="chat-modal-border"></div>
          <div className="text-center">
            {/* <p className="m-0 chat-modal-date">5th Aug, 2020</p> */}
            <div
              className="mb-5"
              style={{
                height: "380px",
                overflow: "auto",
                scrollbarWidth: "none",
              }}
            >
              {chat?.length > 0 &&
                chat?.map((item, index) => (
                  <div
                    className="chat-modal-container"
                    style={{ height: "max-content" }}
                  >
                    {item?.receiver === "user" && (
                      <div
                        className="chat-message-right"
                        style={{ width: "max-content" }}
                      >
                        <p className="m-0">{item?.content}</p>
                        <p className="chat-message-time m-0">
                          {moment(item?.time).format("h:mm A")}
                        </p>
                      </div>
                    )}

                    {item?.receiver === "interviewer" && (
                      <div
                        className="chat-message-left"
                        style={{ width: "max-content" }}
                      >
                        <p className="m-0">{item?.content}</p>
                        <p className="chat-message-time m-0">
                          {moment(item?.time).format("h:mm A")}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <div className="send-message-input-box">
              <div className="send-message-input">
                <textarea
                  name="message"
                  id=""
                  cols="10"
                  rows="1"
                  className="send-message w-100"
                  style={{ resize: "none" }}
                ></textarea>
                <button className="send-btn">
                  <img src={sendBtn} alt="" />
                </button>
              </div>
              <button className="emoji-btn">
                <img src={emojiBtn} alt="" />
              </button>
              <button className="emoji-btn">
                <img src={linkBtn} alt="" />
              </button>
              <button className="emoji-btn">
                <img src={calenderBtn} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // }

  // return null;
}

export default RecruiterChat;

{
  /* <div className="chat-modal-container">
<div className="chat-message-left">
  <p className="m-0">
    Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
    et velit interdum, ac
  </p>
  <p className="chat-message-time m-0">02:00 PM</p>
</div>
<div className="chat-message-right">
  <p className="m-0">
    Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
    v ulputate libero et velit interdum, ac alique
  </p>
  <p className="chat-message-time m-0">04:00 PM</p>
</div>
<div className="chat-message-left">
  <p className="m-0">
    Porem ipsum dolor sit amet, consectetur adipiscing elit.
  </p>
</div>
<div className="chat-message-left">
  <p className="m-0">
    Nunc vulputate libero et velit interdum, ac aliquet odio
    mattis..
  </p>
  <p className="chat-message-time m-0">04:01 PM</p>
</div>
<div className="chat-message-right">
  <p className="m-0">
    Nunc vulputate libero et velit interdum, ac aliquet
  </p>
  <p className="chat-message-time m-0">04:02 PM</p>
</div>
</div> */
}
