import React, { useContext } from "react";
import "./recruiterMenu.css";
import MobileContext from "../../context/mobile/MobileContext";
import { Link, useLocation } from "react-router-dom";
import dashboard from "../../assets/recruiter-subHeader/dashboard.svg";
import Automate from "../../assets/recruiter-subHeader/automate.svg";
import Calender from "../../assets/recruiter-subHeader/calender.svg";
import all from "../../assets/recruiter-subHeader/all.svg";
import ActiveAll from "../../assets/recruiter-subHeader/light-all.svg";

function InterviewSubMenu() {
  const menuItem = useContext(MobileContext);
  const { recruiterMenu } = menuItem;
  let path = useLocation();
  let pathName = path.pathname;

  return (
    <>
      <div
        className="mobile-recruiter-menu-box"
        style={{
          height: recruiterMenu ? "0px" : "auto",
          overflow: recruiterMenu ? "hidden" : "visible",
          padding: recruiterMenu ? "0" : "20px 22px 0 22px",
        }}
      >
        <div className="mobile-recruiter-menu" style={{paddingBottom: "30px"}}>
          <Link
            to="/interview/all-application"
            className={`${
              pathName === "/interview/all-application"
                ? "sub-active"
                : "sub-header-dashboard-btn"
            } `}
          >
            <img
              src={pathName === "/interview/all-application" ? ActiveAll : all}
              style={{ marginRight: "4px" }}
              alt=""
            />
           Application
          </Link>
          <Link
            to=""
            className={`${
              pathName === ""
                ? "sub-active"
                : "sub-header-dashboard-btn"
            } `}
          >
            <img
              src={Automate}
              style={{ marginRight: "4px" }}
              alt=""
            />
           Automate
          </Link>
          <Link
            to="/interview/calender"
            className={`${
              pathName === "/interview/calender"
                ? "sub-active"
                : "sub-header-dashboard-btn"
            } `}
          >
            <img
              src={Calender}
              style={{ marginRight: "4px" }}
              alt=""
            />
            Calender
          </Link>
        </div>
        <div className="mobile-recruiter-menu-wrapper"></div>
      </div>
    </>
  );
}

export default InterviewSubMenu;
