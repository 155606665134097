import React from "react";
import Header from "../header/Header";
import './resume.css'
import { useNavigate } from "react-router-dom";

function ResumeBuilder() {
    const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="resume-page">
        <div className="resume-box">
          <p className="resume-text">How would you like to build profile?</p>
          <div className="resume-btn-box" style={{flexDirection: "column"}}>
            <button className="resume-back-btn"  >Build my resume</button>
            {/* <button className="resume-btn">Upload my resume</button> */}
            <button className="resume-btn" onClick={() => navigate('/resume-builder/templates')}>View templates</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResumeBuilder;
