import React, { useContext } from "react";
import Header from "../components/header/Header";
import Sidebar from "../components/sideBar/Sidebar";
import SideBarContext from "../context/SidebarContext";
import UpSkillSlider from "./UpSkillSlider";
import "./dashboard.css";
import MobileMenu from "../components/mobileMenu/MobileMenu";
import MobileContext from "../context/mobile/MobileContext";
import jobIcon from "../assets/job-icon.svg";
import top from "../assets/top-arrow.svg";


function UpSkillPage() {
  const sidebar = useContext(SideBarContext);
  const menuItem = useContext(MobileContext);
  const { isSidebarOpen } = sidebar;
  const { handleMobileToggle, mobileMenu } = menuItem;
  return (
    <>
      <Header />
      <section className="dashboard">
        <div className="desktop-show-hide">
          <div className="dashboard-box">
          <div className="sidebar-scroll">
            <Sidebar />
          </div>
            <div
              className="up-skill-box"
              style={{ left: isSidebarOpen ? "85px" : "354px" }}
            >
              <h6 className="up-skill-page-title">Recommended Courses</h6>
              <UpSkillSlider />
            </div>
          </div>
        </div>
        <div className="mobile-show-hide mobile-box">
          <h6 className="mobile-track-title mb-3">Recommended Courses</h6>
          <UpSkillSlider />
          <MobileMenu />
          <div className="mobile-menu-btn" onClick={handleMobileToggle}>
            <img
              src={top}
              alt=""
              style={{
                transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 150ms ease",
                height: "14px",
              }}
            />
            <img src={jobIcon} alt="" />
          </div>
        </div>
      </section>
    </>
  );
}

export default UpSkillPage;
