import React, { useContext } from "react";
import AllModalContext from "../../context/allModal/AllModalContext";
import CompleteImg from "../../assets/recruiter-dashboard/complete-btn.svg";
import './account.css'

function HelpModal() {
  const modal = useContext(AllModalContext);
  const { helpModal, toggleHelp } = modal;
  if (helpModal) {
    return (
      <>
        <div className="help-modal">
          <div className="help-modal-box">
            <img src={CompleteImg} alt="" />
            <div className="text-center">
              <h6 className="help-modal-title">Form Submitted Successfully</h6>
              <p className="help-modal-text">
                Thank you for your feedback! <br /> Our executives will review it and
                get back you to soon.
              </p>
            </div>
            <button className="help-close-btn" onClick={toggleHelp}>Close</button>
          </div>
        </div>
      </>
    );
  }

  return null;
}

export default HelpModal;
