import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import "./recruiter.css";
import box from "../../assets/recruiter-dashboard/box.svg";
import star from "../../assets/recruiter/table-star.svg";
import SubHeader from "../subHeader/SubHeader";
import RecruiterSubMenu from "../mobileMenu/RecruiterSubMenu";
import MobileContext from "../../context/mobile/MobileContext";
import jobIcon from "../../assets/recruiter-dashboard/mobile-icon.svg";
import top from "../../assets/top-arrow.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";
import RecruiterHeader from "../header/RecruiterHeader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";

function AllAppDetail() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const { id } = useParams();
  const token = localStorage.getItem("recruiterToken");

  useEffect(() => {
    if (id && token) {
      getData(id, token);
    }
  }, [id, token]);

  // const navigate = useNavigate();
  const [post, setPost] = useState({});
  const [loader, setLoader] = useState(false);
  const { getPostById, makePost, deletePost, closePost } = useMain();

  const getData = async (id, token) => {
    setLoader(true);
    const res = await getPostById(id, token);
    if (res?.statusCode === 200) {
      setLoader(false);
      setPost(res?.data);
    } else {
      setLoader(false);
    }
  };

  const handlePost = async (id) => {
    if (token) {
      const res = await makePost(id, token);
      if (res?.statusCode === 200) {
        toast.success("Job posted successfully.");
        navigate("/dashboard/all-application");
      } else {
        toast.error("Job post failed.");
      }
    } else {
      toast.info("Session expired. Please log in.");
    }
  };

  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  // console.log(post);
  const menuItem = useContext(MobileContext);
  const { handleRecruiterToggle, recruiterMenu } = menuItem;

  const handleDelete = async (id) => {
    if (id) {
      setLoader(true);
      const result = await deletePost(id);
      if (result?.statusCode === 200) {
        toast.success(result?.message);
        setLoader(false);
        navigate("/dashboard/all-application");
      } else {
        toast.error("Something went wrong.");
        setLoader(false);
      }
    }
  };
  const handleClose = async (id) => {
    if (id) {
      setLoader(true);
      const result = await closePost(id);
      if (result?.statusCode === 200) {
        toast.success(result?.message);
        setLoader(false);
        navigate("/dashboard/all-application");
      } else {
        toast.error("Something went wrong.");
        setLoader(false);
      }
    }
  };

  return (
    <>
      <AlertContainer />
      <RecruiterHeader title="New Job Posting" search={false} />
      <div className="desktop-show-hide">
        <SubHeader />
      </div>
      {loader && <Loader />}
      <div className="recruiter-container desktop-show-hide">
        <div className="recruiter-detail">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img
                src={
                  JSON.parse(localStorage.getItem("recruiterData"))?.companyLogo
                    ? JSON.parse(localStorage.getItem("recruiterData"))
                        ?.companyLogo
                    : box
                }
                alt=""
                style={{ width: "45px", height: "45px" }}
              />
              <div style={{ marginLeft: "16px" }}>
                <h6 className="post-review-title">{post?.jobInfo?.position}</h6>
                <p className="post-review-text">
                  {post?.companyName} . {timeAgo(post.createdAt)}
                </p>
              </div>
            </div>
            <div className="d-flex">
              <Link to={`/dashboard/new-job/${post?._id}`}>
                <button
                  className="remove-btn"
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    padding: "6px 12px",
                    width: "70px",
                    height: "36px",
                    marginRight: "14px",
                  }}
                >
                  Edit
                </button>
              </Link>
              <button
                className="invite-btn"
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  padding: "6px 12px",
                  width: "70px",
                  height: "36px",
                }}
                onClick={(e) => handlePost(post?._id)}
              >
                Post
              </button>
            </div>
          </div>
          <ul style={{ marginTop: "22px", paddingLeft: "16px" }}>
            <li className="post-review-list">
              {post?.jobInfo?.jobType} · {post?.jobInfo?.workPlaceType}
            </li>
            <li className="post-review-list">
              {post?.userId?.companyStrength} employees ·{" "}
              {post?.userId?.industry}
            </li>
            {/* <li className="post-review-list">
              See how you compare to 843 applicants.
            </li>
            <li className="post-review-list">
              Skills: Wireframing, User Experience (UX), +8 more
            </li>
            <li className="post-review-list">
              Skills: Wireframing, User Experience (UX), +8 more
            </li> */}
          </ul>
          <div className="d-flex flex-column" style={{ gap: "12px" }}>
            <h6 className="post-job-review-title">About Company</h6>
            <p className="post-job-review-text m-0">
              {post?.about?.split("\n")?.map((e) => (
                <>
                  {e}
                  <br />
                </>
              ))}
            </p>
            <h6 className="post-job-review-title">Job Brief</h6>
            <p className="post-job-review-text m-0">
              {post?.jobInfo?.jobBrief?.split("\n")?.map((e) => (
                <>
                  {e}
                  <br />
                </>
              ))}
            </p>
            <h6 className="post-job-review-title" style={{ fontSize: "10px" }}>
              Responsibilities
            </h6>
            <p className="post-job-review-text m-0">
              The incumbents overall responsibilities will include, but are not
              limited to, the following:
              <ul style={{ paddingLeft: "16px", marginBottom: "0" }}>
                {post?.jobInfo?.responsibility?.split("\n")?.map((e, i) => (
                  <li className="post-review-list" key={`responsibility-${i}`}>
                    {e}
                  </li>
                ))}
              </ul>
            </p>
            <h6 className="post-job-review-title" style={{ fontSize: "10px" }}>
              Qualification
            </h6>
            <p className="post-job-review-text m-0">
              <ul style={{ paddingLeft: "16px", marginBottom: "0" }}>
                {post?.jobInfo?.qualification?.split("\n")?.map((e, i) => (
                  <li className="post-review-list" key={`qualification-${i}`}>
                    {e}
                  </li>
                ))}
              </ul>
            </p>
            <h6 className="post-job-review-title" style={{ fontSize: "10px" }}>
              Benefits
            </h6>
            <p className="post-job-review-text m-0">
              <ul style={{ paddingLeft: "16px", marginBottom: "0" }}>
                {post?.jobInfo?.benefits?.split("\n")?.map((e, i) => (
                  <li className="post-review-list" key={`banefits-${i}`}>
                    {e}
                  </li>
                ))}
                {/* <li className="post-review-list">Flexible leaves</li>
                <li className="post-review-list">Paid vacations</li> */}
              </ul>
            </p>
            <h6 className="post-job-review-title" style={{ fontSize: "10px" }}>
              Learn more about the company
            </h6>
            <div className="row">
              <div className="col-2">
                <h6 className="preview-link-title">Website</h6>
                <p className="preview-link">{post?.weblink}</p>
                <h6 className="preview-link-title">Industry</h6>
                <p className="preview-link">{post?.industry}</p>
                <h6 className="preview-link-title">Head Quarters</h6>
                <p className="preview-link">{post?.headQuater}</p>
              </div>
              <div className="col-2">
                <h6 className="preview-link-title">Phone Number</h6>
                <p className="preview-link">{post?.mobileNo}</p>
                <h6 className="preview-link-title">Company Size</h6>
                <p className="preview-link">
                  {post?.companyStrength} employess
                </p>
                <h6 className="preview-link-title">Founded</h6>
                <p className="preview-link">{post?.yearFounded}</p>
              </div>
              {/* <div className="col-2">
                <h6 className="preview-link-title">Company rating</h6>
                <p
                  style={{
                    margin: "0",
                    color: "#11274B",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "16px",
                  }}
                >
                  <img
                    src={star}
                    alt=""
                    style={{ height: "25px", width: "25px" }}
                  />{" "}
                  4.5/5
                </p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="d-flex w-100" style={{ gap: "16px", marginTop: "8px" }}>
          <button
            style={{
              color: "#475569",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              padding: "6px 12px",
              lineHeight: "20px",
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={() => handleDelete(post?._id)}
          >
            Delete Application
          </button>
          <button
            style={{
              color: "#475569",
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              padding: "6px 12px",
              lineHeight: "20px",
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={() => handleClose(post?._id)}
          >
            Close Application
          </button>
        </div>
      </div>
      <div className="mobile-show-hide">
        <div
          className="recruiter-container"
          style={{ marginTop: "80px", padding: "12px" }}
        >
          <div className="recruiter-detail">
            <div className="d-flex align-items-center">
              <img
                src={
                  JSON.parse(localStorage.getItem("recruiterData"))?.companyLogo
                    ? JSON.parse(localStorage.getItem("recruiterData"))
                        ?.companyLogo
                    : box
                }
                alt=""
                style={{ width: "45px", height: "45px" }}
              />
              <div style={{ marginLeft: "16px" }}>
                <h6 className="post-review-title">{post?.jobInfo?.position}</h6>
                <p className="post-review-text">
                  {post?.companyName} . {timeAgo(post.createdAt)}
                </p>
              </div>
            </div>
            <div
              style={{ borderBottom: "1px solid #E2E8F0", margin: "8px 0" }}
            ></div>
            <ul className="m-0" style={{ paddingLeft: "16px" }}>
              <li className="post-review-list">
                {post?.jobInfo?.jobType} · {post?.jobInfo?.workPlaceType}
              </li>
              <li className="post-review-list">
                {post?.userId?.companyStrength} employees ·{" "}
                {post?.userId?.industry}
              </li>
              {/* <li className="post-review-list">
                See how you compare to 843 applicants.
              </li>
              <li className="post-review-list">
                Skills: Wireframing, User Experience (UX), +8 more
              </li>
              <li className="post-review-list">
                Skills: Wireframing, User Experience (UX), +8 more
              </li> */}
            </ul>
            <div
              style={{ borderBottom: "1px solid #E2E8F0", margin: "8px 0" }}
            ></div>
            <div className="d-flex flex-column" style={{ gap: "8px" }}>
              <h6 className="post-job-review-title">About Company</h6>
              <p className="post-job-review-text m-0">
                {post?.about?.split("\n")?.map((e) => (
                  <>
                    {e}
                    <br />
                  </>
                ))}
              </p>
              <h6 className="post-job-review-title">Job Brief</h6>
              <p className="post-job-review-text m-0">
                {post?.jobInfo?.jobBrief?.split("\n")?.map((e) => (
                  <>
                    {e}
                    <br />
                  </>
                ))}
              </p>
              <h6
                className="post-job-review-title"
                style={{ fontSize: "10px" }}
              >
                Responsibilities
              </h6>
              <p className="post-job-review-text m-0">
                The incumbents overall responsibilities will include, but are
                not limited to, the following:
                <ul style={{ paddingLeft: "16px", marginBottom: "0" }}>
                  {post?.jobInfo?.responsibility?.split("\n")?.map((e, i) => (
                    <li
                      className="post-review-list"
                      key={`responsibility-${i}`}
                    >
                      {e}
                    </li>
                  ))}
                </ul>
              </p>
              <h6
                className="post-job-review-title"
                style={{ fontSize: "10px" }}
              >
                Qualification
              </h6>
              <p className="post-job-review-text m-0">
                <ul style={{ paddingLeft: "16px", marginBottom: "0" }}>
                  {post?.jobInfo?.qualification?.split("\n")?.map((e, i) => (
                    <li className="post-review-list" key={`qualification-${i}`}>
                      {e}
                    </li>
                  ))}
                </ul>
              </p>
              <h6
                className="post-job-review-title"
                style={{ fontSize: "10px" }}
              >
                Benefits
              </h6>
              <p className="post-job-review-text m-0">
                <ul style={{ paddingLeft: "16px", marginBottom: "0" }}>
                  {post?.jobInfo?.benefits?.split("\n")?.map((e, i) => (
                    <li className="post-review-list" key={`banefits-${i}`}>
                      {e}
                    </li>
                  ))}
                  {/* <li className="post-review-list">Flexible leaves</li>
                <li className="post-review-list">Paid vacations</li> */}
                </ul>
              </p>
            </div>
          </div>
          <div className="recruiter-detail" style={{ marginTop: "8px" }}>
            <h6 className="post-job-review-title" style={{ fontSize: "10px" }}>
              Learn more about the company
            </h6>
            <div className="row">
              <div className="col-4">
                <h6 className="preview-link-title">Website</h6>
                <p className="preview-link">{post?.weblink}</p>
                <h6 className="preview-link-title">Industry</h6>
                <p className="preview-link">{post?.userId?.industry}</p>
                <h6 className="preview-link-title">Head Quarters</h6>
                <p className="preview-link">{post?.userId?.headQuater}</p>
              </div>
              <div className="col-4">
                <h6 className="preview-link-title">Phone Number</h6>
                <p className="preview-link">{post?.mobileNo}</p>
                <h6 className="preview-link-title">Company Size</h6>
                <p className="preview-link">
                  {post?.userId?.companyStrength} employess
                </p>
                <h6 className="preview-link-title">Founded</h6>
                <p className="preview-link">{post?.userId?.yearFounded}</p>
              </div>
              {/* <div className="col-4">
                <h6 className="preview-link-title">
                  Company rating <img src={star} alt="" />
                </h6>
                <p className="preview-link">View company reviews</p>
              </div> */}
            </div>
            <RecruiterSubMenu />
            <div className="mobile-menu-btn" onClick={handleRecruiterToggle}>
              <img
                src={top}
                alt=""
                style={{
                  transform: recruiterMenu ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "transform 150ms ease",
                  height: "14px",
                }}
              />
              <img src={jobIcon} style={{ height: "24px" }} alt="" />
            </div>
          </div>
          <div
            className="d-flex flex-column align-items-start w-100"
            style={{ gap: "4px", marginTop: "8px" }}
          >
            <button
              style={{
                color: "#475569",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                padding: "6px 12px",
                lineHeight: "20px",
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => handleDelete(post?._id)}
            >
              Delete Application
            </button>
            <button
              style={{
                color: "#475569",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                padding: "6px 12px",
                lineHeight: "20px",
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => handleClose(post?._id)}
            >
              Close Application
            </button>
          </div>
        </div>
        <div className="bottom-box">
          <button
            className="bottom-preview-btn"
            onClick={(e) => handlePost(post?._id)}
          >
            Post
          </button>
          <Link
            to={`/dashboard/new-job/${post?._id}`}
            className="bottom-clear-btn text-decoration-none text-center"
          >
            Edit
          </Link>
          {/* <button
            className="bottom-clear-btn text-center"
            onClick={() => handleClose(post?._id)}
          >
            Close
          </button> */}
          {/* <button
            className="bottom-preview-btn"
            onClick={() => handleDelete(post?._id)}
          >
            Delete
          </button> */}
        </div>
      </div>
    </>
  );
}

export default AllAppDetail;
