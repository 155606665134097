import React, { useEffect, useState } from "react";
import box from "../assets/office-building.png";
import save from "../assets/sideBar/save.svg";
import save2 from "../assets/save-btn.svg";

import left from "../assets/left-arrow.svg";
import { useNavigate } from "react-router-dom";
import { useMain } from "../hook/useMain";
import "./dashboard.css";
import Loader from "../components/loader/Loader";
import { toast } from "react-toastify";

function MBusinessCard({data}) {
  // useEffect(() => {
  //   getData();
  // }, []);
  const navigate = useNavigate();
  // const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [sort, setSort] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const { getAllUserPost, saveJob, sidebarSearch } = useMain();

  // const getData = async () => {
  //   setLoader(true);
  //   const res = await getAllUserPost();
  //   if (res?.statusCode === 200) {
  //     setLoader(false);
  //     if (userData) {
  //       const initialData = [];
  //       const filteredData = res?.data?.data?.filter((item) => {
  //         const jobInfo = item.jobInfo;
  //         const isCityIndore = item.headQuater === userData?.city;
  //         const isJobTitleDeveloper = jobInfo.position
  //           .toLowerCase()
  //           .includes(userData?.jobTitle);
  //         const hasJobPreference =
  //           jobInfo?.jobType &&
  //           jobInfo?.jobType
  //             .toLowerCase()
  //             .includes(
  //               userData?.jobPreference.map((item) => item.toLowerCase())
  //             );
  //         if (isCityIndore || isJobTitleDeveloper || hasJobPreference) {
  //           return isCityIndore || isJobTitleDeveloper || hasJobPreference;
  //         } else {
  //           initialData.push(item);
  //         }
  //       });
  //       setData([...filteredData, ...initialData]);
  //       setSort([...filteredData, ...initialData]);
  //     }
  //   } else {
  //     setLoader(false);
  //   }
  // };

  const handleSave = async (jobid) => {
    setLoader(true);
    const { _id } = JSON.parse(localStorage.getItem("userData"));
    if (_id && jobid) {
      const res = await saveJob(jobid, { userId: _id });
      if (res?.statusCode == 200) {
        toast.success("Job saved successfully.");

        localStorage.setItem("userData", JSON.stringify(res?.data));
        setLoader(false);
      } else {
        toast.success("Job saved successfully.");

        setLoader(false);
      }
    }
  };
  function handleOnApply(jobId) {
    navigate(`/dashboard/job-description/${jobId}`);
  }

  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }

  const formatToCurrency = (number) => {
    if (number >= 1000 && number < 10000) {
      return `₹${number}`;
    } else if (number >= 100000 && number < 10000000) {
      return `₹${(number / 100000).toFixed(0)}L`;
    } else if (number >= 10000000) {
      return `₹${(number / 100000).toFixed(0)}C`;
    } else {
      return `₹${number}`;
    }
  };

  useEffect(() => {
    filteredDrafts(sidebarSearch);
  }, [sidebarSearch]);

  const filteredDrafts = (sidebarSearch) => {
    const filterVal =
      sort &&
      sort.length > 0 &&
      sort?.filter((item) => {
        const regex = new RegExp(sidebarSearch, "i"); // 'i' for case-insensitive search
        return (
          sidebarSearch.trim() === "" ||
          Object.values(item.jobInfo).some((value) => regex.test(String(value)))
        );
      });
    // setData(filterVal);
  };

  return (
    <>
      {loader && <Loader />}
      {data &&
        data?.length > 0 &&
        data?.map((job, index) => (
          <div className="mobile-business-card " key={index}>
            <div className="d-flex align-items-center justify-content-between">
              <button className="business-actively">Actively hiring</button>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img
                  src={
                    job?.userId?.companyLogo ? job?.userId?.companyLogo : box
                  }
                  alt=""
                  style={{ height: "25px", width: "25px" }}
                />
                <div className="ps-3">
                  <h6 className="business-title m-0">
                    {job?.jobInfo?.position}
                  </h6>
                  <p className="business-text m-0">{job?.companyName}</p>
                </div>
              </div>
              <p className="m-0 business-m-hour">{timeAgo(job?.createdAt)}</p>
            </div>
            <div className="business-card-border"></div>
            <ul className="pl-2 m-0">
              {/* <li className="save-item">Start up</li> */}
              <li className="save-item">{job?.jobInfo?.jobType}</li>
              {/* <li className="save-item">11-{job?.companyStrength} employees</li> */}
              <li className="save-item">{job?.jobInfo?.workPlace}</li>

              {job?.jobInfo?.salaryMax && job?.jobInfo?.salaryMin && (
                <li className="save-item">
                  {formatToCurrency(Number(job?.jobInfo?.salaryMin))} -
                  {formatToCurrency(Number(job?.jobInfo?.salaryMax))}
                </li>
              )}
            </ul>
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div
                className="mobile-apply-btn mt-0"
                style={{
                  backgroundColor: userData?.appliedjobs.includes(job?._id)
                    ? "green"
                    : null,
                  color: userData?.appliedjobs.includes(job?._id)
                    ? "white"
                    : null,
                }}
                onClick={() => handleOnApply(job._id)}
              >
                <button
                  style={{
                    color: userData?.appliedjobs.includes(job?._id)
                      ? "white"
                      : null,
                  }}
                >
                  {userData?.appliedjobs.includes(job?._id)
                    ? "Applied"
                    : "Apply"}
                </button>
                <img src={left} alt="" />
              </div>

              <button
                className="save-btn"
                style={{
                  borderRadius: "8px",
                  background: "#F1F5F9",
                  padding: "0px",
                }}
              >
                <button
                  className="save-btn"
                  onClick={() => handleSave(job?._id)}
                >
                  <img
                    src={userData?.savedjobs.includes(job?._id) ? save2 : save}
                    alt=""
                  />
                </button>
              </button>
            </div>
            {/* <div className="mobile-apply-btn" onClick={() => handleOnApply(job._id)}>
          <button>Apply</button>
          <img src={left} alt="" />
        </div> */}
          </div>
        ))}
    </>
  );
}

export default MBusinessCard;
