import React from "react";
import Slider from "react-slick";
import story1 from "../../assets/stories/img1.svg";
import story2 from "../../assets/stories/img2.svg";
import story3 from "../../assets/stories/img3.svg";
import story4 from "../../assets/stories/img4.svg";
import story5 from "../../assets/stories/img5.svg";
import story6 from "../../assets/stories/img6.svg";
import story7 from "../../assets/stories/img7.svg";
import story8 from "../../assets/stories/img8.svg";

function StoriesCard({ bgName, name, text, jobName, storyImg }) {
  return (
    <>
      <div className={`stories-card ${bgName}`}>
        <div className="d-flex align-items-center">
          <img src={storyImg} alt="" />
          <h6 className="story-name m-0">{name}</h6>
        </div>
        <p className="m-0 story-job-text">{jobName}</p>
        <p className="m-0 story-text">{text}</p>
      </div>
    </>
  );
}

function StoriesSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    autoplay: true,
    centerMode: true,
    centerPadding: "17%",
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1850,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "14%",
        },
      },
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "8%",
        },
      },
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "4%",
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "0%",
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "13%",
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "5%",
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "24%",
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "25%",
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "9%",
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "7%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "22%",
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "13%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "8%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <StoriesCard
            bgName="stories-bg1"
            name="Jayesh Bhaygaonkar"
            text="Recruitifie has been a game-changer for me. The resume making feature is top-notch and has helped me create a professional resume in no time."
            jobName="Job Seeker"
            storyImg={story1}
          />
        </div>
        <div>
          <StoriesCard
            bgName="stories-bg2"
            name="Shoaib Ramani"
            text="As a recruiter, the AI-based resume summary is a lifesaver. It helps me quickly understand a candidate’s profile without going through the entire resume."
            jobName="Recruiter"
            storyImg={story2}
          />
        </div>
        <div>
          <StoriesCard
            bgName="stories-bg3"
            name="Kaivalya Kalamse"
            text="The free ATS is very user-friendly and efficient. It has streamlined our recruitment process significantly."
            jobName="Recruiter"
            storyImg={story3}
          />
        </div>
        <div>
          <StoriesCard
            bgName="stories-bg1"
            name="Pooja Mundada"
            text="The application tracking feature is fantastic! It keeps me updated about the status of my job applications."
            jobName="Job Seeker"
            storyImg={story4}
          />
        </div>
        <div>
          <StoriesCard
            bgName="stories-bg2"
            name="Sanika Tekade"
            text="The internal career counsellors provided excellent guidance and helped me make informed career decisions."
            jobName="Recruiter"
            storyImg={story5}
          />
        </div>
        <div>
          <StoriesCard
            bgName="stories-bg3"
            name="Shubham Marathe"
            text="The career upskilling options are great! They have helped me enhance my skills and stay competitive in the job market."
            jobName="Job Seeker"
            storyImg={story6}
          />
        </div>
        <div>
          <StoriesCard
            bgName="stories-bg1"
            name="Varun Mundada"
            text="The customer service at Recruitifie is impressive. They are always ready to help and resolve any issues promptly."
            jobName="Recruiter"
            storyImg={story7}
          />
        </div>
        <div>
          <StoriesCard
            bgName="stories-bg2"
            name="Omkar Thorve"
            text="I love how Recruitifie keeps adding new features to improve the user experience. The platform is always evolving to meet our needs."
            jobName="Recruiter"
            storyImg={story8}
          />
        </div>
      </Slider>
    </>
  );
}

export default StoriesSlider;
