import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import Loader from "../loader/Loader";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import RecruiterHeader from "../header/RecruiterHeader";
// import Autocomplete from "react-autocomplete";
// import rightCheck from "../assets/select-item-check.svg";

function RecruiterSet() {
  const navigate = useNavigate();
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const [cityPreference, setCityPreference] = useState(
    JSON.parse(localStorage.getItem("recruiterData"))?.designation &&
      JSON.parse(localStorage.getItem("recruiterData"))?.designation
  );
  const [loader, setLoader] = useState(false);
  const recruiterName = JSON.parse(localStorage.getItem("recruiterData"));
  const id = JSON.parse(localStorage.getItem("recruiterData"))?._id;
  const { UpdateCity } = useMain();

  async function GetStarted(e) {
    e.preventDefault();
    setLoader(true);
    if (cityPreference) {
      const result = await UpdateCity({ designation:cityPreference }, id);
      if (result?.statusCode === 200) {
        setLoader(false);
        localStorage.setItem("recruiterData", JSON.stringify(result?.data));
        navigate("/recruiter-build");
        toast.success(result.message);
      } else {
        toast.error("somthing went wrong");
        setLoader(false);
      }
    } else {
      toast.info("Please select city");
      setLoader(false);
    }
  }

  return (
    <>
      <AlertContainer/>
      <RecruiterHeader />
      {loader ? (
        <Loader />
      ) : (
        <section className="job-section">
          <div className="set-job-box">
            <h6 className="set-job-title">Hi {recruiterName?.name}</h6>
            <p className="set-job-text">
              Build your profile for a seamless hiring experience
            </p>
            <div className="form-border"></div>
            <form>
              <div className="d-flex flex-column">
                <label htmlFor="" className="job-label">
                  Designation in Company
                </label>
                <input
                  type="text"
                  placeholder=" Designation in Company"
                  className="resume-input"
                  onChange={(e) => {
                    setCityPreference(e.target.value);
                  }}
                  value={cityPreference}
                />
                {/* <div
                  className="dropdown"
                  onMouseEnter={() => setHover1(true)}
                  onMouseLeave={() => setHover1(false)}
                >
                  <div
                    style={{ outline: isActive ? "2px solid #94A3B8" : "none" }}
                    className="dropdown-btn"
                    onClick={() => {
                      setIsActive(!isActive);
                    }}
                  >
                    {search ? (
                      selected
                    ) : (
                      <input
                        type="text"
                        placeholder="Choose City"
                        className="search-input-box"
                        onChange={(e) => {
                          setCityPreference(e.target.value);
                          setSearch(false);
                        }}
                        value={cityPreference}
                      />
                    )}
                    <img src={hover1 ? downArrowHover : downArrow} alt="" />
                  </div>
                  <div
                    className="dropdown-content"
                    style={{ display: isActive ? "block" : "none" }}
                  >
                    {search
                      ? selectItem.map((element, index) => {
                          return (
                            <div
                              onClick={(e) => {
                                setIsSelected(e.target.textContent);
                                setSearch(!search);
                                setIsActive(!isActive);
                              }}
                              className="item"
                            >
                              {element.option}
                            </div>
                          );
                        })
                      : result.map((element, index) => {
                          return (
                            <div
                              key={index}
                              onClick={(e) => {
                                setIsSelected(e.target.textContent);
                                setSearch(!search);
                                setIsActive(!isActive);
                              }}
                              className="item"
                            >
                              {element.item.option}
                            </div>
                          );
                        })} */}
                {/* </div> */}
                {/* </div> */}
              </div>
              <button
                type="submit"
                className="set-job-btn"
                onClick={GetStarted}
              >
                Get Started
              </button>
            </form>
          </div>
        </section>
      )}
    </>
  );
}

export default RecruiterSet;
