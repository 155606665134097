import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import cap from "../assets/login/cap.svg";
import img1 from "../assets/login/ats-logo.svg";
import img2 from "../assets/login/apply-logo.svg";
import img3 from "../assets/login/skill-logo.svg";
import eyesHide from "../assets/eyes-show.svg";
import eyes from "../assets/eyes-hide.svg";
import img4 from "../assets/login/up-skills.svg";
import google from "../assets/login/google-icon.svg";
import arrow from "../assets/login/left-arrow.svg";
import hoverArrow from "../assets/login/left-arrow-hover.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMain } from "../hook/useMain";
import "./css/login.css";
import {
  emailRegex,
  nameRegex,
  phoneRegex,
} from "../components/regexPatterns/RegexPatterns";
import Loader from "../components/loader/Loader";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import LoginHeader from "../components/header/LoginHeader";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";

function SignIn() {
  const [showArrow, setShowArrow] = useState(false);
  const initialState = {
    name: "",
    phone: "",
    email: "",
    password: "",
  };
  // const [Radio, setRadio] = useState("Recruiter");

  const [formData, setFormData] = useState(initialState);
  const [loader, setLoader] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyes);
  const [phoneError, setPhoneError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const navigate = useNavigate();
  const { SignInUser, googleSeekLogin, userFindByEmal } = useMain();
  const location = useLocation();

  useEffect(() => {
    // const searchParams = new URLSearchParams(location.search);
    // searchParams.forEach((value, key) => {
    //   console.log(`${key}: ${value}`);
    // });
    const params = new URLSearchParams(location.search);
    const name = params.get("Name") || "";
    let email12 = params.get("Email Address") || "";
    email12 = email12.replace(/%40/g, "@");
    const phone = params.get("Phone Number") || "";
    console.log(email12, name, phone, params);
    setFormData({
      ...formData,
      name,
      email:email12,
      phone,
    });
  }, []);
  

  const handleToggle = (e) => {
    e.preventDefault();
    if (type === "password") {
      setIcon(eyesHide);
      setType("text");
    } else {
      setIcon(eyes);
      setType("password");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "name") {
      if (value === "") {
        setNameError("Name is required");
      } else {
        setNameError(null);
      }
    }
    if (name === "phone") {
      if (value === "") {
        setPhoneError("Phone number is required");
      } else {
        setPhoneError(null);
      }
    }
    if (name === "email") {
      if (value === "") {
        setEmailError("Email is required");
      } else {
        setEmailError(null);
      }
    }
    if (name === "password") {
      if (value === "") {
        setPasswordError("password is required");
      } else {
        setPasswordError(null);
      }
    }
  };

  async function SignIn(e) {
    e.preventDefault();
    const { name, phone, email, password } = formData;
    if (name === "" || !nameRegex.test(name)) {
      setNameError("Name is required");
      return;
    }
    if (phone === "" || !phoneRegex.test(phone)) {
      setPhoneError("Please enter valid number");
      return;
    }
    if (email === "" || !emailRegex.test(email)) {
      setEmailError("Please enter valid email");
      return;
    }
    if (password === "" || password.length < 6) {
      setPasswordError("Password must be 6 characters");
      return;
    }

    if (name && phone && email && password) {
      setLoader(true);

      const data = {
        name,
        phone,
        email,
        password,
      };
      const result = await SignInUser(data);
      if (result?.statusCode === 200) {
        setLoader(false);
        console.log(result?.data?.user);
        localStorage.setItem("userToken", result?.data?.tokens?.accessToken);
        localStorage.setItem("userData", JSON.stringify(result?.data?.user));
        localStorage.setItem("userId", result?.data?.user?._id);
        localStorage.setItem("auth", true);
        localStorage.setItem(
          "userName",
          JSON.stringify(result?.data?.user?.name)
        );
        toast.success(result?.message);
        navigate("/dashboard");
        // navigate("/set-job");
      } else if (result?.statusCode === 400) {
        toast.info(result?.message);
        setLoader(false);
      } else {
        toast.error("somthing went wrong");
        setLoader(false);
      }
    } else {
      return toast.error("All fields are required.");
      setLoader(false);
    }
  }

  const googleLogin = async (data) => {
    setLoader(true);
    const decode = jwtDecode(data?.credential);
    if (decode?.email_verified) {
      const result = await userFindByEmal({ email: decode?.email });
      if (result?.statusCode == 200) {
        setLoader(false);
        return toast.info("Already have an account. Please login!");

      } else if (result?.statusCode === 404) {
        const result = await googleSeekLogin({
          email: decode?.email,
          name: decode?.name,
        });
        if (result?.statusCode === 200) {
          setLoader(false);
          setTimeout(() => {
            navigate("/user-update-mobile");
          }, 3000);
          localStorage.setItem("userToken", result?.data?.tokens?.accessToken);
          localStorage.setItem("userId", result?.data?.user?._id);
          localStorage.setItem("userData", JSON.stringify(result?.data?.user));
          // localStorage.setItem("userName", JSON.stringify(result?.data?.name));
          localStorage.setItem("auth", true);
        } else {
          toast.error("Somthing went wrong");
          setLoader(false);
        }
      }
    }
  };

  return (
    <>
      <AlertContainer />
      <LoginHeader btnName="Sign In" link="/log-in" showBtn={false} />
      {loader ? (
        <Loader />
      ) : (
        <section className="login-hero">
          <div className="login-container">
            <div className="login-content">
              <h2 className="login-title">Work smart to land your</h2>
              <div className="span">
                Dream Job <img src={cap} className="img-fluid" alt="" />
              </div>
              <div className="desktop-show-hide">
                <p className="login-text m-0">
                  Here’s how we help you reach your goals faster.
                </p>
                <div className="login-map">
                  <div className="d-flex align-items-center login-map-content">
                    <img src={img1} alt="" className="login-img" />
                    <p className="m-0 login-map-text">
                      Access to Industry standard resume building{" "}
                    </p>
                  </div>
                  <div className="d-flex align-items-center login-map-content">
                    <img src={img2} alt="" className="login-img" />
                    <p className="m-0 login-map-text">Easy apply </p>
                  </div>
                  <div className="d-flex align-items-center login-map-content">
                    <img src={img3} alt="" className="login-img" />
                    <p className="m-0 login-map-text">
                      Application analytics for better results
                    </p>
                  </div>
                  <div className="d-flex align-items-center login-map-content">
                    <img src={img4} alt="" className="login-img" />
                    <p className="m-0 login-map-text">
                      Skill upgradation assets and career assistance{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sign-up">
              <div className="text-center">
                <h6 className="sign-title">Sign up</h6>
                <p className="sign-text pb-2">
                  Ready to take the next step? Sign up today and embark on a
                  journey towards professional success
                </p>
                <div className="d-flex w-100 justify-content-center">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      googleLogin(credentialResponse);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
                <div className="form-border"></div>

                <form>
                  <div>
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      className={
                        nameError === null ? "sign-input" : "sign-input-error"
                      }
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {nameError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{nameError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone number"
                      maxLength={10}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className={
                        phoneError === null ? "sign-input" : "sign-input-error"
                      }
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {phoneError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{phoneError}</p>
                    )}
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder="Email address"
                      name="email"
                      className={
                        emailError === null ? "sign-input" : "sign-input-error"
                      }
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {emailError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{emailError}</p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="pass"
                      className={
                        passwordError === null
                          ? "sign-input"
                          : "sign-input-error"
                      }
                    >
                      <input
                        type={type}
                        placeholder="Password"
                        id="pass"
                        style={{
                          border: "none",
                          outline: "none",
                          width: "100%",
                          left: "0",
                        }}
                        name="password"
                        value={formData?.password}
                        onChange={handleChange}
                      />
                      <button
                        className="show-password-btn"
                        onClick={handleToggle}
                      >
                        <img src={icon} alt="" />
                      </button>
                    </label>
                    {passwordError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{passwordError}</p>
                    )}
                  </div>
                  {/* <div>
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      className={
                        passwordError === null
                          ? "sign-input"
                          : "sign-input-error"
                      }
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {passwordError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{emailError}</p>
                    )}
                  </div> */}
                  <button
                    type="submit"
                    className="sign-up-btn"
                    onMouseEnter={() => setShowArrow(true)}
                    onMouseLeave={() => setShowArrow(false)}
                    onClick={SignIn}
                  >
                    Sign up to Start
                    <img
                      src={showArrow ? hoverArrow : arrow}
                      style={{ marginLeft: "4px" }}
                      alt=""
                    />
                  </button>
                  <p className="link-btn m-0">
                    I already have an account.
                    <Link to="/log-in">
                      <span>Sign-in</span>
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default SignIn;
