import React, { useEffect, useState } from "react";
import "./recruiter.css";
import { useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import Profile from "../../assets/recruiter/profile-circle.svg";
import ProfileIcon from '../../assets/3682281.png'
import dotIcon from "../../assets/recruiter/dot-btn.svg";
import Loader from "../loader/Loader";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
import downArrow from "../../assets/select-down.svg";
import close from "../../assets/close-btn.svg";
import savedImg from "../../assets/saved-application.svg";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";

const InterviewersCard = ({ inputVal }) => {
  useEffect(() => {
    getData();
    recruiterPost();
  }, []);
  const navigate = useNavigate();
  const [dotBtn, setDotBtn] = useState(-1);
  const [loader, setLoader] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState("");
  const [recruiterPosts, setRecruiterPosts] = useState([]);
  const [formData, setFormData] = useState({
    subcategories: [],
    job: [],
  });
  const [selected, setIsSelected] = useState("");
  const [postId, setPostId] = useState([]);

  const [filterData, setFilterData] = useState("");

  const { getAllByCompany, allPostByRecruiter, assignInterviewer } = useMain();

  const getData = async () => {
    setLoader(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await getAllByCompany(token);
    if (res?.statusCode === 200) {
      setData(res?.data);
      setFilterData(res?.data);
      setLoader(false);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };

  const handelDialog = (val) => {
    if (dotBtn == val) {
      setDotBtn(-1);
    } else {
      setDotBtn(val);
    }
  };

  useEffect(() => {
    filteredDrafts(inputVal);
  }, [inputVal]);

  const filteredDrafts = (inputVal) => {
    const filterVal = data?.filter(
      (item) =>
        inputVal?.trim() === "" ||
        Object.values(item)?.some((value) =>
          String(value)?.toLowerCase()?.includes(inputVal?.toLowerCase())
        )
    );
    setFilterData(filterVal);
  };

  const recruiterPost = async () => {
    setLoader(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await allPostByRecruiter(token);
    if (res?.statusCode === 200) {
      setRecruiterPosts(res?.data?.data);
      setLoader(false);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (selected.trim() !== "") {
      if (!formData?.subcategories?.includes(selected))
        setFormData({
          ...formData,
          subcategories: [...formData?.subcategories, selected],
        });
    }
  }, [selected]);
  const handleDeleteSubcategory = (item) => {
    const subcategories = formData?.subcategories?.filter(
      (s, id) => id !== item
    );
    const deleteId = postId.filter((_, id) => id !== item);
    setPostId(deleteId);
    setFormData({ ...formData, subcategories });
  };

  const assignPost = async (id) => {
    if (postId.length > 0) {
      try {
        setLoader(true);
        const token = localStorage.getItem("recruiterToken");
        const Id = [...new Set(postId)];
        const result = await assignInterviewer(id, Id, token);

        if (result?.statusCode == 200) {
          // Handle success, e.g., update state, show a message, etc.
          toast.success(result?.message);
          setLoader(false);
          setPostId([]);
          setFormData({
            subcategories: [],
            job: [],
          });
          setModal(false);
          setIsSelected("");
        } else if (result?.statusCode === 500) {
          // Handle error, e.g., show an error message
          toast.error(result?.message);
          setLoader(false);
          setPostId([]);
          setFormData({
            subcategories: [],
            job: [],
          });
          setModal(false);
          setIsSelected("");
        }
      } catch (error) {
        setLoader(false);
        setPostId([]);
        setFormData({
          subcategories: [],
          job: [],
        });
        setModal(false);
        setIsSelected("");
      }
    }
  };

  return (
    <>
      <AlertContainer />
      {loader ? (
        <Loader />
      ) : filterData && filterData.length > 0 ? (
        filterData.map((e, ind) => (
          <>
            <div className="interview-card">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-start">
                  <img src={ProfileIcon} style={{ marginRight: "8px", width:"27px"}} alt="" />
                  <div>
                    <h6 className="interview-card-title m-0">{e?.name}</h6>
                    <p className="interview-card-text m-0">{e?.department}</p>
                  </div>
                </div>
                <button className="dot-btn" onClick={() => handelDialog(ind)}>
                  <img src={dotIcon} alt="" />
                </button>
                <div
                  className="interview-card-dropdown"
                  style={{ display: dotBtn == `${ind}` ? "flex" : "none" }}
                >
                  <button
                    onClick={() =>
                      navigate(`/dashboard/profile-detail/${e?._id}`)
                    }
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
              <div
                style={{ margin: "12px 0", borderBottom: "1px solid #F1F5F9" }}
              ></div>
              <button
                onClick={() => setModal(e?._id)}
                className="open-record-btn"
              >
                Assign Application
              </button>

              {/* **************
               modal
               ******** */}
              {modal === e?._id ? (
                <div
                  style={{
                    position: "fixed",
                    width: "100%",
                    height: "100vh",
                    top: "0",
                    left: "0",
                    background: "#00000075",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "100",
                  }}
                >
                  <div className="profile-detail-content bg-white">
                    <div>
                      <div className="d-flex justify-content-between mb-2">
                        <p className="back-profile-btn">Assigned Interviewes</p>
                        <p
                          className="back-profile-btn"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setPostId([]);
                            setFormData({
                              subcategories: [],
                              job: [],
                            });
                            setModal(false);
                            setIsSelected("");
                          }}
                        >
                          X
                        </p>
                      </div>
                      <p className="back-profile-text">
                        Send interview collaborations to your colleagues for the
                        job posting
                      </p>
                    </div>
                    <div
                      className="d-flex flex-column profile-detail-"
                      style={{ width: "300px" }}
                    >
                      <label htmlFor="" className="assign-job-label">
                        Job Posting
                      </label>
                      <div
                        className="dropdown"
                        onMouseEnter={() => setHover1(true)}
                        onMouseLeave={() => setHover1(false)}
                        style={{ marginBottom: "8px" }}
                      >
                        <div
                          onClick={(e) => {
                            setIsActive(!isActive);
                          }}
                          style={{
                            outline: isActive ? "2px solid #94A3B8" : "none",
                          }}
                          className="dropdown-btn"
                        >
                          Choose Job Posting
                          <img
                            src={hover1 ? downArrowHover : downArrow}
                            alt=""
                          />
                        </div>
                        <div
                          className="dropdown-content"
                          style={{ display: isActive ? "block" : "none" }}
                        >
                          {recruiterPosts?.map((element, index) => {
                            return (
                              <div
                                onClick={(e) => {
                                  setIsSelected(e.target.textContent);
                                  setPostId([...postId, element?._id]);
                                  setIsActive(!isActive);
                                }}
                                className="item"
                              >
                                {element.jobInfo.position}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {formData?.subcategories?.length > 0 && (
                        <div className="d-flex">
                          {formData?.subcategories?.map((item, index) => (
                            <p className="select-option" key={index}>
                              {item}
                              <img
                                src={close}
                                style={{ marginLeft: "4px" }}
                                alt=""
                                onClick={() => {
                                  handleDeleteSubcategory(index);
                                }}
                              />
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-center w-100">
                      <button
                        onClick={() => assignPost(e._id)}
                        className="save-btn-"
                      >
                        Save
                      </button>
                      {/* <button
                        className="edit-btn"
                        onClick={() =>
                          navigate(`/dashboard/add-new-hire/${data?._id}`)
                        }
                      >
                        Edit
                      </button> */}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        ))
      ) : (
          <div className="unsaved-image">
            <img src={savedImg} alt="" />
            <h6 className="unsaved-text m-0">No Interviewer</h6>
          </div>
      )}
    </>
  );
};

export default InterviewersCard;
