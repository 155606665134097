import React from "react";
import closeBtn from "../assets/close-bt.svg";
import "./dashboard.css";

function ScreenModal({ id, showModal, setShowModal, question }) {
  if (showModal === id) {
    return (
      <>
        <div className="feedback-modal">
          <div className="feedback-filter-box">
            <button className="close-btn">
              <img src={closeBtn} alt="" onClick={() => setShowModal(false)} />
            </button>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {question &&
                  question.length > 0 &&
                  question.map((e) => (
                    <div>
                      <h6 className="feedback-title m-0">{e?.question}</h6>
                      <p className="m-0 feedback-text">{e?.answer}</p>
                      <br />
                      <br />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
}

export default ScreenModal;
