import React, { useState } from "react";
import cap from "../assets/login/cap.svg";
import img1 from "../assets/login/ats-logo.svg";
import img2 from "../assets/login/apply-logo.svg";
import img3 from "../assets/login/skill-logo.svg";
import "../App.css";
import img4 from "../assets/login/up-skills.svg";
import eyesHide from "../assets/eyes-show.svg";
import eyes from "../assets/eyes-hide.svg";
// import google from "../assets/login/google-icon.svg";
import arrow from "../assets/login/left-arrow.svg";
import hoverArrow from "../assets/login/left-arrow-hover.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMain } from "../hook/useMain";
import { emailRegex } from "../components/regexPatterns/RegexPatterns";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Loader from "../components/loader/Loader";
import "./css/login.css";
import LoginHeader from "../components/header/LoginHeader";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";

const initialState = {
  email: "",
  password: "",
};
function LogIn() {
  const [showArrow, setShowArrow] = useState(false);
  const [formdata, setFormdata] = useState(initialState);
  const [Radio, setRadio] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyes);
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const navigate = useNavigate();
  const {
    loginRecruiter,
    loginJobseeker,
    googleRecruierLogin,
    googleSeekLogin,
    recruiterFindByEmal,
    userFindByEmal,
  } = useMain();

  const handleToggle = (e) => {
    e.preventDefault();
    if (type === "password") {
      setIcon(eyesHide);
      setType("text");
    } else {
      setIcon(eyes);
      setType("password");
    }
  };

  const hendleSignin = async (e) => {
    e.preventDefault();

    if (Radio === "") {
      return toast.info("Please select a profile.");

      return;
    }

    if (formdata?.email === "" || !emailRegex.test(formdata?.email)) {
      setEmailError("email is required");
      return;
    }

    if (formdata?.password === "" || formdata?.password.length < 6) {
      setPasswordError("password is required");
      // alert("Password must be 6 characters");
      return;
    }

    if (Radio === "Recruiter") {
      setLoader(true);

      const signRecruiter = await loginRecruiter(formdata);
      if (signRecruiter?.statusCode === 200) {
        localStorage.setItem("recruiterToken", signRecruiter?.data?.token);
        localStorage.setItem(
          "recruiterData",
          JSON.stringify(signRecruiter?.data?.recruiter)
        );
        localStorage.setItem("recruiteId", signRecruiter?.data?.recruiter?._id);
        localStorage.setItem("auth", true);
        return toast.success("Login successful.");

        setLoader(false);
        navigate("/recruiter-dashboard");
        // navigate("/dummy-dashboard");
      } else if (
        signRecruiter?.statusCode == 400 &&
        signRecruiter?.message == "Account is not active."
      ) {
        return toast.error("Your account is inactive.");

        setLoader(false);
      } else {
        toast.error("Login Failed.");
        setLoader(false);
      }
    } else if (Radio === "Job Seeker") {
      setLoader(true);
      const signUser = await loginJobseeker(formdata);
      console.log(signUser);
      if (signUser?.statusCode === 200) {
        localStorage.setItem("userToken", signUser?.data?.tokens?.accessToken);
        localStorage.setItem("userData", JSON.stringify(signUser?.data?.user));
        localStorage.setItem("userId", signUser?.data?.user?._id);
        localStorage.setItem("auth", true);
        localStorage.setItem(
          "userName",
          JSON.stringify(signUser?.data?.user?.name)
        );
        toast.success("Login Successful.");
        setLoader(false);
        navigate("/dashboard");
      } else if (
        signUser?.statusCode == 400 &&
        signUser?.message == "Account is not active."
      ) {
        return toast.error("Your account is inactive.");

        setLoader(false);
      } else {
        toast.error("Login Failed.");
        setLoader(false);
      }
    } else {
      return toast.info("Please try again later.");

      setLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
    if (name === "email") {
      if (value === "") {
        setEmailError("email is required");
      } else {
        setEmailError(null);
      }
    }
    if (name === "password") {
      if (value === "") {
        setPasswordError("password is required");
      } else {
        setPasswordError(null);
      }
    }
  };

  const googleLogin = async (data) => {
    if (Radio === "") {
      toast.info("Please select Profile.");
      return;
    }

    if (Radio === "Recruiter") {
      setLoader(true);
      const decode = jwtDecode(data?.credential);

      if (decode?.email_verified) {
        const result = await recruiterFindByEmal({ email: decode?.email });
        if (result?.statusCode == 200) {
          const resultRecruiter = await googleRecruierLogin({
            email: decode?.email,
            name: decode?.name,
          });
          if (resultRecruiter?.statusCode === 200) {
            setLoader(false);
            navigate("/recruiter-dashboard");
            localStorage.setItem(
              "recruiteId",
              resultRecruiter?.data?.recruiter?._id
            );
            localStorage.setItem(
              "recruiterData",
              JSON.stringify(resultRecruiter?.data?.recruiter)
            );
            localStorage.setItem(
              "recruiterToken",
              resultRecruiter?.data?.token
            );
            localStorage.setItem("auth", true);
          }
          if (
            resultRecruiter?.statusCode == 400 &&
            resultRecruiter?.message == "Account is not active."
          ) {
            return toast.error("Your account is inactive.");

          }
        } else if (result?.statusCode === 404) {
          const result = await googleRecruierLogin({
            email: decode?.email,
            name: decode?.name,
          });
          if (result?.statusCode === 200) {
            setLoader(false);
            navigate("/recruiter-update-mobile");
            // navigate("/recruiter-dashboard");
            localStorage.setItem("recruiteId", result?.data?.recruiter?._id);
            localStorage.setItem(
              "recruiterData",
              JSON.stringify(result?.data?.recruiter)
            );
            localStorage.setItem("recruiterToken", result?.data?.token);
            localStorage.setItem("auth", true);
          } else {
            toast.error("Somthing went wrong");
            setLoader(false);
          }
        }
      }
    }

    if (Radio === "Job Seeker") {
      setLoader(true);
      const decode = jwtDecode(data?.credential);
      if (decode?.email_verified) {
        const result = await userFindByEmal({ email: decode?.email });
        if (result?.statusCode == 200) {
          const resultSeeker = await googleSeekLogin({
            email: decode?.email,
            name: decode?.name,
          });
          console.log(resultSeeker);
          if (resultSeeker?.statusCode === 200) {
            navigate("/dashboard");
            localStorage.setItem("userId", resultSeeker?.data?.user?._id);
            localStorage.setItem(
              "userData",
              JSON.stringify(resultSeeker?.data?.user)
            );
            localStorage.setItem(
              "userToken",
              resultSeeker?.data?.tokens?.accessToken
            );
            localStorage.setItem("auth", true);
            setLoader(false);
          }
          if (
            resultSeeker?.statusCode == 400 &&
            resultSeeker?.message == "Account is not active."
          ) {
            return toast.error("Your account is inactive.");

          }
        } else if (result?.statusCode === 404) {
          const result = await googleSeekLogin({
            email: decode?.email,
            name: decode?.name,
          });
          if (result?.statusCode === 200) {
            setLoader(false);
            navigate("/user-update-mobile");
            // navigate("/dashboard");
            localStorage.setItem(
              "userToken",
              result?.data?.tokens?.accessToken
            );
            localStorage.setItem("userId", result?.data?.user?._id);
            localStorage.setItem(
              "userData",
              JSON.stringify(result?.data?.user)
            );
            // localStorage.setItem(
            //   "userName",
            //   JSON.stringify(result?.data?.name)
            // );
            localStorage.setItem("auth", true);
          } else {
            toast.error("Somthing went wrong");
            setLoader(false);
          }
        }
      }
    }
    setLoader(false);
  };

  return (
    <>
      <AlertContainer />
      <LoginHeader btnName="Sign Up" link="/sign-in" showBtn={true} />
      {loader ? (
        <Loader />
      ) : (
        <section className="login-hero">
          <div className="login-container p-0">
            {Radio === "Recruiter" ? (
              <div className="login-content" style={{ width: "430" }}>
                <h2 className="login-title">Work smart to land your</h2>
                <div className="span">
                  Perfect Candidate
                  <img src={cap} className="img-fluid" alt="" />
                </div>
                <div className="desktop-show-hide">
                  <p className="login-text m-0">
                    Here’s how we help you reach your goals faster.
                  </p>

                  <div className="login-map">
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img1} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Access to Industry standard resume
                      </p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img2} alt="" className="login-img" />
                      <p className="m-0 login-map-text">Free ATS</p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img3} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Application analytics for better results
                      </p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img4} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Add Interviewers & View Their Progress
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="login-content" style={{ width: "430px" }}>
                <h2 className="login-title">Work smart to land your</h2>
                <div className="span">
                  Dream Job <img src={cap} className="img-fluid" alt="" />
                </div>
                <div className="desktop-show-hide">
                  <p className="login-text m-0">
                    Here’s how we help you reach your goals faster.
                  </p>
                  <div className="login-map">
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img1} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Access to Industry standard resume building
                      </p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img2} alt="" className="login-img" />
                      <p className="m-0 login-map-text">Easy apply </p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img3} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Application analytics for better results
                      </p>
                    </div>
                    <div className="d-flex align-items-center login-map-content">
                      <img src={img4} alt="" className="login-img" />
                      <p className="m-0 login-map-text">
                        Skill upgradation assets and career assistance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="sign-up">
              <div className="text-center">
                <h6 className="sign-title">Sign in</h6>
                <p className="sign-text pb-2">
                  Sign in to your account for endless possibilities.
                </p>
                <div className="d-flex w-100 justify-content-center">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      googleLogin(credentialResponse);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />
                </div>
                <div
                  style={{
                    borderBottom: "1px solid #C7D2FE",
                    margin: "12px 0",
                  }}
                ></div>
                <div className="row pt-2 mb-2">
                  <div className="col-6">
                    <div className="radio-button">
                      <input
                        type="radio"
                        className="radio-button__input"
                        id="radio1"
                        onChange={(e) => setRadio(e.target.value)}
                        value="Job Seeker"
                        name="radio-group"
                      />
                      <label className="radio-button__label" htmlFor="radio1">
                        <span className="radio-button__custom"></span>
                        For Job Seeker
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="radio-button">
                      <input
                        type="radio"
                        className="radio-button__input"
                        value="Recruiter"
                        onChange={(e) => setRadio(e.target.value)}
                        id="radio2"
                        name="radio-group"
                      />
                      <label className="radio-button__label" htmlFor="radio2">
                        <span className="radio-button__custom"></span>
                        For Recruiter
                      </label>
                    </div>
                  </div>
                </div>
                <form>
                  <div>
                    <input
                      type="email"
                      placeholder="Email address"
                      className={
                        emailError === null ? "sign-input" : "sign-input-error"
                      }
                      name="email"
                      value={formdata?.email}
                      onChange={handleChange}
                    />
                    {emailError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{emailError}</p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="pass"
                      className={
                        passwordError === null
                          ? "sign-input"
                          : "sign-input-error"
                      }
                    >
                      <input
                        type={type}
                        placeholder="Password"
                        id="pass"
                        style={{
                          border: "none",
                          outline: "none",
                          width: "100%",
                          left: "0",
                        }}
                        name="password"
                        value={formdata?.password}
                        onChange={handleChange}
                      />
                      <button
                        className="show-password-btn"
                        onClick={handleToggle}
                      >
                        <img src={icon} alt="" />
                      </button>
                    </label>
                    {passwordError === null ? (
                      ""
                    ) : (
                      <p className="input-error-text m-0">{passwordError}</p>
                    )}
                  </div>
                  <Link
                    onClick={() => {
                      if (Radio === "") {
                        return toast.info("Please select a profile.");

                        return;
                      }
                    }}
                    to={
                      (Radio === "Job Seeker" && "/job-seeker/forget") ||
                      (Radio === "Recruiter" && "/recruiter/forget")
                    }
                    className="forget-text"
                  >
                    Forgot password?
                  </Link>
                  <button
                    className="sign-up-btn"
                    onMouseEnter={() => setShowArrow(true)}
                    onMouseLeave={() => setShowArrow(false)}
                    onClick={hendleSignin}
                  >
                    Sign in
                    <img
                      src={showArrow ? hoverArrow : arrow}
                      style={{ marginLeft: "4px" }}
                      alt=""
                    />
                  </button>
                  <p className="link-btn m-0">
                    Don’t have an account?
                    <Link
                      // to={Radio === "" ? "/sign-up-switch" : "/sign-in"}
                      onClick={() => {
                        if (Radio === "") {
                          return toast.info("Please select a profile.");

                          return;
                        }
                      }}
                      to={
                        (Radio === "Job Seeker" && "/sign-in") ||
                        (Radio === "Recruiter" && "/recruiter-sign-in")
                      }
                    >
                      <span>Sign-up</span>
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default LogIn;
