import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "./recruiter.css";
import Upcoming from "../../assets/recruiter-dashboard/upcoming.svg";
import RightArrow from "../../assets/recruiter-dashboard/right-arrow.svg";
import LightStar from "../../assets/recruiter-dashboard/light-star.svg";
import star from "../../assets/recruiter-dashboard/star.svg";
import SubscribeSlider from "../subscribeSlider/SubscribeYearly";
import box from "../../assets/images.png";
import CourseSlider from "../courseSlider/CourseSlider";
import ArticleSlider from "../courseSlider/ArticlesSlider";
import { useMain } from "../../hook/useMain";
import MobileContext from "../../context/mobile/MobileContext";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import CalenderImg from "../../assets/calender/calender.svg";
import MobileIn from "../../assets/recruiter/mobile-in.svg";
import MobileUp from "../../assets/recruiter/mobile-up.svg";
import addIcon from "../../recruiter-img/add-icon.svg";
import FirstChart from "./Chart/FirstChart";
import SecondChart from "./Chart/SecondChart";
import { AlertContainer } from "../Alerts/ToastifyContainer";
// import { data } from "jquery";
import { toast } from "react-toastify";

function ChartSlider({
  gradientPercentage1,
  gradientPercentage2,
  number2,
  open,
  close,
  total,
  analysisData,
}) {
  var OpenApp = 23;
  var ClosedApp = 10;
  var InProgress = 650;
  var Rejected = 300;
  var Selected = 50;
  const chartTotal = OpenApp + ClosedApp;
  const TotalApp = InProgress + Rejected + Selected;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "8%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <div className="chart-box">
            <h6 className="chart-title">Analysis on Applications</h6>
            <div className="d-flex align-items-center">
              <div style={{ height: "100px", position: "relative" }}>
                {analysisData?.jobpost && analysisData?.jobpost > 0 ? (
                  <>
                    <FirstChart newdata={analysisData} />
                    <div className="total-box-center">
                      {analysisData?.jobpost}
                    </div>
                  </>
                ) : (
                  <div className="zero-circle">
                    <div className="zero-box-center">0</div>
                  </div>
                )}
              </div>
              {/* <div
                className="pie-chart"
                style={{
                  background: `conic-gradient(#026D92 0 ${gradientPercentage1}%, #CBD5E1 0 ${gradientPercentage2}%)`,
                }}
              >
                <div className="pie-total">{close}</div>
                <div className="pie-closed">{open}</div>
                <div className="chart-total">{total}</div>
              </div> */}
              <div className="chart-text" style={{ marginLeft: "32px" }}>
                <div className="total-box">
                  <p className="box"></p> <p> Total Applications</p>
                </div>
                <div className="open-box">
                  <p className="box"></p> <p>Open Applications</p>
                </div>
                <div className="closed-box">
                  <p className="box"></p> <p>Closed Applications</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="chart-box">
            <h6 className="chart-title">Analysis on Applicants</h6>
            <div className="d-flex align-items-center">
              <div style={{ height: "100px", position: "relative" }}>
                {analysisData.totalapplication &&
                analysisData.totalapplication > 0 ? (
                  <>
                    <SecondChart newdata={analysisData} />
                    <div className="total-box-center">
                      {analysisData.totalapplication}
                    </div>
                  </>
                ) : (
                  <div className="zero-circle">
                    <div className="zero-box-center">0</div>
                  </div>
                )}
              </div>
              {/* <div
                className="pie-chart-2"
                style={{
                  background: `conic-gradient(#026D92 0 50%, #FFEAD7 0 70%, #CBD5E1 0 )`,
                }}
              >
                <div className="pie-total-2">{analysisData?.rejected}</div>
                <div className="pie-closed-2">
                  {analysisData?.totalapplication -
                    analysisData?.rejected -
                    analysisData?.pending || 0}
                </div>
                <div className="pie-process-2">
                  {analysisData?.hirecandidate}
                </div>
                <div className="chart-total">
                  {analysisData?.totalapplication}
                </div>
              </div> */}
              <div className="chart-text" style={{ marginLeft: "32px" }}>
                <div className="total-box">
                  <p className="box"></p> <p> Total Applications</p>
                </div>
                <div className="open-box">
                  <p className="box"></p> <p>In-progress Applicants</p>
                </div>
                <div className="closed-box">
                  <p className="box"></p> <p>Rejected Applicants</p>
                </div>
                <div className="selected-box">
                  <p className="box"></p> <p>Selected Applicants</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}
function UpcomingCard({ data }) {
  return (
    <>
      <div className="upcoming-card">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-start" style={{ gap: "8px" }}>
            <img src={Upcoming} alt="" />
            <div className="d-flex flex-column" style={{ gap: "2px" }}>
              <h6 className="upcoming-title m-0">{data?.userId?.name}</h6>
              <p className="upcoming-category m-0">{data?.title}</p>
              <p className="upcoming-date m-0">
                {data?.date} | {data?.startTime}
              </p>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <img src={RightArrow} alt="" />
            <p className="m-0 upcoming-date"> By Me</p>
          </div>
        </div>
      </div>
    </>
  );
}
function UpcomingSlider({ data }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "18%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {data && data?.length > 0 ? (
          data?.map((item, index) => (
            <div key={index}>
              <UpcomingCard data={item} />
            </div>
          ))
        ) : (
          <h5 className="text-center">It's empty in here</h5>
        )}
      </Slider>
    </>
  );
}
function InterviewCard({ item }) {
  return (
    <>
      <div className="interview-mobile-box">
        <h2 className="acc-title">{item?.name}</h2>
        <div className="acc-box">
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 interview-text">Total Applications</p>
            <p className="m-0 interview-text-number">
              {item?.jobAppId?.length}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 interview-text">Total Applicants</p>
            <p className="m-0 interview-text-number">
              {item?.jobApplicationCount}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 interview-text">Pending Application</p>
            <p className="m-0 interview-text-number">
              {item?.statusCounts?.PENGING && item.statusCounts?.INTERVIEWING
                ? Number(item?.statusCounts?.PENGING) +
                  Number(item.statusCounts?.INTERVIEWING)
                : 0}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <p className="m-0 interview-text">Hire Candidate</p>
            <p className="m-0 interview-text-number">
              {item?.statusCounts?.HIRE ? item?.statusCounts?.HIRE : 0}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
function InterviewSlider({ data2 }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "17%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <div className="interviewers">
        <div className="d-flex align-items-center w-100 justify-content-between">
          <h6 className="interviewers-title">Interviewers</h6>
          <h6 className="interviewers-title">{data2?.length}</h6>
        </div>
        <div
          style={{ margin: "8px 0", borderBottom: "1px solid #E2E8F0" }}
        ></div>

        {data2 && data2?.length > 0 ? (
          <Slider {...settings}>
            {data2?.map((item, index) => (
              <div key={index}>
                <InterviewCard item={item} />
              </div>
            ))}
          </Slider>
        ) : (
          <div className="d-flex justify-content-between">
            <div className="text-center">
              <img src={MobileUp} className="img-fluid" alt="" />
            </div>
            <div className="text-center">
              <h6
                style={{
                  color: "#B3B3B3",
                  fontFamily: "Inter",
                  fontSize: "8px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "12px",
                  marginTop: "24px",
                }}
              >
                No interviewers added
              </h6>
              <button
                className="new-job-add-btn mt-4"
                style={{ display: "flex" }}
              >
                <img src={addIcon} alt="" style={{ marginRight: "8px" }} />
                Add Interviewers
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
function FeedbackCard({ title, data, value }) {
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    let totalCount = 0;

    if (data && data?.length > 0) {
      data?.forEach((item) => {
        totalCount += item[value];
      });
    }

    setAverageRating(totalCount / data.length);
  }, [data, value]);

  const Star = (counts) => {
    const ragtingStar = Array.from({ length: 5 }, (ele, index) => {
      let number = index + 1;
      return (
        <span key={index}>
          {Math.floor(counts) >= number ? (
            <img src={LightStar} alt="" />
          ) : (
            <img src={star} alt="" />
          )}
        </span>
      );
    });
    return ragtingStar;
  };

  return (
    <>
      <div className="recruiter-feedback-card">
        <h2 className="title">{title}</h2>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="d-flex flex-row" style={{ gap: "8px" }}>
            {Star(averageRating)}
          </div>
          <h3 className="rating">{Math.floor(averageRating)}/5</h3>
        </div>
        <div className="center-border"></div>
        {data?.length > 0 &&
          data?.map((item, index) => (
            <div key={index} className="w-100">
              <div className="rating-item">
                <div className="d-flex align-items-center">
                  <img src={LightStar} style={{ height: "16px" }} alt="" />
                  <h6 className="m-0 rating-num">{item?.[value]}/5</h6>
                </div>
                <h6 className="m-0 rating-name">{item?.userId?.name}</h6>
                <p className="m-0 rating-text">({item?.userId?.jobTitle})</p>
              </div>
              <p className="m-0 rating-comment">{item.desc}</p>
              <div className="center-border"></div>
            </div>
          ))}
      </div>
    </>
  );
}
function FeedbackSlider({ data }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "7%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "2%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <FeedbackCard
            title="HR Follow Up"
            data={data}
            value={"hrFollowUps"}
          />
        </div>
        <div>
          <FeedbackCard
            title="HR Communication"
            data={data}
            value={"hrCommunication"}
          />
        </div>
      </Slider>
    </>
  );
}
function DraftCard({ draft }) {
  const [createDate, setCreateDate] = useState("");
  useEffect(() => {
    timeAgo(draft?.createdAt);
  }, []);

  function timeAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const timeDifferenceInSeconds = Math.floor(
      (currentDate - inputDate) / 1000
    );

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInWeek = 604800;
    const secondsInMonth = 2629800; // assuming an average month length of 30.44 days
    const secondsInYear = 31557600; // assuming a non-leap year

    if (timeDifferenceInSeconds < secondsInMinute) {
      return "Just now";
    } else if (timeDifferenceInSeconds < secondsInHour) {
      const minutes = Math.floor(timeDifferenceInSeconds / secondsInMinute);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifferenceInSeconds < secondsInDay) {
      const hours = Math.floor(timeDifferenceInSeconds / secondsInHour);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (timeDifferenceInSeconds < secondsInWeek) {
      const days = Math.floor(timeDifferenceInSeconds / secondsInDay);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (timeDifferenceInSeconds < secondsInMonth) {
      const weeks = Math.floor(timeDifferenceInSeconds / secondsInWeek);
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    } else if (timeDifferenceInSeconds < secondsInYear) {
      const months = Math.floor(timeDifferenceInSeconds / secondsInMonth);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(timeDifferenceInSeconds / secondsInYear);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }
  return (
    <>
      <div className="draft-card">
        <div className="d-flex align-items-start">
          <img
            src={
              JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                ? JSON.parse(localStorage.getItem("recruiterData")).companyLogo
                : box
            }
            alt=""
            style={{ marginRight: "8px", height: "25px", width: "25px" }}
          />
          <div className="w-100" style={{height:"50px"}}>
            <h2 className="draft-card-title">{draft?.jobInfo?.position}</h2>
            <div className="d-flex justify-content-between align-items-center">
              <p className="draft-card-text">{draft?.companyName}</p>
              <p className="draft-days">{createDate}</p>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #F1F5F9" }}></div>
        <ul className="draft-list">
          <li>
            {draft?.jobInfo?.jobType} · {draft?.jobInfo?.workPlaceType}
          </li>
          <li>
            {draft?.userId?.companyStrength} employees ·{" "}
            {draft?.userId?.industry}
          </li>
          {/* <li>See how you compare to 843 applicants.</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li>
          <li>Skills: Wireframing, User Experience (UX), +8 more</li> */}
        </ul>
        <Link
          className="text-decoration-none"
          to={`/dashboard/all-application/review/${draft?._id}`}
        >
          <div className="draft-edit-btn">Edit</div>
        </Link>
      </div>
    </>
  );
}

function DraftSlider({ draft }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "7%",
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "2%",
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "6%",
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "4%",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {draft && draft?.length > 0 ? (
          draft?.map((draft, index) => (
            <div>
              <DraftCard key={index} draft={draft} />
            </div>
          ))
        ) : (
          <h5 className="text-center">It's empty in here</h5>
        )}
      </Slider>
    </>
  );
}

function MobileHome() {
  const token = localStorage.getItem("recruiterToken");
  const id = localStorage.getItem("recruiteId");
  useEffect(() => {
    if (token && id) {
      getData();
      getData2();
      draftData();
      getInterviewByRecruitr(id);
      getFeedback(id);
      getAllanalysist(id);
    }
  }, [token, id]);

  const config = {
    clientId:
      "<550814863959-dktt4vs2opva4h2roep053p58kt0fbu7.apps.googleusercontent.com>",
    apiKey: "<AIzaSyA2evqeadVUXL1EAc1jLvw5NInLqC7K5oA>",
    scope: "https://www.googleapis.com/auth/calendar",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
  };

  // const apiCalendar = new ApiCalendar(config);
  const menuItem = useContext(MobileContext);
  const { handleRecruiterToggle, recruiterMenu } = menuItem;
  const [accOpen1, setAccOpen1] = useState(true);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [radio, setRadio] = useState("Yearly");
  const [data, setData] = useState("");
  const [data2, setData2] = useState([]);
  const [draft, setDraft] = useState([]);
  const [draftLoader, setDraftLoader] = useState(false);
  const [getInterviewer, setGetInterviewer] = useState([]);
  const [feedback, setFeedBack] = useState([]);
  const [analysisData, setAnalysisData] = useState({});
  var OpenApp = 23;
  var ClosedApp = 10;
  var InProgress = 650;
  var Rejected = 300;
  var Selected = 50;
  const chartTotal = OpenApp + ClosedApp;
  const TotalApp = InProgress + Rejected + Selected;

  const number1 = data?.length; // Replace this with your first number
  const number2 = 0; // Replace this with your second number

  const maxPercentage = 100;
  const gradientPercentage1 = Math.min(
    number1 * (maxPercentage / (number1 + number2)),
    maxPercentage
  );
  const gradientPercentage2 = Math.min(
    number2 * (maxPercentage / (number1 + number2)),
    maxPercentage
  );

  const {
    allPostByRecruiter,
    getAllByCompany,
    getAllDraft,
    getInterviewByRecruitrId,
    getFeedbackByUser,
  } = useMain();
  const navigation = useNavigate();

  const getData = async () => {
    setLoader1(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await allPostByRecruiter(token);
    if (res?.statusCode === 200) {
      setLoader1(false);
      setData(res?.data?.data);
    } else {
      toast.error("Something went wrong.");
      setLoader1(false);
    }
  };

  const getData2 = async () => {
    setLoader2(true);
    const recruiterId = localStorage.getItem("recruiteId");
    // const res = await getAllByCompany(token);
    try {
      const response = await fetch(
        `https://recrutify-backend.vercel.app/api/recruiter/allapp/${recruiterId}`
      );
      const res = await response.json();
      if (res?.statusCode === 200) {
        setLoader2(false);
        setData2(res?.data?.newData);
      } else {
        // alert("Somthing went wrong");
        setLoader2(false);
      }
    } catch (error) {}
  };

  const getAllanalysist = async (id) => {
    setLoader2(true);
    const recruiterId = localStorage.getItem("recruiteId");
    // const res = await getAllByCompany(token);
    try {
      const response = await fetch(
        `https://recrutify-backend.vercel.app/api/recruiter/analysis/${id}`
      );
      const res = await response.json();
      if (res?.statusCode === 200) {
        setLoader2(false);
        setAnalysisData(res?.data?.data);
      } else {
        setLoader2(false);
      }
    } catch (error) {}
  };
  const draftData = async () => {
    const token = localStorage.getItem("recruiterToken");

    setDraftLoader(true);
    if (token) {
      const res = await getAllDraft(token);
      if (res?.statusCode === 200) {
        setDraftLoader(false);
        setDraft(res?.data);
      } else {
        // alert("Somthing went wrong");
        setDraftLoader(false);
      }
    } else {
      setDraftLoader(false);
    }
  };

  const getInterviewByRecruitr = async (id) => {
    setLoader3(true);
    const result = await getInterviewByRecruitrId(id);
    if (result?.statusCode === 200) {
      setLoader3(false);
      const currentDate = new Date();

      // Use the filter method to create a new array with only the relevant items
      const filteredData = result?.data?.filter((item, index) => {
        const giveDate = item?.date?.split("/");
        if (giveDate && giveDate.length > 0) {
          const givenDate = new Date(giveDate[2], giveDate[1] - 1, giveDate[0]);

          // Return true if the given date is greater than or equal to the current date
          return givenDate.getTime() >= currentDate.getTime();
        }
      });

      setGetInterviewer(filteredData);
    } else {
      setLoader3(false);
    }
  };

  const getFeedback = async (id) => {
    // setLoader4(true);
    const result = await getFeedbackByUser(id);
    if (result?.statusCode === 200) {
      // setLoader4(false);
      setFeedBack(result?.data);
    } else {
      // setLoader4(false);
    }
  };

  return (
    <>
      <AlertContainer />
      <div className="recruiter-chart">
        <ChartSlider
          gradientPercentage1={gradientPercentage1}
          gradientPercentage2={gradientPercentage2}
          number2={number2}
          open={data?.length}
          close={number2}
          total={data?.length}
          analysisData={analysisData}
        />
      </div>
      <div className="recruiter-dashboard">
        <div className="recruiter-calender">
          <div className="calender-">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h6 className="calender-box-title p-0">My July Calendar</h6>
              <button className="calender-btn">Month</button>
            </div>
            <h5 className="text-center">It's empty in here</h5>
          </div>
          <div className="upcoming" style={{ height: "auto" }}>
            <div className="w-100">
              {getInterviewer?.length === 0 ? (
                <div className="d-flex justify-content-between">
                  <div>
                    <h6 className="upcoming-box-title p-0">
                      Upcoming Interviewes
                    </h6>
                    <h6
                      style={{
                        color: "#B3B3B3",
                        fontFamily: "Inter",
                        fontSize: "8px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "12px",
                        marginTop: "8px",
                      }}
                    >
                      No interviewers added
                    </h6>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "6px", marginTop: "30px" }}
                    >
                      <h6
                        className="m-0"
                        style={{
                          color: "#2E3D55",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "16px",
                        }}
                      >
                        Schedule Now
                      </h6>
                      <img src={CalenderImg} alt="" />
                    </div>
                  </div>
                  <div className="text-center">
                    <img src={MobileIn} className="img-fluid" alt="" />
                  </div>
                </div>
              ) : (
                <>
                  <h6 className="upcoming-box-title p-0">
                    Upcoming Interviewes
                  </h6>
                  <div className="upcoming-slider">
                    <UpcomingSlider data={getInterviewer} />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="interview-slider">
            <InterviewSlider data2={data2} />
          </div>
        </div>
      </div>
      {feedback?.length === 0 ? null : (
        <div className="feedback-container">
          <div className="feedback-box">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h6 className="feedback-box-title p-0">Feedback</h6>
              <button
                className="view-all-btn-recruiter"
                style={{ marginRight: "16px" }}
              >
                View All
              </button>
            </div>
            <div className="feedback-slider">
              {feedback?.length > 0 && (
                <>
                  <FeedbackSlider data={feedback} />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="subscribe-box">
        <div
          className="d-flex w-100 align-items-center justify-content-between"
          style={{ padding: "0 20px", marginBottom: "16px" }}
        >
          <h6 className="title">Subscribe Now</h6>
          <div className="d-flex flex-row" style={{ gap: "8px" }}>
            <div className="radio-button">
              <input
                type="radio"
                className="radio-button__input"
                id="radio1"
                name="date"
              />
              <label className="radio-title m-0" htmlFor="radio1">
                <span
                  className="radio-button__custom"
                  style={{ top: "2px" }}
                ></span>
                Monthly
              </label>
            </div>
            <div className="radio-button">
              <input
                type="radio"
                className="radio-button__input"
                id="radio2"
                name="date"
              />
              <label className="radio-title m-0" htmlFor="radio2">
                <span
                  className="radio-button__custom"
                  style={{ top: "2px" }}
                ></span>
                Yearly
              </label>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "20px" }} className="subscribe-slider">
          <SubscribeSlider />
        </div>
      </div>
      {draft?.length === 0 ? null : (
        <div style={{ background: "#F1F5F9", padding: "8px 0" }}>
          <div className="recruiter-draft">
            <div
              style={{ marginBottom: "12px" }}
              className="d-flex justify-content-between w-100 align-items-center"
            >
              <h6
                style={{
                  color: "#000",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  margin: 0,
                }}
              >
                Draft
              </h6>
              <button className="view-all-btn-recruiter">View All</button>
            </div>
            {draftLoader ? <Loader /> : <DraftSlider draft={draft} />}
          </div>
        </div>
      )}
      <div className="course-container">
        <h6 className="course-title">Recommended Courses</h6>
        <CourseSlider />
      </div>
      <div style={{ background: "#F1F5F9", padding: "0 0 17px 0" }}>
        <div className="article-container">
          <h6 className="article-title">Articles and Blogs</h6>
          <ArticleSlider />
        </div>
      </div>
    </>
  );
}

export default MobileHome;
