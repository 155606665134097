import React, { useState } from "react";
import SideBarContext from "./SidebarContext";

const SidebarState = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <SideBarContext.Provider
      value={{ handleSidebarToggle, isSidebarOpen, hovered, setHovered, toggleHover, setIsSidebarOpen }}
    >
      {props.children}
    </SideBarContext.Provider>
  );
};

export default SidebarState;
