import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/Header";
import Sidebar from "../components/sideBar/Sidebar";
import SaveSlider from "./SaveSlider";
import SideBarContext from "../context/SidebarContext";
import MobileMenu from "../components/mobileMenu/MobileMenu";
import MobileContext from "../context/mobile/MobileContext";
import jobIcon from "../assets/job-icon.svg";
import top from "../assets/top-arrow.svg";
import "./dashboard.css";
import box from "../assets/office-building.png";
import save from "../assets/save-btn.svg";
import left from "../assets/left-arrow.svg";
import { useMain } from "../hook/useMain";
import SaveCard from "./SaveCard";
import { useNavigate } from "react-router-dom";

// function SaveCard() {
//   return (
//     <>
//       <div className="save-card m-0">
//         <button className="save-actively">Actively hiring</button>
//         <div className="d-flex align-items-center">
//           <img src={box} alt="" />
//           <div className="ps-3">
//             <h6 className="save-title m-0">Human Resources</h6>
//             <p className="save-text m-0">Youth Empower Foundation</p>
//           </div>
//         </div>
//         <div className="save-card-border"></div>
//         <div className="d-flex align-items-center">
//           <p className="save-item m-0">Delhi</p>
//           <div className="save-dot"></div>
//           <p className="save-item m-0">Start up</p>
//           <div className="save-dot"></div>
//           <p className="save-item m-0">Full-time</p>
//           <div className="save-dot"></div>
//           <p className="save-item m-0">Immediately</p>
//         </div>
//         <div className="d-flex align-items-center justify-content-between">
//           <div className="apply-btn">
//             <button>Apply</button>
//             <img src={left} alt="" />
//           </div>
//           <button className="save-btn">
//             <img src={save} alt="" />
//           </button>
//         </div>
//       </div>
//     </>
//   );
// }

function BusinessCard() {
  return (
    <>
      <div className="save-mobile-card">
        <div className="d-flex align-items-center justify-content-between">
          <button className="save-mobile-actively">Actively hiring</button>
          <p className="m-0 mobile-hour-text">10 Hour</p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img src={box} alt="" />
            <div className="ps-3">
              <h6 className="save-mobile-title m-0">Human Resources</h6>
              <p className="save-mobile-text m-0">Youth Empower Foundation</p>
            </div>
          </div>
          <button className="save-mobile-btn">
            <img src={save} alt="" />
          </button>
        </div>
        <div className="save-mobile-card-border"></div>
        <div className="d-flex align-items-center">
          <p className="save-mobile-item m-0">Delhi</p>
          <div className="save-mobile-dot"></div>
          <p className="save-mobile-item m-0">Start up</p>
          <div className="save-mobile-dot"></div>
          <p className="save-mobile-item m-0">Full-time</p>
          <div className="save-mobile-dot"></div>
          <p className="save-mobile-item m-0">Immediately</p>
          <div className="save-mobile-dot"></div>
          <p className="save-mobile-item m-0">Remote</p>
        </div>
        <div className="mobile-apply-btn">
          <button>Apply</button>
          <img src={left} alt="" />
        </div>
      </div>
    </>
  );
}

function SavePage() {
  const navigate =useNavigate()
  useEffect(() => {
    const isAuthenticated = localStorage.getItem('auth') === 'true';
    if (!isAuthenticated) {
      navigate('/log-in');
      return;
    }
  }, []);
  const sidebar = useContext(SideBarContext);
  const menuItem = useContext(MobileContext);
  const { isSidebarOpen } = sidebar;
  const { handleMobileToggle, mobileMenu } = menuItem;

  return (
    <>
      <Header />
      <section className="dashboard">
        <div className="dashboard-box p-0">
          <div className="sidebar-scroll">
            <Sidebar />
          </div>
          <div
            className="save-box desktop-show-hide"
            style={{ left: isSidebarOpen ? "85px" : "354px" }}
          >
            <h6 className="save-page-title">Saved Applications</h6>
            {/* <div className="recommended-container" style={{ gap: "16px" }}> */}
              <SaveCard />
              {/* <SaveCard />
              <SaveCard />
              <SaveCard />
              <SaveCard />
              <SaveCard />
              <SaveCard />
              <SaveCard />
              <SaveCard /> */}
            </div>
          {/* </div> */}
        </div>
        <div className="mobile-show-hide mobile-box">
          <h6
            className="mobile-track-title m-0"
            style={{ paddingBottom: "12px" }}
          >
            Jobs/Internships
          </h6>
          <div className="d-flex flex-wrap" style={{gap: "12px"}}>
          <SaveCard />
          </div>
          <MobileMenu />
          <div className="mobile-menu-btn" onClick={handleMobileToggle}>
            <img
              src={top}
              alt=""
              style={{
                transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 150ms ease",
                height: "14px",
              }}
            />
            <img src={jobIcon} alt="" />
          </div>
        </div>
      </section>
    </>
  );
}

export default SavePage;
