import React, { useEffect, useState } from "react";
import "./recruiter.css";
import { useNavigate } from "react-router-dom";
import { useMain } from "../../hook/useMain";
import Profile from "../../assets/recruiter/profile-circle.svg";
import ProfileIcon from "../../assets/3682281.png";
import dotIcon from "../../assets/recruiter/dot-btn.svg";
import Loader from "../loader/Loader";
import downArrowHover from "../../assets/mobile/down-arrow-hover.svg";
import downArrow from "../../assets/select-down.svg";
import close from "../../assets/close-btn.svg";
import box from "../../assets/office-building.png";
import chat from "../../assets/chat-track.svg";
import savedImg from "../../assets/saved-application.svg";
import { AlertContainer } from "../Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl, headers } from "../../contextApi/common/data";
import InterviewChat from "../interviewer/chat/InterviewChat";
import RecruiterChat2 from "./chat/RecruiterChat2";

const RecruiterCard2 = ({ inputVal }) => {
  useEffect(() => {
    // getData();
    // recruiterPost();
  }, []);
  const navigate = useNavigate();
  const [dotBtn, setDotBtn] = useState(-1);
  const [loader, setLoader] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState("");
  const [recruiterPosts, setRecruiterPosts] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [formData, setFormData] = useState({
    subcategories: [],
    job: [],
  });
  const [selected, setIsSelected] = useState("");
  const [postId, setPostId] = useState([]);

  const [filterData, setFilterData] = useState("");

  const { getAllByCompany, allPostByRecruiter, assignInterviewer } = useMain();

  const getData = async () => {
    setLoader(true);
    const token = localStorage.getItem("recruiterToken");
    const res = await getAllByCompany(token);
    if (res?.statusCode === 200) {
      setData(res?.data);
      setFilterData(res?.data);
      setLoader(false);
    } else {
      // alert("Somthing went wrong");
      setLoader(false);
    }
  };

  //   const handelDialog = (val) => {
  //     if (dotBtn == val) {
  //       setDotBtn(-1);
  //     } else {
  //       setDotBtn(val);
  //     }
  //   };

  const findChats = async (data) => {
    try {
      const res = await axios.post(
        `${baseUrl}/message/all/interview/user`,
        JSON.stringify({ ids: data }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res);
      if (res?.status == 200) {
        setFilterData(res?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const jobsId = JSON.parse(localStorage.getItem("recruiterData"))?.jobPost;
    console.log(jobsId);
    if (jobsId && jobsId.length > 0) {
      findChats(jobsId);
    }
  }, []);

  //   const recruiterPost = async () => {
  //     setLoader(true);
  //     const token = localStorage.getItem("recruiterToken");
  //     const res = await allPostByRecruiter(token);
  //     if (res?.statusCode === 200) {
  //       setRecruiterPosts(res?.data?.data);
  //       setLoader(false);
  //     } else {
  //       // alert("Somthing went wrong");
  //       setLoader(false);
  //     }
  //   };

  return (
    <>
      <AlertContainer />
      {loader ? (
        <Loader />
      ) : filterData && filterData.length > 0 ? (
        filterData.map((item, index) => (
          <>
            <div className="open-card">
              <div className="d-flex align-items-center">
                <img
                  src={box}
                  className="me-2"
                  alt=""
                  style={{ width: "45px", height: "45px" }}
                />
                <div>
                  <h6 className="open-card-title m-0">{item?.sender?.name}</h6>
                  {/* <h6 className="open-card-title m-0">
                      {item?.receiver?.jobInfo?.position}
                    </h6> */}
                  <p className="open-card-text m-0">
                    {item?.receiver?.jobInfo?.position}
                  </p>
                </div>
              </div>
              <div className="open-card-border"></div>
              <ul className="ps-4 m-0">
                <li className="open-card-item">
                  {item?.receiver?.jobInfo?.workPlace}
                </li>
                {/* <li className="open-card-item">Start Up</li> */}
                <li className="open-card-item">
                  {item?.receiver?.jobInfo?.jobType}
                </li>
                <li className="open-card-item">
                  {item?.receiver?.jobInfo?.workPlaceType}
                </li>
                <li className="open-card-item">
                  {item?.receiver?.jobInfo?.candidateJoining}
                </li>
              </ul>
              <div className="chat-btn" onClick={() => setToggle(index)}>
                <img src={chat} alt="" />
                <h6 className="chat-btn-text">Chat with HR</h6>
              </div>
              {toggle === index && (
                <RecruiterChat2
                  setToggle={setToggle}
                  userId={item?.sender?._id}
                  name={item?.sender?.name}
                  company={item?.receiver?.companyName}
                  jobId={item?.receiver?._id}
                />
              )}
            </div>
          </>
        ))
      ) : (
        <div className="unsaved-image">
          <img src={savedImg} alt="" />
          <h6 className="unsaved-text m-0">No Interviewer</h6>
        </div>
      )}
    </>
  );
};

export default RecruiterCard2;
