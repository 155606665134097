import React, { useContext, useEffect, useState } from "react";
import Header from "../header/Header";
import search from "../../assets/recruiter/search.svg";
import Close from "../../assets/recruiter/close-input.svg";
import CoralSlider from "./CoralSlider";
import ResumeImg from "../../assets/profile-photo.svg";
import jobIcon from "../../assets/job-icon.svg";
import top from "../../assets/top-arrow.svg";
import CoralCard2 from "./CoralCard2";
import FIleView from "./FIleView";
import eImg from "./img/Mail.svg";
import lImg from "./img/Linked.svg";
import mImg from "./img/Viber.svg";
import CoralCard3 from "./CoralCard3";
import MobileContext from "../../context/mobile/MobileContext";
import MobileMenu from "../mobileMenu/MobileMenu";
import downloadImg from "../../assets/recruiter/download-img.svg";
import editImg from "../../assets/recruiter/edit-img.svg";
import MobileResume from "../../assets/recruiter/mobile-resume.svg";
import pinImg from "../../assets/recruiter/pin-img.svg";
import InputField from "./InputField";
import Sidebar from "../sideBar/Sidebar";
import SideBarContext from "../../context/SidebarContext";
import call from "../../assets/rating/call.svg";
import email from "../../assets/rating/email.svg";
import linkedIn from "../../assets/rating/linkedin.svg";
import { baseUrl } from "../../contextApi/common/data";
import axios from "axios";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useMain } from "../../hook/useMain";
import uploadImg from "../resume/img/upload.svg";

function TemplatesTwo() {
  const menuItem = useContext(MobileContext);
  const { handleMobileToggle, mobileMenu } = menuItem;
  const sidebar = useContext(SideBarContext);
  const { isSidebarOpen } = sidebar;
  const [inputToggle, setInputToggel] = useState(false);
  const handleChangeupdate = () => {
    setInputToggel(!inputToggle);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [allResume, setAllResume] = useState(null);
  const { UpdateResume } = useMain();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [formData, setFormData] = useState(null);
  const [education, setEducation] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [experience, setExperience] = useState(null);
  const [social, setSocial] = useState(null);
  const [loader, setLoader] = useState(false);
  const getAllResume = async () => {
    const id = localStorage.getItem("userToken");
    if (id) {
      try {
        const res = await axios.get(`${baseUrl}/user-resume`, {
          headers: {
            Authorization: `Bearer ${id}`,
          },
        });
        setAllResume(res?.data?.data?.resumes);
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Please log in again.");
    }
  };
  console.log(allResume);
  useEffect(() => {
    getAllResume();
  }, []);

  useEffect(() => {
    setFormData(JSON.parse(localStorage.getItem("resumeForm")));
    setEducation(JSON.parse(localStorage.getItem("resumeEducation")));
    setLanguages(JSON.parse(localStorage.getItem("resumeLanguage")));
    setExperience(JSON.parse(localStorage.getItem("resumeExperience")));
    setSocial(JSON.parse(localStorage.getItem("sociallink")));
  }, [inputToggle]);
  const [selectResume, setSelectResume] = useState(1);
  // console.log(formData, education, languages, experience);
  function handleOnSelect(selectNum) {
    setSelectResume(selectNum);
  }

  const convertAndUploadToAPI = () => {
    // Get the HTML content to convert
    console.log("function is calling.");
    let htmlContent = document.getElementById(
      "resume_section_print"
    )?.innerHTML;

    // Convert HTML to PDF
    html2pdf()
      .from(htmlContent)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        // Convert PDF to base64 string
        const pdfDataUri = pdf.output("datauristring");

        // Open the PDF in a new tab
        window.open(pdfDataUri, "_blank");
        // pdf.save("converted-resume.pdf");
        // const pdfBlob = pdf.output("blob");
        // const formData = new FormData();
        // formData.append("resume", pdfBlob, "converted-resume.pdf");

        // Make API call to upload the PDF
        // fetch("your-upload-api-endpoint", {
        //   method: "POST",
        //   body: formData,
        // })
        //   .then((response) => {
        //     // Handle response from API
        //     if (response.ok) {
        //       console.log("PDF uploaded successfully.");
        //     } else {
        //       console.error("Failed to upload PDF.");
        //     }
        //   })
        //   .catch((error) => {
        //     console.error("Error uploading PDF:", error);
        //   });
      });
  };

  //   async function printDocument() {
  //     try {
  //         const input = document.getElementById("divToPrint");
  //         const options = { filename: 'resume.pdf', image: { type: 'jpeg', quality: 0.98 }, html2canvas: { scale: 2 } };
  //         html2pdf().set(options).from(input).save().then(() => {
  //             const file = new File(["resume.pdf"], "resume.pdf", { type: "application/pdf" });
  //             const id = localStorage.getItem("userId");
  //             setLoader(true);
  //             UpdateResume(file, id)
  //                 .then((result) => {
  //                     if (result?.statusCode === 200) {
  //                         console.log(result);
  //                         if (result?.data) {
  //                             localStorage.setItem("userData", JSON.stringify(result?.data));
  //                         }
  //                         getAllResume();
  //                         toast.success(result?.message);
  //                     } else {
  //                         toast.error(result?.message || "Something went wrong");
  //                     }
  //                 })
  //                 .catch((error) => {
  //                     console.error("An error occurred:", error);
  //                     toast.error("Something went wrong");
  //                 })
  //                 .finally(() => {
  //                     setLoader(false);
  //                 });
  //         });
  //     } catch (error) {
  //         console.error("An error occurred:", error);
  //         toast.error("Something went wrong");
  //         setLoader(false);
  //     }
  // }

  async function printDocument() {
    try {
      const input = document.getElementById("divToPrint");
      const pdf = new jsPDF("p", "mm", "a4"); // 'p' for portrait orientation
      const canvas = await html2canvas(input, { scale: 2 }); // Increase scale for higher DPI
      const imgData = canvas.toDataURL("image/jpeg", 0.7); // Reduce JPEG quality to 0.7
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      const pdfBlob = pdf.output("blob");
      const file = new File([pdfBlob], "resume.pdf", {
        type: "application/pdf",
      });
      const id = localStorage.getItem("userId");
      setLoader(true);
      const result = await UpdateResume(file, id);
      if (result?.statusCode === 200) {
        console.log(result);
        if (result?.data) {
          localStorage.setItem("userData", JSON.stringify(result?.data));
        }
        getAllResume();
        toast.success(result?.message);
      } else {
        toast.error(result?.message || "Something went wrong");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("Something went wrong");
    } finally {
      setLoader(false);
    }
  }

  return (
    <>
      <Header />
      <section className="dashboard desktop-show-hide">
        <div className="desktop-show-hide">
          <div className="dashboard-box ">
            {/* <div className="sidebar-scroll">
              <Sidebar />
            </div> */}
            {/* <div
              className="job-seeker-dashboard"
            > */}
            <div className="temp-container">
              <div className="template-container">
                <div className="template-body">
                  <div className="design-container">
                    <div className="design-head">
                      <div className="d-flex align-items-center">
                        <div className="design-img"></div>
                        <div>
                          <h6 className="design-title">Basic CV Design</h6>
                          <p className="design-text">Best for Business</p>
                        </div>
                      </div>
                      <div className="design-btns">
                        {/* <button className="design-edit">Edit</button> */}
                        <button
                          className="design-edit"
                          // onClick={printDocument}
                          style={{ width: "224px", background: "transparent" }}
                        >
                          Save Changes
                        </button>
                        <button className="design-save" onClick={printDocument}>
                          Done
                        </button>
                      </div>
                    </div>
                    {/* <div className="design-head">
                      <div className="design-btns">
                        <button className="design-edit">Download</button>
                      </div>
                      <div className="design-btns">
                        <button className="design-save">Save</button>
                      </div>
                    </div> */}
                    <div className="design-body" id="resume_section_print">
                      {selectResume === 1 && (
                        <div id="divToPrint" className="resume-design">
                          <div className="row">
                            <div className="col-8">
                              <div className="design-experience">
                                <div className="design-item">
                                  <h6 className="design-name">
                                    {formData?.fullname || "Name"}
                                  </h6>
                                  <p className="design-designation">
                                    {formData?.designation || "Designation"}
                                  </p>
                                </div>
                                <div>
                                  {experience && (
                                    <p className="m-0 design-experience-title">
                                      Experience
                                    </p>
                                  )}
                                  {experience?.map((e, ind) => (
                                    <div>
                                      <h6 className="design-experience-titles">
                                        {e?.designation || "Designation"}
                                      </h6>
                                      <p className="design-experience-text">
                                        {e?.companyName || "Company Name"}
                                      </p>
                                      <p className="design-experience-text-1">
                                        {`${e?.form || "Form"}-${
                                          e?.to || "To"
                                        }`}{" "}
                                        {", "}{" "}
                                        {e?.companyType || "Company Type"}
                                      </p>
                                      <p className="design-experience-text">
                                        {e?.describeRole
                                          ? e.describeRole
                                              .split("\n")
                                              .map((line, index) => (
                                                <span key={index}>
                                                  {line}
                                                  <br />
                                                </span>
                                              ))
                                          : "Describe your Job Roles & Responsibilities"}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                                {/* <div>
                                <h6 className="design-experience-titles">
                                  Designation
                                </h6>
                                <p className="design-experience-text">
                                  Company Name
                                </p>
                                <p className="design-experience-text-1">
                                  Form - To, Company Type
                                </p>
                                <p className="design-experience-text">
                                  Describe your Job Roles & Responsibilities
                                </p>
                              </div> */}
                                <div>
                                  {education && (
                                    <p className="m-0 design-experience-title">
                                      Education
                                    </p>
                                  )}
                                  <div className="d-flex flex-column gap-3">
                                    {education?.map((e, ind) => (
                                      <div>
                                        <h6 className="design-experience-titles">
                                          {e?.degreeName || "Degree Name"}
                                        </h6>
                                        <p className="design-experience-text">
                                          {e?.universityName ||
                                            "Institute Name"}
                                        </p>
                                        <p className="design-experience-text-1">
                                          {`${
                                            e?.selectStartYear || "Start Year"
                                          }-${
                                            e?.selectEndYear || "End Year"
                                          }`}{" "}
                                          {", "} {e?.degree || "Degree Type"}
                                        </p>
                                        <p className="design-experience-text">
                                          {e?.writehighlight ||
                                            "HighLight of Your Journey"}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="design-skill">
                                <div className="design-item">
                                  {selectedFile === null ? (
                                    <label
                                      className="design-experience-text-1 d-flex align-items-center flex-column"
                                      htmlFor="fileInput"
                                      style={{
                                        height: "72px",
                                        width: "72px",
                                        borderRadius: "50%",
                                        backgroundColor: "#e5e8e9",
                                        objectFit: "cover",
                                      }}
                                    >
                                      <img
                                        src={uploadImg}
                                        style={{
                                          height: "84.71px",
                                          width: "84.71px",
                                          objectFit: "cover",
                                        }}
                                        alt=""
                                      />
                                    </label>
                                  ) : (
                                    <img
                                      onClick={() => setSelectedFile(null)}
                                      style={{
                                        height: "84.71px",
                                        width: "84.71px",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                      }}
                                      src={selectedFile}
                                      alt=""
                                    />
                                  )}
                                  <input
                                    type="file"
                                    id="fileInput"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                </div>
                                <div>
                                  <p className="design-experience-text-1">
                                    {formData?.email || "Email Address"}
                                  </p>
                                  <p className="design-experience-text-1">
                                    {formData?.phoneNumber || "Phone Number"}
                                  </p>
                                  <p className="design-experience-text-1">
                                    {formData?.city || "Current City"}
                                  </p>
                                </div>
                                <div>
                                  {languages && (
                                    <p className="design-experience-text-2">
                                      Language
                                    </p>
                                  )}
                                  {languages?.map((e) => (
                                    <p className="design-experience-text-1">
                                      {e?.languageName}
                                      {" - "}
                                      {e?.proficiency}
                                    </p>
                                  ))}
                                  <br />
                                  {formData?.skills &&
                                    formData?.skills?.length > 0 && (
                                      <p className="design-experience-text-2">
                                        Skills
                                      </p>
                                    )}
                                  {formData?.skills &&
                                    formData?.skills?.length > 0 &&
                                    formData?.skills?.map((e_skill) => (
                                      <p className="design-experience-text-1">
                                        {e_skill}
                                      </p>
                                    ))}
                                  {/* <p className="design-experience-text-1">
                                    Skill
                                  </p>
                                  <p className="design-experience-text-1">
                                    Skill
                                  </p>
                                  <p className="design-experience-text-1">
                                    Skill
                                  </p>
                                  <p className="design-experience-text-1">
                                    Skill
                                  </p> */}
                                </div>
                                <div>
                                  <p className="design-experience-text-2">
                                    Social
                                  </p>
                                  {social?.linkedin !== "" && (
                                    <p className="design-experience-text-2">
                                      <a
                                        href={social?.linkedin}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {social?.linkedin}
                                      </a>
                                    </p>
                                  )}
                                  {social?.portfolio !== "" && (
                                    <p className="design-experience-text-2">
                                      <a
                                        href={social?.portfolio}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {social?.portfolio}
                                      </a>
                                    </p>
                                  )}
                                  {social?.website !== "" && (
                                    <p className="design-experience-text-2">
                                      <a
                                        href={social?.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {social?.website}
                                      </a>
                                    </p>
                                  )}
                                  {social?.other !== "" && (
                                    <p className="design-experience-text-2">
                                      <a
                                        href={social?.other}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {social?.other}
                                      </a>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectResume === 2 && (
                        <div id="divToPrint" className="resume-box-1">
                          <h1 className="r-title">
                            {formData?.fullname || "Name"}
                          </h1>
                          <p className="r-text">
                            {" "}
                            {formData?.designation || "Designation"}
                          </p>

                          <div className="row">
                            <div className="col-4">
                              <div className="r-card-title">Contact</div>
                              <p className="r-card-text">
                                M: {formData?.phoneNumber || "Phone Number"}
                              </p>
                              <p className="r-card-text">
                                W: {formData?.phoneNumber || "Work"}
                              </p>
                              <p className="r-card-text">
                                E: {formData?.email || "Email Address"}
                              </p>
                              <div className="r-card-title">Summary</div>
                              <p className="r-card-text">
                                {formData?.summary
                                  ? formData.summary
                                      .split("\n")
                                      .map((line, index) => (
                                        <React.Fragment key={index}>
                                          {line}
                                          <br />
                                        </React.Fragment>
                                      ))
                                  : "Write Summary"}
                              </p>
                              {education && (
                                <div className="r-card-title">Education</div>
                              )}
                              {education?.map((e, ind) => (
                                <div>
                                  <h6 className="design-experience-titles">
                                    {e?.degreeName || "Degree Name"}
                                  </h6>
                                  <p className="design-experience-text">
                                    {e?.universityName || "Institute Name"}
                                  </p>
                                  <p className="design-experience-text">
                                    {`${e?.selectStartYear || "Start Year"}-${
                                      e?.selectEndYear || "End Year"
                                    }`}{" "}
                                    {", "} {e?.degree || "Degree Type"}
                                  </p>
                                  {/* <p className="design-experience-text">
                                    {e?.writehighlight ||
                                      "HighLight of Your Journey"}
                                  </p> */}
                                </div>
                              ))}
                              {formData?.skills && (
                                <div className="r-card-title">Skill</div>
                              )}
                              <div className="mt-2">
                                {formData?.skills?.map((e, ind) => (
                                  <p className="design-experience-text">{e}</p>
                                ))}
                              </div>
                            </div>
                            <div className="col-8">
                              {experience && (
                                <div className="r-card-title">Experience</div>
                              )}
                              {experience?.map((e, ind) => (
                                <div>
                                  <h6 className="form-to">
                                    [{e?.form}] - [{e?.to}] [{e?.designation}] -
                                    [{e?.companyName}]
                                  </h6>
                                  <ul>
                                    {/* <li className="r-card-text">
                                      Created “clean and sexy” designs as per
                                      client brief.
                                    </li> */}{" "}
                                    {e?.describeRole
                                      ? e.describeRole
                                          .split("\n")
                                          .map((line, index) => (
                                            <li className="r-card-text">
                                              <span key={index}>
                                                {line}
                                                <br />
                                              </span>
                                            </li>
                                          ))
                                      : "Describe your Job Roles & Responsibilities"}
                                  </ul>
                                  {/* <h6 className="design-experience-titles">
                                    {e?.designation || "Designation"}
                                  </h6>
                                  <p className="r-card-text">
                                    {" "}
                                    {e?.companyName || "Company Name"}
                                  </p>
                                  <p className="r-card-text">
                                    {`${e?.form || "Form"}-${e?.to || "To"}`}{" "}
                                    {", "} {e?.companyType || "Company Type"}
                                  </p> */}
                                  <p className="r-card-text"></p>
                                </div>
                              ))}
                              <div>
                                {languages && (
                                  <div className="r-card-title">Language</div>
                                )}
                                {languages?.map((e) => (
                                  <p className="design-experience-text-1">
                                    {e?.languageName}
                                    {" - "}
                                    {e?.proficiency}
                                  </p>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectResume === 3 && (
                        <div id="divToPrint" className="resume-box-2">
                          <div className="row w-100 custom-row">
                            <div
                              className="col-5 px-4 py-2"
                              style={{
                                backgroundColor: "#02266C",
                                height: "842px",
                              }}
                            >
                              <div className="r-card-title-white">Contact</div>
                              <div className="r-card-text-white">
                                M: {formData?.phoneNumber || "Phone Number"}
                              </div>
                              <div className="r-card-text-white">
                                W: {formData?.phoneNumber || "Phone Number"}
                              </div>
                              <div className="r-card-text-white">
                                E: {formData?.email || "Email Address"}
                              </div>
                              <div className="r-card-title-white">Summary</div>
                              <p className="r-card-text-white">
                                {formData?.summary
                                  ? formData.summary
                                      .split("\n")
                                      .map((line, index) => (
                                        <React.Fragment key={index}>
                                          {line}
                                          <br />
                                        </React.Fragment>
                                      ))
                                  : "Write Summary"}
                              </p>
                              {/* <div className="r-card-title-white">Education</div> */}
                              <div>
                                {education && (
                                  <div className="r-card-title-white">
                                    Education
                                  </div>
                                )}
                                {education?.map((e) => (
                                  <>
                                    <p className="r-card-text-white">
                                      {e?.degreeName}
                                      {" - "}
                                      {e?.universityName}
                                    </p>
                                    <p className="r-card-text-white">
                                      {`${e?.selectStartYear || "Start Year"}-${
                                        e?.selectEndYear || "End Year"
                                      }`}{" "}
                                      {", "} {e?.degree || "Degree Type"}
                                    </p>
                                  </>
                                ))}
                              </div>
                              <div>
                                {formData?.skills && (
                                  <div className="r-card-title-white">
                                    Skills
                                  </div>
                                )}
                                {formData?.skills?.map((e) => (
                                  <p className="r-card-text-white">{e}</p>
                                ))}
                              </div>
                            </div>
                            <div className="col-7 p-0 ">
                              <div className="r-right-top">
                                <h3 className="r-title">
                                  {" "}
                                  {formData?.fullname || "Name"}
                                </h3>
                                <p className="r-text">
                                  {formData?.designation || "Designation"}
                                </p>
                              </div>
                              <div className="r-right-bottom">
                                {experience && (
                                  <div className="r-card-title">Experience</div>
                                )}
                                {experience?.map((e, ind) => (
                                  <div>
                                    <h6 className="design-experience-titles">
                                      [{e?.form}] - [{e?.to}] [{e?.designation}]
                                      - [{e?.companyName}]
                                    </h6>
                                    <ul>
                                      {e?.describeRole
                                        ? e.describeRole
                                            .split("\n")
                                            .map((line, index) => (
                                              <li className="r-card-text">
                                                <span key={index}>
                                                  {line}
                                                  <br />
                                                </span>
                                              </li>
                                            ))
                                        : "Describe your Job Roles & Responsibilities"}
                                    </ul>
                                    {/* <p className="r-card-text">
                                    {" "}
                                    {e?.companyName || "Company Name"}
                                  </p>
                                  <p className="r-card-text">
                                    {`${e?.form || "Form"}-${e?.to || "To"}`}{" "}
                                    {", "} {e?.companyType || "Company Type"}
                                  </p>
                                  <p className="r-card-text">
                                    {e?.describeRole
                                      ? e.describeRole
                                          .split("\n")
                                          .map((line, index) => (
                                            <span key={index}>
                                              {line}
                                              <br />
                                            </span>
                                          ))
                                      : "Describe your Job Roles & Responsibilities"}
                                  </p> */}
                                  </div>
                                ))}
                                {/* {experience && (
                                <div className="r-card-title">Education</div>
                              )} */}
                                {/* {education?.map((e, ind) => (
                                <div>
                                  <h6 className="design-experience-titles">
                                    {e?.degreeName || "Degree Name"}
                                  </h6>
                                  <p className="design-experience-text">
                                    {e?.universityName || "Institute Name"}
                                  </p>
                                  <p className="design-experience-text-1">
                                    {`${e?.selectStartYear || "Start Year"}-${
                                      e?.selectEndYear || "End Year"
                                    }`}{" "}
                                    {", "} {e?.degree || "Degree Type"}
                                  </p>
                                  <p className="design-experience-text">
                                    {e?.writehighlight ||
                                      "HighLight of Your Journey"}
                                  </p>
                                </div>
                              ))} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectResume === 4 && (
                        <div id="divToPrint" className="resume-box-3">
                          <div className="resume-box-3-top">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex gap-3">
                                <div className="design-item">
                                  {selectedFile === null ? (
                                    <label
                                      className="design-experience-text-1 d-flex align-items-center flex-column"
                                      htmlFor="fileInput"
                                      style={{
                                        height: "84.71px",
                                        width: "84.71px",
                                        objectFit: "cover",
                                      }}
                                    >
                                      <img
                                        src={uploadImg}
                                        style={{
                                          height: "84.71px",
                                          width: "84.71px",
                                          objectFit: "cover",
                                        }}
                                        alt=""
                                      />
                                    </label>
                                  ) : (
                                    <img
                                      onClick={() => setSelectedFile(null)}
                                      style={{
                                        height: "84.71px",
                                        width: "84.71px",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                      }}
                                      src={selectedFile}
                                      alt=""
                                    />
                                  )}
                                  <input
                                    type="file"
                                    id="fileInput"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                </div>
                                <div>
                                  <p className="r-title-1">
                                    {formData?.designation || "Designation"}
                                  </p>
                                  <p className="r-text-1">
                                    {formData?.fullname || "Name"}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex flex-column gap-2">
                                <div className="d-flex align-items-center gap-1">
                                  <img src={eImg} alt="" />
                                  <p className="p-0 r-title-1-black">
                                    {formData?.email || "Email Address"}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={lImg} alt="" />
                                  <p className="p-0 r-title-1-black">
                                    {social?.linkedin || "LinkedIn Link"}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center gap-1">
                                  <img src={mImg} alt="" />
                                  <p className="p-0 r-title-1-black">
                                    {formData?.phoneNumber || "Phone Number"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="resume-box-3-bottom">
                            <div className="resume-box-3-left">
                              {experience && (
                                <p className="r-title-1">Work experience</p>
                              )}
                              {experience?.map((e, ind) => (
                                <div>
                                  <p className="r-title-1-black">
                                    {e?.designation || "Designation"}
                                  </p>
                                  {/* <p className="r-text-1-black">
                                    {e?.companyName || "Company Name"}
                                  </p> */}
                                  <p className="design-experience-text-1">
                                    [{e?.form}] - [{e?.to}] [{e?.designation}] -
                                    [{e?.companyName}]
                                  </p>
                                  <p className="design-experience-text mt-2">
                                    {e?.describeRole
                                      ? e.describeRole
                                          .split("\n")
                                          .map((line, index) => (
                                            <span key={index}>
                                              {line}
                                              <br />
                                            </span>
                                          ))
                                      : "Describe your Job Roles & Responsibilities"}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="resume-box-3-right">
                              <p className="r-title-1">Education & Learning</p>
                              {education?.map((e, ind) => (
                                <div>
                                  <p className="r-title-1-black">
                                    {e?.degreeName || "Degree Name"}
                                  </p>
                                  <p className="r-text-1-black">
                                    {e?.universityName || "Institute Name"}
                                  </p>
                                  <p className="r-text-1-black">
                                    {`${e?.selectStartYear || "Start Year"}-${
                                      e?.selectEndYear || "End Year"
                                    }`}{" "}
                                    {", "} {e?.degree || "Degree Type"}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="skill-resume">
                            {formData?.skills && (
                              <p className="r-title-1">Skills</p>
                            )}
                            <div>
                              {formData?.skills?.map((e, ind) => (
                                <p className="r-title-1-black-">{e}</p>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {selectResume === 5 && (
                        <div id="divToPrint" className="resume-box-4">
                          <div className="resume-box-4-top">
                            <div className="row overflow-hidden">
                              <div className="col-7">
                                <div>
                                  <div className="d-flex gap-4 align-items-center">
                                    <div className="design-item">
                                      {selectedFile === null ? (
                                        <label
                                          className="design-experience-text-1 d-flex align-items-center flex-column"
                                          htmlFor="fileInput"
                                          style={{
                                            height: "84.71px",
                                            width: "84.71px",
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                          }}
                                        >
                                          <img
                                            src={uploadImg}
                                            style={{
                                              height: "84.71px",
                                              width: "84.71px",
                                              objectFit: "cover",
                                            }}
                                            alt=""
                                          />
                                        </label>
                                      ) : (
                                        <img
                                          onClick={() => setSelectedFile(null)}
                                          style={{
                                            height: "84.71px",
                                            width: "84.71px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                          }}
                                          src={selectedFile}
                                          alt=""
                                        />
                                      )}
                                      <input
                                        type="file"
                                        id="fileInput"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                      />
                                    </div>
                                    <div>
                                      <p className="r-title-4-1">
                                        {formData?.fullname || "Name"}
                                      </p>
                                      <p className="r-text-4-1">
                                        {formData?.designation || "Designation"}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="r-text-1-black mt-3" style={{fontFamily: 'Poppins, sans-serif'}}>
                                    {formData?.summary
                                      ? formData.summary
                                          .split("\n")
                                          .map((line, index) => (
                                            <React.Fragment key={index}>
                                              {line}
                                              <br />
                                            </React.Fragment>
                                          ))
                                      : "Write Summary"}
                                  </p>
                                </div>
                              </div>
                              <div className="col-5">
                                <div className="d-flex gap-3 flex-column">
                                  <div>
                                    <h6 className="five-name">Email</h6>
                                    <h6 className="five-text">
                                      {formData?.email || "Email Address"}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="five-name">LinkedIn</h6>
                                    <h6 className="five-text">
                                      {" "}
                                      {social?.linkedin || "LinkedIn Link"}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6 className="five-name">Phone</h6>
                                    <h6 className="five-text">
                                      {" "}
                                      {formData?.phoneNumber || "Phone Number"}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="resume-box-4-bottom">
                            <div className="resume-box-4-left">
                              {experience && (
                                <p className="r-title-4-left">
                                  Work experience
                                </p>
                              )}
                              {experience?.map((e, ind) => (
                                <div>
                                  <p className="r-title-4-1-black">
                                    {e?.designation || "Designation"}
                                  </p>
                                  {/* <p className="r-text-1-black">
                                    {e?.companyName || "Company Name"}
                                  </p> */}
                                  <p className="design-experience-text-11">
                                    [{e?.form}] - [{e?.to}] [{e?.designation}] -
                                    [{e?.companyName}]
                                  </p>
                                  <p className="design-experience-text mt-2">
                                    {e?.describeRole
                                      ? e.describeRole
                                          .split("\n")
                                          .map((line, index) => (
                                            <span key={index}>
                                              {line}
                                              <br />
                                            </span>
                                          ))
                                      : "Describe your Job Roles & Responsibilities"}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="resume-box-4-right">
                              <div className="d-flex flex-column gap-4">
                                <div>
                                  <p className="r-title-4-left p-0">
                                    Education & Learning
                                  </p>
                                  {education?.map((e, ind) => (
                                    <div key={ind} className="mt-2">
                                      <p className="r-title-4-1-black">
                                        {e?.degreeName || "Degree Name"}
                                      </p>
                                      <p className="design-experience-text-11">
                                        {e?.universityName || "Institute Name"}
                                      </p>
                                      <p className="design-experience-text-11">
                                        {`${
                                          e?.selectStartYear || "Start Year"
                                        }-${
                                          e?.selectEndYear || "End Year"
                                        }`}{" "}
                                        {", "} {e?.degree || "Degree Type"}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {formData?.skills && (
                                    <p className="r-title-4-left">Skills</p>
                                  )}
                                    <div className="d-flex">
                                  {formData?.skills?.map((e, ind) => (
                                      <p className="design-experience-text">{e + ","}</p>
                                  ))}
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectResume === 6 && (
                        <div id="divToPrint" className="resume-box-4">
                          <div
                            className="resume-box-4-top"
                            style={{ backgroundColor: "#f9f9f9" }}
                          >
                            <div className="row overflow-hidden">
                              <div className="col-6">
                                <div className="d-flex gap-4 align-items-center">
                                  {/* <img src={ResumeImg} alt="" /> */}
                                  <div>
                                    <p className="r-title-4-1">
                                      {formData?.fullname || "Name"}
                                    </p>
                                    <p className="r-text-4-1">
                                      {formData?.designation || "Designation"}
                                    </p>
                                  </div>
                                </div>
                                <p className="r-text-1-black mt-3" style={{fontFamily: "Poppins, sans-serif"}}>
                                  {formData?.summary || "Summary"}
                                </p>
                              </div>
                              <div className="col-6">
                                <h6 className="resume-email">Email</h6>
                                <p className="resume-text">
                                  {formData?.email || "Email Address"}
                                </p>
                                <h6 className="resume-email">LinkedIn</h6>
                                {social?.linkedin !== "" && (
                                  <p className="resume-text">
                                    <a
                                      href={social?.linkedin}
                                      target="_blank"
                                      className="resume-text"
                                      style={{ textDecoration: "none" }}
                                      rel="noopener noreferrer"
                                    >
                                      {social?.linkedin}
                                    </a>
                                  </p>
                                )}
                                <h6 className="resume-email">Phone</h6>
                                <p className="resume-text">
                                  {formData?.phoneNumber || "Phone Number"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="resume-box-4-bottom">
                            <div className="resume-box-4-left">
                              {experience && (
                                <p className="r-title-4-left">
                                  Work experience
                                </p>
                              )}
                              {experience?.map((e, ind) => (
                                <div>
                                  <p className="r-title-4-1-black">
                                    {e?.designation || "Designation"}
                                  </p>
                                  {/* <p className="r-text-4-black">
                                    {e?.companyName || "Company Name"}
                                  </p> */}
                                  <p className="design-experience-text-11">
                                    [{e?.form}] - [{e?.to}] [{e?.designation}] -
                                    [{e?.companyName}]
                                  </p>
                                  <p className="design-experience-text pt-2">
                                    {e?.describeRole
                                      ? e.describeRole
                                          .split("\n")
                                          .map((line, index) => (
                                            <span key={index}>
                                              {line}
                                              <br />
                                            </span>
                                          ))
                                      : "Describe your Job Roles & Responsibilities"}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="resume-box-4-right">
                              <div className="d-flex flex-column gap-4">
                                <div>
                                  <p className="r-title-4-left p-0">
                                    Education & Learning
                                  </p>
                                  {education?.map((e, ind) => (
                                    <div className="mt-3">
                                      <p className="r-title-4-1-black">
                                        {e?.degreeName || "Degree Name"}
                                      </p>
                                      <p className="r-text-4-black p-0">
                                        {e?.universityName || "Institute Name"}
                                      </p>
                                      <p className="r-text-4-black p-0">
                                        {`${
                                          e?.selectStartYear || "Start Year"
                                        }-${
                                          e?.selectEndYear || "End Year"
                                        }`}{" "}
                                        {", "} {e?.degree || "Degree Type"}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {formData?.skills && (
                                    <p className="r-title-4-left">Skills</p>
                                  )}
                                    <div className="d-flex">
                                  {formData?.skills?.map((e, ind) => (
                                      <p className="design-experience-text pt-0">
                                        {e + ","}
                                      </p>
                                  ))}
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectResume === 7 && (
                        <div id="divToPrint" className="resume-box-7">
                          <div className="row">
                            <div className="col-4">
                              <div className="resume-box-7-left">
                                <p className="r-title-4-1">
                                  {formData?.fullname || "Name"}
                                </p>
                                <p className="r-text-4-1">
                                  {formData?.designation || "Designation"}
                                </p>
                                <p className="r-text-7">
                                  {formData?.summary || "Summary"}
                                </p>
                                <h6 className="left-7-title">Contact</h6>
                                <div className="d-flex flex-column gap-1 mt-1">
                                  <div className="d-flex align-items-center gap-2">
                                    <img src={email} alt="" />
                                    <p className="resume-email-text">
                                      {formData?.email || "Email Address"}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center gap-2">
                                    <img src={linkedIn} alt="" />
                                    <p className="resume-email-text">
                                      {social?.linkedin}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center gap-2">
                                    <img src={call} alt="" />
                                    <p className="resume-email-text">
                                      {formData?.phoneNumber || "Phone Number"}
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <h6
                                    className="left-7-title"
                                    style={{ marginTop: "20px", paddingBottom: "10px" }}
                                  >
                                    Skills
                                  </h6>
                                  {formData?.skills?.map((e, ind) => (
                                    <p className="r-text-7 mt-1 p-0">{e}</p>
                                  ))}
                                </div>
                                <div>
                                  <h6
                                    className="left-7-title"
                                    style={{ marginTop: "20px", paddingBottom: "10px" }}
                                  >
                                    Tools
                                  </h6>
                                  <p className="r-text-7 p-0">
                                    {formData?.tools || "Tools"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="resume-box-7-right">
                                <div className="">
                                  {experience?.map((e, ind) => (
                                    <div className="resume-7-box">
                                      <p className="title-7">
                                        {e?.designation || "Designation"}
                                      </p>
                                      <p className="sub-title-7">
                                        {e?.companyName || "Company Name"}
                                      </p>
                                      <ul style={{ paddingLeft: "14px" }}>
                                        <li className="text-7">
                                          [{e?.form}] - [{e?.to}] [
                                          {e?.designation}] - [{e?.companyName}]
                                        </li>
                                        <li className="text-7">
                                          {e?.describeRole
                                            ? e.describeRole
                                                .split("\n")
                                                .map((line, index) => (
                                                  <span key={index}>
                                                    {line}
                                                    <br />
                                                  </span>
                                                ))
                                            : "Describe your Job Roles & Responsibilities"}
                                        </li>
                                      </ul>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="resume-7-bottom">
                            <div className="row">
                              <div className="col-6">
                                <div className="resume-7-box">
                                  <p className="sub-title-7">
                                    Education & Learning
                                  </p>
                                  {education?.map((e, ind) => (
                                    <div className="mt-3">
                                      <p className="title-7">
                                        {e?.degreeName || "Degree Name"}
                                      </p>
                                      <p className="text-7">
                                        {e?.universityName || "Institute Name"}
                                      </p>
                                      <p className="text-7">
                                        {`${
                                          e?.selectStartYear || "Start Year"
                                        }-${
                                          e?.selectEndYear || "End Year"
                                        }`}{" "}
                                        {", "} {e?.degree || "Degree Type"}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              {/* <div className="col-6">
                                <p className="title-77">
                                  Design Leadership Masterclass
                                </p>
                                <p className="text-7">
                                  Design Lab Inc., Mar 2020
                                </p>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                      {selectResume === 8 && (
                        <div id="divToPrint" className="resume-box-8">
                          <div className="row">
                            <div className="col-3 overflow-hidden">
                              <h6 className="resume-8-title">
                                {formData?.fullname || "Name"}
                              </h6>
                              <p className="resume-8-text">
                                {formData?.designation || "Designation"}
                              </p>
                            </div>
                            <div className="col-9 overflow-hidden">
                              <div className="d-flex align-items-center gap-2">
                                <img src={email} alt="" />
                                <p className="resume-email-text">
                                  {formData?.email || "Email Address"}
                                </p>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <img src={linkedIn} alt="" />
                                <p className="resume-email-text">
                                  {social?.linkedin}
                                </p>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <img src={call} alt="" />
                                <p className="resume-email-text">
                                  {formData?.phoneNumber || "Phone Number"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <p className="resume-8-sub-text">
                            {formData?.summary || "Summary"}
                          </p>
                          <div className="row">
                            <div className="col-3">
                              {experience && (
                                <h6 className="skill-title-8">Experience</h6>
                              )}
                            </div>
                            <div className="col-3">
                              {experience?.map((e, ind) => (
                                <div>
                                  <h6 className="skill-name-8">
                                    {e?.designation || "Designation"}
                                  </h6>
                                  <h6 className="skill-title-8">
                                    {e?.companyName || "Company Name"}
                                  </h6>
                                  <h6 className="skill-date-8">
                                    [{e?.form}] - [{e?.to}] [{e?.designation}] -
                                    [{e?.companyName}]
                                  </h6>
                                  {/* <p className="design-experience-text">
                                    {e?.describeRole
                                      ? e.describeRole
                                          .split("\n")
                                          .map((line, index) => (
                                            <span key={index}>
                                              {line}
                                              <br />
                                            </span>
                                          ))
                                      : "Describe your Job Roles & Responsibilities"}
                                  </p> */}
                                </div>
                              ))}
                            </div>
                            <div className="col-6">
                              {experience?.map((e, ind) => (
                                <div>
                                  <p className="skill-desc-8">
                                    {e?.describeRole
                                      ? e.describeRole
                                          .split("\n")
                                          .map((line, index) => (
                                            <span key={index}>
                                              {line}
                                              <br />
                                            </span>
                                          ))
                                      : "Describe your Job Roles & Responsibilities"}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="row mt-5">
                              <div className="col-3">
                                <h6 className="skill-title-8">Education</h6>
                              </div>
                              <div className="col-9">
                                {education?.map((e, ind) => (
                                  <div className="mt-2" key={ind}>
                                    <h6 className="skill-title-8">
                                      {e?.degreeName || "Degree Name"}
                                    </h6>
                                    <h6 className="skill-name-8">
                                      {e?.universityName || "Institute Name"}
                                    </h6>
                                    <h6 className="skill-date-8">
                                      {`${e?.selectStartYear || "Start Year"}-${
                                        e?.selectEndYear || "End Year"
                                      }`}{" "}
                                      {", "} {e?.degree || "Degree Type"}
                                    </h6>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="row mt-5">
                              <div className="col-3">
                                {formData?.skills && (
                                  <h6 className="skill-title-8">Skills</h6>
                                )}
                              </div>
                              <div className="col-9">
                                {formData?.skills?.map((e, ind) => (
                                  <h6 className="skill-name-8"> {e}</h6>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectResume === 9 && (
                        <div id="divToPrint" className="resume-box-9">
                          <div className="d-flex align-items-end justify-content-between">
                            <div>
                              <p className="resume-text-9">
                                {formData?.designation || "Designation"}
                              </p>
                              <h6 className="resume-title-99">
                                {formData?.fullname || "Name"}
                              </h6>
                            </div>
                            <div className="d-flex gap-4">
                              <div>
                                <p className="resume-text-9">Linkedin</p>
                                <h6 className="resume-title-9">
                                  {social?.linkedin}
                                </h6>
                              </div>
                              <div>
                                <p className="resume-text-9">Instagram</p>
                                <h6 className="resume-title-9">Daniel.Na</h6>
                              </div>
                              <div>
                                <p className="resume-text-9">Contact Number</p>
                                <h6 className="resume-title-9">
                                  {" "}
                                  {formData?.phoneNumber || "Phone Number"}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <br />
                          <hr />
                          <br />
                          <div className="resume-9-box">
                            {selectedFile === null ? (
                              <label
                                className="design-experience-text-1 d-flex align-items-center flex-column"
                                htmlFor="fileInput"
                                style={{
                                  height: "84.71px",
                                  width: "84.71px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                              >
                                <img
                                  src={uploadImg}
                                  style={{
                                    height: "84.71px",
                                    width: "84.71px",
                                    objectFit: "cover",
                                  }}
                                  alt=""
                                />
                              </label>
                            ) : (
                              <img
                                onClick={() => setSelectedFile(null)}
                                style={{
                                  height: "84.71px",
                                  width: "84.71px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                                src={selectedFile}
                                alt=""
                              />
                            )}
                            <input
                              type="file"
                              id="fileInput"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                            <div>
                              <h6 className="resume-title-9">About</h6>
                              <p
                                style={{ color: "#000000" }}
                                className="resume-text-9"
                              >
                                {formData?.summary || "Summary"}
                              </p>
                            </div>
                          </div>
                          <br />
                          <div className="resume-9-second">
                            <div className="row">
                              <div className="col-8">
                                {experience && (
                                  <h6 className="skill-title-9">Experience</h6>
                                )}
                                <hr />
                                {experience?.map((e, ind) => (
                                  <div>
                                    <p className="skill-sub-text-9">
                                      {e?.designation || "Designation"}
                                    </p>
                                    <ul style={{ paddingLeft: "14px" }}>
                                      <p className="skill-desc-text">
                                        {e?.companyName || "Company Name"}
                                      </p>
                                      <p className="skill-desc-text">
                                        [{e?.form}] - [{e?.to}] [
                                        {e?.designation}] - [{e?.companyName}]
                                      </p>
                                    </ul>
                                    <p className="skill-sub-text-9">
                                      {e?.companyName}
                                      <span>({e?.form})</span>
                                    </p>
                                    <ul style={{ paddingLeft: "14px" }}>
                                      {e?.describeRole
                                        ? e.describeRole
                                            .split("\n")
                                            .map((line, index) => (
                                              <li className="r-card-text">
                                                <span key={index}>
                                                  {line}
                                                  <br />
                                                </span>
                                              </li>
                                            ))
                                        : ""}
                                    </ul>
                                  </div>
                                ))}
                              </div>
                              <div className="col-4">
                                <div>
                                  {formData?.skills && (
                                    <h6 className="skill-title-9">Skils</h6>
                                  )}
                                  <hr className="" />
                                  <ul style={{ paddingLeft: "14px" }}>
                                    {formData?.skills?.map((e, ind) => (
                                      <li className="skill-desc-text">{e}</li>
                                    ))}
                                  </ul>
                                </div>
                                <div className="mt-1">
                                  {education && (
                                    <h6 className="skill-title-9">
                                      Education
                                    </h6>
                                  )}
                                  <hr />
                                  {education?.map((e, ind) => (
                                      <div>
                                        <h6 className="design-experience-titles">
                                          {e?.degreeName || "Degree Name"}
                                        </h6>
                                        <p className="design-experience-text">
                                          {e?.universityName ||
                                            "Institute Name"}
                                        </p>
                                        <p className="design-experience-text-1">
                                          {`${
                                            e?.selectStartYear || "Start Year"
                                          }-${
                                            e?.selectEndYear || "End Year"
                                          }`}{" "}
                                          {", "} {e?.degree || "Degree Type"}
                                        </p>
                                        <p className="design-experience-text">
                                          {e?.writehighlight ||
                                            "HighLight of Your Journey"}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="resume-9-bottom">
                            <p className="resume-text">
                              Things cannot be perfect, I try to focus on
                              executing them on time.
                            </p>
                          </div>
                        </div>
                      )}
                      {/* <img src={ResumeImg} className="img-fluid" alt="" /> */}
                    </div>
                    <div className="resume-file">
                      <h6 className="resume-file-title">Resumes</h6>
                      {/* <FIleView />
                      <FIleView />
                      <FIleView />
                      <FIleView /> */}
                      {allResume?.length > 0 &&
                        allResume?.map((resume) => (
                          <FIleView
                            key={resume._id}
                            resume={resume}
                            getAllResume={getAllResume}
                          />
                        ))}
                    </div>
                  </div>
                  <div className="paid-tamp">
                    <nav>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                          className="nav-link active"
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-home"
                          type="button"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          Templates
                        </button>
                        <button
                          className="nav-link"
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-profile"
                          type="button"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                        >
                          Input fields
                        </button>
                        {/* <button
                    className="nav-link"
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                  >
                    Uploads
                  </button> */}
                      </div>
                    </nav>
                    <div
                      className="tab-content"
                      style={{ borderLeft: "none" }}
                      id="nav-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        <div className="coral-2-container">
                          <div className="template-search">
                            <img src={search} alt="" />
                            <input type="text" placeholder="Choose category" />
                            <img src={Close} alt="" />
                          </div>
                          <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="paid-title">Saved Resume</h6>
                              <button className="paid-btn">View all</button>
                            </div>
                            <div className="coral-2-box">
                              <CoralCard2
                                handleOnSelect={handleOnSelect}
                                cardNum={1}
                              />
                              <CoralCard2
                                handleOnSelect={handleOnSelect}
                                cardNum={2}
                              />
                              <CoralCard2
                                handleOnSelect={handleOnSelect}
                                cardNum={3}
                              />
                              <CoralCard2
                                handleOnSelect={handleOnSelect}
                                cardNum={4}
                              />
                              <CoralCard2
                                handleOnSelect={handleOnSelect}
                                cardNum={5}
                              />
                              <CoralCard2
                                handleOnSelect={handleOnSelect}
                                cardNum={6}
                              />
                              <CoralCard2
                                handleOnSelect={handleOnSelect}
                                cardNum={7}
                              />
                              <CoralCard2
                                handleOnSelect={handleOnSelect}
                                cardNum={8}
                              />
                              <CoralCard2
                                handleOnSelect={handleOnSelect}
                                cardNum={9}
                              />
                            </div>
                          </div>
                          {/* <div>
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="paid-title">More</h6>
                              <button className="paid-btn">View all</button>
                            </div>
                            <div className="coral-2-box">
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                              <CoralCard2 />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        <div className="coral-2-container">
                          <InputField handleChangeupdate={handleChangeupdate} />
                        </div>
                      </div>
                      {/* <div
                  className="tab-pane fade"
                  id="nav-contact"
                  role="tabpanel"
                  aria-labelledby="nav-contact-tab"
                >
                  Upload
                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*  Mobile Screen View */}
            {/* </div> */}
          </div>
        </div>
      </section>
      {/*  Mobile Screen View */}
      <div className="template-container">
        <div className="mobile-show-hide">
          <div className="paid-tamp">
            <nav>
              <div className="nav nav-tabs pb-3" id="nav-tab" role="tablist">
                <button
                  className="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#templates"
                  type="button"
                  role="tab"
                  aria-controls="templates"
                  aria-selected="true"
                >
                  Templates
                </button>
                <button
                  className="nav-link"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#input-fields"
                  type="button"
                  role="tab"
                  aria-controls="input-fields"
                  aria-selected="false"
                >
                  Input fields
                </button>
                {/* <button
                  className="nav-link"
                  id="nav-contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#upload"
                  type="button"
                  role="tab"
                  aria-controls="upload"
                  aria-selected="false"
                >
                  Uploads
                </button> */}
              </div>
            </nav>
            <div
              className="tab-content"
              style={{ borderLeft: "none" }}
              id="nav-tabContent"
            >
              <div
                className="tab-pane fade show active"
                id="templates"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="coral-2-container">
                  <div style={{ marginTop: "12px" }}>
                    <p className="resume-tamp-text">Pinned</p>
                    <div className="coral-slider">
                      <CoralSlider />
                    </div>
                  </div>
                  <div style={{ marginTop: "12px" }}>
                    <p className="resume-tamp-text">More Templates</p>
                    <div className="d-flex flex-column" style={{ gap: "8px" }}>
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                      <CoralCard3 />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <h6 className="design-title">Basic CV Design</h6>
                    <p className="design-text">Best for Business</p>
                  </div>
                  <div className="d-flex" style={{ gap: "16px" }}>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        padding: "0",
                      }}
                    >
                      <img src={downloadImg} alt="download" />
                    </button>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        padding: "0",
                      }}
                    >
                      <img src={editImg} alt="edi" />
                    </button>
                  </div>
                </div>
                <img
                  src={MobileResume}
                  className="img-fluid w-100 mt-3"
                  alt=""
                />
                <button className="design-save-btn">
                  Save <img src={pinImg} alt="" />
                </button>
                <div className="resume-file">
                  <h6 className="resume-file-title">Resumes</h6>
                  <FIleView />
                  <FIleView />
                  <FIleView />
                  <FIleView />
                </div>
                <div className="mt-2">
                  <p className="resume-tamp-text">More Designs</p>
                  <div className="coral-slider">
                    <CoralSlider />
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="input-fields"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <InputField handleChangeupdate={handleChangeupdate} />
              </div>
              {/* <div
                className="tab-pane fade"
                id="upload"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                Upload
              </div> */}
            </div>
          </div>
          <MobileMenu />
          <div className="mobile-menu-btn" onClick={handleMobileToggle}>
            <img
              src={top}
              alt=""
              style={{
                transform: mobileMenu ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 150ms ease",
                height: "14px",
              }}
            />
            <img src={jobIcon} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default TemplatesTwo;
