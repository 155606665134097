import { createHashRouter, createBrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";

import App from "../App";
import Loader from "../components/loader/Loader";
import { CareerLogin } from "../components/career/CareerLogin";
import Calender from "../components/recruiter/Calender";
import CareerCalender from "../components/career/CareerCalender";
import SetJobSwitch from "../pages/SetJobSwitch";
import JobEdit from "../pages/JobEdit";
import RecruiterEdit from "../components/recruiterPages/RecruiterEdit";
import RecruiterForget from "../components/recruiter/RecruiterForget";
import JobForget from "../pages/JobForget";
import CareerForget from "../components/career/CareerForget";
import CareerSlider from "../dashboard/CareerSlider";
import CareerSetting from "../components/acount-pages/CareerSetting";
import UserSetting from "../components/acount-pages/UserSetting";
import RecruiterHelp2 from "../components/acount-pages/RecruiterHelp2";
import ViewProfile from "../components/recruiter/ViewProfile";
import ViewProfileIn from "../components/interviewer/ViewProfileIn";
import UpgradeRecriterPage from "../dashboard/UpgradeRecriterPage";

import Homepage from "../pages/Homepage";
// const Homepage = lazy(() => import("../pages/Homepage"));

import AboutPage from "../pages/AboutPage";
// const AboutPage = lazy(() => import("../pages/AboutPage"));

import Community from "../pages/Community";
// const Community = lazy(() => import("../pages/Community"));

import SignIn from "../pages/SignIn";
// const SignIn = lazy(() => import("../pages/SignIn"));

import Login from "../pages/Login";
// const Login = lazy(() => import("../pages/Login"));

import SignUpSwitch from "../pages/SignUpSwitch";
// const SignUpSwitch = lazy(() => import("../pages/SignUpSwitch"));

import SetJob from "../pages/SetJob";
// const SetJob = lazy(() => import("../pages/SetJob"));

import CreateResume from "../pages/CreateResume";
// const CreateResume = lazy(() => import("../pages/CreateResume"));

import BuildResume from "../pages/BuildResume";
// const BuildResume = lazy(() => import("../pages/BuildResume"));

import BuildResumeOne from "../pages/BuildResumeOne";
// const BuildResumeOne = lazy(() => import("../pages/BuildResumeOne"));

import BuildResumeTwo from "../pages/BuildResumeTwo";
// const BuildResumeTwo = lazy(() => import("../pages/BuildResumeTwo"));

import HelpUs from "../pages/HelpUs";
// const HelpUs = lazy(() => import("../pages/HelpUs"));

import OtpPage from "../pages/OTPPage";
// const OtpPage = lazy(() => import("../pages/OTPPage"));

import Complete from "../pages/Complete";
// const Complete = lazy(() => import("../pages/Complete"));

import Home from "../dashboard/Home";
// const Home = lazy(() => import("../dashboard/Home"));

import JobDescription from "../dashboard/JobDescription";
// const JobDescription = lazy(() => import("../dashboard/JobDescription"));

import JobApply from "../dashboard/JobApply";
// const JobApply = lazy(() => import("../dashboard/JobApply"));

import SubmitSwitch from "../dashboard/SubmitSwitch";
// const SubmitSwitch = lazy(() => import("../dashboard/SubmitSwitch"));

import UpgradePage from "../dashboard/UpgradePage";
// const UpgradePage = lazy(() => import("../dashboard/UpgradePage"));

import SavePage from "../dashboard/SavePage";
// const SavePage = lazy(() => import("../dashboard/SavePage"));

import UpSkillPage from "../dashboard/UpSkillsPage";
// const UpSkillPage = lazy(() => import("../dashboard/UpSkillsPage"));

import CareerPage from "../dashboard/CareerPage";
// const CareerPage = lazy(() => import("../dashboard/CareerPage"));

import TrackPage from "../dashboard/TrackPage";
// const TrackPage = lazy(() => import("../dashboard/TrackPage"));

import ChatPage from "../dashboard/ChatPage";
// const ChatPage = lazy(() => import("../dashboard/ChatPage"));

import MobileChat from "../components/OpenSlider/MobileChat";
// const MobileChat = lazy(() => import("../components/OpenSlider/MobileChat"));

import RatingMobile from "../dashboard/RatingMobile";
// const RatingMobile = lazy(() => import("../dashboard/RatingMobile"));

import Analysis from "../dashboard/Analysis";
// const Analysis = lazy(() => import("../dashboard/Analysis"));

import AnalysisUpgrade from "../dashboard/AnalysisUpgrade";
// const AnalysisUpgrade = lazy(() => import("../dashboard/AnalysisUpgrade"));

import NewJob from "../components/recruiter/NewJob";
// const NewJob = lazy(() => import("../components/recruiter/NewJob"));

import ProfileDetail from "../components/recruiter/ProfileDetail";
// const ProfileDetail = lazy(() =>
//   import("../components/recruiter/ProfileDetail")
// );

import Draft from "../components/recruiter/Draft";
// const Draft = lazy(() => import("../components/recruiter/Draft"));

import AllApplication from "../components/recruiter/AllApplication";
// const AllApplication = lazy(() =>
//   import("../components/recruiter/AllApplication")
// );

import AllAppDetail from "../components/recruiter/AllAppDetail";
// const AllAppDetail = lazy(() => import("../components/recruiter/AllAppDetail"));

import Table from "../components/recruiter/Table";
// const Table = lazy(() => import("../components/recruiter/Table"));

import AllInterviews from "../components/recruiter/AllInterviews";
// const AllInterviews = lazy(() =>
//   import("../components/recruiter/AllInterviews")
// );

import AddNewHire from "../components/recruiter/AddNewHire";
// const AddNewHire = lazy(() => import("../components/recruiter/AddNewHire"));

import PostNewJob from "../components/recruiter-dashboard/PostNewJob";
// const PostNewJob = lazy(() =>
//   import("../components/recruiter-dashboard/PostNewJob")
// );

import PostJobDraft from "../components/recruiter/PostJobDraft";
// const PostJobDraft = lazy(() => import("../components/recruiter/PostJobDraft"));

import PostJobReview from "../components/recruiter/PostJobReview";
// const PostJobReview = lazy(() =>
//   import("../components/recruiter/PostJobReview")
// );

import AllJobs from "../components/recruiter-dashboard/AllJobs";
// const AllJobs = lazy(() => import("../components/recruiter-dashboard/AllJobs"));

import Setting from "../components/acount-pages/Setting";
// const Setting = lazy(() => import("../components/acount-pages/Setting"));

import BugReport from "../components/acount-pages/BugReport";
// const BugReport = lazy(() => import("../components/acount-pages/BugReport"));

import Help from "../components/acount-pages/Help";
// const Help = lazy(() => import("../components/acount-pages/Help"));

import Faq from "../components/acount-pages/Faq";
// const Faq = lazy(() => import("../components/acount-pages/Faq"));

import PrivacyPolicy from "../components/acount-pages/PrivacyPolicy";
// const PrivacyPolicy = lazy(() =>
//   import("../components/acount-pages/PrivacyPolicy")
// );

import TermsPage from "../components/acount-pages/TermsPage";
// const TermsPage = lazy(() => import("../components/acount-pages/TermsPage"));

import RefundPage from "../components/acount-pages/RefundPage";
// const RefundPage = lazy(() => import("../components/acount-pages/RefundPage"));

import ResumeBuild from "../components/acount-pages/ResumeBuild";
// const ResumeBuild = lazy(() =>
//   import("../components/acount-pages/ResumeBuild")
// );

import LogOut from "../components/acount-pages/LogOut";
// const LogOut = lazy(() => import("../components/acount-pages/LogOut"));

import Deactivate from "../components/acount-pages/Deactivate";
// const Deactivate = lazy(() => import("../components/acount-pages/Deactivate"));

import Delete from "../components/acount-pages/DeletePage";
// const Delete = lazy(() => import("../components/acount-pages/DeletePage"));

import RecruiterDashboard from "../components/recruiter/RecruiterDashboard";
// const RecruiterDashboard = lazy(() =>
//   import("../components/recruiter/RecruiterDashboard")
// );

import DummyDashboard from "../components/recruiter/DummyDashboard";
// const DummyDashboard = lazy(() =>
//   import("../components/recruiter/DummyDashboard")
// );

import RecruiterSignIn from "../components/recruiterPages/RecruiterSignIn";
// const RecruiterSignIn = lazy(() =>
//   import("../components/recruiterPages/RecruiterSignIn")
// );

import RecruiterLogin from "../components/recruiterPages/RecruiterLogin";
// const RecruiterLogin = lazy(() =>
//   import("../components/recruiterPages/RecruiterLogin")
// );

import RecruiterSet from "../components/recruiterPages/RecruiterSet";
// const RecruiterSet = lazy(() =>
//   import("../components/recruiterPages/RecruiterSet")
// );

import RecruiterBuild from "../components/recruiterPages/RecruiterBuild";
// const RecruiterBuild = lazy(() =>
//   import("../components/recruiterPages/RecruiterBuild")
// );

import RecruiterHelp from "../components/recruiterPages/RecruiterHelp";
// const RecruiterHelp = lazy(() =>
//   import("../components/recruiterPages/RecruiterHelp")
// );

import RecruiterComplete from "../components/recruiterPages/RecruiterComplete";
// const RecruiterComplete = lazy(() =>
//   import("../components/recruiterPages/RecruiterComplete")
// );

import CareerSignUp from "../components/career/CarrerSignUp";
// const CareerSignUp = lazy(() => import("../components/career/CarrerSignUp"));

import CareerResume from "../components/career/CareerResume";
// const CareerResume = lazy(() => import("../components/career/CareerResume"));

import CareerComplete from "../components/career/CareerComplete";
// const CareerComplete = lazy(() =>
  // import("../components/career/CareerComplete")
// );

import CareerTable from "../components/career/CareerTable";
// const CareerTable = lazy(() => import("../components/career/CareerTable"));

import InterviewSignUp from "../components/interviewer/interviewSign";
// const InterviewSignUp = lazy(() =>
//   import("../components/interviewer/interviewSign")
// );

import InterviewAllAPP from "../components/interviewer/InterviewAllApp";
// const InterviewAllAPP = lazy(() =>
//   import("../components/interviewer/InterviewAllApp")
// );

import InterviewTable from "../components/interviewer/InterviewTable";
// const InterviewTable = lazy(() =>
//   import("../components/interviewer/InterviewTable")
// );

import ResumeBuilder from "../components/resume/ResumeBuilder";
// const ResumeBuilder = lazy(() => import("../components/resume/ResumeBuilder"));

import Templates from "../components/resume/Templates";
// const Templates = lazy(() => import("../components/resume/Templates"));

import TemplatesTwo from "../components/resume/TemplatesTwo";
// const TemplatesTwo = lazy(() => import("../components/resume/TemplatesTwo"));

import GetMoreTemplate from "../components/resume/GetMoreTemplate";
import InCalender from "../components/interviewer/InCalender";
import RecruiterFaq from "../components/acount-pages/RecruiterFaq";
import RecruiterPrivacyPolicy from "../components/acount-pages/RecruiterPrivacyPolicy";
import RecruiterTermsPage from "../components/acount-pages/RecruiterTermsPage";
import RecruiterRefundPage from "../components/acount-pages/RecruiterRefundPage";
import FilterMobilePage from "../dashboard/Chat/FilterMobilePage";
import RecruiterMobileUpdate from "../components/recruiterPages/RecruiterMobileUpdate";
import UserMobileUpdate from "../pages/UserMobileUpdate";
import AllChat from "../components/recruiter/AllChat";
import TemplatesThree from "../components/resume/TemplatesThree";
import TemplatesFour from "../components/resume/TemplatesFour";
import TemplatesFive from "../components/resume/TemplatesFive";
import TemplatesSix from "../components/resume/TemplatesSix";
import AuthPage from "../components/recruiterPages/AuthPage";
// const GetMoreTemplate = lazy(() =>
//   import("../components/resume/GetMoreTemplate")
// );

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  {
    path: "/home",
    element: (
      // <Suspense fallback={<Loader />}>
        <Homepage />
      // </Suspense>
    ),
  },
  {
    path: "/about-us",
    element: (
      // <Suspense fallback={<Loader />}>
        <AboutPage />
      // </Suspense>
    ),
  },
  {
    path: "/community",
    element: (
      // <Suspense fallback={<Loader />}>
        <Community />
      // </Suspense>
    ),
  },
  {
    path: "/sign-in",
    element: (
      // <Suspense fallback={<Loader />}>
        <SignIn />
      // </Suspense>
    ),
  },
  {
    path: "/log-in",
    element: (
      // <Suspense fallback={<Loader />}>
        <Login />
      // </Suspense>
    ),
  },
  {
    path: "/sign-up-switch",
    element: (
      // <Suspense fallback={<Loader />}>
        <SignUpSwitch />
      // </Suspense>
    ),
  },
  {
    path: "/user-update-mobile",
    element: (
      // <Suspense fallback={<Loader />}>
        <UserMobileUpdate />
      // </Suspense>
    ),
  },
  {
    path: "/set-job",
    element: (
      // <Suspense fallback={<Loader />}>
        <SetJob />
      // </Suspense>
    ),
  },
  {
    path: "/set-job/switch",
    element: (
      // <Suspense fallback={<Loader />}>
        <SetJobSwitch />
      // </Suspense>
    ),
  },
  {
    path: "/create-resume",
    element: (
      // <Suspense fallback={<Loader />}>
        <CreateResume />
      // </Suspense>
    ),
  },
  {
    path: "/build-resume",
    element: (
      // <Suspense fallback={<Loader />}>
        <BuildResume />
      // </Suspense>
    ),
  },
  {
    path: "/build-resume-1",
    element: (
      // <Suspense fallback={<Loader />}>
        <BuildResumeOne />
      // </Suspense>
    ),
  },
  {
    path: "/build-resume-2",
    element: (
      // <Suspense fallback={<Loader />}>
        <BuildResumeTwo />
      // </Suspense>
    ),
  },
  {
    path: "/help-us",
    element: (
      // <Suspense fallback={<Loader />}>
        <HelpUs />
       // </Suspense>
    ),
  },
  {
    path: "/help-us/enter-otp",
    element: (
      // <Suspense fallback={<Loader />}>
        <OtpPage />
       // </Suspense>
    ),
  },
  {
    path: "/complete-resume",
    element: (
      // <Suspense fallback={<Loader />}>
        <Complete />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard",
    element: (
      // <Suspense fallback={<Loader />}>
        <Home />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/job-filter",
    element: (
      // <Suspense fallback={<Loader />}>
        <FilterMobilePage />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/job-description/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <JobDescription />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/job-apply",
    element: (
      // <Suspense fallback={<Loader />}>
        <JobApply />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/job-apply/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <JobApply />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/job-apply/switch/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <SubmitSwitch />
       // </Suspense>
    ),
  },
  {
    path: "/upgrade",
    element: (
      // <Suspense fallback={<Loader />}>
        <UpgradePage />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter/upgrade",
    element: (
      // <Suspense fallback={<Loader />}>
        <UpgradeRecriterPage />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/saved-applications",
    element: (
      // <Suspense fallback={<Loader />}>
        <SavePage />
       // </Suspense>
    ),
  },

  //private route is done 
  {
    path: "/dashboard/up-skill",
    element: (
      // <Suspense fallback={<Loader />}>
        <UpSkillPage />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/career",
    element: (
      // <Suspense fallback={<Loader />}>
        <CareerPage />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/track",
    element: (
      // <Suspense fallback={<Loader />}>
        <TrackPage />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/chat-room",
    element: (
      // <Suspense fallback={<Loader />}>
        <ChatPage />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/chat",
    element: (
      // <Suspense fallback={<Loader />}>
        <MobileChat />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/track/rating",
    element: (
      // <Suspense fallback={<Loader />}>
        <RatingMobile />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/analysis",
    element: (
      // <Suspense fallback={<Loader />}>
        <Analysis />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/analysis/upgrade",
    element: (
      // <Suspense fallback={<Loader />}>
        <AnalysisUpgrade />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/new-job",
    element: (
      // <Suspense fallback={<Loader />}>
        <NewJob />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/calender",
    element: (
      // <Suspense fallback={<Loader />}>
        <Calender />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/new-job/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <NewJob />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/profile-detail",
    element: (
      // <Suspense fallback={<Loader />}>
        <ProfileDetail />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/profile-detail/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <ProfileDetail />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/draft",
    element: (
      // <Suspense fallback={<Loader />}>
        <Draft />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/all-application",
    element: (
      // <Suspense fallback={<Loader />}>
        <AllApplication />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/all-application/review",
    element: (
      // <Suspense fallback={<Loader />}>
        <AllAppDetail />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/all-application/review/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <AllAppDetail />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/table/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <Table />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/table/view-profile/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <ViewProfile />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/table",
    element: (
      // <Suspense fallback={<Loader />}>
        <Table />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/all-interviews",
    element: (
      // <Suspense fallback={<Loader />}>
        <AllInterviews />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/recruiter/chat",
    element: (
      // <Suspense fallback={<Loader />}>
        <AllChat />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/add-new-hire",
    element: (
      // <Suspense fallback={<Loader />}>
        <AddNewHire />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/add-new-hire/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <AddNewHire />
       // </Suspense>
    ),
  },
  // {
  //   path: "/dashboard/recruiter",
  //   element: <HomePage />,
  // },
  {
    path: "/dashboard/post-new-job",
    element: (
      // <Suspense fallback={<Loader />}>
        <PostNewJob />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/post-new-job/draft",
    element: (
      // <Suspense fallback={<Loader />}>
        <PostJobDraft />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/post-new-job/job-review",
    element: (
      // <Suspense fallback={<Loader />}>
        <PostJobReview />
       // </Suspense>
    ),
  },
  {
    path: "/dashboard/all-job",
    element: (
      // <Suspense fallback={<Loader />}>
        <AllJobs />
       // </Suspense>
    ),
  },
  {
    path: "/setting",
    element: (
      // <Suspense fallback={<Loader />}>
        <Setting />
       // </Suspense>
    ),
  },
  {
    path: "/user-setting",
    element: (
      // <Suspense fallback={<Loader />}>
        <UserSetting />
       // </Suspense>
    ),
  },
  {
    path: "/career-setting",
    element: (
      // <Suspense fallback={<Loader />}>
        <CareerSetting />
       // </Suspense>
    ),
  },
  {
    path: "/setting/bug-report",
    element: (
      // <Suspense fallback={<Loader />}>
        <BugReport />
       // </Suspense>
    ),
  },
  {
    path: "/help-and-support",
    element: (
      // <Suspense fallback={<Loader />}>
        <Help />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter/help-and-support",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterHelp2 />
       // </Suspense>
    ),
  },
  {
    path: "/help-and-support/faq",
    element: (
      // <Suspense fallback={<Loader />}>
        <Faq />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter/faq",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterFaq />
       // </Suspense>
    ),
  },
  {
    path: "/help-and-support/privacy-policy",
    element: (
      // <Suspense fallback={<Loader />}>
        <PrivacyPolicy />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter/privacy-policy",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterPrivacyPolicy />
       // </Suspense>
    ),
  },
  {
    path: "/help-and-support/terms-and-conditions",
    element: (
      // <Suspense fallback={<Loader />}>
        <TermsPage />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter/terms-and-conditions",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterTermsPage />
       // </Suspense>
    ),
  },
  {
    path: "/help-and-support/refund",
    element: (
      // <Suspense fallback={<Loader />}>
        <RefundPage />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter/refund",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterRefundPage />
       // </Suspense>
    ),
  },
  {
    path: "/write-us",
    element: (
      // <Suspense fallback={<Loader />}>
        <ResumeBuild />
       // </Suspense>
    ),
  },
  {
    path: "/log-out",
    element: (
      // <Suspense fallback={<Loader />}>
        <LogOut />
       // </Suspense>
    ),
  },
  {
    path: "/deactivate",
    element: (
      // <Suspense fallback={<Loader />}>
        <Deactivate />
       // </Suspense>
    ),
  },
  {
    path: "/delete",
    element: (
      // <Suspense fallback={<Loader />}>
        <Delete />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter-dashboard",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterDashboard />
       // </Suspense>
    ),
  },
  {
    path: "/dummy-dashboard",
    element: (
      // <Suspense fallback={<Loader />}>
        <DummyDashboard />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter-sign-in",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterSignIn />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter-login",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterLogin />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter-update-mobile",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterMobileUpdate />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter-set",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterSet />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter-build",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterBuild />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter-help",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterHelp />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter-complete",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterComplete />
       // </Suspense>
    ),
  },
  {
    path: "/oauth2callback",
    element: (
      // <Suspense fallback={<Loader />}>
        <AuthPage />
       // </Suspense>
    ),
  },
  {
    path: "/career/sign-up",
    element: (
      // <Suspense fallback={<Loader />}>
        <CareerSignUp />
       // </Suspense>
    ),
  },
  {
    path: "/career/sign-in",
    element: (
      // <Suspense fallback={<Loader />}>
        <CareerLogin />
       // </Suspense>
    ),
  },
  {
    path: "/career/create-profile",
    element: (
      // <Suspense fallback={<Loader />}>
        <CareerResume />
       // </Suspense>
    ),
  },
  {
    path: "/career/complete",
    element: (
      // <Suspense fallback={<Loader />}>
        <CareerComplete />
       // </Suspense>
    ),
  },
  {
    path: "/career/calender",
    element: (
      // <Suspense fallback={<Loader />}>
        <CareerCalender />
       // </Suspense>
    ),
  },
  {
    path: "/career/table",
    element: (
      // <Suspense fallback={<Loader />}>
        <CareerTable />
       // </Suspense>
    ),
  },
  {
    path: "/interview/sign-in",
    element: (
      // <Suspense fallback={<Loader />}>
        <InterviewSignUp />
       // </Suspense>
    ),
  },
  {
    path: "/interview/all-application",
    element: (
      // <Suspense fallback={<Loader />}>
        <InterviewAllAPP />
       // </Suspense>
    ),
  },
  {
    path: "/interview/table/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <InterviewTable />
       // </Suspense>
    ),
  },
  {
    path: "/interview/calender",
    element: (
      // <Suspense fallback={<Loader />}>
        <InCalender />
       // </Suspense>
    ),
  },
  {
    path: "/interview/table/view-profile/:id",
    element: (
      // <Suspense fallback={<Loader />}>
        <ViewProfileIn />
       // </Suspense>
    ),
  },
  {
    path: "/resume-builder",
    element: (
      // <Suspense fallback={<Loader />}>
        <ResumeBuilder />
       // </Suspense>
    ),
  },
  {
    path: "/resume-builder/templates",
    element: (
      // <Suspense fallback={<Loader />}>
        <Templates />
       // </Suspense>
    ),
  },
  {
    path: "/resume-builder/templates/get-more",
    element: (
      // <Suspense fallback={<Loader />}>
        <GetMoreTemplate />
       // </Suspense>
    ),
  },
  {
    path: "/resume-builder/templates-paid",
    element: (
      // <Suspense fallback={<Loader />}>
        <TemplatesTwo />
       // </Suspense>
    ),
  },
  {
    path: "/resume-builder/templates-paid-1",
    element: (
        <TemplatesThree />
    ),
  },
  {
    path: "/resume-builder/templates-paid-2",
    element: (
        <TemplatesFour />
    ),
  },
  {
    path: "/resume-builder/templates-paid-3",
    element: (
        <TemplatesFive />
    ),
  },
  {
    path: "/resume-builder/templates-paid-4",
    element: (
        <TemplatesSix />
    ),
  },
  {
    path: "/job-seeker/edit",
    element: (
      // <Suspense fallback={<Loader />}>
        <JobEdit />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter/edit",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterEdit />
       // </Suspense>
    ),
  },
  {
    path: "/recruiter/forget",
    element: (
      // <Suspense fallback={<Loader />}>
        <RecruiterForget />
       // </Suspense>
    ),
  },
  {
    path: "/job-seeker/forget",
    element: (
      // <Suspense fallback={<Loader />}>
        <JobForget />
       // </Suspense>
    ),
  },
  {
    path: "/career/forget",
    element: (
      // <Suspense fallback={<Loader />}>
        <CareerForget />
       // </Suspense>
    ),
  },
]);

export default router;
