import React from "react";
import "./account.css";
// import AccUp from "../../assets/recruiter-dashboard/acc-arrow.svg";
// import LightAccUp from "../../assets/recruiter-dashboard/light-acc-arrow.svg";
import emailImg from "../../assets/contact-us/email.svg";
import callImg from "../../assets/contact-us/call.svg";
import locationImg from "../../assets/contact-us/location.svg";
import messageImg from "../../assets/contact-us/message.svg";
import { Link } from "react-router-dom";
import backImg from "../../assets/recruiter/back-img.svg";
import { useNavigate } from "react-router-dom";
import FormHeader from "../header/FormHeader";
import RecruiterHeader from "../header/RecruiterHeader";

function Accordion({ title, accLink }) {
  return (
    <>
        <Link to={accLink} style={{textDecoration:"none"}}>
      <div className="help-custom-accordion" style={{ display: "flex" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2 className="help-acc-title">{title}</h2>
            </div>
            <div className="acc-up-img"></div>
          </div>
      </div>
        </Link>
    </>
  );
}

function ContactUs({ contactImg, contactTitle, contactText }) {
  return (
    <>
      <div className="contact-us-card">
        <img src={contactImg} alt="" />
        <div>
          <h6 className="contact-us-card-title">{contactTitle}</h6>
          <p className="contact-us-card-text">{contactText}</p>
        </div>
      </div>
    </>
  );
}

function RecruiterHelp2() {
  const navigate = useNavigate();
  return (
    <>
      <RecruiterHeader title="Help & Support" />
      <div className="help-page">
        <div className="help-container">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <button
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={() => navigate(-1)}
              >
                <img src={backImg} className="prev-page" alt="" />
              </button>
            </div>
            <div>
              <h6 className="help-title">Help and Support</h6>
              <p className="help-text">Know more about us </p>
            </div>
            <div></div>
          </div>
          <div
            style={{ borderBottom: "1px solid #E2E8F0", margin: "16px 0" }}
          ></div>
          <div className="help-accordion-box">
            <Accordion accLink="/recruiter/faq" title="FAQs" />
            <Accordion
              accLink="/recruiter/privacy-policy"
              title="Privacy Policy"
            />
            <Accordion
              accLink="/recruiter/terms-and-conditions"
              title="Terms and Conditions"
            />
            <Accordion
              accLink="/recruiter/refund"
              title="Refund & Cancellation Policy"
            />
          </div>
        </div>
        <div
          className="help-container "
          style={{ marginTop: "12px",  }}
        >
          <h6
            style={{
              color: "#475569",
              fontFamily: "Inter",
              fontSize: "10px",
              fontWeight: "600",
              lineHeight: "12px",
              paddingLeft:"24px"
            }}
          >
            Contact us
          </h6>
          <div className="d-flex flex-wrap help-accordion-box" style={{ gap: "12px" }}>
            <ContactUs
              contactImg={emailImg}
              contactTitle="Email us"
              contactText="support@recruitifie.com"
            />
            <ContactUs
              contactImg={callImg}
              contactTitle="Call us"
              contactText="+91 6006989302"
            />
            {/* <ContactUs
              contactImg={locationImg}
              contactTitle="Address"
              contactText="Office address "
            /> */}
            {/* <ContactUs
              contactImg={messageImg}
              contactTitle="Write to us"
              contactText="amanarora@gmail.com"
            /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default RecruiterHelp2;
