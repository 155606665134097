import React, { useState } from "react";
import "./account.css";
// import { Link } from "react-router-dom";
import lightArrow from "../../assets/recruiter-dashboard/light-down-arrow.svg";
import backImg from "../../assets/recruiter/back-img.svg";
import { useNavigate } from "react-router-dom";
import FormHeader from "../header/FormHeader";
import RecruiterHeader from "../header/RecruiterHeader";

function Accordion({ title, accLink, content }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div
        className="faq-custom-accordion"
        onClick={() => setIsActive(!isActive)}
        style={{ backgroundColor: isActive ? "#2E3D55" : "#FFF" }}
      >
        {/* <Link to={accLink}> */}
        <div className="faq-custom-accordion-box">
          <div>
            <h2
              className="faq-acc-title"
              style={{ color: isActive ? "#fff" : "#262626" }}
            >
              {title}
            </h2>
          </div>
          <div
            className={isActive ? "img-active" : "acc-up-img"}
            style={{
              backgroundImage: isActive ? `url(${lightArrow})` : "",
              height: "32px",
              width: "32px",
            }}
          ></div>
        </div>
        {/* </Link> */}
        {isActive && <div className="faq-acc-content">{content}</div>}
      </div>
    </>
  );
}
const accordionData = [
  {
    title: "FAQ 1",
    content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`,
  },
  {
    title: "FAQ 2",
    content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
      reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
      quaerat iure quos dolorum accusantium ducimus in illum vero commodi
      pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
      quidem maiores doloremque est numquam praesentium eos voluptatem amet!
      Repudiandae, mollitia id reprehenderit a ab odit!`,
  },
  {
    title: "FAQ 3",
    content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
      quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
      dolor ut sequi minus iste? Quas?`,
  },
  {
    title: "FAQ 4",
    content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
      quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
      dolor ut sequi minus iste? Quas?`,
  },
  {
    title: "FAQ 5",
    content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
      quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
      dolor ut sequi minus iste? Quas?`,
  },
];

function RecruiterFaq() {
  const navigate = useNavigate();
  return (
    <>
      <RecruiterHeader title="FAQs" />
      <div className="faq-page">
        <div className="faq-container">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <button
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={() => navigate(-1)}
              >
                <img src={backImg} className="prev-page" alt="" />
              </button>
            </div>
            <div>
              <h6 className="faq-title">Know more about Recruitifie</h6>
              <p className="faq-text">
                Read about the frequently asked questions{" "}
              </p>
            </div>
            <div></div>
          </div>
          <div
            style={{ borderBottom: "1px solid #E2E8F0", margin: "16px 0" }}
          ></div>
          <div className="faq-accordion-box">
            {accordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} />
            ))}
          </div>
          {/* <div className="help-accordion-box">
            <Accordion accLink="/" title="FAQs" />
            <Accordion accLink="/" title="Terms and Conditions" />
            <Accordion accLink="/" title="About the Company" />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default RecruiterFaq;
