import React, { useContext } from "react";
import HelpModal from "./HelpModal";
import './account.css'
import AllModalContext from "../../context/allModal/AllModalContext";
import FormHeader from "../header/FormHeader";
import Header from "../header/Header";

function ResumeBuild() {
  const modal = useContext(AllModalContext);
  const { toggleHelp } = modal;
  return (
    <>
      <Header title="Contact Us" />
      <div className="write-page">
        <div className="write-container">
          <h6 className="faq-title">Write to us</h6>
          <p className="faq-text w-100">We will get back with the updates</p>
          <div
            style={{ borderBottom: "1px solid #E2E8F0", margin: "16px 0" }}
          ></div>
          <div className="help-input-container">
            <div className="help-input-items">
              <input
                type="text"
                placeholder="name"
                className="help-modal-input"
              />
              <input
                type="text"
                placeholder="number"
                className="help-modal-input"
              />
              <input
                type="text"
                placeholder="gmail"
                className="help-modal-input"
              />
            </div>
            <textarea
              name=""
              placeholder="Write your grievances here"
              className="help-modal-input w-100 m-0"
              id=""
              cols="30"
              rows="3"
            ></textarea>
            <div className="text-center">
              <button className="help-back-btn">Back</button>
              <button
                onClick={toggleHelp}
                className="setting-save-btn"
                style={{ width: "362px" }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className="bottom-btn mobile-show-hide">
          <div className="d-flex flex-column">
            <button className="save-btn" onClick={toggleHelp}>
              Save and Continue
            </button>
            <button className="skip-btn">Skip for now</button>
          </div>
        </div>
      </div>
      <HelpModal />
    </>
  );
}

export default ResumeBuild;
