import axios from 'axios';
import { baseUrl } from '../contextApi/common/data';
import { toast } from "react-toastify";

export const initializePayment = async (total, type, id, planName, start_time, end_time, user_name, email, contact) => {
    try {
        if (!total || !type || !id || !planName || !start_time || !end_time || !user_name || !email || !contact ) {
            toast.error("Missing required parameters.");
            return { statusCode: 400, status: "Failed", Message: "Missing required parameters" };
        }
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        await loadScript('https://checkout.razorpay.com/v1/checkout.js');
        const createOrderResponse = await axios.post(`${baseUrl}/razorpay/create/payment`, {
            amount: total * 100,
            currency: 'INR',
            receipt: 'Receipt no. 1',
            notes: {
                notes_key_1: 'Tea, Earl Grey, Hot',
                notes_key_2: 'Tea, Earl Grey… decaf.',
            },
        });

        if (createOrderResponse.status !== 200) {
            toast.error("Create Order Failed.")
            return { statusCode: 500, status: "Failed", Message: "Create Order Failed." }
        }
        const orderId = createOrderResponse.data?.data?.id;

        const options = {
            key: 'rzp_test_qwG3SEbjTZPCWK',
            amount: total * 100,
            currency: 'INR',
            name: 'Recruitifie',
            description: 'Payment',
            order_id: orderId,
            handler: async function (response) {
                try {
                    const verifyPaymentResponse = await axios.post(`${baseUrl}/razorpay/verify/payment`, {
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                        type,
                        id,
                        planName,
                        start_time, end_time
                    });
                    console.log(verifyPaymentResponse);
                    if (verifyPaymentResponse.status==200) {
                        toast.success("Plan upgrade successful.");

                        return { statusCode: 200, status: "Success", Message: "Payment Verification Done" }
                    } else {
                        toast.error("Payment verification failed.");

                        return { statusCode: 500, status: "Failed", Message: "Payment Verification Failed." }
                    }
                } catch (error) {
                    console.error('Error verifying payment:', error);
                    return { statusCode: 500, status: "Failed", Message: "An error occurred while verifying payment." }
                }
            },
            prefill: {
                name: user_name,
                email,
                contact,
            },
            notes: {
                address:"Recruitifie Corporate Office.",
            },
            theme: {
                color: '#F37254',
            },
        };
        const razorpay = new window.Razorpay(options);
        razorpay.open();

    } catch (error) {
        console.error('Error initializing payment:', error);
        return { statusCode: 500, status: "Failed", Message: "An error occurred while initializing payment." }
    }
};
