import { baseUrl } from "../common/data";

export const createPost = async (data, token) => {
  try {
    const response = await fetch(`${baseUrl}/job/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getAllDraft = async (token) => {
  try {
    const response = await fetch(`${baseUrl}/job/alldraft`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getPostById = async (id, token) => {
  try {
    const response = await fetch(`${baseUrl}/job/getsinglepost/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updatePost = async (data, id, token) => {
  try {
    const response = await fetch(`${baseUrl}/job/updatepost/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const makePost = async (id, token) => {
  try {
    const response = await fetch(`${baseUrl}/job/makepost/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const allPostByRecruiter = async (token) => {
  try {
    const response = await fetch(`${baseUrl}/job/getallpost`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getInterviewByRecruitrId = async (id) => {
  try {
    let data = [];
    await fetch(`${baseUrl}/interviewer/interview/getbyrecruiter/${id}`, {
      method: "GET",
      redirect: "follow",
    })
      .then((req) => req.json())
      .then((res) => {
        data = res;
        return res;
      })
      .catch((err) => console.log(err));
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const deletePost = async (id) => {
  try {
    let data = [];
    await fetch(`${baseUrl}/recruiter/deletepost/${id}`, {
      method: "DELETE",
      redirect: "follow",
    })
      .then((req) => req.json())
      .then((res) => {
        data = res;
        return res;
      })
      .catch((err) => console.log(err));
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const closePost = async (id) => {
  try {
    let data = [];
    await fetch(`${baseUrl}/recruiter/closepost/${id}`, {
      method: "PATCH",
      redirect: "follow",
    })
      .then((req) => req.json())
      .then((res) => {
        data = res;
        return res;
      })
      .catch((err) => console.log(err));
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getClosePost = async (id) => {
  try {
    let data = [];
    await fetch(`${baseUrl}/recruiter/getall/closepost/${id}`, {
      method: "GET",
      redirect: "follow",
    })
      .then((req) => req.json())
      .then((res) => {
        data = res;
        return res;
      })
      .catch((err) => console.log(err));
    return data;
  } catch (err) {
    console.log(err);
  }
};
